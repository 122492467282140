import { IColumns } from '../../../interfaces/table.insterface';

export const columnsSupervisionExtraordinary: IColumns[] = [
	{
		text: '',
		nameID: 'routineDescription',
		width: '800px',
	},
	{
		text: '',
		nameID: 'acciones',
		width: 100,
	},
];
