/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { RootState } from '../../../store';
import { setAlert } from '../../../store/modules/snackbar';
import { useDevice } from './useDevice';
import { IProject } from '../../../interfaces/project.interface';
import { isAdmin, isDirector } from '../../../utils/permissions.utils';
import { resetProject } from '../../../store/modules/project';

export const useHomeProjects = () => {
	const { firstName, lastName, isDeviceCreated, role } = useSelector(
		(state: RootState) => state.users
	);
	const [data, setData] = useState<IProject[]>([]);
	const dispatch = useDispatch();
	const { getLocation } = useDevice();
	const [pagination, setPagination] = useState<{
		pagination: number;
		rowsPerPage: number;
		pages: number;
	}>({ pagination: 1, rowsPerPage: 6, pages: 0 });

	const {
		execute: getProjects,
		status: statusProject,
		value: projects,
	} = useApiRequest<unknown, IResponsePayload<IProject>>({
		path: URLS.PROJECT.CREATE_PROJECT,
		method: 'GET',
	});
	const {
		execute: getProjectsBySupervisor,
		status: statusProjectBySupervisor,
		value: projectsBySupervisor,
	} = useApiRequest<unknown, IResponsePayload<IProject>>({
		path: URLS.PROJECT.PROJECT_BY_SUPERVISOR,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(resetProject());
	}, []);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		setData([]);
		if (isAdmin(role) || isDirector(role)) {
			getProjects(
				null,
				`?step=x&includeCoverImage=1&page=${pagination.pagination}&recordsByPage=${pagination.rowsPerPage}`
			);
		} else {
			getProjectsBySupervisor(
				null,
				`?includeCoverImage=1&page=${pagination.pagination}&recordsByPage=${pagination.rowsPerPage}`
			);
		}
		if (!isDeviceCreated) getLocation();
	}, [pagination.pagination]);

	useEffect(() => {
		if (statusProject === 'success') {
			dispatch(stopLoading());
			setData(projects?.data || []);
			setPagination({
				...pagination,
				pages: projects?.totalPages || 0,
			});
		} else if (statusProject === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusProject]);
	useEffect(() => {
		if (statusProjectBySupervisor === 'success') {
			dispatch(stopLoading());
			setData(projectsBySupervisor?.data || []);
			setPagination({
				...pagination,
				pages: projectsBySupervisor?.totalPages || 0,
			});
		} else if (statusProjectBySupervisor === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusProjectBySupervisor]);

	return {
		data,
		firstName,
		lastName,
		pagination,
		setPagination,
	};
};
