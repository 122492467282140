export const IS_DELETED = Object.freeze({
	BUTTON_DESCRIPTION: 'Sí, continuar',
	DESCRIPTION:
		'¿Seguro que desea continuar con el proceso de Eliminar al usuario? Esta acción será permanente.',
});

export const IS_DELETED_MODAL = Object.freeze({
	TITLE: 'Eliminar usuario',
	DESCRIPTION_IS_VALID: '¿Desea eliminar el usuario? Esta acción es permanente.',
	DESCRIPTION_IS_NOT_VALID:
		'Detectamos que el usuario que eliminará cuenta con proyectos asignados. Asigna nuevos encargados para continuar con el proceso. ¿Deseas continuar?',
});

export const IS_DELETED_PROJECT = Object.freeze({
	BUTTON_DESCRIPTION: 'Sí, continuar',
	DESCRIPTION: '¿Seguro que desea continuar con el proceso de Desactivar al usuario?',
});

export const IS_DELETED_PROJECT_MODAL = Object.freeze({
	TITLE: 'Suspender usuario',
	DESCRIPTION_IS_VALID: '¿Desea suspender al usuario del proyecto?',
	DESCRIPTION_IS_NOT_VALID:
		'Detectamos que el usuario que desactivará cuenta con asignaciones en el proyecto. Asigna nuevos encargados para continuar con el proceso. ¿Deseas continuar?',
});

export const IS_ACTIVE = Object.freeze({
	BUTTON_DESCRIPTION: 'Sí, activar',
	DESCRIPTION: '¿Seguro que desea Activar al usuario?',
});

export const IS_INACTIVE = Object.freeze({
	BUTTON_DESCRIPTION: 'Sí, suspender',
	DESCRIPTION: '¿Seguro que desea Suspender al usuario?',
});
