import { createSlice } from '@reduxjs/toolkit';

interface IProfileProject {
	step: number;
	stepEjecucion: number;
	stepSupervision: number;
	stepReporteo: number;
}

const defaultValueProfileProject: IProfileProject = {
	step: 0,
	stepEjecucion: 0,
	stepSupervision: 0,
	stepReporteo: 0,
};
export const ProfileProjectSlice = createSlice({
	name: 'profileProject',
	initialState: defaultValueProfileProject,
	reducers: {
		setValuePP: (state, action) => ({
			...state,
			...action.payload,
		}),
		resetPP: () => defaultValueProfileProject,
	},
});

export const { setValuePP, resetPP } = ProfileProjectSlice.actions;

export default ProfileProjectSlice.reducer;
