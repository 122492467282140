import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { RoutineList } from './routine-list.component';
import { SupervisionHistoryDetail } from '../supervision-detail.component';
import { RoutineDetailEvidence } from '../routine/routine-detail-evidence.component';
import { SupervisionViewActions } from '../supervision-view-actions';
import { SupervisionViewReports } from '../supervision-view-reports';
import { SupervisionViewActivities } from '../supervision-view-activities';
import { useState } from 'react';
import { SupervisionHistoryContext } from '../../contexts/useSupervisionHistoryContext';
import { DefaultDataSelect } from '../../interfaces/supervision-history.interface';
interface IRoutineMain {
	setOption: React.Dispatch<React.SetStateAction<string>>;
}

export const RoutineMain = ({ setOption }: IRoutineMain) => {
	const step = useSelector((state: RootState) => state.stateStep);
	const [canBeExecuted, setCanBeExecuted] = useState(false);

	const [dataSelect, setDataSelect] = useState<any>({ ...DefaultDataSelect });

	return (
		<SupervisionHistoryContext.Provider value={{ dataSelect, setDataSelect }}>
			{step.state === 0 && (
				<RoutineList setOption={setOption} setCanBeExecuted={setCanBeExecuted} />
			)}
			{step.state === 2 && (
				<SupervisionHistoryDetail canBeExecuted={canBeExecuted} />
			)}
			{step.state === 4 && <RoutineDetailEvidence />}
			{step.state === 5 && <SupervisionViewActions />}
			{step.state === 6 && <SupervisionViewReports />}
			{step.state === 7 && <SupervisionViewActivities />}
		</SupervisionHistoryContext.Provider>
	);
};
