/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { stopLoading, setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { ITeam } from '../../interfaces/team.interface';

interface ICreateNewTeam {
	teamData: ITeam[];
	setTeamData: React.Dispatch<React.SetStateAction<ITeam[]>>;
}

export const useCreateNewTeams = ({ teamData, setTeamData }: ICreateNewTeam) => {
	const dispatch = useDispatch();
	const [body, setBody] = useState<ITeam | undefined>(undefined);
	const [index, setIndex] = useState(0);

	const {
		execute: createTeam,
		status: statusCreateTeam,
		value: valueCreateTeam,
	} = useApiRequest<ITeam, IResponsePayload<ITeam>>({
		path: URLS.EDIT_PROJECT.TEAMS.GET_TEAMS,
		method: 'POST',
	});

	useEffect(() => {
		if (statusCreateTeam === 'success') {
			dispatch(stopLoading());
			if (valueCreateTeam?.detail)
				setTeamData(
					teamData.map((team, i) => {
						if (i === index) {
							return { ...valueCreateTeam.detail, isComplete: true };
						} else return team;
					})
				);
			dispatch(
				setAlert({
					show: true,
					message: 'Equipo creado con éxito',
					severity: 'success',
				})
			);
		} else if (statusCreateTeam === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el equipo',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusCreateTeam]);

	useEffect(() => {
		if (body) {
			dispatch(setLoading({ loading: true }));
			createTeam(body);
		}
	}, [body]);

	const handleNewTeam = (body: ITeam, index: number) => {
		setBody(body);
		setIndex(index);
	};

	return {
		handleNewTeam,
	};
};
