import { RemoveRedEye } from '@mui/icons-material';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { ISuspension } from '../interfaces/suspension.interface';
import { ITypeRoutine } from '../../../constants/routines.constants';
import { getRoutineDescriptionByType } from '../../../utils/get-title-routines';

interface SuspensionAdapter extends ISuspension {
	acciones: React.ReactNode;
}
export const suspensionAdapter = (
	data: ISuspension[],
	handleAction: (action: string) => void
): SuspensionAdapter[] => {
	const newData: SuspensionAdapter[] = data.map((suspension: ISuspension) => {
		return {
			...suspension,
			description: getRoutineDescriptionByType(
				suspension.type as keyof ITypeRoutine,
				suspension.executionDate as unknown as Date
			),
			acciones: (
				<ButtonIconSmall
					text="Ver"
					type="info"
					icon={<RemoveRedEye />}
					onPress={() => handleAction(suspension.routine)}
				/>
			),
		};
	});
	return newData;
};
