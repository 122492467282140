export interface IUserRoles {
	ADMIN: string;
	EXTERNAL: string;
	SUPERVISOR: string;
	GERENTE: string;
	DIRECTOR: string;
	COORDINADOR: string;
}
export const USER_ROLES: IUserRoles = {
	ADMIN: 'ADMIN',
	EXTERNAL: 'EXTERNAL',
	SUPERVISOR: 'SUPERVISOR',
	GERENTE: 'GERENTE',
	DIRECTOR: 'DIRECTOR',
	COORDINADOR: 'COORDINADOR',
};

export const isExternal = (role: keyof IUserRoles): boolean =>
	role === USER_ROLES.EXTERNAL;
export const isAdmin = (role: keyof IUserRoles): boolean => role === USER_ROLES.ADMIN;
export const isSupervisor = (role: keyof IUserRoles): boolean =>
	role === USER_ROLES.SUPERVISOR;
export const isGerente = (role: keyof IUserRoles): boolean => role === USER_ROLES.GERENTE;
export const isDirector = (role: keyof IUserRoles): boolean =>
	role === USER_ROLES.DIRECTOR;
export const isCoordinador = (role: keyof IUserRoles): boolean =>
	role === USER_ROLES.COORDINADOR;

export const getRoles = (): { id: string; value: string; label: string }[] => {
	const roles = [];
	for (const role in USER_ROLES) {
		roles.push({
			id: role,
			value: role,
			label: getRoleName(role),
		});
	}

	return roles;
};
export const getRoleName = (role: keyof IUserRoles | string): string => {
	let roleName = '';
	switch (role) {
		case USER_ROLES.ADMIN:
			roleName = 'Administrador';
			break;
		case USER_ROLES.EXTERNAL:
			roleName = 'Externo';
			break;
		case USER_ROLES.SUPERVISOR:
			roleName = 'Supervisor';
			break;
		case USER_ROLES.GERENTE:
			roleName = 'Gerente';
			break;
		case USER_ROLES.DIRECTOR:
			roleName = 'Director';
			break;
		case USER_ROLES.COORDINADOR:
			roleName = 'Coordinador';
			break;
		default:
			roleName = 'Administrador';
			break;
	}
	return roleName;
};
