import { IFileButtonInterface } from '../../../interfaces/file-button.interface';

export const formNewProject = [
	{
		id: 'name',
		name: 'name',
		label: 'Nombre del proyecto',
		placeholder: 'Ingresar nombre',
		type: 'text',
	},
	{
		id: 'shortName',
		name: 'shortName',
		label: 'Nombre corto del proyecto',
		placeholder: 'Ingresar nombre',
		type: 'text',
	},
	{
		id: 'contractNumber',
		name: 'contractNumber',
		label: 'No. de contrato',
		placeholder: 'Ingresar nombre',
		type: 'text',
		autoComplete: 'off',
	},
	{
		id: 'location',
		name: 'location',
		label: 'Ubicación',
		placeholder: 'Escribe la dirección/coordenadas',
		type: 'text',
	},
];

export const datePickers = [
	{
		id: 'contractStartDate',
		name: 'contractStartDate',
		label: 'Fecha de inicio',
		placeholder: 'Seleccionar',
		type: 'text',
	},
	{
		id: 'contractEndDate',
		name: 'contractEndDate',
		label: 'Fecha de terminación',
		placeholder: 'Seleccionar',
		type: 'text',
	},
];

export const editDatePickers = [
	{
		id: 'contractStartDate',
		name: 'contractStartDate',
		label: 'Fecha de inicio',
		placeholder: 'Seleccionar',
		type: 'text',
	},
	{
		id: 'contractEndDate',
		name: 'contractEndDate',
		label: 'Fecha de terminación',
		placeholder: 'Seleccionar',
		type: 'text',
	},
	{
		id: 'nextChangesApplicationDate',
		name: 'nextChangesApplicationDate',
		label: 'Selecciona la fecha de aplicación de los cambios',
		placeholder: 'Seleccionar',
		type: 'text',
	},
];

export const descriptionNewProject = [
	{
		id: 'description',
		name: 'description',
		label: 'Descripción',
		placeholder: 'Descripción del proyecto',
		type: 'text',
		multiline: true,
		rows: 4,
	},
];
export const cardsUploadFiles: IFileButtonInterface[] = [
	{
		id: 'uploadJPG',
		icon: 'ImageIcon',
		iconText: 'Subir JPG',
		information:
			'Designa una imagen de portada para el proyecto que estás registrando.',
		accept: 'image/jpeg',
		multiple: false,
	},
	{
		id: 'uploadPDF',
		icon: 'UploadFileOutlinedIcon',
		iconText: 'Subir PDF',
		information: 'Sube el documento correspondiente a este proyecto en formato pdf.',
		accept: 'application/pdf',
		step: 1,
		multiple: true,
	},
];
