/* eslint-disable react-hooks/exhaustive-deps*/
import { DefaultModal, IModal } from '../../../../interfaces/modal.interface';
import { URLS } from '../../../../constants/urls.constants';
import { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../store/modules/snackbar';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import { setLoading } from '../../../../store/modules/loading';

export function useStartSupervisionDetail() {
	const dispatch = useDispatch();
	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const { execute: startSupervision, status } = useApiRequest<
		any,
		IResponsePayload<any>
	>({
		path: URLS.SUPERVISION_EXECUTION.START_TIMING,
		method: 'POST',
	});

	const {
		idRoutine,
		idProject,
		mainSelect,
		options,
		setSubSelect,
		setNewState,
		setStatus,
	} = useContext(SupervisionProjectContext);

	const openModal = (startTiming: boolean) => {
		setModal({
			...modal,
			title:
				'Estás entrando' +
				(mainSelect.optionLabel === 'servicio'
					? ' al servicio '
					: ' al espacio ') +
				options.find((element: any) => element.id === mainSelect.valueLabel)
					?.label,
			open: true,
			labelButton: 'Aceptar',
			hideConfirm: false,
			callback: () => {
				setStatus('pending');
				dispatch(setLoading({ loading: true }));
				startTiming
					? startSupervision(
							mainSelect.optionLabel === 'servicio'
								? { findings: [], activity: mainSelect.valueLabel }
								: { findings: [], funcional: mainSelect.valueLabel },
							`/${idRoutine}?project=${idProject}`
					  )
					: changeStep();
			},
		});
	};

	useEffect(() => {
		if (status === 'success') {
			setModal({ ...modal, open: false });
			dispatch(
				setAlert({
					show: true,
					message: 'Se ha dado inicio a la supervisión',
					severity: 'success',
				})
			);
			setSubSelect({ functionalN1: '', functionalN2: '', service: '' });
			setNewState(2);
		} else if (status === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						'Ha ocurrido un error al iniciar la supervisión, vuelva a recargar.',
					severity: 'error',
				})
			);
		}
	}, [status]);

	const changeStep = () => {
		setSubSelect({ functionalN1: '', functionalN2: '', service: '' });
		setNewState(2);
	};

	return {
		modal,
		openModal,
		setModal,
	};
}
