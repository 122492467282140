export const DESCRIPTION_SUPERVISION = Object.freeze({
	RESUME_SUPERVISION: '¿Deseas continuar la ', // Concatenar fecha
	START_SUPERVISION: '¿Deseas comenzar la ', // Concatenar fecha
	EDIT_SUPERVISION: '¿Deseas editar la ', // Concatenar fecha
	NOT_ACCOMPLISHED: '¿Estás seguro que no cumple?',
	PENDING_TO_VALIDATE: '¿Estás seguro que está pendiente de validación?',
	FINISH_SUPERVISION: 'Terminar supervisión de hoy',
});

export const LABEL_BUTTON_SUPERVISION = Object.freeze({
	RESUME_SUPERVISION: 'Continuar',
	START_SUPERVISION: 'Comenzar',
	EDIT_SUPERVISION: 'Editar',
	NOT_ACCOMPLISHED: 'No cumple',
	PENDING_TO_VALIDATE: 'Si, está pendiente',
	FINISH_SUPERVISION: 'Terminar',
});
