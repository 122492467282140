import { IOptionsMenu } from '../../../interfaces/select.interface';
import { IActivities, IAdaptedActivities } from '../interfaces/get-activities.interface';

export const getActivitiesAdapter = (data: IActivities[]) => {
	const newOptions = data.map((element) => {
		return {
			id: element._id,
			description: element.description,
			rows: element.attributes.map((attribute, index) => {
				return {
					key: attribute,
					value: element.columns[index],
				};
			}),
			frequency: element.frequency,
			isComplete: true,
		};
	});
	return newOptions as IAdaptedActivities[];
};

export const getActivitiesSelectAdapter = (data: IActivities[]) => {
	const newOptions = data.map((element) => {
		return {
			id: element.activity,
			label: element.description,
			value: element.activity,
		};
	});
	return newOptions as IOptionsMenu[];
};
