import { IColumns } from '../../../../../interfaces/table.insterface';

export const columnsDocumentation: IColumns[] = [
	{
		text: 'Nombre',
		nameID: 'fileName',
	},
	{
		text: 'Fecha creación',
		nameID: 'date',
	},
	{
		text: 'Tipo de archivo',
		nameID: 'type',
	},
	{
		text: '',
		nameID: 'acciones',
	},
];
