/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from 'react';
import { FormEditActivities } from '../components/form-edit-activities.component';
import { EditActivity } from '../components/edit-activity.component';
import { EditIG } from '../components/edit-IG.component';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';

export const useEditActivities = () => {
	const [step, setStep] = useState<number>(0);
	const [currentActivity, setCurrentActivity] = useState<IAdaptedValues>({
		id: '',
		mainAttribute: '',
		description: '',
		rows: [],
		frequency: '',
	} as unknown as IAdaptedValues);

	const getComponent = () => {
		switch (step) {
			case 0:
			default:
				return (
					<FormEditActivities
						setStep={setStep}
						setCurrentActivity={setCurrentActivity}
					/>
				);
			case 1:
				return (
					<EditActivity
						setStep={setStep}
						setCurrentActivity={setCurrentActivity}
						currentActivity={currentActivity}
					/>
				);
			case 2:
				return <EditIG setStep={setStep} currentActivity={currentActivity} />;
		}
	};

	return { getComponent };
};
