/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { FilterList } from '@mui/icons-material';
import { Box, Divider, Paper } from '@mui/material';
import { useNotifications } from './hooks/useNotifications';
import TableCustom from '../../components/table-custom.component';
import FilterCustomComponent from '../../components/filter-custom.component';

function NotificationList() {
	const {
		getNotificationsUserDevices,
		getNotificationsUser,
		data,
		setData,
		pagination,
		rowsPerPage,
		filters,
		handleDisplayFilterModal,
		displayFilterModal,
		filterInputs,
		dinamicColumns,
	} = useNotifications();

	useEffect(() => {
		getNotificationsUser();
		getNotificationsUserDevices();
	}, [pagination, rowsPerPage, filters]);

	return (
		<Paper>
			<Box padding={3}>
				<h3>Notificaciones</h3>
				<Divider />
				<TableCustom
					columns={dinamicColumns}
					data={data}
					setData={setData}
					showPagination
					showLimitPerPage
					buttons={[
						{
							icon: FilterList,
							label: 'Filtrar',
							execute: () => handleDisplayFilterModal(),
							type: 'primary',
							width: 150,
							variant: 'outlined',
						},
					]}
					modal={
						<FilterCustomComponent
							open={displayFilterModal}
							data={filterInputs}
							setOpen={handleDisplayFilterModal}
							handleFilter={(args) => {
								getNotificationsUser(args);
							}}
						/>
					}
				/>
			</Box>
		</Paper>
	);
}

export default NotificationList;
