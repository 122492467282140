/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { uniqueId } from '../../../utils/uniqueId';

const defaultHallazgo: IStepTwoModel[] = [
	{
		columns: [''],
		id: uniqueId(),
		attributes: ['name'],
		isComplete: false,
		mainAttribute: 'name',
		name: 'Tipo de hallazgo',
	},
];

const useHallazgos = () => {
	const [hallazgos, setHallazgos] = useState<IStepTwoModel[]>(defaultHallazgo);
	const dispatch = useDispatch<any>();

	const {
		execute: updateHallazgos,
		status: statusUpdateHallazgos,
		value: valueUpdateHallazgos,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.CREATE_PROJECT,
		method: 'PATCH',
	});

	const {
		execute: getHallazgos,
		status: statusGetHallazgos,
		value: valueGetHallazgos,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.CREATE_PROJECT,
		method: 'GET',
	});

	useEffect(() => {
		if (statusUpdateHallazgos === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Se actualizaron los hallazgos correctamente',
					severity: 'success',
				})
			);
		} else if (statusUpdateHallazgos === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message:
						'No se han podido realizar los cambios en los hallazgos correctamente',
					severity: 'error',
				})
			);
		}
	}, [statusUpdateHallazgos]);

	useEffect(() => {
		if (statusGetHallazgos === 'success') {
			dispatch(stopLoading());
			let dataFlag: IStepTwoModel[] = [];
			if (
				valueGetHallazgos?.detail &&
				valueGetHallazgos?.detail?.typeFindings.length > 0
			) {
				dataFlag = (valueGetHallazgos?.detail?.typeFindings || []).map(
					(element: any) => ({
						...defaultHallazgo[0],
						columns: [element],
						isComplete: true,
					})
				);
			} else {
				dataFlag = defaultHallazgo;
			}
			setHallazgos(dataFlag);
		} else if (statusGetHallazgos === 'error') {
			dispatch(stopLoading());
			setHallazgos(defaultHallazgo);
			dispatch(
				setAlert({
					show: true,
					message: 'No se han podido obtener los hallazgos',
					severity: 'error',
				})
			);
		}
	}, [statusGetHallazgos]);

	return {
		updateHallazgos,
		valueUpdateHallazgos,
		statusUpdateHallazgos,
		getHallazgos,
		statusGetHallazgos,
		valueGetHallazgos,
		hallazgos,
		setHallazgos,
	};
};

export default useHallazgos;
