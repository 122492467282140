/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { useApiRequest } from '../../../../hooks/useApiRequest';
import { stopLoading } from '../../../../store/modules/loading';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import { IUpdateSuspension } from '../interfaces/update-suspension.interface';

export function useUpdateSuspension() {
	const dispatch = useDispatch();
	const { undoState } = useContext(SupervisionProjectContext);

	const { execute: executeUpdateSuspension, status: statusUpdateSuspension } =
		useApiRequest<IUpdateSuspension, unknown>({
			path: URLS.SUPERVISION_ROUTINE.UPDATE_SUSPENSIONS,
			method: 'PATCH',
		});

	useEffect(() => {
		if (statusUpdateSuspension === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Suspensión modificada con éxito.',
					severity: 'success',
				})
			);
			undoState();
		} else if (statusUpdateSuspension === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, intente de nuevo.',
					severity: 'error',
				})
			);
		}
	}, [statusUpdateSuspension]);

	return {
		executeUpdateSuspension,
	};
}
