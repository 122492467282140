import { Grid, Paper } from '@mui/material';
import { Button } from '../../../../components/main-button.component';
import confirmationImage from '../../../../assets/imgs/confirmation.png';
import styles from './complete-creation-styles.module.css';
import { useCompleteCreation } from './hooks/useCompleteCreation';

export const CompleteCreation = () => {
	const { handleOnContinue } = useCompleteCreation();

	return (
		<Paper>
			<Grid container spacing={2} style={{ height: '80vh' }}>
				<Grid item sm={12}>
					<div className={styles.mainContainer}>
						<div className={styles.infoContainer}>
							<img
								className={styles.img}
								src={confirmationImage}
								alt="actions"
							/>
							<h3>Supervisión extraordinaria creada con éxito</h3>
							<div className={styles.buttonsContainer}>
								<Button
									label="Terminar"
									onClick={() => {
										//navigate(-1);
										handleOnContinue();
									}}
									variant="solid"
									style={{
										width: '100%',
										backgroundColor: '#0080F9',
									}}
									id="finish-creation"
								/>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};
