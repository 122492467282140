import { FormControlLabel, FormGroup, Grid, styled, useMediaQuery } from '@mui/material';
import {
	ISpatialUnit,
	TSpatialUnit,
} from '../../../interfaces/get-supervision.interfaces';
import { Button } from '../../../../../components/main-button.component';
import CheckboxMUI from '@mui/material/Checkbox';
import theme from '../../../../../config/theme';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSaveActivities } from '../hooks/useSaveActivities';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';

interface IStepOneGD {
	items: TSpatialUnit[];
	rawData: ISpatialUnit[];
	initialValue: string[];
}

const CheckBox = styled(CheckboxMUI)(({ theme }) => ({
	color: 'var(--color-highlight-blue)',
	'&.Mui-checked': {
		color: 'var(--color-highlight-blue)',
	},
}));

export const StepOneAct = ({ items, rawData, initialValue }: IStepOneGD) => {
	const [checkedList, setCheckedList] = useState<Set<number | string>>(new Set());
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const { executeSaveActivities } = useSaveActivities({});

	const onSelect = (index: number | string, checked: boolean) => {
		const collection = new Set(checkedList);

		if (checked) collection.add(index);
		else collection.delete(index);

		setCheckedList(collection);
	};

	const selectAllColumns = () => {
		const collection = new Set(checkedList);

		for (const column of items) {
			for (const row of column) {
				if (!collection.has(row.functional)) collection.add(row.functional);
			}
		}

		setCheckedList(collection);
	};

	useEffect(() => {
		const collection = new Set(initialValue);
		setCheckedList(collection);
	}, [initialValue]);

	const adaptValues = (): { _id: string; applies: boolean }[] => {
		return rawData.map((e) => {
			return {
				_id: e.id.toString(),
				applies: checkedList.has(e.functional) ? true : false,
			};
		});
	};

	return (
		<Grid
			container
			marginTop={2}
			spacing={2}
			style={{
				padding: '8px 0px 0px 8px',
			}}
		>
			<Grid item xs={12}>
				<FormControlLabel
					sx={{ fontSize: '20px' }}
					checked={selectAll}
					value={0}
					control={<CheckBox />}
					label={
						<p
							style={{
								fontSize: '20px',
								margin: 0,
								color: 'var(--color-highlight-blue)',
							}}
						>
							Seleccionar todo
						</p>
					}
					onChange={() => {
						setSelectAll(!selectAll);
						if (!selectAll) selectAllColumns();
						else setCheckedList(new Set());
					}}
				/>
			</Grid>
			<Grid item>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						gap: '40px',
					}}
				>
					{items.map((item, column) => (
						<div key={`formGroup-${column}`}>
							<FormGroup>
								{item.map((prop, row) => (
									<FormControlLabel
										sx={{ fontSize: '20px' }}
										key={`formControl-${column}-${row}`}
										checked={checkedList.has(prop.functional)}
										value={prop.id}
										control={<CheckBox id={`checkbox-${prop.id}`} />}
										label={
											<p style={{ fontSize: '18px', margin: 0 }}>
												{prop.name}
											</p>
										}
										onChange={(_, checked) =>
											onSelect(prop.functional, checked)
										}
									/>
								))}
							</FormGroup>
						</div>
					))}
				</div>
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'flex-end'}
					spacing={2}
				>
					<Grid item>
						<Button
							label="Cancelar"
							onClick={() => navigate(-1)}
							style={{
								backgroundColor: 'var(--color-error-main)',
								width: '150px',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Continuar"
							onClick={() => {
								executeSaveActivities(
									adaptValues(),
									STEPS_SUPERVISION_EXTRAORDINARY.ACT_2
								);
								//dispatch(setState({ state: 3 }));
							}}
							style={{
								backgroundColor: 'var(--color-highlight-blue)',
								width: '150px',
							}}
							disabled={checkedList.size === 0}
							id="btn-continue"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
