/* eslint-disable react-hooks/exhaustive-deps */
import Bowser from 'bowser';
import { useEffect } from 'react';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { stopLoading } from '../../../store/modules/loading';
import { IDevice } from '../../devices/interfaces/device.interface';
import { setUser } from '../../../store/modules/user';
import axios from 'axios';

export const useDevice = () => {
	const browser = Bowser.parse(window.navigator.userAgent);
	const user = useSelector((state: RootState) => state.users);
	const dispatch = useDispatch();

	const { execute: createDevice, status: statusDevice } = useApiRequest<
		IDevice,
		unknown
	>({
		path: URLS.DEVICES.GET_DEVICES,
		method: 'POST',
	});

	useEffect(() => {
		if (statusDevice === 'success') {
			dispatch(stopLoading());
			dispatch(setUser({ ...user, isDeviceCreated: true }));
		} else if (statusDevice === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al registrar el dispositivo',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusDevice]);

	async function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				async function (position) {
					const lat = position.coords.latitude;
					const lon = position.coords.longitude;
					try {
						const response = await axios.get(
							`http://dataservice.accuweather.com/locations/v1/cities/geoposition/search/?apikey=${
								process.env.REACT_APP_LOCATION_TOKEN
							}&q=${lat + ',' + lon}`
						);
						addDevice(response.data.AdministrativeArea.LocalizedName || '-');
					} catch (error) {
						addDevice('-');
					}
				},
				function () {
					addDevice('-');
				}
			);
		}
	}

	const addDevice = (location: string) => {
		createDevice({
			name: browser.browser.name || '',
			memory: '',
			camera: '',
			location: location,
			lastConnection: new Date().toISOString(),
			relationUser: user._id,
			operatingSystem: browser.os.name || '',
		});
	};

	return { getLocation };
};
