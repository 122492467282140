import { useState } from 'react';
import theme from '../../../../config/theme';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IModal, DefaultModal } from '../../../../interfaces/modal.interface';
import { IUser } from '../../../../interfaces/user.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

interface IUseHeaderUser {
	user: IUser;
}

export const useHeaderUser = ({ user }: IUseHeaderUser) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [active, setActive] = useState<string | number>('');
	const [isEditing, setIsEditing] = useState(false);
	const { role } = useSelector((state: RootState) => state.users);

	const handleEdit = () => {
		setIsEditing(!isEditing);
		if (!isEditing) {
			setActive(user.status === 'ACTIVE' ? 1 : 0);
		} else {
			if (active === (user.status === 'ACTIVE' ? 1 : 0)) return;
			navigate('/usuarios/baja-usuario', {
				state: { idUser: user._id, newStatus: active, isDeleted: false },
			});
		}
	};

	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const deleteUser = () => {
		navigate('/usuarios/baja-usuario', {
			state: { idUser: user._id, newStatus: 0, isDeleted: true },
		});
	};

	return {
		isEditing,
		matches,
		modal,
		active,
		role,
		handleEdit,
		setModal,
		setActive,
		deleteUser,
	};
};
