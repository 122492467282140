import { useSelector } from 'react-redux';
import { Paper, Grid } from '@mui/material';
import { RootState } from '../../../../../store';
import styles from './styles/modal-teams-styles.module.css';
import confirmationImage from '../../../../../assets/imgs/confirmation.png';
import { Button } from '../../../../../components/main-button.component';
import useUpdateProject from '../../../../../hooks/useUpdateProject';

export const ModalTeams = () => {
	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);

	const { handleUpdateProject } = useUpdateProject();

	return (
		<Paper>
			<Grid container spacing={2} style={{ height: '80vh' }}>
				<Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
					<div className={styles.mainContainer}>
						<div className={styles.infoContainer}>
							<img
								className={styles.img}
								src={confirmationImage}
								alt="actions"
							/>
							<h3>¿Deseas crear equipos?</h3>
							<div className={styles.buttonsContainer}>
								<Button
									label="Continuar sin equipos"
									onClick={() =>
										handleUpdateProject(
											{ step: '4.2.0' },
											idProject || ''
										)
									}
									style={{
										width: '100%',
									}}
								/>
								<Button
									label="Sí, hacer equipos"
									onClick={() =>
										handleUpdateProject(
											{ step: '4.1.0' },
											idProject || ''
										)
									}
									variant="solid"
									style={{
										width: '100%',
										backgroundColor: '#0080F9',
									}}
								/>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};
