/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useApiRequest, IResponsePayload } from './../../../hooks/useApiRequest';
import { IResponseUser } from './../../../interfaces/new-user.interface';
import { URLS } from './../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from './../../../store/modules/snackbar';

export const useRescheduleRoutine = () => {
	const dispatch = useDispatch();
	const {
		execute: executeReschedule,
		status: statusReschedule,
		value: valueReschedule,
	} = useApiRequest<any, IResponsePayload<IResponseUser>>({
		path: URLS.INCOMING_ROUTINES.RESCHEDULE_ROUTINE,
		method: 'POST',
	});

	useEffect(() => {
		if (statusReschedule === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Se ha actualizado la fecha con exito',
					severity: 'success',
				})
			);
		} else if (statusReschedule === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: valueReschedule?.message
						? valueReschedule?.message
						: 'Ocurrio un error al actualizar fecha',
					severity: 'error',
				})
			);
		}
	}, [statusReschedule]);

	return { executeReschedule, statusReschedule };
};
