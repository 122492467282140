/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { RootState } from '../../../store';
import { IFunctionalResponse } from '../../../interfaces/project.interface';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import { ICollapsable } from '../../../interfaces/collapsable.interface';

const useFunctionalShort = () => {
	const [UFList, setUFList] = useState<IOptionsMenu[]>([]);
	const [EFList, setEFList] = useState<IOptionsMenu[]>([]);
	const [currentEF, setCurrentEF] = useState<string>('');
	const [currentUF, setCurrentUF] = useState<string>('');

	const [rawSelectData, setRawSelectData] = useState<ICollapsable[]>([]);

	const dispatch = useDispatch();
	const { _id } = useSelector((root: RootState) => root.projectBase);

	const {
		execute: getFunctionalNull,
		status: statusFunctionalNull,
		value: valueFunctionalNull,
	} = useApiRequest<unknown, IResponsePayload<IFunctionalResponse>>({
		path: URLS.SUPERVISION_UNIT.GET_FUNCTIONALS_ACTIVITIES,
		method: 'GET',
	});

	const {
		execute: getFunctionalDataValue,
		status: statusFunctionalDataValue,
		value: valueFunciontalDataValue,
	} = useApiRequest<unknown, IResponsePayload<IFunctionalResponse>>({
		path: URLS.SUPERVISION_UNIT.GET_FUNCTIONALS_ACTIVITIES,
		method: 'GET',
	});

	//TO CONTINUE
	useEffect(() => {
		getFunctionalDataNull();
	}, []);

	useEffect(() => {
		if (currentUF) {
			getFunctionalData(currentUF);
		}
	}, [currentUF]);

	useEffect(() => {
		if (statusFunctionalNull === 'success') {
			if (!valueFunctionalNull || valueFunctionalNull.data.length < 1) return;
			const _adapted: IFunctionalResponse[] = valueFunctionalNull.data;
			const resultArray = _adapted.map((i) => ({
				id: i._id,
				isComplete: i.isIndicatorAssociated,
				status: i.isIndicatorAssociated,
				items: [],
				name: i.name,
				value: i.name,
			}));
			setRawSelectData(resultArray);
			setUFList(
				resultArray.map((i) => ({
					id: i.id,
					label: i.value,
					value: i.id,
					status: i.status,
				}))
			);
			if (resultArray.length > 0) {
				setCurrentUF(valueFunctionalNull.data[0]._id);
				if (valueFunctionalNull?.data[0]?._id === currentUF)
					getFunctionalData(valueFunctionalNull.data[0]._id);
			}
			dispatch(stopLoading());
		} else if (statusFunctionalNull === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'No se pudo al obtener la información el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusFunctionalNull]);

	useEffect(() => {
		if (statusFunctionalDataValue === 'success') {
			if (!valueFunciontalDataValue || valueFunciontalDataValue.data.length < 1) {
				if (!currentEF) {
					setCurrentEF(valueFunctionalNull?.data[0]._id || '');
				}
				return;
			}
			const dataFlag = valueFunciontalDataValue.data.map((i) => ({
				id: i._id,
				label: i.name,
				value: i._id,
				status: i.isIndicatorAssociated,
			}));
			setEFList(dataFlag);
			if (dataFlag.length > 0) setCurrentEF(valueFunciontalDataValue.data[0]?._id);
			dispatch(stopLoading());
		} else if (statusFunctionalDataValue === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'No se pudo al obtener la información el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusFunctionalDataValue]);

	const getFunctionalDataNull = () => {
		getFunctionalNull(null, `?project=${_id}&isFunctionalParent=true`);
	};
	const getFunctionalData = (func: string) => {
		getFunctionalDataValue(null, `?project=${_id}&functionalParent=${func}`);
	};

	return {
		getFunctionalDataNull,
		getFunctionalData,
		UFList,
		setUFList,
		EFList,
		setEFList,
		rawSelectData,
		setRawSelectData,
		currentEF,
		setCurrentEF,
		currentUF,
		setCurrentUF,
	};
};

export default useFunctionalShort;
