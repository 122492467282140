import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { NotStarted } from '../pages/not-started/not-started.component';
import { InProgress } from '../pages/in-progress/in-progress.component';
import { STEPS_AUTOMATIC_CHARGE } from '../utils/steps-automatic-charge';
import { StartedLoadData } from '../pages/started-load-data/started-load-data.component';
import { NotStartedTeams } from '../pages/not-started-teams/not-started-teams.component';
import { DataConfirmation } from '../pages/data-confirmation/data-confimation.component';
import { CreatePersonal } from '../../personal/components/create-personal/create-personal.component';
import { CreateTeamsForm } from '../../personal/components/create-teams/components/create-teams-form.component';
import { SupervisionExecution } from '../pages/supervision-execution/supervision-execution.component';
import { Finished } from '../pages/finished/finished.component';

export const useAutomaticCharge = () => {
	const project = useSelector((state: RootState) => state.projectBase);
	const [step, setStep] = useState(project.automaticStep || '0.0.0');

	const getComponent = () => {
		switch (step) {
			case STEPS_AUTOMATIC_CHARGE.NOT_STARTED:
			default:
				return <NotStarted />;
			case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_DATA:
				return <StartedLoadData />;
			case STEPS_AUTOMATIC_CHARGE.RELOAD_DATA:
				return <StartedLoadData consultStatus={false} />;
			case STEPS_AUTOMATIC_CHARGE.IN_PROGRESS_DATA:
			case STEPS_AUTOMATIC_CHARGE.PROJECT_GENERATION:
				return <InProgress />;
			case STEPS_AUTOMATIC_CHARGE.DATA_CONFIRMATION:
			case STEPS_AUTOMATIC_CHARGE.CONFIRMATION_LOAD_PERSONAL:
			case STEPS_AUTOMATIC_CHARGE.CONFIRMATION_LOAD_RELATIONS:
				return <DataConfirmation />;
			case STEPS_AUTOMATIC_CHARGE.STARTED_PERSONAL_DATA_CONFIGURATION:
				return <CreatePersonal />;
			case STEPS_AUTOMATIC_CHARGE.NOT_START_TEAMS:
				return <NotStartedTeams />;
			case STEPS_AUTOMATIC_CHARGE.STARTED_TEAMS_DATA_CONFIGURATION:
				return <CreateTeamsForm isEdit={true} isAutomaticCharge />;
			case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_PERSONAL_DATA:
				return <StartedLoadData />;
			case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_RELATIONS:
				return <StartedLoadData />;
			case STEPS_AUTOMATIC_CHARGE.SUPERVISION_EXECUTION:
				return <SupervisionExecution />;
			case STEPS_AUTOMATIC_CHARGE.FINISHED:
				return <Finished />;
		}
	};

	return { getComponent, setStep };
};
