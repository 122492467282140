import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { IGetPersonal } from '../../../../../../interfaces/user.interface';

export const getPersonalAdaptedData = (
	data: IGetPersonal[],
	currentRol: IAdaptedValues
) => {
	if (data.length === 0) {
		return [
			{
				id: '',
				description: '',
				rows: currentRol?.attributes?.map((attribute) => {
					if (attribute === 'Active') return { key: attribute, value: '1' };
					else return { key: attribute, value: '' };
				}),
				isComplete: false,
				mainAttribute: currentRol.mainAttribute,
				idPersonal: '',
			},
		] as IAdaptedValues[];
	}

	const newOptions = data.map((element) => {
		return {
			id: element.user || '',
			description: element.user,
			rows: currentRol?.attributes?.map((attribute, index) => {
				return {
					key: attribute || '',
					value: getData(index, element),
				};
			}),
			isComplete: true,
			mainAttribute: currentRol.mainAttribute,
			attributes: currentRol.attributes,
			idPersonal: element._id,
		};
	});
	return newOptions as IAdaptedValues[];
};

const getData = (index: number, data: IGetPersonal) => {
	switch (index) {
		case 0:
			return data.user;
		case 1:
			return data.active ? '1' : '0';
		default:
			return data.columns[index];
	}
};
