/* eslint-disable array-callback-return */
import { IOptionsMenu } from '../../../../../../interfaces/select.interface';
import { ITeam } from '../../interfaces/team.interface';

export const getTeamsAdapter = (
	data: ITeam[],
	idProject: string,
	userProject?: IOptionsMenu[]
) => {
	if (data.length === 0) {
		return [
			{
				name: '',
				personals: [''],
				project: idProject,
				isComplete: false,
			},
		];
	}
	const adaptedTeams = data.map((team) => {
		return {
			...team,
			personals: team.personals.length > 0 ? team.personals : [''],
			isComplete: true,
			names:
				(userProject || [])?.length > 0 && userProject
					? getLabelTeam(team.personals, userProject)
					: '',
		};
	});

	return adaptedTeams;
};

export const getTeamsOptions = (
	data: ITeam[],
	userProject: IOptionsMenu[]
): IOptionsMenu[] => {
	const teamsOptions = data.map((team) => {
		return {
			id: team._id,
			value: team._id,
			label: team.name + ': ' + getLabelTeam(team.personals, userProject),
		};
	});

	return teamsOptions as IOptionsMenu[];
};

const getLabelTeam = (personals: string[], userProject: IOptionsMenu[]) => {
	const labelTeam = personals.map((personal) => {
		const userNameProject = userProject.find((uProject) => uProject.id === personal);
		if (userNameProject) {
			return userNameProject.label;
		}
	});

	return labelTeam.join(', ');
};
