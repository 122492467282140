/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoBack } from '../../../../components/go-back-component';
import InputSelect from '../../../../components/select-input.component';
import { IRoutine } from '../../../../interfaces/logic.interface';
import { setState, undoState } from '../../../../store/modules/steps';
import TableCustom from '../../../../components/table-custom.component';
import { columnsActivityRoutines } from './utils/columns-service-select';
import { useByActivityRoutines } from './hooks/useByActivityRoutines';
import { useLocation } from 'react-router-dom';
import { setLoading } from '../../../../store/modules/loading';
import { NewSupervisionListKeys } from '../../interfaces/supervision-list.interface';
import TextInput from '../../../../components/text-input.component';
import {
	optionsMonthsSupervisionHistory,
	optionsTypeSupervisionHistory,
} from '../../utils/options-history.utils';
import { RootState } from '../../../../store';
import { isEmpty } from '../../../../utils/validators';
import { resetPage, setPage } from '../../../../store/modules/page-table';

interface IServiceView {
	areaSelected: IRoutine;
	setRoutine: React.Dispatch<React.SetStateAction<IRoutine>>;
}

export const ServiceView = ({ areaSelected, setRoutine }: IServiceView) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location?.state?.params;
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const handleView = (service: any) => {
		dispatch(setLoading({ loading: true }));
		setRoutine(service);
		dispatch(
			setPage({
				page: dataActivityRoutines.pagination,
				rowsPerPage: dataActivityRoutines.rowsPerPage,
			})
		);
		dispatch(setState({ state: 3 }));
	};

	const {
		dataActivityRoutines,
		setDataActivityRoutines,
		executeActivityRoutines,
		handleGetFiltersParams,
		dataSelect,
		setDataSelect,
	} = useByActivityRoutines(handleView);

	useEffect(() => {
		if (!areaSelected._id) dispatch(undoState);
		dispatch(setLoading({ loading: true }));
		executeActivityRoutines(
			{},
			`?project=${state?.project?._id || _id}&activity=${areaSelected._id}&page=${
				dataActivityRoutines.pagination
			}&recordsByPage=${dataActivityRoutines.rowsPerPage}${
				isEmpty(dataSelect.executionMonth)
					? ''
					: '&executionMonth=' + dataSelect.executionMonth
			}${
				isEmpty(dataSelect.executionYear) && dataSelect.executionYear.length < 4
					? ''
					: '&executionYear=' + dataSelect.executionYear
			}
			&isHistoryPendingRoutine=${
				dataSelect?.isHistoryCompletedRoutine === 'false' ? true : false
			}${handleGetFiltersParams()}`
		);
	}, [dataActivityRoutines.pagination, dataActivityRoutines.rowsPerPage, dataSelect]);

	const handleChange = (e: any, property: NewSupervisionListKeys) => {
		(dataSelect[property] as any) = e.target.value;
		setDataSelect({ ...dataSelect });
		dispatch(resetPage());
		setDataActivityRoutines({
			...dataActivityRoutines,
			pagination: '1',
			rowsPerPage: '10',
		});
	};

	const [year, setYear] = useState<string>(dataSelect.executionYear || '');
	const handleChangeYear = (e: any) => {
		setYear(e.target.value);
		if (e.target.value.length === 4) {
			handleChange(e, 'executionYear');
		}
		if (e.target.value === '') {
			handleChange(e, 'executionYear');
		}
	};

	return (
		<>
			<GoBack />
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								{areaSelected.description}
							</span>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs md={2}>
							<span style={{ fontSize: '15px', fontWeight: 'bold' }}>
								Temporada de supervisión
							</span>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<InputSelect
								value={dataSelect.executionMonth}
								placeholder="Mes"
								optionsMenu={optionsMonthsSupervisionHistory}
								onChange={(e) => handleChange(e, 'executionMonth')}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<TextInput
								id="year"
								placeholder="Año"
								InputProps={{
									sx: { height: '50px', backgroundColor: '#F7F7F7' },
								}}
								value={year}
								name="year"
								onChange={(e) => handleChangeYear(e)}
								type={'number'}
							/>
						</Grid>
						<Grid item sm={1} md={4} display={matches ? 'none' : ''} />
						<Grid item xs sm={3} md={2}>
							<span style={{ fontSize: '15px', fontWeight: 'bold' }}>
								Tipo de supervisión
							</span>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<InputSelect
								value={dataSelect.isHistoryCompletedRoutine}
								placeholder="Tipo supervisión"
								optionsMenu={optionsTypeSupervisionHistory}
								onChange={(e) =>
									handleChange(e, 'isHistoryCompletedRoutine')
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<TableCustom
								columns={columnsActivityRoutines}
								data={dataActivityRoutines}
								setData={setDataActivityRoutines}
								hideHeader
								showLimitPerPage
								showPagination
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
