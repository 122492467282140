/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { isEmpty } from '../../../../../utils/validators';
import { useChangeStep } from '../../../hooks/useChangeStep';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import {
	ISaveActivitiesBody,
	ISaveActivitiesResp,
} from '../interfaces/save-activities.interface';

interface IUseSaveActivities {
	setOptionsChanged?: () => void;
}

export function useSaveActivities({ setOptionsChanged }: IUseSaveActivities) {
	const dispatch = useDispatch();
	const [step, setStep] = useState('');
	const [activities, setActivities] = useState<{ _id: string; applies: boolean }[]>([]);
	const { changeStepSE } = useChangeStep();

	const { idProject, supervisionExtraordinaryData } = useContext(
		CreateSupervisionContext
	);

	const { execute: saveActivities, status: statusSaveActivities } = useApiRequest<
		ISaveActivitiesBody,
		IResponsePayload<ISaveActivitiesResp>
	>({
		path: URLS.SUPERVISION_EXTRAORDINARY.SAVE_ACTIVITIES,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusSaveActivities === 'success') {
			if (!isEmpty(step)) {
				changeStepSE(
					{
						newStep: step,
						extraordinarySupervision:
							supervisionExtraordinaryData.idSupervisionExtraordinary,
					},
					`/${idProject}`
				);
			} else {
				dispatch(stopLoading());
				if (setOptionsChanged) {
					setOptionsChanged();
				}
			}
		} else if (statusSaveActivities === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al guardar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusSaveActivities]);

	useEffect(() => {
		if (!isEmpty(activities)) {
			dispatch(setLoading({ loading: true }));
			saveActivities({ activities: activities });
		}
	}, [activities, step]);

	const executeSaveActivities = (
		idsActivities: {
			_id: string;
			applies: boolean;
			isFunctionalAssociated?: boolean;
		}[],
		step: string
	) => {
		setActivities(idsActivities);
		setStep(step);
	};

	return { executeSaveActivities };
}
