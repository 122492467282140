import { Grid } from '@mui/material';
import Modal from '../../../components/modal.component';
import { IModal } from '../../../interfaces/modal.interface';
import { useSelectFrequency } from './hooks/useSelectFrequency';
import InputSelect from '../../../components/select-input.component';

interface ISelectFrequency {
	modal: IModal;
	setModal: React.Dispatch<React.SetStateAction<IModal>>;
	onSave: (label: string, frequency: { [key: string]: string | null }) => void;
}

export const SelectFrequency = ({ modal, setModal, onSave }: ISelectFrequency) => {
	const { usersData, data, frequenciesOptions, setData, getIsTeam } =
		useSelectFrequency();

	return (
		<Modal
			open={modal.open}
			title={`Guardar actividad ${modal?.title || ''}`}
			cancelLabel="Cancelar"
			confirmLabel="Guardar"
			styleButtonConfirm={{ color: '#0080F9' }}
			width="md"
			onClose={modal.onClose}
			onConfirm={() => onSave(modal?.title || '', data)}
			modal={modal}
			setModal={setModal}
		>
			<Grid container spacing={3} alignItems={'center'}>
				<Grid item xs={4}>
					<span>Selecciona una frecuencia </span>
				</Grid>
				<Grid item xs={8} style={{ textAlign: 'left' }}>
					<InputSelect
						id="select-frequency"
						onChange={(e) =>
							setData({
								...data,
								frequency: e.target.value?.toString() || '',
							})
						}
						optionsMenu={frequenciesOptions}
						value={data.frequency}
						placeholder="Selecciona"
					/>
				</Grid>
				<Grid item xs={4}>
					<span>Selecciona Supervisor</span>
				</Grid>
				<Grid item xs={8} style={{ textAlign: 'left' }}>
					<InputSelect
						id="select-supervisor"
						onChange={(e) =>
							setData({
								...data,
								supervisor: getIsTeam(e.target.value?.toString() || '')
									? null
									: e.target.value?.toString() || '',
								supervisorTeam: getIsTeam(
									e.target.value?.toString() || ''
								)
									? e.target.value?.toString() || ''
									: null,
								executor: getIsTeam(e.target.value?.toString() || '')
									? null
									: e.target.value?.toString() || '',
								executorTeam: getIsTeam(e.target.value?.toString() || '')
									? e.target.value?.toString() || ''
									: null,
							})
						}
						optionsMenu={usersData}
						value={data.supervisor || data.supervisorTeam || ''}
						placeholder="Selecciona"
					/>
				</Grid>
				<Grid item xs={4}>
					<span>Selecciona Sustituto 1</span>
				</Grid>
				<Grid item xs={8} style={{ textAlign: 'left' }}>
					<InputSelect
						id="select-substitute1"
						onChange={(e) =>
							setData({
								...data,
								substitute1: getIsTeam(e.target.value?.toString() || '')
									? null
									: e.target.value?.toString() || '',
								substitute1Team: getIsTeam(
									e.target.value?.toString() || ''
								)
									? e.target.value?.toString() || ''
									: null,
							})
						}
						optionsMenu={usersData}
						value={data.substitute1 || data.substitute1Team || ''}
						placeholder="Selecciona"
					/>
				</Grid>
				<Grid item xs={4}>
					<span>Seleccion Sustituto 2</span>
				</Grid>
				<Grid item xs={8} style={{ textAlign: 'left' }}>
					<InputSelect
						id="select-substitute2"
						onChange={(e) =>
							setData({
								...data,
								substitute2: getIsTeam(e.target.value?.toString() || '')
									? null
									: e.target.value?.toString() || '',
								substitute2Team: getIsTeam(
									e.target.value?.toString() || ''
								)
									? e.target.value?.toString() || ''
									: null,
							})
						}
						optionsMenu={usersData}
						value={data.substitute2 || data.substitute2Team || ''}
						placeholder="Selecciona"
					/>
				</Grid>
			</Grid>
		</Modal>
	);
};
