import { TSpatialUnit } from '../../../interfaces/get-supervision.interfaces';
import { IFunctionalsExtraordinary } from '../interfaces/get-functionals.interface';

export const functionalsAdapter = (data: IFunctionalsExtraordinary[]): TSpatialUnit[] => {
	const newData: TSpatialUnit[] = data.map((element: IFunctionalsExtraordinary) => {
		return {
			id: element._id,
			name: element.description,
			value: element.applies,
			functional: element.functional,
		} as unknown as TSpatialUnit;
	});
	return newData;
};
