import { getDateLocalMX } from '../../../utils/dates';
import EditIcon from '@mui/icons-material/Edit';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { IProject } from '../../../interfaces/project.interface';

interface IProyectoReturn extends IProject {
	documents: string | JSX.Element;
}

export const projectsAdapter = (
	data: IProject[],
	handleEdit: (project: IProject) => void
): IProyectoReturn[] => {
	const projects: IProyectoReturn[] = (data || []).map((project) => {
		return {
			...project,
			startDate: project.contractStartDate
				? getDateLocalMX(project.contractStartDate)
				: '',
			endDate: project.contractEndDate
				? getDateLocalMX(project.contractEndDate)
				: '',
			documents: 'Ver documentos',
			acciones: (
				<ButtonIconSmall
					text="Editar"
					type="primary"
					icon={<EditIcon />}
					onPress={() => handleEdit(project)}
					disabled={project.step === 'x'}
				/>
			),
		};
	});
	return projects;
};
