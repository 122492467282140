/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { INewUser } from '../../../interfaces/new-user.interface';
import { defaultValueUser, IUser } from '../../../interfaces/user.interface';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { CompanyInfoComponent } from '../components/company-info.component';
import { PerformanceHistory } from '../components/performance-history';
import { PersonalInfoComponent } from '../components/personal-info.component';
import { SelectChangeEvent, useMediaQuery } from '@mui/material';
import theme from '../../../config/theme';
import { RootState } from '../../../store';
import { isCoordinador, isDirector } from '../../../utils/permissions.utils';

export function useUserProfile() {
	const { role } = useSelector((state: RootState) => state.users);
	const [step, setStep] = useState(0);
	const [userInfo, setUserInfo] = useState<IUser>({
		...defaultValueUser,
	});
	const [temporalData, setTemporalData] = useState<IUser>({
		...defaultValueUser,
	});
	const location = useLocation();
	const state = location.state as string;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [currentTab, setCurrentTab] = useState<number>(0);
	const tabItems =
		isDirector(role) || isCoordinador(role)
			? [
					{ label: 'Información personal', value: 0 },
					{ label: 'Información de la empresa', value: 1 },
			  ]
			: [
					{ label: 'Información personal', value: 0 },
					{ label: 'Información de la empresa', value: 1 },
					{ label: 'Historial de desempeño', value: 2 },
			  ];

	useEffect(() => {
		if (!state) {
			navigate(-1);
		}
		dispatch(setLoading({ loading: true }));
		getUser({}, `/${state}`);
	}, []);

	const {
		execute: getUser,
		status: statusUser,
		value: dataUser,
	} = useApiRequest<unknown, IUser>({
		path: URLS.USERS.GET_USERS,
		method: 'GET',
	});

	const {
		execute: updateUser,
		status: statusUpdateUser,
		value: dataUpdateUser,
	} = useApiRequest<INewUser, IUser>({
		path: URLS.USERS.GET_USERS,
		method: 'PUT',
	});

	useEffect(() => {
		if (statusUser === 'success') {
			setUserInfo(dataUser || defaultValueUser);
			dispatch(stopLoading());
		} else if (statusUser === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consulta el usuario',
					severity: 'error',
				})
			);
			navigate(-1);
			dispatch(stopLoading());
		}
	}, [statusUser]);

	useEffect(() => {
		if (statusUpdateUser === 'success') {
			setUserInfo(dataUpdateUser || defaultValueUser);
			dispatch(stopLoading());
		} else if (statusUpdateUser === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el usuario',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateUser]);

	const handleSetData = (
		e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | number | null>
	) => {
		const { name, value } = e.target;
		setTemporalData({ ...temporalData, [name]: value });
	};

	const getComponent = () => {
		switch (step) {
			case 0:
				return (
					<PersonalInfoComponent
						user={userInfo}
						temporalData={temporalData}
						setTemporalData={setTemporalData}
						handleSetData={handleSetData}
						updateUser={updateUser}
					/>
				);
			case 1:
				return (
					<CompanyInfoComponent
						user={userInfo}
						temporalData={temporalData}
						setTemporalData={setTemporalData}
						handleSetData={handleSetData}
						updateUser={updateUser}
					/>
				);
			case 2:
				return <PerformanceHistory user={userInfo} />;
		}
	};

	return {
		userInfo,
		currentTab,
		tabItems,
		matches,
		setCurrentTab,
		setStep,
		getComponent,
	};
}
