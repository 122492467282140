/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { uniqueId } from '../../../utils/uniqueId';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import { isEmpty } from '../../../utils/validators';

interface IGet {
	setActivities: TReactDispatch<IStepTwoModel[]>;
	setIsShow: TReactDispatch<boolean>;
}

const useGetActivity = (props: IGet) => {
	const { setActivities, setIsShow } = props;
	const [isNext1Disabled, setIsNext1Disabled] = useState<boolean | null>(null);

	const dispatch = useDispatch<any>();
	const { execute, status, value } = useApiRequest<
		any,
		IResponsePayload<IStepTwoModel>
	>({
		path: URLS.PROJECT.ACTIVITY,
		method: 'GET',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (!isEmpty(value?.data)) {
				const dataFlag = (value?.data || []).map((ele) => ({
					...ele,
					id: uniqueId(),
					isComplete: ele?.columns?.every((i) => !isEmpty(i)),
				}));
				setActivities(dataFlag);
				setIsShow(true);
				setIsNext1Disabled(true);
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, isNext1Disabled, setIsNext1Disabled, value };
};

export default useGetActivity;
