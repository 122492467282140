import { IColumns } from '../../../interfaces/table.insterface';

export const columns: IColumns[] = [
	{
		text: 'Nombre del dispositivo',
		nameID: 'name',
	},
	{
		text: 'Memoria',
		nameID: 'memory',
	},
	{
		text: 'Cámara',
		nameID: 'camera',
	},
	{
		text: 'Ubicación',
		nameID: 'location',
	},
	{
		text: 'Última conexión',
		nameID: 'lastConnection',
	},
];
