/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from 'react';
import VerticalStepper from './vertical-stepper-fixed.component';
import renderItem from './styles/render-item-styles.module.css';
import { SxProps } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { isEmpty } from '../utils/validators';
import { ICheck } from './table-vertical-stepper-fixed.component';
import Alert from './info.alert.component';
import { IOptionsMenu } from '../interfaces/select.interface';
import AltaCSV, { IResponseData } from './alta-csv';
import ContinueButton from './custom-btn.component';
import DashedBtn from './dashed-button.component';
import CsvButton from './csv-button';
import { DeleteBtn } from './button-delete.component';
import { uniqueId } from '../utils/uniqueId';
import {
	IStepTwoModel,
	IRenderItemProps,
	IStepProps,
} from '../interfaces/stepTwo.interface';
import InputSelect from './select-input.component';
import SaveButton from './save-button.component';
import { useDispatch } from 'react-redux';
import { setLoading } from '../store/modules/loading';
import useDeleteElement from '../hooks/useDeleteElement';
import { setAlert } from '../store/modules/snackbar';

const sxContainerDefault: SxProps = {
	flexWrap: 'nowrap',
	alignItems: 'flex-start',
};

const sxElementDefault: SxProps = {
	position: 'sticky !important',
	left: '-5px',
	zIndex: 1,
	height: '100%',
	background: '#fff',
	minWidth: '5rem',
};

interface ITitle {
	item: ICheck;
	idx?: number;
	hiddeNumbers?: boolean;
}
const Title = (props: ITitle) => {
	const { item, idx, hiddeNumbers } = props;
	return (
		<Grid
			item
			xs={2}
			sx={{
				height: '100%',
				backgroundColor: '#fff',
				position: 'sticky !important',
				left: '70px',
				background: '#fff',
				zIndex: 1,
				marginRight: '2rem',
				paddingLeft: '15px !important',
			}}
		>
			<p className={renderItem.renderTitle} style={{ minWidth: 'auto' }}>
				{item.name} {!hiddeNumbers && (idx || 0) + 1}
			</p>
		</Grid>
	);
};

interface IItem {
	itemProps: IRenderItemProps;
	renderItem?: (props: IRenderItemProps) => React.ReactNode;
	handleDelete: null | ((id: IStepTwoModel, index: number) => void);
	hiddeDelete?: boolean;
}

const ItemRendered = (props: IItem, index: number) => {
	const { hiddeDelete, renderItem, handleDelete, itemProps } = props;
	return (
		<>
			{renderItem && renderItem({ ...itemProps })}
			{!hiddeDelete && (
				<DeleteBtn
					btn-type="delete"
					sx={{ display: 'flex' }}
					disabled={itemProps.disabled || !handleDelete}
					onClick={() =>
						handleDelete && handleDelete(itemProps.item, itemProps.column)
					}
				/>
			)}
		</>
	);
};

type TAllowed = {
	save: boolean;
	continue: boolean;
};
const StepTwoContent = (props: IStepProps) => {
	const {
		id,
		data,
		labelItem,
		subContent,
		ItemContent,
		setData,
		onContinue,
		isDone,
		onCVSUpload,
		showAltaCsv = false,
		hiddeCsvButton: hiddenCsvButton = false,
		hiddeSaveButton: hiddenSaveButton = false,
		showAlwaysContinueButton,
		hiddeVerticalNumbers: hiddeNumbers,
		hiddeAddButton,
		hiddeDeleteItemsButtons,
		setShowAltaCsv,
		renderItem,
		sxContainer = sxContainerDefault,
		sxElement = sxElementDefault,
		selectData,
		onSelectChange,
		onSave,
		url,
		disabledRows = [],
	} = props;
	const [selectOptions, setSelectOptions] = useState<IOptionsMenu[]>([]);
	const [selectValue, setSelectValue] = useState<string | number | null>('');
	const [isDelete, setIsDelete] = useState<boolean>(false);

	const isNextAllowed: TAllowed = useMemo(() => {
		const _some = data.some((i) => !i.isComplete);
		if (showAlwaysContinueButton)
			return {
				continue: true,
				save: true,
			};
		if (selectData)
			return {
				continue: selectOptions.every((i) => !!i.status),
				save: !_some,
			};
		return {
			continue: !_some,
			save: !_some,
		};
	}, [data, selectOptions, selectData]);

	const { handleDeleteElement } = useDeleteElement({
		data,
		setData,
	});
	const dispatch = useDispatch();

	const label = useMemo(() => {
		if (!data || data.length === 0) return '';
		return String(data[0].name || '').toLowerCase();
	}, [data]);

	useEffect(() => {
		if (!selectData) return;
		setSelectOptions(selectData.data.options);
		if (selectData.data.options.length > 1)
			setSelectValue(selectData.data.options[0].id);
	}, []);

	useEffect(() => {
		if (!onSelectChange || isEmpty(selectValue)) return;
		onSelectChange(selectValue);
	}, [selectValue]);

	useEffect(() => {
		if (!selectData) return;
		setSelectOptions(selectData.data.options);
	}, [selectData?.data.options]);

	useEffect(() => {
		if (!isEmpty(selectValue) || selectOptions.length < 1) return;
		setSelectValue(String(selectOptions[0].id));
	}, [selectOptions]);

	const handleTable = (column: number, row: number, value: any) => {
		const copy_data = [...data];
		const copy_column = copy_data[column];
		//console.log({ copy_column });
		copy_column.columns.splice(row, 1, value);
		if (copy_column.columns.every((el) => !isEmpty(el)))
			copy_column.isComplete = true;
		else copy_column.isComplete = false;
		copy_data.splice(column, 1, copy_column);
		if (setData) setData(copy_data);
	};

	const handleAddSpace = () => {
		const { attributes, name, mainAttribute } = data[0];
		const new_element: IStepTwoModel[] = [
			...data,
			{
				id: uniqueId(),
				attributes: attributes,
				columns: attributes.map((_) => ''),
				isComplete: false,
				name: name,
				mainAttribute: mainAttribute,
			},
		];
		if (setData) setData(new_element);
	};

	const handleUpload = () => {
		if (isDone) return;
		if (onCVSUpload) onCVSUpload(data);
	};

	const handleDelete = (value: IStepTwoModel, index: number) => {
		setIsDelete(true);
		setTimeout(() => {
			if (data.length <= 1) return;
			if (value._id) {
				dispatch(setLoading({ loading: true }));
				setIsDelete(false);
				return handleDeleteElement(url, value._id);
			}
			const copy_data = [...data];
			copy_data.splice(index, 1);
			if (setData) setData(copy_data);
			setIsDelete(false);
		}, 700);
	};

	const handleCsvLoaded = (p_data: IResponseData) => {
		const { parsed } = p_data;
		if (parsed.length <= 0) {
			if (setShowAltaCsv) setShowAltaCsv(false);
			return;
		}
		const savedItems = data.filter((e) => e._id);
		const adapted: IStepTwoModel[] = parsed.map((i) => {
			const { columns, attributes, mainAttribute, name } = data[0];
			const _values = Object.values(i);
			return {
				id: uniqueId(),
				isComplete: true,
				attributes: attributes,
				mainAttribute,
				name,
				columns: columns.map((_, idy) => _values[idy]),
			};
		});
		if (setData) setData([...savedItems, ...adapted]);
		if (setShowAltaCsv) setShowAltaCsv(false);
	};

	const isDataComplete = (): boolean => {
		if (data.some((i) => !i.isComplete)) {
			dispatch(
				setAlert({
					show: true,
					severity: 'error',
					message: 'Faltan columnas por llenar',
				})
			);
			return false;
		}
		return true;
	};

	const handleSave = () => {
		if (isDataComplete() && onSave) onSave(data, String(selectValue));
	};

	const handleContinue = () => {
		if (isDataComplete() && onContinue) onContinue(data, String(selectValue));
	};

	if (showAltaCsv) {
		return (
			<AltaCSV
				maxColumns={data.length > 0 ? data[0].attributes.length : 0}
				onCancel={() => {
					if (setShowAltaCsv) setShowAltaCsv(false);
				}}
				onContinue={handleCsvLoaded}
			/>
		);
	}

	return (
		<>
			<Grid container sx={{ marginTop: '2rem' }} rowSpacing={2}>
				{labelItem && (
					<Grid item md={12} xl={6}>
						<Alert severity="info" sx={{ margin: 0 }}>
							{labelItem}
						</Alert>
					</Grid>
				)}
				{!hiddenCsvButton && (
					<>
						<Grid item xs={1} />
						<CsvButton handleUpload={handleUpload} isDone={isDone} />
					</>
				)}
			</Grid>
			{selectData && (
				<Grid
					container
					columnGap={1}
					marginTop={4}
					marginBottom={1}
					marginLeft={{ xs: 0, xl: 2 }}
					marginRight={{ xs: 0, xl: 2 }}
					sx={{ flexWrap: 'nowrap' }}
				>
					<Grid item xs={12} sm={5} md={5} lg={3} xl={3}>
						<h4
							style={{
								margin: 0,
								padding: '0.5rem 0 0 0.5rem',
								fontWeight: 600,
							}}
						>
							{selectData.label}
						</h4>
					</Grid>
					<Grid item xs={12} sm={7} md={7} lg={6} xl={6}>
						<InputSelect
							id="select-data-dinamic"
							onChange={(e) => setSelectValue(e.target.value)}
							optionsMenu={selectOptions}
							value={selectValue}
							withStatus={!!selectData.data.withStatus || true}
						/>
					</Grid>
				</Grid>
			)}
			{subContent && subContent}
			<div style={{ overflow: 'auto' }}>
				<VerticalStepper
					gridKey="criterios-table"
					sxContainer={sxContainer}
					sxElement={sxElement}
					items={data || []}
					renderTitle={(item, idx) => (
						<Title item={item} idx={idx} hiddeNumbers={hiddeNumbers} />
					)}
					renderItem={(item, row) =>
						renderItem ? (
							<ItemRendered
								handleDelete={data.length > 1 ? handleDelete : null}
								itemProps={{
									column: row,
									item,
									disabled:
										isDelete ||
										isDone ||
										!!disabledRows.includes(row),
									handleTable,
									items: data,
								}}
								renderItem={renderItem}
								hiddeDelete={hiddeDeleteItemsButtons}
							/>
						) : (
							ItemContent && ItemContent(item, row)
						)
					}
				/>
			</div>
			{!isDone && !hiddeAddButton && (
				<div style={{ width: '100%', display: 'flex', margin: '2rem 0' }}>
					<DashedBtn
						onClick={handleAddSpace}
						width="100"
						text={`Agregar ${label}`}
					/>
				</div>
			)}
			<Grid
				container
				sx={{ width: '100%', marginTop: '2rem', marginLeft: 0 }}
				spacing={2}
			>
				{!hiddenSaveButton && <Grid item sm={12} md={6} lg={8} xl={8} />}
				{!hiddenSaveButton && (
					<Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
						<SaveButton
							id={`uf-guardar-btn-${id}`}
							disabled={!isNextAllowed.save || isDone}
							onClick={handleSave}
						/>
					</Grid>
				)}
				<Grid
					item
					xs={12}
					sm={6}
					md={3}
					lg={2}
					xl={2}
					marginLeft={hiddenSaveButton ? 'auto' : 0}
				>
					<ContinueButton
						id={id}
						disabled={!isNextAllowed.continue || isDone}
						onClick={handleContinue}
						customSx={{
							width: '100%',
						}}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default StepTwoContent;
