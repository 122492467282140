/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../../../store';
import { useGetActivities } from './useGetActivities';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { useChangeStep } from '../../../hooks/useChangeStep';
import { StepOneAct } from '../components/step-one.component';
import { StepTwoAct } from '../components/step-two.component';
import { setLoading } from '../../../../../store/modules/loading';
import { SplitArrayInNumbers } from '../../../../../utils/splitArray';
import { ISpatialUnit } from '../../../interfaces/get-supervision.interfaces';
import { ICreationPage } from '../../../../../interfaces/creation-page-interfaces';
import { statusCreation } from '../../../constants/creation-supervision.component';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';

export function useActivities() {
	const { idProject, modal, supervisionExtraordinaryData, setModal } = useContext(
		CreateSupervisionContext
	);
	const [activitiesChecked, setActivitiesChecked] = useState<string[]>([]);
	const { activityBase } = useSelector((state: RootState) => state.projectBase);

	const { activities, getActivities } = useGetActivities({
		setActivitiesChecked: setActivitiesChecked,
	});
	const dispatch = useDispatch();
	const { changeStepSE } = useChangeStep();

	const [steps, setSteps] = useState<{
		step1: boolean;
		step2: boolean;
	}>({ step1: true, step2: false });

	const ActivitiesSteps: ICreationPage = {
		header: {
			label: 'Estado de creación',
			stepper: {
				activeStep: 1,
				items: statusCreation,
				completed: {},
			},
		},
		stepOne: {
			title: `1. Elige ${activityBase?.name ?? 'actividad'}`,
			show: steps.step1,
			Children: (
				<StepOneAct
					items={SplitArrayInNumbers(activities, 5)}
					rawData={activities as unknown as ISpatialUnit[]}
					initialValue={activitiesChecked}
				/>
			),
		},
		stepTwo: {
			title: '2. Selecciona los espacios para los que aplica',
			show: steps.step2,
			Children: <StepTwoAct />,
			showExitButon: true,
			onExit: () => {
				setModal({
					...modal,
					open: true,
					callback: () => {
						dispatch(setLoading({ loading: true }));
						changeStepSE(
							{
								newStep: STEPS_SUPERVISION_EXTRAORDINARY.ACT_1,
								extraordinarySupervision:
									supervisionExtraordinaryData.idSupervisionExtraordinary,
							},
							`/${idProject}`
						);
					},
				});
			},
		},
	};

	const undoStep = () => {
		setModal({
			...modal,
			open: true,
			callback: () => {
				dispatch(setLoading({ loading: true }));
				changeStepSE(
					{
						newStep: STEPS_SUPERVISION_EXTRAORDINARY.DG_2,
						extraordinarySupervision:
							supervisionExtraordinaryData.idSupervisionExtraordinary,
					},
					`/${idProject}`
				);
			},
		});
	};

	useEffect(() => {
		if (supervisionExtraordinaryData.step === STEPS_SUPERVISION_EXTRAORDINARY.ACT_1) {
			return setSteps({ step1: true, step2: false });
		}
		setSteps({ step1: false, step2: true });
	}, [supervisionExtraordinaryData.step]);

	useEffect(() => {
		if (supervisionExtraordinaryData.step === STEPS_SUPERVISION_EXTRAORDINARY.ACT_1) {
			dispatch(setLoading({ loading: true }));
			getActivities(
				{},
				`?project=${idProject}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&activity=null`
			);
		}
	}, []);

	return { ActivitiesSteps, undoStep };
}
