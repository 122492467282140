/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper } from '@mui/material';
import TableCustom from '../../../components/table-custom.component';
import { columnsReports } from '../utils/table-columns.utils';
import { useEffect } from 'react';
import { GoBack } from '../../../components/go-back-component';
import { useLocation } from 'react-router-dom';
import { useAllReports } from '../hooks/useAllReports';
import { useDispatch } from 'react-redux';
import { setValuePP } from '../../../store/modules/profile-project';

export const SupervisionViewReports = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location.state.params;
	const { dataReports, setDataReports, executeAllReports } = useAllReports();

	useEffect(() => {
		executeAllReports(
			{},
			`/?routine=${state?.routine}&page=${dataReports?.pagination}&recordsByPage=${dataReports?.rowsPerPage}`
		);
	}, []);

	return (
		<>
			{state.planeacion ? (
				<GoBack action={() => dispatch(setValuePP({ step: 3 }))} />
			) : (
				<GoBack />
			)}
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Historial de supervisión
							</span>
							<br />
							<span style={{ fontSize: '17px', color: '#707070' }}>
								{state.routine.routineDescription}
							</span>
						</Grid>

						<Grid item xs={12}>
							<TableCustom
								columns={columnsReports}
								data={dataReports}
								setData={setDataReports}
								hideHeader
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
