/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../config/theme';
import { useGetIndicators } from './useGetIndicators';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { IIndicatorModel } from '../../../../interfaces/project.interface';
import { useCreateIndicator } from './useCreateIndicator';

interface IUseEditFormGeneralIndicators {
	idActivity: string;
	isGeneralIndicator: boolean;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentIndicator: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	structureData: IIndicatorModel;
}

export const useEditFormGeneralIndicators = ({
	isGeneralIndicator,
	structureData,
	idActivity,
	setStep,
	setCurrentIndicator,
}: IUseEditFormGeneralIndicators) => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { indicatorsOptions, setIndicatorsOptions } = useGetIndicators({
		idActivity,
		isGeneralIndicator,
	});

	const { handleCreatestatusCreateIndicator } = useCreateIndicator({
		indicatorsOptions,
		setIndicatorsOptions,
	});

	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	const handleNewIndicator = () => {
		const temporalIndicator = indicatorsOptions;
		temporalIndicator.push({
			id: '',
			description: '',
			rows: [],
			isComplete: false,
		} as unknown as IAdaptedValues);
		setIndicatorsOptions([...temporalIndicator]);
	};

	const onSave = (value: string) => {
		const newIndicator = isGeneralIndicator
			? {
					project: idProject,
					name: structureData.name,
					mainAttribute: structureData.mainAttribute,
					attributes: structureData.attributes,
					columns: structureData.attributes.map((element, index) => {
						return index === 0 ? value : '';
					}),
					isGeneral: isGeneralIndicator,
			  }
			: {
					project: idProject,
					activity: idActivity,
					columns: structureData.attributes.map((element, index) => {
						return index === 0 ? value : '';
					}),
					isGeneral: isGeneralIndicator,
			  };
		handleCreatestatusCreateIndicator(newIndicator);
	};

	const onEdit = (item: IAdaptedValues) => {
		setStep(2);
		setCurrentIndicator(item);
	};

	const onDelete = (rowIndex: number) => {
		const newIndicators = indicatorsOptions;
		newIndicators.splice(rowIndex, 1);
		setIndicatorsOptions([...newIndicators]);
	};

	return {
		indicatorsOptions,
		matches,
		navigate,
		handleNewIndicator,
		onSave,
		onEdit,
		onDelete,
	};
};
