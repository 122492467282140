import { TCriteriosTable, ITable } from '../interfaces/criterios.interface';
import { IVerticalStepperAttributes } from '../interfaces/vertical.steper.attributes.interface';

interface ICreateCriterio {
	id: number;
	attr: IVerticalStepperAttributes[];
	secondaryAttrs: Array<{ key: number | string; label: string }>;
}

export const createCriterio = (props: ICreateCriterio): TCriteriosTable => {
	const { attr, secondaryAttrs, id } = props;
	const [criterio, mainAttr] = attr;

	const table: TCriteriosTable = [];
	table.push(
		addElement({
			id: id,
			criterio: criterio,
			mainAttr: mainAttr,
			rows: secondaryAttrs,
		})
	);
	return table;
};

interface IAddElement {
	id: number;
	criterio: IVerticalStepperAttributes;
	mainAttr: IVerticalStepperAttributes;
	rows: Array<{ key: number | string; label: string }>;
}

export const addElement = (props: IAddElement): ITable => {
	const { criterio, mainAttr, rows, id } = props;
	return {
		//label: `${criterio.value} ${index + 1}`,
		id: id,
		label: criterio.value,
		isComplete: false,
		rows: [
			{
				key: mainAttr.value,
				value: '',
			},
			...rows.map((i) => ({
				key: i.label,
				value: '',
			})),
		],
	};
};

interface ICloneElement {
	data: TCriteriosTable;
	id: number;
}
export const cloneElement = (props: ICloneElement): ITable => {
	const { data, id } = props;
	const new_data: ITable = {
		//label: `${data[0].label} ${index}`,
		id: id,
		label: data[0].label,
		isComplete: false,
		rows: data[0].rows.map((i) => ({
			key: i.key,
			value: '',
			isRequired: i.isRequired || false,
		})),
	};
	return new_data;
};
