/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generateZIP } from '../../../../../utils/generateZIP';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { stopLoading } from '../../../../../store/modules/loading';
import { IDowloadZip } from '../interfaces/download-zip.interface';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';

export const useDownloadZip = () => {
	const dispatch = useDispatch();

	const {
		execute: executeGetZip,
		status: statusGetZip,
		value: valueGetZip,
	} = useApiRequest<IDowloadZip, IResponsePayload<string>>({
		path: URLS.REPORTS.DOWNLOAD_ZIP,
		method: 'POST',
	});

	useEffect(() => {
		if (statusGetZip === 'success') {
			dispatch(stopLoading());
			if (valueGetZip?.detail)
				generateZIP(valueGetZip?.detail, 'zip', 'Boletín de supervisión');
		} else if (statusGetZip === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al descargar el documento',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetZip]);

	return { executeGetZip };
};
