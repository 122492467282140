import { Grid, Paper } from '@mui/material';
import { cards } from './utils/cards.utils';
import { RemoveRedEye } from '@mui/icons-material';
import { BodyCard } from './components/body-card.component';
import DataTable from './components/table-general-info.component';
import { CardComponent } from '../user-profile/components/card.component';
import ButtonIconSmall from '../../components/button-icon-small.component';
import { useGetSummaryTasks } from './hooks/useGetSummaryTasks';
import { tablesSummary } from './utils/tables.utils';

export const SummaryTasks = () => {
	const { navigate, dataDashboard, dataTable } = useGetSummaryTasks();

	return (
		<Grid container display={'flex'} spacing={3}>
			<Grid item xs={12} lg={6}>
				<Paper style={{ padding: '30px' }}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<span
								style={{
									fontSize: '18px',
									fontWeight: 'bold',
								}}
							>
								Tareas asignadas
							</span>
						</Grid>
						<Grid
							item
							xs={6}
							justifyContent={'flex-end'}
							style={{ display: 'flex' }}
						>
							<ButtonIconSmall
								text="Ver tareas asignadas"
								onPress={() => navigate('ver-tareas-base')}
								style={{
									textTransform: 'none',
									backgroundColor: '#cbe5fb',
									width: '150px',
								}}
								icon={<RemoveRedEye />}
								type="info"
							/>
						</Grid>
						{cards.map((card, index) => {
							return (
								<Grid item xs={12} lg={6} key={index}>
									<CardComponent
										title={card.title}
										subtitle={card.subtitle}
										stylesContainer={card.stylesContainer}
										body={
											<BodyCard
												title={card.title}
												subtitle={card.subtitle}
												stats={dataDashboard[card.name || '']}
												stylesColorFont={card.stylesColorFont}
												icon={card.icon}
											/>
										}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Paper>
			</Grid>
			{tablesSummary.map((table) => {
				return (
					<Grid item xs={12} lg={3} key={`table-${table.name}`}>
						<Paper
							style={{
								padding: '30px',
								maxHeight: '80vh',
								overflow: 'auto',
							}}
						>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<span
										style={{ fontSize: '18px', fontWeight: 'bold' }}
									>
										{table.title}
									</span>
									<br />
									<span style={{ fontSize: '14px' }}>
										{table.subtitle}
									</span>
								</Grid>
								<Grid item xs={12}>
									<DataTable
										headers={table.columns}
										data={dataTable[table.name] || []}
										align="center"
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				);
			})}
		</Grid>
	);
};
