export interface ISupervisionHistoryContext {
	dataSelect: {
		[key: string]: string;
	};
	setDataSelect: React.Dispatch<React.SetStateAction<any>>;
}

export const DefaultDataSelect = {
	executionMonth: '',
	executionYear: '',
	isHistoryCompletedRoutine: 'false',
};
