import StartDate from '../../../../components/select-date-modal';
import { useConfirmProgress } from '../../hooks/useConfirmProgress';

export const SupervisionExecution = () => {
	const { confirmProgress } = useConfirmProgress();

	const sendExecutionDate = (date: Date) => {
		confirmProgress({ executionDate: date });
	};

	return <StartDate onContinue={sendExecutionDate} />;
};
