import { Paper, Box, Grid } from '@mui/material';
import styles from './styles/stepper-styles.module.css';
import { Button } from '../../../components/main-button.component';
import DimensionesIcon from '../../../assets/icons/dimensiones.icon';
import { HeaderEdit } from '../../../components/header-edit.component';
import { EditItemRow } from '../../../components/edit-item-row.component';
import VerticalStepper from '../../../components/vertical-stepper-fixed.component';
import { useListFunctionalLvl1 } from './hooks/useListFunctionalsLvl1';

interface IListFunctionalsLvl1 {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setFunctionalParent: React.Dispatch<React.SetStateAction<string>>;
}

export const ListFunctionalsLvl1 = ({
	setStep,
	setFunctionalParent,
}: IListFunctionalsLvl1) => {
	const { functionalsOptions, matches, onEdit, navigate } = useListFunctionalLvl1({
		isLvlOne: true,
		setStep,
		setFunctionalParent,
	});

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					<HeaderEdit
						section="Dimensiones geográficas"
						title="1. Selecciona tu espacio nivel 1"
						alertText="Selecciona tu espacio nivel 1 al cual deseas modificar sus niveles 2. Da clic en el botón el ícono correspondiente"
						icon={<DimensionesIcon size="23px" />}
					/>
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={functionalsOptions}
							renderItem={(item, column) => (
								<EditItemRow
									item={item}
									column={column}
									onEdit={onEdit}
									onSave={() => null}
									onDelete={() => null}
								/>
							)}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Salir"
							onClick={() => navigate(-1)}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
