/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import {
	activitiesAdapter,
	supervisorAdapter,
} from '../../supervision-project/components/adapters/summary-routine.adapter';
import { ISummaryRoutineResp } from '../../supervision-project/components/summary-routine/interfaces/summary-routine.interface';
import { getRoutineDescriptionByType } from '../../../utils/get-title-routines';
import { ITypeRoutine } from '../../../constants/routines.constants';

export function useSummaryDetail() {
	const [routine, setRoutine] = useState({
		_id: '',
		status: '',
		supervisorName: '',
		unitInfo: '',
	});
	const [graphic, setGraphic] = useState({
		title: '',
		percentage: 0,
		completedTasks: 0,
		pendingSupervision: 0,
		pendingValidation: 0,
		suspendedTasks: 0,
	});

	const { data: dataActivites, setData: setDataActivities } = useTableCustom<Object>();
	const { data: supervisor, setData: setSupervisor } = useTableCustom<Object>();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		execute: executeGetSummaryRoutine,
		status: statusGetSummaryRoutine,
		value: valueGetSummaryRoutine,
	} = useApiRequest<unknown, IResponsePayload<ISummaryRoutineResp>>({
		path: URLS.SUPERVISION_EXECUTION.GET_SUMMARY_ROUTINE,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetSummaryRoutine === 'success') {
			dispatch(stopLoading());
			setGraphic({
				title: valueGetSummaryRoutine?.detail?.routine?.description || '',
				percentage:
					valueGetSummaryRoutine?.detail?.circularDashboard
						?.accomplishedPercentage || 0,
				completedTasks:
					valueGetSummaryRoutine?.detail?.circularDashboard?.totalCompleted ||
					0,
				pendingSupervision:
					valueGetSummaryRoutine?.detail?.circularDashboard
						?.totalPendingSupervision || 0,
				pendingValidation:
					valueGetSummaryRoutine?.detail?.circularDashboard
						?.totalPendingValidation || 0,
				suspendedTasks:
					valueGetSummaryRoutine?.detail?.circularDashboard?.totalSuspended ||
					0,
			});
			setDataActivities({
				...dataActivites,
				countRows: valueGetSummaryRoutine?.totalRecords || 0,
				rows: activitiesAdapter(valueGetSummaryRoutine?.detail.units || []),
			});
			setSupervisor({
				...supervisor,
				countRows: valueGetSummaryRoutine?.detail.supervisors.length || 0,
				rows: supervisorAdapter(valueGetSummaryRoutine?.detail),
			});
			setRoutine({
				_id: valueGetSummaryRoutine?.detail?.routine?._id || '',
				status: valueGetSummaryRoutine?.detail?.routine?.status || '',
				supervisorName:
					valueGetSummaryRoutine?.detail?.supervisorDescription || '',
				unitInfo: getRoutineDescriptionByType(
					valueGetSummaryRoutine?.detail?.routine.type as keyof ITypeRoutine,
					valueGetSummaryRoutine?.detail?.routine
						.executionDate as unknown as Date
				),
			});
		} else if (statusGetSummaryRoutine === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetSummaryRoutine]);

	const handleGetDetails = (idRoutine: string) => {
		executeGetSummaryRoutine({}, `/${idRoutine}`);
	};

	return {
		dataActivites,
		graphic,
		supervisor,
		routine,
		navigate,
		setDataActivities,
		setSupervisor,
		handleGetDetails,
	};
}
