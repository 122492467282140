/* eslint-disable react-hooks/exhaustive-deps */
import { Box, SvgIconTypeMap, CircularProgress } from '@mui/material';
import { ArrowUpward, ArrowDownward, Search } from '@mui/icons-material';
// hook
import { IColumns, IDataTable } from '../hooks/useTableCustom';
// styles
import styles from './styles/table-custom-styles.component.module.css';

// Messages
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Button } from './main-button.component';
import { IFilter } from '../interfaces/filter.interface';
import { useEffect, useState, createRef, cloneElement } from 'react';
import { INTERNAL_MESSAGES } from '../constants/internal-messages';
import { returnStyles } from '../utils/color';

const optionsLimit = [
	{ label: '10', value: '10' },
	{ label: '25', value: '25' },
	{ label: '50', value: '50' },
	{ label: '100', value: '100' },
];

export interface IButtonsAction {
	type?: 'info' | 'warning' | 'success' | 'error' | 'primary' | 'black' | 'blue';
	variant?: 'solid' | 'text' | 'outlined';
	label: string;
	execute: (arg?: any) => void;
	icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
		muiName: string;
	};
	width?: number | string;
	id?: string;
	isHidden?: boolean;
}

interface IProps {
	columns: IColumns[];
	colorColumns?: boolean;
	data: IDataTable<any>;
	setData: (arg: IDataTable<any>) => void;
	showPagination?: boolean;
	showSearch?: boolean;
	labelNotRows?: string;
	hideHeader?: boolean;
	titleHeader?: JSX.Element[] | JSX.Element | string;
	iconHeader?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
	imgHeader?: string;
	rowsSelect?: { position: 'start' | 'end'; label?: string; width?: number };
	buttonsAction?: {
		width?: number;
		buttons: IButtonsAction[];
	};
	fnButtonRowsSelect?: (arg: any) => void;
	labelSelectRows?: string;
	showLimitPerPage?: boolean;
	buttons?: IButtonsAction[];
	filterChips?: IFilter[];
	extraHeader?: JSX.Element;
	clickInRow?: (arg: any) => void;
	modal?: JSX.Element;
}

const TableCustom = ({
	columns,
	colorColumns,
	data,
	setData,
	showPagination,
	showSearch = true,
	labelNotRows,
	hideHeader,
	titleHeader,
	iconHeader: Icon,
	imgHeader,
	buttons,
	rowsSelect,
	buttonsAction,
	fnButtonRowsSelect,
	labelSelectRows,
	showLimitPerPage,
	filterChips,
	extraHeader,
	clickInRow,
	modal,
}: IProps) => {
	const {
		countRows,
		rows,
		rowsSelect: rowSelect,
		rowsPerPage,
		pagination,
		filters,
		activeAdvanceFilter,
		advancedFilter,
	} = data;

	const getSorting = (colId: string) => {
		if (data.sort.col !== colId) return 'ASC';
		if (data.sort.order === 'ASC') return 'DESC';
		if (data.sort.order === 'DESC') return 'ASC';
		return '';
	};

	const filterRef = createRef<any>();
	// eslint-disable-next-line
	const [currentFilters, setCurrentFilters] = useState<string[]>([]);
	const getOptions = () => {
		const options: any = [];
		const limit = Number(rowsPerPage || 10);
		let start = 1;
		let finish = 0;
		let value = 0;

		for (let index = 0; index < Math.ceil(countRows / limit); index++) {
			start = index * limit + 1;
			value = index * limit;
			finish = finish + limit;
			if (finish > countRows) {
				finish = countRows;
			}
			options.push({ start, finish, value, index: index + 1 });
		}
		return options;
	};

	useEffect(() => {
		let _filters: string[] = [];
		if (filterRef.current && filterRef.current.getFilters) {
			_filters = filterRef.current.getFilters();
		}
		setCurrentFilters(_filters);
	}, [filterRef.current, filters]);

	const changeCheckbox = (selected: any) => {
		const index = (rowSelect || []).indexOf(selected);

		if (index > -1) {
			return setData({
				...data,
				rowsSelect: (rowSelect || []).filter((e, j) => j !== index),
			});
		}

		setData({
			...data,
			rowsSelect: [...(data.rowsSelect || []), selected],
		});
	};

	const changeAllCheckboxs = (isAddItem: boolean) => {
		if (isAddItem) {
			return setData({
				...data,
				rowsSelect: rows,
			});
		}

		setData({
			...data,
			rowsSelect: [],
		});
	};

	const onClickInRow = (values: any) => {
		if (clickInRow === undefined) return;

		clickInRow(values);
	};

	return (
		<>
			{!hideHeader && (
				<Box
					display={'flex'}
					alignItems={'center'}
					marginBottom={2}
					marginTop={2}
					position={'relative'}
					className={styles.containerSearch}
				>
					<Box
						display={'flex'}
						alignItems={'center'}
						flexGrow={1}
						fontWeight={700}
						fontSize={20}
						marginBottom={2}
					>
						{Icon && <Icon color="primary" style={{ marginRight: 10 }} />}
						{imgHeader && (
							<img
								src={imgHeader}
								alt="logotipoheader"
								style={{ marginRight: 10 }}
							/>
						)}
						{titleHeader}
					</Box>

					{showSearch && (
						<div className={styles.inputSearch}>
							<Search style={{ fontSize: 18, margin: '0px 10px' }} />
							<input
								value={advancedFilter || ''}
								placeholder={'Buscar...'}
								onChange={(e) =>
									setData({
										...data,
										activeAdvanceFilter: true,
										advancedFilter: e.target.value,
									})
								}
							/>
							{activeAdvanceFilter && (
								<CircularProgress size={16} style={{ marginRight: 10 }} />
							)}
						</div>
					)}
					{buttons && (
						<Box
							display={'flex'}
							alignItems={'center'}
							gap={1}
							height={'min-content'}
							className={styles.containerButtonsSearch}
						>
							{buttons.map(
								(button, k) =>
									!button.isHidden && (
										<Box
											key={k}
											width={button?.width || 'auto'}
											className={styles.buttonContainer}
										>
											<Button
												size="sm"
												{...(button.id && { id: button.id })}
												label={button?.label}
												variant={button?.variant || 'solid'}
												onClick={() =>
													button?.execute
														? button?.execute()
														: {}
												}
												icon={button?.icon ? button?.icon : null}
												style={returnStyles(
													button?.type,
													button?.variant
												)}
											/>
										</Box>
									)
							)}
						</Box>
					)}
					{/*modal && modal*/}
					{modal && cloneElement(modal, { ref: filterRef })}
					{(rowSelect || []).length > 0 && (
						<Box width={210}>
							<Button
								size="sm"
								label={`${
									labelSelectRows ? labelSelectRows : 'Seleccionado:'
								} ${(rowSelect || []).length}`}
								variant="solid"
								onClick={() =>
									fnButtonRowsSelect
										? fnButtonRowsSelect(rowSelect)
										: {}
								}
							/>
						</Box>
					)}
				</Box>
			)}

			{extraHeader !== undefined && <Box>{extraHeader}</Box>}

			<Box width={'100%'} overflow={'auto'} position={'relative'}>
				<table className={styles.tableCustom}>
					<thead
						style={{
							background: colorColumns ? '#e4e4e4' : '',
							borderRadius: '20px',
						}}
					>
						<tr>
							{rowsSelect?.position === 'start' && (
								<th
									data-checkbox={'true'}
									style={{
										minWidth: rowsSelect.width
											? rowsSelect.width
											: 'auto',
									}}
								>
									{rowsSelect.label}
									<input
										type="checkbox"
										id="rowsSelect"
										name="rowsSelect"
										checked={
											(rowSelect || []).length === rows.length
												? true
												: false
										}
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => changeAllCheckboxs(e.target.checked)}
									/>
								</th>
							)}

							{columns.map((col, i) => (
								<th
									style={{
										minWidth: col.width ? col.width : 'auto',
										textAlign: col.align,
									}}
									id={`column-table-${col.nameID}`}
									key={i}
									data-text={col.nameID}
									data-sort={col.sort ? 'true' : 'false'}
									onClick={() => {
										if (col.sort) {
											setData({
												...data,
												sort: {
													col: col.nameID,
													order: getSorting(col.nameID),
												},
											});
										}
									}}
								>
									<>
										{col.text}
										{data?.sort?.col === col.nameID && (
											<>
												{data?.sort?.order === 'ASC' && (
													<ArrowUpward
														style={{
															fontSize: 13,
															marginLeft: 3,
														}}
													/>
												)}
												{data?.sort?.order === 'DESC' && (
													<ArrowDownward
														style={{
															fontSize: 13,
															marginLeft: 3,
														}}
													/>
												)}
											</>
										)}
									</>
								</th>
							))}

							{rowsSelect?.position === 'end' && (
								<th
									data-checkbox={'true'}
									style={{
										minWidth: rowsSelect.width
											? rowsSelect.width
											: 'auto',
									}}
								>
									{rowsSelect.label}
									<input
										type="checkbox"
										id="rowsSelect"
										name="rowsSelect"
										checked={
											(rowSelect || []).length === rows.length
												? true
												: false
										}
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => changeAllCheckboxs(e.target.checked)}
									/>
								</th>
							)}

							{buttonsAction && (
								<th
									style={{
										minWidth: buttonsAction.width
											? buttonsAction.width
											: 'auto',
									}}
								></th>
							)}
						</tr>
					</thead>
					<tbody>
						{rows.length > 0 ? (
							rows.map((row, j) => (
								<tr key={j} onClick={() => onClickInRow(row)}>
									{rowsSelect?.position === 'start' && (
										<td style={{ textAlign: 'center' }}>
											<input
												type="checkbox"
												id="rowsSelect"
												name="rowsSelect"
												checked={
													(rowSelect || []).indexOf(row) > -1
														? true
														: false
												}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>
												) => changeCheckbox(row)}
											/>
										</td>
									)}

									{columns.map((col, k: number) => (
										<td
											key={`${col.nameID}-${j}-${k}`}
											style={{ textAlign: col.align }}
											data-text={col.nameID}
										>
											{row[col.nameID] ? row[col.nameID] : '-'}
										</td>
									))}

									{rowsSelect?.position === 'end' && (
										<td style={{ textAlign: 'center' }}>
											<input
												type="checkbox"
												id="rowsSelect"
												name="rowsSelect"
												checked={
													(rowSelect || []).indexOf(row) > -1
														? true
														: false
												}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>
												) => changeCheckbox(row)}
											/>
										</td>
									)}

									{buttonsAction && (
										<td>
											{buttonsAction.buttons.map((btn, k) => (
												<Button
													key={`button-${j}-${k}`}
													label={btn.label}
													icon={btn.icon}
													onClick={() => btn.execute(row)}
													size="sm"
												/>
											))}
										</td>
									)}
								</tr>
							))
						) : (
							<tr>
								<td
									colSpan={
										rowsSelect ? columns.length + 1 : columns.length
									}
									style={{ textAlign: 'center' }}
								>
									{labelNotRows
										? labelNotRows
										: INTERNAL_MESSAGES.NO_FOUND_ROWS_TABLE_CUSTOM}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</Box>
			<div className={styles.footer}>
				{showLimitPerPage && (
					<div className={styles.limitPerPage}>
						<span>Visualizar</span>
						<select
							value={rowsPerPage || '10'}
							onChange={(e) =>
								setData({
									...data,
									rowsPerPage: e.target.value,
									pagination: '1',
								})
							}
						>
							{optionsLimit.map((op, i) => (
								<option key={i} value={op.value}>
									{op.label}
								</option>
							))}
						</select>
					</div>
				)}
				{showPagination && (
					<div className={styles.pagination}>
						Mostrando{' '}
						<select
							value={pagination}
							onChange={(e) => {
								e.preventDefault();
								setData({
									...data,
									pagination: e.target.value,
								});
							}}
						>
							{getOptions().map((op: any, j: number) => (
								<option key={j} value={op.index}>
									{op.start} - {op.finish}
								</option>
							))}
						</select>
						de {countRows}
					</div>
				)}
			</div>
		</>
	);
};

export default TableCustom;
