import { useAutomaticCharge } from './hooks/useAutomaticCharge';
import { AutomaticChargeContext } from './contexts/useAutomaticChargeContext';

export const AutomaticCharge = () => {
	const { getComponent, setStep } = useAutomaticCharge();

	return (
		<AutomaticChargeContext.Provider value={{ setStep }}>
			{getComponent()}
		</AutomaticChargeContext.Provider>
	);
};
