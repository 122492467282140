import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';

export const getFindignsAdapter = (data?: string[]): { [key: string]: string } => {
	const myObject: { [key: string]: string } = {};
	if (!data) return myObject;
	for (let i = 0; i < data.length; i++) {
		myObject[i] = data[i];
	}
	return myObject;
};

export const getFindingsAdaptedData = (data: string[]) => {
	const newOptions = data.map((element) => {
		return {
			id: element,
			description: element,
			rows: [
				{
					key: element,
					value: element,
				},
			],
			isComplete: true,
			mainAttribute: 'Tipo de hallazgo',
		};
	});
	return newOptions as IAdaptedValues[];
};
