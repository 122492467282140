/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import {
	ISaveFunctionalsBody,
	ISaveFunctionalsResp,
} from '../interfaces/save-functionals.interface';
import { isEmpty } from '../../../../../utils/validators';
import { useChangeStep } from '../../../hooks/useChangeStep';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';

interface IUseSaveFunctionals {
	setOptionsChanged?: (changeUnit?: boolean) => void;
}

export function useSaveFunctionals({ setOptionsChanged }: IUseSaveFunctionals) {
	const dispatch = useDispatch();
	const [step, setStep] = useState('');
	const [functionals, setFunctionals] = useState<{ _id: string; applies: boolean }[]>(
		[]
	);
	const { changeStepSE } = useChangeStep();

	const { idProject, supervisionExtraordinaryData, setSupervisionExtraordinaryData } =
		useContext(CreateSupervisionContext);

	const {
		execute: saveFunctionals,
		status: statusSaveFunctionals,
		value: valueSaveFunctionals,
	} = useApiRequest<ISaveFunctionalsBody, IResponsePayload<ISaveFunctionalsResp>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.SAVE_FUNCTIONALS,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusSaveFunctionals === 'success') {
			if (!isEmpty(step)) {
				changeStepSE(
					{
						newStep: step,
						extraordinarySupervision:
							supervisionExtraordinaryData.idSupervisionExtraordinary,
					},
					`/${idProject}`
				);
				if (step === STEPS_SUPERVISION_EXTRAORDINARY.DG_2) {
					setSupervisionExtraordinaryData({
						...supervisionExtraordinaryData,
						functionalUnit:
							valueSaveFunctionals?.data.filter(
								(e) => e.applies === true
							)[0].functional || '',
					});
				}
			} else {
				dispatch(stopLoading());
				if (setOptionsChanged) setOptionsChanged();
			}
		} else if (statusSaveFunctionals === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al guardar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusSaveFunctionals]);

	useEffect(() => {
		if (!isEmpty(functionals)) {
			dispatch(setLoading({ loading: true }));
			saveFunctionals({ functionals: functionals });
		}
	}, [functionals, step]);

	const executeSaveFunctionals = (
		idsFunctionals: { _id: string; applies: boolean; isIndicatorAssociated?: true }[],
		step: string
	) => {
		setFunctionals(idsFunctionals);
		setStep(step);
	};

	return { executeSaveFunctionals };
}
