import { ModeEdit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { IUser } from '../../../interfaces/user.interface';
import { getRoleName } from '../../../utils/permissions.utils';
import { getDateLocalMX } from '../../../utils/dates';

export const usersAdapter = (
	data: IUser[],
	functionEdit: (item: IUser) => void
): IUser[] => {
	const users: IUser[] = data.map((user: IUser) => {
		return {
			...user,
			roleName: getRoleName(user.role),
			statusName: user.status === 'ACTIVE' ? 'Activo' : 'Inactivo',
			firstName: user.firstName + ' ' + user.lastName,
			lastConnection: user.lastConnection
				? getDateLocalMX(user.lastConnection, 'yyyy/MM/dd hh:mm')
				: '-',
			actions: (
				<div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
					<IconButton
						onClick={() => functionEdit(user)}
						aria-label="edit"
						size="small"
					>
						<ModeEdit />
					</IconButton>
				</div>
			),
		};
	});
	return users;
};
