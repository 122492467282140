/*
 *
 * DG -> Dimensiones geograficas
 * ACT -> Actividades
 * PER -> Personal
 * CRO -> Cronograma
 * FIN -> Finalizar
 *
 */

export const STEPS_SUPERVISION_EXTRAORDINARY = Object.freeze({
	DG_1: '1.0.0',
	DG_2: '1.1.0',
	ACT_1: '2.0.0',
	ACT_2: '2.1.0',
	CRT_1: '3.0.0',
	PER_1: '4.0.0',
	CRO_1: '5.0.0',
	FIN_1: '6.0.0',
	FIN_2: 'x',
});
