/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { IItem } from '../../components/tabs.component';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IVerticalStepperAttributes } from '../../interfaces/vertical.steper.attributes-fixed.interface';
import useUpdateProject from '../../hooks/useUpdateProject';
import Nivel1 from './components/nivel-uno/index';
import Nivel2 from './components/nivel-dos/index';

export interface IStepOneProp {
	data: IVerticalStepperAttributes[];
	attr: Array<{
		key: number;
		label: string;
	}>;
}

const Index = () => {
	const { step: projectStep } = useSelector((state: RootState) => state.projectBase);
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState<number>(0);
	const [tabItems, setTabItems] = useState<Array<IItem>>([
		{
			label: 'Nivel 1',
			value: 0,
		},
	]);
	const { status: statusUpdateProject } = useUpdateProject();
	useEffect(() => {
		if (projectStep === '1.2.0') {
			handleAddlevel();
		}
		if (projectStep === '2.0.0') {
			navigate('/nuevo-proyecto/actividades');
		}
	}, [projectStep, statusUpdateProject]);

	useEffect(() => {
		if (tabItems.length === 1) return;
		setCurrentTab(1);
	}, [tabItems]);

	const handleAddlevel = () => {
		setTabItems((prev) => [
			...prev,
			{
				label: 'Nivel 2',
				value: 1,
			},
		]);
	};
	const RenderPage = (): JSX.Element => {
		let component: JSX.Element = <></>;
		switch (currentTab) {
			case 0:
				component = (
					<Nivel1
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
						tabItems={tabItems}
					/>
				);
				break;
			case 1:
				component = (
					<Nivel2
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
						tabItems={tabItems}
					/>
				);
				break;
			default:
				break;
		}
		return component;
	};
	return (
		<div>
			<RenderPage />
		</div>
	);
};

export default Index;
