/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { ITeam } from '../../interfaces/team.interface';

interface IUseDeleteTeam {
	teamData: ITeam[];
	setTeamData: React.Dispatch<React.SetStateAction<ITeam[]>>;
}

export const useDeleteTeam = ({ teamData, setTeamData }: IUseDeleteTeam) => {
	const dispatch = useDispatch();

	const {
		execute: deleteTeam,
		status: statusDeleteTeam,
		value: valueDeleteTeam,
	} = useApiRequest<unknown, IResponsePayload<ITeam>>({
		path: URLS.EDIT_PROJECT.TEAMS.GET_TEAMS,
		method: 'DELETE',
	});

	useEffect(() => {
		if (statusDeleteTeam === 'success') {
			setTeamData(
				teamData.filter((team) => team._id !== valueDeleteTeam?.detail?._id)
			);
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Equipo eliminado con éxito',
					severity: 'success',
				})
			);
		} else if (statusDeleteTeam === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al eliminar el equipo',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusDeleteTeam]);

	const handleDeleteTeam = (_id: string) => {
		dispatch(setLoading({ loading: true }));
		deleteTeam(null, `/${_id}`);
	};

	return { handleDeleteTeam };
};
