import confirmationImage from '../../../../assets/imgs/confirmation.png';
import { Button } from '../../../../components/main-button.component';
import { useStartCreation } from './hooks/useStartCreation';
import { Paper, Grid } from '@mui/material';
import styles from './index.module.css';

export const StartCreation = () => {
	const { handleCreateNewSupervision, handleContinueSupervision } = useStartCreation();

	return (
		<Paper>
			<Grid container spacing={2} style={{ height: '80vh' }}>
				<Grid item sm={12}>
					<div className={styles.mainContainer}>
						<div className={styles.infoContainer}>
							<img
								className={styles.img}
								src={confirmationImage}
								alt="actions"
							/>
							<h3>
								Actualmente cuentas con una supervisión extraordinaria en
								curso. ¿Deseas crear una nueva? La supervisión actual se
								perderá.
							</h3>
							<div className={styles.buttonsContainer}>
								<Button
									label={'Continuar'}
									onClick={() => handleContinueSupervision()}
									variant="solid"
									style={{
										width: '100%',
										backgroundColor: '#0080F9',
									}}
								/>
								<Button
									label="Crear uno nuevo"
									onClick={() => handleCreateNewSupervision()}
									style={{
										width: '100%',
									}}
								/>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};
