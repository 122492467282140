/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/system';
import { Grid, Paper } from '@mui/material';
import { columns } from './utils/columns.utils';
import styles from './styles/execution-styles.module.css';
import TableCustom from '../../components/table-custom.component';
import { useSupervisionDetail } from './hooks/useSupervisionDetail';
import { GpsFixedOutlined } from '@mui/icons-material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { SupervisionCardComponent } from '../../components/supervision-card.component';

export const SupervisionDetail = () => {
	const {
		state,
		dataActivites,
		supervisor,
		graphic,
		routine,
		columnsActivities,
		setDataActivities,
		setSupervisor,
	} = useSupervisionDetail();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12} lg={4}>
				<SupervisionCardComponent
					supervisionTitle={graphic.title}
					value={graphic.percentage}
					completedTasks={graphic.completedTasks}
					pendingSupervision={graphic.pendingSupervision}
					pendingValidation={graphic.pendingValidation}
					suspendedTasks={graphic.suspendedTasks}
					isDetail
					showContinue={!state.params.isTask}
					routine={state.params.idSupervision}
					idSupervisor={state.params.idSupervisor}
					routineStatus={routine.status}
					supervisor={routine.supervisorName}
					unitInfo={routine.unitInfo}
					showPendiente
				/>
			</Grid>
			<Grid item xs={12} lg={8}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper elevation={0}>
							<Box padding={4}>
								<Grid container spacing={4}>
									<Grid item>
										<PeopleAltOutlinedIcon
											className={styles.iconExecution}
										/>
									</Grid>
									<Grid item xs>
										<h3 style={{ margin: 0 }}>
											Supervisores encargados
										</h3>
										<span
											className={`${styles.subtitle} ${styles.grey}`}
										>
											Visualiza a los supervisores del proyecto
										</span>
										<TableCustom
											columns={columns}
											data={supervisor}
											setData={setSupervisor}
											hideHeader
										/>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper elevation={0}>
							<Box padding={4}>
								<Grid container spacing={4}>
									<Grid item>
										<GpsFixedOutlined
											className={styles.iconExecution}
											style={{ backgroundColor: '#f06885' }}
										/>
									</Grid>
									<Grid item xs>
										<h3 style={{ margin: 0 }}>
											Dimensiones geográficas y actividades
										</h3>
										<span
											className={`${styles.subtitle} ${styles.grey}`}
										>
											Incluidas en la supervisión
										</span>
										<TableCustom
											columns={columnsActivities}
											data={dataActivites}
											setData={setDataActivities}
											hideHeader
										/>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
