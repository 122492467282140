/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Paper } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/main-button.component';
import InputSelect from '../../../components/select-input.component';
import { resetState } from '../../../store/modules/steps';
import { optionsSupervisionHistory } from '../utils/options-history.utils';
import { resetPage } from '../../../store/modules/page-table';

interface ISupervisionHistory {
	setOption: React.Dispatch<React.SetStateAction<string>>;
}

export const SupervisionHistoryComponent = ({ setOption }: ISupervisionHistory) => {
	const dispatch = useDispatch();

	const [selectedOption, setSelectedOption] = useState('');

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		setSelectedOption(value);
	};

	const disabledButton = useMemo((): boolean => {
		if (selectedOption === '') return true;
		return false;
	}, [selectedOption]);

	useEffect(() => {
		dispatch(resetPage());
	}, []);

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12}>
						<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
							Historial de supervisión
						</span>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item>
						<span style={{ fontSize: '18px' }}>
							Elige el tipo de supervisión
						</span>
					</Grid>
					<Grid item xs={12} sm md={6}>
						<InputSelect
							placeholder="Selecciona el tipo de supervisión"
							value={selectedOption}
							optionsMenu={optionsSupervisionHistory}
							onChange={(e: any) => handleChange(e)}
							id="select-type"
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Button
									label="Ver supervisiones"
									onClick={() => {
										dispatch(resetState());
										setOption(selectedOption);
									}}
									variant="solid"
									style={{
										width: '100%',
										backgroundColor: '#0080F9',
										padding: '20px',
									}}
									disabled={disabledButton}
									id="view-supervision"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
