import { Suspense, lazy } from 'react';
import StepTwoFixed from '../../../components/step-two-fixed';
import { IStepProps, IRenderItemProps } from '../../../interfaces/stepTwo.interface';
import Grid from '@mui/material/Grid';
import renderItem from '../../../components/styles/render-item-styles.module.css';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

interface IItem {
	itemProps: IRenderItemProps;
}

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

const TextArea = lazy(() => import('../../../components/text-area.component'));
const RichText = lazy(() => import('../../../components/rich-text.component'));
const ColumnItem = (props: IItem) => {
	const { item, column, handleTable, disabled } = props.itemProps;
	return (
		<Grid item className={renderItem.renderItem}>
			<Suspense fallback={<div>Loading...</div>}>
				{item.columns.map((el, row) => {
					if (row === 0) {
						return (
							<TextArea
								id={`table-ig-${column}-${row}`}
								key={`table-ig-${column}-${row}`}
								RenderTitle={() =>
									column === 0 ? <p>{item.attributes[row]}</p> : null
								}
								//hideCopyPaste={item.rows.length <= 1}
								hideCopyPaste={false}
								disabled={disabled}
								defaultValue={el}
								onChange={(val) => {
									handleTable(column, row, val);
								}}
								onPaste={(txt) => {
									handleTable(column, row, txt);
								}}
							/>
						);
					}
					return (
						<RichText
							key={`table-${column}-${row}`}
							RenderTitle={() => {
								if (column === 0) return <p>{item.attributes[row]}</p>;
								return null;
							}}
							isReadonly={disabled}
							defaultValue={el}
							onChange={(val) => {
								handleTable(column, row, val);
							}}
							onPaste={(txt) => {
								handleTable(column, row, txt);
							}}
							id={`${column}-${row}`}
						/>
					);
				})}
			</Suspense>
		</Grid>
	);
};

const InputItem = (props: IItem) => {
	const { item, column, handleTable, disabled } = props.itemProps;
	return (
		<>
			{item.columns.map((el, row) => (
				<Input
					id={`table-${column}-${row}`}
					key={`table-${column}-${row}`}
					variant="outlined"
					size="small"
					fullWidth
					disabled={disabled}
					placeholder="Escribe aquí"
					value={el}
					onChange={(e) => handleTable(column, row, e.target.value)}
				/>
			))}
		</>
	);
};

const StepTwoComponent = (props: IStepProps) => {
	const { data } = props;
	return (
		<StepTwoFixed
			{...props}
			id="btn-continue-ig"
			renderItem={(eleProps) => {
				if (!data || data.length < 1) return <></>;
				if (data[0].attributes.length > 1)
					return <ColumnItem itemProps={eleProps} />;
				return <InputItem itemProps={eleProps} />;
			}}
		/>
	);
};

export default StepTwoComponent;
