/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { ICreationPage } from '../../../../interfaces/creation-page-interfaces';
import { TReactDispatch } from '../../../../interfaces/utils';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import Nivel2StepOne from './step-one.component';
import Nivel2StepTwo from './step-two.component';
import { IItem } from '../../../../components/tabs.component';
import { estadoCreacion } from '../../../../constants/estado-creacion';
import { StepOneHandler } from '../../../../components/step-one-fixed';
import { IStepOneProp } from '../../Index';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';
import { URLS } from '../../../../constants/urls.constants';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import useGetEF from '../../hooks/useGetEF';
import { sendDataAdapterFunctional } from '../../../../adapters/step-two.adapter';
import useUpdateEF from '../../hooks/useUpdateEF';
import CreationPage from '../../../../components/creation-page.component';
import { IFunctional } from '../../../../interfaces/project.interface';
import useGetUF from '../../hooks/useGetUF';
import useUpdateProject from '../../../../hooks/useUpdateProject';

interface IProps {
	currentTab: number;
	setCurrentTab: TReactDispatch<number>;
	tabItems: IItem[];
}
const Level2 = (props: IProps) => {
	const { currentTab, setCurrentTab, tabItems } = props;
	const dispatch = useDispatch<any>();
	const { _id: _projectID, functionalBase } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [EF_2, setEF_2] = useState<IStepTwoModel[]>([]);
	const [EF_1, setEF_1] = useState<IStepOneProp>({
		data: [],
		attr: [],
	});
	const [isEFshow, setIsEFshow] = useState(false);
	const EFRef = useRef<StepOneHandler>(null);
	const [showAltaCsv2, setShowAltaCsv2] = useState(false);
	const [showCsvUF_2] = useState(false);
	const [isNivelTwoDone] = useState(false);
	const [optionsSelect, setOptionsSelect] = useState<IOptionsMenu[]>([]);
	const [UF_2, setUF_2] = useState<IStepTwoModel[]>([]);
	const { handleUpdateProject } = useUpdateProject();

	const {
		execute: getEFProject,
		status: statusGetEF,
		value: valueGetEF,
		handleTransformEF,
	} = useGetEF();
	const { execute: getProject } = useGetUF({ setUF_2 });
	const {
		execute: updateEF,
		//isSave,
		setIsSave,
	} = useUpdateEF({
		EF_2,
		setEF_2,
	});

	const handleSelectChange = (id: string) => {
		dispatch(setLoading({ loading: true }));
		getEFProject(null, `?project=${_projectID}&functional=${id}`);
	};

	useEffect(() => {
		getProject(null, `?project=${_projectID}&isSubFunctional=0`);
	}, []);

	useEffect(() => {
		if (functionalBase) {
			setIsEFshow(true);
			EFRef.current?.disableAll();
		}
	}, [functionalBase]);

	useEffect(() => {
		if (statusGetEF === 'success') {
			const valueFlag = handleTransformEF(valueGetEF?.data, EF_1);
			setEF_2(valueFlag);
		}
	}, [statusGetEF]);

	useEffect(() => {
		if (isEFshow && UF_2) {
			const dataFlag: IOptionsMenu[] = [];
			const copy = [...UF_2];

			for (let i = 0; i < copy.length; i++) {
				const element: IFunctional = { ...copy[i] };
				dataFlag.push({
					id: String(element._id || ''),
					label: element.columns[0],
					value: String(element._id || ''),
					status: !!element.isFunctionalAssociated,
				});
			}
			setOptionsSelect(dataFlag);
		}
	}, [UF_2, isEFshow]);

	const handleUpdateUF = async (
		data: IStepTwoModel[],
		p_isSave: boolean,
		_functionalID?: string
	) => {
		dispatch(setLoading({ loading: true }));
		try {
			await setIsSave(p_isSave);
			const _adapted = sendDataAdapterFunctional(
				data,
				_projectID || '',
				_functionalID || null
			);
			await updateEF(_adapted);
			if (p_isSave)
				await handleUpdateProject(
					{
						functionalBase: {
							name: data[0].name,
							mainAttribute: data[0].mainAttribute,
							attributes: data[0].attributes,
						},
					},
					_projectID || ''
				);

			setOptionsSelect((prev) =>
				prev.map((i) => {
					if (i.id === _functionalID)
						return {
							...i,
							status: true,
						};
					return i;
				})
			);
			dispatch(stopLoading());
		} catch (e) {
			dispatch(stopLoading());
		}
	};

	const Nivel2: ICreationPage = {
		header: {
			show: !showAltaCsv2,
			label: 'Estado de creación',
			stepper: {
				activeStep: 0,
				items: estadoCreacion,
				completed: {},
			},
			tabs: {
				items: tabItems,
				value: currentTab,
				onTabChange: (_, value) => setCurrentTab(value),
			},
		},
		stepOne: {
			title: '1. Nombra tu nivel 2, así como su atributo principal y los secundarios',
			show: !showAltaCsv2,
			alertLabel:
				'Designa el nombre genérico de los espacios a supervisar según lo indique el contrato. NO utilices el nombre de un área específica',
			Children: (
				<Nivel2StepOne
					refComponent={EFRef}
					onContinue={(data, _) => {
						setIsEFshow(true);
						setEF_2(data);
					}}
					onChange={(data) => {
						setEF_1((prev) => ({ ...prev, data: data }));
					}}
					onSecondaryAttributesChange={(val) => {
						setEF_1((prev) => ({ ...prev, attr: val }));
					}}
				/>
			),
		},
		stepTwo: {
			title:
				!showCsvUF_2 && !showAltaCsv2 ? (
					'2. Enlista y nombra tus espacios nivel 2'
				) : (
					<></>
				),
			onExit: () => {
				setIsEFshow(false);
				EFRef.current?.unDone();
			},
			showExitButon: !isNivelTwoDone && !showAltaCsv2,
			show: isEFshow,
			Children: (
				<Nivel2StepTwo
					setShowAltaCsv={setShowAltaCsv2}
					isDone={isNivelTwoDone}
					data={EF_2}
					url={URLS.PROJECT.FUNCTIONAL}
					setData={setEF_2}
					showAltaCsv={showAltaCsv2}
					labelItem={
						'Designa el nombre específico de cada elemento nivel 2. Ejemplo: Oficina del jefe del servicio, Secretaria, etc.'
					}
					selectData={{
						data: {
							options: optionsSelect,
							withStatus: false,
						},
						label: 'Elige un espacio nivel 2:',
					}}
					onSelectChange={(id) => {
						handleSelectChange(String(id));
					}}
					onContinue={(data, _selected) => {
						handleUpdateUF(data, false, _selected);
					}}
					onSave={(data, _selected) => {
						handleUpdateUF(data, true, _selected);
					}}
					onCVSUpload={() => {
						setShowAltaCsv2(true);
					}}
				/>
			),
		},
	};
	return (
		<div>
			<CreationPage {...Nivel2} />;
		</div>
	);
};

export default Level2;
