import { CameraAltOutlined, UploadFileOutlined } from '@mui/icons-material';
import { Paper, Box, Grid } from '@mui/material';
import { ActionComponent } from '../../components/action.component';
import CustomCamera from '../../components/custom-camera.component';
import { DragNDrop } from '../../components/drag-drop.component';
import InputSelect from '../../components/select-input.component';
import TextInput from '../../components/text-input.component';
import { optionsAreas } from '../supervision-project/utils/options-supervision';
import { useSupendRoutine } from './hooks/useSuspendRoutine';
import { Button } from '../../components/main-button.component';
import styles from './styles/suspend-routine-styles.module.css';
import CloseIcon from '@mui/icons-material/Close';
import { GoBack } from '../../components/go-back-component';

export const SuspendRoutine = () => {
	const {
		data,
		matches,
		camera,
		disabledButton,
		supervisorName,
		unitInfoText,
		backAction,
		handleFile,
		handleChange,
		deleteFile,
		setCamera,
		handlePhoto,
		navigate,
		handleSuspendRoutine,
	} = useSupendRoutine();

	return (
		<>
			{backAction && <GoBack action={() => navigate(-1)} />}
			<Paper>
				<Box padding={3}>
					<Grid
						container
						spacing={2}
						justifyContent="flex-end"
						alignItems="center"
					>
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Suspensión de supervisión
							</span>
							<br />
						</Grid>
						<Grid item xs={12}>
							<span style={{ fontSize: '18px' }}>
								Selecciona la causa de suspensión
							</span>
							<InputSelect
								value={data.type}
								optionsMenu={optionsAreas}
								onChange={handleChange}
								placeholder="Selecciona"
								id="select-suspension"
							/>
						</Grid>
						<Grid item xs={12}>
							<span style={{ fontSize: '18px' }}>
								Descripción de la incidencia
							</span>
							<TextInput
								value={data.description}
								id="description"
								name="description"
								placeholder="Escribe, si es necesario, la descripción de la incidencia"
								onChange={handleChange}
								multiline
								rows={6}
							/>
						</Grid>
						<Grid item xs={12}>
							<span style={{ fontSize: '18px' }}>Evidencias</span>
							<Grid
								container
								spacing={2}
								justifyContent={matches ? 'center' : ''}
							>
								<Grid item>
									{camera ? (
										<CustomCamera
											setImg={handlePhoto}
											stylesCamera={{
												width: '244px',
												height: '221px',
												borderRadius: '10px',
											}}
											withInfo
											user={supervisorName}
											unitInfo={unitInfoText}
										/>
									) : (
										<ActionComponent
											icon={CameraAltOutlined}
											label="Tomar foto"
											onClick={() => setCamera(true)}
										/>
									)}
								</Grid>
								<Grid item>
									<DragNDrop
										element={{ accept: 'image/jpeg' }}
										onChange={handleFile}
										icon={
											<div
												className={styles.dragNDropIconContainer}
											>
												<UploadFileOutlined
													style={{
														fontSize: '3em',
													}}
												/>
												<span style={{ fontSize: '12px' }}>
													JPEG
												</span>
											</div>
										}
										stylesContainer={{
											height: '100%',
											width: matches ? '244px' : '100%',
											padding: '20px',
										}}
										stylesTitle={{
											fontSize: '21px',
											color: '#7f8297',
											fontWeight: 'bold',
										}}
										stylesSubTitle={{
											fontSize: '9px',
											color: '#7f8297',
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						{data.files.length > 0 && (
							<Grid item xs={12} marginY={2}>
								<Grid container spacing={2}>
									{data.files.map((element, index) => (
										<Grid
											item
											key={`${element.fileName}-${index}`}
											md={6}
											lg={3}
											style={{
												position: 'relative',
												display: 'flex',
											}}
										>
											<img
												src={`data:image/jpeg;base64,${element.fileBase64}`}
												alt={element.fileName}
												style={{ width: '100%' }}
											/>
											<div
												className={styles.badgeCustom}
												onClick={() => deleteFile(index)}
											>
												<CloseIcon style={{ color: 'white' }} />
											</div>
										</Grid>
									))}
								</Grid>
							</Grid>
						)}
						<Grid item>
							<Button
								label="Cancelar"
								onClick={() => navigate(-1)}
								style={{
									width: '150px',
								}}
							/>
						</Grid>
						<Grid item>
							<Button
								label="Continuar"
								onClick={handleSuspendRoutine}
								variant="solid"
								style={{
									width: '150px',
									backgroundColor: '#0080F9',
								}}
								disabled={disabledButton}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
