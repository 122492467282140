/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { RootState } from '../../../../../store';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { IGetActivitiesUA } from '../interfaces/get-activities-UA.interface';
import { getOptionsActivitiesUA } from '../adapters/get-activities-UA.adapter';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';

interface IUseGetActivitiesUA {
	loading?: boolean;
}

export const useGetActivitiesUA = ({ loading = true }: IUseGetActivitiesUA) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location?.state?.params?.idProject as string;
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const [activitiesOptions, setActivitiesOptions] = useState<IOptionsMenu[]>([]);

	const {
		execute: getActivitiesUA,
		status: statusGetActivitiesUA,
		value: valueGetActivitiesUA,
	} = useApiRequest<unknown, IResponsePayload<IGetActivitiesUA>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetActivitiesUA === 'success') {
			if (loading) dispatch(stopLoading());
			setActivitiesOptions(
				getOptionsActivitiesUA(valueGetActivitiesUA?.data || [])
			);
		} else if (statusGetActivitiesUA === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusGetActivitiesUA]);

	const handleGetActivitiesUA = (functional: string) => {
		if (loading) dispatch(setLoading({ loading: true }));
		getActivitiesUA(
			null,
			`?project=${
				idProject || _id
			}&functional=${functional}&type=U-A&applies=true&populate=1`
		);
	};

	return {
		activitiesOptions,
		handleGetActivitiesUA,
	};
};
