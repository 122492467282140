/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { isEmpty } from '../../../../../utils/validators';
import { ISaveUnitsBody, ISaveUnitsResp } from '../interfaces/save-units.interface';
import { useSaveActivities } from './useSaveActivities';

interface IUseSaveUnits {
	setOptionsChanged?: (changeUnit?: boolean) => void;
}

export function useSaveUnits({ setOptionsChanged }: IUseSaveUnits) {
	const dispatch = useDispatch();
	const [units, setUnits] = useState<{ _id: string; applies: boolean }[]>([]);
	const [activity, setActivity] = useState('');

	const { executeSaveActivities } = useSaveActivities({
		setOptionsChanged: setOptionsChanged,
	});

	const {
		execute: saveUnits,
		status: statusSaveUnits,
		value,
	} = useApiRequest<ISaveUnitsBody, IResponsePayload<ISaveUnitsResp>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.SAVE_UNITS,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusSaveUnits === 'success') {
			if (!isEmpty(activity)) {
				executeSaveActivities(
					[{ _id: activity, applies: true, isFunctionalAssociated: true }],
					''
				);
			} else {
				dispatch(stopLoading());
				if (setOptionsChanged)
					setOptionsChanged(
						value?.data.every((e) => e.isIndicatorAssociated === true)
					);
			}
		} else if (statusSaveUnits === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al guardar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusSaveUnits]);

	useEffect(() => {
		if (!isEmpty(units)) {
			dispatch(setLoading({ loading: true }));
			saveUnits({ units: units });
		}
	}, [units]);

	const executeSavUnits = (
		idsUnits: { _id: string; applies: boolean; isIndicatorAssociated?: boolean }[],
		idActivity: string
	) => {
		setUnits(idsUnits);
		setActivity(idActivity);
	};

	return { executeSavUnits, statusSaveUnits };
}
