import CreationPage from '../../../../components/creation-page.component';
import { GoBack } from '../../../../components/go-back-component';
import { useActivities } from './hooks/useActivities';

export const Activities = () => {
	const { ActivitiesSteps, undoStep } = useActivities();

	return (
		<>
			<GoBack action={undoStep} />
			<div>
				<CreationPage {...ActivitiesSteps} />
			</div>
		</>
	);
};
