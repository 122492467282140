/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import theme from '../../../../../../config/theme';
import { ITeam } from '../../interfaces/team.interface';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { useCreateNewTeams } from './useCreateNewTeam';
import { useUpdateTeams } from './useUpdateTeam';
import { useDeleteTeam } from './useDeleteTeam';

interface IUseItemCreateTeam {
	item: ITeam;
	teamData: ITeam[];
	setTeamData: React.Dispatch<React.SetStateAction<ITeam[]>>;
}

export const useItemCreateTeam = ({
	item,
	teamData,
	setTeamData,
}: IUseItemCreateTeam) => {
	const dispatch = useDispatch();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [value, setValue] = useState(item.name);
	const [personal, setPersonal] = useState<string[]>(item.personals);

	const { handleNewTeam } = useCreateNewTeams({ teamData, setTeamData });
	const { handleUpdateTeam } = useUpdateTeams();
	const { handleDeleteTeam: executeDeleteTeam } = useDeleteTeam({
		teamData,
		setTeamData,
	});

	useEffect(() => {
		setPersonal(item.personals);
	}, [item.personals]);

	useEffect(() => {
		setValue(item.name);
	}, [item.name]);

	const handleValue = (
		newValue: string,
		row: number,
		column: number,
		name?: string
	) => {
		if (name) {
			setTeamData(
				teamData.map((team, i) => {
					if (i === row) {
						return {
							...team,
							name: newValue,
						};
					} else return team;
				})
			);
		} else {
			if (teamData[row].personals.find((e) => e === newValue)) {
				return dispatch(
					setAlert({
						show: true,
						message: 'El usuario ya fue seleccionado',
						severity: 'error',
					})
				);
			}
			setTeamData(
				teamData.map((team, i) => {
					if (i === row) {
						return {
							...team,
							personals: team.personals.map((idUser, index) => {
								if (index === column) {
									return newValue;
								} else return idUser;
							}),
						};
					} else return team;
				})
			);
		}
	};

	const handleNewPersonal = (row: number) => {
		setTeamData(
			teamData.map((team, i) => {
				if (i === row) {
					const newPersonal = team.personals;
					newPersonal.push('');
					return {
						...team,
						personals: newPersonal,
					};
				} else return team;
			})
		);
	};

	const handleDeletePersonal = (row: number, indexElement: number) => {
		setTeamData(
			teamData.map((team, i) => {
				if (i === row) {
					const newPersonal = team.personals;
					newPersonal.splice(indexElement, 1);
					return {
						...team,
						personals: [...newPersonal],
					};
				} else return team;
			})
		);
	};

	const handleDeleteTeam = (row: number, _id?: string) => {
		if (_id) {
			executeDeleteTeam(_id);
		} else {
			const newTeams = teamData;
			newTeams.splice(row, 1);
			setTeamData([...newTeams]);
		}
	};

	const handleCreateTeam = (team: ITeam, index: number) => {
		if (team._id) {
			handleUpdateTeam({ name: item.name, personals: item.personals }, team._id);
		} else {
			handleNewTeam(team, index);
		}
	};

	return {
		value,
		personal,
		matches,
		setValue,
		setPersonal,
		handleValue,
		handleNewPersonal,
		handleDeletePersonal,
		handleDeleteTeam,
		handleCreateTeam,
	};
};
