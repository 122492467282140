/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import useUpdateProject from '../../../hooks/useUpdateProject';
import { RootState } from '../../../store';

const useUpdateUF = () => {
	const [UF_2, setUF_2] = useState<IStepTwoModel[]>([]);
	const [isSave, setIsSave] = useState<boolean>(false);
	const { handleUpdateProject } = useUpdateProject();
	const { _id: _projectID } = useSelector((state: RootState) => state.projectBase);
	const { execute, status, value } = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_FUNCTIONAL,
		method: 'POST',
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (value?.data && value.data.length > 0) {
				const _adapted = UF_2.map((i, _idx) => ({
					...i,
					_id: value.data[_idx]._id,
				}));
				setUF_2(_adapted);
				if (!isSave) {
					handleUpdateProject(
						{
							step: '1.1.1',
							levels: 1,
							functionalParentBase: {
								name: UF_2[0].name,
								mainAttribute: UF_2[0].mainAttribute,
								attributes: UF_2[0].attributes,
							},
						},
						_projectID || ''
					);
				}
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, status, UF_2, setUF_2, isSave, setIsSave };
};

export default useUpdateUF;
