import CreationPage from '../../../../components/creation-page.component';
import { GoBack } from '../../../../components/go-back-component';
import { usePersonal } from './hooks/usePersonal';

export const Personal = () => {
	const { PersonalSteps, undoStep } = usePersonal();

	return (
		<>
			<GoBack action={undoStep} />
			<div>
				<CreationPage {...PersonalSteps} />
			</div>
		</>
	);
};
