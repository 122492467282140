import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';

export const getInputsCustom = (item: IAdaptedValues) => {
	const newArray = [];
	for (let i = 2; i < (item?.attributes || []).length; i++) {
		newArray.push((item?.attributes || [])[i]);
	}

	const newInputs = [
		{
			id: '0',
			mainAttribute: 'Rol',
			description: item.description,
			rows: [],
			frequency: '',
			disabled: true,
			isComplete: true,
		} as unknown as IAdaptedValues,
		{
			id: '1',
			mainAttribute: 'Atributo principal',
			description: item?.mainAttribute || 'Nombre',
			rows: [],
			frequency: '',
			disabled: true,
			isComplete: true,
		} as unknown as IAdaptedValues,
		{
			id: '2',
			mainAttribute: 'Atributos secundarios',
			description: '',
			rows: newArray.map((e) => {
				return { key: e, value: e };
			}),
			frequency: '',
		} as unknown as IAdaptedValues,
	];

	return newInputs || [];
};
