/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { URLS } from '../../../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';

export const useGenerateReports = () => {
	const dispatch = useDispatch();
	const [urlReport, setUrlReport] = useState<string>('');

	const {
		execute: executeGenerateReport,
		status,
		value,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.REPORTS.GENERATE_REPORTS,
		method: 'POST',
	});

	const {
		execute: executeGetReport,
		status: statusGetReport,
		value: valueGet,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.REPORTS.GET_URL_REPORT,
		method: 'POST',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			executeGetReport({ key: value?.detail?.fileNameKey });
		} else if (status === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: value?.message
						? value?.message
						: 'Ocurrió un error al generar reporte',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [status]);

	useEffect(() => {
		if (statusGetReport === 'success') {
			dispatch(stopLoading());
			setUrlReport(valueGet?.detail);
		} else if (statusGetReport === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al descargar el documento',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetReport]);

	const downloadReport = (url: string) => {
		dispatch(stopLoading());
		document.location = url;
	};

	return {
		urlReport,
		executeGenerateReport,
		executeGetReport,
		downloadReport,
	};
};
