import React from 'react';
import { useEditBaseRole } from './hooks/useEditBase';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';
import { CreateBase } from '../../../../../components/create-base.component';
import { Grid } from '@mui/material';
import Alert from '../../../../../components/info.alert.component';
import { Button } from '../../../../../components/main-button.component';
import { HeaderEdit } from '../../../../../components/header-edit.component';
import PersonalIcon from '../../../../../assets/icons/personal.icon';
import { LABELS_EDIT_BASE_ROLE } from './constants/labels';

interface IEditBase {
	role: IAdaptedValues;
	roleAttributes: IAdaptedValues[];
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentRole: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	setCurrentBase: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
	isEdit: boolean;
	isAutomaticCharge: boolean;
}

export const EditBaseRole = ({
	role,
	roleAttributes,
	setStep,
	setCurrentRole,
	setCurrentBase,
	isEdit,
	isAutomaticCharge,
}: IEditBase) => {
	const { saveChanges } = useEditBaseRole({
		role,
		setStep,
		setCurrentRole,
		setCurrentBase,
	});

	return (
		<CreateBase
			header={
				!isEdit && !isAutomaticCharge ? (
					<>
						<Grid item xs={12}>
							<h4
								style={{
									margin: 0,
									padding: '0.5rem 0 0 0.5rem',
									fontWeight: 600,
								}}
							>
								{LABELS_EDIT_BASE_ROLE.AUTOMATIC_CHARGE.TITLE}
							</h4>
						</Grid>
						<Grid item xs={12}>
							<Alert severity="info" sx={{ marginTop: '1rem' }}>
								{LABELS_EDIT_BASE_ROLE.AUTOMATIC_CHARGE.ALERT}
							</Alert>
						</Grid>
					</>
				) : (
					<HeaderEdit
						section="Personal"
						title={
							isAutomaticCharge
								? LABELS_EDIT_BASE_ROLE.AUTOMATIC_CHARGE.TITLE
								: LABELS_EDIT_BASE_ROLE.EDIT.TITLE
						}
						alertText={
							isAutomaticCharge
								? LABELS_EDIT_BASE_ROLE.AUTOMATIC_CHARGE.ALERT
								: LABELS_EDIT_BASE_ROLE.EDIT.ALERT
						}
						icon={<PersonalIcon size="23px" />}
					/>
				)
			}
			onSaveCustom={saveChanges}
			label={'Rol'}
			inputsCustom={roleAttributes}
			onBackCustom={() => {
				setStep(0);
			}}
			button={
				<Grid item>
					<Button
						id="add-personal"
						label="Asignar personal"
						onClick={() => setStep(2)}
						variant="outlined"
						style={{
							width: '200px',
							color: '#0080F9',
						}}
					/>
				</Grid>
			}
		/>
	);
};
