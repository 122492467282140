import { Card } from '../pages/criterios/components/card.component';
import { Stepper } from './stepper.component';
import { Tabs } from './tabs.component';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ICreationPage } from '../interfaces/creation-page-interfaces';
import Alert from './info.alert.component';

interface IDeleteBtn {
	onExit: () => void;
}
const DeleteButton = (props: IDeleteBtn) => {
	const { onExit } = props;
	return (
		<div
			onClick={onExit}
			style={{
				border: '1px solid #A50018',
				width: '18px',
				height: '18px',
				background: '#A5001833 0% 0% no-repeat padding-box',
				display: 'flex',
				marginLeft: 'auto',
				cursor: 'pointer',
				borderRadius: '50%',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '0.7em',
			}}
		>
			<span
				style={{
					color: '#BB261A',
					width: '7px',
				}}
			>
				x
			</span>
		</div>
	);
};

const CreationPage = (props: ICreationPage) => {
	const { header, stepOne, stepTwo } = props;
	const { show: showHeader = true } = header || {};
	const { showExitButon: showExitBtn2 = true } = stepTwo || {};
	const show = showHeader !== undefined ? showHeader : true;
	const handleChange = (e: React.SyntheticEvent<Element, Event>, value: number) => {
		if (header?.tabs && header?.tabs.onTabChange) header.tabs.onTabChange(e, value);
	};

	return (
		<div>
			{header && show && (
				<Card
					sxRoot={{ marginBottom: header.tabs ? '3rem' : '0' }}
					title={header.label}
					sx={{ width: '100%', marginLeft: 0, paddingBottom: 2 }}
				>
					{header.stepper ? (
						<Stepper
							steps={header.stepper.items}
							activeStep={header.stepper.activeStep}
							completed={header.stepper.completed}
						/>
					) : (
						<></>
					)}
					{header?.tabs ? (
						<Tabs
							items={header.tabs.items}
							value={header.tabs.value}
							onChange={handleChange}
						/>
					) : (
						<></>
					)}
				</Card>
			)}
			<Box
				sx={{
					display: stepOne && stepOne.show ? 'flex' : 'none',
					flexDirection: 'column',
					pt: 2,
				}}
			>
				<Paper sx={{ flexGrow: 1, padding: '2rem' }} elevation={0}>
					{stepOne?.Header && stepOne.Header}
					{stepOne?.title && (
						<h4
							style={{
								margin: 0,
								padding: '0.5rem 0 0 0.5rem',
								fontWeight: 600,
							}}
						>
							{stepOne.title}
						</h4>
					)}
					{stepOne?.alertLabel && (
						<Alert severity="info" sx={{ marginTop: '1rem' }}>
							{stepOne.alertLabel}
						</Alert>
					)}
					{stepOne?.Children}
				</Paper>
			</Box>
			{stepTwo && stepTwo.show && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						pt: stepTwo.hideHeader ? 0 : 2,
					}}
				>
					<Paper sx={{ flexGrow: 1, padding: '2rem' }} elevation={0}>
						{stepTwo?.Header && stepTwo.Header}
						{!stepTwo.hideHeader && (
							<>
								<div style={{ display: 'flex' }}>
									{stepTwo?.title && (
										<h4
											style={{
												margin: 0,
												padding: '0.5rem 0 0 0.5rem',
												fontWeight: 600,
											}}
										>
											{stepTwo.title}
										</h4>
									)}
									{showExitBtn2 && (
										<DeleteButton
											onExit={() =>
												stepTwo.onExit && stepTwo.onExit()
											}
										/>
									)}
								</div>
								{stepTwo.alertLabel && (
									<Alert severity="info" sx={{ marginTop: '1rem' }}>
										{stepTwo.alertLabel}
									</Alert>
								)}
							</>
						)}
						{stepTwo.Children}
					</Paper>
				</Box>
			)}
		</div>
	);
};

export default CreationPage;
