/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { URLS } from '../../../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';

export const useGetDataFilters = () => {
	const dispatch = useDispatch();
	const [units, setUnits] = useState<IOptionsMenu[]>([]);
	const [activities, setActivities] = useState<IOptionsMenu[]>([]);
	const [actBySupervisor, setActBySupervisor] = useState<IOptionsMenu[]>([]);
	const [fuctionals, setFuctionals] = useState<IOptionsMenu[]>([]);

	const {
		execute: executeGetFuctionals,
		status: statusUnits,
		value: valueUnits,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.SUPERVISION_UNIT.GET_FUNCTIONALS,
		method: 'GET',
	});

	const {
		execute: executeGetActivities,
		status: statusActivities,
		value: valueActivities,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.SUPERVISION_UNIT.GET_ACTIVITIES,
		method: 'GET',
	});

	const {
		execute: executeGetFuctionals2,
		status: statusFuctionals,
		value: valueFuctionals,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.SUPERVISION_UNIT.GET_FUNCTIONALS,
		method: 'GET',
	});

	const {
		execute: getActivitiesBySupervisor,
		status: statusActBySupervisor,
		value: valueActBySupervisor,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.SUPERVISION_HISTORY.ALL_ACTIVITIES_BY_SUPERVISOR,
		method: 'GET',
	});

	useEffect(() => {
		if (statusUnits === 'success') {
			dispatch(stopLoading());
			setUnits(
				(valueUnits?.data || [])?.map((i, idx) => ({
					id: idx,
					label: i.description,
					value: i._id,
				})) || []
			);
		} else if (statusUnits === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al cargar las unidades funcionales',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUnits]);

	useEffect(() => {
		if (statusActivities === 'success') {
			dispatch(stopLoading());
			setActivities(
				(valueActivities?.data || [])?.map((i, idx) => ({
					id: idx,
					label: i.description,
					value: i._id,
				})) || []
			);
		} else if (statusActivities === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al cargar las actividades',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusActivities]);

	useEffect(() => {
		setFuctionals([]);
		if (statusFuctionals === 'success') {
			setFuctionals(
				(valueFuctionals?.data || [])?.map((i, idx) => ({
					id: idx,
					label: i.description,
					value: i._id,
				})) || []
			);
		} else if (statusFuctionals === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al cargar los servicios',
					severity: 'error',
				})
			);
		}
		dispatch(stopLoading());
	}, [statusFuctionals]);

	useEffect(() => {
		setActBySupervisor([]);
		if (statusActBySupervisor === 'success') {
			setActBySupervisor(
				(valueActBySupervisor?.data || [])?.map((i, idx) => ({
					id: idx,
					label: i.activityName,
					value: i._id,
				})) || []
			);
		} else if (statusActBySupervisor === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al cargar las actividades',
					severity: 'error',
				})
			);
		}
		dispatch(stopLoading());
	}, [statusActBySupervisor]);

	return {
		units,
		fuctionals,
		activities,
		actBySupervisor,
		executeGetFuctionals,
		executeGetFuctionals2,
		executeGetActivities,
		getActivitiesBySupervisor,
	};
};
