/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useLocation } from 'react-router-dom';
import { CreateTeamsForm } from '../components/create-teams-form.component';
import { ModalTeams } from '../components/modal-teams.component';
import { stopLoading } from '../../../../../store/modules/loading';
import { useEffect } from 'react';

export const useCreateTeams = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const isEdit = location?.state?.params?.isEditing as boolean;
	const { step: projectStep } = useSelector((state: RootState) => state.projectBase);

	useEffect(() => {
		dispatch(stopLoading());
	}, []);

	const getComponent = () => {
		if (isEdit) return <CreateTeamsForm isEdit={isEdit} />;
		switch (projectStep) {
			case '4.0.1':
			default:
				return <ModalTeams />;
			case '4.1.0':
				return <CreateTeamsForm isEdit={isEdit} />;
		}
	};

	return { getComponent };
};
