/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { projectsAdapter } from '../adapters/projects.adapter';
import { getView } from '../constants/views';
import { useNavigate } from 'react-router-dom';
import { IProject } from '../../../interfaces/project.interface';
import { resetProject, setValueProject } from '../../../store/modules/project';
import { TYPES_LOAD_PROJECT } from '../../../constants/type-load-project';

export const useViewProjects = () => {
	const { data, setData, pagination, rowsSelect, rowsPerPage, filters } =
		useTableCustom<IProject>();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		execute: getProjects,
		status: statusProject,
		value: projects,
	} = useApiRequest<unknown, IResponsePayload<IProject>>({
		path: URLS.PROJECT.CREATE_PROJECT,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getProjects(
			null,
			`?page=${pagination}&recordsByPage=${rowsPerPage}&advancedSearch=${
				filters?.advancedSearch || ''
			}`
		);
	}, [pagination, rowsSelect, rowsPerPage, filters]);

	const handleEdit = (data: IProject) => {
		const view = getView(data.step);
		if (view && data.typeLoadProject !== TYPES_LOAD_PROJECT.AUTOMATIC) {
			dispatch(
				setValueProject({
					...data,
					functionalParentBase: data.functionalParentBase || undefined,
					functionalBase: data.functionalBase || undefined,
					activityBase: data.activityBase || undefined,
					specificIndicatorBase: data.specificIndicatorBase || undefined,
					generalIndicatorBase: data.generalIndicatorBase || undefined,
				})
			);
			navigate(`/nuevo-proyecto/${view}`, {
				state: {
					params: {
						project: data,
						step: data.step,
					},
				},
			});
		} else if (data.typeLoadProject === TYPES_LOAD_PROJECT.AUTOMATIC) {
			dispatch(
				setValueProject({
					...data,
				})
			);
			navigate('/creacion-automatica');
		} else navigate('/inicio');
	};

	useEffect(() => {
		if (statusProject === 'success') {
			dispatch(stopLoading());
			setData({
				...data,
				countRows: projects?.totalRecords || 0,
				rows: projectsAdapter(projects?.data || [], handleEdit),
				rowsPerPage,
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusProject === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusProject]);

	const handleNewProject = (isAutomatic: string) => {
		dispatch(resetProject());
		navigate('/nuevo-proyecto', {
			state: {
				params: {
					typeLoadProject: isAutomatic,
				},
			},
		});
	};

	return {
		data,
		setData,
		handleNewProject,
	};
};
