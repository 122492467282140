import { IGetIGsByActivity } from '../interfaces/get-IGs-by-activity.interface';
import { IGetIGs } from '../interfaces/get-IGs.interface';

export const updateSavedIGsAdapter = (
	data: IGetIGsByActivity[],
	checkedList: Set<string>
) => {
	const listIGs = data.map((element) => {
		return {
			name: element.name,
			project: element.project,
			activity: element.activity,
			indicator: element.indicator,
			type: 'A-IG',
			_id: element._id,
			applies: checkedList.has(element.indicator),
		};
	});
	return listIGs;
};

export const saveNewIGsAdapter = (
	data: IGetIGs[],
	checkedList: Set<string>,
	idActivity: string,
	project: string
) => {
	const listIGs = data.map((element) => {
		return {
			name: `${idActivity}-${element._id}`,
			project: project,
			activity: idActivity,
			indicator: element._id,
			type: 'A-IG',
			applies: checkedList.has(element._id),
		};
	});
	return listIGs;
};
