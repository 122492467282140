/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { stopLoading } from '../../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setState } from '../../../../../store/modules/steps';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { IExtraordinarySupervision } from '../../../interfaces/get-supervision.interfaces';

export function useStartSupervision() {
	const dispatch = useDispatch();
	const { setSupervisionExtraordinaryData } = useContext(CreateSupervisionContext);

	const {
		execute: startSupervision,
		status: statusStartSupervision,
		value: valueStartSupervision,
	} = useApiRequest<unknown, IResponsePayload<IExtraordinarySupervision>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.START,
		method: 'POST',
	});

	useEffect(() => {
		if (statusStartSupervision === 'success') {
			dispatch(stopLoading());
			dispatch(setState({ state: 1 }));
			setSupervisionExtraordinaryData({
				idSupervisionExtraordinary: valueStartSupervision?.detail._id || '',
				step: valueStartSupervision?.detail.step || '1.0.0',
				functionalUnit: '',
			});
		} else if (statusStartSupervision === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear la supervisión extraordinaria.',
					severity: 'error',
				})
			);
		}
	}, [statusStartSupervision]);

	return { startSupervision };
}
