/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { IGetSupervisionUnit } from '../../interfaces/get-supervision-unit.interface';
import { getCheckedListRelations } from '../../adapters/get-relations.adapter';

export const useGetUIRelations = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const [idsIndicators, setIdsIndicators] = useState<Set<string>>(new Set<string>());

	const {
		execute: getUIRelations,
		status: statusGetUIRelations,
		value: valueGetUIRelations,
		setValue,
	} = useApiRequest<unknown, IResponsePayload<IGetSupervisionUnit>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetUIRelations === 'success') {
			dispatch(stopLoading());
			setIdsIndicators(
				getCheckedListRelations(valueGetUIRelations?.data || [], true)
			);
		} else if (statusGetUIRelations === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusGetUIRelations]);

	const handleGetUIRelations = (idActivity: string, idFunctional: string) => {
		dispatch(setLoading({ loading: true }));
		getUIRelations(
			null,
			`?project=${idProject}&activity=${idActivity}&functional=${idFunctional}&type=U-I`
		);
	};

	return {
		idsIndicators,
		valueGetUIRelations,
		handleGetUIRelations,
		setIdsIndicators,
		setValue,
	};
};
