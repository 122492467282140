/* eslint-disable react-hooks/exhaustive-deps */
import theme from '../../../config/theme';
import { RootState } from '../../../store';
import { useMediaQuery } from '@mui/material';
import { isEmpty } from '../../../utils/validators';
import { bytesToMB } from '../../../utils/converts';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../store/modules/snackbar';
import { fileToBase64 } from '../../../utils/fileToBase64';
import { setLoading } from '../../../store/modules/loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddInfoImage } from '../../../hooks/useAddInfoImage';
import { DOCS_EXTENSIONS, DOCS_TYPES } from '../../../constants/files-type';
import { STATUS_SUPERVISION_TYPES } from '../../../constants/status-supervision';
import { useChangeStatus } from '../../supervision-project/hooks/useChangeStatus';
import { validateImages } from '../../supervision-project/components/utils/validate-images';
import { INewIncident } from '../../supervision-project/components/interfaces/new-incident.interface';
import { IChangeStatusParams } from '../../supervision-project/components/interfaces/change-status.interface';

export function useSupendRoutine() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const routine = location?.state?.routine as string;
	const { _id } = useSelector((state: RootState) => state.projectBase);
	const supervisorName = (location?.state?.supervisorName as string) || '';
	const unitInfoText = (location?.state?.unitInfo as string) || '';
	const backAction = (location?.state?.backAction as boolean) || '';

	const { executeChangeStatus, statusChangeStatus } = useChangeStatus();

	useEffect(() => {
		if (isEmpty(routine)) {
			navigate(-1);
		}
	}, []);

	useEffect(() => {
		if (statusChangeStatus === 'success') {
			navigate(-1);
		}
	}, [statusChangeStatus]);

	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [camera, setCamera] = useState(false);
	const [data, setData] = useState<INewIncident>({
		name: '',
		type: '',
		description: '',
		project: '',
		files: [],
	});

	const handleChange = (event: any) => {
		const { name } = event.target;
		if (name === undefined) {
			setData({
				...data,
				type: event.target.value,
			});
		} else {
			setData({
				...data,
				[name]: event.target.value,
			});
		}
	};

	const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (data.files.length === 4) {
			dispatch(
				setAlert({
					show: true,
					message: 'No se pueden cargar más fotos.',
					severity: 'error',
				})
			);
			return;
		}
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const fullName = file.name.split('.');
			const size = file.size;
			const reader = new FileReader();
			const extension = file ? fileList[0].type : '';
			reader.onload = (e) => {
				const srcData = reader.result;
				if (srcData) {
					addInfoImage(
						fileToBase64(srcData),
						true,
						fullName[0] +
							'_' +
							Date.now() +
							'.' +
							DOCS_EXTENSIONS[extension || 'image/jpeg'],
						bytesToMB(size),
						extension
					);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const setFileImage = (
		imageSrc: string | null | undefined,
		name?: string,
		size?: string,
		extension?: string
	) => {
		if (imageSrc) {
			setData({
				...data,
				files: [
					...data.files,
					{
						fileName: name || 'photo_user',
						type: DOCS_EXTENSIONS[extension || 'image/jpeg'],
						fileBase64: imageSrc,
						description: data.description,
						size: size || '0',
					},
				],
			});
		}
	};

	const { addInfoImage } = useAddInfoImage({
		setImg: setFileImage,
		user: supervisorName,
		unitInfo: unitInfoText,
	});

	const deleteFile = (index: number) => {
		const newFindings = data.files;
		newFindings.splice(index, 1);
		setData({ ...data, files: newFindings });
	};

	const handlePhoto = (imageSrc: string | null | undefined) => {
		if (data.files.length === 4) {
			dispatch(
				setAlert({
					show: true,
					message: 'No se pueden cargar más fotos.',
					severity: 'error',
				})
			);
			return;
		}
		if (imageSrc) {
			setData({
				...data,
				files: [
					...data.files,
					{
						fileName: `photo_user${new Date().toISOString()}.jpeg`,
						type: DOCS_TYPES.JPEG,
						fileBase64: fileToBase64(imageSrc),
						description: data.description,
						size: '',
					},
				],
			});
			setCamera(false);
		}
	};

	const handleSuspendRoutine = () => {
		dispatch(setLoading({ loading: true }));
		executeChangeStatus({
			project: _id,
			routine: routine,
			newStatus: STATUS_SUPERVISION_TYPES.SUSPENDED,
			causeSuspension: data.type,
			suspensionDescription: data.description,
			files: validateImages(data.files, data.description),
		} as IChangeStatusParams);
	};

	const disabledButton = useMemo((): boolean => {
		if (data.description !== '' && data.type !== '' && data.files.length > 0)
			return false;
		return true;
	}, [data]);

	return {
		data,
		matches,
		camera,
		disabledButton,
		supervisorName,
		unitInfoText,
		backAction,
		dispatch,
		handleFile,
		handleChange,
		deleteFile,
		setCamera,
		handlePhoto,
		navigate,
		handleSuspendRoutine,
	};
}
