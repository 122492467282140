/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';

const usePersonal = () => {
	const dispatch = useDispatch<any>();
	const {
		execute: createPersonal,
		status: statusCreatePersonal,
		value: valueCreatePersonal,
	} = useApiRequest<any, IResponsePayload<IStepTwoModel>>({
		path: URLS.PROJECT.PERSONAL,
		method: 'POST',
	});

	const {
		execute: updatePersonal,
		status: statusUpdatePersonal,
		value: valueUpdatePersonal,
	} = useApiRequest<any, IResponsePayload<IStepTwoModel>>({
		path: URLS.PROJECT.PERSONAL,
		method: 'PATCH',
	});

	const {
		execute: getPersonal,
		status: statusGetPersonal,
		value: valueGetPersonal,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.PERSONAL,
		method: 'GET',
	});

	const {
		execute: updateActivities,
		status: statusUpdateActivities,
		value: valueUpdateActivities,
	} = useApiRequest<any, IResponsePayload<IStepTwoModel>>({
		path: URLS.PROJECT.MANY_ACTIVITIES,
		method: 'POST',
	});

	const {
		execute: getActivities,
		status: statusGetActivities,
		value: valueActivities,
	} = useApiRequest<any, IResponsePayload<IStepTwoModel>>({
		path: URLS.PROJECT.ACTIVITY,
		method: 'GET',
	});

	useEffect(() => {
		if (statusCreatePersonal === 'success') {
			dispatch(stopLoading());
		} else if (statusCreatePersonal === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusCreatePersonal]);

	useEffect(() => {
		if (statusUpdatePersonal === 'success') {
			dispatch(stopLoading());
		} else if (statusUpdatePersonal === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusUpdatePersonal]);

	return {
		createPersonal,
		statusCreatePersonal,
		valueCreatePersonal,
		updatePersonal,
		statusUpdatePersonal,
		valueUpdatePersonal,
		getPersonal,
		statusGetPersonal,
		valueGetPersonal,
		//activities
		getActivities,
		statusGetActivities,
		valueActivities,
		updateActivities,
		statusUpdateActivities,
		valueUpdateActivities,
	};
};

export default usePersonal;
