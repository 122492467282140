/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';

import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { IValuesInfo } from '../../../../interfaces/edit-values.interface';
import { getSingleAdaptedData } from '../../../../utils/get-adapted-data';

interface IUseUpdateFunctional {
	setCurrentFunctional: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
}

export const useUpdateFunctional = ({ setCurrentFunctional }: IUseUpdateFunctional) => {
	const dispatch = useDispatch();

	const {
		execute: updateFunctional,
		status: statusUpdateFunctional,
		value,
	} = useApiRequest<{ columns: string[] }, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.FUNCTIONALS.UPDATE_FUNCTIONAL,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusUpdateFunctional === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Información actualizada con éxito',
					severity: 'success',
				})
			);
			setCurrentFunctional(getSingleAdaptedData(value?.detail));
			dispatch(stopLoading());
		} else if (statusUpdateFunctional === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateFunctional]);

	const handleUpdateFunctional = (
		idFunctional: string,
		body: { columns: string[] }
	) => {
		dispatch(setLoading({ loading: true }));
		updateFunctional(body, `/${idFunctional}`);
	};

	return { handleUpdateFunctional };
};
