import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IUtils {
	isCSVPasted: boolean;
}

const defaultState: IUtils = {
	isCSVPasted: false,
};
export const UtilsSlice = createSlice({
	name: 'stateProject',
	initialState: defaultState,
	reducers: {
		setIsCsvLoaded: (state, action: PayloadAction<boolean>): IUtils => {
			return {
				...state,
				isCSVPasted: action.payload,
			};
		},
	},
});

export const { setIsCsvLoaded } = UtilsSlice.actions;

export default UtilsSlice.reducer;
