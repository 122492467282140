import { ListNextTasks } from './components/list-next-tasks.component';
import styles from './styles/index-styles.module.css';
import { useNextTasks } from './hooks/useNextTasks';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import { NoRecurringTasks } from './components/no-recurring-tasks.component';
import { NoRecurringTasksBase } from './components/no-recurring-tasks-base.component';
import { resetPage } from '../../store/modules/page-table';

export const NextTasks = () => {
	const { currentTab, tabItems, setCurrentTab, dispatch } = useNextTasks();

	return (
		<>
			<Tabs
				value={currentTab}
				variant="scrollable"
				onChange={(_, value) => {
					setCurrentTab(value);
					dispatch(resetPage());
				}}
				TabIndicatorProps={{
					style: { background: '#0080F9' },
				}}
				sx={{
					'& button.Mui-selected': {
						color: '#0080F9',
					},
					width: '100%',
				}}
			>
				{(tabItems || []).map((element, index: number) => {
					return (
						<Tab
							key={index}
							className={styles.tabsStyle}
							label={element.label}
						/>
					);
				})}
			</Tabs>
			<Paper elevation={0}>
				<Box padding={3}>
					{currentTab === 0 && <ListNextTasks />}
					{currentTab === 1 && <NoRecurringTasks />}
					{currentTab === 2 && <NoRecurringTasksBase />}
				</Box>
			</Paper>
		</>
	);
};
