import { IValuesInfo } from '../../../../../interfaces/edit-values.interface';
import { IGetActivitiesUA } from '../interfaces/get-activities-UA.interface';

export const getIGsUAAdapter = (data: IGetActivitiesUA[]) => {
	const listIGs = data?.map((element) => {
		return {
			_id: element?.indicator?._id || '',
			value: element?.indicator?.columns[0] || '',
		};
	});
	return listIGs;
};

export const getIEsUAAdapter = (data: IValuesInfo[]) => {
	const listIGs = data?.map((element) => {
		return {
			_id: element?._id || '',
			value: element?.description || '',
		};
	});
	return listIGs;
};
