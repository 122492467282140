/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { RootState } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { setAlert } from '../../../store/modules/snackbar';
import { ISummaryTasks } from '../interfaces/get-summary-tasks.interface';

export const useGetSummaryTasks = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { _id } = useSelector((state: RootState) => state.projectBase);
	const [dataDashboard, setDataDashboard] = useState<{ [key: string]: string }>({});
	const [dataTable, setDataTable] = useState<{
		[key: string]: { [key: string]: string }[];
	}>({});

	const {
		execute: getSummaryTasks,
		status: statusSummaryTasks,
		value: valueSummaryTasks,
	} = useApiRequest<unknown, ISummaryTasks>({
		path: URLS.SUPERVISION_HISTORY.DASHBOARD,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getSummaryTasks(null, `?project=${_id}`);
	}, []);

	useEffect(() => {
		if (statusSummaryTasks === 'success') {
			dispatch(stopLoading());
			setDataDashboard({
				tareasBase: valueSummaryTasks?.tareasBase?.toString() || '0',
				espacios1: valueSummaryTasks?.espacios1?.toString() || '0',
				espacios2: valueSummaryTasks?.espacios2?.toString() || '0',
				totalActividades: valueSummaryTasks?.totalActividades?.toString() || '0',
			});

			setDataTable({
				actividadesServicio: Object.entries(
					valueSummaryTasks?.actividadesServicio || {}
				).map(([key, value]) => {
					return {
						label: key,
						total: value.toString(),
					};
				}),
				actividadesEspacio: Object.entries(
					valueSummaryTasks?.actividadesEspacio || {}
				).map(([key, value]) => {
					return {
						label: key,
						total: value.toString(),
					};
				}),
			});
		} else if (statusSummaryTasks === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusSummaryTasks]);

	return { navigate, dataDashboard, dataTable };
};
