import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import { Grid } from '@mui/material';
import calendar from './styles/calendar.component.module.css';
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import './styles/calendar-styles.css';
import './styles/body-calendar.css';
import { URLS } from '../constants/urls.constants';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setAlert } from '../store/modules/snackbar';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { IResponsePayload } from '../hooks/useApiRequest';
import { IActivitiesLabelsResponse } from '../pages/profile-project/components/execution/interfaces/activitiesLabels.interface';

interface ICalendarProps {
	events: any;
	infoPophover: string | null;
	setInfoPophover: React.Dispatch<React.SetStateAction<string | null>>;
	calendarViews?: string;
	eventClick?: (e: any) => void;
	initialView?: string;
	initialDate?: string;
	title?: string;
	datesSet?: (fetchInfo: any) => void;
	dataCalendar?: any;
}

const Calendar = (props: ICalendarProps) => {
	const {
		events,
		eventClick,
		initialView = 'dayGridMonth',
		calendarViews = 'dayGridDay,dayGridWeek,dayGridMonth,multiMonthYear,listWeek',
		datesSet,
		dataCalendar,
		infoPophover,
		setInfoPophover,
	} = props;

	const user = useSelector((state: RootState) => state.users);
	const dispatch = useDispatch();
	const { _id } = useSelector((state: RootState) => state.projectBase);
	let controller: any = null;

	const renderEventContent = (eventInfo: any) => {
		return (
			<div className={calendar.calendarContent}>
				<div
					className={
						eventInfo?.view?.type === 'listWeek'
							? 'mt-lg-4'
							: calendar.circulo
					}
					style={{
						backgroundColor: eventInfo.event?.extendedProps?.eventColor,
					}}
				></div>
				<p>
					{eventInfo.event?.title}, {eventInfo.event?.extendedProps?.supervisor}
				</p>
			</div>
		);
	};

	const getInfo = async (info: any) => {
		const routine = info.event._def.publicId;
		let statusLabels: boolean = false;

		if (infoPophover !== info.event._def.publicId) {
			if (controller) {
				controller.abort();
			}

			let activitiesLabels: IResponsePayload<IActivitiesLabelsResponse>;
			if (_id) {
				controller = new AbortController();
				await axios
					.post(
						`${process.env.REACT_APP_API_URL}/${URLS.SUPERVISION_EXECUTION.ACTIVITIES_LABELS}`,
						{ project: _id, routines: [routine] },
						{
							headers: {
								'Content-Type': 'application/json; charset=utf-8',
								Authorization: `Bearer ${user.token}`,
							},
							signal: controller.signal,
						}
					)
					.then(
						(
							response: AxiosResponse<
								IResponsePayload<IActivitiesLabelsResponse>
							>
						) => {
							activitiesLabels = response?.data;
							statusLabels = true;
							const popover = new bootstrap.Popover(info.el, {
								title: info.event.title,
								placement: 'auto',
								trigger: 'hover focus',
								content:
									'<p class="text-secondary">' +
									format(
										new Date(info.event.extendedProps.dateRutine),
										'EEEE PPP',
										{
											locale: es,
										}
									) +
									'<p>Actividades: ' +
									(activitiesLabels?.detail?.activitiesByRoutine[0]?.activitiesDescription.join() ||
										'-') +
									'</p>' +
									info.event.extendedProps.dataPopover +
									'<div class="skeleton-container"></div>',
								html: true,
							});
							popover.show();
						}
					)
					.catch((error) => {
						if (error.name !== 'CanceledError')
							dispatch(
								setAlert({
									show: true,
									message:
										'Ocurrió un error al consultar la información',
									severity: 'error',
								})
							);
					});
			}

			if (statusLabels) {
				controller = new AbortController();
				await axios
					.get(
						`${process.env.REACT_APP_API_URL}/${URLS.SUPERVISION_EXECUTION.GET_STATS}/${routine}`,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
								Authorization: `Bearer ${user.token}`,
							},
							signal: controller.signal,
						}
					)
					.then((response) => {
						const popoverPreview = bootstrap.Popover.getInstance(info.el);
						if (popoverPreview) {
							popoverPreview.dispose();
						}
						const popover = new bootstrap.Popover(info.el, {
							title: info.event.title,
							placement: 'auto',
							trigger: 'hover focus',
							content:
								'<p class="text-secondary">' +
								format(
									new Date(info.event.extendedProps.dateRutine),
									'EEEE PPP',
									{
										locale: es,
									}
								) +
								'<p>Actividades: ' +
								(activitiesLabels?.detail?.activitiesByRoutine[0]?.activitiesDescription.join() ||
									'-') +
								'</p>' +
								info.event.extendedProps.dataPopover +
								'<div class="main-container-row"><div class="container-title"><div class="circle color-popover-pending-supervision"></div><span>' +
								(response?.data?.detail?.circularDashboard
									?.totalPendingSupervision || 0) +
								' Por supervisar</span></div><div class="container-title"><div class="circle color-popover-pending-validation"></div><span>' +
								(response?.data?.detail?.circularDashboard
									?.totalPendingValidation || 0) +
								' Por validar</span></div></div><div class="main-container-row"><div class="container-title"><div class="circle color-popover-total-suspending"></div><span>' +
								(response?.data?.detail?.circularDashboard
									?.totalSuspended || 0) +
								' Suspendidas</span></div><div class="container-title"><div class="circle color-popover-total-accomplished"></div><span>' +
								(response?.data?.detail?.circularDashboard
									?.totalCompleted || 0) +
								' Completadas</span></div></div>',
							html: true,
						});
						popover.show();
					})
					.catch((error) => {
						if (error.name !== 'CanceledError')
							dispatch(
								setAlert({
									show: true,
									message:
										'Ocurrió un error al consultar la información',
									severity: 'error',
								})
							);
					});
			}
		}
	};

	return (
		<Grid item xs={12}>
			<FullCalendar
				events={events}
				datesSet={datesSet}
				eventClick={eventClick}
				locale={esLocale}
				themeSystem={'bootstrap5'}
				dayHeaderContent={(args) => {
					return args?.view?.type === 'dayGridWeek' ? (
						<p className="text-secondary">
							<p>{args.text.substring(0, 3).toUpperCase()}</p>
							<p>{args.text.substring(4).split('/')[0]}</p>
						</p>
					) : (
						<p className="text-secondary">{args?.text?.toUpperCase()}</p>
					);
				}}
				plugins={[
					dayGridPlugin,
					listPlugin,
					timeGridPlugin,
					multiMonthPlugin,
					bootstrap5Plugin,
				]}
				initialView={initialView}
				headerToolbar={{
					left: 'prevYear prev,next nextYear today',
					center: 'title',
					right: calendarViews,
				}}
				eventContent={renderEventContent}
				dayMaxEventRows={
					dataCalendar?.view?.type === 'dayGridWeek'
						? false
						: dataCalendar?.view?.type === 'dayGridDay'
						? false
						: 4
				}
				eventMouseEnter={(info) => getInfo(info)}
				eventMouseLeave={(info) => {
					const popover = bootstrap.Popover.getInstance(info.el);
					if (popover) {
						popover.dispose();
					}
					if (controller) {
						controller.abort();
						controller = null;
					}
					setInfoPophover(null);
				}}
				fixedWeekCount={false}
			/>
		</Grid>
	);
};

export default Calendar;
