/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import TableCustom from '../../../components/table-custom.component';
import { columnsSuspension } from '../utils/columns-supervision';
import { useSuspension } from '../hooks/useSuspension';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

export const ListNextTasks = () => {
	const { data, setData, getSuspensions, pagination, rowsPerPage, filters } =
		useSuspension();
	const location = useLocation();
	const state = location?.state?.params;
	const { _id } = useSelector((state: RootState) => state.projectBase);

	useEffect(() => {
		getSuspensions(
			null,
			`?project=${
				state?.idProject || _id
			}&page=${pagination}&recordsByPage=${rowsPerPage}&advancedSearch=${
				filters?.advancedSearch || ''
			}`
		);
	}, [pagination, rowsPerPage, filters]);

	return (
		<Paper elevation={0}>
			<Box padding={3}>
				<TableCustom
					titleHeader="Suspensiones"
					columns={columnsSuspension}
					data={data}
					setData={setData}
					showPagination
					showLimitPerPage
				/>
			</Box>
		</Paper>
	);
};
