/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Grid } from '@mui/material';
import TextInput from '../../../components/text-input.component';
import { Button } from '../../../components/main-button.component';
import { useMemo } from 'react';
import { isEmpty } from '../../../utils/validators';
import { INewUser } from '../../../interfaces/new-user.interface';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import InputSelect from '../../../components/select-input.component';
import { getRoles } from '../../../utils/permissions.utils';

interface INewUserForm {
	currentUser: INewUser;
	currentTabValue: number;
	users: INewUser[];
	addUser: () => void;
	handleSetData: any;
	createUser: any;
}

export const NewUserForm = ({
	currentUser,
	currentTabValue,
	users,
	handleSetData,
	createUser,
}: INewUserForm) => {
	const dispatch = useDispatch();
	const disabledButton = useMemo((): { button: boolean; button2: boolean } => {
		const isValid = users.every(
			(item) =>
				!isEmpty(item.firstName) &&
				!isEmpty(item.lastName) &&
				!isEmpty(item.employeeNumber) &&
				!isEmpty(item.email) &&
				!isEmpty(item.role) &&
				!isEmpty(item.position) &&
				!isEmpty(item.password)
		);
		if (
			currentUser.firstName !== '' &&
			currentUser.lastName !== '' &&
			!isEmpty(currentUser.employeeNumber) &&
			currentUser.email !== '' &&
			currentUser.role !== '' &&
			currentUser.position !== '' &&
			currentUser.password !== ''
		)
			return { button: false, button2: isValid };
		return { button: true, button2: isValid };
	}, [
		currentUser.position,
		currentUser.role,
		currentUser.employeeNumber,
		currentUser.email,
		currentUser.lastName,
		currentUser.firstName,
		currentUser.password,
	]);

	return (
		<Grid container spacing={3}>
			<Grid item>Nuevo usuario</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					id="firstName"
					name="firstName"
					label="Nombres(s)"
					placeholder="Ingresar nombre"
					value={currentUser.firstName}
					onChange={(e) => handleSetData(e, 'firstName')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					id="lastName"
					name="lastName"
					label="Apellidos"
					placeholder="Ingresar apellidos"
					value={currentUser.lastName}
					onChange={(e) => handleSetData(e, 'lastName')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					id="employeeNumber"
					name="employeeNumber"
					label="Número de empleado"
					placeholder="Escribe el número de empleado"
					//type="number"
					value={currentUser.employeeNumber}
					onChange={(e) => handleSetData(e, 'employeeNumber')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					id="email"
					name="email"
					label="Correo electrónico"
					placeholder="Ingresar correo electrónico"
					value={currentUser.email}
					onChange={(e) => handleSetData(e, 'email')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					id="position"
					name="position"
					label="Puesto"
					placeholder="Asignar puesto"
					value={currentUser.position}
					onChange={(e) => handleSetData(e, 'position')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					id="password"
					name="password"
					label="Contraseña"
					placeholder="Contraseña"
					type="password"
					value={currentUser.password}
					onChange={(e) => handleSetData(e, 'password')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<span style={{ fontSize: 14 }}>Selecciona un rol</span>
				<InputSelect
					backgroundColor="#FFF"
					optionsMenu={getRoles()}
					value={currentUser.role}
					height="45px"
					placeholder="Selecciona un rol"
					name="role"
					id="role"
					onChange={(e) => handleSetData(e, 'role')}
				/>
			</Grid>
			{/* {currentTabValue === 0 && (
				<Grid item xs={12} md={12}>
					<span>¿Deseas crear más de un usuario?</span>
				</Grid>
			)} */}
			<Grid item xs={12} md={12}>
				<Grid
					container
					justifyContent={currentTabValue === 0 ? 'space-between' : 'flex-end'}
				>
					{/* {currentTabValue === 0 && (
						<Button
							label="Crear más"
							onClick={addUser}
							variant="outlined"
							style={{ color: '#0080F9', width: '25%' }}
							disabled={disabledButton.button}
						/>
					)} */}
					<Button
						id="save-btn"
						label="Guardar"
						onClick={() => {
							dispatch(setLoading({ loading: true }));
							createUser({
								...users[0],
								employeeNumber: parseInt(
									users[0].employeeNumber.toString()
								),
							});
						}}
						style={{
							backgroundColor: '#0080F9',
							width: '25%',
						}}
						disabled={!disabledButton.button2}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
