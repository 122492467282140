/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo } from 'react';
import { isEmpty } from '../../../utils/validators';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../../store/modules/snackbar';
import { fileToBase64 } from '../../../utils/fileToBase64';
import { setLoading } from '../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { bytesToMB } from '../../../utils/converts';
import { IProject } from '../../../interfaces/project.interface';
import NewProjectContext from '../contexts/useNewProjectContext';
import { setValueProject } from '../../../store/modules/project';
import { DOCS_TYPES } from '../../../constants/files-type';
import useUpdateProject from '../../../hooks/useUpdateProject';
import { TYPES_LOAD_PROJECT } from '../../../constants/type-load-project';

interface IUseNewProject {
	state?: any;
}

export function useNewProject(props?: IUseNewProject) {
	const [data, setData] = useContext(NewProjectContext);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { handleUpdateProject, status } = useUpdateProject();

	const {
		execute: createProject,
		status: statusProject,
		value: valueProject,
	} = useApiRequest<IProject, IResponsePayload<IProject>>({
		path: URLS.PROJECT.CREATE_PROJECT,
		method: 'POST',
	});

	useEffect(() => {
		if (statusProject === 'success') {
			dispatch(setValueProject(valueProject?.detail));
			navigate(
				valueProject?.detail?.typeLoadProject === TYPES_LOAD_PROJECT.AUTOMATIC
					? '/creacion-automatica'
					: '/nuevo-proyecto/dimensiones-geograficas'
			);
			dispatch(setLoading({ loading: false }));
		} else if (statusProject === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusProject]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setData({ ...data, [name]: value });
	};

	const getValue = (property: keyof IProject): string => {
		return (data[property] || '').toString();
	};

	const disabledButton = useMemo((): boolean => {
		if (
			isEmpty(data.name) ||
			isEmpty(data.shortName) ||
			isEmpty(data.contractNumber) ||
			isEmpty(data.location) ||
			isEmpty(data.contractStartDate) ||
			isEmpty(data.contractEndDate) ||
			isEmpty(data.description) ||
			(!props?.state?.params.isEditing && isEmpty(data.files))
		)
			return true;
		return false;
	}, [data]);

	const handleFile = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const name = file.name;
			const size = file.size;
			const reader = new FileReader();
			reader.onload = (e) => {
				if (id === 'uploadJPG') {
					const srcData = reader.result;
					if (srcData) {
						const temporalFiles = data.files.filter(
							(file) => file.type !== DOCS_TYPES.JPG
						);
						setData({
							...data,
							files: [
								...temporalFiles,
								{
									projectId: '',
									type: DOCS_TYPES.JPG,
									url: fileToBase64(srcData),
									name: `${name}`,
									size: bytesToMB(size),
									modifiedDate: new Date().toISOString(),
									description: name,
									isCoverImage: true,
								},
							],
						});
					}
				} else {
					const file = e.target?.result;
					if (file) {
						const base64String = fileToBase64(file);
						setData({
							...data,
							files: [
								...data.files,
								{
									projectId: '',
									type: DOCS_TYPES.PDF,
									url: base64String,
									name: `${name}`,
									size: bytesToMB(size),
									modifiedDate: new Date().toISOString(),
									description: name,
								},
							],
						});
					}
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const handleCreateProject = () => {
		dispatch(setLoading({ loading: true }));
		if (props?.state?.params.isEditing) {
			handleUpdateProject(
				{
					_id: data._id,
					name: data.name,
					shortName: data.shortName,
					contractNumber: data.contractNumber,
					location: data.location,
					contractStartDate: data.contractStartDate,
					contractEndDate: data.contractEndDate,
					description: data.description,
					nextChangesApplicationDate: data.nextChangesApplicationDate,
				},
				data._id || ''
			);
		} else {
			createProject({ ...data, step: '1.0.0' });
		}
	};

	useEffect(() => {
		if (status === 'success') {
			navigate(-1);
		}
	}, [status]);

	return {
		disabledButton,
		getValue,
		handleSetData,
		handleFile,
		dispatch,
		handleCreateProject,
		data,
		setData,
	};
}
