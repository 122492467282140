/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { ICollapsable } from '../../../../../interfaces/collapsable.interface';
import { IFunctionalResponse } from '../../../../../interfaces/project.interface';
import { stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { attachSublevels } from '../../../../../utils/attachSublevels';

export const useGetFunctionalsValue = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [functionalsTable, setFunctionalsTable] = useState<ICollapsable[]>([]);

	const {
		execute: getFunctionalsValue,
		status: statusGetFunctionalsValue,
		value: valueGetFunctionalsValue,
	} = useApiRequest<unknown, IResponsePayload<IFunctionalResponse>>({
		path: URLS.PROJECT.FUNCTIONAL,
		method: 'GET',
	});

	useEffect(() => {
		getFunctionalsValue(null, `?project=${idProject}`);
	}, []);

	useEffect(() => {
		if (statusGetFunctionalsValue === 'success') {
			dispatch(stopLoading());
			setFunctionalsTable(attachSublevels(valueGetFunctionalsValue?.data || []));
		} else if (statusGetFunctionalsValue === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusGetFunctionalsValue]);

	return { functionalsTable, valueGetFunctionalsValue };
};
