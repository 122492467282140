export const LABELS_CREATE_ROLE = Object.freeze({
	AUTOMATIC_CHARGE: {
		TITLE: '1. Agrega al rol un atributo principal y los atributos secundarios pertinentes.',
		ALERT: 'A continuación designe el nombre de los roles a considerar en la supervisión según lo indique el contrato. NO utilice el nombre de una persona.',
	},
	EDIT: {
		TITLE: '1. Edita tus roles, así como su atributo principal y los secundarios',
		ALERT: 'A continuación edita el nombre de los roles a considerar en la supervisión según lo indique el contrato. NO utilice el nombre de una persona.',
	},
});

export const LABELS_EDIT_BASE_ROLE = Object.freeze({
	AUTOMATIC_CHARGE: {
		TITLE: '1. Agrega al rol un atributo principal y los atributos secundarios pertinentes.',
		ALERT: 'A continuación designe el nombre de los roles a considerar en la supervisión según lo indique el contrato. NO utilice el nombre de una persona.',
	},
	EDIT: {
		TITLE: '1. Edita al rol un atributo principal y los atributos secundarios pertinentes.',
		ALERT: 'A continuación edita el nombre de los roles a considerar en la supervisión según lo indique el contrato. NO utilice el nombre de una persona.',
	},
});

export const LABELS_ASSIGN_PERSONAL = Object.freeze({
	TITLE: '2. Asigna usuarios al rol',
	ALERT: 'Establezca el número de personas para cada rol del proyecto y designe quién ocupará dicho puesto',
});
