/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultAlertValue, IAlert } from '../../../interfaces/alert.interface';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IParamsLogin, IResponseLogin } from '../interfaces/login.interface';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/modules/user';
import { setLoading, stopLoading } from '../../../store/modules/loading';

interface IProps {
	email: string;
	password: string;
	code: string;
}
export const useLogin = (props: IProps) => {
	const { email, password, code } = props;
	const [data, setData] = useState<IProps>({
		email: '',
		password: '',
		code: '',
	});
	//const [errorEmail, setErrorEmail] = useState({ valid: false, msg: '' });
	const [alert, setAlertLocal] = useState<IAlert>(defaultAlertValue);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		execute: loginApi,
		status: statusLogin,
		value: dataLogin,
	} = useApiRequest<IParamsLogin, IResponseLogin>({
		path: URLS.LOGIN.AUTH,
		method: 'POST',
	});

	useEffect(() => {
		setData((prev) => ({ ...prev, email: email, password: password, code: code }));
	}, [email, password, code]);

	useEffect(() => {
		if (statusLogin === 'success') {
			const dataFlag = {
				...dataLogin?.user,
				isLogged: true,
				token: dataLogin?.access_token,
			};
			dispatch(setUser({ ...dataFlag }));
			navigate('inicio');
			dispatch(stopLoading());
		} else if (statusLogin === 'error') {
			setAlertLocal({
				show: true,
				message: dataLogin?.message || 'No se ha podido iniciar sesión',
				severity: 'error',
			});
			dispatch(stopLoading());
		}
	}, [statusLogin]);

	const handleOnSubmit = () => {
		dispatch(setLoading({ loading: true }));
		loginApi({ ...data });
	};

	const handleValidate = (): void => {
		handleOnSubmit();
	};

	return {
		handleValidate,
		alert,
		//errorEmail,
	};
};
