/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { IServices } from '../interfaces/actions.interface';
import { URLS } from '../../../../../constants/urls.constants';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';

export const useRescheduleFilters = () => {
	const [services, setServices] = useState<IOptionsMenu[]>([]);

	const {
		execute: executeGetServices,
		status: statusServices,
		value: valueServices,
	} = useApiRequest<any, IResponsePayload<IServices>>({
		path: URLS.SUPERVISION_UNIT.GET_ACTIVITIES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusServices === 'success') {
			setServices([
				{ id: '', label: 'Todos', value: '' },
				...((valueServices?.data || [])?.map((i, idx) => ({
					id: idx,
					label: i.description,
					value: i._id,
				})) || []),
			]);
		}
	}, [statusServices]);

	return {
		executeGetServices,
		statusServices,
		services,
	};
};
