import { Checkbox, Autocomplete, TextField } from '@mui/material';
import { IOptionsMenu, ISelect } from '../interfaces/select.interface';
import { Fragment, useCallback } from 'react';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';

interface ISelectProps {
	backgroundColor?: string;
	optionsMenu: IOptionsMenu[];
	onChange: (event: any) => void;
	height?: string;
	placeholder?: string;
	name?: string;
	width?: string;
	value: ISelect[];
	isDisabled?: boolean;
	withStatus?: boolean;
	id?: string;
}

function InputSelectMultiple({
	backgroundColor = '#F7F7F7',
	optionsMenu,
	onChange,
	value,
	placeholder,
	isDisabled,
	name,
	id,
}: ISelectProps) {
	const styles = {
		backgroundColor: backgroundColor,
	};
	return (
		<Fragment>
			<Autocomplete
				multiple
				id={id}
				value={value}
				style={styles}
				options={optionsMenu}
				disabled={isDisabled}
				disableCloseOnSelect
				getOptionLabel={(option) => String(option.label)}
				renderOption={(props, option, { selected }) => (
					<li {...props}>
						<Checkbox
							icon={<CheckBoxOutlineBlank fontSize="small" />}
							checkedIcon={<CheckBox fontSize="small" />}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
						{option.label}
					</li>
				)}
				onChange={(e, newValue) => {
					onChange({ target: { name, value: newValue || '' } });
				}}
				renderInput={(params) => (
					<TextField {...params} placeholder={placeholder} />
				)}
				isOptionEqualToValue={useCallback(
					(option: any, value: any) => option.value === value.value,
					[]
				)}
			/>
		</Fragment>
	);
}
export default InputSelectMultiple;
