export interface IDays {
	monday: string;
	tuesday: string;
	wednesday: string;
	thursday: string;
	friday: string;
	saturday: string;
	sunday: string;
}

export const daysK: IDays = {
	monday: 'Lunes',
	tuesday: 'Martes',
	wednesday: 'Miércoles',
	thursday: 'Jueves',
	friday: 'Viernes',
	saturday: 'Sábado',
	sunday: 'Domingo',
};

export const datePickers = [
	{
		id: 'date1',
		name: 'date1',
		placeholder: 'Seleccionar',
		type: 'text',
	},
	{
		id: 'date2',
		name: 'date2',
		placeholder: 'Seleccionar',
		type: 'text',
	},
];
