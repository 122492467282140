import { IColumns } from '../../../../../interfaces/table.insterface';

export const columnsSelectLvl: IColumns[] = [
	{
		text: 'Espacio funcional',
		nameID: 'functionalSpace',
	},
	{
		text: 'Actividades',
		nameID: 'activities',
	},
	{
		text: '',
		nameID: 'acciones',
		align: 'right',
	},
];
