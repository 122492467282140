type MappedViews = {
	[key: string]: string;
};

export const mappedViews: MappedViews = {
	//DIMENSIONES GEOGRAFICAS
	'1.0.0': 'dimensiones-geograficas',
	'1.1.1': 'dimensiones-geograficas', // modal - Continuar -> 2.0.0
	'1.2.0': 'dimensiones-geograficas', // agregar un nivel 2 - Continuar -> 2.0.0
	//ACTIVIDADES
	'2.0.0': 'actividades', // manda a criterios
	//CRITERIOS GENERALES
	'3.0.0': 'criterios', // Modal donde se pregunta a criterios
	'3.0.1': 'criterios', // Criterios Generales
	'3.0.2': 'criterios', // Modal cuando se da en criterios generales y pregunta por los especificos
	'3.1.0': 'criterios', // Criterios especificos
	'3.2.0': 'criterios', // Geograficos y servicios
	'3.2.1': 'criterios', // Modal relación criterios - espacio funcional
	'3.3.0': 'criterios', // Hallazgos
	//PERSONAL
	'4.0.0': 'personal', // Creacion roles
	'4.0.1': 'personal', // Modal creacion equipos
	'4.1.0': 'personal', // Creacion equipos
	'4.2.0': 'personal', // Asignacion supervisor
	//CRONOGRAMA
	'5.0.0': 'cronograma', // Creacion frecuencias
	'5.0.1': 'cronograma', // Dias laborales
	'5.1.0': 'cronograma', // Frecuencia de servicios
	'5.1.1': 'cronograma', // Frecuencia por unidad-actividad
	'5.2.0': 'cronograma', // Frecuencia por unidad-espacio-actividad
	'5.2.1': 'cronograma', // Modal seleccion fecha inicio
	x: 'inicio',
};

export const getView = (step: string) => {
	if (Object.prototype.hasOwnProperty.call(mappedViews, step)) {
		return mappedViews[step];
	}
	return null;
};
