import StepTwoFixed from '../../../../components/step-two-fixed';
import { IStepProps, IStepTwoModel } from '../../../../interfaces/stepTwo.interface';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import InputSelect from '../../../../components/select-input.component';
import Button from '@mui/material/Button';
import renderItem from '../../styles/render-item-styles.module.css';
import { useState, useEffect } from 'react';
import { TReactDispatch } from '../../../../interfaces/utils';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';

const ContainedBtn = styled(Button)<{ background: string; customcolor: string }>(
	({ theme, customcolor, background }) => ({
		textTransform: 'none',
		backgroundColor: background,
		color: customcolor,
		marginLeft: 'auto',
		borderRadius: '8px',
		minWidth: '157px',
		minHeight: '45px',
		':hover': {
			'background-color': background,
			color: customcolor,
		},
	})
);

interface IItem {
	item: IStepTwoModel;
	store: ISelected;
	column: number;
	options: IOptionsMenu[];
	handleSelectedIndicator: (column: number, value: string) => void;
}

interface ISubcontainerElement {
	label: string;
	children: JSX.Element | JSX.Element[];
}
const SubContainerElement = (props: ISubcontainerElement) => {
	const { label, children } = props;
	return (
		<>
			<Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
				<h4
					style={{
						margin: 0,
						padding: '0.5rem 0 0 0.5rem',
						fontWeight: 600,
					}}
				>
					{label}:
				</h4>
			</Grid>
			<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
				{children}
			</Grid>
		</>
	);
};

interface ISubContainer {
	handleUF: TReactDispatch<string>;
	handleEF: TReactDispatch<string>;
	handleACT: TReactDispatch<string>;
	UFValue: string;
	EFValue: string;
	ACTValue: string;
	UFOptions: IOptionsMenu[];
	EFOptions: IOptionsMenu[];
	ACTOptions: IOptionsMenu[];
	isLevelTwo: boolean;
}
const SubContainer = (props: ISubContainer) => {
	const {
		handleACT,
		handleEF,
		handleUF,
		ACTValue,
		EFValue,
		UFValue,
		ACTOptions,
		EFOptions,
		UFOptions,
		isLevelTwo,
	} = props;

	const {
		functionalParentBase: lvl1,
		functionalBase: lvl2,
		activityBase: activity,
	} = useSelector((state: RootState) => state.projectBase);

	return (
		<Grid
			container
			columnGap={1}
			rowGap={2}
			marginTop={2}
			marginBottom={2}
			marginLeft={{ xs: 0, xl: 2 }}
			marginRight={{ xs: 0, xl: 2 }}
		>
			<SubContainerElement label={`Elige ${lvl1?.name || 'nivel 1'}`}>
				<InputSelect
					onChange={(e) => {
						handleUF(String(e.target.value));
						if (!isLevelTwo) handleEF(String(e.target.value));
					}}
					value={UFValue}
					optionsMenu={UFOptions}
				/>
			</SubContainerElement>
			{isLevelTwo && (
				<SubContainerElement label={`Elige ${lvl2?.name || 'nivel 2'}`}>
					<InputSelect
						onChange={(e) => handleEF(String(e.target.value))}
						value={EFValue}
						optionsMenu={EFOptions}
					/>
				</SubContainerElement>
			)}
			<SubContainerElement label={`${activity?.name || 'Servicio'}`}>
				<InputSelect
					onChange={(e) => handleACT(String(e.target.value))}
					value={ACTValue}
					optionsMenu={ACTOptions}
				/>
			</SubContainerElement>
		</Grid>
	);
};

interface ISelected {
	[key: string]: string;
}
const Item = (props: IItem) => {
	const { options, store, column, handleSelectedIndicator, item } = props;

	return (
		<Grid container item className={renderItem.renderItem} columnGap={2} rowGap={2}>
			{options.map((i, idx) => {
				const id = item._id || '';
				return (
					<Grid item key={`freq-${column}-${idx}-${i.value}`}>
						<ContainedBtn
							customcolor={store[id] === i.value ? '#fff' : '#727272'}
							background={store[id] === i.value ? '#0080F9' : '#CECECE'}
							onClick={() =>
								handleSelectedIndicator(column, String(i.value))
							}
						>
							{i.label}
						</ContainedBtn>
					</Grid>
				);
			})}
		</Grid>
	);
};

interface IProps extends IStepProps {
	frequenciesList: IOptionsMenu[];
	subcontainerOptions?: ISubContainer;
	onCustomSave: (EF: string, ACT: string, I: ISelected) => void;
	onCustomContinue: (EF: string, ACT: string, I: ISelected) => void;
}
const StepTwoComponent = (props: IProps) => {
	const { subcontainerOptions, frequenciesList, onCustomContinue, onCustomSave, data } =
		props;
	const [selectedIndicators, setSelectedIndicators] = useState<ISelected>({});

	const handleSelectedIndicator = (indicatorID: string, value: string) => {
		setSelectedIndicators((prev) => ({ ...prev, [indicatorID]: value }));
	};

	useEffect(() => {
		const _new: ISelected = {};
		data.forEach((i) => {
			_new[String(i._id)] = i.columns.slice(-1).at(0);
		});
		setSelectedIndicators(_new);
	}, [data]);

	return (
		<StepTwoFixed
			{...props}
			onSave={(_, selected) => {
				onCustomSave(
					String(subcontainerOptions?.EFValue),
					String(subcontainerOptions?.ACTValue),
					selectedIndicators
				);
			}}
			onContinue={() => {
				onCustomContinue(
					String(subcontainerOptions?.EFValue),
					String(subcontainerOptions?.ACTValue),
					selectedIndicators
				);
			}}
			{...(subcontainerOptions && {
				subContent: <SubContainer {...subcontainerOptions} />,
			})}
			id="btn-continue-freq-detail"
			ItemContent={(item, column) => {
				return (
					<Item
						key={`item-${item}-${column}`}
						options={frequenciesList}
						column={column}
						store={selectedIndicators}
						handleSelectedIndicator={(_, value) => {
							handleSelectedIndicator(item._id || '', value);
						}}
						item={item}
					/>
				);
			}}
			hiddeAddButton
			hiddeCsvButton
			hiddeDeleteItemsButtons
			hiddeVerticalNumbers
		/>
	);
};

export default StepTwoComponent;
