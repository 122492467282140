export const toBase64 = (file: File) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			resolve(fileReader.result);
		};
		fileReader.onerror = (error) => {
			reject(error);
		};
	});
};
export function getFileFromBase64(string64: any) {
	const trimmedString = string64.replace('dataimage/jpegbase64', '');
	const imageContent = atob(trimmedString);
	const buffer = new ArrayBuffer(imageContent.length);
	const view = new Uint8Array(buffer);

	for (let n = 0; n < imageContent.length; n++) {
		view[n] = imageContent.charCodeAt(n);
	}
	const type = 'image/jpeg';
	const blob = new Blob([buffer], { type });
	return new File([blob], 'FILE', { lastModified: new Date().getTime(), type });
}

export function base64toBlob(base64Data: string, contentType: string) {
	contentType = contentType || '';
	const sliceSize = 1024;
	const byteCharacters = atob(base64Data);
	const bytesLength = byteCharacters.length;
	const slicesCount = Math.ceil(bytesLength / sliceSize);
	const byteArrays = new Array(slicesCount);

	for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		const begin = sliceIndex * sliceSize;
		const end = Math.min(begin + sliceSize, bytesLength);

		const bytes = new Array(end - begin);
		for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}
	return new Blob(byteArrays, { type: contentType });
}

export function removeAccents(value: string) {
	const accents: { [key: string]: string } = {
		á: 'a',
		é: 'e',
		í: 'i',
		ó: 'o',
		ú: 'u',
		Á: 'A',
		É: 'E',
		Í: 'I',
		Ó: 'O',
		Ú: 'U',
	};
	return value
		.split('')
		.map((key) => accents[key] || key)
		.join('')
		.toString();
}

export const transformToCapital = (value: string): string => {
	const words = value.replace(/(^\w{1})|(\s+\w{1})/g, (letra) => letra.toUpperCase());
	return words;
};

export const bytesToMB = (bytes: number) => {
	const mb = bytes / (1024 * 1024);
	return mb.toFixed(2);
};
