/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Suspensions } from '../../../supervision-project/components/suspensions.component.';
import theme from '../../../../config/theme';
import { SuspensionDetails } from '../suspensions-details.component';

export function useViewSuspensionDetails() {
	const [currentTab, setCurrentTab] = useState<number>(0);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	const tabItems = [
		{ label: 'Gestor de suspensiones', value: 0 },
		{ label: 'Evidencias', value: 1 },
	];

	const getComponent = () => {
		switch (currentTab) {
			case 0:
				return <SuspensionDetails />;
			case 1:
				return <Suspensions isSupervisionRoutine={false} isFindings={false} />;
			default:
				return <SuspensionDetails />;
		}
	};

	return {
		currentTab,
		matches,
		tabItems,
		setCurrentTab,
		getComponent,
	};
}
