import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Divider, Paper, Popover, SvgIconTypeMap } from '@mui/material';
import styles from './styles/info-cards-styles.module.css';
import { MoreHorizRounded } from '@mui/icons-material';
import { Box } from '@mui/system';
import { useContext, useState } from 'react';
import { IActions, IActionsList } from './planning/interfaces/actions.interface';
import { useDispatch } from 'react-redux';
import { setValuePP } from '../../../store/modules/profile-project';
import { useNavigate } from 'react-router-dom';
import { ProfileProjectContext } from '../context/useProfileProjectContext';

interface IInfoCards {
	icon: (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }) | null;
	label: string;
	subtitle?: string;
	actions?: boolean;
	actionsList?: IActionsList;
	stylesCard?: React.CSSProperties;
	stylesIcon?: React.CSSProperties;
}

export const InfoCards = (props: IInfoCards) => {
	const {
		icon: Icon,
		label,
		subtitle,
		actions,
		actionsList,
		stylesCard,
		stylesIcon,
	} = props;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { data } = useContext(ProfileProjectContext);

	const open = Boolean(anchorEl);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handleClickOption = (element: IActions) => {
		if (element.action === 'navigate') {
			navigate(`${element.value}`, {
				state: {
					params: {
						isEditing: true,
						idProject: data?._id,
						structureData: getStructuredData(element),
						projectData: data,
					},
				},
			});
		} else if (element.action === 'step') {
			dispatch(setValuePP({ step: element.value }));
		}
	};

	const getStructuredData = (element: IActions) => {
		if (!element.structureData) return undefined;
		switch (element.structureData) {
			case 1:
				return data.generalIndicatorBase;
			case 2:
				return data.specificIndicatorBase;
			case 3:
				return data.functionalParentBase;
			case 4:
				return data.functionalBase;
			default:
				return undefined;
		}
	};

	return (
		<Paper
			elevation={actions ? 1 : 0}
			className={actions ? styles.position : styles.backgroundGrey}
		>
			<Box
				padding={actions ? 2 : 0}
				className={`${styles.title} ${
					actions && actionsList
						? styles.actions
						: stylesCard
						? styles.titleColorCard
						: ''
				}`}
				style={{ ...stylesCard }}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{Icon &&
						(actions ? (
							<Icon
								data-testid={`button-${label
									.toLowerCase()
									.replace(/ /g, '-')}`}
								className={styles.iconCards}
							/>
						) : (
							<Icon
								data-testid={`button-${label
									.toLowerCase()
									.replace(/ /g, '-')}`}
								style={{ ...stylesIcon }}
							/>
						))}
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{label}</span>
						{!actions && (
							<span className={styles.subtitleCard}>{subtitle}</span>
						)}
					</div>
				</div>
				{actions && actionsList && (
					<div>
						<span
							id={`more-${label.toLowerCase().replace(/ /g, '-')}`}
							aria-owns={open ? 'mouse-over-popover' : undefined}
							aria-haspopup="true"
							onClick={handlePopoverOpen}
						>
							<MoreHorizRounded className={styles.moreIcon} />
						</span>
						<Popover
							id="mouse-over-popover"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'center',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={open}
							onClose={handlePopoverClose}
						>
							<Box>
								{actionsList?.elements.map((element, key) => {
									return (
										<span key={key}>
											<div
												id={`${label
													.toLowerCase()
													.replace(/ /g, '-')}-${element.id}`}
												style={{
													padding: '15px',
													cursor: 'pointer',
												}}
												onClick={() => handleClickOption(element)}
											>
												{element.label}
											</div>
											<Divider />
										</span>
									);
								})}
							</Box>
						</Popover>
					</div>
				)}
			</Box>
		</Paper>
	);
};
