/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { IGetIGsByActivity } from '../interfaces/get-IGs-by-activity.interface';

interface IUseUpdateIGsActivity {
	setCheckedList: React.Dispatch<React.SetStateAction<Set<string>>>;
	setValueIGsByActivity: React.Dispatch<React.SetStateAction<any>>;
}

export const useUpdateIGsActivity = ({
	setCheckedList,
	setValueIGsByActivity,
}: IUseUpdateIGsActivity) => {
	const dispatch = useDispatch();

	const {
		execute: updateIGsActivity,
		status: statusUpdateIGsActivity,
		value: valueUpdateIGs,
	} = useApiRequest<{ supervisionUnits: any[] }, IResponsePayload<IGetIGsByActivity>>({
		path: URLS.PROJECT.MANY_SUPERVISION_UNIT,
		method: 'POST',
	});

	useEffect(() => {
		if (statusUpdateIGsActivity === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Información actualizada con éxito',
					severity: 'success',
				})
			);
			const temporalCollection = new Set<string>();
			valueUpdateIGs?.data?.forEach((element) => {
				if (element.applies) temporalCollection.add(element.indicator);
			});
			setCheckedList(temporalCollection);
			setValueIGsByActivity(valueUpdateIGs);
			dispatch(stopLoading());
		} else if (statusUpdateIGsActivity === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateIGsActivity]);

	const handleUpdateIGsActivity = (body: { supervisionUnits: any[] }) => {
		dispatch(setLoading({ loading: true }));
		updateIGsActivity(body);
	};

	return { handleUpdateIGsActivity };
};
