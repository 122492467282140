/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from './useApiRequest';
import { stopLoading } from '../store/modules/loading';
import { setAlert } from '../store/modules/snackbar';
import { URLS } from '../constants/urls.constants';
import { IProject } from '../interfaces/project.interface';
import { setValueProject } from '../store/modules/project';
import { RootState } from '../store';

const useUpdateProject = () => {
	const project = useSelector((state: RootState) => state.projectBase);
	const dispatch = useDispatch<any>();
	const { execute, status, value } = useApiRequest<
		Partial<IProject>,
		IResponsePayload<IProject>
	>({
		path: URLS.PROJECT.CREATE_PROJECT,
		method: 'PATCH',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Se actualizó el proyecto correctamente',
					severity: 'success',
				})
			);
			dispatch(setValueProject({ ...project, ...value?.detail }));
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	const handleUpdateProject = (
		dataValue: Partial<IProject> | undefined,
		id: string
	) => {
		execute({ ...dataValue }, `/${id}`);
	};

	return { handleUpdateProject, value, status };
};

export default useUpdateProject;
