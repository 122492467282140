/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { IGetSupervisionExtraordinary } from '../../../interfaces/get-supervision.interfaces';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setState } from '../../../../../store/modules/steps';
import { useStartSupervision } from './useStartSupervision';

export function useStartCreation() {
	const { idProject, setSupervisionExtraordinaryData } = useContext(
		CreateSupervisionContext
	);
	const dispatch = useDispatch();

	const { startSupervision } = useStartSupervision();

	const {
		execute: getExtraordinarySupervision,
		status: statusExtraordinarySupervision,
		value: valueExtraordinarySupervision,
	} = useApiRequest<unknown, IResponsePayload<IGetSupervisionExtraordinary>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.IN_PROGRESS,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getExtraordinarySupervision({}, `/${idProject}`);
	}, []);

	useEffect(() => {
		if (statusExtraordinarySupervision === 'success') {
			if (valueExtraordinarySupervision?.detail.isInProcess) {
				dispatch(stopLoading());
			} else {
				handleCreateNewSupervision();
			}
		} else if (statusExtraordinarySupervision === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusExtraordinarySupervision]);

	const handleCreateNewSupervision = () => {
		dispatch(setLoading({ loading: true }));
		startSupervision({}, `/${idProject}`);
	};

	const handleContinueSupervision = () => {
		dispatch(setState({ state: 1 }));
		setSupervisionExtraordinaryData({
			functionalUnit: '',
			idSupervisionExtraordinary:
				valueExtraordinarySupervision?.detail.extraordinarySupervision._id || '',
			step:
				valueExtraordinarySupervision?.detail.extraordinarySupervision.step ||
				'1.0.0',
		});
	};

	return { handleCreateNewSupervision, handleContinueSupervision };
}
