import { IOptionsMenu } from '../../../../interfaces/select.interface';

export const getTypeFindingsAdapter = (data: string[]): IOptionsMenu[] => {
	const newOptions: IOptionsMenu[] = data.map((element) => {
		return {
			id: element,
			label: element,
			value: element,
		};
	});
	return newOptions;
};
