/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { IDocument } from '../../../../../interfaces/project.interface';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';

export const useSetCoverImage = () => {
	const dispatch = useDispatch();

	const {
		execute: executeSetCoverImage,
		status: statusSetCoverImage,
		value: valueSetCoverImage,
	} = useApiRequest<unknown, IResponsePayload<IDocument>>({
		path: URLS.PROJECT.SET_COVER_IMAGE,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusSetCoverImage === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Se actualizó la imagen del proyecto',
					severity: 'success',
				})
			);
		} else if (statusSetCoverImage === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueSetCoverImage?.message ||
						'Ocurrió un error al actualizar la imagen del proyecto',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusSetCoverImage]);

	const setCoverImage = (id: string) => {
		dispatch(setLoading({ loading: true }));
		executeSetCoverImage(null, `/${id}`);
	};

	return { setCoverImage, statusSetCoverImage };
};
