/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GoBack } from '../../../../components/go-back-component';
import InputSelect from '../../../../components/select-input.component';
import TableCustom from '../../../../components/table-custom.component';
import { useRoutineSupervision } from '../../hooks/useRoutineSupervision';
import { setLoading } from '../../../../store/modules/loading';
import TextInput from '../../../../components/text-input.component';
import {
	optionsMonthsSupervisionHistory,
	optionsTypeSupervisionHistory,
	columnsRoutineMonitoring,
} from '../../utils/options-history.utils';
import { RootState } from '../../../../store';

interface IRoutineList {
	setOption: React.Dispatch<React.SetStateAction<string>>;
	setCanBeExecuted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RoutineList = ({ setOption, setCanBeExecuted }: IRoutineList) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location?.state?.params;
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const { _id } = useSelector((state: RootState) => state.projectBase);
	const {
		dataTable,
		setDataTable,
		executeGetSupervisionsComplete,
		executeGetSupervisionsPending,
		dataSelect,
		setDataSelect,
		handleGetFiltersParams,
	} = useRoutineSupervision({ setCanBeExecuted });

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		dataSelect.isHistoryCompletedRoutine === 'true'
			? executeGetSupervisionsComplete(
					{},
					`?page=${dataTable.pagination}&recordsByPage=${
						dataTable.rowsPerPage
					}&project=${state?.project?._id || _id}${handleGetFiltersParams()}`
			  )
			: executeGetSupervisionsPending(
					{},
					`?page=${dataTable.pagination}&recordsByPage=${
						dataTable.rowsPerPage
					}&project=${state?.project?._id || _id}${handleGetFiltersParams()}`
			  );
	}, [
		dataTable.pagination,
		dataTable.rowsPerPage,
		state?.project?._id || _id,
		dataSelect,
	]);

	const handleChange = (e: any, property: any) => {
		(dataSelect[property] as any) = e.target.value;
		setDataTable({ ...dataTable, pagination: '1' });
		setDataSelect({ ...dataSelect });
	};

	const handleChangeType = (e: any, property: any) => {
		(dataSelect[property] as any) = e.target.value;
		setDataTable({ ...dataTable, pagination: '1' });
		setDataSelect({ ...dataSelect });
	};

	const [year, setYear] = useState<string>(dataSelect.executionYear || '');
	const handleChangeYear = (e: any) => {
		setYear(e.target.value);
		if (e.target.value.length === 4) {
			handleChange(e, 'executionYear');
		}
		if (e.target.value === '') {
			handleChange(e, 'executionYear');
		}
	};

	return (
		<>
			<GoBack action={() => setOption('')} />
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Supervisión por rutina
							</span>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs md={2}>
							<span style={{ fontSize: '18px' }}>
								Temporada de supervisión
							</span>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<InputSelect
								value={dataSelect.executionMonth || ''}
								placeholder="Mes"
								optionsMenu={optionsMonthsSupervisionHistory}
								onChange={(e) => handleChange(e, 'executionMonth')}
								id="select-month"
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<TextInput
								id="year"
								placeholder="Año"
								InputProps={{
									sx: { height: '50px', backgroundColor: '#F7F7F7' },
								}}
								value={year}
								name="year"
								onChange={(e) => handleChangeYear(e)}
								type={'number'}
							/>
						</Grid>
						<Grid item sm={1} md={4} display={matches ? 'none' : ''} />
						<Grid item xs sm={3} md={2}>
							<span style={{ fontSize: '18px' }}>Tipo de supervisión</span>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<InputSelect
								value={dataSelect.isHistoryCompletedRoutine}
								placeholder="Tipo supervisión"
								optionsMenu={optionsTypeSupervisionHistory}
								onChange={(e) =>
									handleChangeType(e, 'isHistoryCompletedRoutine')
								}
								id="select-type"
							/>
						</Grid>
						<TableCustom
							columns={columnsRoutineMonitoring}
							data={dataTable}
							setData={setDataTable}
							hideHeader
							showPagination
							showLimitPerPage
						/>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
