/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { uniqueId } from '../../../utils/uniqueId';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import { isEmpty } from '../../../utils/validators';
import { RootState } from '../../../store';

interface IProps {
	setIsIG2Show: TReactDispatch<boolean>;
}
const useGeneralIndicator = (props: IProps) => {
	const [generalIndicator, setGeneralIndicator] = useState<IStepTwoModel[]>([]);
	const [isGeneralDone, setIsGeneralDone] = useState(false);
	const { setIsIG2Show } = props;

	const dispatch = useDispatch();

	const { generalIndicatorBase } = useSelector((state: RootState) => state.projectBase);
	const { execute, status, value } = useApiRequest<
		unknown,
		IResponsePayload<IStepTwoModel>
	>({
		path: URLS.PROJECT.INDICATOR,
		method: 'GET',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (value?.data) {
				if (!isEmpty(generalIndicatorBase) && value.data.length === 0) {
					const adapted = [
						{
							id: uniqueId(),
							attributes: generalIndicatorBase?.attributes,
							columns: generalIndicatorBase?.attributes.map((_) => ''),
							isComplete: false,
							name: generalIndicatorBase?.name,
							mainAttribute: generalIndicatorBase?.mainAttribute,
						},
					];
					setGeneralIndicator(adapted as IStepTwoModel[]);
					return;
				}
				const dataFlag = (value?.data || []).map((ele) => ({
					...ele,
					id: uniqueId(),
					isComplete: true,
				}));
				if (dataFlag.length > 0) setIsIG2Show(true);
				setGeneralIndicator(dataFlag);
			} else {
				if (!isEmpty(generalIndicatorBase)) {
					const adapted = [
						{
							id: uniqueId(),
							attributes: generalIndicatorBase?.attributes,
							columns: generalIndicatorBase?.attributes.map((_) => ''),
							isComplete: false,
							name: generalIndicatorBase?.name,
							mainAttribute: generalIndicatorBase?.mainAttribute,
						},
					];
					setGeneralIndicator(adapted as IStepTwoModel[]);
				}
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return {
		execute,
		setGeneralIndicator,
		generalIndicator,
		isGeneralDone,
		setIsGeneralDone,
		status,
		value,
	};
};

export default useGeneralIndicator;
