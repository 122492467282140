import { IGetIGs } from '../interfaces/get-IGs.interface';

export const getIGsAdapter = (data: IGetIGs[]) => {
	const listIGs = data.map((element) => {
		return {
			_id: element._id,
			value: element.columns[0] || '',
		};
	});
	return listIGs;
};
