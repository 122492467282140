/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField, styled, useMediaQuery } from '@mui/material';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';
import styles from '../../../../../components/styles/item-text-area-styles.module.css';
import { useEffect, useState } from 'react';
import theme from '../../../../../config/theme';
import { EditButton } from '../../../../../components/buttons.component';
import { Delete } from '@mui/icons-material';

interface IItem {
	item: IAdaptedValues;
	values: { [key: number]: string };
	column: number;
	setValues: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>;
	onDelete: (row: number) => void;
}

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

export const EditFindingRow = ({ item, column, values, setValues, onDelete }: IItem) => {
	const [value, setValue] = useState(values[column]);
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const keys = Object.keys(values);

	useEffect(() => {
		setValue(values[column]);
	}, [values]);

	return (
		<>
			<Grid item xs>
				<p>{`${item.mainAttribute} ${column + 1}`}</p>
			</Grid>
			<Grid
				item
				xs={matches ? 8 : 9}
				className={styles.renderItem}
				style={{ display: 'flex', flexDirection: 'column' }}
			>
				<Input
					id={`text-input-${column}`}
					variant="outlined"
					size="small"
					fullWidth
					name={keys[column]}
					placeholder="Escribe aquí"
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
						setValues({ ...values, [e.target.name]: e.target.value });
					}}
				/>
			</Grid>

			<Grid item>
				{!item.id && (
					<EditButton
						id={`delete-${column}`}
						onClick={() => onDelete(column)}
						icon={<Delete sx={{ fontSize: '15px' }} />}
						styles={{
							background: 'var(--color-error-light)',
							color: 'var(--color-error-main)',
						}}
					/>
				)}
			</Grid>
		</>
	);
};
