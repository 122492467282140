import { Grid } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import { columnsAllActions } from '../../../utils/columns-supervision';
import TableCustom from '../../../../../components/table-custom.component';

interface IViewAllActions {
	dataActions: any;
	setDataActions: React.Dispatch<React.SetStateAction<any>>;
	setStepRoutine: React.Dispatch<React.SetStateAction<string>>;
}

export const ViewAllActions = ({
	dataActions,
	setDataActions,
	setStepRoutine,
}: IViewAllActions) => {
	return (
		<Grid item xs={12}>
			<TableCustom
				columns={columnsAllActions}
				data={dataActions}
				setData={setDataActions}
				buttons={[
					{
						icon: RemoveRedEye,
						label: 'Ver menos',
						execute: () => {
							setStepRoutine('1');
							setDataActions({
								...dataActions,
								pagination: '1',
								rowsPerPage: '5',
							});
						},
						type: 'blue',
						variant: 'outlined',
						width: 150,
						id: 'view-all-action',
					},
				]}
				showSearch={false}
				showLimitPerPage
				showPagination
			/>
		</Grid>
	);
};
