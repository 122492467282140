/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from 'react';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';
import { ListRoles } from '../components/list-roles.component';
import { EditBaseRole } from '../components/edit-base-role.component';
import { AssignPersonal } from '../components/assign-personal.component';
import { useLocation } from 'react-router-dom';

export const useCreatePersonal = () => {
	const location = useLocation();
	const isEdit =
		(location?.state?.params?.isEditing as boolean) ||
		location.pathname.includes('perfil-proyecto');
	const isAutomaticCharge = location.pathname.includes('creacion-automatica');

	const [step, setStep] = useState<number>(0);
	const [currentRol, setCurrentRol] = useState<IAdaptedValues>({
		id: '',
		mainAttribute: '',
		description: '',
		rows: [],
		frequency: '',
	} as unknown as IAdaptedValues);

	const [currentBase, setCurrentBase] = useState<IAdaptedValues[]>([
		{
			id: '',
			mainAttribute: '',
			description: '',
			rows: [],
			frequency: '',
		},
	] as unknown as IAdaptedValues[]);

	const getComponent = () => {
		switch (step) {
			case 0:
			default:
				return (
					<ListRoles
						setRole={setCurrentRol}
						setStep={setStep}
						setCurrenBase={setCurrentBase}
						isEdit={isEdit}
						isAutomaticCharge={isAutomaticCharge}
					/>
				);
			case 1:
				return (
					<EditBaseRole
						role={currentRol}
						setStep={setStep}
						roleAttributes={currentBase}
						setCurrentRole={setCurrentRol}
						setCurrentBase={setCurrentBase}
						isEdit={isEdit}
						isAutomaticCharge={isAutomaticCharge}
					/>
				);
			case 2:
				return (
					<AssignPersonal
						role={currentRol}
						setStep={setStep}
						isEdit={isEdit}
						isAutomaticCharge={isAutomaticCharge}
					/>
				);
		}
	};

	return { isEdit, isAutomaticCharge, getComponent };
};
