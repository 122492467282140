import { IColumns } from '../../../interfaces/table.insterface';

export const columns: IColumns[] = [
	{
		text: 'No. de empleado',
		nameID: 'employeeNumber',
	},
	{
		text: 'Nombre del empleado',
		nameID: 'employeeName',
	},
	{
		text: 'Correo electrónico',
		nameID: 'email',
	},
	{
		text: 'Puesto',
		nameID: 'position',
	},
	{
		text: 'Rol',
		nameID: 'role',
	},
	{
		text: 'Estatus',
		nameID: 'status',
	},
	{
		text: 'Ultima conexión',
		nameID: 'date',
	},
];

export const columnsTeams: IColumns[] = [
	{
		text: 'Nombre del equipo',
		nameID: 'teamName',
	},
	{
		text: 'Integrantes',
		nameID: 'names',
	},
];
