/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { EditFormIndicators } from '../edit-form-indicators.component';
import { ViewActivities } from '../view-activities.component';
import { EditIndicator } from '../edit-indicator.component';
import { useLocation } from 'react-router-dom';
import { IIndicatorModel } from '../../../../interfaces/project.interface';
import { CreateBase } from '../../../../components/create-base.component';
import useUpdateProject from '../../../../hooks/useUpdateProject';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { HeaderEdit } from '../../../../components/header-edit.component';
import CriteriosIcon from '../../../../assets/icons/criterios.icon';

interface IUseEditIndicators {
	isGeneralIndicator: boolean;
}

export const useEditIndicators = ({ isGeneralIndicator }: IUseEditIndicators) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const idProject = location.state.params.idProject as string;
	const [structureData, setStructureData] = useState(
		location.state.params.structureData as IIndicatorModel
	);

	const [step, setStep] = useState<number>(
		!structureData ? -1 : isGeneralIndicator ? 1 : 0
	);

	const [currentActivity, setCurrentActivity] = useState('');
	const [currentIndicator, setCurrentIndicator] = useState<IAdaptedValues>({
		id: '',
		mainAttribute: '',
		description: '',
		rows: [],
		frequency: '',
	} as unknown as IAdaptedValues);

	const {
		handleUpdateProject,
		status: statusProject,
		value: valueProject,
	} = useUpdateProject();

	useEffect(() => {
		if (statusProject === 'success') {
			dispatch(stopLoading());
			if (valueProject?.detail?.generalIndicatorBase && isGeneralIndicator)
				setStructureData(valueProject.detail.generalIndicatorBase);
			if (valueProject?.detail?.specificIndicatorBase && !isGeneralIndicator)
				setStructureData(valueProject.detail.specificIndicatorBase);
			isGeneralIndicator ? setStep(1) : setStep(0);
		} else if (statusProject === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la base',
					severity: 'error',
				})
			);
		}
	}, [statusProject]);

	const onSave = (newBase: IIndicatorModel) => {
		dispatch(setLoading({ loading: true }));
		const body = isGeneralIndicator
			? { generalIndicatorBase: newBase }
			: { specificIndicatorBase: newBase };
		handleUpdateProject(body, idProject);
	};

	const getComponent = () => {
		switch (step) {
			case -1:
				return (
					<CreateBase
						header={
							<HeaderEdit
								section="Criterio"
								title="Nombra tus criterios, así como su atributo principal y los secundarios pertinentes."
								alertText="Designa el nombre genérico de los criterios de evaluación generales a considerar en la supervisión según lo indique el contrato"
								icon={<CriteriosIcon size="23px" />}
							/>
						}
						label="Criterios"
						onSaveCustom={onSave}
					/>
				);
			case 0:
				return (
					<ViewActivities
						setStep={setStep}
						setCurrentIndicator={setCurrentActivity}
					/>
				);
			case 1:
			default:
				return (
					<EditFormIndicators
						idActivity={currentActivity}
						setStep={setStep}
						setCurrentIndicator={setCurrentIndicator}
						isGeneralIndicator={isGeneralIndicator}
						structureData={structureData}
					/>
				);
			case 2:
				return (
					<EditIndicator
						setStep={setStep}
						currentIndicator={currentIndicator}
						setCurrentIndicator={setCurrentIndicator}
						isGeneralIndicator={isGeneralIndicator}
					/>
				);
		}
	};

	return { getComponent };
};
