import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/styles.module.css';
import { Button } from '../../../components/main-button.component';

export const NoResults = () => {
	const navigate = useNavigate();

	return (
		<Grid item sm={12}>
			<div className={styles.mainContainer}>
				<div className={styles.infoContainer}>
					<h4 style={{ margin: 0 }}>
						Aún no has finalizado ningún proyecto, da clic en el siguiente
						botón para ver todos los proyectos.
					</h4>
					<div className={styles.buttonsContainer}>
						<Button
							label={'Ver proyectos'}
							onClick={() => navigate('/proyectos')}
							variant="solid"
							style={{
								width: '100%',
								backgroundColor: '#0080F9',
							}}
						/>
					</div>
				</div>
			</div>
		</Grid>
	);
};
