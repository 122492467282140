import { IMenuList, styleIcon, styleIconContainer } from '../utils/resources.utils';
import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/svg/projects.svg';
import { ReactComponent as DevicesIcon } from '../../assets/svg/devices.svg';
import Home from '../../pages/home';
import { UserProfile } from '../../pages/user-profile';
import { SvgIcon } from '@mui/material';
import { ProjectProfile } from '../../pages/profile-project';
import { SupervisionHistory } from '../../pages/supervision-history';
import { SupervisionExtraordinary } from '../../pages/supervision-extraordinary';
import { SupervisionDetail } from '../../pages/supervision-detail-project';
import { NextTasks } from '../../pages/next-tasks';
import { SupervisionDetailTasks } from '../../pages/supervision-detail-project/supervisionDetailTasks';
import { ExtraordinaryIncidence } from '../../pages/extraordinary-incidence';
import { SummaryRoutine } from '../../pages/supervision-project/components/summary-routine/summary-routine.component';
import { SupervisionComponent } from '../../pages/supervision-project';
import Suspension from '../../pages/suspension/index';
import SuspensionComponent from '../../pages/suspension-detail-project';
import NotificationList from '../../pages/notifications';
import { CreateSupervisionExtraordinary } from '../../pages/create-supervision-extraordinary';
import { SuspendRoutine } from '../../pages/suspend-routine';
import { SummaryTasks } from '../../pages/summary-tasks';
import { ViewBaseTasks } from '../../pages/view-base-task';
import { EditNonWorkingDays } from '../../pages/edit-schedule/components/non-working-days/non-working-days.component';
import { EditScheduleActivity } from '../../pages/edit-schedule/components/schedule-activity/schedule-activity.component';
import { EditScheduleByIndicator } from '../../pages/edit-schedule/components/schedule-by-indicator/schedule-by-indicator.component';
import { EditScheduleByLevel } from '../../pages/edit-schedule/components/schedule-by-level/schedule-by-level.component';
import { EditAssigments } from '../../pages/edit-assigments/edit-assigments.component';
import { CreatePersonal } from '../../pages/personal/components/create-personal/create-personal.component';
import { CreateTeams } from '../../pages/personal/components/create-teams/create-teams.component';
import { UserManagment } from '../../pages/user-project-management/user-management.component';

export const RouteManagerList: IMenuList[] = [
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Inicio',
		link: '/inicio',
		Component: Home,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Mi Perfil',
		link: '/mi-perfil',
		Component: UserProfile,
		hideMenu: true,
	},
	{
		icon: (
			<SvgIcon>
				<ProjectsIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Perfil de proyecto',
		link: '/perfil-proyecto',
		hideMenu: true,
		Component: ProjectProfile,
		submenu: [
			{
				title: 'Detalle de la supervisión',
				link: '/ver-supervision',
				Component: SummaryRoutine,
				hideMenu: true,
			},
			{
				title: 'Historial de supervisión',
				link: '/historial-supervision',
				Component: SupervisionHistory,
				hideMenu: true,
			},
			{
				title: 'Incidencia extraordinaria',
				link: '/incidencia-extraordinaria',
				Component: ExtraordinaryIncidence,
				hideMenu: true,
			},
			{
				title: 'Supervision',
				link: '/supervision',
				Component: SupervisionComponent,
				hideMenu: true,
			},
			{
				title: 'Suspensiones',
				link: '/suspensiones',
				hideMenu: true,
				Component: Suspension,
				submenu: [
					{
						title: 'Detalle de la suspensión',
						link: '/ver-suspension',
						Component: SuspensionComponent,
						hideMenu: true,
					},
				],
			},
			{
				icon: (
					<SvgIcon>
						<ProjectsIcon style={styleIcon} />
					</SvgIcon>
				),
				title: 'Supervisión extraordinaria',
				link: '/supervision-extraordinaria',
				hideMenu: true,
				Component: SupervisionExtraordinary,
				submenu: [
					{
						title: 'Detalle de la supervisión',
						link: '/ver-supervision',
						Component: SupervisionDetail,
						hideMenu: true,
					},
					{
						title: 'Crear supervisión extraordinaria',
						link: '/crear-supervision-extraordinaria',
						hideMenu: true,
						Component: CreateSupervisionExtraordinary,
					},
				],
			},
			{
				icon: (
					<SvgIcon>
						<ProjectsIcon style={styleIcon} />
					</SvgIcon>
				),
				title: 'Próximas tareas',
				link: '/proximas-tareas',
				hideMenu: true,
				Component: NextTasks,
				submenu: [
					{
						title: 'Detalle de la supervisión',
						link: '/ver-supervision',
						Component: SupervisionDetailTasks,
						hideMenu: true,
						submenu: [
							{
								title: 'Suspender supervisión',
								link: '/suspender-supervision',
								Component: SuspendRoutine,
								hideMenu: true,
							},
						],
					},
				],
			},
			{
				title: 'Resumen de tareas',
				link: '/resumen-tareas',
				Component: SummaryTasks,
				submenu: [
					{
						title: 'Ver tareas base',
						link: '/ver-tareas-base',
						Component: ViewBaseTasks,
					},
				],
			},
			{
				title: 'Cronograma Unidad',
				link: '/cronograma-unidad',
				Component: EditScheduleByLevel,
			},
			{
				title: 'Cronograma Servicio',
				link: '/cronograma-actividad',
				Component: EditScheduleActivity,
			},
			{
				title: 'Cronograma Indicador',
				link: '/cronograma-indicador',
				Component: EditScheduleByIndicator,
			},
			{
				title: 'Cronograma Laborales',
				link: '/cronograma-editar-dias-laborales',
				Component: EditNonWorkingDays,
			},
			{
				title: 'Modificar asignaciones',
				link: '/edicion-asignaciones',
				Component: EditAssigments,
			},
			{
				title: 'Modificar equipos',
				link: '/edicion-equipos',
				Component: CreateTeams,
			},
			{
				title: 'Edición roles',
				link: '/edicion-roles',
				Component: CreatePersonal,
				submenu: [
					{
						title: 'Desactivar usuario',
						link: '/desactivar-usuario',
						Component: UserManagment,
						hideMenu: true,
					},
				],
			},
		],
	},
	{
		icon: (
			<SvgIcon>
				<DevicesIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Notificaciones',
		link: '/notificaciones',
		Component: NotificationList,
		hideMenu: true,
	},
];
