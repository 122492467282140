import React from 'react';

interface IProps {
	disabled?: boolean;
}

function Icon(props: IProps) {
	const { disabled = false } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="44.06"
			data-name="Grupo 35755"
			viewBox="0 0 50 44.06"
		>
			<g data-name="Grupo 35608">
				<g data-name="Grupo 35607">
					<g fill={disabled ? '#00000042' : '#10a243'} data-name="Grupo 35606">
						<text
							data-name="Subir CSV"
							fontFamily="ArialMT, Arial"
							fontSize="10"
							transform="translate(0 42.06)"
						>
							<tspan x="0" y="0">
								Subir CSV{' '}
							</tspan>
						</text>
						<path
							d="M17.653 26.414h1.984v-6.645l2.711 2.711 1.389-1.389-5.157-5.025-5.091 5.091 1.389 1.389 2.777-2.777zm-7.67 4.033A2.034 2.034 0 018 28.464V5.984A1.9 1.9 0 018.6 4.6 1.9 1.9 0 019.984 4h11.934l7.24 7.24v17.224a2.034 2.034 0 01-1.984 1.984zm10.943-18.314V5.984H9.984v22.48h17.191V12.133zM9.984 5.984z"
							transform="translate(5.224 -4)"
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Icon;
