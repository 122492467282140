import TableCustom from '../../../components/table-custom.component';
import { columnsEvidencesDetail } from '../utils/columns-supervision';
import { useGetEvidences } from './hooks/useGetEvidencesTable';
import { Image } from '@mui/icons-material';
import { useDownloadImageZip } from './hooks/useDownloadImageZip';
interface IEvidences {
	currentState?: string;
	isSupervisionRoutine: boolean;
}

export const Evidences = ({ currentState, isSupervisionRoutine }: IEvidences) => {
	const { handleDownloadZip } = useDownloadImageZip();
	const {
		data,
		idRoutine,
		_id,
		idRoutineParams,
		idProject,
		idProjectParams,
		buttons,
		setData,
	} = useGetEvidences({
		currentState,
		isSupervisionRoutine,
	});

	return (
		<TableCustom
			titleHeader="Evidencias"
			columns={columnsEvidencesDetail}
			data={data}
			setData={setData}
			showPagination
			showLimitPerPage
			showSearch={false}
			buttons={
				data.rows.length !== 0
					? [
							...buttons,
							{
								icon: Image,
								label: 'Descargar evidencias',
								id: 'descarga-evidencias',
								execute: () => {
									handleDownloadZip(
										'supervision-routine-files',
										idRoutineParams || idRoutine,
										_id || idProjectParams || idProject,
										data.pagination,
										data.rowsPerPage || '0'
									);
								},
								type: 'blue',
								width: 200,
							},
					  ]
					: [...buttons]
			}
		/>
	);
};
