/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderUser } from './components/header-user.component';
import styles from './components/styles/header-user-styles.module.css';
import { useUserProfile } from './hooks/useUserProfile';
import { Tabs, Tab } from '@mui/material';

export const UserProfile = () => {
	const {
		userInfo,
		currentTab,
		tabItems,
		matches,
		setCurrentTab,
		setStep,
		getComponent,
	} = useUserProfile();

	return (
		<>
			<HeaderUser user={userInfo} />

			<Tabs
				value={currentTab}
				onChange={(_, value) => {
					setCurrentTab(value);
					setStep(value);
				}}
				TabIndicatorProps={{ style: { background: '#0080F9' } }}
				sx={{
					'& button.Mui-selected': {
						color: '#0080F9',
					},
				}}
				orientation={matches ? 'vertical' : 'horizontal'}
			>
				{(tabItems || []).map((element, index: number) => {
					return (
						<Tab
							key={index}
							id={`tab-${element.label.replaceAll(' ', '-').toLowerCase()}`}
							className={styles.tabsStyle}
							label={element.label}
						/>
					);
				})}
			</Tabs>
			{getComponent()}
		</>
	);
};
