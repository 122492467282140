import { getDateLocalMX } from '../../../utils/dates';
import { IGetBaseTasks } from '../interfaces/get-base-tasks.interface';

export const baseTaskAdapter = (data: IGetBaseTasks[]) => {
	const newData = data.map((task: IGetBaseTasks) => {
		return {
			...task,
			functional: task.functionalChild,
			activity: task.claveServicio,
			indicator: task.indicador,
			assignedTo: task.supervisorName ?? task.teamName,
			lastExecutionDate: getDateLocalMX(task.lastExecutionDate),
		};
	});
	return newData;
};
