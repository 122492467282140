import { IOptionsMenu } from '../../../interfaces/select.interface';
import { IFrequencies } from '../interfaces/get-frequencies.interface';

export const getFrequenciesAdapter = (data: IFrequencies[]): IOptionsMenu[] => {
	const newOptions: IOptionsMenu[] = data.map((element) => {
		return {
			id: element._id,
			label: element.description,
			value: element._id,
		};
	});
	return newOptions;
};
