import { useState } from 'react';
import { IVerticalStepperAttributes } from '../../../../interfaces/vertical.steper.attributes-fixed.interface';
import StepOneComponent, {
	TFunctionHandler,
} from '../../../../components/step-one-fixed';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';

const generalCriterio: IVerticalStepperAttributes[] = [
	{
		index: 0,
		name: 'Criterio',
		placeholder: 'Nombre del criterio',
		value: '',
		isComplete: false,
	},
	{
		index: 1,
		name: 'Atributo Principal',
		placeholder: 'Nombre del atributo',
		value: '',
		isComplete: false,
	},
];

interface IStep {
	onContinue?: (data: IStepTwoModel[]) => void;
	defaultActividad: IVerticalStepperAttributes[] | null;
	disableNext: boolean | null;
	resetDone?: null | true;
	isDefaultDone?: boolean;
	refComponent: React.RefObject<TFunctionHandler>;
}

const StepOneContent = (props: IStep) => {
	const {
		onContinue,
		defaultActividad,
		disableNext,
		resetDone,
		isDefaultDone,
		refComponent,
	} = props;
	const [activities, setActivities] =
		useState<IVerticalStepperAttributes[]>(generalCriterio);
	return (
		<StepOneComponent
			ref={refComponent}
			data={activities}
			resetDone={resetDone}
			disableNext={disableNext}
			setData={setActivities}
			onContinue={onContinue}
			defaultData={defaultActividad}
			isDefaultDone={isDefaultDone}
		/>
	);
};
export default StepOneContent;
