import { Check } from '@mui/icons-material';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { INotification } from '../interfaces/notifications.interface.utils';
import { Box } from '@mui/material';

interface INotificationAdated extends INotification {
	actions: JSX.Element;
}
export const notificationsAdapter = (
	data: INotification[],
	changeNotificationStatus: (id: string) => void
): INotificationAdated[] => {
	const newData: INotificationAdated[] = data.map((notification: INotification) => {
		return {
			...notification,
			actions: (
				<Box display="flex" flexDirection="row" gap="10px">
					<ButtonIconSmall
						text="Marcar visto"
						type="info"
						icon={<Check />}
						onPress={() => changeNotificationStatus(notification.id)}
						style={{ width: '100%' }}
						disabled={notification.state !== 'unread'}
					/>
				</Box>
			),
		};
	});
	return newData;
};
