/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from 'react';
import { ICreationPage } from '../../../../../interfaces/creation-page-interfaces';
import { statusCreation } from '../../../constants/creation-supervision.component';
import { StepOneStandars } from '../components/step-one.component';
import { useGetFunctionals } from '../../geographical-dimensions/hooks/useGetFunctionals';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../../store/modules/loading';
import { useChangeStep } from '../../../hooks/useChangeStep';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';

export function useStandars() {
	const dispatch = useDispatch();
	const { idProject, modal, supervisionExtraordinaryData, setModal } = useContext(
		CreateSupervisionContext
	);

	const { functionalOptions, getFunctionals } = useGetFunctionals({
		isLevelOne: true,
		getOptions: true,
	});
	const { changeStepSE } = useChangeStep();

	const StandarsSteps: ICreationPage = {
		header: {
			label: 'Estado de creación',
			stepper: {
				activeStep: 2,
				items: statusCreation,
				completed: {},
			},
		},
		stepOne: {
			title: '',
			show: true,
			Children: <StepOneStandars functionalOptions={functionalOptions} />,
		},
	};

	const undoStep = () => {
		setModal({
			...modal,
			open: true,
			callback: () => {
				dispatch(setLoading({ loading: true }));
				changeStepSE(
					{
						newStep: STEPS_SUPERVISION_EXTRAORDINARY.ACT_2,
						extraordinarySupervision:
							supervisionExtraordinaryData.idSupervisionExtraordinary,
					},
					`/${idProject}`
				);
			},
		});
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getFunctionals(
			{},
			`?project=${idProject}&functional=null&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&applies=true`
		);
	}, []);

	return { StandarsSteps, undoStep };
}
