import {
	FactCheckOutlined,
	PendingActions,
	RemoveRedEye,
	ReportGmailerrorredOutlined,
	WorkHistoryOutlined,
} from '@mui/icons-material';
import { getTableValues } from '../../../../utils/tableInfo';
import ButtonIconSmall from '../../../../components/button-icon-small.component';
import { STATUS_SUPERVISION_TYPES } from '../../../../constants/status-supervision';
import { ISupervisionRoutineData } from '../interfaces/get-supervision-routines.interface';

export const areasAdapter = (
	data: ISupervisionRoutineData[],
	type: string,
	handleAction: (status: string, element: ISupervisionRoutineData) => void
) => {
	const newData = data.map((element: ISupervisionRoutineData) => {
		const rowsWithoutFormat = getValuesWithoutFormat(element, type);
		return {
			...getTableValues(rowsWithoutFormat),
			acciones: (
				<div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
					<ButtonIconSmall
						text="Cumple"
						type="success"
						icon={<FactCheckOutlined />}
						style={{ fontSize: '11px' }}
						onPress={() =>
							handleAction(STATUS_SUPERVISION_TYPES.ACCOMPLISHED, element)
						}
						isSelected={
							element.status === STATUS_SUPERVISION_TYPES.ACCOMPLISHED
						}
						disabled={
							element.status ===
								STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED ||
							element.status === STATUS_SUPERVISION_TYPES.SUSPENDED
						}
					/>
					<ButtonIconSmall
						text="No Cumple"
						type="error"
						icon={<ReportGmailerrorredOutlined />}
						style={{ fontSize: '11px' }}
						onPress={() =>
							handleAction(
								STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED,
								element
							)
						}
						isSelected={
							element.status === STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED
						}
						disabled={element.status === STATUS_SUPERVISION_TYPES.SUSPENDED}
					/>
					<ButtonIconSmall
						text="Por validar"
						type="warning"
						icon={<WorkHistoryOutlined />}
						style={{ fontSize: '11px' }}
						onPress={() =>
							handleAction(
								STATUS_SUPERVISION_TYPES.PENDING_TO_VALIDATE,
								element
							)
						}
						isSelected={
							element.status ===
							STATUS_SUPERVISION_TYPES.PENDING_TO_VALIDATE
						}
						disabled={
							element.status ===
								STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED ||
							element.status === STATUS_SUPERVISION_TYPES.SUSPENDED
						}
					/>
					<ButtonIconSmall
						text="Suspender"
						type="yellow"
						style={{ fontSize: '11px' }}
						icon={<PendingActions />}
						onPress={() =>
							handleAction(STATUS_SUPERVISION_TYPES.SUSPENDED, element)
						}
						isSelected={element.status === STATUS_SUPERVISION_TYPES.SUSPENDED}
						disabled={
							element.status === STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED
						}
					/>
					<ButtonIconSmall
						text="Ver"
						type="info"
						icon={<RemoveRedEye />}
						style={{ fontSize: '11px' }}
						onPress={() => handleAction('ver', element)}
					/>
				</div>
			),
		};
	});
	return newData;
};

const getValuesWithoutFormat = (element: ISupervisionRoutineData, type: string) => {
	const rows =
		type === 'servicio'
			? [
					...(element?.functionalParentColumns || []),
					...(element?.functionalColumns || []),
					...(element?.indicatorColumns || []),
			  ]
			: [
					...(element?.functionalColumns || []),
					...(element?.activityColumns || []),
					...(element?.indicatorColumns || []),
			  ];
	return rows;
};
