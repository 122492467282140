/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { URLS } from '../../../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useGetPersonalByProject } from '../../../../../hooks/usePersonalByProject';

export const useGetSupervisors = () => {
	const [activities, setActivities] = useState<IOptionsMenu[]>([]);
	const dispatch = useDispatch();

	const {
		getPersonalByProject: executeGetSupervisors,
		usersData: supervisors,
		getIsTeam,
	} = useGetPersonalByProject({ addTeams: true, addAllOption: true });

	const {
		execute: executeGetActivities,
		status: statusActivities,
		value: valueActivities,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.SUPERVISION_UNIT.GET_ACTIVITIES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusActivities === 'success') {
			let data: any[] = [];
			data = (valueActivities?.data || [])?.map((i, idx) => ({
				id: idx,
				label: i.description,
				value: i._id,
			}));

			data.unshift({ id: 'all', label: 'Todos', value: 'all' });

			setActivities(data || []);
		} else if (statusActivities === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al cargar las actividades',
					severity: 'error',
				})
			);
		}
	}, [statusActivities]);

	return {
		executeGetSupervisors,
		executeGetActivities,
		activities,
		supervisors,
		getIsTeam,
	};
};
