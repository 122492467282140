import { Grid, useMediaQuery } from '@mui/material';
import SelectComponent from '../../../components/select.component';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import styles from './styles/item-assignment-styles.module.css';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import theme from '../../../config/theme';
import { useState } from 'react';

interface IItemAssignment {
	item: IAdaptedValues;
	column: number;
	userList: IOptionsMenu[];
	onEdit: (idActivity: string, column: number, newValue: string) => void;
}

export const AssignmentRow = ({ item, column, userList, onEdit }: IItemAssignment) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [data, setData] = useState<{ [key: string]: string }>({
		Ejecutor: item.rows[0].value,
		Supervisor: item.rows[1].value,
		'Sustituto 1': item.rows[2].value,
		'Sustituto 2': item.rows[3].value,
	});

	return (
		<Grid item xs={matches ? 10 : 11} className={styles.renderItem}>
			<Grid item xs="auto">
				<span style={{ fontWeight: 'bold' }}>{item.description}</span>
			</Grid>
			{item.rows.map((el, row) => (
				<Grid item key={`superv-table-${column}-${row}`}>
					<SelectComponent
						id={`superv-table-${column}-${row}`}
						formSx={{ width: '250px' }}
						placeholder={'Selecciona un valor'}
						onChange={(e) => {
							setData({
								...data,
								[el.key]: e.target.value?.toString() || '',
							});
							onEdit(item.id, row, e.target.value?.toString() || '');
						}}
						RenderTitle={() => {
							if (column > 0) return null;
							return <p>{el.key}</p>;
						}}
						value={userList.length > 0 ? data[el.key] : ''}
						optionsMenu={userList}
						disabled={el.isDisabled}
					/>
				</Grid>
			))}
		</Grid>
	);
};
