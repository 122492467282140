import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { es } from 'date-fns/locale';
import { nameMonths } from './months';

export const formatDate = (date: string, signo?: string): string => {
	const dateArray = date.split('-');
	return `${dateArray[2]}${signo || '/'}${dateArray[1]}${signo || '/'}${dateArray[0]}`;
};
export const getDateLocalMX = (date: string, formatValue?: string) => {
	let dateFlag = '';
	try {
		const parsedTime = parseISO(date + 'Z');
		dateFlag = format(
			utcToZonedTime(parsedTime, 'America/Regina'),
			formatValue || 'dd/MM/yyyy',
			{
				timeZone: 'America/Regina',
				locale: es,
			}
		);
	} catch (error) {
		try {
			dateFlag = format(
				new Date(date.toString()),
				formatValue || 'dd/MM/yyyy - HH:mm'
			);
		} catch (e) {
			dateFlag = '';
		}
	}
	return dateFlag;
};

export const getHour = () => {
	const date = new Date();
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM.' : 'AM.';

	hours = hours % 12 || 12;

	minutes = minutes < 10 ? parseInt('0' + minutes) : minutes;

	const hourFormatted = `${hours}:${minutes} ${ampm}`;
	return hourFormatted;
};

export const getFormattedDate = () => {
	const date = new Date();
	const day = date.getDate();
	const month = nameMonths[date.getMonth()];
	const year = date.getFullYear();

	const fechaConFormato = `${day} de ${month} ${year}`;
	return fechaConFormato;
};
