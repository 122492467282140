export const DOCS_TYPES = Object.freeze({
	PDF: 'PDF',
	DOC: 'DOC',
	XLS: 'XLS',
	JPG: 'JPG',
	JPEG: 'JPEG',
	PNG: 'PNG',
});

export const DOCS_EXTENSIONS: { [key: string]: string } = Object.freeze({
	'application/pdf': 'PDF',
	'image/jpg': 'JPG',
	'image/jpeg': 'JPEG',
});
