import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/material/styles';
import styles from '../components/styles/vertical-stepper-styles.component.module.css';
import SvgIcon from '@mui/material/SvgIcon';

export interface ICheck {
	isComplete: boolean;
	name: string;
}

interface ICheckIcon {
	color: string;
}

function CheckIcon(props: ICheckIcon) {
	const { color } = props;
	return (
		<SvgIcon>
			<path
				fill={color}
				d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
			></path>
		</SvgIcon>
	);
}

export interface IVerticalStepper<IItem extends ICheck> {
	items: IItem[];
	gridKey?: string;
	sxContainer?: SxProps;
	sxElement?: SxProps;
	independientSteps?: boolean;
	type?: string;
	hiddeLines?: boolean;
	rootStyle?: React.CSSProperties;
	renderItem?: (item: IItem, index: number) => React.ReactNode;
	renderTitle?: (item: IItem, index?: number) => React.ReactNode;
	spacing?: number;
	withCollapsable?: boolean;
}

const VerticalStepper = <IItem extends ICheck>(props: IVerticalStepper<IItem>) => {
	const {
		items,
		renderItem,
		renderTitle,
		sxContainer,
		sxElement,
		rootStyle,
		hiddeLines = false,
		independientSteps: independientStepps = false,
		spacing = 1,
		withCollapsable,
	} = props;
	const {
		verticalStepperRoot,
		verticalStepperRootCollapsable,
		steps,
		stepsOff,
		stepsOn,
		independient,
		lines,
	} = styles;

	return (
		<div
			className={`${
				withCollapsable ? verticalStepperRootCollapsable : verticalStepperRoot
			} ${independientStepps ? independient : ''} ${!hiddeLines ? lines : ''}`}
			style={{ ...rootStyle }}
		>
			{(items || []).map((el: any, idx) => (
				<Grid
					container
					spacing={spacing}
					key={`${el.id}-${idx}`}
					id={`${el.id}-${idx}`}
					sx={{ ...sxContainer }}
				>
					<Grid item xs={1} sx={{ ...sxElement }}>
						<div
							className={`${steps} ${el.isComplete ? stepsOn : stepsOff}`}
							style={{ top: withCollapsable ? '10px' : '0px' }}
						>
							<CheckIcon color="#fff" />
						</div>
					</Grid>
					{renderTitle && renderTitle(el, idx)}
					{renderItem && renderItem(el, idx)}
					{independientStepps && idx < items.length - 1 && (
						<hr style={{ width: '100%', border: '1px solid #E3E3E3' }} />
					)}
				</Grid>
			))}
		</div>
	);
};

export default VerticalStepper;
