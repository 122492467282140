/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { IGetRoles } from '../interfaces/get-roles.interface';
import { ICreateRoles } from '../interfaces/create-roles.interface';

interface IUseCreateRoles {
	rolesOptions: IAdaptedValues[];
	setRolesOptions: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
}

export const useCreateRoles = ({ rolesOptions, setRolesOptions }: IUseCreateRoles) => {
	const dispatch = useDispatch();
	const [index, setIndex] = useState(0);
	const [body, setBody] = useState<ICreateRoles | undefined>(undefined);

	const {
		execute: createRoles,
		status: statusExecuteRoles,
		value: valueExecuteRoles,
	} = useApiRequest<ICreateRoles, IResponsePayload<IGetRoles>>({
		path: URLS.EDIT_PROJECT.ROLES.GET_ROLES,
		method: 'POST',
	});

	useEffect(() => {
		if (statusExecuteRoles === 'success') {
			setRolesOptions(
				rolesOptions.map((rol, i) => {
					if (i === index)
						return {
							id: valueExecuteRoles?.detail?._id || '',
							mainAttribute: valueExecuteRoles?.detail?.mainAttribute || '',
							description: valueExecuteRoles?.detail?.name || '',
							rows:
								valueExecuteRoles?.detail?.attributes?.map((e, i) => {
									return {
										key: e,
										value:
											i === 0
												? valueExecuteRoles?.detail?.mainAttribute
												: '',
									};
								}) || [],
							isComplete: true,
							attributes: valueExecuteRoles?.detail?.attributes || [],
							frequency: '',
						} as unknown as IAdaptedValues;
					else return rol;
				})
			);
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Rol creado con éxito',
					severity: 'success',
				})
			);
		} else if (statusExecuteRoles === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los roles',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusExecuteRoles]);

	useEffect(() => {
		if (body) {
			dispatch(setLoading({ loading: true }));
			createRoles(body);
		}
	}, [body]);

	const handleCreateRoles = (body: ICreateRoles, index: number) => {
		setBody(body);
		setIndex(index);
	};

	return { handleCreateRoles };
};
