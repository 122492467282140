import { IColumns } from '../../../interfaces/table.insterface';

export const columnsProjects: IColumns[] = [
	{
		text: 'Número de contrato',
		nameID: 'contractNumber',
		width: 200,
	},
	{
		text: 'Nombre del proyecto',
		nameID: 'name',
		width: 200,
	},
	{
		text: 'Nombre corto',
		nameID: 'shortName',
		width: 150,
	},
	{
		text: 'Ubicación',
		nameID: 'location',
	},
	{
		text: 'Fecha de inicio',
		nameID: 'startDate',
		width: 150,
	},
	{
		text: 'Fecha de terminación',
		nameID: 'endDate',
		width: 200,
	},
	{
		text: '',
		nameID: 'acciones',
		width: 100,
	},
];
