import { optionsAreas } from '../utils/options-supervision';
import { Box, Grid, Paper } from '@mui/material';
import TextInput from '../../../components/text-input.component';
import { Button } from '../../../components/main-button.component';
import { ActionComponent } from '../../../components/action.component';
import { CameraAltOutlined, UploadFileOutlined } from '@mui/icons-material';
import InputSelect from '../../../components/select-input.component';
import { DragNDrop } from '../../../components/drag-drop.component';
import CloseIcon from '@mui/icons-material/Close';
import { useSupendActivity } from './hooks/useSupendActivity';
import styles from './styles/suspend-activity-styles.module.css';
import CustomCamera from '../../../components/custom-camera.component';

export const SuspendActivity = () => {
	const {
		data,
		matches,
		camera,
		disabledButton,
		editFinding,
		supervisorName,
		unitInfo,
		changeStatus,
		handleFile,
		handleChange,
		deleteFile,
		setCamera,
		handlePhoto,
		undoState,
		updateSuspension,
	} = useSupendActivity();

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={2} justifyContent="flex-end" alignItems="center">
					<Grid item xs={12}>
						<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
							Suspensión de supervisión
						</span>
						<br />
						<span style={{ fontSize: '18px', color: '#707070' }}>
							Suspensión de tarea
						</span>
					</Grid>
					<Grid item xs={12}>
						<span style={{ fontSize: '18px' }}>
							Selecciona la causa de suspensión
						</span>
						<InputSelect
							value={optionsAreas.length > 0 ? data.type : ''}
							optionsMenu={optionsAreas}
							onChange={handleChange}
							placeholder="Selecciona"
							id="select-cause"
						/>
					</Grid>
					<Grid item xs={12}>
						<span style={{ fontSize: '18px' }}>
							Descripción de la incidencia
						</span>
						<TextInput
							value={data.description}
							id="description"
							name="description"
							placeholder="Escribe, si es necesario, la descripción de la incidencia"
							onChange={handleChange}
							multiline
							rows={6}
						/>
					</Grid>
					<Grid item xs={12}>
						<span style={{ fontSize: '18px' }}>Evidencias</span>
						<Grid
							container
							spacing={2}
							justifyContent={matches ? 'center' : ''}
						>
							<Grid item>
								{camera ? (
									<CustomCamera
										setImg={handlePhoto}
										stylesCamera={{
											width: '244px',
											height: '221px',
											borderRadius: '10px',
										}}
										user={supervisorName}
										unitInfo={unitInfo}
										withInfo
									/>
								) : (
									<ActionComponent
										icon={CameraAltOutlined}
										label="Tomar foto"
										onClick={() => setCamera(true)}
									/>
								)}
							</Grid>
							<Grid item>
								<DragNDrop
									element={{ accept: 'image/jpeg' }}
									onChange={handleFile}
									icon={
										<div className={styles.dragNDropIconContainer}>
											<UploadFileOutlined
												style={{
													fontSize: '3em',
												}}
											/>
											<span style={{ fontSize: '12px' }}>JPEG</span>
										</div>
									}
									stylesContainer={{
										height: '100%',
										width: matches ? '244px' : '100%',
										padding: '20px',
									}}
									stylesTitle={{
										fontSize: '21px',
										color: '#7f8297',
										fontWeight: 'bold',
									}}
									stylesSubTitle={{ fontSize: '9px', color: '#7f8297' }}
								/>
							</Grid>
						</Grid>
					</Grid>
					{data.files.length > 0 && (
						<Grid item xs={12} marginY={2}>
							<Grid container spacing={2}>
								{data.files.map((element, index) => (
									<Grid
										item
										key={index}
										md={6}
										lg={3}
										style={{
											position: 'relative',
											display: 'flex',
										}}
									>
										<img
											src={
												element._id
													? element.url
													: `data:image/jpeg;base64,${element.fileBase64}`
											}
											alt={element.fileName}
											style={{ width: '100%' }}
										/>
										<div
											className={styles.badgeCustom}
											onClick={() => deleteFile(index)}
										>
											<CloseIcon style={{ color: 'white' }} />
										</div>
									</Grid>
								))}
							</Grid>
						</Grid>
					)}
					<Grid item>
						<Button
							label="Cancelar"
							onClick={() => undoState()}
							style={{
								width: '150px',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label={editFinding ? 'Guardar' : 'Continuar'}
							onClick={() =>
								editFinding ? updateSuspension() : changeStatus()
							}
							variant="solid"
							style={{
								width: '150px',
								backgroundColor: '#0080F9',
							}}
							disabled={disabledButton}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
