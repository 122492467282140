import { IUserRoles, USER_ROLES } from '../../utils/permissions.utils';
import { RouteExternalList } from './route-external';
import { RouteAdminList } from './route-admin';
import { RouteSupervisorList } from './route-supervisor';
import { RouteManagerList } from './route-manager';
import { RouteDirectorList } from './route-director';
import { RouteCordinatorList } from './route-coordinator';

export const getRouteList = (role: keyof IUserRoles) => {
	switch (role) {
		case USER_ROLES.ADMIN:
			return RouteAdminList;
		case USER_ROLES.EXTERNAL:
			return RouteExternalList;
		case USER_ROLES.SUPERVISOR:
			return RouteSupervisorList;
		case USER_ROLES.GERENTE:
			return RouteManagerList;
		case USER_ROLES.DIRECTOR:
			return RouteDirectorList;
		case USER_ROLES.COORDINADOR:
			return RouteCordinatorList;
		default:
			return RouteAdminList;
	}
};
