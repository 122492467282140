/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import CriteriosGenerales from './components/criterios-generales';
import CriteriosEspecificos from './components/criterios-especificos';
import GeograficosServicios from './components/geograficos-servicios';
import Hallazgos from './components/tipos-hallazgos';

const Index = () => {
	const { step: projectStep } = useSelector((state: RootState) => state.projectBase);
	const [currentTab, setCurrentTab] = useState<number>(-1);

	useEffect(() => {
		if (
			projectStep === '3.0.0' ||
			projectStep === '3.0.1' ||
			projectStep === '3.0.2'
		) {
			setCurrentTab(0);
		}
		if (projectStep === '3.1.0') {
			setCurrentTab(1);
		}
		if (projectStep === '3.2.0') {
			setCurrentTab(2);
		}
		if (projectStep === '3.2.1') {
			setCurrentTab(2);
		}
		if (projectStep === '3.3.0') {
			setCurrentTab(3);
		}
	}, [projectStep]);

	const RenderPage = (): JSX.Element => {
		let component: JSX.Element;
		switch (currentTab) {
			case 0:
				component = (
					<CriteriosGenerales
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
				);
				break;
			case 1:
				component = (
					<CriteriosEspecificos
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
				);
				break;
			case 2:
				component = (
					<GeograficosServicios
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
				);
				break;
			case 3:
				component = (
					<Hallazgos currentTab={currentTab} setCurrentTab={setCurrentTab} />
				);
				break;
			default:
				component = <></>;
				break;
		}
		return component;
	};

	return (
		<div>
			<RenderPage />
		</div>
	);
};

export default Index;
