import { Grid } from '@mui/material';
import TextInput from '../../../components/text-input.component';
import { selectDatePickers } from './utilis/date-pickers-utils';
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns';
import { useState } from 'react';
import { Button } from '../../../components/main-button.component';

interface IBodyRangeDates {
	handleSuspendAction: (startDate: string, endDate: string) => void;
	closeModal: () => void;
}

export const BodyRangeDates = ({ handleSuspendAction, closeModal }: IBodyRangeDates) => {
	const [data, setData] = useState<{ [key: string]: string }>({
		suspensionStartDate: formatISO(startOfDay(new Date())),
		suspensionEndDate: formatISO(endOfDay(new Date())),
	});

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				Selecciona el tiempo que deseas desactivar al usuario
			</Grid>
			{selectDatePickers.map((e) => {
				return (
					<Grid item xs={12} key={`select${e.id}`}>
						<TextInput
							key={e.id}
							id={e.id}
							data-testid={e.id}
							name={e.name}
							label={e.label}
							placeholder={e.placeholder}
							value={parseISO(data[e.name])}
							type={e.type}
							onChange={(newValue: any) => {
								setData({
									...data,
									[e.name]:
										e.name === selectDatePickers[0].name
											? formatISO(startOfDay(newValue))
											: formatISO(endOfDay(newValue)),
								});
							}}
							which="datePicker"
							contentStyles={{
								width: {
									xs: '100%',
									sm: '350px',
									md: '200px',
								},
							}}
							sx={{
								backgroundColor: '#F7F7F7',
								width: { xs: '100%' },
								fontSize: '15px',
							}}
							inputFormat={e.inputFormat}
						/>
					</Grid>
				);
			})}
			<Grid item xs={12} style={{ display: 'flex', gap: '20px' }}>
				<Button
					variant="outlined"
					label="Cancelar"
					onClick={() => closeModal()}
				/>
				<Button
					style={{ color: '#0080F9' }}
					variant="outlined"
					label="Continuar"
					onClick={() =>
						handleSuspendAction(
							data.suspensionStartDate,
							data.suspensionEndDate
						)
					}
				/>
			</Grid>
		</Grid>
	);
};
