import StepTwoFixed from '../../../../components/step-two-fixed';
import { IStepProps, IRenderItemProps } from '../../../../interfaces/stepTwo.interface';
import Grid from '@mui/material/Grid';
import { TextArea } from '../../../../components/text-area.component';
import renderItem from '../../../../components/styles/render-item-styles.module.css';
import SelectComponent from '../../../../components/select.component';
import { IOptionsMenu } from '../../../../interfaces/select.interface';

interface IItem {
	itemProps: IRenderItemProps;
	options: IOptionsMenu[];
}

const ColumnItem = (props: IItem) => {
	const { options } = props;
	const { item, column, handleTable, disabled } = props.itemProps;
	return (
		<Grid item className={renderItem.renderItem}>
			{item.columns.map((el, row) => {
				if (row < item.columns.length - 1) {
					return (
						<TextArea
							id={`table-ig-${column}-${row}`}
							key={`table-ig-${column}-${row}`}
							RenderTitle={() => {
								if (column === 0) return <p>{item.attributes[row]}</p>;
								return null;
							}}
							hideCopyPaste
							disabled={disabled || row < item.columns.length - 1}
							defaultValue={el}
							onChange={(val) => {
								handleTable(column, row, val);
							}}
							onPaste={(txt) => {
								handleTable(column, row, txt);
							}}
						/>
					);
				}
				return (
					<SelectComponent
						formSx={{ width: '100%', marginTop: 0 }}
						id={`freq-serv-${column}`}
						key={`freq-serv-${column}`}
						selectSx={{ height: '3.5rem' }}
						placeholder={'Selecciona un valor'}
						onChange={(e) => {
							handleTable(column, row, e.target.value);
						}}
						RenderTitle={() => {
							if (column > 0) return null;
							return <p>Frecuencia</p>;
						}}
						value={el}
						optionsMenu={options}
						disabled={disabled}
					/>
				);
			})}
		</Grid>
	);
};

interface IStepTwoProps extends IStepProps {
	options: IOptionsMenu[];
}
const StepTwoComponent = (props: IStepTwoProps) => {
	const { data, options, onSelectChange } = props;
	return (
		<StepTwoFixed
			{...props}
			id="continue-act-functional"
			onSelectChange={(value) => {
				if (onSelectChange) onSelectChange(value);
			}}
			renderItem={(eleProps) => {
				if (!data || data.length < 1) return <></>;
				else return <ColumnItem itemProps={eleProps} options={options} />;
			}}
			hiddeAddButton
			hiddeCsvButton
			hiddeDeleteItemsButtons
		/>
	);
};

export default StepTwoComponent;
