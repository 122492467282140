/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, FormControlLabel, TextField } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { isEmpty } from '../utils/validators';

import styles from './styles/text-input-styles.component.module.css';
import { IOptions } from '../interfaces/filter.interface';

interface ISelectCustom {
	id: string;
	value: string | number;
	name?: string;
	label?: string;
	placeholder?: string;
	helperText?: string;
	onChange: (option: any) => void;
	isRequired?: boolean;
	disabled?: Boolean | any;
	options?: IOptions[];
	labelPosition?: 'top' | 'start' | 'end';
}

const SelectAsyncSearch = ({
	id,
	value,
	name,
	label,
	placeholder,
	helperText,
	onChange,
	isRequired,
	disabled,
	options,
	labelPosition = 'top',
}: ISelectCustom) => {
	const [data, setData] = useState<any>([]);
	const [open, setOpen] = useState(false);
	const [optionSelected, setOptionSelected] = useState<any>('');

	useEffect(() => {
		if (value && !isEmpty(data)) {
			const newLabel = data.find(
				(element: any) => element.value.toString() === value.toString()
			);
			if (!isEmpty(newLabel)) {
				setOptionSelected(newLabel);
			}
		} else {
			setOptionSelected(value);
		}
	}, [value, data]);

	const dataInputs = useMemo((): IOptions[] => {
		let dataFlag: IOptions[] = [];
		if (options) {
			dataFlag = options;
		}
		return dataFlag;
	}, [options]);

	useEffect(() => {
		if (dataInputs) setData(dataInputs);
	}, [dataInputs]);

	return (
		<Autocomplete
			id={`search-select-${id}`}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			defaultValue=""
			onChange={(_, option) => {
				setOptionSelected(option);
				onChange({ target: { value: option?.value || '' } });
			}}
			renderOption={(props, option) => (
				<Box
					component="li"
					sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
					{...props}
				>
					{option.label}
				</Box>
			)}
			value={optionSelected || null}
			options={data}
			disabled={disabled}
			size="small"
			renderInput={(params) => (
				<FormControlLabel
					className={styles.centerElements}
					label={
						<div className={styles.labelContainerInput}>
							<span className={styles.labelContainerInput}>
								{label}:
								{isRequired && (
									<label className={styles.labelRequired}>*</label>
								)}
							</span>
						</div>
					}
					style={{ alignItems: 'center !important' }}
					labelPlacement={labelPosition}
					control={
						<TextField
							{...params}
							size="small"
							margin="dense"
							placeholder={placeholder || ''}
							name={name}
							InputProps={{
								...params.InputProps,
							}}
							helperText={helperText}
						/>
					}
				/>
			)}
		/>
	);
};
export default SelectAsyncSearch;
