/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNextTasks } from '../hooks/useNextTasks';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import TableCustom from '../../../components/table-custom.component';
import { columnsNextTasks } from '../utils/columns-tasks.utils';
import { RootState } from '../../../store';

export const ListNextTasks = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location?.state?.params;
	const { dataTable, setDataTable, executeGetTask, filters, handleGetFiltersParams } =
		useNextTasks();
	const { _id } = useSelector((state: RootState) => state.projectBase);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetTask(
			{},
			`?page=${dataTable.pagination}&recordsByPage=${
				dataTable.rowsPerPage
			}&project=${
				state?.project?._id || _id
			}&routineType=RECURRENT${handleGetFiltersParams()}`
		);
	}, [dataTable.pagination, dataTable.rowsPerPage, filters]);

	return (
		<TableCustom
			titleHeader="Próximas tareas"
			columns={columnsNextTasks}
			data={dataTable}
			setData={setDataTable}
			showPagination
			showLimitPerPage
		/>
	);
};
