import { useState } from 'react';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';
import StepOneFixed, { TFunctionHandler } from '../../../../components/step-one-fixed';
import { IVerticalStepperAttributes } from '../../../../interfaces/vertical.steper.attributes-fixed.interface';

interface IStep {
	onContinue?: (data: IStepTwoModel[]) => void;
	defaultCriterio: IVerticalStepperAttributes[] | null;
	disableNext: boolean | null;
	resetDone?: null | true;
	refComponent: React.RefObject<TFunctionHandler>;
}

const _default1: IVerticalStepperAttributes[] = [
	{
		index: 0,
		name: 'Nivel 1',
		placeholder: 'Ingrese nombre',
		value: '',
		isComplete: false,
	},
	{
		index: 1,
		name: 'Atributo Principal',
		placeholder: 'Nombre del atributo',
		value: '',
		isComplete: false,
	},
];

const StepOneContent = (props: IStep) => {
	const { onContinue, defaultCriterio, disableNext, resetDone, refComponent } = props;
	const [dimensiones, setDimensiones] =
		useState<IVerticalStepperAttributes[]>(_default1);
	return (
		<StepOneFixed
			ref={refComponent}
			data={dimensiones}
			resetDone={resetDone}
			defaultData={defaultCriterio}
			setData={setDimensiones}
			disableNext={disableNext}
			onContinue={onContinue}
		/>
	);
};

export default StepOneContent;
