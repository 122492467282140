/* eslint-disable react-hooks/exhaustive-deps */

import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { SupervisionProjectContext } from '../../../contexts/useSupervisionProjectContext';
import { useGetSupervisionDetail } from '../../hooks/useGetSupervisionDetail';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { STATUS_SUPERVISION_TYPES } from '../../../../../constants/status-supervision';
import {
	DESCRIPTION_SUPERVISION,
	LABEL_BUTTON_SUPERVISION,
} from '../../../constants/labels-supervision';
import { setAlert } from '../../../../../store/modules/snackbar';
import { IChangeStatusParams } from '../../interfaces/change-status.interface';
import { ISupervisionRoutineDetail } from '../../interfaces/get-supervision-detail.interface';

export function useViewDetailsInfo() {
	const {
		mainSelect,
		options,
		supervisionRoutineId,
		executeChangeStatus,
		statusChangeStatus,
		setSupervisionAction,
		setStatus,
		setNewState,
		undoState,
		setWithStatus,
		setSupervisionRoutineId,
		setEditFinding,
	} = useContext(SupervisionProjectContext);

	const dispatch = useDispatch();

	const {
		data,
		valueSupervisionDetail,
		dinamicColumns,
		getSupervisionDetail,
		setData,
	} = useGetSupervisionDetail();

	useEffect(() => {
		setWithStatus(null);
		dispatch(setLoading({ loading: true }));
		getSupervisionDetail(null, `/${supervisionRoutineId}`);
	}, []);

	const handleSetAction = (status: string, element?: ISupervisionRoutineDetail) => {
		if (element) {
			if (status === element.status) return;
			switch (status) {
				case STATUS_SUPERVISION_TYPES.ACCOMPLISHED:
					changeStatus(getValuesStatus(status, element));
					break;
				case STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED:
					setEditFinding(null);
					setSupervisionRoutineId(element._id);
					setWithStatus(getValuesStatus(status, element));
					setStatus('pending');
					setSupervisionAction({
						descriptionLabel: DESCRIPTION_SUPERVISION.NOT_ACCOMPLISHED,
						labelButton: LABEL_BUTTON_SUPERVISION.NOT_ACCOMPLISHED,
						continueActionButton: () => {
							setNewState(4);
						},
						cancelActionButton: () => undoState(),
					});
					setNewState(0);
					break;
				case STATUS_SUPERVISION_TYPES.PENDING_TO_VALIDATE:
					setStatus('pending');
					setSupervisionAction({
						descriptionLabel: DESCRIPTION_SUPERVISION.PENDING_TO_VALIDATE,
						labelButton: LABEL_BUTTON_SUPERVISION.PENDING_TO_VALIDATE,
						continueActionButton: () => {
							changeStatus(getValuesStatus(status, element));
						},
						cancelActionButton: () => undoState(),
					});
					setNewState(0);
					break;
				case STATUS_SUPERVISION_TYPES.SUSPENDED:
					setEditFinding(null);
					setWithStatus(getValuesStatus(status, element));
					setStatus('pending');
					setNewState(6);
					break;
			}
		}
	};

	useEffect(() => {
		if (statusChangeStatus === 'success') {
			getSupervisionDetail(null, `/${supervisionRoutineId}`);
		} else if (statusChangeStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusChangeStatus]);

	const changeStatus = async (element: IChangeStatusParams) => {
		dispatch(setLoading({ loading: true }));
		await executeChangeStatus({ ...element });
	};

	const getValuesStatus = (
		newStatus: string,
		element: ISupervisionRoutineDetail
	): IChangeStatusParams => {
		return {
			newStatus: newStatus,
			_id: element._id,
			project: element.project,
			routine: element.routine._id,
			supervisionRoutine: element._id,
		};
	};

	return {
		data,
		mainSelect,
		options,
		valueSupervisionDetail,
		dinamicColumns,
		setData,
		handleSetAction,
		setNewState,
		setStatus,
	};
}
