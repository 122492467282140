/* eslint-disable react-hooks/exhaustive-deps */
import { Check, Update } from '@mui/icons-material';
import { Dialog, Grid, Paper } from '@mui/material';
import TableCustom from '../../../components/table-custom.component';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { useSuspensionByRoutine } from './hooks/useSupervisionDetail';
import { SelectDate } from '../../../components/static-date-picker';
import { Button } from '../../../components/main-button.component';

export const SuspensionDetails = () => {
	const {
		dataTable,
		setDataTable,
		columnsDynamic,
		showCalendarModal,
		setShowCalendarModal,
		calendarValue,
		setCalendarValue,
		handleRescheduleBySupervisionRoutine,
		handleConfirmByRoutine,
		setAllReschedule,
		allReschedule,
		handleRescheduleByRoutine,
	} = useSuspensionByRoutine();

	return (
		<Grid container spacing={3} justifyContent="flex-end">
			<Grid item sm>
				<h3>Causa de la suspensión</h3>
			</Grid>
			<Grid item>
				<ButtonIconSmall
					text="Confirmar suspensión"
					type="success"
					icon={<Check />}
					style={{ fontSize: '10px' }}
					onPress={handleConfirmByRoutine}
					disabled={dataTable.rows.length < 1}
				/>
			</Grid>
			<Grid item>
				<ButtonIconSmall
					text="Reprogramar"
					type="grey"
					icon={<Update />}
					style={{ fontSize: '10px' }}
					onPress={() => {
						setAllReschedule(true);
						setShowCalendarModal(true);
					}}
					disabled={dataTable.rows.length < 1}
				/>
			</Grid>
			<Grid item xs={12}>
				<TableCustom
					columns={columnsDynamic}
					data={dataTable}
					setData={setDataTable}
					showPagination
					showLimitPerPage
					hideHeader
				/>
			</Grid>
			<Dialog
				fullWidth
				open={showCalendarModal}
				onClose={() => setShowCalendarModal(!showCalendarModal)}
			>
				<Paper
					elevation={1}
					sx={{
						padding: '2rem',
					}}
				>
					<SelectDate value={calendarValue} setValueDate={setCalendarValue} />
					<Grid item xs={12}>
						<Grid container justifyContent={'center'} spacing={2}>
							<Grid item>
								<Button
									label="Cancelar"
									onClick={() =>
										setShowCalendarModal(!showCalendarModal)
									}
									style={{
										backgroundColor: 'var(--color-error-main)',
										width: '150px',
									}}
								/>
							</Grid>
							<Grid item>
								<Button
									label="OK"
									onClick={() => {
										if (allReschedule) {
											handleRescheduleByRoutine();
										} else {
											handleRescheduleBySupervisionRoutine();
										}
									}}
									style={{
										backgroundColor: 'var(--color-highlight-blue)',
										width: '150px',
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Dialog>
		</Grid>
	);
};
