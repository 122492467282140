import { IEvidenciasSH } from '../interfaces/tables.interface';

export const evidencesSHAdapter = (data: IEvidenciasSH[]) => {
	const newData = data.map((element) => {
		return {
			...data,
			evidences: (
				<img
					src={element.url}
					alt="evidencia"
					style={{
						width: '111px',
						height: '91px',
						borderRadius: '5px',
						boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16',
					}}
				/>
			),
		};
	});
	return newData;
};
