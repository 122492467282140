import { Grid, useMediaQuery } from '@mui/material';
import TextArea from './text-area.component';
import styles from './styles/item-text-area-styles.module.css';
import theme from '../config/theme';
import { EditButton } from './buttons.component';
import SaveIcon from '@mui/icons-material/Save';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import { IAdaptedValues } from '../interfaces/edit-adapted-values';
import { isEmpty } from 'lodash';

interface IItem {
	item: IAdaptedValues;
	column: number;
	onEdit: (item: IAdaptedValues) => void;
	onSave: (value: string) => void;
	onDelete: (index: number) => void;
}

export const EditItemRow = ({ item, column, onSave, onDelete, onEdit }: IItem) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [value, setValue] = useState('');

	return (
		<>
			<Grid item xs={matches ? 9 : 10} className={styles.renderItem}>
				<TextArea
					key={`table-${column}-${item.id}`}
					id={`table-${column}-${item.id}`}
					RenderTitle={() => {
						if (column === 0) return <p>{item.mainAttribute}</p>;
						return null;
					}}
					defaultValue={item.description}
					onChange={(value: string) => {
						setValue(value);
					}}
					onPaste={() => null}
					hideCopyPaste
					isReadonly={item.id ? true : false}
				/>
			</Grid>
			<Grid xs item>
				{item.id ? (
					<EditButton id={`edit-${column}`} onClick={() => onEdit(item)} />
				) : (
					<div style={{ display: 'flex', gap: '15px' }}>
						<EditButton
							id={`save-${column}`}
							onClick={() => onSave(value)}
							icon={<SaveIcon sx={{ fontSize: '15px' }} />}
							disabled={isEmpty(value)}
						/>
						<EditButton
							id={`delete-${column}`}
							onClick={() => onDelete(column)}
							icon={<Delete sx={{ fontSize: '15px' }} />}
							styles={{
								background: 'var(--color-error-light)',
								color: 'var(--color-error-main)',
							}}
						/>
					</div>
				)}
			</Grid>
		</>
	);
};
