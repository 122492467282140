/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import {
	activitiesAdapter,
	reportsAdapter,
} from '../../adapters/summary-routine.adapter';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { ISummaryRoutineResp, IActionsSH } from '../interfaces/summary-routine.interface';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { SupervisionProjectContext } from '../../../contexts/useSupervisionProjectContext';
import { useDownloadReport } from '../../../../../hooks/useDownloadReport';
import { actionsSHAdapter } from '../adapters/actions-adapter-sh.adapter';
import { RootState } from '../../../../../store';
import { getTableColumns } from '../../../../../utils/tableInfo';

export function useSummaryRoutineDetail() {
	const location = useLocation();
	const idRoutineParams = location.state.routine as string;
	const [stepRoutine, setStepRoutine] = useState('1');
	const [graphic, setGraphic] = useState({
		percentage: 0,
		completedTasks: 0,
		pendingSupervision: 0,
		pendingValidation: 0,
		suspendedTasks: 0,
	});

	const { idRoutine, descriptionRoutine } = useContext(SupervisionProjectContext);
	const { data: dataActivites, setData: setDataActivities } = useTableCustom<Object>();
	const { data: dataActions, setData: setDataActions } = useTableCustom<Object>({
		countRows: 0,
		rows: [],
		sort: { col: '', order: '' },
		pagination: '1',
		rowsSelect: [],
		rowsPerPage: '5',
		filters: {},
		advancedFilter: '',
		activeAdvanceFilter: false,
		filterCustom: {},
	});
	const [reports, setReports] = useState<{ name: string; _id: string }[]>([]);
	const [supervisorDescription, setSupervisor] = useState<string>('');
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { functionalBase, functionalParentBase } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [columnsActivities] = useState(
		getTableColumns(
			functionalBase
				? [
						'Actividades Supervisadas',
						functionalParentBase?.name || 'Nivel 1',
						functionalBase?.name || 'Nivel 2',
				  ]
				: ['Actividades Supervisadas', functionalParentBase?.name || 'Nivel 1']
		)
	);

	const { executeDownloadReport } = useDownloadReport();

	const {
		execute: executeGetSummaryRoutine,
		status: statusGetSummaryRoutine,
		value: valueGetSummaryRoutine,
	} = useApiRequest<unknown, IResponsePayload<ISummaryRoutineResp>>({
		path: URLS.SUPERVISION_EXECUTION.GET_SUMMARY_ROUTINE,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetSummaryRoutine({}, `/${idRoutineParams ? idRoutineParams : idRoutine}`);
	}, []);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeAllActions(
			{},
			`?routine=${idRoutineParams ? idRoutineParams : idRoutine}&page=${
				dataActions?.pagination
			}&recordsByPage=${dataActions?.rowsPerPage}`
		);
	}, [dataActions.pagination, dataActions.rowsPerPage]);

	useEffect(() => {
		if (statusGetSummaryRoutine === 'success') {
			dispatch(stopLoading());
			setGraphic({
				percentage:
					valueGetSummaryRoutine?.detail?.circularDashboard
						?.accomplishedPercentage || 0,
				completedTasks:
					valueGetSummaryRoutine?.detail?.circularDashboard?.totalCompleted ||
					0,
				pendingSupervision:
					valueGetSummaryRoutine?.detail?.circularDashboard
						?.totalPendingSupervision || 0,
				pendingValidation:
					valueGetSummaryRoutine?.detail?.circularDashboard
						?.totalPendingValidation || 0,
				suspendedTasks:
					valueGetSummaryRoutine?.detail?.circularDashboard?.totalSuspended ||
					0,
			});
			setDataActivities({
				...dataActivites,
				countRows: valueGetSummaryRoutine?.totalRecords || 0,
				rows: activitiesAdapter(valueGetSummaryRoutine?.detail.units || []),
			});
			setReports(reportsAdapter(valueGetSummaryRoutine?.detail?.reports));
			setSupervisor(valueGetSummaryRoutine?.detail?.supervisorDescription || '');
		} else if (statusGetSummaryRoutine === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetSummaryRoutine]);

	const {
		execute: executeAllActions,
		status: statusAllActions,
		value: valueAllActions,
	} = useApiRequest<unknown, IResponsePayload<IActionsSH>>({
		path: URLS.ACTIONS.GET_ACTIONS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusAllActions === 'success') {
			dispatch(stopLoading());
			setDataActions({
				...dataActions,
				countRows: valueAllActions?.totalRecords || 0,
				rows: actionsSHAdapter(valueAllActions?.data || []),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusAllActions === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar las acciones.',
					severity: 'error',
				})
			);
		}
	}, [statusAllActions]);

	const downloadDocument = (fileNameKey: string) => {
		dispatch(setLoading({ loading: true }));
		executeDownloadReport({ key: fileNameKey });
	};

	return {
		dataActivites,
		graphic,
		descriptionRoutine,
		reports,
		columnsActivities,
		stepRoutine,
		navigate,
		setDataActivities,
		downloadDocument,
		dataActions,
		setDataActions,
		supervisorDescription,
		setStepRoutine,
	};
}
