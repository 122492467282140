/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import {
	IActivities,
	IAdaptedActivities,
} from '../../../interfaces/get-activities.interface';

interface IUpdateActivitiesByFunctional {
	activitiesOptions: IAdaptedActivities[];
	setActivitiesOptions: React.Dispatch<React.SetStateAction<IAdaptedActivities[]>>;
}

export const useUpdateActivitiesByFunctional = ({
	activitiesOptions,
	setActivitiesOptions,
}: IUpdateActivitiesByFunctional) => {
	const dispatch = useDispatch();

	const {
		execute: updateActivitiesByFunctional,
		status: statusUpdateActivitiesByFunctional,
		value,
	} = useApiRequest<{ frequency: string }, IResponsePayload<IActivities>>({
		path: URLS.EDIT_PROJECT.CHRONOGRAM.GET_ACTIVITIES_BY_FUNCTIONAL,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusUpdateActivitiesByFunctional === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Frecuencia actualizada con éxito',
					severity: 'success',
				})
			);
			setActivitiesOptions(
				activitiesOptions.map((activity) => {
					return {
						...activity,
						frequency:
							activity.id === value?.detail?._id
								? value?.detail?.frequency
								: activity.frequency,
					};
				})
			);
			dispatch(stopLoading());
		} else if (statusUpdateActivitiesByFunctional === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la frecuencia',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateActivitiesByFunctional]);

	const handleUpdateActivitiesByFunctional = (
		idFunctional: string,
		body: { frequency: string }
	) => {
		dispatch(setLoading({ loading: true }));
		updateActivitiesByFunctional(body, `/${idFunctional}`);
	};

	return { handleUpdateActivitiesByFunctional };
};
