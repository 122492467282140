/* eslint-disable react-hooks/exhaustive-deps*/
import { DefaultModal, IModal } from '../../../../../interfaces/modal.interface';
import { URLS } from '../../../../../constants/urls.constants';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { stopLoading } from '../../../../../store/modules/loading';
import { IGetLevels } from '../interfaces/exportPlanning.interface';

export function useExportPlanning() {
	const dispatch = useDispatch();
	const [levels, setLevels] = useState<string[]>();
	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const openModal = () => {
		setModal({ ...modal, open: true });
	};

	const {
		execute: executeGetLevels,
		status: statusLevels,
		value: valueLevels,
	} = useApiRequest<unknown, IResponsePayload<IGetLevels>>({
		path: URLS.SUPERVISION_UNIT.GET_LEVELS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusLevels === 'success') {
			dispatch(stopLoading());
			setLevels(valueLevels?.detail?.levels || []);
		} else if (statusLevels === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueLevels?.message
						? valueLevels?.message
						: 'Ocurrió un error al obtener tipos de hallazgos',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusLevels]);

	return {
		modal,
		openModal,
		setModal,
		executeGetLevels,
		levels,
	};
}
