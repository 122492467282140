/* eslint-disable react-hooks/exhaustive-deps */
import DocumentUploadSuccesfull from '../save-docs/document-upload-succesfull';
import FormNewProject from './components/form-new-project.component';
import ReviewDocuments from '../save-docs/review-documents';
import { resetState } from '../../store/modules/steps';
import { useDispatch, useSelector } from 'react-redux';
import DragDocument from '../save-docs/drag-document';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RootState } from '../../store';
import NewProjectContext from './contexts/useNewProjectContext';
import {
	IDocument,
	IProject,
	defaultProjectBase,
} from '../../interfaces/project.interface';

interface IParams {
	state: {
		params: {
			isEditing: boolean;
			projectData: IProject;
			typeLoadProject: string;
		};
	};
}

export const NewProject = () => {
	const dispatch = useDispatch();
	const { state } = useLocation() as IParams;
	const projectData = state?.params?.projectData;
	const step = useSelector((state: RootState) => state.stateStep);
	const [data, setData] = useState<IProject>({
		...defaultProjectBase,
		typeLoadProject: state?.params?.typeLoadProject,
	});

	const deleteDocument = (document: IDocument) => {
		setData({
			...data,
			files: data.files.filter((doc: IDocument) => doc.name !== document.name),
		});
	};

	useEffect(() => {
		dispatch(resetState());
		if (projectData) {
			setData({
				...data,
				_id: projectData._id,
				name: projectData.name,
				shortName: projectData.shortName,
				contractNumber: projectData.contractNumber,
				location: projectData.location,
				contractStartDate: projectData.contractStartDate,
				contractEndDate: projectData.contractEndDate,
				description: projectData.description,
				nextChangesApplicationDate: projectData.nextChangesApplicationDate,
			});
		}
	}, []);

	return (
		<NewProjectContext.Provider value={[data, setData]}>
			{step.state === 0 && <FormNewProject state={state} />}
			{step.state === 1 && <DragDocument />}
			{step.state === 2 && (
				<ReviewDocuments data={data} deleteDocument={deleteDocument} />
			)}
			{step.state === 3 && <DocumentUploadSuccesfull />}
		</NewProjectContext.Provider>
	);
};
