import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
declare module '@mui/material/styles' {
	interface Palette {
		blue: Palette['primary'];
		yellow: Palette['primary'];
	}

	interface PaletteOptions {
		blue?: PaletteOptions['primary'];
		yellow?: PaletteOptions['primary'];
	}
}
const theme = createTheme(
	{
		palette: {
			primary: {
				main: '#293990',
				dark: '#212E74',
			},
			secondary: {
				main: '#248EFA',
				dark: '#1d7ad6',
			},
			success: {
				main: '#3ABE21',
				dark: '#079531',
				light: '#D0F0C9',
				contrastText: '#ffffff',
			},
			warning: {
				main: '#E35700',
				dark: '#E35700',
				light: '#FAE295',
				contrastText: '#FFA800',
			},
			error: {
				main: '#DD0528',
				dark: '#DD0528',
				light: '#FFE2E5',
			},
			info: {
				main: '#293990',
				light: '#EEFAFA',
				dark: '#283990',
				contrastText: '#ffffff',
			},
			background: {
				default: '#f8f8f8',
			},
			grey: {
				'200': '#CBCBCB',
			},
			blue: {
				main: '#0080F9',
				light: '#e2f1ff',
			},
			yellow: {
				main: '#dcc97c',
				light: '#fcf3ce',
				dark: '#d3bd63',
			},
		},
	},
	esES
);

export const themBackdrop = createTheme({
	components: {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					zIndex: 99999,
					backgroundColor: 'rgba(0,0,0,0.6)',
				},
			},
		},
	},
});

export default theme;
