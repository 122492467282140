/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Paper } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoBack } from '../../../../components/go-back-component';
import TableCustom from '../../../../components/table-custom.component';
import { IRoutine } from '../../../../interfaces/logic.interface';
import { setState } from '../../../../store/modules/steps';
import { columnsService } from './utils/columns-service-select';
import { useLocation } from 'react-router-dom';
import { useSuspensionsService } from './hooks/useSuspensionsService';
import { setLoading } from '../../../../store/modules/loading';
import { RootState } from '../../../../store';
import { resetPage } from '../../../../store/modules/page-table';
import { SupervisionHistoryContext } from '../../contexts/useSupervisionHistoryContext';
import { DefaultDataSelect } from '../../interfaces/supervision-history.interface';
import { isEmpty } from '../../../../utils/validators';

interface IServiceSelect {
	areaSelected: IRoutine;
	setAreaSelected: React.Dispatch<React.SetStateAction<IRoutine>>;
	setOption: React.Dispatch<React.SetStateAction<string>>;
}

export const ServiceSelect = ({
	areaSelected,
	setAreaSelected,
	setOption,
}: IServiceSelect) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location?.state?.params;

	const { _id } = useSelector((state: RootState) => state.projectBase);
	const { setDataSelect } = useContext(SupervisionHistoryContext);

	const handleView = (area: IRoutine) => {
		setDataSelect({ ...DefaultDataSelect });
		dispatch(setState({ state: 1 }));
		dispatch(resetPage());
		setAreaSelected(area);
	};

	const {
		dataSuspensionsService,
		setDataSuspensionsService,
		executeSuspensionsService,
	} = useSuspensionsService(handleView);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeSuspensionsService(
			{},
			`?project=${state?.project?._id || _id}&page=${
				dataSuspensionsService.pagination
			}&recordsByPage=${dataSuspensionsService.rowsPerPage}${
				isEmpty(dataSuspensionsService?.advancedFilter)
					? ''
					: `&advancedSearch=${dataSuspensionsService.advancedFilter}`
			}`
		);
	}, [
		dataSuspensionsService.pagination,
		dataSuspensionsService.rowsPerPage,
		dataSuspensionsService?.filters,
	]);

	return (
		<>
			<GoBack action={() => setOption('')} />
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Supervisión por servicio
							</span>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<TableCustom
								columns={columnsService}
								data={dataSuspensionsService}
								setData={setDataSuspensionsService}
								showLimitPerPage
								showPagination
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
