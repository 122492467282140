import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../../components/header-edit.component';
import ActividadesIcon from '../../../assets/icons/actividades.icon';
import { useEditActivity } from './hooks/useEditActivity';
import { GoBack } from '../../../components/go-back-component';
import { Button } from '../../../components/main-button.component';
import TextInput from '../../../components/text-input.component';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';

interface IFormActivitites {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentActivity: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	currentActivity: IAdaptedValues;
}

export const EditActivity = ({
	setStep,
	setCurrentActivity,
	currentActivity,
}: IFormActivitites) => {
	const { inputs, matches, onChangeValue, updateActivity } = useEditActivity({
		setCurrentActivity,
		currentActivity,
	});

	return (
		<>
			<GoBack action={() => setStep(0)} />
			<Paper>
				<Box padding={3}>
					<Grid
						container
						spacing={3}
						justifyContent={matches ? 'center' : 'flex-end'}
					>
						<HeaderEdit
							section="Actividad"
							title="2. Asigna o edita tus atributos."
							alertText="Designe el nombre específico de los atributos de tu actividad."
							icon={<ActividadesIcon size="23px" />}
						/>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								{inputs.map((input: any) => {
									return (
										<Grid
											item
											xs={12}
											md={6}
											key={`input-text-${input.id}`}
										>
											<TextInput
												key={`input-text-${input.id}`}
												id={input.id
													.toLowerCase()
													.replace(/ /g, '-')}
												name={input.id}
												label={input.label}
												placeholder={input.placeholder}
												value={input.value}
												type="text"
												onChange={(e) =>
													onChangeValue(
														e.target.name,
														e.target.value
													)
												}
												contentStyles={{
													width: {
														xs: '100%',
														sm: '350px',
														md: '100%',
													},
												}}
												sx={{
													width: '100%',
												}}
												InputProps={{
													sx: {
														backgroundColor: '#F7F7F7',
														width: { xs: '100%' },
														fontSize: '15px',
													},
												}}
											/>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
						<Grid item>
							<Button
								label="Guardar"
								onClick={() => updateActivity()}
								variant="outlined"
								style={{
									backgroundColor: ' #0080F9',
									width: '200px',
									color: 'white',
								}}
								disabled={inputs.some((input: any) => input.value === '')}
							/>
						</Grid>
						<Grid item>
							<Button
								label="Editar criterios generales"
								onClick={() => setStep(2)}
								variant="outlined"
								style={{
									color: ' #0080F9',
									width: '200px',
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
