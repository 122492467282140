/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react';
import theme from '../../../../config/theme';
import { useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useValidateSuspension } from './useValidateSuspension';
import { INewInfoProject } from '../interfaces/new-info-project.interface';
import { DefaultModal, IModal } from '../../../../interfaces/modal.interface';
import { BodyRangeDates } from '../body-range-dates';
import { useTemporarySuspension } from './useTemporarySuspension';
import { usePermanentSuspension } from './usePermanentSuspension';

interface IViewProjects {
	newInfoProject: INewInfoProject[];
}

export const useViewProjects = ({ newInfoProject }: IViewProjects) => {
	const navigate = useNavigate();
	const location = useLocation();
	const idUser = location.state.idUser as string;
	const idProject = location.state.idProject as string;
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [active, setActive] = useState(false);
	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const { projectsData, handleValidate } = useValidateSuspension();
	const { handleSuspendTemporary } = useTemporarySuspension();
	const { handleSuspendPermanent } = usePermanentSuspension();

	useEffect(() => {
		handleValidate(idUser);
	}, []);

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const disabledButton = useMemo((): boolean => {
		if (
			newInfoProject.length === 0 ||
			newInfoProject.some((project) =>
				project.activities.some((activity) =>
					Object.keys(activity).some(
						(property) => activity[property] === idUser
					)
				)
			)
		)
			return true;
		return false;
	}, [newInfoProject]);

	const handleSuspendAction = (startDate: string, endDate: string) => {
		handleSuspendTemporary({
			userToSuspend: idUser,
			data: newInfoProject,
			suspensionEndDate: endDate,
			suspensionStartDate: startDate,
		});
	};

	const onContinue = () => {
		if (idProject) {
			if (!active) {
				setModal({
					...modal,
					open: true,
					title: 'Desactivar usuario',
					body: (
						<BodyRangeDates
							handleSuspendAction={handleSuspendAction}
							closeModal={closeModal}
						/>
					),
					hideConfirm: true,
					hideCancel: true,
				});
			} else {
				setModal({
					...modal,
					open: true,
					title: 'Desactivar usuario',
					body: '¿Seguro que desea desactivar este usuario? Esta acción será permanente.',
					hideConfirm: false,
					hideCancel: false,
					callback: () => {
						handleSuspendPermanent({
							userToSuspend: idUser,
							data: newInfoProject,
							fromOnlyOneProject: idProject ? true : false,
						});
					},
				});
			}
		} else {
			setModal({
				...modal,
				open: true,
				title: 'Eliminar usuario',
				body: '¿Seguro que desea eliminar al usuario? Esta acción será permanente.',
				hideConfirm: false,
				hideCancel: false,
				callback: () => {
					handleSuspendPermanent({
						userToSuspend: idUser,
						data: newInfoProject,
						fromOnlyOneProject: idProject ? true : false,
					});
				},
			});
		}
	};

	return {
		active,
		projectsData,
		matches,
		disabledButton,
		modal,
		idProject,
		navigate,
		onContinue,
		setModal,
		setActive,
	};
};
