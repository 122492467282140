/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { IGetPersonal } from '../../../../../../interfaces/user.interface';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';

export const useUpdatePersonal = () => {
	const dispatch = useDispatch();

	const { execute: updatePersonal, status: statusUpdatePersonal } = useApiRequest<
		Partial<IGetPersonal>,
		IResponsePayload<IGetPersonal>
	>({
		path: URLS.EDIT_PROJECT.PERSONALS.GET_PERSONAL_BY_ROLE,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusUpdatePersonal === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Usuario actualizado con éxito',
					severity: 'success',
				})
			);
		} else if (statusUpdatePersonal === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdatePersonal]);

	const handleUpdatePersonal = (body: Partial<IGetPersonal>, _id: string) => {
		dispatch(setLoading({ loading: true }));
		updatePersonal(body, `/${_id}`);
	};

	return { handleUpdatePersonal };
};
