import StepTwoFixed from '../../../../components/step-two-fixed';
import { IStepProps, IRenderItemProps } from '../../../../interfaces/stepTwo.interface';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

interface IItem {
	itemProps: IRenderItemProps;
}

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

const InputItem = (props: IItem) => {
	const { item, column, handleTable, disabled } = props.itemProps;
	return (
		<>
			{item.columns.map((el, row) => (
				<Input
					id={`table-${column}-${row}`}
					key={`table-${column}-${row}`}
					variant="outlined"
					size="small"
					fullWidth
					disabled={disabled}
					placeholder="Escribe aquí"
					value={el}
					onChange={(e) => handleTable(column, row, e.target.value)}
				/>
			))}
		</>
	);
};

const StepTwoComponent = (props: IStepProps) => {
	const { data } = props;
	return (
		<StepTwoFixed
			{...props}
			id="btn-findings-continue"
			renderItem={(eleProps) => {
				if (!data || data.length < 1) return <></>;
				return <InputItem itemProps={eleProps} />;
			}}
		/>
	);
};

export default StepTwoComponent;
