import { IColumns } from '../../../interfaces/table.insterface';

export const columnsSuspensionsSupervisionRoutine: IColumns[] = [
	{
		text: '',
		nameID: 'img',
		align: 'center',
	},
	{
		text: 'Causa de la suspensión',
		nameID: 'causeSuspension',
	},
	{
		text: 'Descripción de la suspensión',
		nameID: 'suspensionDescription',
		width: '300px',
	},
	{
		text: '',
		nameID: 'actions',
	},
];

export const columnsSuspensionsRoutine: IColumns[] = [
	{
		text: '',
		nameID: 'img',
		align: 'center',
	},
	{
		text: 'Causa de la suspensión',
		nameID: 'causeSuspension',
	},
	{
		text: 'Descripción de la suspensión',
		nameID: 'suspensionDescription',
		width: '500px',
	},
];
