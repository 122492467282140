/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IReportsSH } from '../interfaces/tables.interface';
import { stopLoading, setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { reportsSHAdapter } from '../adapters/reports-adapter-sh.adapter';

export function useAllReports() {
	const { data: dataReports, setData: setDataReports } = useTableCustom<Object>();
	const dispatch = useDispatch();

	const downloadReport = (fileNameKey: string) => {
		dispatch(setLoading({ loading: true }));
		executeGetReport({ key: fileNameKey });
	};

	const {
		execute: executeAllReports,
		status: statusAllReports,
		value: valueAllReports,
	} = useApiRequest<unknown, IResponsePayload<IReportsSH>>({
		path: URLS.REPORTS.GET_REPORTS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusAllReports === 'success') {
			setDataReports({
				...dataReports,
				countRows: valueAllReports?.totalRecords || 0,
				rows: reportsSHAdapter(valueAllReports?.data || [], downloadReport),
			});
		} else if (statusAllReports === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
		dispatch(stopLoading());
	}, [statusAllReports]);

	const {
		execute: executeGetReport,
		status: statusGetReport,
		value: valueGet,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.REPORTS.GET_URL_REPORT,
		method: 'POST',
	});

	useEffect(() => {
		if (statusGetReport === 'success') {
			dispatch(stopLoading());
			document.location = valueGet?.detail;
		} else if (statusGetReport === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al descargar el reporte',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetReport]);

	return {
		dataReports,
		setDataReports,
		executeAllReports,
	};
}
