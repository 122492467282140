/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { ISuspendUser } from '../interfaces/suspend-user.interface';
import { useNavigate } from 'react-router-dom';

export const useTemporarySuspension = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		execute: temporarySuspension,
		status: statusTemporarySuspension,
		value: valueTemporarySuspension,
	} = useApiRequest<ISuspendUser, IResponsePayload<unknown>>({
		path: URLS.USER_PROJECT_MANAGEMENT.TEMPORARY_SUSPENSION,
		method: 'POST',
	});

	useEffect(() => {
		if (statusTemporarySuspension === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message:
						'Solicitud recibida con éxito. El cambio se realizará a las 12:00PM.',
					severity: 'success',
				})
			);
			navigate(-1);
		} else if (statusTemporarySuspension === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueTemporarySuspension?.message ||
						'Ocurrió un error validar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusTemporarySuspension]);

	const handleSuspendTemporary = (body: ISuspendUser) => {
		dispatch(setLoading({ loading: true }));
		temporarySuspension(body);
	};

	return {
		handleSuspendTemporary,
	};
};
