import { cloneElement } from 'react';
import Step from '@mui/material/Step';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import StepperMUI, { StepperProps } from '@mui/material/Stepper';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export interface IStep {
	label: string;
	icon?: JSX.Element;
}

export interface IStepper {
	steps: IStep[];
	stepperProps?: StepperProps;
	wrap?: boolean;
	activeStep: number;
	completed: {
		[k: number]: boolean;
	};
}

interface IStepItemProps {
	completed?: boolean;
	active?: boolean;
	nonLinear?: boolean;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 15,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: '#10A243',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: '#10A243',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
}));

const StepItem = styled('div')<{
	ownerState: IStepItemProps;
}>(({ theme, ownerState }) => ({
	backgroundColor: '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 35,
	height: 35,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: ownerState.nonLinear ? 'pointer' : 'default',
	...(ownerState.active && {
		background: '#0080F9',
	}),
	...(ownerState.completed && {
		background: '#10A243',
	}),
}));

export const Stepper = (props: IStepper) => {
	const { steps, activeStep, completed, stepperProps, wrap = true } = props;

	const LibStepIcon = (props: StepIconProps) => {
		const { active, completed, className } = props;

		if (!steps[Number(props.icon) - 1]) {
			return <StepItem ownerState={{ completed, active }} className={className} />;
		}

		return (
			<StepItem
				ownerState={{ completed, active }}
				className={className}
				id={`icon-${props.icon}`}
			>
				{steps[Number(props.icon) - 1].icon &&
					cloneElement(steps[Number(props.icon) - 1].icon as JSX.Element)}
			</StepItem>
		);
	};

	return (
		<div>
			<Stack sx={{ width: '100%' }} spacing={4}>
				<StepperMUI
					alternativeLabel
					sx={{ flexWrap: wrap ? 'wrap' : 'nowrap', overflow: 'auto' }}
					{...stepperProps}
					activeStep={activeStep}
					connector={<ColorlibConnector />}
				>
					{steps.map((item, index) => (
						<Step
							key={item.label}
							completed={completed[index]}
							id={`step-${index}`}
						>
							<StepLabel StepIconComponent={LibStepIcon}>
								{item.label}
							</StepLabel>
						</Step>
					))}
				</StepperMUI>
			</Stack>
		</div>
	);
};
