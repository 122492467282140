/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../constants/urls.constants';
import { setAlert } from '../store/modules/snackbar';
import { stopLoading } from '../store/modules/loading';
import { IResponsePayload, useApiRequest } from './useApiRequest';

export const useDownloadReport = () => {
	const dispatch = useDispatch();

	const {
		execute: executeDownloadReport,
		status: statusDownloadReport,
		value: valueReport,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.REPORTS.GET_URL_REPORT,
		method: 'POST',
	});

	useEffect(() => {
		if (statusDownloadReport === 'success') {
			dispatch(stopLoading());
			window.open(valueReport?.detail, '_blank');
		} else if (statusDownloadReport === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al descargar el archivo',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusDownloadReport]);

	return { executeDownloadReport };
};
