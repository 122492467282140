import { Box, Grid, Pagination, Stack } from '@mui/material';
import styles from './styles/styles.module.css';
import Card from '../../components/card.component';
import { useHomeProjects } from './hooks/useHomeProjects';
import { NoResults } from './components/no-results.component';
import { IProject } from '../../interfaces/project.interface';

const Home = () => {
	const { data, firstName, lastName, pagination, setPagination } = useHomeProjects();
	return (
		<div>
			<h2 className={styles.welcome}>Bienvenido, {`${firstName} ${lastName}`}</h2>
			<Grid container>
				<Box display="flex" flexWrap="wrap" width="100%">
					{data.length > 0 ? (
						data.map((item: IProject) => (
							<Grid xs={12} sm={6} xl={4} item key={item._id}>
								<Box marginBottom={2} marginRight={2}>
									<Card
										image={item?.coverImage || ''}
										title={item.shortName}
										keyValue={item.contractNumber}
										idProject={item._id || ''}
										description={item.description}
									/>
								</Box>
							</Grid>
						))
					) : (
						<NoResults />
					)}
				</Box>
				<Box display={'flex'} justifyContent="center" flex={1} marginTop={'20px'}>
					<Stack spacing={2}>
						<Pagination
							showFirstButton
							showLastButton
							count={pagination.pages}
							onChange={(_e, page) =>
								setPagination({
									...pagination,
									pagination: page,
								})
							}
						/>
					</Stack>
				</Box>
			</Grid>
		</div>
	);
};

export default Home;
