/* eslint-disable prettier/prettier */
import { useEffect, useState, useMemo } from 'react';
import VerticalStepper from '../../../../components/vertical-stepper.component';
import renderItem from '../../styles/render-item-styles.module.css';
import { TextArea } from '../../../../components/text-area.component';
import Grid from '@mui/material/Grid';
import { isEmpty } from '../../../../utils/validators';
import { cloneElement } from '../../../../utils/createCriterio';
import { ICheck } from '../../../../components/table-vertical-stepper.component';
import { IItem } from '../../interfaces/logic.interface';
import { defaultCreacion } from '../../adapters/data.adapters';
import { ITable } from '../../../../interfaces/criterios.interface';
import SelectComponent from '../../../../components/select.component';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import ContinueButton from '../../../../components/custom-btn.component';
import DashedBtn from '../../../../components/dashed-button.component';
import { DeleteBtn } from '../../../../components/button-delete.component';
import { uniqueId } from '../../../../utils/uniqueId';

const monthOptions: IOptionsMenu[] = [
	{
		id: 0,
		label: 'Día',
		value: 1,
	},
	{
		id: 1,
		label: 'Semana',
		value: 7,
	},
	{
		id: 2,
		label: 'Mes',
		value: 30,
	},
	{
		id: 3,
		label: 'Año',
		value: 365,
	},
];

const dayOptions: IOptionsMenu[] = Array.from(Array(31), (_, i) => ({
	id: i,
	label: i,
	value: i,
}));

const Title = (item: ICheck, idx?: number) => {
	return (
		<Grid
			item
			xs={2}
			sx={{
				height: '100%',
				backgroundColor: '#fff',
				position: 'sticky !important',
				left: '70px',
				background: '#fff',
				zIndex: 1,
				marginRight: '5rem',
			}}
		>
			<p className={renderItem.renderTitle}>
				{item.label} {(idx || 0) + 1}
			</p>
		</Grid>
	);
};

const ColumnItem = (props: IItem) => {
	const { item, column, handleTable, handleDelete } = props;
	const isNotRecurrent = item.rows[3].value;
	const RepeatValue = item.rows[1].value;
	return (
		<Grid item spacing={2} className={renderItem.containerScroll}>
			{item.rows.map((el, row) => {
				const id = `freq-table-${column}-${row}`;
				if (el.key === 'name')
					return (
						<Grid item style={{ width: '600px' }} key={id}>
							<TextArea
								id={id}
								RenderTitle={() => {
									if (column === 0)
										return <p>Nombre de la frecuencia</p>;
									return (
										<p style={{ color: 'transparent' }}>
											Nombre de la frecuencia
										</p>
									);
								}}
								hideCopyPaste
								maxRows={1}
								defaultValue={el.value}
								onChange={(val) => {
									handleTable(column, row, val);
								}}
							/>
						</Grid>
					);
				else if (el.key === 'repeat_day' || el.key === 'repeat_month')
					return (
						<Grid item xs="auto" container spacing={2} alignItems={'center'}>
							{el.key === 'repeat_day' && (
								<Grid item>
									<p>Repetir cada</p>
								</Grid>
							)}
							<Grid item key={id}>
								<SelectComponent
									id={`select-frequency-${el.key}-${column}-${row}`}
									rootSx={{
										...(el.key === 'repeat_day' && {}),
										display: 'flex',
										alignItems: 'center',
									}}
									containerSx={{
										display: 'flex',
										width: '100%',
									}}
									styles={{
										width: '100%',
									}}
									formSx={{
										width: '100%',
									}}
									disabled={isNotRecurrent}
									placeholder=""
									value={el.value}
									optionsMenu={
										el.key === 'repeat_day'
											? dayOptions
											: monthOptions.map((i) => {
													let label = null;
													if (RepeatValue > 1) {
														if (i.label === 'Día')
															label = 'Días';
														else if (i.label === 'Semana')
															label = 'Semanas';
														else if (i.label === 'Mes')
															label = 'Meses';
														else if (i.label === 'Año')
															label = 'Años';
													}

													return {
														...i,
														label: label || i.label,
													};
											  })
									}
									onChange={(e) => {
										handleTable(column, row, e.target.value);
									}}
								/>
							</Grid>
						</Grid>
					);
				return (
					<Grid item xs={4}>
						<FormGroup
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<FormControlLabel
								control={
									<Checkbox
										checked={el.value}
										onChange={(_, value) => {
											handleTable(column, row, value);
										}}
									/>
								}
								label="Hacer no recurrente"
							/>
						</FormGroup>
					</Grid>
				);
			})}
			<Grid item alignSelf={'end'}>
				<DeleteBtn
					disabled={false}
					onClick={() => handleDelete && handleDelete(item.id)}
				/>
			</Grid>
		</Grid>
	);
};

interface IStepProps {
	onContinue?: (data: ITable[]) => void;
}
const StepTwoContent = (props: IStepProps) => {
	const { onContinue } = props;
	const [creacion, setCreacion] = useState<ITable[]>([]);

	useEffect(() => {
		setCreacion(defaultCreacion);
	}, []);

	const isNextAllowed = useMemo(() => creacion.every((i) => i.isComplete), [creacion]);

	const handleTable = (column: number, row: number, value: any) => {
		const newCriteriosTable = [...creacion];
		newCriteriosTable[column].rows[row].value = value;
		if (
			newCriteriosTable[column].rows[3].value === true &&
			!isEmpty(newCriteriosTable[column].rows[0].value)
		)
			newCriteriosTable[column].isComplete = true;
		else if (
			newCriteriosTable[column].rows
				.slice(0, 3)
				.every((el) => isEmpty(el.value) === false)
		)
			newCriteriosTable[column].isComplete = true;
		else newCriteriosTable[column].isComplete = false;

		setCreacion(newCriteriosTable);
	};

	const handleContinue = () => {
		if (onContinue) onContinue(creacion);
	};

	const handleAddSpace = () => {
		const new_element = cloneElement({
			id: uniqueId(),
			data: creacion,
		});
		const clone = [...creacion, new_element];
		setCreacion(clone);
	};

	const handleDelete = (id: string | number) => {
		if (creacion.length <= 1) return;
		setCreacion((prev) => prev.filter((i) => i.id !== id));
	};

	return (
		<>
			<div style={{ overflow: 'auto' }}>
				<VerticalStepper
					gridKey="criterios-table"
					sxContainer={{
						flexWrap: 'nowrap',
						alignItems: 'flex-start',
					}}
					sxElement={{
						position: 'sticky !important',
						left: '-5px',
						zIndex: 1,
						height: '100%',
						background: '#fff',
						minWidth: '5rem',
					}}
					rootStyle={{ overflowX: 'scroll', width: '100%' }}
					items={creacion}
					renderTitle={Title}
					renderItem={(item, column) => (
						<ColumnItem
							column={column}
							handleTable={handleTable}
							item={item}
							handleDelete={handleDelete}
						/>
					)}
				/>
			</div>
			<div style={{ width: '100%', display: 'flex', margin: '2rem 0' }}>
				<DashedBtn
					id="cronograma-addfreq-btn"
					onClick={handleAddSpace}
					width="100"
					text="Agregar frecuencia"
				/>
			</div>
			<div style={{ width: '100%', display: 'flex', marginTop: '2rem' }}>
				<ContinueButton
					id="cronograma-continue-btn"
					customSx={{ marginLeft: 'auto' }}
					disabled={!isNextAllowed}
					onClick={handleContinue}
				/>
			</div>
		</>
	);
};

export default StepTwoContent;
