import { STATUS_SUPERVISION_TYPES } from '../../../../constants/status-supervision';
import { IFilter } from '../../../../interfaces/filter.interface';

export const FilterSupervision: IFilter[] = [
	{
		label: 'Estatus',
		name: 'supervisionStatus',
		value: '',
		type: 'select',
		options: [
			{ label: 'Ninguno', value: '' },
			{
				label: 'Cumple',
				value: STATUS_SUPERVISION_TYPES.ACCOMPLISHED,
			},
			{
				label: 'No cumple',
				value: STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED,
			},
			{
				label: 'Por validar',
				value: STATUS_SUPERVISION_TYPES.PENDING_TO_VALIDATE,
			},
			{
				label: 'Supendidas',
				value: STATUS_SUPERVISION_TYPES.SUSPENDED,
			},
		],
		md: 6,
		placeholder: 'Selecciona un estatus',
	},
];
