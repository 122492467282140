/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { stopLoading, setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { IGetRoles } from '../interfaces/get-roles.interface';
import {
	getRolesAdaptedData,
	getRolesInputsAdapter,
} from '../adapters/get-roles.adapter';
import { RootState } from '../../../../../../store';

export const useGetRoles = () => {
	const dispatch = useDispatch();
	const [rolesInputs, setRolesInputs] = useState<{ [key: string]: string }>({});
	const [rolesOptions, setRolesOptions] = useState<IAdaptedValues[]>([]);

	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);

	const {
		execute: getRoles,
		status: statusGetRoles,
		value: valueGetRoles,
	} = useApiRequest<unknown, IResponsePayload<IGetRoles>>({
		path: URLS.EDIT_PROJECT.ROLES.GET_ROLES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetRoles === 'success') {
			dispatch(stopLoading());
			setRolesOptions(getRolesAdaptedData(valueGetRoles?.data || []));
			setRolesInputs(getRolesInputsAdapter(valueGetRoles?.data || []));
		} else if (statusGetRoles === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los roles',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetRoles]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getRoles(null, `?project=${idProject}`);
	}, []);

	return {
		rolesOptions,
		rolesInputs,
		valueGetRoles,
		setRolesOptions,
		setRolesInputs,
	};
};
