/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { useApiRequest } from '../../../../hooks/useApiRequest';
import { stopLoading } from '../../../../store/modules/loading';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import { INewFindings } from '../interfaces/new-incident.interface';

export function useCreateFindings() {
	const dispatch = useDispatch();
	const { undoState } = useContext(SupervisionProjectContext);

	const { execute: executeCreateFindings, status: statusCreateFindings } =
		useApiRequest<INewFindings, unknown>({
			path: URLS.SUPERVISION_ROUTINE.FILES,
			method: 'POST',
		});

	useEffect(() => {
		if (statusCreateFindings === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Incidencia creada con éxito.',
					severity: 'success',
				})
			);
			undoState();
		} else if (statusCreateFindings === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, intente de nuevo.',
					severity: 'error',
				})
			);
		}
	}, [statusCreateFindings]);

	return {
		executeCreateFindings,
	};
}
