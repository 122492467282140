import React from 'react';

interface IProps {
	size?: string;
}
function Icon(props: IProps) {
	const { size = '19.609px' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 19.609 10.895"
		>
			<path
				fill="#fff"
				d="M6.817 24.895a.792.792 0 11.586-.232.794.794 0 01-.586.232zm0-4.63a.792.792 0 11.586-.232.794.794 0 01-.586.231zm0-4.63A.792.792 0 117.4 15.4a.794.794 0 01-.583.234zm3.813 9.26V23.26h14.98v1.634zm0-4.63V18.63h14.98v1.634zm0-4.63V14h14.98v1.634z"
				transform="translate(-6 -14)"
			></path>
		</svg>
	);
}

export default Icon;
