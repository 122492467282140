import { Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from './styles/upload-successful-styles.module.css';

interface IUploadSuccessful {
	nameFile: string;
	setNameFile: React.Dispatch<React.SetStateAction<string>>;
}

export const UploadSuccessful = ({ nameFile, setNameFile }: IUploadSuccessful) => {
	return (
		<Grid container justifyContent="center" marginTop={10}>
			<Grid
				item
				display="flex"
				alignItems="center"
				flexDirection="column"
				justifyContent="center"
			>
				<div className={styles.iconSuccess}>
					<CheckCircleIcon />
					<Typography variant="h5">
						El archivo se ha cargado correctamente.
						<br />
						{nameFile}
					</Typography>
				</div>
				<div className={styles.buttonSuccess}>
					<Typography variant="body1">
						<span onClick={() => setNameFile('')}>Subir otro archivo.</span>
					</Typography>
				</div>
			</Grid>
		</Grid>
	);
};
