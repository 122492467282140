/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITask } from '../../../interfaces/project.interface';
import { supervisionExtraordinaryAdapter } from '../adapters/supervision-extraordinary.adapter';
import { RootState } from '../../../store';
import { setPage } from '../../../store/modules/page-table';

export const useSupervisionExtraordinary = () => {
	const location = useLocation();
	const idProject = location.state as string;
	const { _id } = useSelector((state: RootState) => state.projectBase);
	const { page, rowsPerPage: rows } = useSelector(
		(state: RootState) => state.pageTable
	);
	const { data, setData, pagination, rowsSelect, rowsPerPage, filters } =
		useTableCustom<Object>({
			countRows: 0,
			rows: [],
			sort: { col: '', order: '' },
			pagination: page,
			rowsSelect: [],
			rowsPerPage: rows,
			advancedFilter: '',
			activeAdvanceFilter: false,
			filterCustom: {},
		});

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		execute: getSupervisions,
		status: statusSupervisions,
		value: valueSupervisions,
	} = useApiRequest<unknown, IResponsePayload<ITask>>({
		path: URLS.SUPERVISION_EXECUTION.INCOMING_ROUTINES,
		method: 'GET',
	});

	useEffect(() => {
		if (!idProject && !_id) navigate(-1);
	}, []);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getSupervisions(
			null,
			`?page=${pagination}&recordsByPage=${rowsPerPage}&project=${
				idProject || _id
			}&advancedSearch=${filters?.advancedSearch || ''}&routineType=EXTRAORDINARY`
		);
	}, [pagination, rowsSelect, rowsPerPage, filters]);

	useEffect(() => {
		if (statusSupervisions === 'success') {
			dispatch(stopLoading());
			setData({
				...data,
				countRows: valueSupervisions?.totalRecords || 0,
				rows: supervisionExtraordinaryAdapter(
					valueSupervisions?.data || [],
					viewDetails
				),
				rowsPerPage,
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusSupervisions === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusSupervisions]);

	const viewDetails = (idSupervision: string, idSupervisor: string) => {
		dispatch(
			setPage({
				page: data.pagination,
				rowsPerPage: data.rowsPerPage,
			})
		);
		navigate('ver-supervision', {
			state: {
				params: {
					idSupervision: idSupervision,
					idSupervisor: idSupervisor,
					isTask: false,
					isRecurring: true,
				},
			},
		});
	};

	const createSupervision = () => {
		dispatch(
			setPage({
				page: data.pagination,
				rowsPerPage: data.rowsPerPage,
			})
		);
		navigate('crear-supervision-extraordinaria', {
			state: idProject || _id,
		});
	};

	return {
		data,
		setData,
		createSupervision,
	};
};
