/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { URLS } from '../../../../../constants/urls.constants';
import { stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { serviceViewAdapter } from '../adapters/service-view-adapter';
import { RootState } from '../../../../../store';
import { SupervisionHistoryContext } from '../../../contexts/useSupervisionHistoryContext';

export function useByActivityRoutines(handleView: any) {
	const dispatch = useDispatch();
	const { page, rowsPerPage } = useSelector((state: RootState) => state.pageTable);
	const { dataSelect, setDataSelect } = useContext(SupervisionHistoryContext);

	const {
		data: dataActivityRoutines,
		setData: setDataActivityRoutines,
		handleGetFiltersParams,
		filters,
	} = useTableCustom<Object>({
		countRows: 0,
		rows: [],
		sort: { col: '', order: '' },
		pagination: page,
		rowsSelect: [],
		rowsPerPage: rowsPerPage,
		advancedFilter: '',
		activeAdvanceFilter: false,
		filterCustom: {},
	});

	const {
		execute: executeActivityRoutines,
		status,
		value,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.SUPERVISION_HISTORY.HISTORY_BY_ACTIVITY_ROUTINES,
		method: 'GET',
	});

	useEffect(() => {
		if (status === 'success') {
			setDataActivityRoutines({
				...dataActivityRoutines,
				countRows: value?.totalRecords || 0,
				rows: serviceViewAdapter(value?.data || [], handleView),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
			dispatch(stopLoading());
		} else if (status === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [status]);

	return {
		dataActivityRoutines,
		setDataActivityRoutines,
		executeActivityRoutines,
		handleGetFiltersParams,
		dataSelect,
		setDataSelect,
		filters,
	};
}
