import { createSlice } from '@reduxjs/toolkit';

interface IPageTable {
	page: string;
	rowsPerPage: string;
}

const defaultValueStateProject: IPageTable = {
	page: '1',
	rowsPerPage: '10',
};
export const PageTableSlice = createSlice({
	name: 'pageTable',
	initialState: defaultValueStateProject,
	reducers: {
		setPage: (state, action) => ({
			...state,
			...action.payload,
		}),
		resetPage: () => defaultValueStateProject,
	},
});

export const { setPage, resetPage } = PageTableSlice.actions;

export default PageTableSlice.reducer;
