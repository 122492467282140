import TableCustom from '../../../components/table-custom.component';
import {
	columnsFindingsRoutine,
	columnsFindingsSupervisionRoutine,
} from '../utils/columns-findings.utils';
import { useGetFindingsTable } from './hooks/useGetFindingsTable';
import { Image } from '@mui/icons-material';
import { useDownloadImageZip } from './hooks/useDownloadImageZip';

interface IFindings {
	isSupervisionRoutine: boolean;
	isFindings: boolean;
}

export const Findings = ({ isSupervisionRoutine, isFindings }: IFindings) => {
	const { handleDownloadZip } = useDownloadImageZip();
	const { data, idRoutine, _id, idRoutineParams, idProject, idProjectParams, setData } =
		useGetFindingsTable({
			isSupervisionRoutine,
			isFindings,
		});

	return (
		<TableCustom
			titleHeader="Hallazgos"
			columns={
				isSupervisionRoutine
					? columnsFindingsSupervisionRoutine
					: columnsFindingsRoutine
			}
			data={data}
			setData={setData}
			showPagination
			showLimitPerPage
			showSearch={false}
			buttons={
				data.rows.length !== 0
					? [
							{
								icon: Image,
								label: 'Descargar hallazgos',
								id: 'descarga-hallazgos',
								execute: () => {
									handleDownloadZip(
										'finding-files',
										idRoutineParams || idRoutine,
										_id || idProjectParams || idProject,
										data.pagination,
										data.rowsPerPage || '0'
									);
								},
								type: 'blue',
								width: 200,
							},
					  ]
					: []
			}
		/>
	);
};
