import { IInitialEvidences } from '../interfaces/initial-evidences.interface';
import ImageCarousel from '../../../../../components/image-carousel.component';

export const initialEvidencesAdapter = (data: IInitialEvidences[]) => {
	const activitiesInfo = data?.map((element) => {
		return {
			img: <ImageCarousel images={getImages([element] || [])} />,
			description: element?.description || '',
		};
	});
	return activitiesInfo;
};

const getImages = (images: any) => {
	const imagesAdapted = images?.map((e: any) => {
		return { label: e.description, imgPath: e.url, id: e._id || '' };
	});

	return imagesAdapted;
};
