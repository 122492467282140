import { Paper, Box, Grid } from '@mui/material';
import styles from './styles/stepper-styles.module.css';
import { useViewFunctionals } from './hooks/useViewFunctionals';
import { Button } from '../../../components/main-button.component';
import DashedBtn from '../../../components/dashed-button.component';
import DimensionesIcon from '../../../assets/icons/dimensiones.icon';
import { HeaderEdit } from '../../../components/header-edit.component';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import { EditItemRow } from '../../../components/edit-item-row.component';
import VerticalStepper from '../../../components/vertical-stepper-fixed.component';
import { LABELS_LVL_1_1, LABELS_LVL_2_1 } from '../utils/labels-header';
import { GoBack } from '../../../components/go-back-component';
import { IIndicatorModel } from '../../../interfaces/project.interface';

interface IViewFunctionals {
	isLvlOne: boolean;
	functionalParent: string;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentFunctional: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	structureData: IIndicatorModel;
}

export const ViewFunctionals = ({
	isLvlOne,
	functionalParent,
	setStep,
	setCurrentFunctional,
	structureData,
}: IViewFunctionals) => {
	const {
		functionalsOptions,
		matches,
		onEdit,
		onSave,
		onDelete,
		handleNewFunctional,
		navigate,
	} = useViewFunctionals({
		functionalParent,
		isLvlOne,
		setStep,
		setCurrentFunctional,
		structureData,
	});

	return (
		<>
			{!isLvlOne && <GoBack action={() => setStep(0)} />}
			<Paper>
				<Box padding={3}>
					<Grid
						container
						spacing={3}
						justifyContent={matches ? 'center' : 'flex-end'}
					>
						<HeaderEdit
							section="Dimensiones geográficas"
							title={isLvlOne ? LABELS_LVL_1_1.title : LABELS_LVL_2_1.title}
							alertText={
								isLvlOne
									? LABELS_LVL_1_1.alertText
									: LABELS_LVL_2_1.alertText
							}
							icon={<DimensionesIcon size="23px" />}
						/>
						<Grid item xs={12}>
							<VerticalStepper
								gridKey="criterios-table"
								sxContainer={styles.containerVerticalStepper}
								sxElement={styles.verticalStepperSxElement}
								items={functionalsOptions}
								renderItem={(item, column) => (
									<EditItemRow
										item={item}
										column={column}
										onEdit={onEdit}
										onSave={onSave}
										onDelete={onDelete}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<DashedBtn
								onClick={() => handleNewFunctional()}
								width="100"
								text="Agregar"
								disabled={
									!functionalsOptions.every((element) => element.id)
								}
							/>
						</Grid>
						<Grid item>
							<Button
								label="Salir"
								onClick={() => navigate(-1)}
								variant="outlined"
								style={{
									backgroundColor: ' #0080F9',
									width: '200px',
									color: 'white',
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
