import { columns } from '../utils/columns';
import { IDataTable } from '../../../../../hooks/useTableCustom';
import TableCustom from '../../../../../components/table-custom.component';

interface ITableSummary {
	data: IDataTable<any>;
	setData: (arg: IDataTable<any>) => void;
}

export const TableSummary = ({ data, setData }: ITableSummary) => {
	return <TableCustom columns={columns} data={data} setData={setData} hideHeader />;
};
