import { Paper, Box, Grid } from '@mui/material';
import { useEditRelationsUI } from './hooks/useEditRelationsUI';
import { Button } from '../../../../components/main-button.component';
import DimensionesIcon from '../../../../assets/icons/dimensiones.icon';
import InputSelect from '../../../../components/select-input.component';
import { HeaderEdit } from '../../../../components/header-edit.component';
import { ListIndicators } from './components/list-Indicators.component';

export const EditRelationsUI = () => {
	const {
		labelN1,
		labelN2,
		levels,
		currentFunctionalLvl1,
		currentFunctionalLvl2,
		currentActivity,
		matches,
		functionalsSelectOptions,
		functionalsSelectOptionsLvl2,
		activitiesOptions,
		matrixIndicators,
		arrayIndicators,
		idsIndicators,
		navigate,
		setCurrentFunctionalLvl1,
		setCurrentFunctionalLvl2,
		setCurrentActivity,
		onSelect,
		onSelectColumn,
		onSave,
	} = useEditRelationsUI();

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={3} display={'flex'}>
					<HeaderEdit
						section="Dimensiones geográficas"
						title="1. Relación Actividades - Criterios - Espacios (Detallado)"
						alertText="A continuación aparecerán selectores correspondientes a los
						niveles de espacios previamente creados. Para cada combinación
						de niveles aparecerán las actividades a supervisar asociadas
						así como los indicadores de dicha actividad. Selecciona o
						deselecciona los indicadores según apliquen."
						icon={<DimensionesIcon size="23px" />}
					/>
					<Grid item xs={12} sm={6} display={'flex'} alignItems={'center'}>
						<Grid item xs={3}>
							<span style={{ fontWeight: 'bold' }}>
								Elige {labelN1 || 'Nivel 1'}:
							</span>
						</Grid>
						<Grid item xs>
							<InputSelect
								onChange={(e) => {
									setCurrentFunctionalLvl1(
										e?.target?.value?.toString() || ''
									);
									setCurrentFunctionalLvl2('');
									setCurrentActivity('');
								}}
								value={currentFunctionalLvl1}
								optionsMenu={functionalsSelectOptions}
								placeholder="Selecciona"
								id="select-lvl1"
							/>
						</Grid>
					</Grid>
					{levels > 1 && (
						<Grid item xs={12} sm={6} display={'flex'} alignItems={'center'}>
							<Grid item xs={3}>
								<span style={{ fontWeight: 'bold' }}>
									Elige {labelN2 || 'Nivel 2'}:
								</span>
							</Grid>
							<Grid item xs>
								<InputSelect
									onChange={(e) => {
										setCurrentFunctionalLvl2(
											e?.target?.value?.toString() || ''
										);
										setCurrentActivity('');
									}}
									value={currentFunctionalLvl2}
									optionsMenu={functionalsSelectOptionsLvl2}
									placeholder="Selecciona"
									id="select-lvl2"
								/>
							</Grid>
						</Grid>
					)}
					<Grid item xs={12} sm={6} display={'flex'} alignItems={'center'}>
						<Grid item xs={3}>
							<span style={{ fontWeight: 'bold' }}>
								Elige una actividad:
							</span>
						</Grid>
						<Grid item xs>
							<InputSelect
								onChange={(e) => {
									setCurrentActivity(
										e?.target?.value?.toString() || ''
									);
								}}
								value={currentActivity}
								optionsMenu={activitiesOptions}
								placeholder="Selecciona"
								id="select-activity"
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<ListIndicators
							matrixIndicators={matrixIndicators}
							checkedList={idsIndicators}
							selectColumn={onSelectColumn}
							onSelect={onSelect}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						display={'flex'}
						columnGap={3}
						justifyContent={matches ? 'center' : 'flex-end'}
					>
						<Grid item alignSelf={'flex-end'}>
							<Button
								label="Salir"
								onClick={() => navigate(-1)}
								variant="outlined"
								style={{
									width: '200px',
									color: ' var(--color-error-main)',
								}}
							/>
						</Grid>
						<Grid item>
							<Button
								label="Guardar"
								onClick={() => onSave()}
								disabled={arrayIndicators.length > 0 ? false : true}
								variant="outlined"
								style={{
									backgroundColor: ' #0080F9',
									width: '200px',
									color: 'white',
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
