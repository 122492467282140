/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAlert } from '../../../../store/modules/snackbar';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { useGetSupervisionRoutines } from './useGetSupervisionRoutines';
import { STATUS_SUPERVISION_TYPES } from '../../../../constants/status-supervision';
import {
	DESCRIPTION_SUPERVISION,
	LABEL_BUTTON_SUPERVISION,
} from '../../constants/labels-supervision';
import { IFilter } from '../../../../interfaces/filter.interface';
import { FilterSupervision } from '../constants/table-supervision-filters';
import { ISupervisionRoutineData } from '../interfaces/get-supervision-routines.interface';
import { IChangeStatusParams } from '../interfaces/change-status.interface';
import { useGetFunctionals } from './useGetFunctionals';
import { isEmpty } from '../../../../utils/validators';
import { RootState } from '../../../../store';
import { useStopTiming } from '../../hooks/useStopTiming';

export function useTableSupervision() {
	const {
		idRoutine,
		idProject,
		mainSelect,
		subSelect,
		options,
		statusChangeStatus,
		executeChangeStatus,
		executeCompleteSupervision,
		historyState,
		setSupervisionAction,
		setSubSelect,
		setStatus,
		setSupervisionRoutineId,
		setStatusCompleteSupervision,
		setNewState,
		undoState,
		setWithStatus,
		setEditFinding,
		setUnitInfo,
	} = useContext(SupervisionProjectContext);

	const {
		getFunctionals,
		functionalsLvl1,
		functionalsLvl2,
		activitiesList,
		withTwoLevels,
		labelService,
	} = useGetFunctionals();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const isEdit = location.state.isEdit as boolean;

	const [openFilters, setOpenFilters] = useState(false);
	const [filterInputs] = useState<IFilter[]>(FilterSupervision);

	const { labelN1, labelN2 } = useSelector((state: RootState) => state.projectBase);
	const { executeStopTiming } = useStopTiming();

	const handleSetAction = (status: string, element: ISupervisionRoutineData) => {
		if (status === element.status) return;
		switch (status) {
			case STATUS_SUPERVISION_TYPES.ACCOMPLISHED:
				changeStatus(getValuesStatus(status, element));
				break;
			case STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED:
				setEditFinding(null);
				setSupervisionRoutineId(element.supervisionRoutine);
				setWithStatus(getValuesStatus(status, element));
				setStatus('pending');
				setSupervisionAction({
					descriptionLabel: DESCRIPTION_SUPERVISION.NOT_ACCOMPLISHED,
					labelButton: LABEL_BUTTON_SUPERVISION.NOT_ACCOMPLISHED,
					continueActionButton: () => {
						setNewState(4);
					},
					cancelActionButton: () => undoState(),
				});
				setUnitInfo(
					`${element?.functionalParentColumns[0] || '-'} - ${
						element?.functionalColumns[0] || '-'
					} - ${element?.activityColumns[0] || '-'} - ${
						element?.indicatorColumns[0] || '-'
					}`
				);
				setNewState(0);
				break;
			case STATUS_SUPERVISION_TYPES.PENDING_TO_VALIDATE:
				setStatus('pending');
				setSupervisionAction({
					descriptionLabel: DESCRIPTION_SUPERVISION.PENDING_TO_VALIDATE,
					labelButton: LABEL_BUTTON_SUPERVISION.PENDING_TO_VALIDATE,
					continueActionButton: () => {
						changeStatus(getValuesStatus(status, element));
					},
					cancelActionButton: () => undoState(),
				});
				setNewState(0);
				break;
			case STATUS_SUPERVISION_TYPES.SUSPENDED:
				setEditFinding(null);
				setWithStatus(getValuesStatus(status, element));
				setStatus('pending');
				setNewState(6);
				setUnitInfo(
					`${element?.functionalParentColumns[0] || '-'} - ${
						element?.functionalColumns[0] || '-'
					} - ${element?.activityColumns[0] || '-'} - ${
						element?.indicatorColumns[0] || '-'
					}`
				);
				break;
			case 'ver':
				setStatus('pending');
				setSupervisionRoutineId(element.supervisionRoutine);
				setNewState(5);
				break;
		}
	};

	const getFunctionalId = (): string => {
		let functional = '';
		if (
			(withTwoLevels && !isEmpty(subSelect.functionalN2)) ||
			!isEmpty(subSelect.functionalN2)
		) {
			functional = subSelect.functionalN2;
		} else if (!withTwoLevels) {
			functional =
				mainSelect.optionLabel === 'servicio'
					? subSelect.functionalN1
					: mainSelect.valueLabel.toString();
		}
		return functional;
	};

	const {
		data,
		pagination,
		rowsPerPage,
		dinamicColumns,
		filters,
		getSupervisionRoutines,
		setDinamicColumns,
		setData,
		handleFilter,
		handleGetFiltersParams,
	} = useGetSupervisionRoutines({ handleSetAction, getFunctionalId, getFunctionals });

	useEffect(() => {
		if (historyState.currentStep === 2) {
			dispatch(setLoading({ loading: true }));
			const functional = getFunctionalId();
			setDinamicColumns([]);
			setData({
				...data,
				countRows: 0,
				rows: [],
			});
			if (isEmpty(functional)) return;

			if (mainSelect.optionLabel === 'espacio' && !isEmpty(subSelect.service)) {
				getSupervisionRoutines(
					{},
					`?routine=${idRoutine}&functional=${functional}&activity=${
						subSelect.service
					}&page=${pagination}&recordsByPage=${rowsPerPage}&includeColumns=true${handleGetFiltersParams()}`
				);
			} else if (mainSelect.optionLabel === 'servicio') {
				getSupervisionRoutines(
					{},
					`?routine=${idRoutine}&functional=${functional}&activity=${
						mainSelect.valueLabel
					}&page=${pagination}&recordsByPage=${rowsPerPage}&includeColumns=true${handleGetFiltersParams()}`
				);
			}
		}
	}, [subSelect, pagination, rowsPerPage, filters, historyState.currentStep]);

	useEffect(() => {
		if (statusChangeStatus === 'success') {
			const functional = getFunctionalId();
			if (isEmpty(functional)) return;
			getSupervisionRoutines(
				{},
				`?routine=${idRoutine}&functional=${functional}&activity=${
					mainSelect.optionLabel === 'espacio'
						? subSelect.service
						: mainSelect.valueLabel
				}&page=${pagination}&recordsByPage=${rowsPerPage}&includeColumns=true`
			);
		} else if (statusChangeStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusChangeStatus]);

	const changeStatus = async (element: IChangeStatusParams) => {
		dispatch(setLoading({ loading: true }));
		await executeChangeStatus({ ...element });
	};

	const completeSupervision = () => {
		if (isEdit) navigate(-1);
		setStatus('pending');
		setStatusCompleteSupervision('pending');
		setSupervisionAction({
			descriptionLabel: DESCRIPTION_SUPERVISION.FINISH_SUPERVISION,
			labelButton: LABEL_BUTTON_SUPERVISION.FINISH_SUPERVISION,
			continueActionButton: () => {
				dispatch(setLoading({ loading: true }));
				if (!isEdit) executeStopTiming(idRoutine);
				executeCompleteSupervision({
					routine: idRoutine,
				});
			},
			cancelActionButton: () => undoState(),
		});
		setNewState(0);
	};

	const handleStopTiming = (exit?: boolean) => {
		if (!isEdit) executeStopTiming(idRoutine);
		if (exit) navigate(-1);
		else undoState();
	};

	const getValuesStatus = (
		newStatus: string,
		element: ISupervisionRoutineData
	): IChangeStatusParams => {
		return {
			newStatus: newStatus,
			_id: element.supervisionRoutine,
			project: idProject,
			routine: element.routine,
			supervisionRoutine: element.supervisionRoutine,
		};
	};

	return {
		mainSelect,
		subSelect,
		options,
		data,
		dinamicColumns,
		openFilters,
		filterInputs,
		setData,
		dispatch,
		completeSupervision,
		undoState,
		setOpenFilters,
		handleFilter,
		setSubSelect,
		executeStopTiming,
		handleStopTiming,
		functionalsLvl1,
		functionalsLvl2,
		activitiesList,
		withTwoLevels,
		labelN1,
		labelN2,
		labelService,
		isEdit,
	};
}
