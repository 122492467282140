/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import theme from '../../../../../../config/theme';
import { useEffect } from 'react';
import { useGetTeams } from './useGetTeams';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { useNavigate } from 'react-router-dom';
import useUpdateProject from '../../../../../../hooks/useUpdateProject';
import { useConfirmProgress } from '../../../../../automatic-charge/hooks/useConfirmProgress';
import { useGetPersonalByProject } from '../../../../../../hooks/usePersonalByProject';

interface IUseCreateTeamsForm {
	isEdit: boolean;
	isAutomaticCharge?: boolean;
}

export const useCreateTeamsForm = ({
	isEdit,
	isAutomaticCharge,
}: IUseCreateTeamsForm) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);

	const { usersData, getTotalUsers } = useGetPersonalByProject({});

	const { teamsData, setTeamsData } = useGetTeams({});
	const { handleUpdateProject } = useUpdateProject();
	const { confirmProgress } = useConfirmProgress();

	useEffect(() => {
		getTotalUsers();
	}, []);

	const handleNewTeam = () => {
		const newTeams = teamsData;
		newTeams.push({
			name: '',
			personals: [''],
			project: idProject || '',
			isComplete: false,
		});
		setTeamsData([...newTeams]);
	};

	const handleOnContinue = () => {
		if (isAutomaticCharge) confirmProgress();
		else if (isEdit) navigate(-1);
		else handleUpdateProject({ step: '4.2.0' }, idProject || '');
	};

	return {
		teamsData,
		usersData,
		matches,
		setTeamsData,
		handleNewTeam,
		handleOnContinue,
	};
};
