/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckboxMUI from '@mui/material/Checkbox';
import Alert from '../../components/info.alert.component';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import InputSelect from '../../components/select-input.component';
import { SplitArrayInNumbers } from '../../utils/splitArray';
import { IOptionsMenu } from '../../interfaces/select.interface';
import ContinueButton from '../../components/custom-btn.component';
import { IIndicador } from '../../interfaces/project.interface';
import usePatchActivities from './hooks/usePatchActivities';
import { IStepTwoModel } from '../../interfaces/stepTwo.interface';
import { setLoading, stopLoading } from '../../store/modules/loading';
import useGetIGbyAct from './hooks/useGetIGbyActivity';
import { isEmpty } from 'lodash';
import useUpdateIG_Act from './hooks/useUpdateIG_Act';
import { sendDataAdapterSupervisionUnit } from '../../adapters/step-two.adapter';
import { setAlert } from '../../store/modules/snackbar';
import { TReactDispatch } from '../../interfaces/utils';
import useUpdateProject from '../../hooks/useUpdateProject';
import SaveButton from '../../components/save-button.component';

const style = {
	p: 4,
};

const FilledButton = styled(Button)<{ customcolor?: string }>(
	({ theme, customcolor = '#0080F9' }) => ({
		textTransform: 'none',
		background: customcolor,
		color: '#FFF',
		marginLeft: 'auto',
		'&:hover': {
			background: customcolor,
		},
	})
);

const TextButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	color: '#0080F9',
}));

const CheckBox = styled(CheckboxMUI)(({ theme }) => ({
	color: 'var(--color-highlight-blue)',
	'&.Mui-checked': {
		color: 'var(--color-highlight-blue)',
	},
}));

interface IModal {
	defaultItems: IStepTwoModel[];
	serviceIGOptions: IOptionsMenu[];
	setServiceIGOptions: TReactDispatch<IOptionsMenu[]>;
	setShowAsignacion: TReactDispatch<boolean>;
	setCurrentTab: TReactDispatch<number>;
}

const ModalAsignación = (props: IModal) => {
	const {
		defaultItems,
		setShowAsignacion,
		serviceIGOptions: serviceOptions,
		setServiceIGOptions: setServiceOptions,
	} = props;
	const dispatch = useDispatch();
	const { _id } = useSelector((state: RootState) => state.projectBase);
	const { handleUpdateProject } = useUpdateProject();
	const [isSave] = useState<boolean>(false);
	const [items, setItems] = useState<Array<IIndicador[]>>([]);
	const [currentService, setCurrentService] = useState<string>('');
	const [checkedList, setCheckedList] = useState<Set<string>>(new Set());
	const { execute: getIGbyAct, value: valueIDs } = useGetIGbyAct();
	const { execute: updateIG_Act } = useUpdateIG_Act({ isSave });
	const { execute: patchActivities } = usePatchActivities();

	const isNext = useMemo(() => {
		return serviceOptions.every((i) => i.status);
	}, [serviceOptions]);

	const handleGetIGbyAct = (id: string) => {
		getIGbyAct(null, `?activity=${id}&project=${_id}`);
	};

	useEffect(() => {
		if (!isEmpty(currentService) || serviceOptions.length < 1) return;
		setCurrentService(String(serviceOptions[0].id));
	}, [serviceOptions, currentService]);

	useEffect(() => {
		setItems(SplitArrayInNumbers(defaultItems, 10));
	}, [defaultItems]);
	//When fetch the new value
	useEffect(() => {
		const collection = new Set<string>();
		const iterable: any[] = valueIDs ? valueIDs.data : [];
		for (const item of iterable) {
			if (item.applies) collection.add(String(item.indicator));
		}
		setCheckedList(collection);
	}, [valueIDs]);

	useEffect(() => {
		if (isEmpty(currentService)) return;
		dispatch(setLoading({ loading: true }));
		handleGetIGbyAct(currentService);
	}, [currentService]);

	const selectColumn = (column: number) => {
		const collection = new Set(checkedList);
		for (const item of items[column]) {
			collection.add(String(item._id));
		}
		setCheckedList(collection);
	};

	const selectAllColumns = () => {
		const collection = new Set(checkedList);
		for (const item of items) {
			for (const itm of item) collection.add(String(itm._id));
		}
		setCheckedList(collection);
	};

	const removeAllCoumns = () => {
		const collection = new Set(checkedList);
		for (const item of items) {
			for (const itm of item) collection.delete(String(itm._id));
		}
		setCheckedList(collection);
	};

	const onSelect = (id: string, checked: boolean) => {
		const collection = new Set(checkedList);

		if (checked) collection.add(id);
		else collection.delete(id);

		setCheckedList(collection);
	};

	const handleSelect = (value: string) => {
		setCurrentService(value);
	};

	const handleContinue = async () => {
		try {
			await handleUpdateProject({ step: '3.0.2' }, `${_id}`);
		} catch (e: any) {
			dispatch(
				setAlert({
					severity: 'error',
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
				})
			);
			dispatch(stopLoading());
		}
	};

	const handleClose = () => {
		setShowAsignacion(false);
	};

	const handleSave = async () => {
		//HACER AQUI LAS PERMUTACIONES;
		const data: any = sendDataAdapterSupervisionUnit({
			indicators: items,
			checkedList,
			projectID: String(_id),
			currentService,
			defaultItems: valueIDs?.data || [],
		});
		try {
			dispatch(setLoading({ loading: true }));
			await updateIG_Act(data);
			//isGeneralAssociate
			await patchActivities(
				{
					isGeneralAssociated: true,
				},
				`/${currentService}`
			);
			handleGetIGbyAct(currentService);
			setServiceOptions((prev) =>
				prev.map((i) => {
					if (i.id === currentService) {
						return {
							...i,
							status: true,
						};
					}
					return i;
				})
			);
		} catch (e: any) {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					severity: 'error',
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
				})
			);
		}
	};

	return (
		<>
			<Paper sx={style}>
				<Grid
					container
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid item>
						<h3>Asignación de criterios generales a actividades</h3>
						<Alert severity="info" sx={{ marginTop: '1rem' }}>
							<>
								En esta pantalla podrás asignar criterios generales a
								múltiples actividades. Para ello, selecciona una
								actividad. En la pantalla inferior aparecerán todos los
								criterios generales creados. Selecciona aquellos que
								apliquen a la actividad. Una vez terminada la selección,
								oprime el botón "Guardar" y continúa con la siguiente
								Actividad. Si has terminado con todas las actividades, haz
								clic en "Continuar".
							</>
						</Alert>
					</Grid>
				</Grid>
				<Grid container spacing={1} alignItems="center" mt={1}>
					<Grid item xs={12} sm={4} lg={2}>
						<p style={{ margin: 0 }}>Elige el servicio</p>
					</Grid>
					<Grid item xs={12} sm={8} lg={5}>
						<FormControl variant="standard" sx={{ m: 1 }} fullWidth>
							<InputSelect
								id="activity-asign-select"
								value={currentService}
								onChange={(e) => handleSelect(String(e.target.value))}
								optionsMenu={serviceOptions}
								withStatus
							/>
						</FormControl>
					</Grid>
				</Grid>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						marginTop: '2rem',
						justifyContent: 'space-between',
					}}
				>
					<Grid container columnGap={4}>
						{items.map((el, idx) => (
							<Grid
								item
								key={`form-${idx}`}
								direction="column"
								display={'flex'}
								flexDirection="column"
							>
								<TextButton
									sx={{ paddingLeft: 0, fontSize: '12px' }}
									onClick={() => selectColumn(idx)}
								>
									Seleccionar Columna
								</TextButton>
								{el.map((prop, row) => (
									<FormControlLabel
										sx={{ fontSize: '27px', marginTop: '10px' }}
										key={`formControl-${idx}-${row}`}
										checked={checkedList.has(prop._id || '')}
										value={prop._id || ''}
										control={<CheckBox />}
										//label="as"
										label={
											<p
												style={{
													fontSize: '24px',
													margin: 0,
												}}
											>
												{prop.columns[0]}
											</p>
										}
										onChange={(_, checked) =>
											onSelect(prop._id || '', checked)
										}
									/>
								))}
							</Grid>
						))}
					</Grid>
				</div>
				<Grid container justifyContent="space-between" mt={3}>
					<Grid
						item
						xs={12}
						sm={6}
						display="flex"
						sx={{
							justifyContent: { xs: 'center', sm: 'flex-start' },
						}}
					>
						<FilledButton
							id="ig-select-all-modal"
							customcolor="#ACAFBD"
							sx={{ margin: { xs: '0 10px', sm: '0' } }}
							onClick={selectAllColumns}
						>
							Seleccionar todo
						</FilledButton>
						<FilledButton
							customcolor="#ACAFBD"
							sx={{ margin: { xs: '0 10px', sm: '0 1rem' } }}
							onClick={removeAllCoumns}
						>
							No aplica ninguno
						</FilledButton>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						display="flex"
						alignItems="center"
						sx={{
							justifyContent: { xs: 'center', sm: 'flex-end' },
							marginTop: { xs: '1rem', sm: '0' },
						}}
					>
						<SaveButton
							id="uf-guardar-btn"
							disabled={false}
							onClick={handleSave}
							sx={{ width: 'auto' }}
						/>
						<FilledButton
							sx={{ margin: '0 1rem' }}
							onClick={handleClose}
							customcolor="#FA4040"
						>
							Cancelar
						</FilledButton>
						<ContinueButton
							id="ig-continue-modal"
							disabled={!isNext}
							onClick={handleContinue}
						/>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};

export default ModalAsignación;
