import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { BoardComponent } from './components/board-component';
import { EditList } from './components/edit-list.component';
import { PlanningMain } from './components/planning-main.component';
import { IRoutine } from '../../../../interfaces/logic.interface';
import { SupervisionHistoryDetail } from '../../../supervision-history/components/supervision-detail.component';
import { RoutineDetailEvidence } from '../../../supervision-history/components/routine/routine-detail-evidence.component';
import { SupervisionViewActions } from '../../../supervision-history/components/supervision-view-actions';
import { SupervisionViewReports } from '../../../supervision-history/components/supervision-view-reports';
import { SupervisionViewActivities } from '../../../supervision-history/components/supervision-view-activities';

export const PlanningComponent = () => {
	const planningProject = useSelector((state: RootState) => state.profileProject);
	const [routineSelected] = useState<IRoutine>({
		_id: '',
		description: '',
	});
	return (
		<>
			{planningProject.step === 0 && <PlanningMain />}
			{planningProject.step === 1 && <EditList />}
			{planningProject.step === 2 && <BoardComponent />}
			{planningProject.step === 3 && (
				<SupervisionHistoryDetail routine={routineSelected} />
			)}
			{planningProject.step === 4 && <RoutineDetailEvidence />}
			{planningProject.step === 5 && <SupervisionViewActions />}
			{planningProject.step === 6 && <SupervisionViewReports />}
			{planningProject.step === 7 && <SupervisionViewActivities />}
		</>
	);
};
