import { Avatar, Grid, IconButton, Paper } from '@mui/material';
import styles from './styles/header-user-styles.module.css';
import { Delete, Edit, Save } from '@mui/icons-material';
import { Box } from '@mui/system';
import { IUser } from '../../../interfaces/user.interface';
import { getLetter } from '../utils/getLetterAvatar';
import InputSelect from '../../../components/select-input.component';
import { getRoleName, isAdmin } from '../../../utils/permissions.utils';
import Modal from '../../../components/modal.component';
import { useHeaderUser } from './hooks/useHeaderUser';

interface IHeader {
	user: IUser;
}

export const HeaderUser = ({ user }: IHeader) => {
	const {
		isEditing,
		matches,
		modal,
		active,
		role,
		handleEdit,
		setModal,
		setActive,
		deleteUser,
	} = useHeaderUser({ user });

	return (
		<Paper elevation={0} style={{ marginBottom: '20px' }}>
			<Modal
				open={modal.open}
				title={'Eliminar usuario'}
				cancelLabel="Cancelar"
				confirmLabel="Continuar"
				styleButtonConfirm={{ color: '#0080F9' }}
				width="sm"
				onClose={modal.onClose}
				onConfirm={modal.callback}
				modal={modal}
				setModal={setModal}
			>
				¿Estás seguro que deseas eliminar el usuario? Esta acción será permanente.
			</Modal>
			<Box padding={matches ? 3 : 6}>
				<Grid container spacing={8} justifyContent="center" rowSpacing={3}>
					<Grid item sm={12} md="auto">
						<Avatar variant="rounded" className={styles.avatar}>
							{getLetter(user)}
						</Avatar>
					</Grid>
					<Grid item sm={12} md>
						<Grid
							container
							justifyContent="space-between"
							alignItems="center"
							rowSpacing={2}
						>
							<Grid item>
								<h3 style={{ margin: 0 }}>
									{user.firstName + ' ' + user.lastName}
								</h3>
							</Grid>
							<Grid item>
								{isAdmin(role) && (
									<>
										<IconButton
											onClick={() => handleEdit()}
											aria-label="more"
											size="medium"
											className={styles.moreIcon}
										>
											{isEditing ? <Save /> : <Edit />}
										</IconButton>
										<IconButton
											onClick={() =>
												setModal({
													...modal,
													open: true,
													callback: () => deleteUser(),
												})
											}
											aria-label="more"
											size="medium"
											className={styles.deleteIcon}
										>
											<Delete />
										</IconButton>
									</>
								)}
							</Grid>
							<Grid item md={12}>
								<Grid
									container
									direction="row"
									columnSpacing={8}
									rowSpacing={2}
								>
									<Grid item>
										<span className={styles.textLabel}>
											No. trabajador
										</span>
										<br />
										<span className={styles.textValue}>
											{user.employeeNumber}
										</span>
									</Grid>
									<Grid item>
										<span className={styles.textLabel}>Mail</span>
										<br />
										<span className={styles.textValue}>
											{user.email}
										</span>
									</Grid>
									<Grid item>
										<span className={styles.textLabel}>Rol</span>
										<br />
										<span className={styles.textValue}>
											{getRoleName(user.role)}
										</span>
									</Grid>
									<Grid item>
										<span className={styles.textLabel}>Estatus</span>
										<br />
										{!isEditing ? (
											<div className={styles.containerStatus}>
												<div
													className={styles.circleStyle}
													style={{
														backgroundColor:
															user.status === 'ACTIVE'
																? '#049D10'
																: 'orange',
													}}
												/>
												<span className={styles.textValue}>
													{user.status === 'ACTIVE'
														? 'Activo'
														: 'Inactivo'}
												</span>
											</div>
										) : (
											<InputSelect
												optionsMenu={[
													{
														id: 0,
														label: 'Inactivo',
														value: 0,
													},
													{
														id: 1,
														label: 'Activo',
														value: 1,
													},
												]}
												onChange={(e) =>
													setActive(e.target.value || 0)
												}
												value={active}
												placeholder="Selecciona"
												width="350px"
											/>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
