/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { uniqueId } from '../../../utils/uniqueId';
import { setAlert } from '../../../store/modules/snackbar';
import { isEmpty } from '../../../utils/validators';
import { IStepOneProp } from '../Index';
import { RootState } from '../../../store';

const useGetEF = () => {
	const dispatch = useDispatch<any>();
	const { execute, status, value } = useApiRequest<
		any,
		IResponsePayload<IStepTwoModel>
	>({
		path: URLS.PROJECT.FUNCTIONAL,
		method: 'GET',
	});

	const { functionalBase } = useSelector((state: RootState) => state.projectBase);

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	const handleTransformEF = (
		dataValue: IStepTwoModel[] | undefined,
		EF_1?: IStepOneProp
	): IStepTwoModel[] => {
		let dataFlag: IStepTwoModel[] = [];
		if (dataValue && dataValue.length > 0) {
			dataFlag = dataValue.map((ele) => ({
				...ele,
				id: uniqueId(),
				_id: ele._id,
				isComplete: ele.columns.every((i) => !isEmpty(i)),
			}));
		} else {
			if (EF_1) {
				const { attr, data } = EF_1;
				let attributes;
				let columns;
				if (functionalBase?.attributes) {
					attributes = functionalBase.attributes;
					columns = functionalBase.attributes.map((e) => {
						return '';
					});
				} else {
					attributes = [data[1].value, ...attr.map((i) => i.label)];
					columns = ['', ...attr.map((_) => '')];
				}
				//console.log(EF_1);
				dataFlag = [
					{
						id: uniqueId(),
						attributes: attributes,
						columns: columns,
						isComplete: false,
						name: data[0].value,
						mainAttribute: data[1].value,
					},
				];
			}
		}
		return dataFlag;
	};
	return { execute, status, value, handleTransformEF };
};

export default useGetEF;
