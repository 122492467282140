// Dependencies
import React from 'react';
import { Alert, AlertColor } from '@mui/material';

// Resources
import styles from './styles/alert-styles.component.module.css';
import theme from '../config/theme';

interface ICustomAlert {
	show: boolean;
	severity: AlertColor;
	message?: string | React.ReactChild;
	onClose?: () => void;
	children?: JSX.Element;
	id?: string;
}

const CustomAlert = (props: ICustomAlert) => {
	const { show, severity, message, onClose, children, id } = props;

	const getColorBackground = (): string => {
		let color = '';
		switch (severity) {
			case 'warning':
				color = theme.palette.warning.light;
				break;
			case 'info':
				color = theme.palette.info.light;
				break;
			case 'error':
				color = theme.palette.error.light;
				break;
			case 'success':
				color = theme.palette.success.light;
				break;
			default:
				break;
		}
		return color;
	};

	const getColorLabel = (): string => {
		let color = '';
		switch (severity) {
			case 'warning':
				color = theme.palette.warning.dark;
				break;
			case 'info':
				color = theme.palette.info.dark;
				break;
			case 'error':
				color = theme.palette.error.dark;
				break;
			case 'success':
				color = theme.palette.success.dark;
				break;
			default:
				break;
		}
		return color;
	};
	return (
		<Alert
			id={id}
			style={{
				display: show ? 'flex' : 'none',
				backgroundColor: getColorBackground(),
			}}
			className={styles.alert}
			onClose={severity === 'warning' ? onClose : undefined}
			severity={severity}
			sx={{ width: '100%', color: getColorLabel() }}
		>
			{children !== undefined && children}
			{children === undefined && <span>{message ? message : ''}</span>}
		</Alert>
	);
};

export default CustomAlert;
