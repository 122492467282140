/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../config/theme';
import { getInputs } from '../../../../utils/get-inputs';
import { useUpdateActivity } from './useUpdateActivity';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';

interface IUseFormActivitites {
	setCurrentActivity: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	currentActivity: IAdaptedValues;
}

export const useEditActivity = ({
	currentActivity,
	setCurrentActivity,
}: IUseFormActivitites) => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [inputs, setInputs] = useState(getInputs(currentActivity));

	const { handleUpdateActivity } = useUpdateActivity({
		setCurrentActivity,
	});

	const matches = useMediaQuery(theme.breakpoints.down('md'));
	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	const onChangeValue = (name: string, value: string) => {
		setInputs(
			inputs.map((element: any) => {
				if (name === element.id)
					return {
						...element,
						value: value,
					};
				return element;
			})
		);
	};

	const updateActivity = () => {
		const values = inputs.map((element: any) => {
			return element.value;
		});
		handleUpdateActivity(currentActivity.id, { columns: values });
	};

	return {
		matches,
		inputs,
		navigate,
		onChangeValue,
		updateActivity,
	};
};
