import { NOT_STARTED } from './constants/messages';
import { useNotStarted } from './hooks/useNotStarted';
import { MessagesView } from '../../components/messages-view.component';

export const NotStarted = () => {
	const { project, navigate, executeConfirmation } = useNotStarted();

	return (
		<MessagesView
			descriptionLabel={NOT_STARTED.DESCRIPTION}
			cancelAction={() => navigate(-1)}
			continueAction={() => executeConfirmation(null, `/${project._id}`)}
		/>
	);
};
