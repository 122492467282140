/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IValuesInfo } from '../../../interfaces/edit-values.interface';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import { getAdaptedData, getSelectOptionsData } from '../../../utils/get-adapted-data';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import { RootState } from '../../../store';

interface IGetFunctional {
	functionalParent: string;
	isLvlOne: boolean;
	execute?: boolean;
	loading?: boolean;
}

export const useGetFunctional = ({
	functionalParent,
	isLvlOne,
	execute = true,
	loading = true,
}: IGetFunctional) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location?.state?.params?.idProject as string;
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const [functionalsOptions, setFunctionalsOptions] = useState<IAdaptedValues[]>([]);
	const [functionalsSelectOptions, setFunctionalsSelectOptions] = useState<
		IOptionsMenu[]
	>([]);

	const {
		execute: getFunctional,
		status: statusGetFunctional,
		value: valueGetFunctional,
	} = useApiRequest<unknown, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.FUNCTIONALS.GET_FUNCTIONAL,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetFunctional === 'success') {
			if (loading) dispatch(stopLoading());
			setFunctionalsOptions(getAdaptedData(valueGetFunctional?.data || []));
			setFunctionalsSelectOptions(
				getSelectOptionsData(valueGetFunctional?.data || [])
			);
		} else if (statusGetFunctional === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetFunctional]);

	useEffect(() => {
		if (execute) {
			if (loading) dispatch(setLoading({ loading: true }));
			if (isLvlOne) getFunctional(null, `/functional-parents/${idProject || _id}`);
			else getFunctional(null, `/functionals/${functionalParent}`);
		}
	}, []);

	const getFunctionalData = (functionalParent: string) => {
		if (loading) dispatch(setLoading({ loading: true }));
		getFunctional(null, `/functionals/${functionalParent}`);
	};

	return {
		functionalsOptions,
		functionalsSelectOptions,
		valueGetFunctional,
		setFunctionalsOptions,
		getFunctionalData,
	};
};
