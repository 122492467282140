/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAdaptedData } from '../../../../utils/get-adapted-data';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { IValuesInfo } from '../../../../interfaces/edit-values.interface';

interface IGetIndicator {
	idActivity: string;
	isGeneralIndicator: boolean;
}

export const useGetIndicators = ({ idActivity, isGeneralIndicator }: IGetIndicator) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [indicatorsOptions, setIndicatorsOptions] = useState<IAdaptedValues[]>([]);

	const {
		execute: getIndicators,
		status: statusGetIndicators,
		value: valueGetIndicators,
	} = useApiRequest<unknown, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.INDICATORS.GET_GENERAL_INDICATORS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetIndicators === 'success') {
			dispatch(stopLoading());
			setIndicatorsOptions(getAdaptedData(valueGetIndicators?.data || []));
		} else if (statusGetIndicators === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los indicadores',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetIndicators]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		if (isGeneralIndicator) getIndicators(null, `/general-indicators/${idProject}`);
		else getIndicators(null, `/specific-indicators/${idActivity}`);
	}, []);

	return { indicatorsOptions, valueGetIndicators, setIndicatorsOptions };
};
