/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { useNavigate } from 'react-router-dom';

export const useUpdateUser = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { execute: updateUser, status: statusUpdateUser } = useApiRequest<
		unknown,
		IResponsePayload<unknown>
	>({
		path: URLS.USERS.GET_USERS,
		method: 'PUT',
	});

	useEffect(() => {
		if (statusUpdateUser === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Usuario actualizado con éxito',
					severity: 'success',
				})
			);
			navigate(-1);
		} else if (statusUpdateUser === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateUser]);

	const handleUpdate = (idUser: string, newStatus: string) => {
		dispatch(setLoading({ loading: true }));
		updateUser({ status: newStatus }, `/${idUser}`);
	};

	return {
		handleUpdate,
	};
};
