/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { deviceAdapter } from '../adapters/data.adapter';
import { IDeviceRow } from '../interfaces/device.interface';

export const useDevices = () => {
	const { data, setData, pagination, rowsPerPage, filters, handleGetFiltersParams } =
		useTableCustom<Object>();
	const dispatch = useDispatch();

	const {
		execute: getDevices,
		status: statusDevices,
		value: valueDevices,
	} = useApiRequest<unknown, IResponsePayload<IDeviceRow>>({
		path: URLS.DEVICES.GET_DEVICES,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getDevices(
			null,
			`?page=${pagination}&recordsByPage=${rowsPerPage}${handleGetFiltersParams()}`
		);
	}, [pagination, rowsPerPage, filters]);

	useEffect(() => {
		if (statusDevices === 'success') {
			dispatch(stopLoading());
			setData({
				...data,
				countRows: valueDevices?.totalRecords || 0,
				rows: deviceAdapter(valueDevices?.data || []),
			});
		} else if (statusDevices === 'error') {
			dispatch(stopLoading());
		}
	}, [statusDevices]);

	return {
		data,
		setData,
	};
};
