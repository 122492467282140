/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { ICreationPage } from '../../interfaces/creation-page-interfaces';
import CreationPage from '../../components/creation-page.component';
import { estadoCreacion } from '../../constants/estado-creacion';
import StepOne from './components/step-one.component';
import StepTwo from './components/step-two.component';
import { IStepTwoModel } from '../../interfaces/stepTwo.interface';
import { IVerticalStepperAttributes } from '../../interfaces/vertical.steper.attributes-fixed.interface';
//FETCH
import useGetActivity from './hooks/useGetActivity';
import useUpdateActivity from './hooks/useUpdateActivity';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/modules/loading';
import { sendDataAdapterActivities } from '../../adapters/step-two.adapter';
import { RootState } from '../../store';
import { URLS } from '../../constants/urls.constants';
import { StepOneHandler } from '../../components/step-one-fixed';
import { useNavigate } from 'react-router-dom';
import useUpdateProject from '../../hooks/useUpdateProject';
const Index = () => {
	const ACTRef = useRef<StepOneHandler>(null);
	const [isShow, setIsShow] = useState(false);
	const {
		step: stepProject,
		_id: _projectID,
		activityBase,
	} = useSelector((state: RootState) => state.projectBase);
	const dispatch = useDispatch();
	const [activities, setActivities] = useState<IStepTwoModel[]>([]);
	const [defaultActivity, setDefaultActivity] = useState<
		IVerticalStepperAttributes[] | null
	>(null);
	const [showCsv, setShowCsv] = useState(false);
	const [isSave, setIsSave] = useState<boolean>(false);
	const [isBaseSetted, setIsBaseSetted] = useState<boolean>(false);
	const navigate = useNavigate();

	const { handleUpdateProject } = useUpdateProject();

	const {
		execute: getActivities,
		isNext1Disabled,
		setIsNext1Disabled,
		value: valueResult,
	} = useGetActivity({ setActivities, setIsShow });
	const { execute: updateActivities } = useUpdateActivity({
		setActivities,
		isSave,
	});

	const handleUpdateAct = async (data: IStepTwoModel[], p_isSave: boolean) => {
		await dispatch(setLoading({ loading: true }));
		await setIsSave(p_isSave);
		const _adapted = sendDataAdapterActivities(data, _projectID || '');
		updateActivities(_adapted);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getActivities(null, `?project=${_projectID}`);
	}, []);

	useEffect(() => {
		if (!valueResult || valueResult.data.length < 1) {
			if (activityBase) {
				setIsShow(true);
				setIsNext1Disabled(true);
				ACTRef.current?.disableAll();
				setIsBaseSetted(true);
				setActivities([
					{
						id: '',
						isComplete: false,
						name: activityBase.name,
						mainAttribute: activityBase.mainAttribute,
						attributes: activityBase.attributes,
						columns: activityBase.attributes.map((e) => {
							return '';
						}),
					},
				]);
				setDefaultActivity([
					{
						index: -1,
						name: 'Nombre',
						placeholder: '',
						value: activityBase.name,
						isComplete: true,
					},
					...activityBase.attributes.map((element, i) => {
						return {
							index: i,
							name:
								i === 0 ? 'Atributo principal' : 'Atributos secundarios',
							placeholder: '',
							value: element,
							isComplete: true,
						};
					}),
				]);
			}
			return;
		}
		setIsBaseSetted(true);
	}, [valueResult]);

	useEffect(() => {
		if (stepProject === '3.0.0') {
			navigate('/nuevo-proyecto/criterios');
		}
	}, [stepProject]);

	const Actividad: ICreationPage = {
		header: {
			show: !showCsv,
			label: 'Estado de creación',
			stepper: {
				activeStep: 1,
				items: estadoCreacion,
				completed: {},
			},
		},
		stepOne: {
			title: '1. Nombra tu actividad, así como su atributo principal y los secundarios',
			show: !showCsv,
			alertLabel:
				'Designa el nombre genérico de los elementos a considerar en la supervisión según lo indique el contrato',
			Children: (
				<StepOne
					refComponent={ACTRef}
					onContinue={(data) => {
						setActivities(data);
						setIsShow(true);
						handleUpdateProject(
							{
								activityBase: {
									name: data[0].name,
									mainAttribute: data[0].mainAttribute,
									attributes: data[0].attributes,
								},
							},
							_projectID || ''
						);
					}}
					defaultActividad={defaultActivity}
					disableNext={isNext1Disabled}
				/>
			),
		},
		stepTwo: {
			title: showCsv ? <></> : '2. Enlista y nombra todas tus actividades',
			show: isShow,
			Children: (
				<StepTwo
					data={activities}
					setData={setActivities}
					labelItem={
						'Designa el nombre específico de cada actividad a supervisar. Ejemplo: S-01, S02, etc.'
					}
					onContinue={(data) => {
						handleUpdateAct(data, false);
					}}
					onCVSUpload={() => {
						setShowCsv(true);
					}}
					onSave={(data) => {
						handleUpdateAct(data, true);
					}}
					url={URLS.PROJECT.ACTIVITY}
					isDone={false}
					setShowAltaCsv={setShowCsv}
					showAltaCsv={showCsv}
				/>
			),
			onExit: () => {
				setIsShow(false);
				setIsNext1Disabled(null);
				ACTRef.current?.unDone();
			},
			showExitButon: !isBaseSetted && !showCsv,
		},
	};

	return (
		<div>
			<CreationPage {...Actividad} />
		</div>
	);
};

export default Index;
