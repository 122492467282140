import { IColumns } from '../../../interfaces/table.insterface';

export const columnsNoRecurringTasks: IColumns[] = [
	{
		text: '',
		nameID: 'descriptionCustom',
		width: '800px',
	},
	{
		text: '',
		nameID: 'acciones',
	},
];

export const columnsNoRecurringTasksBase: IColumns[] = [
	{
		text: 'Espacio funcional',
		nameID: 'functionalDescription',
	},
	{
		text: 'Servicio',
		nameID: 'activityDescription',
	},
	{
		text: 'Criterio',
		nameID: 'indicatorDescription',
	},
	{
		text: 'Supervisor',
		nameID: 'supervisorDescription',
	},
	{
		text: '',
		nameID: 'acciones',
	},
];

export const columnsNextTasks: IColumns[] = [
	{
		text: 'Supervisión',
		nameID: 'descriptionCustom',
		width: '400px',
	},
	{
		text: 'Asignado a',
		nameID: 'assignedTo',
		width: '400px',
	},
	{
		text: '',
		nameID: 'acciones',
	},
];
