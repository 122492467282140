import { IOptionsMenu } from '../../../../interfaces/select.interface';
import { ISupervisionFunctionalsData } from '../../interfaces/supervision-functionals.interface';

export const getFunctionalsAdapter = (
	data: ISupervisionFunctionalsData[]
): IOptionsMenu[] => {
	const newOptions: IOptionsMenu[] = data?.map((element) => {
		return {
			id: element.functional,
			label: element.functionalDescription,
			value: element.functional,
			status: element.status,
		};
	});
	return newOptions;
};

export const getActivitiesAdapter = (
	data: ISupervisionFunctionalsData[]
): IOptionsMenu[] => {
	const newOptions: IOptionsMenu[] = data?.map((element) => {
		return {
			id: element.activity,
			label: element.activityDescription,
			value: element.activity,
			status: element.status,
		};
	});
	return newOptions;
};
