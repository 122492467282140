/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { URLS } from '../../../../../constants/urls.constants';
import { stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { areaSelectAdapter } from '../adapters/area-select-adapter';

export function useSuspensionsArea(handleView?: any) {
	const { data: dataSuspensionsArea, setData: setDataSuspensionsArea } =
		useTableCustom<Object>();
	const dispatch = useDispatch();

	const {
		execute: executeSuspensionsArea,
		status: statusSuspensionsArea,
		value: valueSuspensionsArea,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.SUPERVISION_HISTORY.HISTORY_FUNCTIONALS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusSuspensionsArea === 'success') {
			dispatch(stopLoading());
			setDataSuspensionsArea({
				...dataSuspensionsArea,
				countRows: valueSuspensionsArea?.totalRecords || 0,
				rows: areaSelectAdapter(valueSuspensionsArea?.data || [], handleView),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusSuspensionsArea === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusSuspensionsArea]);

	return {
		dataSuspensionsArea,
		setDataSuspensionsArea,
		executeSuspensionsArea,
	};
}
