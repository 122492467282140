import AlertMUI from '@mui/material/Alert';
import { styled, SxProps, Theme } from '@mui/material/styles';

interface IProps {
	severity: 'error' | 'info' | 'success' | 'warning';
	as?: React.ElementType;
	children?: any;
	sx?: SxProps<Theme>;
}

const CustomAlert = styled((props: IProps) => <AlertMUI {...props} />)(({ theme }) => ({
	color: '#6593A5',
	background: '#EDF9FD',
	[theme.breakpoints.down('md')]: {
		width: '100%',
	},
	[theme.breakpoints.up('md')]: {
		width: '100%',
	},
	borderRadius: '8px',
	svg: {
		color: '#85D6F8',
	},
}));

const Alert = (props: IProps) => {
	const { severity, as, sx, children } = props;
	return (
		<CustomAlert severity={severity} as={as} sx={{ marginTop: '1rem', ...sx }}>
			{children}
		</CustomAlert>
	);
};

export default Alert;
