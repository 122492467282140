/* eslint-disable react-hooks/exhaustive-deps */

import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { getTableColumns } from '../../../../utils/tableInfo';
import { useTableCustom } from '../../../../hooks/useTableCustom';
import { IColumns } from '../../../../interfaces/table.insterface';
import { stopLoading } from '../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../hooks/useApiRequest';
import { supervisionDetailAdapter } from '../adapters/supervision-detail.adapter';
import { ISupervisionRoutineDetail } from '../interfaces/get-supervision-detail.interface';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';

export function useGetSupervisionDetail() {
	const dispatch = useDispatch();
	const { data, setData, rowsPerPage } = useTableCustom<Object>();
	const [dinamicColumns, setDinamicColumns] = useState<IColumns[]>([]);
	const { mainSelect, undoState, setUnitInfo } = useContext(SupervisionProjectContext);

	const {
		execute: getSupervisionDetail,
		status: statusSupervisionDetail,
		value: valueSupervisionDetail,
	} = useApiRequest<unknown, IResponsePayload<ISupervisionRoutineDetail>>({
		path: URLS.SUPERVISION_EXECUTION.GET_SUPERVISION_ROUTINES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusSupervisionDetail === 'success') {
			dispatch(stopLoading());
			getCustomColumns();
			setData({
				...data,
				countRows: valueSupervisionDetail?.totalRecords || 0,
				rows: supervisionDetailAdapter(
					mainSelect.optionLabel,
					valueSupervisionDetail?.detail
				),
				rowsPerPage,
				sort: {
					col: '',
					order: 'ASC',
				},
			});
			setUnitInfo(
				`${
					valueSupervisionDetail?.detail?.functionalParent?.columns[0] || '-'
				} - ${valueSupervisionDetail?.detail?.functional?.columns[0] || '-'} - ${
					valueSupervisionDetail?.detail?.activity?.columns[0] || '-'
				} - ${valueSupervisionDetail?.detail?.indicator?.columns[0] || '-'}`
			);
		} else if (statusSupervisionDetail === 'error') {
			undoState();
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusSupervisionDetail]);

	const getCustomColumns = () => {
		mainSelect.optionLabel === 'servicio'
			? setDinamicColumns(
					getTableColumns(
						[
							'Espacio nivel 1',
							...(valueSupervisionDetail?.detail?.functionalParent?.attributes?.slice(
								1
							) || []),
							'Espacio nivel 2',
							...(valueSupervisionDetail?.detail?.functional?.attributes?.slice(
								1
							) || []),
							'Indicador',
							...(valueSupervisionDetail?.detail?.indicator?.attributes?.slice(
								1
							) || []),
						],
						undefined,
						[
							...(valueSupervisionDetail?.detail?.functionalParent
								?.columns || []),
							...(valueSupervisionDetail?.detail?.functional?.columns ||
								[]),
							...(valueSupervisionDetail?.detail?.indicator?.columns || []),
						]
					)
			  )
			: setDinamicColumns(
					getTableColumns(
						[
							'Espacio nivel 2',
							...(valueSupervisionDetail?.detail?.functional?.attributes?.slice(
								1
							) || []),
							'Servicio',
							...(valueSupervisionDetail?.detail?.activity?.attributes?.slice(
								1
							) || []),
							'Indicador',
							...(valueSupervisionDetail?.detail?.indicator?.attributes?.slice(
								1
							) || []),
						],
						undefined,
						[
							...(valueSupervisionDetail?.detail?.functional?.columns ||
								[]),
							...(valueSupervisionDetail?.detail?.activity?.columns || []),
							...(valueSupervisionDetail?.detail?.indicator?.columns || []),
						]
					)
			  );
	};

	return {
		data,
		valueSupervisionDetail,
		dinamicColumns,
		getSupervisionDetail,
		setData,
	};
}
