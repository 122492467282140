//Resources
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { useNewProject } from '../new-project/hooks/useNewProject';
import { DragNDrop } from '../../components/drag-drop.component';
import { Button } from '../../components/main-button.component';
import { GoBack } from '../../components/go-back-component';
import { setAlert } from '../../store/modules/snackbar';
import { fileToBase64 } from '../../utils/fileToBase64';
import { setState } from '../../store/modules/steps';
import { bytesToMB } from '../../utils/converts';
import { useDispatch } from 'react-redux';
import { DOCS_EXTENSIONS } from '../../constants/files-type';
import { useContext } from 'react';
import { ProfileProjectContext } from '../profile-project/context/useProfileProjectContext';

interface IDragDocument {
	withImages?: boolean;
}

function DragDocument({ withImages = false }: IDragDocument) {
	const { data, setData } = useNewProject();
	const { data: profileData, setData: setDataProfile } =
		useContext(ProfileProjectContext);
	const dispatch = useDispatch();

	const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const name = file.name;
			const extension = file ? fileList[0].type : '';
			if (
				(withImages ? profileData : data).files.find((doc) => doc.name === name)
			) {
				dispatch(
					setAlert({
						show: true,
						message: 'El archivo ya se ha seleccionado anteriormente.',
						severity: 'error',
					})
				);
			} else {
				const size = file.size;
				const reader = new FileReader();
				reader.onload = (e) => {
					const file = e.target?.result;
					if (file) {
						const base64String = fileToBase64(file);
						(withImages ? setDataProfile : setData)({
							...(withImages ? profileData : data),
							files: [
								...(withImages ? profileData : data).files,
								{
									projectId:
										(withImages ? profileData : data)._id || '',
									type: DOCS_EXTENSIONS[extension],
									url: base64String,
									name: `${name}`,
									size: bytesToMB(size),
									modifiedDate: new Date().toISOString(),
									description: name,
								},
							],
						});
						dispatch(setState({ state: 3 }));
					}
				};
				reader.readAsDataURL(file);
			}
		}
	};

	return (
		<>
			<GoBack
				action={() => {
					dispatch(setState({ state: 0 }));
				}}
			/>
			<Paper>
				<Box padding={3}>
					<Grid
						container
						component="section"
						display="flex"
						alignContent="space-between"
						alignItems="center"
						justifyContent="center"
						gap={3}
					>
						<Grid item xs={12}>
							<span style={{ fontSize: '20px', fontWeight: 'bold' }}>
								Subir documento
							</span>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={11} display="flex" justifyContent="center">
							<Typography variant="subtitle1" sx={{ fontSize: '15px' }}>
								Carga el o los archivos relacionados con la documentación
								general del proyecto; esto es, el contrato y los anexos
								correspondientes.
							</Typography>
						</Grid>
						<DragNDrop
							element={{
								accept: withImages
									? 'application/pdf,image/jpeg'
									: 'application/pdf',
							}}
							onChange={handleFile}
						/>
						<Grid item xs={11} display="flex" justifyContent="flex-end">
							<Button
								label={'Continuar'}
								variant="solid"
								style={{
									width: '160px',
									backgroundColor: '#0080F9',
									padding: '20px',
								}}
								onClick={() => dispatch(setState({ state: 2 }))}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
}
export default DragDocument;
