import {
	INonWorkingDays,
	SpecificDays,
} from '../interfaces/get-non-working-days.interface';

export const specificDaysAdapter = (data?: INonWorkingDays) => {
	if (!data) return [];
	const newSpecifcDays = data.specificDays.map((day) => {
		const isObject = typeof day === 'object' && day !== null;
		return {
			_id: data._id,
			label: 'Dia no laborable',
			isComplete: true,
			rows: [
				{
					key: 'day',
					value: isObject
						? day.day.replace(/^0+/, '')
						: new Date(day).getUTCDate(),
				},
				{
					key: 'month',
					value: isObject
						? day.month.replace(/^0+/, '')
						: new Date(day).getUTCMonth() + 1,
				},
			],
		};
	});
	return newSpecifcDays as unknown as SpecificDays[];
};
