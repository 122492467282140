/* eslint-disable react-hooks/exhaustive-deps */

import { useGetPersonalByRole } from './useGetPersonalByRole';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { useUpdateRoles } from './useUpdateRole';

interface IUseEditBaseRole {
	role: IAdaptedValues;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentRole: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	setCurrentBase: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
}

export const useEditBaseRole = ({
	role,
	setStep,
	setCurrentRole,
	setCurrentBase,
}: IUseEditBaseRole) => {
	useGetPersonalByRole({ role, setStep });

	const { handleUpdateRoles } = useUpdateRoles({
		currentRole: role,
		setCurrentRole: setCurrentRole,
		setCurrenBase: setCurrentBase,
	});

	const saveChanges = (body: any) => {
		handleUpdateRoles(
			{
				attributes: [
					...((role?.attributes || []).slice(0, 2) || []),
					...body.attributes.slice(1),
				],
			},
			0,
			role.id
		);
	};

	return { saveChanges };
};
