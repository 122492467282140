/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { ITask } from '../../../interfaces/project.interface';
import { stopLoading } from '../../../store/modules/loading';
import { resetState } from '../../../store/modules/steps';
import { setAlert } from '../../../store/modules/snackbar';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { routineSupervisionAdapter } from '../adapters/routineSupervisionAdapter.adapter';
import { useNavigate, useLocation } from 'react-router-dom';
import { setState } from './../../../store/modules/steps';
import { setLoading } from './../../../store/modules/loading';
import { RootState } from '../../../store';
import { setPage } from '../../../store/modules/page-table';
import { SupervisionHistoryContext } from '../contexts/useSupervisionHistoryContext';

interface IUseRotuineSupervision {
	setCanBeExecuted: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useRoutineSupervision({ setCanBeExecuted }: IUseRotuineSupervision) {
	const { page, rowsPerPage } = useSelector((state: RootState) => state.pageTable);
	const { _id } = useSelector((state: RootState) => state.projectBase);
	const { dataSelect, setDataSelect } = useContext(SupervisionHistoryContext);

	const {
		data: dataTable,
		setData: setDataTable,
		handleGetFiltersParams,
	} = useTableCustom<Object>({
		countRows: 0,
		rows: [],
		sort: { col: '', order: '' },
		pagination: page,
		rowsSelect: [],
		rowsPerPage: rowsPerPage,
		filters: dataSelect,
		advancedFilter: '',
		activeAdvanceFilter: false,
		filterCustom: {},
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location?.state?.params;

	const {
		execute: executeGetSupervisionsComplete,
		status: statusGetSupervisionsComplete,
		value: valueSupervisionsComplete,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.SUPERVISION_HISTORY.HISTORY_ROUTINES_COMPLETE,
		method: 'GET',
	});

	const {
		execute: executeGetSupervisionsPending,
		status: statusGetSupervisionsPending,
		value: valueSupervisionsPending,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.SUPERVISION_HISTORY.HISTORY_ROUTINES_PENDING,
		method: 'GET',
	});

	const viewDetails = (routine: ITask) => {
		dispatch(setLoading({ loading: true }));
		dispatch(
			setPage({ page: dataTable.pagination, rowsPerPage: dataTable.rowsPerPage })
		);
		dispatch(setState({ state: 2 }));
		setCanBeExecuted(routine.canBeExecuted);
		navigate('/perfil-proyecto/historial-supervision', {
			state: {
				params: {
					project: state?.project || _id,
					routine: routine.routine,
				},
			},
		});
	};

	useEffect(() => {
		if (statusGetSupervisionsComplete === 'success') {
			dispatch(stopLoading());
			setDataTable({
				...dataTable,
				countRows: valueSupervisionsComplete?.totalRecords || 0,
				rows: routineSupervisionAdapter(
					valueSupervisionsComplete?.data || [],
					viewDetails
				),
				sort: {
					col: '',
					order: 'ASC',
				},
				filters: dataSelect,
			});
			dispatch(resetState());
		} else if (statusGetSupervisionsComplete === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueSupervisionsComplete?.message
						? valueSupervisionsComplete?.message
						: 'Ocurrió un error al obtener los datos.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetSupervisionsComplete]);

	useEffect(() => {
		if (statusGetSupervisionsPending === 'success') {
			dispatch(stopLoading());
			setDataTable({
				...dataTable,
				countRows: valueSupervisionsPending?.totalRecords || 0,
				rows: routineSupervisionAdapter(
					valueSupervisionsPending?.data || [],
					viewDetails
				),
				sort: {
					col: '',
					order: 'ASC',
				},
				filters: dataSelect,
			});
			dispatch(resetState());
		} else if (statusGetSupervisionsPending === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueSupervisionsPending?.message
						? valueSupervisionsPending?.message
						: 'Ocurrió un error al obtener los datos.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetSupervisionsPending]);

	return {
		dataTable,
		setDataTable,
		executeGetSupervisionsComplete,
		executeGetSupervisionsPending,
		dataSelect,
		setDataSelect,
		handleGetFiltersParams,
	};
}
