import styles from './styles/document-upload-successfull.module.css';
import { Button } from '../../components/main-button.component';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid, Typography, Box, Paper } from '@mui/material';
import { setState } from '../../store/modules/steps';
import { useDispatch } from 'react-redux';

function DocumentUploadSuccesfull() {
	const dispatch = useDispatch();

	return (
		<Paper>
			<Box padding={3}>
				<Grid container component="section" justifyContent="center" marginY={10}>
					<Grid
						item
						display="flex"
						alignItems="center"
						flexDirection="column"
						justifyContent="center"
					>
						<div className={styles.iconSuccess}>
							<CheckCircleIcon />
							<Typography variant="h5">
								El archivo se ha cargado correctamente.
							</Typography>
						</div>
						<div className={styles.buttonSuccess}>
							<Typography variant="body1">
								<span onClick={() => dispatch(setState({ state: 1 }))}>
									Subir otro archivo.
								</span>
							</Typography>
							<Button
								label={'Continuar'}
								variant="solid"
								style={{
									width: '160px',
									backgroundColor: '#0080F9',
									padding: '20px',
								}}
								onClick={() => dispatch(setState({ state: 2 }))}
							/>
						</div>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
}

export default DocumentUploadSuccesfull;
