import { Box, Grid, Paper, Radio } from '@mui/material';
import { useSelectService } from './hooks/useSelectService';
import { Button } from '../../../components/main-button.component';
import InputSelect from '../../../components/select-input.component';
import { useStartSupervisionDetail } from './hooks/useStartSupervisionDetail';
import Modal from '../../../components/modal.component';

export const SelectService = () => {
	const { user, mainSelect, disabledButton, options, handleChange, getStartTiming } =
		useSelectService();
	const { modal, openModal, setModal } = useStartSupervisionDetail();

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						<h2 style={{ margin: 0 }}>
							Hola {`${user.firstName} ${user.lastName}`}
						</h2>
					</Grid>
					<Grid item sm={12}>
						<h4 style={{ margin: 0 }}>
							A continuación elige el enfoque de supervisión, por servicio o
							por espacio
						</h4>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<Radio
									checked={mainSelect.optionLabel === 'servicio'}
									onChange={handleChange}
									value="servicio"
									name="optionLabel"
									inputProps={{ 'aria-label': 'servicio' }}
								/>
								Elige el servicio
							</Grid>
							<Grid item xs>
								<InputSelect
									value={
										mainSelect.optionLabel === 'servicio'
											? mainSelect.valueLabel
											: ''
									}
									optionsMenu={options}
									onChange={(e) => handleChange(e)}
									isDisabled={mainSelect.optionLabel !== 'servicio'}
									withStatus
									placeholder="Selecciona"
									id="select-activity"
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<Radio
									checked={mainSelect.optionLabel === 'espacio'}
									onChange={handleChange}
									value="espacio"
									name="optionLabel"
									inputProps={{ 'aria-label': 'espacio' }}
								/>
								Elige el espacio
							</Grid>
							<Grid item xs>
								<InputSelect
									value={
										mainSelect.optionLabel === 'espacio'
											? mainSelect.valueLabel
											: ''
									}
									optionsMenu={options}
									onChange={(e) => handleChange(e)}
									isDisabled={mainSelect.optionLabel !== 'espacio'}
									withStatus
									placeholder="Selecciona"
									id="select-functional"
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Button
									label="Continuar"
									onClick={() => openModal(getStartTiming())}
									variant="solid"
									style={{
										width: '100%',
										backgroundColor: '#0080F9',
										padding: '20px',
									}}
									disabled={disabledButton}
								/>
								{modal.open && (
									<Modal
										open={modal.open}
										title={modal.title}
										hideConfirm={modal.hideConfirm}
										cancelLabel="Cerrar"
										confirmLabel={modal.labelButton}
										styleButtonConfirm={{ color: '#0080F9' }}
										width="sm"
										onClose={modal.onClose}
										onConfirm={modal.callback}
										modal={modal}
										setModal={setModal}
									>
										{modal.body}
									</Modal>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
