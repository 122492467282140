import { CalendarMonthOutlined } from '@mui/icons-material';
import {
	createTheme,
	DialogActions,
	Grid,
	MenuItem,
	TextField,
	ThemeProvider,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';

import { parseISO } from 'date-fns';
import { useState } from 'react';
import { months } from '../utils/months';
import styles from './styles/select-date.component.module.css';

interface ISelectDate {
	value: Date;
	withMinDate?: boolean;
	setValueDate: React.Dispatch<React.SetStateAction<Date>>;
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#0080f9',
			dark: '#0080f9',
		},
	},
});

export const SelectDate = ({ value, withMinDate, setValueDate }: ISelectDate) => {
	const [open, setOpen] = useState(false);

	const getLabelDate = (): string => {
		return `${months[value.getMonth()]} ${value.getDate()}, ${value.getFullYear()}`;
	};
	const actions = ['cancel', 'accept'];
	const menuItems = actions?.map((actionType) => {
		switch (actionType) {
			case 'cancel':
				return (
					<MenuItem
						onClick={() => setOpen(false)}
						style={{ color: 'var(--color-highlight-blue)' }}
						key={actionType}
					>
						Cancelar
					</MenuItem>
				);
			case 'accept':
				return (
					<MenuItem
						onClick={() => setOpen(false)}
						style={{ color: 'var(--color-highlight-blue)' }}
						key={actionType}
					>
						Aceptar
					</MenuItem>
				);
			default:
				return null;
		}
	});

	const getButtons = () => {
		return <DialogActions>{menuItems}</DialogActions>;
	};

	return (
		<Grid
			container
			direction="column"
			style={{
				boxShadow: '9px 10px 29px -13px rgba(0, 0, 0, 0.37)',
			}}
		>
			<Grid
				item
				padding={3}
				style={{ backgroundColor: 'var(--color-highlight-blue)', color: 'white' }}
			>
				<Grid container rowSpacing={2} direction="column">
					<Grid item className={styles.title}>
						SELECCIONE UNA FECHA
					</Grid>
					<Grid item className={styles.dateSelected}>
						<div
							className={styles.dateSelectedContainer}
							onClick={() => setOpen(true)}
						>
							<span>{getLabelDate()}</span>
							<CalendarMonthOutlined className={styles.icon} />
						</div>
					</Grid>
				</Grid>
			</Grid>
			<ThemeProvider theme={theme}>
				<MobileDatePicker
					onChange={(newValue: any) => {
						setValueDate(newValue);
					}}
					open={open}
					value={parseISO(value.toISOString())}
					renderInput={() => (
						<TextField fullWidth style={{ display: 'none' }} />
					)}
					inputFormat={'MM/dd/yyyy'}
					components={{
						ActionBar: getButtons,
					}}
					minDate={withMinDate ? new Date() : null}
				/>
			</ThemeProvider>
		</Grid>
	);
};
