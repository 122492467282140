import { Grid } from '@mui/material';
import styles from './styles/view-activities-styles.module.css';
import { ItemTextArea } from '../../item-text-area.component';
import { useViewActivities } from './hooks/useViewActivitites';
import { Button } from '../../../../../components/main-button.component';
import VerticalStepper from '../../../../../components/vertical-stepper-fixed.component';
import { HeaderEdit } from '../../../../../components/header-edit.component';
import CronogramaIcon from '../../../../../assets/icons/cronograma.icon';

export const ViewActivities = () => {
	const { frequenciesOptions, activitiesOptions, matches, handleFrequency, navigate } =
		useViewActivities();

	return (
		<Grid container spacing={3} justifyContent={matches ? 'center' : 'flex-end'}>
			<HeaderEdit
				section="Cronograma"
				title="Selecciona la frecuencia de tus servicios"
				alertText="A continuación podrás editar la frecuencia de las actividades."
				icon={<CronogramaIcon size="23px" />}
			/>
			<Grid item xs={12}>
				<VerticalStepper
					gridKey="criterios-table"
					rootStyle={{ overflowX: 'scroll', width: '100%' }}
					sxContainer={styles.containerVerticalStepper}
					sxElement={styles.verticalStepperSxElement}
					items={activitiesOptions}
					renderItem={(item, column) => (
						<ItemTextArea
							item={item}
							column={column}
							freqOptions={frequenciesOptions}
							handleFrecuencia={handleFrequency}
						/>
					)}
				/>
			</Grid>
			<Grid item>
				<Button
					label="Regresar"
					onClick={() => navigate(-1)}
					variant="outlined"
					style={{
						backgroundColor: ' #0080F9',
						width: '200px',
						color: 'white',
					}}
				/>
			</Grid>
		</Grid>
	);
};
