/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { stopLoading } from '../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { IChangeStepBody, IChangeStepRes } from '../interfaces/change-step.interface';
import { CreateSupervisionContext } from '../contexts/useCreateSupervisionExtraordinaryContext';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../constants/steps-supervision-extraordinary';
import { useNavigate } from 'react-router-dom';

export function useChangeStep() {
	const {
		supervisionExtraordinaryData,
		modal,
		setSupervisionExtraordinaryData,
		setModal,
	} = useContext(CreateSupervisionContext);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		execute: changeStepSE,
		status: statusChangeStepSE,
		value: valueChangeStepSE,
	} = useApiRequest<IChangeStepBody, IResponsePayload<IChangeStepRes>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.CHANGE_STEP,
		method: 'POST',
	});

	useEffect(() => {
		if (statusChangeStepSE === 'success') {
			dispatch(stopLoading());
			setModal({ ...modal, open: false });
			if (
				valueChangeStepSE?.detail?.newStep ===
				STEPS_SUPERVISION_EXTRAORDINARY.FIN_2
			) {
				navigate(-1);
				dispatch(
					setAlert({
						show: true,
						message: 'Supervisión extraordinaria creada con éxito.',
						severity: 'success',
					})
				);
			} else {
				setSupervisionExtraordinaryData({
					...supervisionExtraordinaryData,
					step:
						valueChangeStepSE?.detail?.newStep ||
						supervisionExtraordinaryData.step,
				});
			}
		} else if (statusChangeStepSE === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al guardar la supervisión extraordinaria.',
					severity: 'error',
				})
			);
		}
	}, [statusChangeStepSE]);

	return { changeStepSE };
}
