import { IColumns } from '../interfaces/table.insterface';

export const getTableValues = (values: string[] = []) => {
	const rowFormatted = values.reduce((obj, value, index) => {
		return Object.assign(obj, { [index]: value });
	}, {});
	return rowFormatted;
};

export const getTableColumns = (
	columns: string[] = [],
	component?: JSX.Element,
	values?: string[]
): IColumns[] => {
	const newColumns = columns.map((element: string, index) => {
		return {
			text: element === 'Acciones' ? (component ? component : '') : element,
			nameID: element === 'Acciones' ? 'acciones' : index.toString(),
			width:
				!values || element === 'Acciones'
					? ''
					: getSizeColumn(values[index] ?? '0'),
		};
	});
	return newColumns;
};

export const getSizeColumn = (value: string): string => {
	const size = value.length;

	if (size < 30) {
		return '150px';
	} else if (size > 30 && size < 60) {
		return '250px';
	} else {
		return '700px';
	}
};
