/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { resetState } from '../../../../../store/modules/steps';
import { setAlert } from '../../../../../store/modules/snackbar';
import { stopLoading } from '../../../../../store/modules/loading';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import {
	ISupervisionDay,
	ISupervisionGraph,
} from '../interfaces/supervisionRoutines.interface';
import { supervisorAdapter } from '../../../adapters/project-supervisor.adapter';
import { useGetActivitiesLabels } from './useGetActivitiesLabesl';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';
import { useGetTeams } from '../../../../personal/components/create-teams/components/hooks/useGetTeams';
import { getTeamsTable } from '../adapters/get-teams-table.adapter';
import { useGetPersonalByProject } from '../../../../../hooks/usePersonalByProject';

interface ITaskComponent {
	value: number;
	activeTasks: number;
	pendingTasks: number;
}

export const useGetSupervisors = () => {
	const dispatch = useDispatch();
	const { data } = useContext(ProfileProjectContext);
	const { data: dataTable, setData: setDataTable } = useTableCustom<Object>();
	const [summary, setSummary] = useState<ITaskComponent>({
		value: 0,
		activeTasks: 0,
		pendingTasks: 0,
	});

	const { valueActivitiesLabels, executeGetActivitiesLabels } =
		useGetActivitiesLabels();

	const [todaySupervisions, setTodaySupervisions] = useState<ISupervisionDay[]>([]);
	const [chartSupervisions, setChartSupervisions] = useState<ISupervisionGraph[]>([]);

	const { usersData, getTotalUsers } = useGetPersonalByProject({});
	const { teamsData, valueGetTeams, handleGetTeams } = useGetTeams({
		execute: false,
		usersData: usersData,
	});
	const { data: teamsTable, setData: setTeamsTable } = useTableCustom<Object>();

	const {
		execute: executeGetSupervision,
		status,
		value: valueSupervision,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.SUPERVISION_EXECUTION.DASHBOARD,
		method: 'GET',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			setDataTable({
				...dataTable,
				countRows: valueSupervision?.totalRecords || 0,
				rows: supervisorAdapter(valueSupervision?.detail?.users, () => null),
				sort: {
					col: '',
					order: 'ASC',
				},
			});

			const dataSummary = valueSupervision?.detail?.activeRoutines?.summary;
			setSummary({
				...summary,
				value: dataSummary?.accomplishedPercentage,
				activeTasks: dataSummary?.totalCompleted,
				pendingTasks: dataSummary?.totalPendingSupervision,
			});

			setTodaySupervisions(
				valueSupervision?.detail?.activeRoutines?.supervisionRoutines || []
			);
			const routines =
				valueSupervision?.detail?.activeRoutines?.supervisionRoutines.map(
					(sup: any) => {
						return sup.routine;
					}
				);
			if (data._id && routines.length > 0)
				executeGetActivitiesLabels({ project: data._id, routines: routines });
			setChartSupervisions(
				valueSupervision?.detail?.activeRoutines?.supervisionRoutines || []
			);

			dispatch(resetState());
		} else if (status === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al cargar la supervisión de hoy',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [status]);

	useEffect(() => {
		getTotalUsers();
	}, []);

	useEffect(() => {
		if (usersData.length > 0) {
			handleGetTeams();
		}
	}, [usersData]);

	useEffect(() => {
		if (teamsData?.length > 0) {
			setTeamsTable({
				...teamsTable,
				countRows: valueGetTeams?.totalRecords || 0,
				rows: getTeamsTable(teamsData),
			});
		}
	}, [teamsData]);

	return {
		dataTable,
		teamsTable,
		setDataTable,
		setTeamsTable,
		summary,
		todaySupervisions,
		chartSupervisions,
		valueActivitiesLabels,
		executeGetSupervision,
	};
};
