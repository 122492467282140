/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { ISuspendUser } from '../interfaces/suspend-user.interface';
import { useNavigate } from 'react-router-dom';

export const usePermanentSuspension = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		execute: permanentSuspension,
		status: statusPermanentSuspension,
		value: valuePermanentSuspension,
	} = useApiRequest<ISuspendUser, IResponsePayload<unknown>>({
		path: URLS.USER_PROJECT_MANAGEMENT.PERMANENT_SUSPENSION,
		method: 'POST',
	});

	useEffect(() => {
		if (statusPermanentSuspension === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message:
						'Solicitud recibida con éxito. El cambio se realizará a las 12:00PM.',
					severity: 'success',
				})
			);
			navigate(-1);
		} else if (statusPermanentSuspension === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valuePermanentSuspension?.message ||
						'Ocurrió un error validar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusPermanentSuspension]);

	const handleSuspendPermanent = (body: ISuspendUser) => {
		dispatch(setLoading({ loading: true }));
		permanentSuspension(body);
	};

	return {
		handleSuspendPermanent,
	};
};
