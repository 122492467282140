/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { stopLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { INonWorkingDays } from '../interfaces/get-non-working-days.interface';

export const useUpdateNonWorkingDays = () => {
	const dispatch = useDispatch();

	const { execute: updateNonWorkingDays, status: statusUpdateNonWorkingDays } =
		useApiRequest<unknown, IResponsePayload<INonWorkingDays>>({
			path: URLS.EDIT_PROJECT.CHRONOGRAM.GET_NON_WORKING_DAYS,
			method: 'PATCH',
		});

	useEffect(() => {
		if (statusUpdateNonWorkingDays === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Información actualizada con éxito',
					severity: 'success',
				})
			);
		} else if (statusUpdateNonWorkingDays === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar las frecuencias',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateNonWorkingDays]);

	return { updateNonWorkingDays };
};
