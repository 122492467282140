import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setAlert } from '../../../../../store/modules/snackbar';
import { isEmpty } from '../../../../../utils/validators';
import { IValuesInfo } from '../../../../../interfaces/edit-values.interface';
import { IGetActivitiesUA } from '../interfaces/get-activities-UA.interface';
import { getIEsUAAdapter, getIGsUAAdapter } from '../adapters/get-indicators.adapter';
import { SplitArrayInNumbers } from '../../../../../utils/splitArray';

/* eslint-disable react-hooks/exhaustive-deps */
export const useGetIndicators = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const [idActivity, setIdActivity] = useState('');
	const [arrayIndicators, setArrayIndicators] = useState<
		{ _id: string; value: string }[]
	>([]);
	const [matrixIndicators, setMatrixIndicators] = useState<
		{ _id: string; value: string }[][]
	>([]);

	const {
		execute: getGeneralIndicators,
		status: statusGetGeneralIndicators,
		value: valueGetGeneralIndicators,
	} = useApiRequest<unknown, IResponsePayload<IGetActivitiesUA>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	const {
		execute: getSpecificIndicators,
		status: statusGetSpecificIndicators,
		value: valueGetSpecificIndicators,
	} = useApiRequest<unknown, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.INDICATORS.GET_GENERAL_INDICATORS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetGeneralIndicators === 'success') {
			getSpecificIndicators(null, `/specific-indicators/${idActivity}`);
		} else if (statusGetGeneralIndicators === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los criterios',
					severity: 'error',
				})
			);
		}
	}, [statusGetGeneralIndicators]);

	useEffect(() => {
		if (statusGetSpecificIndicators === 'success') {
			dispatch(stopLoading());
			const temporalIndicators = [
				...getIGsUAAdapter(valueGetGeneralIndicators?.data || []),
				...getIEsUAAdapter(valueGetSpecificIndicators?.data || []),
			];
			setArrayIndicators(temporalIndicators);
			setMatrixIndicators(SplitArrayInNumbers(temporalIndicators, 10));
		} else if (statusGetSpecificIndicators === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los criterios',
					severity: 'error',
				})
			);
		}
	}, [statusGetSpecificIndicators]);

	useEffect(() => {
		if (!isEmpty(idActivity)) {
			dispatch(setLoading({ loading: true }));
			getGeneralIndicators(
				null,
				`?project=${idProject}&activity=${idActivity}&type=A-IG&applies=true&populate=1`
			);
		}
	}, [idActivity]);

	const getAllIndicators = (idActivity: string) => {
		setIdActivity(idActivity);
	};

	return {
		arrayIndicators,
		matrixIndicators,
		getAllIndicators,
		setMatrixIndicators,
		setArrayIndicators,
		setIdActivity,
	};
};
