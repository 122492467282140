import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../../../components/header-edit.component';
import { useEditScheduleByLevel } from './hooks/useScheduleByLevel';
import InputSelect from '../../../../components/select-input.component';
import { Button } from '../../../../components/main-button.component';
import VerticalStepper from '../../../../components/vertical-stepper-fixed.component';
import { ItemTextArea } from '../item-text-area.component';
import styles from '../schedule-activity/components/styles/view-activities-styles.module.css';
import CronogramaIcon from '../../../../assets/icons/cronograma.icon';

export const EditScheduleByLevel = () => {
	const {
		frequenciesOptions,
		functionalsOptions,
		functionalLvl1,
		activitiesOptions,
		labelN1,
		matches,
		navigate,
		setFunctionalLvl1,
		handleFrequency,
	} = useEditScheduleByLevel();

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
					alignItems="center"
				>
					<HeaderEdit
						section="Cronograma"
						title="Selecciona la frecuencia de tus servicios"
						alertText="A continuación podrás editar la frecuencia de las actividades."
						icon={<CronogramaIcon size="23px" />}
					/>
					<Grid item xs="auto">
						<span style={{ fontWeight: 'bold' }}>
							Elige {labelN1 || 'nivel 1'}:
						</span>
					</Grid>
					<Grid item xs display={'flex'}>
						<InputSelect
							value={functionalLvl1}
							optionsMenu={functionalsOptions}
							onChange={(e) => {
								setFunctionalLvl1(e?.target?.value?.toString() || '');
							}}
							placeholder="Selecciona"
							id="select-lvl1"
						/>
					</Grid>
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							rootStyle={{ overflowX: 'scroll', width: '100%' }}
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={activitiesOptions}
							renderItem={(item, column) => (
								<ItemTextArea
									item={item}
									column={column}
									freqOptions={frequenciesOptions}
									handleFrecuencia={handleFrequency}
								/>
							)}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Regresar"
							onClick={() => navigate(-1)}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
