import CreationPage from '../../../../components/creation-page.component';
import { GoBack } from '../../../../components/go-back-component';
import { useStandars } from './hooks/useStandars';

export const Standars = () => {
	const { StandarsSteps, undoStep } = useStandars();

	return (
		<>
			<GoBack action={undoStep} />
			<div>
				<CreationPage {...StandarsSteps} />
			</div>
		</>
	);
};
