/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../../../../config/theme';
import { setLoading } from '../../../../../store/modules/loading';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { useChangeStep } from '../../../hooks/useChangeStep';

export function useStepOne() {
	const { idProject, supervisionExtraordinaryData } = useContext(
		CreateSupervisionContext
	);

	const [date, setDate] = useState(new Date());
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { changeStepSE } = useChangeStep();

	const handleOnContinue = () => {
		date.setHours(0, 0, 0, 0);
		dispatch(setLoading({ loading: true }));
		changeStepSE(
			{
				newStep: STEPS_SUPERVISION_EXTRAORDINARY.FIN_1,
				extraordinarySupervision:
					supervisionExtraordinaryData.idSupervisionExtraordinary,
				executionDate: date.toISOString(),
			},
			`/${idProject}`
		);
	};

	return { date, matches, handleOnContinue, navigate, setDate };
}
