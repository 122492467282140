import { Grid } from '@mui/material';
import Alert from '../components/info.alert.component';
import CSV from '../assets/icons/CSV.icon';

interface IProps {
	handleUpload: () => void;
	isDone: boolean;
}
const CsvButton = (props: IProps) => {
	const { handleUpload, isDone } = props;
	return (
		<Grid
			container
			item
			md={12}
			xl={5}
			display="flex"
			alignItems="center"
			columnSpacing={2}
		>
			<Grid item xs={9}>
				<Alert
					severity="info"
					sx={{
						background: '#fcfdfd 0% 0% no-repeat padding-box',
						color: '#2B667D',
						border: 'solid 1px #30BAF2',
						margin: 0,
					}}
				>
					Recuerda que el archivo CSV no puede llevar títulos, únicamente
					columnas
				</Alert>
			</Grid>
			<Grid item xs={1} />
			<Grid item xs={2} sx={{ height: '100%' }}>
				<div
					onClick={handleUpload}
					id="act-upload-btn"
					style={{
						background: isDone ? '#0000001f' : '#e3f4e9',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '7px',
						height: '100%',
						cursor: isDone ? 'auto' : 'pointer',
					}}
				>
					<CSV disabled={isDone} />
				</div>
			</Grid>
		</Grid>
	);
};

export default CsvButton;
