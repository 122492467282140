import { SxProps, Theme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ContinueButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	background: '#0080F9',
	color: '#FFF',
}));

interface IProps {
	onClick: () => void;
	disabled?: boolean;
	id?: string;
	text?: string;
	variant?: any;
	customSx?: SxProps<Theme>;
}
const ContinueBtn = (props: IProps) => {
	const {
		onClick,
		disabled = false,
		id = 'btn-continue',
		text = 'Continuar',
		variant = 'contained',
		customSx,
	} = props;
	return (
		<ContinueButton
			disableElevation
			sx={customSx}
			variant={variant}
			id={id}
			onClick={onClick}
			disabled={disabled}
		>
			{text}
		</ContinueButton>
	);
};

export default ContinueBtn;
