/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from '../../../../utils/validators';
import { useValidateSuspension } from './useValidateSuspension';
import { useUpdateUser } from './useUpdateUser';
import { IModal, DefaultModal } from '../../../../interfaces/modal.interface';
import {
	IS_ACTIVE,
	IS_DELETED,
	IS_DELETED_MODAL,
	IS_DELETED_PROJECT,
	IS_DELETED_PROJECT_MODAL,
	IS_INACTIVE,
} from '../../constants/modal-messages';
import { usePermanentSuspension } from './usePermanentSuspension';

interface IUseConfirmationStatus {
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const useConfirmationStatus = ({ setStep }: IUseConfirmationStatus) => {
	const navigate = useNavigate();
	const location = useLocation();
	const idUser = location.state.idUser as string;
	const newStatus = location.state.newStatus as number;
	const isDeleted = location.state.isDeleted as boolean;
	const idProject = location.state.idProject as string;

	const [descriptionButton, setDescriptionButton] = useState('');
	const [title, setTitle] = useState('');

	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const { handleSuspendPermanent } = usePermanentSuspension();
	const { handleValidate, statusValidateSuspension, isValid } = useValidateSuspension();
	const { handleUpdate } = useUpdateUser();

	useEffect(() => {
		if (isEmpty(idUser) || isEmpty(newStatus)) {
			navigate(-1);
		}
	}, []);

	useEffect(() => {
		if (idProject) {
			setDescriptionButton(IS_DELETED_PROJECT.BUTTON_DESCRIPTION);
			setTitle(IS_DELETED_PROJECT.DESCRIPTION);
		} else if (isDeleted) {
			setDescriptionButton(IS_DELETED.BUTTON_DESCRIPTION);
			setTitle(IS_DELETED.DESCRIPTION);
		} else if (newStatus === 1) {
			setDescriptionButton(IS_ACTIVE.BUTTON_DESCRIPTION);
			setTitle(IS_ACTIVE.DESCRIPTION);
		} else if (newStatus === 0) {
			setDescriptionButton(IS_INACTIVE.BUTTON_DESCRIPTION);
			setTitle(IS_INACTIVE.BUTTON_DESCRIPTION);
		}
	}, [newStatus]);

	useEffect(() => {
		if (statusValidateSuspension === 'success') {
			if (isValid) {
				setModal({
					...modal,
					open: true,
					title: idProject
						? IS_DELETED_PROJECT_MODAL.TITLE
						: IS_DELETED_MODAL.TITLE,
					body: idProject
						? IS_DELETED_PROJECT_MODAL.DESCRIPTION_IS_VALID
						: IS_DELETED_MODAL.DESCRIPTION_IS_VALID,
					callback: () =>
						handleSuspendPermanent({
							userToSuspend: idUser,
							data: [],
							fromOnlyOneProject: idProject ? true : false,
						}),
				});
			} else {
				setModal({
					...modal,
					open: true,
					title: idProject
						? IS_DELETED_PROJECT_MODAL.TITLE
						: IS_DELETED_MODAL.TITLE,
					body: idProject
						? IS_DELETED_PROJECT_MODAL.DESCRIPTION_IS_NOT_VALID
						: IS_DELETED_MODAL.DESCRIPTION_IS_NOT_VALID,
					callback: () => setStep(1),
				});
			}
		}
	}, [statusValidateSuspension, isValid]);

	const handleContinue = () => {
		if (isDeleted) {
			handleValidate(idUser);
		} else {
			handleUpdate(idUser, newStatus === 0 ? 'INACTIVE' : 'ACTIVE');
		}
	};

	return { title, descriptionButton, modal, handleContinue, setModal, navigate };
};
