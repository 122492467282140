import DimensionIcon from '../assets/icons/dimensiones.icon';
import ActividadesIcon from '../assets/icons/actividades.icon';
import CriteriosIcon from '../assets/icons/criterios.icon';
import PersonalIcon from '../assets/icons/personal.icon';
import CronogramaIcon from '../assets/icons/cronograma.icon';

export const estadoCreacion = [
	{
		label: 'Dimensiones Geográficas',
		icon: <DimensionIcon />,
	},
	{
		label: 'Actividades',
		icon: <ActividadesIcon />,
	},
	{
		label: 'Criterios',
		icon: <CriteriosIcon />,
	},
	{
		label: 'Personal',
		icon: <PersonalIcon />,
	},
	{
		label: 'Cronograma',
		icon: <CronogramaIcon />,
	},
];
