// components
import Home from '../../pages/home';
import Criterios from '../../pages/criterios/Index';
import Personal from '../../pages/personal/Index';
import DimensionesGeograficas from '../../pages/dimensiones-geograficas/Index';
import { UserProfile } from '../../pages/user-profile';
import Actividades from '../../pages/actividades/Index';
import Cronograma from '../../pages/cronograma/Index';

import { ProjectProfile } from '../../pages/profile-project';
import { SupervisionComponent } from '../../pages/supervision-project';
import { SupervisionAction } from '../../pages/supervision-action';
import { ViewProjects } from '../../pages/view-projects';
import { ExtraordinaryIncidence } from '../../pages/extraordinary-incidence';
import { SupervisionHistory } from '../../pages/supervision-history';

import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/svg/projects.svg';
import { SvgIcon } from '@mui/material';
import { NewProject } from '../../pages/new-project';
import ProyectSuccesful from '../../pages/proyect-created/Index';
import DocumentUploadSuccesfull from '../../pages/save-docs/document-upload-succesfull';
import { SummaryRoutine } from '../../pages/supervision-project/components/summary-routine/summary-routine.component';
import { IMenuList, styleIcon, styleIconContainer } from '../utils/resources.utils';

import { ReactComponent as DevicesIcon } from '../../assets/svg/devices.svg';
import NotificationList from '../../pages/notifications';
import { ViewBaseTasks } from '../../pages/view-base-task';
import { SummaryTasks } from '../../pages/summary-tasks';
import { EditActivities } from '../../pages/edit-activities/edit-activities.component';
import { EditFunctionalLvl2 } from '../../pages/edit-functionals/components/functionals-lvl2/editFunctionalLvl2.component';
import { EditRelationsUA } from '../../pages/edit-functionals/components/functionals-relations-UA/edit-relations-UA.component';
import { EditRelationsUI } from '../../pages/edit-functionals/components/functionals-relations-UI/edit-relations-UI.component';
import { EditFunctionalLvl1 } from '../../pages/edit-functionals/components/funtionals-lvl1/editFunctionalLvl1.component';
import { EditFindings } from '../../pages/edit-indicators/components/edit-findings/edit-findings.component';
import { EditGeneralIndicators } from '../../pages/edit-indicators/components/edit-general-indicators/edit-general-indicators.component';
import { EditSpecificIndicators } from '../../pages/edit-indicators/components/edit-specific-indicators/edit-specific-indicators.component';

export const RouteCordinatorList: IMenuList[] = [
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Inicio',
		link: '/inicio',
		Component: Home,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Mi Perfil',
		link: '/mi-perfil',
		Component: UserProfile,
		hideMenu: true,
	},
	{
		icon: (
			<SvgIcon>
				<ProjectsIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Nuevo Proyecto',
		link: '/nuevo-proyecto',
		Component: NewProject,
		hideMenu: true,
		submenu: [
			{
				title: 'Dimensiones geográficas',
				link: '/dimensiones-geograficas',
				Component: DimensionesGeograficas,
			},
			{
				title: 'Actividades',
				link: '/actividades',
				Component: Actividades,
			},
			{
				title: 'Criterios',
				link: '/criterios',
				Component: Criterios,
			},
			{
				title: 'Personal',
				link: '/personal',
				Component: Personal,
			},
			{
				title: 'Cronograma',
				link: '/cronograma',
				Component: Cronograma,
			},
			{
				title: 'Proyecto creado',
				link: '/proyecto-creado',
				Component: ProyectSuccesful,
				hideMenu: true,
			},
		],
	},
	{
		icon: (
			<SvgIcon>
				<ProjectsIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Planeación de la supervision',
		link: '/planeacion',
		hideMenu: true,
		Component: NewProject,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<ProjectsIcon style={styleIcon} />
			</div>
		),
		title: 'Proyectos',
		link: '/proyectos',
		Component: ViewProjects,
	},
	{
		icon: (
			<SvgIcon>
				<ProjectsIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Perfil de proyecto',
		link: '/perfil-proyecto',
		hideMenu: true,
		Component: ProjectProfile,
		submenu: [
			{
				title: 'Edición de la supervisión',
				link: '/editar-datos-generales',
				Component: NewProject,
				hideMenu: true,
			},
			{
				title: 'Detalle de la supervisión',
				link: '/ver-supervision',
				Component: SummaryRoutine,
				hideMenu: true,
			},
			{
				title: 'Supervision',
				link: '/supervision-confirmar',
				Component: SupervisionAction,
				hideMenu: true,
			},
			{
				title: 'Supervision',
				link: '/supervision',
				Component: SupervisionComponent,
				hideMenu: true,
			},
			{
				title: 'Incidencia extraordinaria',
				link: '/incidencia-extraordinaria',
				Component: ExtraordinaryIncidence,
				hideMenu: true,
			},
			{
				title: 'Historial de supervisión',
				link: '/historial-supervision',
				Component: SupervisionHistory,
				hideMenu: true,
			},
			{
				title: 'Resumen de tareas',
				link: '/resumen-tareas',
				Component: SummaryTasks,
				submenu: [
					{
						title: 'Ver tareas base',
						link: '/ver-tareas-base',
						Component: ViewBaseTasks,
					},
				],
			},
			{
				title: 'Dimensiones geográficas nivel uno',
				link: '/dimensiones-nivel-uno',
				Component: EditFunctionalLvl1,
			},
			{
				title: 'Dimensiones geográficas nivel dos',
				link: '/dimensiones-nivel-dos',
				Component: EditFunctionalLvl2,
			},
			{
				title: 'Relaciones espacios-actividades',
				link: '/relaciones-espacios-actividades',
				Component: EditRelationsUA,
			},
			{
				title: 'Relaciones espacios-indicadores',
				link: '/relaciones-espacios-indicadores',
				Component: EditRelationsUI,
			},
			{
				title: 'Edición actividades',
				link: '/edicion-actividades',
				Component: EditActivities,
			},
			{
				title: 'Edición criterios generales',
				link: '/edicion-criterios-generales',
				Component: EditGeneralIndicators,
			},
			{
				title: 'Edición criterios específicos',
				link: '/edicion-criterios-especificos',
				Component: EditSpecificIndicators,
			},
			{
				title: 'Edición Hallazgos',
				link: '/edicion-hallazgos',
				Component: EditFindings,
			},
		],
	},
	{
		title: 'Mensaje',
		link: '/proyecto/mensaje-proyecto',
		Component: DocumentUploadSuccesfull,
		hideMenu: true,
	},
	{
		icon: (
			<SvgIcon>
				<DevicesIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Notificaciones',
		link: '/notificaciones',
		Component: NotificationList,
		hideMenu: true,
	},
];
