/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../store';
import { useGetStatus } from '../../../hooks/useGetStatus';
import { useConfirmProgress } from '../../../hooks/useConfirmProgress';

export const useNotStarted = () => {
	const navigate = useNavigate();
	const { executeConfirmation } = useConfirmProgress();
	const { getStatus } = useGetStatus({ changeStep: false });
	const project = useSelector((state: RootState) => state.projectBase);

	useEffect(() => {
		getStatus();
	}, []);

	return { project, navigate, executeConfirmation };
};
