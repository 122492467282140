import { Paper, Box, Grid } from '@mui/material';
import CriteriosIcon from '../../../../assets/icons/criterios.icon';
import { HeaderEdit } from '../../../../components/header-edit.component';
import { Button } from '../../../../components/main-button.component';
import styles from '../styles/stepper-styles.module.css';
import { useEditFindings } from './hooks/useEditFindings';
import VerticalStepper from '../../../../components/vertical-stepper-fixed.component';
import { EditFindingRow } from './components/edit-finding-row.component';
import DashedBtn from '../../../../components/dashed-button.component';

export const EditFindings = () => {
	const {
		findignsInputs,
		findignsOptions,
		matches,
		setFindignsInputs,
		navigate,
		handleNewFinding,
		handleDeleteFinding,
		updateFindings,
	} = useEditFindings();

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
					alignItems="center"
				>
					<HeaderEdit
						section="Criterios"
						title="1. Nombre los tipos de hallazgos"
						alertText=""
						icon={<CriteriosIcon size="23px" />}
					/>
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={findignsOptions}
							renderItem={(item, column) => (
								<EditFindingRow
									item={item}
									column={column}
									values={findignsInputs}
									setValues={setFindignsInputs}
									onDelete={handleDeleteFinding}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<DashedBtn
							onClick={() => handleNewFinding()}
							width="100"
							text="Agregar hallazgo"
						/>
					</Grid>
					<Grid item>
						<Button
							label="Salir"
							onClick={() => navigate(-1)}
							variant="outlined"
							style={{
								color: ' var(--color-error-main)',
								width: '200px',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Guardar"
							onClick={() => updateFindings()}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
							disabled={
								!Object.values(findignsInputs).every(
									(value) => value !== ''
								)
							}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
