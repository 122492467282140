import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../../../components/header-edit.component';
import { useEditNonWorkingDays } from './hooks/useEditNonWorkingDays';
import { FormNonWorkingDays } from './components/form-non-working-days.component';
import CronogramaIcon from '../../../../assets/icons/cronograma.icon';

export const EditNonWorkingDays = () => {
	const { matches } = useEditNonWorkingDays();

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
					alignItems="center"
				>
					<HeaderEdit
						section="Cronograma"
						title="Días no laborales"
						alertText="A continuación podrás editar los días que NO serán laborables. En estos días no se asignarán tareas de supervisión."
						icon={<CronogramaIcon size="23px" />}
					/>
					<FormNonWorkingDays />
				</Grid>
			</Box>
		</Paper>
	);
};
