import { KeyboardArrowRight } from '@mui/icons-material';
import { IDimensionEspacial } from '../../../../../interfaces/logic.interface';

export const areaSelectAdapter = (
	data: IDimensionEspacial[],
	functionView: (item: IDimensionEspacial) => void
): IDimensionEspacial[] => {
	const niveles: IDimensionEspacial[] = data.map((nivel: IDimensionEspacial) => {
		return {
			...nivel,
			acciones: (
				<span
					onClick={() => functionView(nivel)}
					style={{ cursor: 'pointer' }}
					id={`view-${nivel._id}`}
				>
					<KeyboardArrowRight />
				</span>
			),
		};
	});
	return niveles;
};
