import { Grid } from '@mui/material';
import styles from './styles/body-card-styles.module.css';
import { ReactElement } from 'react';

interface IBodyCard {
	title: string;
	subtitle: string;
	stats?: string;
	stylesColorFont?: React.CSSProperties;
	icon?: ReactElement;
}

export const BodyCard = ({
	title,
	stats,
	subtitle,
	stylesColorFont,
	icon,
}: IBodyCard) => {
	return (
		<>
			<Grid item xs={6}>
				<h4
					id={title.toLowerCase().replaceAll(' ', '-')}
					className={styles.title}
					style={stylesColorFont}
				>
					{title}
				</h4>
				<span className={styles.stats} style={stylesColorFont}>
					{stats}
				</span>
				<br />
				<span className={styles.title} style={stylesColorFont}>
					{subtitle}
				</span>
			</Grid>
			{icon && (
				<Grid item xs={6} className={styles.iconContainer}>
					{icon}
				</Grid>
			)}
		</>
	);
};
