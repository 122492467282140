import { Box, Grid, Paper } from '@mui/material';
import TableCustom from '../../components/table-custom.component';
import { useViewBaseTasks } from './hooks/useViewBaseTasks';
import SelectAsyncSearch from '../../components/select-search.component';
import { IOptions } from '../../interfaces/filter.interface';

export const ViewBaseTasks = () => {
	const {
		columns,
		data,
		dataValues,
		functionalParentBase,
		functionalBase,
		activityBase,
		functionalsSelectOptions,
		functionalsSelectOptionsLvl2,
		activitiesOptions,
		setData,
		setDataValues,
	} = useViewBaseTasks();

	return (
		<>
			<Paper elevation={0}>
				<Box padding={3}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<h5
								style={{
									fontWeight: 'bold',
								}}
							>
								Tareas asignadas
							</h5>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<SelectAsyncSearch
								id="lvl1"
								value={dataValues.functionalParent}
								placeholder="Todos"
								label={`${functionalParentBase?.name || 'Nivel 1'}`}
								onChange={(e) => {
									setDataValues({
										...dataValues,
										functionalParent: e?.target?.value || '',
										functional: '',
										activity: '',
									});
									setData({ ...data, pagination: '1' });
								}}
								options={functionalsSelectOptions as IOptions[]}
								labelPosition="start"
							/>
						</Grid>
						{functionalBase && (
							<Grid item xs={12} md={6} lg={4}>
								<SelectAsyncSearch
									id="lvl2"
									value={dataValues.functional}
									placeholder="Todos"
									label={`${functionalBase?.name || 'Nivel 2'}`}
									onChange={(e) => {
										setDataValues({
											...dataValues,
											functional: e?.target?.value || '',
											activity: '',
										});
										setData({ ...data, pagination: '1' });
									}}
									options={functionalsSelectOptionsLvl2 as IOptions[]}
									labelPosition="start"
								/>
							</Grid>
						)}
						<Grid item xs={12} md={6} lg={4}>
							<SelectAsyncSearch
								id="activity"
								value={dataValues.activity}
								placeholder="Todos"
								label={`${activityBase?.name || 'Actividad'}`}
								onChange={(e) => {
									setDataValues({
										...dataValues,
										activity: e?.target?.value || '',
									});
									setData({ ...data, pagination: '1' });
								}}
								options={activitiesOptions as IOptions[]}
								labelPosition="start"
							/>
						</Grid>
						<Grid item xs={12}>
							<TableCustom
								columns={columns}
								data={data}
								setData={setData}
								showPagination
								showLimitPerPage
								hideHeader
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
