import { Grid } from '@mui/material';
import styles from './styles/header-edit-styles.module.css';
import Alert from './info.alert.component';

interface IHeaderSchedule {
	section: string;
	title: string;
	alertText: string;
	icon: JSX.Element;
}

export const HeaderEdit = ({ section, title, alertText, icon }: IHeaderSchedule) => {
	return (
		<>
			<Grid item xs={12} className={styles.containerHeader}>
				<div className={styles.iconSchedule}>{icon}</div>
				<span className={styles.titleSchedule}>{section}</span>
			</Grid>
			<Grid item xs={12} style={{ fontWeight: 'bold' }}>
				{title}
			</Grid>
			{alertText.length > 0 && (
				<Grid item xs={12}>
					<Alert severity="info" sx={{ marginTop: '0rem' }}>
						{alertText}
					</Alert>
				</Grid>
			)}
		</>
	);
};
