import { Box, Divider, Grid, Paper } from '@mui/material';
import { Sheets } from './components/sheets.component';
import { useSummaryView } from './hooks/useSummaryView';
import styles from '../styles/upload-files-styles.module.css';
import Alert from '../../../../components/info.alert.component';
import { TableSummary } from './components/table-summary.component';
import { SummaryDetail } from './components/summary-detail.component';
import { Button } from '../../../../components/main-button.component';
import { STEPS_AUTOMATIC_CHARGE } from '../../utils/steps-automatic-charge';

interface ISumaryView {
	alertDescription: string;
}

export const SummaryView = ({ alertDescription }: ISumaryView) => {
	const {
		data,
		sheets,
		canContinue,
		summaryAdapted,
		selectedSheet,
		errorsFile,
		matches,
		setStep,
		setData,
		setSelectedSheet,
		confirmProgress,
		restartProject,
	} = useSummaryView();

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<span className={styles.titleUploadFile}>Resumen de carga</span>
					</Grid>
					<Grid item xs={12}>
						<Divider className={styles.dividerUploadFile} />
					</Grid>
					<Grid item>
						<Alert
							severity="info"
							sx={{ margin: '0px', width: 'min-content' }}
						>
							{alertDescription}
						</Alert>
					</Grid>
					<Grid item xs={12} />
					{errorsFile.length > 0 && (
						<Grid item xs={12}>
							<ul>
								{errorsFile.map((error, index) => {
									return (
										<li
											key={`error-file-${index}`}
											style={{ color: '#690015' }}
										>
											{error}
										</li>
									);
								})}
							</ul>
						</Grid>
					)}
					<Grid item xs={12} md={3}>
						<Sheets
							sheets={sheets}
							selectedSheet={selectedSheet}
							setSelectedSheet={setSelectedSheet}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						style={{ maxHeight: '50vh', overflow: 'auto' }}
					>
						<TableSummary data={data} setData={setData} />
					</Grid>
					<Grid item xs={12} md={3}>
						<SummaryDetail summary={summaryAdapted[selectedSheet]} />
					</Grid>
					<Grid
						item
						xs={12}
						display="flex"
						justifyContent={matches ? 'center' : 'flex-end'}
						flexDirection={matches ? 'column' : 'row'}
						alignItems={'center'}
						gap={matches ? 1 : 3}
					>
						<Grid item>
							<Button
								label="Reiniciar creación"
								onClick={() => restartProject()}
								variant="outlined"
								style={{
									width: '200px',
								}}
							/>
						</Grid>
						<Grid item>
							<Button
								label="Cargar otro archivo"
								onClick={() =>
									setStep(STEPS_AUTOMATIC_CHARGE.RELOAD_DATA)
								}
								style={{
									width: '200px',
								}}
							/>
						</Grid>
						{canContinue && (
							<Grid item>
								<Button
									label="Continuar"
									onClick={() => confirmProgress()}
									style={{
										width: '200px',
										backgroundColor: 'var(--color-highlight-blue)',
									}}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
