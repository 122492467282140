export const editRoutineSupervisionAdapter = (data: any) => {
	return {
		routine: data?.detail?.routine?._id || '',
		supervisor:
			data?.detail?.routine?.supervisor || data?.detail?.routine?.team || '',
		supervisorName: data?.detail?.supervisorDescription || '',
		status: data?.detail?.routine?.status || '',
		idProject: data?.detail?.project || '',
		description: data?.detail?.routine?.description || '',
		canBeExecuted: data?.detail?.routine?.canBeExecuted || '',
	};
};
