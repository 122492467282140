/* eslint-disable react-hooks/exhaustive-deps */
import styles from './components/styles/header-project-styles.module.css';
import { HeaderProject } from './components/header-project.component';
import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { resetPP } from '../../store/modules/profile-project';
import { useProjectProfile } from './hooks/useProfileProject';
import { ProfileProjectContext } from './context/useProfileProjectContext';

export const ProjectProfile = () => {
	const {
		currentTab,
		getTabs,
		matches,
		data,
		setData,
		setStep,
		dispatch,
		setCurrentTab,
		getComponent,
		getProject,
	} = useProjectProfile();

	return (
		<ProfileProjectContext.Provider value={{ data, setData, getProject }}>
			<HeaderProject setStep={setStep} />
			<Tabs
				value={currentTab}
				variant="scrollable"
				onChange={(_, value) => {
					dispatch(resetPP());
					setCurrentTab(value);
					setStep(value);
				}}
				TabIndicatorProps={{
					style: { background: '#0080F9' },
				}}
				sx={{
					'& button.Mui-selected': {
						color: '#0080F9',
					},
					width: '100%',
				}}
				orientation={matches ? 'vertical' : 'horizontal'}
			>
				{getTabs().map((element, index: number) => {
					return (
						<Tab
							key={index}
							className={styles.tabsStyle}
							label={element.label}
						/>
					);
				})}
			</Tabs>
			<Paper>
				<Box padding={3}>{getComponent()}</Box>
			</Paper>
		</ProfileProjectContext.Provider>
	);
};
