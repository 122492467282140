/* eslint-disable react-hooks/exhaustive-deps */
import CheckboxMUI from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup, Grid, styled, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import {
	ISpatialUnit,
	TSpatialUnit,
} from '../../../interfaces/get-supervision.interfaces';
import { Button } from '../../../../../components/main-button.component';
import theme from '../../../../../config/theme';
import { useNavigate } from 'react-router-dom';
import InputSelect from '../../../../../components/select-input.component';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { useSaveFunctionals } from '../hooks/useSaveFunctionals';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';

interface IStepOneGD {
	items: TSpatialUnit[];
	labels: ISpatialUnit[];
	step: { step1: boolean; step2: boolean };
	levels: number;
	initialValue: string;
}

const CheckBox = styled(CheckboxMUI)(({ theme }) => ({
	color: 'var(--color-highlight-blue)',
	'&.Mui-checked': {
		color: 'var(--color-highlight-blue)',
	},
}));

export const StepOneGD = ({ items, labels, step, levels, initialValue }: IStepOneGD) => {
	const [valueSelected, setValueSelected] = useState<string>('');
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const { executeSaveFunctionals } = useSaveFunctionals({});

	const onSelect = (index: number | string) => {
		setValueSelected(index.toString());
	};

	const getOptions = (): IOptionsMenu[] => {
		const optionsSelected: IOptionsMenu[] = [
			{
				id: valueSelected,
				label: labels.find((e) => e.functional === valueSelected)?.name || '',
				value: valueSelected,
			},
		];

		return optionsSelected;
	};

	const adaptValues = (): { _id: string; applies: boolean }[] => {
		return labels.map((e) => {
			return {
				_id: e.id.toString(),
				applies: e.functional.toString() === valueSelected ? true : false,
			};
		});
	};

	useEffect(() => {
		setValueSelected(initialValue);
	}, [initialValue]);

	return step.step1 ? (
		<Grid
			container
			marginTop={2}
			spacing={2}
			style={{
				padding: '8px 0px 0px 8px',
			}}
		>
			<Grid item>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
					}}
				>
					{items.map((item, column) => (
						<div key={`formGroup-${column}`}>
							<FormGroup>
								{item.map((prop, row) => (
									<FormControlLabel
										sx={{ fontSize: '20px' }}
										key={`formControl-${column}-${row}`}
										checked={prop.functional === valueSelected}
										value={prop.id}
										control={
											<CheckBox id={`checkbox-${column}-${row}`} />
										}
										label={
											<p style={{ fontSize: '18px', margin: 0 }}>
												{prop.name}
											</p>
										}
										onChange={() => onSelect(prop.functional)}
									/>
								))}
							</FormGroup>
						</div>
					))}
				</div>
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'flex-end'}
					spacing={2}
				>
					<Grid item>
						<Button
							label="Cancelar"
							onClick={() => navigate(-1)}
							style={{
								backgroundColor: 'var(--color-error-main)',
								width: '150px',
							}}
							id="btn-gd-cancel"
						/>
					</Grid>
					<Grid item>
						<Button
							label="Continuar"
							onClick={() => {
								executeSaveFunctionals(
									adaptValues(),
									levels > 1
										? STEPS_SUPERVISION_EXTRAORDINARY.DG_2
										: STEPS_SUPERVISION_EXTRAORDINARY.ACT_1
								);
							}}
							style={{
								backgroundColor: 'var(--color-highlight-blue)',
								width: '150px',
							}}
							disabled={!valueSelected}
							id="btn-gd-continue"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	) : (
		<Grid
			container
			marginTop={2}
			style={{
				padding: '8px 0px 0px 8px',
			}}
		>
			<Grid item xs={12}>
				<InputSelect
					optionsMenu={getOptions()}
					value={valueSelected}
					placeholder="Selecciona unidad funcional"
					onChange={() => null}
					isDisabled
				/>
			</Grid>
		</Grid>
	);
};
