import React, { useState } from 'react';
import {
	Alert,
	FormControlLabel,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Visibility, VisibilityOff, Close } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

//Assets
import styles from './styles/text-input-styles.component.module.css';

export interface ITextField {
	id: string;
	name: string;
	label?: string;
	labelComponent?: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>;
	value: string | number | '' | Date;
	error?: boolean;
	helperText?: string;
	type?: string;
	size?: 'small' | 'medium' | undefined;
	isRequired?: boolean;
	labelStyles?: React.CSSProperties;
	sx?: any;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	onBlur?: (e: any) => void;
	onFocus?: (e: any) => void;
	noCopy?: boolean;
	noPaste?: boolean;
	disabled?: boolean;
	inputProps?: object;
	InputProps?: object;
	select?: boolean;
	startAdornment?: React.ReactElement;
	contentStyles?: object;
	maxLength?: number | undefined;
	labelDate?: string;
	inputFormat?: string;
	which?: string;
	multiline?: boolean;
	rows?: number;
	onClickSearch?: () => void;
	autoComplete?: string;
}

const TextInput = (props: ITextField) => {
	const {
		label,
		value,
		onChange,
		id,
		name,
		helperText,
		type,
		isRequired,
		size,
		sx,
		labelStyles,
		placeholder,
		onBlur,
		onFocus,
		noPaste,
		noCopy,
		disabled,
		inputProps,
		InputProps, //Referente a las propiedades de los Input's
		select,
		labelComponent,
		startAdornment,
		contentStyles = {},
		maxLength,
		labelDate,
		inputFormat = 'dd/MM/yyyy',
		which = 'input',
		multiline,
		rows,
		onClickSearch,
		autoComplete,
	} = props;
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	return (
		<div style={{ width: '100%', ...contentStyles }}>
			<FormControlLabel
				label={
					<span
						style={{ ...labelStyles }}
						className={styles.labelContainerInput}
					>
						<>
							{labelComponent ? labelComponent : label}
							{isRequired && (
								<label className={styles.labelRequired}>*</label>
							)}
						</>
					</span>
				}
				className={styles.containerInput}
				labelPlacement="top"
				control={
					which === 'input' ? (
						<TextField
							inputProps={{
								...inputProps,
								'data-testid': id,
								maxLength: maxLength,
							}}
							InputProps={{
								...InputProps,
								endAdornment:
									type === 'password' ? (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
											>
												{showPassword ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									) : (
										type === 'search' && (
											<InputAdornment position="start">
												<IconButton
													aria-label="search"
													onClick={onClickSearch}
												>
													<SearchIcon />
												</IconButton>
											</InputAdornment>
										)
									),
								startAdornment,
							}}
							id={id}
							name={name}
							type={
								type === 'number'
									? 'number'
									: type === 'password'
									? showPassword
										? 'text'
										: 'password'
									: 'text'
							}
							variant="outlined"
							//margin="dense"
							size={size || 'small'}
							fullWidth
							sx={sx}
							className={styles.textField}
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							onFocus={onFocus}
							placeholder={placeholder}
							onPaste={(e) => {
								if (noPaste) {
									e.preventDefault();
									return false;
								}
							}}
							onCopy={(e) => {
								if (noCopy) {
									e.preventDefault();
									return false;
								}
							}}
							multiline={multiline}
							rows={rows}
							disabled={disabled}
							select={select}
							autoComplete={autoComplete}
						/>
					) : (
						<DesktopDatePicker
							onChange={onChange as any}
							label={labelDate}
							value={value}
							renderInput={(params: any) => (
								<TextField {...params} sx={sx} size="small" fullWidth />
							)}
							inputFormat={inputFormat}
						/>
					)
				}
			/>
			{helperText && (
				<Alert
					style={{
						marginTop: 5,
						marginBottom: 5,
						boxSizing: 'border-box',
						backgroundColor: '#FCFCFC',
						color: '#D1001F',
					}}
					severity="error"
					sx={{ width: '100%' }}
					data-testid={`alert-input-${id}`}
					icon={<Close fontSize="inherit" style={{ color: '#D1001F' }} />}
				>
					{helperText}
				</Alert>
			)}
		</div>
	);
};

export default TextInput;
