/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Paper } from '@mui/material';
import { useAllActivities } from '../hooks/useAllActivities';
import { GoBack } from '../../../components/go-back-component';
import { setValuePP } from '../../../store/modules/profile-project';
import TableCustom from '../../../components/table-custom.component';

export const SupervisionViewActivities = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location.state.params;
	const { dataActivities, columnsActivities, setDataActivities, executeAllActivities } =
		useAllActivities();

	useEffect(() => {
		executeAllActivities(
			{},
			`/${state?.routine}?page=${dataActivities?.pagination}&recordsByPage=${dataActivities?.rowsPerPage}`
		);
	}, [dataActivities?.pagination, dataActivities?.rowsPerPage]);

	return (
		<>
			{state.planeacion ? (
				<GoBack action={() => dispatch(setValuePP({ step: 3 }))} />
			) : (
				<GoBack />
			)}
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Historial de supervisión
							</span>
							<br />
							<span style={{ fontSize: '17px', color: '#707070' }}>
								{state?.routine?.routineDescription}
							</span>
						</Grid>

						<Grid item xs={12}>
							<TableCustom
								columns={columnsActivities}
								data={dataActivities}
								setData={setDataActivities}
								showLimitPerPage
								showPagination
								hideHeader
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
