import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

interface ICardProps {
	title?: string;
	spacing?: number;
	sxRoot?: SxProps;
	sx?: SxProps;
	children?: JSX.Element | JSX.Element[];
}

export const Card = (props: ICardProps) => {
	const { title, spacing = 2, sx, sxRoot, children } = props;
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				pt: 2,
				marginBottom: '3rem',
				position: 'relative',
				...sxRoot,
			}}
			borderRadius={'5%'}
		>
			<Paper sx={{ flexGrow: 1 }} elevation={0}>
				<Grid container spacing={spacing} sx={{ ...sx }}>
					{title && (
						<>
							<Grid item xs={12}>
								<h3 style={{ margin: 0, padding: '0.5rem 0 0 0.5rem' }}>
									{title}
								</h3>
							</Grid>
							<hr
								style={{
									width: '100%',
									borderBottom: '0.5px solid #E3E3E3',
									borderTop: 'none',
								}}
							/>
						</>
					)}
					<Grid item xs={12}>
						{children}
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};
