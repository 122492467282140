/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckboxMUI from '@mui/material/Checkbox';
import InputSelect from '../../components/select-input.component';
import { IOptionsMenu } from '../../interfaces/select.interface';
import ContinueButton from '../../components/custom-btn.component';
import SaveButton from '../../components/save-button.component';
import useCriterios from './hooks/useCriterios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { isEmpty } from 'lodash';
import { setLoading, stopLoading } from '../../store/modules/loading';
import { updateFunctionalIndicator } from '../../adapters/step-two.adapter';
import { setAlert } from '../../store/modules/snackbar';
import useFunctionalShort from './hooks/useFunctionalShort';

const TextButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	color: '#0080F9',
}));

const CheckBox = styled(CheckboxMUI)(({ theme }) => ({
	color: 'var(--color-highlight-blue)',
	'&.Mui-checked': {
		color: 'var(--color-highlight-blue)',
	},
}));

interface IModal {
	onContinue: () => void;
}

const ModalAsignación = (props: IModal) => {
	const { onContinue } = props;
	const dispatch = useDispatch();
	const [isSave, setIsSave] = useState(true);
	const _id = useSelector((root: RootState) => root.projectBase._id);
	const [items, setItems] = useState<Array<{ id: string; columns: any[] }>>([]);
	const [ACT_list, setACT_list] = useState<IOptionsMenu[]>([]);
	const [currentACT, setCurrentACT] = useState<string>('');
	const [checkedIndicatorLst, setCheckedIndicatorLst] = useState<Set<string>>(
		new Set()
	);
	const [isSelectedAll, setIsSelectedAll] = useState(false);
	const selectedAllText = isSelectedAll ? 'Seleccionar' : 'Deseleccionar';
	//FETCH
	const {
		UFList,
		EFList,
		currentEF,
		setCurrentEF,
		currentUF,
		setCurrentUF,
		getFunctionalData,
		getFunctionalDataNull,
	} = useFunctionalShort();

	const {
		//IG
		getIGs,
		getStatusIGs,
		getValueIGs,
		setValueIGs,
		//IE
		getIEs,
		// getStatusIEs,
		getValueIEs,
		setValueIEs,
		//ACT
		getActivity,
		statusActivity,
		valueActivities,
		//UPDATE
		updatedIndicatorAssociated,
		statusUpdatedIndicatorAssociated,
		valueUpdatedIndicatorAssociated,
		updateIndicators,
		updatedStatusIndicator,
		// updatedValueIndicator,
		//IS_CREATED
		getIsCreated,
		statusIsCreated,
		valueIsCreated,
		updatedIndicatorAssociatedLvl2,
		statusUpdatedIndicatorAssociatedLvl2,
		valueUpdatedIndicatorAssociatedLvl2,
		updatedIndicatorAssociatedLvl1,
		statusUpdatedIndicatorAssociatedLvl1,
		valueUpdatedIndicatorAssociatedLvl1,
	} = useCriterios();

	function showError() {
		dispatch(
			setAlert({
				show: true,
				message: 'No se pudo al obtener la información el proyecto',
				severity: 'error',
			})
		);
	}

	const isNext = useMemo(() => {
		return UFList.every((ele) => ele.status);
	}, [UFList]);

	//TO CONTINUE
	useEffect(() => {
		if (isSave) return;
		handleSave();
	}, [isSave]);

	//SEARCH ACTIVITIES
	useEffect(() => {
		if (isEmpty(currentEF)) return;
		dispatch(setLoading({ loading: true }));
		setACT_list([]);
		setValueIEs(null);
		setValueIGs(null);
		getActivity(
			null,
			`?project=${_id}&type=U-A&functional=${currentEF}&populate=1&applies=true`
		);
	}, [
		currentEF,
		statusUpdatedIndicatorAssociatedLvl2,
		statusUpdatedIndicatorAssociatedLvl1,
	]);

	//GET ACTIVITIES VALUES
	useEffect(() => {
		if (statusActivity === 'success') {
			dispatch(stopLoading());
			if (isEmpty(valueActivities?.data)) return;
			const _adapted = valueActivities?.data.map((element) => {
				const { activity: i } = element;
				return {
					id: i._id,
					label: i.columns[0],
					value: i._id,
					status: element.isIndicatorAssociated,
					_id: element._id,
				};
			});

			setACT_list(_adapted || []);
			if ((_adapted || []).length > 0) {
				setCurrentACT((_adapted || [])[0]?.value);
				getUIData((_adapted || [])[0]?.value);
			}
		} else if (statusActivity === 'error') {
			setItems([]);
			setCheckedIndicatorLst(new Set());
			dispatch(stopLoading());
			showError();
		}
	}, [statusActivity]);

	//SELECT INDICATORS
	useEffect(() => {
		if (isEmpty(currentACT)) return;
		getUIData();
	}, [currentACT]);

	//CHECK IF INDICATORS ARE CREATED
	useEffect(() => {
		if (statusIsCreated === 'success') {
			dispatch(stopLoading());
			//IF IS NOT CREATED OR EMPTY
			if (!valueIsCreated || valueIsCreated.data.length < 1) {
				getIGs(
					null,
					`?project=${_id}&activity=${currentACT}&type=A-IG&applies=true&populate=1`
				);
				dispatch(setLoading({ loading: true }));
				return;
			}
			const collection = new Set<string>();
			const _data: Array<{
				id: string;
				columns: any[];
				_id: string;
			}> = [];
			valueIsCreated.data.forEach((i) => {
				const { indicator } = i;
				_data.push({
					id: indicator._id,
					columns: indicator.columns,
					_id: i._id,
				});
				if (i.applies) collection.add(indicator._id);
			});

			setItems(_data);
			setCheckedIndicatorLst(collection);
		} else if (statusIsCreated === 'error') {
			setItems([]);
			setCheckedIndicatorLst(new Set());
			dispatch(stopLoading());
			showError();
		}
	}, [statusIsCreated]);

	//WHEN IGs has data
	useEffect(() => {
		if (getStatusIGs === 'success') {
			dispatch(stopLoading());
			if (isEmpty(getValueIGs)) return;
			getIEs(null, `?project=${_id}&activity=${currentACT}&populate=1`);
			//setCheckedIndicatorLst(collection);
		} else if (getStatusIGs === 'error') {
			dispatch(stopLoading());
			showError();
		}
	}, [getStatusIGs]);
	//WHEN IEs has data
	useEffect(() => {
		const collection = new Set<string>();
		const _data: Array<{ id: string; columns: any[] }> = [];

		if (getValueIEs && getValueIEs.data.length > 0) {
			getValueIEs.data.forEach((i) => {
				collection.add(i._id);
				_data.push({
					columns: i.columns,
					id: i._id || '',
				});
			});
		}
		if (getValueIGs && getValueIGs.data.length > 0) {
			getValueIGs.data.forEach((element) => {
				const { indicator: i } = element;
				if (element.applies) collection.add(i._id);
				_data.push({
					columns: i.columns,
					id: i._id || '',
				});
			});
		}

		setItems(_data);
		//setCheckedIndicatorLst(collection);
	}, [getValueIEs, getValueIGs, valueActivities]);

	//UPDATE INDICATORS
	useEffect(() => {
		if (updatedStatusIndicator === 'success') {
			if (isSave) {
				const actFlag = ACT_list.find((ele) => ele.id === currentACT);

				if (!isEmpty(actFlag)) {
					updatedIndicatorAssociated(
						{
							isIndicatorAssociated: true,
						},
						`/${actFlag._id}`
					);
				}
			} else {
				onContinue();
			}
		} else if (updatedStatusIndicator === 'error') {
			dispatch(stopLoading());
			showError();
		}
	}, [updatedStatusIndicator]);

	useEffect(() => {
		if (statusUpdatedIndicatorAssociated === 'success') {
			if (valueUpdatedIndicatorAssociated?.detail._id) {
				const actFlag: IOptionsMenu[] = [];
				ACT_list.forEach((ele) => {
					if (ele._id !== valueUpdatedIndicatorAssociated?.detail._id)
						actFlag.push(ele);
				});
				if (actFlag.every((ele) => ele.status)) {
					if (EFList.length > 0) {
						updatedIndicatorAssociatedLvl2(
							{
								isIndicatorAssociated: true,
							},
							`/${currentEF}`
						);
					} else if (UFList.length > 0) {
						updatedIndicatorAssociatedLvl1(
							{
								isIndicatorAssociated: true,
							},
							`/${currentUF}`
						);
					}
				} else {
					getActivities();
				}
			}
		} else if (statusUpdatedIndicatorAssociated === 'error') {
			dispatch(stopLoading());
			showError();
		}
	}, [statusUpdatedIndicatorAssociated]);

	useEffect(() => {
		if (statusUpdatedIndicatorAssociatedLvl2 === 'success') {
			if (valueUpdatedIndicatorAssociatedLvl2?.detail._id) {
				const EFListFlag: IOptionsMenu[] = [];
				EFList.forEach((ele) => {
					if (ele.id !== valueUpdatedIndicatorAssociatedLvl2?.detail._id)
						EFListFlag.push(ele);
				});
				if (EFListFlag.every((ele) => ele.status)) {
					if (UFList.length > 0) {
						updatedIndicatorAssociatedLvl1(
							{
								isIndicatorAssociated: true,
							},
							`/${currentUF}`
						);
					}
				} else {
					getFunctionalData(currentUF);
				}
			}
		} else if (statusUpdatedIndicatorAssociatedLvl2 === 'error') {
			dispatch(stopLoading());
			showError();
		}
	}, [statusUpdatedIndicatorAssociatedLvl2]);

	useEffect(() => {
		if (statusUpdatedIndicatorAssociatedLvl1 === 'success') {
			if (valueUpdatedIndicatorAssociatedLvl1?.detail._id) {
				getFunctionalDataNull();
			}
		} else if (statusUpdatedIndicatorAssociatedLvl1 === 'error') {
			dispatch(stopLoading());
			showError();
		}
	}, [statusUpdatedIndicatorAssociatedLvl1]);

	const getActivities = () => {
		dispatch(setLoading({ loading: true }));
		getActivity(
			null,
			`?project=${_id}&type=U-A&functional=${currentEF}&populate=1&applies=true`
		);
	};
	const getUIData = (act?: string) => {
		dispatch(setLoading({ loading: true }));
		getIsCreated(
			null,
			`?project=${_id}&type=U-I&activity=${
				act || currentACT
			}&functional=${currentEF}&populate=1`
		);
	};

	const selectEverything = () => {
		const collection = new Set(checkedIndicatorLst);
		for (const item of items) {
			if (isSelectedAll) collection.add(item.id);
			else collection.delete(item.id);
		}
		setIsSelectedAll((prev) => !prev);
		setCheckedIndicatorLst(collection);
	};

	const onSelect = (id: string, checked: boolean) => {
		const collection = new Set(checkedIndicatorLst);
		if (!checked) collection.delete(id);
		else collection.add(id);
		setCheckedIndicatorLst(collection);
	};

	const handleSelectUF = (value: string) => {
		setItems([]);
		setCheckedIndicatorLst(new Set());
		if (EFList.length <= 0) {
			setCurrentEF(value);
		}
		setCurrentUF(value);
	};
	const handleSelectEF = (value: string) => {
		setItems([]);
		setCheckedIndicatorLst(new Set());
		setCurrentEF(value);
	};
	const handleSelectActivity = (value: string) => {
		setItems([]);
		setCheckedIndicatorLst(new Set());
		setCurrentACT(value);
	};

	const handleSave = () => {
		dispatch(setLoading({ loading: true }));
		const _adapted = updateFunctionalIndicator(
			currentEF,
			items,
			checkedIndicatorLst,
			_id || '',
			currentACT
		);

		updateIndicators(_adapted);
	};

	const handleContinue = () => {
		setIsSave(false);
	};

	return (
		<>
			<Grid container marginBottom={2} marginTop={2} rowGap={2} xl={12}>
				<Grid container item xs={12} sm={12} md={6} xl={4} alignItems="center">
					<Grid item xs={5} sm={4} md={5} xl={5}>
						<p style={{ margin: 0 }}>Espacio Nivel 1</p>
					</Grid>
					<Grid item xs={7} sm={8} md={6} xl={5}>
						<InputSelect
							onChange={(e) => handleSelectUF(String(e.target.value))}
							value={currentUF}
							optionsMenu={UFList}
							withStatus
						/>
					</Grid>
				</Grid>
				{EFList.length > 0 && (
					<Grid container xs={12} sm={12} md={6} xl={4} alignItems="center">
						<Grid item xs={5} sm={4} md={5} xl={5}>
							<p style={{ margin: 0 }}>Espacio Nivel 2</p>
						</Grid>
						<Grid item xs={7} sm={8} md={6} xl={5}>
							<InputSelect
								onChange={(e) => handleSelectEF(String(e.target.value))}
								value={currentEF}
								optionsMenu={EFList}
								withStatus
							/>
						</Grid>
					</Grid>
				)}
				<Grid container xs={12} sm={12} md={6} xl={4} alignItems="center">
					<Grid item xs={5} sm={4} md={5} xl={5}>
						<p style={{ margin: 0 }}>Actividad</p>
					</Grid>
					<Grid item xs={7} sm={8} md={6} xl={5}>
						<InputSelect
							onChange={(e) => handleSelectActivity(String(e.target.value))}
							value={currentACT}
							optionsMenu={ACT_list}
							withStatus
						/>
					</Grid>
				</Grid>
			</Grid>
			{items.length > 0 && (
				<Grid item xs={2}>
					<TextButton
						//id='ie-select-all'
						sx={{ paddingLeft: 0, fontSize: '12px' }}
						onClick={() => {
							selectEverything();
						}}
					>
						{selectedAllText} todo
					</TextButton>
				</Grid>
			)}
			<div
				style={{
					//display: 'table-cell',
					//flexWrap: 'wrap',
					display: 'block',
					maxWidth: '100%',
					overflowX: 'auto',
					columns: Math.ceil(items.length / 10),
					columnGap: '8rem',
					//justifyContent: 'space-between',
				}}
			>
				{(items || []).map((item, column) => (
					<Grid container key={`formGroup-${column}-${item.id}`}>
						<Grid
							item
							xs={10}
							sx={{ width: '100%', overflowX: 'auto', display: 'contents' }}
						>
							<div
								style={{
									//display: 'contents',
									columnGap: '3rem',
								}}
							>
								<div
									key={`prop-${column}-${item.id}`}
									style={{
										width: 'fit-content',
									}}
								>
									<FormControlLabel
										sx={{ fontSize: '27px' }}
										checked={checkedIndicatorLst.has(item.id)}
										value={_id}
										control={<CheckBox />}
										label={
											<p
												style={{
													fontSize: '24px',
													margin: 0,
													width: '100%',
													whiteSpace: 'nowrap',
												}}
											>
												{item.columns[0]}
											</p>
										}
										onChange={(_, checked) =>
											onSelect(item.id, checked)
										}
									/>
								</div>
							</div>
						</Grid>
					</Grid>
				))}
			</div>
			<Grid sx={{ display: 'flex', justifyContent: 'flex-end' }} columnGap={2}>
				<Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
					<SaveButton
						id="ig-guardar-modal"
						onClick={handleSave}
						disabled={false}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
					<ContinueButton
						id="geograficos2-continue-btn"
						disabled={!isNext}
						onClick={handleContinue}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default ModalAsignación;
