import { Avatar, Grid, useMediaQuery, useTheme } from '@mui/material';
import styles from './styles/task-styles.module.css';

interface ITask {
	id: string;
	task?: any;
	dragOverlay?: any;
}

export const Task = ({ task, dragOverlay }: ITask) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const style = {
		cursor: dragOverlay ? 'grabbing' : 'grab',
	};

	const getLetter = (nombre: string) => {
		const splitName = nombre.split(' ', 2);
		const name = splitName[0];
		const lastName = splitName[1];
		if (name !== undefined && lastName !== undefined)
			return name[0]?.toUpperCase() + lastName[0]?.toUpperCase();
		return ' ';
	};

	return (
		<Grid
			container
			style={style}
			className={styles.item}
			justifyContent="center"
			flexDirection={matches ? 'column' : 'row-reverse'}
			id={`task-${task.id}`}
		>
			<Grid item md={4} lg={4}>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					flexDirection={matches ? 'column' : 'row-reverse'}
				>
					<Grid item>
						<Avatar style={{ backgroundColor: `${task.color}` }}>
							{getLetter(task.supervisor)}
						</Avatar>
					</Grid>
					<Grid item>
						<span className={styles.labelRol}>{task.rol}</span>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={8} lg={8}>
				<Grid
					container
					alignItems="center"
					textAlign={matches ? 'center' : 'left'}
					flexDirection={matches ? 'column' : 'row'}
				>
					<Grid item>
						<p className={styles.titleItem}>{task.services}</p>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
