/* eslint-disable react-hooks/exhaustive-deps */
import CheckboxMUI from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup, Grid, styled } from '@mui/material';
import { Button as ButtonCustom } from '../../../../../components/main-button.component';
import InputSelect from '../../../../../components/select-input.component';
import Button from '@mui/material/Button';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { useStepOne } from '../hooks/useStepOne';
import { useEffect } from 'react';
import SaveButton from '../../../../../components/save-button.component';

const CheckBox = styled(CheckboxMUI)(() => ({
	color: 'var(--color-highlight-blue)',
	'&.Mui-checked': {
		color: 'var(--color-highlight-blue)',
	},
}));

const TextButton = styled(Button)(() => ({
	textTransform: 'none',
	color: '#0080F9',
}));

interface IStepOneStandars {
	functionalOptions: IOptionsMenu[];
}

export const StepOneStandars = ({ functionalOptions }: IStepOneStandars) => {
	const {
		functionalUnit,
		functionalUnitN2,
		activitie,
		activitiesOptions,
		functionalOptionsN2,
		items,
		checkedList,
		matches,
		selectAll,
		activityBase,
		functionalBase,
		functionalParentBase,
		handleSelectAllItems,
		setFunctionalUnit,
		setFunctionalUnitN2,
		setActivitie,
		navigate,
		onSelect,
		setSelectAll,
		handleSave,
		handleOnContinue,
	} = useStepOne();

	useEffect(() => {
		setFunctionalUnit(functionalOptions[0]?.value.toString() || '');
	}, [functionalOptions]);

	return (
		<Grid
			container
			marginTop={2}
			spacing={2}
			style={{
				padding: '8px 0px 0px 8px',
			}}
		>
			<Grid item xs={12} sm={6}>
				<span style={{ fontWeight: 'bold' }}>
					Elige {functionalParentBase?.name ?? 'Nivel 1'}:
				</span>
				<InputSelect
					optionsMenu={functionalOptions}
					value={functionalUnit}
					placeholder={`Selecciona ${functionalParentBase?.name ?? 'Nivel 1'}`}
					onChange={() => null}
					isDisabled
				/>
			</Grid>
			{functionalOptionsN2.length > 0 && (
				<Grid item xs={12} sm={6}>
					<span style={{ fontWeight: 'bold' }}>
						Elige {functionalBase?.name ?? 'Nivel 2'}
					</span>
					<InputSelect
						optionsMenu={functionalOptionsN2}
						value={functionalUnitN2}
						placeholder={`Selecciona ${functionalBase?.name ?? 'Nivel 2'}`}
						onChange={(e) => {
							setFunctionalUnitN2(e.target.value?.toString() || '');
						}}
						withStatus
						id="select-lvl2"
					/>
				</Grid>
			)}
			<Grid item xs={12} sm={6}>
				<span style={{ fontWeight: 'bold' }}>
					{activityBase?.name ?? 'Actividad'}:
				</span>
				<InputSelect
					optionsMenu={activitiesOptions}
					value={activitie}
					placeholder={`Selecciona ${activityBase?.name ?? 'servicio'}`}
					onChange={(e) => {
						setActivitie(e.target.value?.toString() || '');
					}}
					withStatus
					id="select-activity"
				/>
			</Grid>
			<Grid item xs={12}>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						gap: '40px',
					}}
				>
					{items.map((item, column) => (
						<Grid container key={`formGroup-${column}`}>
							<Grid item xs={10}>
								<p style={{ fontWeight: 'bold' }}>{item.servicio}</p>
							</Grid>
							<Grid item xs={2}>
								<TextButton
									sx={{ paddingLeft: 0, fontSize: '12px' }}
									onClick={() => {
										setSelectAll(!selectAll);
										handleSelectAllItems(!selectAll);
									}}
								>
									{selectAll
										? 'Deseleccionar todo'
										: 'Seleccionar todo'}
								</TextButton>
							</Grid>
							<Grid item xs={10}>
								<FormGroup
									sx={{
										display: 'flex',
										width: '100%',
										flexWrap: 'wrap',
										flexDirection: 'row',
									}}
								>
									{item.criterios.map((prop, row) => (
										<div
											key={`prop-${column}-${row}`}
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: 'fit-content',
												gap: '10px',
											}}
										>
											{prop.map((i, idx) => (
												<FormControlLabel
													sx={{ fontSize: '20px' }}
													key={`prop-item-${column}-${row}-${idx}`}
													checked={checkedList.has(i.id)}
													value={i.id}
													control={<CheckBox />}
													label={
														<p
															style={{
																fontSize: '20px',
																margin: 0,
															}}
														>
															{i.name}
														</p>
													}
													onChange={(_, checked) =>
														onSelect(i.id, checked)
													}
													id={`checkbox-${i.id}`}
												/>
											))}
										</div>
									))}
								</FormGroup>
							</Grid>
						</Grid>
					))}
				</div>
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'flex-end'}
					spacing={2}
				>
					<Grid item>
						<SaveButton
							id="uf-guardar-btn"
							disabled={checkedList.size === 0}
							onClick={() => handleSave()}
							sx={{
								height: '100%',
								width: '150px',
							}}
						/>
					</Grid>
					<Grid item>
						<ButtonCustom
							label="Cancelar"
							onClick={() => navigate(-1)}
							style={{
								backgroundColor: 'var(--color-error-main)',
								width: '150px',
							}}
						/>
					</Grid>
					<Grid item>
						<ButtonCustom
							label="Finalizar criterios"
							onClick={() => {
								//dispatch(setState({ state: 4 }));
								handleOnContinue();
							}}
							style={{
								backgroundColor: 'var(--color-highlight-blue)',
								width: '150px',
							}}
							disabled={
								functionalOptionsN2.length === 0
									? !activitiesOptions.every((e) => e.status === true)
									: !functionalOptionsN2.every((e) => e.status === true)
							}
							id="finish-indicators"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
