import { Box, Paper, Tab, Tabs } from '@mui/material';
import { useDetailEvidences } from '../../hooks/useDetailEvidences';
import styles from '../styles/supervision-detail-styles.module.css';
import { setValuePP } from '../../../../store/modules/profile-project';
import { GoBack } from './../../../../components/go-back-component';
import { useLocation } from 'react-router-dom';

export const RoutineDetailEvidence = () => {
	const location = useLocation();
	const state = location?.state?.params;
	const { currentTab, matches, tabItems, setCurrentTab, dispatch, getComponent } =
		useDetailEvidences();
	return (
		<>
			{state.planeacion ? (
				<GoBack action={() => dispatch(setValuePP({ step: 3 }))} />
			) : (
				<GoBack />
			)}
			<Tabs
				value={currentTab}
				variant="scrollable"
				onChange={(_, value) => {
					setCurrentTab(value);
				}}
				TabIndicatorProps={{
					style: { background: '#0080F9' },
				}}
				sx={{
					'& button.Mui-selected': {
						color: '#0080F9',
					},
					width: '100%',
				}}
				orientation={matches ? 'vertical' : 'horizontal'}
			>
				{(tabItems || []).map((element, index: number) => {
					return (
						<Tab
							key={index}
							className={styles.tabsStyle}
							label={element.label}
						/>
					);
				})}
			</Tabs>
			<Paper>
				<Box padding={3}>{getComponent()}</Box>
			</Paper>
		</>
	);
};
