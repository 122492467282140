import { Settings, WorkOutlineOutlined, WorkspacesOutlined } from '@mui/icons-material';
import styles from '../styles/header-general-info-styles.module.css';
import { ICard } from '../../user-profile/components/card.component';

export const cards: ICard[] = [
	{
		icon: (
			<WorkOutlineOutlined
				className={styles.iconHeader}
				style={{ color: 'rgba(255, 255, 255, 0.6)' }}
			/>
		),
		title: 'Tareas base',
		subtitle: 'Número total',
		stylesContainer: {
			backgroundColor: '#764DB1',
			borderRadius: '10px',
			color: 'white',
		},
		stylesColorFont: {},
		name: 'tareasBase',
	},
	{
		icon: (
			<WorkspacesOutlined
				className={styles.iconHeader}
				style={{ color: 'rgba(255, 255, 255, 0.6' }}
			/>
		),
		title: 'Espacio 1',
		subtitle: 'Número total',
		stylesContainer: {
			backgroundColor: '#56596A',
			borderRadius: '10px',
			color: 'white',
		},
		stylesColorFont: { color: 'white' },
		name: 'espacios1',
	},
	{
		icon: (
			<WorkspacesOutlined
				className={styles.iconHeader}
				style={{ color: 'rgba(255, 255, 255, 0.6)' }}
			/>
		),
		title: 'Espacio 2',
		subtitle: 'Número total',
		stylesContainer: {
			backgroundColor: '#C25866',
			borderRadius: '10px',
			color: 'white',
		},
		stylesColorFont: {},
		name: 'espacios2',
	},
	{
		icon: (
			<Settings
				className={styles.iconHeader}
				style={{ color: 'rgba(255, 255, 255, 0.6)' }}
			/>
		),
		title: 'Actividades',
		subtitle: 'Número total',
		stylesContainer: {
			backgroundColor: '#6D5D83',
			borderRadius: '10px',
			color: 'white',
		},
		stylesColorFont: { color: 'white' },
		name: 'totalActividades',
	},
];
