import { ModeEdit } from '@mui/icons-material';
import {
	Box,
	Divider,
	Grid,
	IconButton,
	Paper,
	SelectChangeEvent,
	useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { Button } from '../../../components/main-button.component';
import TextInput from '../../../components/text-input.component';
import theme from '../../../config/theme';
import { IUser } from '../../../interfaces/user.interface';
import { setLoading } from '../../../store/modules/loading';
import { newUserAdapter } from '../adapter/new-user-adapter';
import styles from './styles/header-user-styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getRoleName, getRoles, isAdmin } from '../../../utils/permissions.utils';
import InputSelect from '../../../components/select-input.component';

interface ICompanyInfoComponent {
	user: IUser;
	temporalData: IUser;
	setTemporalData: React.Dispatch<React.SetStateAction<IUser>>;
	handleSetData: (
		e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | number | null>
	) => void;
	updateUser: any;
}

export const CompanyInfoComponent = ({
	user,
	temporalData,
	setTemporalData,
	handleSetData,
	updateUser,
}: ICompanyInfoComponent) => {
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { role } = useSelector((state: RootState) => state.users);

	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);

	return (
		<Paper elevation={0}>
			<Box padding={matches ? 3 : 6}>
				<Grid container spacing={3}>
					<Grid item sm={12}>
						<Grid
							container
							justifyContent={matches ? 'center' : 'space-between'}
							alignItems="center"
							rowSpacing={2}
						>
							<Grid item>
								<h3 style={{ margin: 0 }}>Información en la empresa</h3>
							</Grid>
							<Grid item>
								{isEditing ? (
									<div style={{ display: 'flex', gap: 10 }}>
										<Button
											label="Cancelar"
											id="cancel-edit-company"
											onClick={() => setIsEditing(false)}
											variant="outlined"
											style={{
												color: 'var(--color-error-main)',
												width: '120px',
											}}
										/>
										<Button
											label="Guardar"
											id="save-edit-company"
											onClick={() => {
												dispatch(setLoading({ loading: true }));
												updateUser(
													{
														...newUserAdapter(temporalData),
														employeeNumber: parseInt(
															temporalData.employeeNumber.toString()
														),
													},
													`/${temporalData._id}`
												);
												setIsEditing(false);
											}}
											style={{
												width: '120px',
												backgroundColor: '#0080F9',
											}}
										/>
									</div>
								) : (
									<>
										{isAdmin(role) && (
											<IconButton
												onClick={() => {
													setIsEditing(true);
													setTemporalData({ ...user });
												}}
												aria-label="more"
												size="medium"
												id="btn-edit-company"
												className={styles.editIcon}
											>
												<ModeEdit />
											</IconButton>
										)}
									</>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid data-testid="row-position" item xs={12}>
						<Grid container spacing={matches ? 1 : 3} alignItems="center">
							<Grid item xs={12} sm={3}>
								<span className={styles.grey}>Puesto</span>
							</Grid>
							<Grid item xs={12} sm={9}>
								{isEditing ? (
									<TextInput
										id="position"
										name="position"
										placeholder="Asignar puesto"
										value={temporalData.position}
										onChange={handleSetData}
									/>
								) : (
									<span id="span-position">{user.position}</span>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid data-testid="row-role" item xs={12}>
						<Grid container spacing={matches ? 1 : 3} alignItems="center">
							<Grid item xs={12} sm={3}>
								<span className={styles.grey}>Rol</span>
							</Grid>
							<Grid item xs={12} sm={9}>
								{isEditing ? (
									<InputSelect
										backgroundColor="#FFF"
										optionsMenu={getRoles()}
										value={temporalData.role}
										height="45px"
										placeholder="Selecciona un rol"
										name="role"
										id="role"
										onChange={handleSetData}
									/>
								) : (
									<span id="span-role">{getRoleName(user.role)}</span>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={matches ? 1 : 3} alignItems="center">
							<Grid item xs={12} sm={3}>
								<span className={styles.grey}>Fecha de creación</span>
							</Grid>
							<Grid item xs={12} sm={9}>
								<span>{user.lastConnection || '-'}</span>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={matches ? 1 : 3} alignItems="center">
							<Grid item xs={12} sm={3}>
								<span className={styles.grey}>Última conexión</span>
							</Grid>
							<Grid item xs={12} sm={9}>
								<span>{user.lastConnection || '-'}</span>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
