/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { IValidateSuspension } from '../interfaces/validate-suspension.interface';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { getProjectsAdapter } from '../adapters/projects-validate.adapter';
import { useLocation } from 'react-router-dom';

export const useValidateSuspension = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.idProject as string;
	const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
	const [projectsData, setProjectsData] = useState<IAdaptedValues[]>([]);

	const {
		execute: validateSuspension,
		status: statusValidateSuspension,
		value: valueValidateSuspension,
	} = useApiRequest<unknown, IResponsePayload<IValidateSuspension>>({
		path: URLS.USER_PROJECT_MANAGEMENT.VALIDATE_SUSPENSION,
		method: 'GET',
	});

	useEffect(() => {
		if (statusValidateSuspension === 'success') {
			dispatch(stopLoading());
			setIsValid(valueValidateSuspension?.detail?.itCanBeSuspended);
			setProjectsData(
				getProjectsAdapter(valueValidateSuspension?.detail?.projects || [])
			);
		} else if (statusValidateSuspension === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueValidateSuspension?.message ||
						'Ocurrió un error validar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusValidateSuspension]);

	const handleValidate = (idUser: string) => {
		dispatch(setLoading({ loading: true }));
		if (idProject) {
			validateSuspension(null, `?user=${idUser}&project=${idProject}`);
		} else {
			validateSuspension(null, `?user=${idUser}`);
		}
	};

	return {
		projectsData,
		isValid,
		statusValidateSuspension,
		handleValidate,
	};
};
