/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../../../../config/theme';
import { useContext, useEffect, useState } from 'react';
import { useGetFunctionals } from '../../geographical-dimensions/hooks/useGetFunctionals';
import { isEmpty } from '../../../../../utils/validators';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { useGetUnitsOptions } from './useGetUnitsOptions';
import { setLoading } from '../../../../../store/modules/loading';
import { useSaveUnits } from '../../activities/hooks/useSaveUnits';
import { useSaveFunctionals } from '../../geographical-dimensions/hooks/useSaveFunctionals';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';
import { useChangeStep } from '../../../hooks/useChangeStep';
import { RootState } from '../../../../../store';

export function useStepOne() {
	const { idProject, supervisionExtraordinaryData } = useContext(
		CreateSupervisionContext
	);
	const [functionalUnit, setFunctionalUnit] = useState<string>('');
	const [functionalUnitN2, setFunctionalUnitN2] = useState<string>('');
	const [activitie, setActivitie] = useState<string>('');
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const [optionsActivitiesChanged, setOptionsActivitiesChanged] = useState<number>(0);
	const [optionsFunctionalChanged, setOptionsFunctionalChanged] = useState<number>(0);

	const { activityBase, functionalBase, functionalParentBase } = useSelector(
		(state: RootState) => state.projectBase
	);

	const {
		items,
		idsIndicators,
		checkedList,
		valueUnits,
		setItems,
		setCheckedList,
		getUnits: getIndicators,
	} = useGetUnitsOptions({
		withIndicators: true,
	});

	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { functionalOptions: functionalOptionsN2, getFunctionals } = useGetFunctionals({
		isLevelOne: false,
		getOptions: true,
	});
	const { unitsOptions: activitiesOptions, getUnits } = useGetUnitsOptions({});
	const { changeStepSE } = useChangeStep();

	const handleNewOptionsActivities = (changeUnit?: boolean) => {
		if (changeUnit === true) {
			executeSaveFunctionals(
				[
					{
						_id:
							functionalOptionsN2
								.find((e) => e.value === functionalUnitN2)
								?.id.toString() || '',
						applies: true,
						isIndicatorAssociated: true,
					},
				],
				''
			);
		}
		setOptionsActivitiesChanged(optionsActivitiesChanged + 1);
	};

	const handleNewOptionsFunctional = () => {
		setOptionsFunctionalChanged(optionsFunctionalChanged + 1);
	};

	const { executeSavUnits, statusSaveUnits } = useSaveUnits({});
	const { executeSavUnits: executeSaveActivity } = useSaveUnits({
		setOptionsChanged: handleNewOptionsActivities,
	});
	const { executeSaveFunctionals } = useSaveFunctionals({
		setOptionsChanged: handleNewOptionsFunctional,
	});

	const onSelect = (index: number | string, checked: boolean) => {
		const collection = new Set(checkedList);

		if (checked) collection.add(index);
		else collection.delete(index);

		setCheckedList(collection);
	};

	useEffect(() => {
		if (!isEmpty(functionalUnit)) {
			dispatch(setLoading({ loading: true }));
			getFunctionals(
				{},
				`?project=${idProject}&functional=${functionalUnit}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&applies=true`
			);
		}
	}, [functionalUnit, optionsFunctionalChanged]);

	useEffect(() => {
		if (!isEmpty(functionalUnitN2)) {
			setSelectAll(false);
			setActivitie('');
			setCheckedList(new Set());
			setItems([]);
			dispatch(setLoading({ loading: true }));
			getUnits(
				{},
				`?project=${idProject}&functional=${functionalUnitN2}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&applies=true&type=U-A`
			);
		}
	}, [functionalUnitN2]);

	useEffect(() => {
		if (functionalOptionsN2.length === 0 && !isEmpty(functionalUnit)) {
			dispatch(setLoading({ loading: true }));
			getUnits(
				{},
				`?project=${idProject}&functional=${functionalUnit}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&applies=true&type=U-A`
			);
		} else if (!isEmpty(functionalUnitN2)) {
			dispatch(setLoading({ loading: true }));
			getUnits(
				{},
				`?project=${idProject}&functional=${functionalUnitN2}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&applies=true&type=U-A`
			);
		}
	}, [optionsActivitiesChanged]);

	useEffect(() => {
		if (!isEmpty(activitie)) {
			setSelectAll(false);
			setCheckedList(new Set());
			dispatch(setLoading({ loading: true }));
			getIndicators(
				{},
				`?project=${idProject}&functional=${functionalUnitN2}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&activity=${activitie}&type=U-I`
			);
		}
	}, [activitie]);

	useEffect(() => {
		if (statusSaveUnits === 'success') {
			executeSaveActivity(
				[
					{
						_id:
							activitiesOptions
								.find((e) => e.value === activitie)
								?.id.toString() || '',
						applies: true,
						isIndicatorAssociated: true,
					},
				],
				''
			);
		}
	}, [statusSaveUnits]);

	const handleSelectAllItems = (isSelected: boolean) => {
		if (!isSelected) {
			setCheckedList(new Set());
		} else {
			setCheckedList(idsIndicators);
		}
	};

	const handleSave = () => {
		const adaptedRequest =
			valueUnits?.data.map((e) => {
				return { _id: e._id, applies: checkedList.has(e._id) };
			}) || [];
		executeSavUnits(adaptedRequest, '');
	};

	const handleOnContinue = () => {
		dispatch(setLoading({ loading: true }));
		changeStepSE(
			{
				newStep: STEPS_SUPERVISION_EXTRAORDINARY.PER_1,
				extraordinarySupervision:
					supervisionExtraordinaryData.idSupervisionExtraordinary,
			},
			`/${idProject}`
		);
	};

	return {
		functionalUnit,
		functionalUnitN2,
		activitie,
		activitiesOptions,
		functionalOptionsN2,
		items,
		checkedList,
		matches,
		selectAll,
		activityBase,
		functionalBase,
		functionalParentBase,
		handleSelectAllItems,
		setFunctionalUnit,
		setFunctionalUnitN2,
		setActivitie,
		navigate,
		onSelect,
		setSelectAll,
		handleSave,
		handleOnContinue,
	};
}
