import { IColumns } from '../../../../../hooks/useTableCustom';

export const columns: IColumns[] = [
	{
		text: 'Columna',
		nameID: 'column',
	},
	{
		text: 'Espacios en blanco',
		nameID: 'blankSpaces',
	},
	{
		text: 'Advertencias',
		nameID: 'warnings',
	},
	{
		text: 'Errores',
		nameID: 'errors',
	},
];
