import { IMenuList, styleIcon, styleIconContainer } from '../utils/resources.utils';
import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/svg/projects.svg';
import { ReactComponent as DevicesIcon } from '../../assets/svg/devices.svg';

import Home from '../../pages/home';
import { UserProfile } from '../../pages/user-profile';
import { SvgIcon } from '@mui/material';
import { ProjectProfile } from '../../pages/profile-project';
import { SupervisionHistory } from '../../pages/supervision-history';
import NotificationList from '../../pages/notifications';

export const RouteExternalList: IMenuList[] = [
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Inicio',
		link: '/inicio',
		Component: Home,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Mi Perfil',
		link: '/mi-perfil',
		Component: UserProfile,
		hideMenu: true,
	},
	{
		icon: (
			<SvgIcon>
				<ProjectsIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Perfil de proyecto',
		link: '/perfil-proyecto',
		hideMenu: true,
		Component: ProjectProfile,
		submenu: [
			{
				title: 'Historial de supervisión',
				link: '/historial-supervision',
				Component: SupervisionHistory,
				hideMenu: true,
			},
		],
	},
	{
		icon: (
			<SvgIcon>
				<DevicesIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Notificaciones',
		link: '/notificaciones',
		Component: NotificationList,
		hideMenu: true,
	},
];
