/* eslint-disable react-hooks/exhaustive-deps */
import theme from '../../../config/theme';
import { Edit, PlayArrow, RemoveRedEye } from '@mui/icons-material';
import styles from './styles/supervision-detail-styles.module.css';
import { Box, Grid, IconButton, Paper, Tooltip, useMediaQuery } from '@mui/material';
import TableCustom from '../../../components/table-custom.component';
import { columnsActions, columnsReports } from '../utils/table-columns.utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setState } from '../../../store/modules/steps';
import { GoBack } from '../../../components/go-back-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSupervisionHistorySummary } from '../hooks/useSupervisionHistorySummary';
import { IRoutine } from '../../../interfaces/logic.interface';
import { Button } from './../../../components/main-button.component';
import { STATUS_ROUTINE } from '../../../constants/status-routine';
import { isExternal } from '../../../utils/permissions.utils';
import { RootState } from '../../../store';
import { setValuePP } from '../../../store/modules/profile-project';

interface IRoutineDetail {
	canBeExecuted?: boolean;
	routine?: IRoutine;
}

export const SupervisionHistoryDetail = ({ canBeExecuted, routine }: IRoutineDetail) => {
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location?.state?.params;

	const {
		dataAction,
		setDataAction,
		dataActivities,
		setDataActivities,
		//dataEvidences,
		//setDataEvidences,
		executeGetHistory,
		dataReports,
		setDataReports,
		dataRoutine,
		columnsActivities,
	} = useSupervisionHistorySummary();
	const { role } = useSelector((state: RootState) => state.users);

	useEffect(() => {
		executeGetHistory({}, `/${routine?.routine || state?.routine}`);
		if (state.routine === undefined)
			location.state.params = {
				...location.state.params,
				routine: routine?.routine,
			};
	}, []);

	return (
		<>
			{state.planeacion ? (
				<GoBack action={() => dispatch(setValuePP({ step: 0 }))} />
			) : (
				<GoBack />
			)}
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Grid
								container
								justifyContent={matches ? 'center' : 'space-between'}
								alignItems="center"
							>
								<Grid item>
									<span
										style={{ fontSize: '21px', fontWeight: 'bold' }}
									>
										Historial de supervisión
									</span>
									<br />
								</Grid>
								<Grid item>
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										spacing={2}
									>
										<Grid item>
											<Button
												icon={RemoveRedEye}
												label="Ver evidencias de supervisión"
												onClick={() =>
													state.planeacion
														? dispatch(
																setValuePP({ step: 4 })
														  )
														: dispatch(setState({ state: 4 }))
												}
												style={{
													backgroundColor: '#0080F9',
													fontSize: 15,
												}}
												id="view-evidences"
											/>
										</Grid>

										{dataRoutine.status ===
											STATUS_ROUTINE.ACCOMPLISHED &&
											!isExternal(role) && (
												<Grid item>
													<IconButton
														onClick={() => {
															navigate(
																'/perfil-proyecto/supervision',
																{
																	state: {
																		routine:
																			dataRoutine.routine,
																		supervisor:
																			dataRoutine.supervisor,
																		status: dataRoutine.status,
																		description:
																			dataRoutine.description,
																		idProject:
																			dataRoutine.idProject,
																		isEdit: true,
																		supervisorName:
																			dataRoutine.supervisorName,
																	},
																}
															);
														}}
														aria-label="edit"
														size="medium"
														className={
															styles.iconEditSupervision
														}
													>
														<Tooltip title="Editar supervisión">
															<Edit />
														</Tooltip>
													</IconButton>
												</Grid>
											)}
										{(dataRoutine.status ===
											STATUS_ROUTINE.PENDING_TO_EXECUTE ||
											dataRoutine.status ===
												STATUS_ROUTINE.EXECUTING) &&
											canBeExecuted &&
											!isExternal(role) && (
												<Grid item>
													<IconButton
														onClick={() => {
															navigate(
																'/perfil-proyecto/supervision',
																{
																	state: {
																		routine:
																			dataRoutine.routine,
																		supervisor:
																			dataRoutine.supervisor,
																		status: dataRoutine.status,
																		description:
																			dataRoutine.description,
																		idProject:
																			dataRoutine.idProject,
																		supervisorName:
																			dataRoutine.supervisorName,
																	},
																}
															);
														}}
														aria-label="continue"
														size="medium"
														className={
															styles.iconEditSupervision
														}
													>
														<Tooltip title="Continuar supervisión">
															<PlayArrow />
														</Tooltip>
													</IconButton>
												</Grid>
											)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} />
						<Grid item md={12} lg={6} style={{ overflow: 'auto' }}>
							<TableCustom
								titleHeader={
									<span style={{ fontSize: '17px', color: '#707070' }}>
										{state?.routine?.routineDescription
											? state?.routine?.routineDescription
											: routine?.description}
									</span>
								}
								columns={columnsActions}
								data={dataAction}
								setData={setDataAction}
								buttons={[
									{
										icon: RemoveRedEye,
										label: 'Ver todas',
										execute: () =>
											state.planeacion
												? dispatch(setValuePP({ step: 5 }))
												: dispatch(setState({ state: 5 })),
										type: 'blue',
										variant: 'outlined',
										width: 150,
										id: 'view-all-action',
									},
								]}
								showSearch={false}
								colorColumns
							/>
						</Grid>
						<Grid item md={12} lg={6} style={{ overflow: 'auto' }}>
							<TableCustom
								titleHeader={
									<span className={styles.titleTable}>Reportes</span>
								}
								columns={columnsReports}
								data={dataReports}
								setData={setDataReports}
								buttons={[
									{
										icon: RemoveRedEye,
										label: 'Ver todas',
										execute: () =>
											state.planeacion
												? dispatch(setValuePP({ step: 6 }))
												: dispatch(setState({ state: 6 })),
										type: 'blue',
										variant: 'outlined',
										width: 150,
										id: 'view-all-reports',
									},
								]}
								showSearch={false}
								colorColumns
							/>
						</Grid>
						<Grid item md={12} lg={6} style={{ overflow: 'auto' }}>
							<TableCustom
								titleHeader={
									<span className={styles.titleTable}>
										Actividades y espacios supervisados
									</span>
								}
								columns={columnsActivities}
								data={dataActivities}
								setData={setDataActivities}
								buttons={[
									{
										icon: RemoveRedEye,
										label: 'Ver todas',
										execute: () =>
											state.planeacion
												? dispatch(setValuePP({ step: 7 }))
												: dispatch(setState({ state: 7 })),
										type: 'blue',
										variant: 'outlined',
										width: 150,
										id: 'view-all-activities',
									},
								]}
								showSearch={false}
								colorColumns
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
