/*
 *
 * SIN USAR AL MOMENTO, TODO: ELIMINAR EN CASO DE NO NECESITARSE EN EL FLUJO
 *
 *  */

import { Grid } from '@mui/material';
import { Button } from '../../../../../components/main-button.component';
import { useStepTwo } from '../hooks/useStepTwo';
import InputSelect from '../../../../../components/select-input.component';
import Collapsable from '../../../../../components/collapsable.component';
import SaveButton from '../../../../../components/save-button.component';
import { isEmpty } from 'lodash';

export const StepTwoAct = () => {
	const {
		criteriosTable,
		matches,
		activitiesOptions,
		currentService,
		elementsChecked,
		navigate,
		handleCheck,
		handleCheckColumn,
		setCurrentService,
		handleOnSave,
		handleOnContinue,
	} = useStepTwo();

	return (
		<Grid
			container
			marginTop={2}
			spacing={2}
			style={{
				padding: '8px 0px 0px 8px',
			}}
			alignItems="center"
		>
			<Grid
				container
				marginTop={2}
				style={{
					padding: '8px 0px 0px 8px',
				}}
			>
				<Grid item xs={12}>
					<InputSelect
						optionsMenu={activitiesOptions}
						value={currentService}
						placeholder="Selecciona un servicio"
						onChange={(e) => {
							setCurrentService(e.target.value?.toString() || '');
						}}
						withStatus
						id={'select-service'}
					/>
				</Grid>
			</Grid>
			{criteriosTable.map((element) => {
				return (
					<Collapsable
						key={`collapsable-${element.id}`}
						activeElements={elementsChecked}
						id={element.id}
						items={element.items}
						column={1}
						isComplete={element.isComplete}
						name={'a'}
						value={element.value}
						onCheck={(UF, EF, check) => handleCheck(0, UF, EF, check)}
						onSelectColumn={handleCheckColumn}
					/>
				);
			})}

			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'flex-end'}
					spacing={2}
				>
					<Grid item>
						<SaveButton
							id="uf-guardar-btn"
							disabled={isEmpty(currentService)}
							onClick={() => handleOnSave()}
							sx={{ height: '100%', width: '150px' }}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Cancelar"
							onClick={() => navigate(-1)}
							style={{
								backgroundColor: 'var(--color-error-main)',
								width: '150px',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Finalizar actividades"
							onClick={() => {
								//dispatch(setState({ state: 2 }));
								handleOnContinue();
							}}
							style={{
								backgroundColor: 'var(--color-highlight-blue)',
							}}
							disabled={!activitiesOptions.every((e) => e.status === true)}
							id="finish-activity"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
