/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../../../../config/theme';
import { useContext, useEffect, useState } from 'react';
import { useChangeStep } from '../../../hooks/useChangeStep';
import { setLoading } from '../../../../../store/modules/loading';
import { useGetPersonalByProject } from '../../../../../hooks/usePersonalByProject';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';

export function useStepOne() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	const [personalSelected, setPersonalSelected] = useState('');

	const { idProject, supervisionExtraordinaryData } = useContext(
		CreateSupervisionContext
	);

	const { changeStepSE } = useChangeStep();
	const {
		getTotalUsers: getPersonal,
		usersData: personalOptions,
		getIsTeam,
	} = useGetPersonalByProject({ addTeams: true });

	useEffect(() => {
		getPersonal();
	}, []);

	const handleOnContinue = () => {
		const isTeam = getIsTeam(personalSelected);
		dispatch(setLoading({ loading: true }));
		changeStepSE(
			{
				newStep: STEPS_SUPERVISION_EXTRAORDINARY.CRO_1,
				extraordinarySupervision:
					supervisionExtraordinaryData.idSupervisionExtraordinary,
				supervisor: isTeam ? null : personalSelected,
				team: isTeam ? personalSelected : null,
			},
			`/${idProject}`
		);
	};

	return {
		matches,
		personalSelected,
		personalOptions,
		navigate,
		setPersonalSelected,
		handleOnContinue,
	};
}
