import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import styles from './styles/action-styles.component.module.css';

interface IActionComponent {
	icon: (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }) | null;
	label: string;
	title?: string;
	subtitle?: string;
	stylesContainerIcon?: React.CSSProperties;
	onClick: () => void;
}

export const ActionComponent = (props: IActionComponent) => {
	const { icon: Icon, label, title, subtitle, stylesContainerIcon, onClick } = props;

	return (
		<div className={styles.actionContainer} onClick={onClick}>
			<div
				className={styles.actionIconContainer}
				style={{ ...stylesContainerIcon }}
			>
				<div>
					{Icon && (
						<Icon
							data-testid={`button-${label
								.toLowerCase()
								.replace(/ /g, '-')}`}
							className={styles.actionIcon}
						/>
					)}
				</div>
				<div>{label}</div>
			</div>
			{title && <div className={styles.actionTitle}>{title}</div>}
			{subtitle && <div className={styles.actionSubtitle}>{subtitle}</div>}
		</div>
	);
};
