/* eslint-disable react-hooks/exhaustive-deps */
import {
	SupervisorAccount,
	Assignment,
	MyLocationOutlined,
	CalendarMonthOutlined,
} from '@mui/icons-material';
import { useState, useEffect, useContext, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../../components/main-button.component';
import InputSelect from '../../../../../components/select-input.component';
import InputSelectMultiple from '../../../../../components/select-multiple.component';
import { NewReportKeys } from '../interfaces/exportPlanning.interface';
import styles from '../../reports/components/styles/generate-report-styles.module.css';
import { useGetDataFilters } from '../../reports/hooks/useGetDataFilters';
import { setLoading } from '../../../../../store/modules/loading';
import { datePickers } from '../utils/days.utils';
import TextInput from '../../../../../components/text-input.component';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';
import { useDownloadPlanning } from '../hooks/useDownloadPlanning';
import { months } from '../utils/months.utils';
import { useExportPlanning } from '../hooks/useExportPlanning';
import { Grid } from '@mui/material';
import { endOfDay, formatISO, startOfDay, parseISO } from 'date-fns';

const ExportPlanning = (supervisors: any) => {
	const dispatch = useDispatch();
	const { data: dataProject } = useContext(ProfileProjectContext);
	const [period, setPeriod] = useState<string>('');
	const [supervisorsExport, setsupervisorsExport] = useState<any>([]);
	const [dataSupervisors, setDataSupervisors] = useState<any>([]);
	const [dataActivities, setDataActivities] = useState<any>([]);
	const { executeReportPlanning } = useDownloadPlanning(period);
	const { executeGetLevels, levels } = useExportPlanning();

	const {
		units,
		fuctionals,
		actBySupervisor,
		executeGetFuctionals,
		executeGetFuctionals2,
		getActivitiesBySupervisor,
	} = useGetDataFilters();

	const [data, setData] = useState({
		supervisor: [],
		actividad: [],
		unidadFuncional: '',
		espacioFuncional: '',
		selectAllSupervisors: false,
		selectAllActivitie: false,
		selectAllNivel1: false,
		selectAllNivel2: false,
		date1: new Date().toISOString(),
		date2: new Date().toISOString(),
	});

	useEffect(() => {
		setsupervisorsExport(
			supervisors?.supervisors?.filter((e: any) => e.value !== 'all')
		);
		executeGetLevels({}, `/${dataProject?._id}`);
		executeGetFuctionals({}, `?project=${dataProject?._id}&isFunctionalParent=1`);
	}, []);

	const handleChange = (e: any, property: NewReportKeys) => {
		(data[property] as any) = e.target.value;
		if (property === 'supervisor') {
			const selecSupervisors = e.target.value.map((option: any) => option.value);
			setDataSupervisors(selecSupervisors);
			getActivitiesBySupervisor(
				{},
				`?project=${dataProject?._id}&supervisors[]=${selecSupervisors.join(
					'&supervisors[]='
				)}`
			);
		}

		if (property === 'actividad') {
			const selecActivities = e.target.value.map((option: any) => option.value);
			setDataActivities(selecActivities);
		}

		if (property === 'unidadFuncional')
			executeGetFuctionals2(
				{},
				`?project=${dataProject?._id}&functionalParent=${e.target.value}`
			);

		if (property === 'selectAllSupervisors') {
			data.selectAllSupervisors = e.target.checked;
			if (e.target.checked) data.supervisor = [];
		}

		if (property === 'selectAllActivitie') {
			data.selectAllActivitie = e.target.checked;
			if (e.target.checked) data.actividad = [];
		}

		if (property === 'selectAllNivel1') {
			data.selectAllNivel1 = e.target.checked;
			if (e.target.checked) data.unidadFuncional = '';
		}
		if (property === 'selectAllNivel2') {
			data.selectAllNivel2 = e.target.checked;
			if (e.target.checked) data.espacioFuncional = '';
		}

		setData({ ...data });
	};

	const getValue = (property: NewReportKeys): string => {
		return (data[property] || '').toString();
	};

	const transformMonth = (month: number): string => {
		return months[month];
	};

	const downloadPlanning = () => {
		dispatch(setLoading({ loading: true }));
		setPeriod(
			new Date(data.date1).getDate() +
				' ' +
				transformMonth(new Date(data.date1).getMonth()) +
				' - ' +
				new Date(data.date2).getDate() +
				' ' +
				transformMonth(new Date(data.date2).getMonth())
		);

		const dataPlanning = {
			reportFormat: 'excel',
			reportType: 'supervision-calendar',
			project: dataProject._id,
			supervisors: data.supervisor.length > 0 ? dataSupervisors : undefined,
			activities: data.actividad.length > 0 ? dataActivities : undefined,
			functionalParent: data.unidadFuncional ? data.unidadFuncional : undefined,
			functional: data.espacioFuncional ? data.espacioFuncional : undefined,
			executionDateFrom: formatISO(startOfDay(new Date(data.date1))),
			executionDateTo: formatISO(endOfDay(new Date(data.date2))),
		};
		executeReportPlanning(dataPlanning, '');
	};

	return (
		<Grid container padding="10px" columnSpacing={2} rowSpacing={3}>
			<Grid item xs={12}>
				<span
					style={{
						fontSize: '17px',
						color: '#A1A5B6',
						position: 'relative',
						top: '-15px',
					}}
				>
					Para adquirir una planeación siga los siguientes pasos:
				</span>
			</Grid>
			<Grid item xs={2}>
				<SupervisorAccount
					className={styles.selectsIcon}
					style={{
						position: 'relative',
						bottom: '-25px',
					}}
				/>
			</Grid>
			<Grid item xs={10} md={10}>
				<span
					style={{
						position: 'relative',
						top: '-13px',
						color: 'var(--color-highlight-blue)',
					}}
				>
					Seleccionar todos
					<input
						type="checkbox"
						id="selectAllSupervisors"
						onChange={(e) => handleChange(e, 'selectAllSupervisors')}
					/>
				</span>
				<span
					style={{
						position: 'relative',
						top: '-10px',
					}}
				>
					<InputSelectMultiple
						value={data.supervisor}
						placeholder="Supervisor"
						onChange={(e) => handleChange(e, 'supervisor')}
						optionsMenu={supervisorsExport}
						isDisabled={data.selectAllSupervisors}
					/>
				</span>
			</Grid>
			<Grid item xs={2}>
				<Assignment
					className={styles.selectsIcon}
					style={{
						position: 'relative',
						bottom: '-25px',
					}}
				/>
			</Grid>
			<Grid item xs={10} md={10}>
				<span
					style={{
						position: 'relative',
						top: '-13px',
						color: 'var(--color-highlight-blue)',
					}}
				>
					Seleccionar todos
					<input
						type="checkbox"
						id="selectAllActivitie"
						onChange={(e) => handleChange(e, 'selectAllActivitie')}
					/>
				</span>
				<span
					style={{
						position: 'relative',
						top: '-10px',
					}}
				>
					<InputSelectMultiple
						value={data.actividad}
						placeholder="Actividad"
						onChange={(e) => handleChange(e, 'actividad')}
						optionsMenu={actBySupervisor}
						isDisabled={data.selectAllActivitie}
					/>
				</span>
			</Grid>

			<Grid item xs={2}>
				<MyLocationOutlined
					className={styles.selectsIcon}
					style={{
						position: 'relative',
						bottom: '-20px',
					}}
				/>
			</Grid>
			<Grid item xs={5} md={5}>
				<span
					style={{
						position: 'relative',
						top: '-15px',
						color: 'var(--color-highlight-blue)',
					}}
				>
					Seleccionar todos
					<input
						type="checkbox"
						id="selectAllNivel1"
						onChange={(e) => handleChange(e, 'selectAllNivel1')}
					/>
				</span>
				<span
					style={{
						position: 'relative',
						top: '-13px',
					}}
				>
					<InputSelect
						value={data.unidadFuncional}
						placeholder={levels?.[0]}
						onChange={(e) => handleChange(e, 'unidadFuncional')}
						optionsMenu={units}
						isDisabled={data.selectAllNivel1}
					/>
				</span>
			</Grid>

			<Grid item xs={5} md={5}>
				<span
					style={{
						position: 'relative',
						top: '-15px',
						color: 'var(--color-highlight-blue)',
					}}
				>
					Seleccionar todos
					<input
						type="checkbox"
						id="selectAllNivel2"
						onChange={(e) => handleChange(e, 'selectAllNivel2')}
					/>
				</span>
				<span
					style={{
						position: 'relative',
						top: '-13px',
					}}
				>
					<InputSelect
						value={data.espacioFuncional}
						placeholder={levels?.[1]}
						onChange={(e) => handleChange(e, 'espacioFuncional')}
						optionsMenu={fuctionals}
						isDisabled={data.selectAllNivel2}
					/>
				</span>
			</Grid>

			<Grid item xs={2}>
				<CalendarMonthOutlined className={styles.selectsIcon} />
			</Grid>
			<Grid item xs={10} md={10}>
				<div className={styles.selectsContainer}>
					{(datePickers || []).map((e: any) => (
						<Fragment key={e.id}>
							<Grid component="div" item xs={12}>
								<TextInput
									key={e.id}
									id={e.id}
									data-testid={e.id}
									name={e.name}
									label={e.label}
									placeholder={e.placeholder}
									value={parseISO(getValue(e.name))}
									type={e.type}
									size="medium"
									onChange={(newValue: any) => {
										try {
											handleChange(
												{
													target: {
														name: e.name,
														value: newValue.toISOString(),
													},
												} as unknown as React.ChangeEvent<HTMLInputElement>,
												e.name
											);
										} catch (error) {
											// eslint-disable-next-line no-console
											console.log(error);
										}
									}}
									which="datePicker"
									sx={{
										backgroundColor: '#F7F7F7',
									}}
									inputFormat={e.inputFormat}
								/>
							</Grid>
						</Fragment>
					))}
				</div>
			</Grid>
			<Grid item xs={12} md={12}>
				<Button
					label="Generar planeación"
					onClick={() => downloadPlanning()}
					style={{
						backgroundColor: '#0080F9',
					}}
				/>
			</Grid>
		</Grid>
	);
};
export default ExportPlanning;
