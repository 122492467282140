/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { ICreationPage } from '../../interfaces/creation-page-interfaces';
import CreationPage from '../../components/creation-page.component';
import { DefaultTabItems } from './adapters/data.adapters';
import { estadoCreacion } from '../../constants/estado-creacion';
//CREADOR FRECUENCIAS
import CreacionFrecuencias from './components/creacion-frecuencia/step-two.component';
//GENERALES
import FrecuenciaServiciosOne from './components/frecuencia-servicios/step-one.component';
//ESPECIFICOS
import FrecuenciaUnidadOne from './components/frecuencia-unidad/step-one.component';
import FrecuenciaUnidadTwo from './components/frecuencia-unidad/step-two.component';

import Hider from '../../components/hider.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setLoading, stopLoading } from '../../store/modules/loading';
import { useNavigate } from 'react-router-dom';
import { ITable } from '../../interfaces/criterios.interface';
import DiasNolaborales from './components/creacion-frecuencia/dias-no-laborales';
import StartDateModal from '../../components/select-date-modal';
import {
	creacionFrecuenciaAdapter,
	creacionNonWorkingDays,
	sendACT_UFAdapter,
	sendFreqAdapterActivities,
	updateFreqACT_EFAdapter,
} from '../../adapters/step-two.adapter';
import useCronograma from './hooks/useCronograma';
import { IStepTwoModel } from '../../interfaces/stepTwo.interface';
import { uniqueId } from '../../utils/uniqueId';
import { IOptionsMenu } from '../../interfaces/select.interface';
import { isEmpty } from 'lodash';
import useOptions from './hooks/useOptions';
import useUpdateProject from '../../hooks/useUpdateProject';
import useUpdateFunctionalUnit from '../dimensiones-geograficas/hooks/useUpdateFunctionalUnit';

const Index = () => {
	const dispatch = useDispatch<any>();
	const navigate = useNavigate();
	const [isSave, setIsSave] = useState(false);
	const { step, _id: projectID } = useSelector((root: RootState) => root.projectBase);
	const [isDetalle, setIsDetalle] = useState(false); //false
	const [activitiesBase, setActivitiesBase] = useState<string[]>([]);
	const [activities, setActivities] = useState<IStepTwoModel[]>([]);
	const [activitiesUF, setActivitiesUF] = useState<IStepTwoModel[]>([]);
	const [FrequencyOptions, setFrequencyOptions] = useState<IOptionsMenu[]>([]);
	const [UFOptions, setUFOptions] = useState<IOptionsMenu[]>([]);
	//Freq-Unidad
	const [currentACT, setCurrentACT] = useState<string | null>(null);
	//Indicator
	const [UFIOptions, setUFIOptions] = useState<IOptionsMenu[]>([]);
	const [EFIOptions, setEFIOptions] = useState<IOptionsMenu[]>([]);
	const [ACTIOptions, setACTIOptions] = useState<IOptionsMenu[]>([]);
	const [currentUFI, setCurrentUFI] = useState<string>('');
	const [currentEFI, setCurrentEFI] = useState<string>('');
	const [currentACTI, setCurrentACTI] = useState<string>('');
	const [IndicatorList, setIndicatorList] = useState<IStepTwoModel[]>([]);
	const [currentIndicatorList, setCurrentIndicatorList] = useState<string[]>([]);
	const [currentTab, setCurrentTab] = useState<number>(0);
	const [defaultCreate, setDefaultCreate] = useState<{
		selectedDays: Set<number | string>;
		days: ITable[];
	} | null>(null);
	//ISDONE
	const [isCreacionDone, setIsCreacionDone] = useState(false);
	const [isCreacionModal, setIsCreacionModal] = useState(false);
	const [isAplicacionModal, setIsAplicacionModal] = useState(false);
	const [isFreqActDone, setIsFreqActDone] = useState(false);
	const [isFreqActUnitDone, setIsFreqActUnitDone] = useState(false);
	const [isFreqActUnitSpaceDone, setIsFreqActUnitSpaceDone] = useState(false);
	const { handleUpdateProject } = useUpdateProject();
	const { handleUpdateFunctionalUnit, status: statusUpdateFunctionalUnit } =
		useUpdateFunctionalUnit();

	const {
		//GET
		getFreq,
		getValueFreq,
		//UPDATE
		updateFreq,
		updatedStatusFreq,
		//NON-WORKING-DAYS
		updateNonWorkingDays,
		updatedStatusNonWorkingDays,
		//ACTIVITY
		getActivity,
		valueActivity,
		//UPDATE ACTIVITIES
		updateActivity,
		updatedStatusActivity,
		//UF
		getUF,
		//ACT-UF
		getACT_UF,
		valueGetACT_UF,
		//UPDATE-ACT-UF
		updatedACT_UF,
		statusUpdatedACT_UF,
		valueUpdatedACT_UF,
		updateProject,
		statusUpdateProject,
		//GETNONWORKING
		getStatusNonWorkingDays,
		getNonWorkingDays,
		getValueNonWorkingDays,
	} = useCronograma({
		setUFOptions,
	});

	const {
		handleGetUF_UE,
		updateIndicators,
		updatedStatusIndicator,
		getCurrentIndicators,
		isLevelTwo,
	} = useOptions({
		currentACTI,
		currentEFI,
		currentUFI,
		setACTIOptions,
		setCurrentACTI,
		setCurrentEFI,
		setCurrentUFI,
		setEFIOptions,
		setIndicatorList,
		setUFIOptions,
		//Handle
		setCurrentIndicatorID: setCurrentIndicatorList,
	});

	useEffect(() => {
		const _url = `?project=${projectID}`;
		dispatch(setLoading({ loading: true }));
		getActivity(null, _url);
		getFreq(null, _url);
		getNonWorkingDays(null, _url);
		getUF(null, _url);
	}, []);

	useEffect(() => {
		if (getStatusNonWorkingDays === 'success') {
			dispatch(stopLoading());
			if (!getValueNonWorkingDays || getValueNonWorkingDays.data.length < 1) return;
			const responseData = getValueNonWorkingDays.data[0];
			const specificDays: ITable[] = responseData.specificDays.map((i: any) => {
				const date = new Date(i);
				const formattedDate2 = date
					.toISOString()
					.substring(0, 10)
					.replace(/-/g, '/');
				const [_, mm, dd] = formattedDate2.split('/');
				return {
					_id: responseData._id,
					id: uniqueId(),
					label: 'Día no laborable',
					isComplete: true,
					rows: [
						{
							key: 'day',
							value: dd,
						},
						{
							key: 'month',
							value: Number(mm),
						},
					],
				};
			});
			setDefaultCreate({
				days: specificDays,
				selectedDays: new Set(responseData.days),
			});
		} else if (getStatusNonWorkingDays === 'error') dispatch(stopLoading());
	}, [getStatusNonWorkingDays]);

	useEffect(() => {
		if (step === '5.0.1') {
			setIsCreacionModal(true);
		}
		if (step === '5.1.0') {
			setCurrentTab(1);
			setIsCreacionModal(true);
			setIsCreacionDone(true);
		}
		if (step === '5.1.1') {
			setIsCreacionDone(true);
			setIsCreacionModal(true);
			setCurrentTab(2);
			setIsFreqActDone(true);
		}
		if (step === '5.2.0') {
			setIsCreacionDone(true);
			setIsCreacionModal(true);
			//getUF();
			setIsDetalle(true);
			handleGetUF_UE(); //handle
			setCurrentTab(2);
			setIsFreqActDone(true);
			setIsFreqActUnitDone(true);
		}
		if (step === '5.2.1') {
			setIsCreacionDone(true);
			setIsCreacionModal(true);
			setIsFreqActDone(true);
			setCurrentTab(2);
			setIsFreqActUnitDone(true);
			setIsFreqActUnitSpaceDone(true);
		}
	}, [step]);

	useEffect(() => {
		if (!getValueFreq || getValueFreq.data.length < 1) return;
		setFrequencyOptions(
			getValueFreq.data.map((i) => ({
				id: i._id,
				label: i.name,
				value: i._id,
			}))
		);
	}, [getValueFreq]);

	useEffect(() => {
		if (statusUpdateFunctionalUnit === 'success') {
			getUF(null, `?project=${projectID}`);
		}
	}, [statusUpdateFunctionalUnit]);

	useEffect(() => {
		if (!valueActivity || valueActivity.data.length < 1) return;
		const _ids: string[] = [];
		const _copy: IStepTwoModel[] = [];
		valueActivity.data.forEach((i) => {
			_ids.push(i._id);
			const _freq = isEmpty(i.frequency) ? '' : i.frequency;
			_copy.push({
				_id: i._id,
				attributes: [...i.attributes, 'freq'],
				columns: [...i.columns, _freq],
				id: uniqueId(),
				isComplete: false,
				mainAttribute: i.mainAttribute,
				name: i.name,
			});
		});
		setActivities(_copy);
		setActivitiesBase(_ids);
	}, [valueActivity]);

	useEffect(() => {
		if (updatedStatusFreq === 'success') {
			setIsCreacionModal(true);
			handleUpdateProject({ step: '5.0.1' }, `${projectID}`);
			const _url = `?project=${projectID}`;
			getFreq(null, _url);
		} else if (updatedStatusFreq === 'error') dispatch(stopLoading());
	}, [updatedStatusFreq]);

	useEffect(() => {
		if (updatedStatusNonWorkingDays === 'success') {
			dispatch(stopLoading());
			setCurrentTab(1);
			handleUpdateProject({ step: '5.1.0' }, `${projectID}`);
		} else if (updatedStatusNonWorkingDays === 'error') dispatch(stopLoading());
	}, [updatedStatusNonWorkingDays]);

	useEffect(() => {
		if (updatedStatusActivity === 'success') {
			dispatch(stopLoading());
			if (!isSave) {
				setCurrentTab(2);
				getUF(null, `?project=${projectID}`);
				handleUpdateProject({ step: '5.1.1' }, `${projectID}`);
				getACT_UF(
					null,
					`?project=${projectID}&functional=${currentACT}&type=U-A&populate=1&applies=true`
				);
			}
		} else if (updatedStatusActivity === 'error') dispatch(stopLoading());
	}, [updatedStatusActivity]);

	useEffect(() => {
		if (statusUpdatedACT_UF === 'success') {
			dispatch(stopLoading());
			if (!isSave) {
				handleUpdateProject({ step: '5.2.0' }, `${projectID}`);
			}
			if ((valueUpdatedACT_UF?.data || []).length > 0) {
				handleUpdateFunctionalUnit(
					{
						isFrequencyAssociated: true,
					},
					valueUpdatedACT_UF?.data[0]?.functional
				);
			}
		} else if (statusUpdatedACT_UF === 'error') dispatch(stopLoading());
	}, [statusUpdatedACT_UF]);

	useEffect(() => {
		if (updatedStatusIndicator === 'success') {
			dispatch(stopLoading());
			getCurrentIndicators(currentACTI);
			//if (step === '5.2.0') {
			if (!isSave) {
				setIsAplicacionModal(true);
				handleUpdateProject({ step: '5.2.1' }, `${projectID}`);
			}
			//}
		} else if (updatedStatusIndicator === 'error') dispatch(stopLoading());
	}, [updatedStatusIndicator]);

	useEffect(() => {
		if (statusUpdateProject === 'success') {
			dispatch(stopLoading());
			handleUpdateProject({ step: 'x' }, `${projectID}`);
			navigate('/nuevo-proyecto/proyecto-creado');
		} else if (statusUpdateProject === 'error') dispatch(stopLoading());
	}, [statusUpdateProject]);

	useEffect(() => {
		if (!valueGetACT_UF || valueGetACT_UF.data.length < 1) return;

		setActivitiesUF(
			valueGetACT_UF.data.map((el) => {
				const { activity: i } = el;
				const _freq = isEmpty(el?.frequency?._id)
					? isEmpty(i?.frequency)
						? ''
						: i.frequency
					: el.frequency._id;
				return {
					_id: el._id,
					attributes: [...i.attributes, 'freq'],
					columns: [...i.columns, _freq],
					id: uniqueId(),
					isComplete: false,
					mainAttribute: i.mainAttribute,
					name: i.name,
					idActivity: i._id || '',
				};
			})
		);
	}, [valueGetACT_UF]);

	const Header = {
		label: 'Estado de creación',
		stepper: {
			activeStep: 4,
			items: estadoCreacion,
			completed: {},
		},
		tabs: {
			items: DefaultTabItems,
			value: currentTab,
			onTabChange: (e: any, value: number) => setCurrentTab(value),
		},
	};

	const CreacionFreq: ICreationPage = {
		header: { ...Header },
		stepOne: {
			title: !isCreacionModal ? 'Frecuencias por actividad' : 'Días no laborales',
			alertLabel: !isCreacionModal
				? 'A continuación crea las frecuencias solicitadas para el proyecto'
				: 'A continuación selecciona los días que NO serán laborables. En estos días no se asignarán tareas de supervisión.',
			show: true,
			Children: !isCreacionModal ? (
				<CreacionFrecuencias
					onContinue={(data) => {
						dispatch(setLoading({ loading: true }));
						const _adapted = creacionFrecuenciaAdapter(data, projectID || '');
						updateFreq(_adapted);
					}}
				/>
			) : (
				<DiasNolaborales
					isDone={isCreacionDone}
					defaultData={defaultCreate}
					onContinue={(days, selected) => {
						const _adapted = creacionNonWorkingDays(
							days,
							selected,
							projectID || ''
						);
						updateNonWorkingDays(_adapted);
					}}
				/>
			),
		},
	};

	const FrecuenciaServicios: ICreationPage = {
		header: { ...Header },
		stepOne: {
			title: 'Frecuencias por actividad',
			alertLabel:
				'Selecciona las frecuencias para cada una de las actividades creadas.',
			show: true,
			Children: (
				<FrecuenciaServiciosOne
					onContinue={(data) => {
						setIsSave(false);
						dispatch(setLoading({ loading: true }));
						const _adapted = sendFreqAdapterActivities(data, projectID || '');
						updateActivity(_adapted);
					}}
					onSave={(data) => {
						setIsSave(true);
						dispatch(setLoading({ loading: true }));
						const _adapted = sendFreqAdapterActivities(data, projectID || '');
						updateActivity(_adapted);
					}}
					options={FrequencyOptions}
					data={activities}
					setData={setActivities}
					isDone={isFreqActDone}
					url=""
				/>
			),
		},
	};

	const FrecuenciaUnidad: ICreationPage = {
		header: { ...Header },
		stepOne: {
			title: !isDetalle
				? 'Frecuencias por actividad y espacios'
				: 'Frecuencias por actividad, indicador y espacio (detalle)',
			alertLabel: !isDetalle
				? 'Selecciona las frecuencias para cada una de las actividades creadas, considerando el espacio tipo nivel 1.'
				: 'Selecciona las frecuencias para cada una de las actividades creadas, considerando criterios y espacios.',
			show: true,
			Children: !isDetalle ? (
				<FrecuenciaUnidadOne
					onSelectChange={(select) => {
						if (isEmpty(select)) return;
						dispatch(setLoading({ loading: true }));
						setCurrentACT(String(select));
						getACT_UF(
							null,
							`?project=${projectID}&functional=${select}&type=U-A&populate=1&applies=true`
						);
					}}
					selectData={{
						data: {
							options: UFOptions,
							withStatus: false,
						},
						label: 'Elige una unidad funcional:',
					}}
					onContinue={(data, _UF) => {
						setIsSave(false);
						dispatch(setLoading({ loading: true }));
						const _adapted = sendACT_UFAdapter(
							data,
							String(_UF),
							projectID || ''
						);
						updatedACT_UF(_adapted, `?project=${projectID}`);
					}}
					onSave={(data, _UF) => {
						setIsSave(true);
						dispatch(setLoading({ loading: true }));
						const _adapted = sendACT_UFAdapter(
							data,
							String(_UF),
							projectID || ''
						);
						updatedACT_UF(_adapted, `?project=${projectID}`);
					}}
					data={activitiesUF}
					setData={setActivitiesUF}
					isDone={isFreqActUnitDone}
					url=""
					options={FrequencyOptions}
				/>
			) : (
				<FrecuenciaUnidadTwo
					data={IndicatorList}
					setData={setIndicatorList}
					subcontainerOptions={{
						//UF
						UFOptions: UFIOptions,
						handleUF: setCurrentUFI,
						UFValue: currentUFI,
						//EF
						EFOptions: EFIOptions,
						handleEF: setCurrentEFI,
						EFValue: currentEFI,
						//ACT
						ACTOptions: ACTIOptions,
						handleACT: setCurrentACTI,
						ACTValue: currentACTI,
						// # LEVELS
						isLevelTwo: isLevelTwo,
					}}
					isDone={isFreqActUnitSpaceDone}
					url=""
					frequenciesList={FrequencyOptions}
					onCustomContinue={(EF, ACT, I) => {
						dispatch(setLoading({ loading: true }));
						setIsSave(false);
						const _adapter = updateFreqACT_EFAdapter({
							ACT,
							EF,
							projectID: String(projectID),
							I,
							IndicatorList: currentIndicatorList,
						});
						updateIndicators(
							_adapter,
							`?project=${projectID}&functional=${EF}&activity=${ACT}`
						);
					}}
					onCustomSave={(EF, ACT, I) => {
						dispatch(setLoading({ loading: true }));
						setIsSave(true);
						const _adapter = updateFreqACT_EFAdapter({
							ACT,
							EF,
							projectID: String(projectID),
							I,
							IndicatorList: currentIndicatorList,
						});
						updateIndicators(
							_adapter,
							`?project=${projectID}&functional=${EF}&activity=${ACT}`
						);
					}}
				/>
			),
		},
	};

	const pages = [
		<CreationPage {...CreacionFreq} />,
		<CreationPage {...FrecuenciaServicios} />,
		<CreationPage {...FrecuenciaUnidad} />,
	];

	return (
		<div>
			{pages.map((Component, idx) => (
				<Hider
					key={`hidder-${idx}`}
					isVisible={idx === currentTab && !isAplicacionModal}
					Component={Component}
				/>
			))}
			{isAplicacionModal && (
				<StartDateModal
					onContinue={(date) => {
						updateProject({ supervisionStartDate: date }, `/${projectID}`);
					}}
				/>
			)}
		</div>
	);
};

export default Index;
