/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { uniqueId } from '../../../utils/uniqueId';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import { isEmpty } from '../../../utils/validators';
import { RootState } from '../../../store';

interface IGet {
	setIE: TReactDispatch<IStepTwoModel[]>;
}

const useGetIE = (props: IGet) => {
	const { setIE } = props;
	const dispatch = useDispatch();
	const { specificIndicatorBase } = useSelector(
		(state: RootState) => state.projectBase
	);
	const { execute, status, value } = useApiRequest<
		any,
		IResponsePayload<IStepTwoModel>
	>({
		path: URLS.PROJECT.INDICATOR,
		method: 'GET',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (value?.data && value.data.length > 0) {
				const _adapted: IStepTwoModel[] = value.data.map((i, _idx) => ({
					...i,
					isComplete: i.columns.every((i: any) => !isEmpty(i)),
					id: uniqueId(),
					_id: i._id,
				}));
				setIE(_adapted);
			} else {
				if (!isEmpty(specificIndicatorBase)) {
					const adapted = [
						{
							id: uniqueId(),
							attributes: specificIndicatorBase?.attributes,
							columns: specificIndicatorBase?.attributes.map((_) => ''),
							isComplete: false,
							name: specificIndicatorBase?.name,
							mainAttribute: specificIndicatorBase?.mainAttribute,
						},
					];
					setIE(adapted as IStepTwoModel[]);
				}
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute };
};

export default useGetIE;
