import { Paper, Box, Grid } from '@mui/material';
import { useListRoles } from './hooks/useListRoles';
import styles from './styles/stepper-styles.module.css';
import DashedBtn from '../../../../../components/dashed-button.component';
import VerticalStepper from '../../../../../components/vertical-stepper-fixed.component';
import Alert from '../../../../../components/info.alert.component';
import { Button } from '../../../../../components/main-button.component';
import { ItemCreateRole } from './item-create-role.component';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';
import { HeaderEdit } from '../../../../../components/header-edit.component';
import PersonalIcon from '../../../../../assets/icons/personal.icon';
import { LABELS_CREATE_ROLE } from './constants/labels';

interface IUseListRoles {
	setRole: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrenBase: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
	isEdit: boolean;
	isAutomaticCharge: boolean;
}

export const ListRoles = ({
	setRole,
	setStep,
	setCurrenBase,
	isEdit,
	isAutomaticCharge,
}: IUseListRoles) => {
	const {
		rolesOptions,
		rolesInputs,
		matches,
		navigate,
		handleDeleteRol,
		handleNewRol,
		setRolesInputs,
		handleSaveRole,
		getInputsCustom,
		getTotalUsers,
	} = useListRoles();

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					{!isEdit && !isAutomaticCharge ? (
						<>
							<Grid item xs={12}>
								<h4
									style={{
										margin: 0,
										padding: '0.5rem 0 0 0.5rem',
										fontWeight: 600,
									}}
								>
									{LABELS_CREATE_ROLE.AUTOMATIC_CHARGE.TITLE}
								</h4>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="info" sx={{ marginTop: '1rem' }}>
									{LABELS_CREATE_ROLE.AUTOMATIC_CHARGE.ALERT}
								</Alert>
							</Grid>
						</>
					) : (
						<HeaderEdit
							section="Personal"
							title={
								isAutomaticCharge
									? LABELS_CREATE_ROLE.AUTOMATIC_CHARGE.TITLE
									: LABELS_CREATE_ROLE.EDIT.TITLE
							}
							alertText={
								isAutomaticCharge
									? LABELS_CREATE_ROLE.AUTOMATIC_CHARGE.ALERT
									: LABELS_CREATE_ROLE.EDIT.ALERT
							}
							icon={<PersonalIcon size="23px" />}
						/>
					)}
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={rolesOptions}
							renderItem={(item, column) => (
								<ItemCreateRole
									item={item}
									column={column}
									values={rolesInputs}
									onEdit={(item) => {
										setRole(item);
										setStep(1);
										setCurrenBase(getInputsCustom(item));
									}}
									onSave={handleSaveRole}
									onDelete={handleDeleteRol}
									setValues={setRolesInputs}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<DashedBtn
							onClick={handleNewRol}
							width="100"
							text="Agregar rol"
						/>
					</Grid>
					<Grid item>
						<Button
							id="btn-continue-role"
							label="Continuar"
							onClick={isEdit ? () => navigate(-1) : getTotalUsers}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
