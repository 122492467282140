import { IAdaptedValues } from '../interfaces/edit-adapted-values';

export const getInputs = (data: IAdaptedValues) => {
	const newOptions = data.rows.map((element, index) => {
		return {
			id: element.key,
			label: element.key,
			placeholder: 'Ingresa la información',
			value: element.value || '',
		};
	});
	return newOptions;
};
