/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { getSingleAdaptedData } from '../../../../utils/get-adapted-data';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { IValuesInfo } from '../../../../interfaces/edit-values.interface';

interface IUseCreateFunctional {
	functionalsOptions: IAdaptedValues[];
	setFunctionalsOptions: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
}

export const useCreateFunctional = ({
	functionalsOptions,
	setFunctionalsOptions,
}: IUseCreateFunctional) => {
	const dispatch = useDispatch();

	const {
		execute: createFunctional,
		status: statusCreateFunctional,
		value: valueCreateFunctional,
	} = useApiRequest<any, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.FUNCTIONALS.CREATE_FUNCTIONAL,
		method: 'POST',
	});

	useEffect(() => {
		if (statusCreateFunctional === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Nivel agregado con éxito',
					severity: 'success',
				})
			);
			const temporalFunctionals = functionalsOptions;
			temporalFunctionals.pop();
			setFunctionalsOptions([
				...temporalFunctionals,
				getSingleAdaptedData(valueCreateFunctional?.detail),
			]);
			dispatch(stopLoading());
		} else if (statusCreateFunctional === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al agregar el nivel',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusCreateFunctional]);

	const handleCreateCreateFunctional = (body: any) => {
		dispatch(setLoading({ loading: true }));
		createFunctional(body);
	};

	return { handleCreateCreateFunctional };
};
