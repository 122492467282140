import { Box, Grid, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNewIncident } from './hooks/useNewIncident';
import TextInput from '../../../components/text-input.component';
import { Button } from '../../../components/main-button.component';
import InputSelect from '../../../components/select-input.component';
import { ActionComponent } from '../../../components/action.component';
import { CameraAltOutlined, UploadFileOutlined } from '@mui/icons-material';
import CustomCamera from '../../../components/custom-camera.component';
import { DragNDrop } from '../../../components/drag-drop.component';
import styles from './styles/suspend-activity-styles.module.css';

export const NewIncident = () => {
	const {
		data,
		matches,
		camera,
		typesFindings,
		disabledButton,
		withStatus,
		editFinding,
		supervisorName,
		unitInfo,
		handleFile,
		handleChange,
		deleteFile,
		setCamera,
		handlePhoto,
		undoState,
		createIncident,
		changeStatus,
	} = useNewIncident();

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={2} justifyContent="flex-end" alignItems="center">
					<Grid item xs={12}>
						<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
							{withStatus || editFinding
								? 'Nueva incidencia'
								: 'Nueva evidencia'}
						</span>
					</Grid>
					<Grid item xs={12}>
						<span style={{ fontSize: '18px' }}>
							{withStatus || editFinding
								? 'Descripción de la incidencia'
								: 'Descripción de la evidencia'}
						</span>
					</Grid>
					{(withStatus || editFinding) && (
						<>
							<Grid item>
								<span style={{ fontSize: '15px', fontWeight: 'bold' }}>
									Tipo de hallazgo
								</span>
							</Grid>
							<Grid item xs={12} sm={4}>
								<InputSelect
									value={typesFindings.length > 0 ? data.type : ''}
									optionsMenu={typesFindings}
									onChange={handleChange}
									placeholder="Selecciona"
									id="select-findings"
								/>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<TextInput
							value={data.description}
							id="description"
							name="description"
							placeholder="Escribe, si es necesario, la descripción de la incidencia"
							onChange={handleChange}
							multiline
							rows={6}
						/>
					</Grid>
					<Grid item xs={12}>
						<span style={{ fontSize: '18px' }}>Evidencias</span>
					</Grid>
					<Grid item xs={12}>
						<Grid
							container
							spacing={2}
							justifyContent={matches ? 'center' : ''}
						>
							<Grid item>
								{camera ? (
									<CustomCamera
										setImg={handlePhoto}
										stylesCamera={{
											width: '244px',
											height: '221px',
											borderRadius: '10px',
										}}
										user={supervisorName}
										unitInfo={unitInfo}
										withInfo
									/>
								) : (
									<ActionComponent
										icon={CameraAltOutlined}
										label="Tomar foto"
										onClick={() => setCamera(true)}
									/>
								)}
							</Grid>
							<Grid item>
								<DragNDrop
									element={{ accept: 'image/jpeg' }}
									onChange={handleFile}
									icon={
										<div className={styles.dragNDropIconContainer}>
											<UploadFileOutlined
												style={{
													fontSize: '3em',
												}}
											/>
											<span style={{ fontSize: '12px' }}>JPEG</span>
										</div>
									}
									stylesContainer={{
										height: '100%',
										width: matches ? '244px' : '100%',
										padding: '20px',
									}}
									stylesTitle={{
										fontSize: '21px',
										color: '#7f8297',
										fontWeight: 'bold',
									}}
									stylesSubTitle={{ fontSize: '9px', color: '#7f8297' }}
								/>
							</Grid>
						</Grid>
					</Grid>
					{data.files.length > 0 && (
						<Grid item xs={12} marginY={2}>
							<Grid container spacing={2}>
								{data.files.map((element, index) => (
									<Grid
										item
										key={index}
										xs={12}
										md={6}
										lg={3}
										style={{
											position: 'relative',
											display: 'flex',
										}}
									>
										<img
											src={
												element._id
													? element.url
													: `data:image/jpeg;base64,${element.fileBase64}`
											}
											alt={element.fileName}
											style={{ width: '100%' }}
										/>
										<div
											className={styles.badgeCustom}
											onClick={() => deleteFile(index)}
										>
											<CloseIcon style={{ color: 'white' }} />
										</div>
									</Grid>
								))}
							</Grid>
						</Grid>
					)}
					<Grid item>
						<Button
							label="Cancelar"
							onClick={() => undoState()}
							style={{
								width: '150px',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label={editFinding ? 'Guardar' : 'Continuar'}
							onClick={() =>
								withStatus || editFinding
									? changeStatus()
									: createIncident()
							}
							variant="solid"
							style={{
								width: '150px',
								backgroundColor: '#0080F9',
							}}
							disabled={disabledButton}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
