import { Paper, Box, Grid } from '@mui/material';
import { Button } from '../../../../components/main-button.component';
import DimensionesIcon from '../../../../assets/icons/dimensiones.icon';
import { HeaderEdit } from '../../../../components/header-edit.component';
import { useEditRelationsUA } from './hooks/useEditRelationsUA';
import InputSelect from '../../../../components/select-input.component';
import VerticalStepper from '../../../../components/vertical-stepper-fixed.component';
import Collapsable from '../../../../components/collapsable.component';
import styles from './styles/edit-relations-UA-styles.module.css';

export const EditRelationsUA = () => {
	const {
		matches,
		activitiesSelectOptions,
		currentActivity,
		functionalsTable,
		idsFunctionals,
		selectAll,
		setCurrentActivity,
		navigate,
		handleSaveUARelations,
		handleCheck,
		handleSelectAll,
		setSelectAll,
		handleCheckColumn,
	} = useEditRelationsUA();

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
					display={'flex'}
				>
					<HeaderEdit
						section="Dimensiones geográficas"
						title="1. Relación Actividades - Espacios"
						alertText='En el selector aparecen las actividades previamente creadas. Selecciona los espacios tipo nivel 1 en los que deba ser supervisada la actividad que se muestra en el selector. A pesar de que la actividad no deba ser supervisada en todos los sub-espacios, si aplica para la mayoría selecciona el nivel 1. Al hacer clic en "Expandir" podrás hacer modificaciones de mayor precisión.'
						icon={<DimensionesIcon size="23px" />}
					/>
					<Grid container item xs={12} spacing={3}>
						<Grid item>
							<p style={{ fontWeight: 'bold' }}>Elige una actividad: </p>
						</Grid>
						<Grid item xs>
							<InputSelect
								id="select-activity"
								onChange={(e) => {
									setCurrentActivity(
										e?.target?.value?.toString() || ''
									);
									setSelectAll(false);
								}}
								value={currentActivity}
								optionsMenu={activitiesSelectOptions}
								placeholder="Selecciona una actividad"
							/>
						</Grid>
					</Grid>
					<Grid item>
						<Button
							label={selectAll ? 'Deseleccionar todo' : 'Seleccionar todo'}
							onClick={() => {
								setSelectAll(!selectAll);
								handleSelectAll(!selectAll);
							}}
							variant="outlined"
							style={{
								width: '200px',
								color: ' #0080F9',
								borderColor: 'white',
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							items={functionalsTable}
							withCollapsable
							renderItem={(item, column) => (
								<>
									<Grid item xs={2} className={styles.titleUA}>
										<p>{`${item.name} ${column + 1}`}</p>
									</Grid>
									<Grid item xs>
										<Grid container rowSpacing={2}>
											<Collapsable
												activeElements={idsFunctionals}
												id={item.id}
												items={item.items}
												column={column}
												isComplete={true}
												name={item.name}
												value={item.value}
												onCheck={(UF, EF, Check) =>
													handleCheck(column, UF, EF, Check)
												}
												onSelectColumn={handleCheckColumn}
											/>
										</Grid>
									</Grid>
								</>
							)}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Salir"
							onClick={() => navigate(-1)}
							variant="outlined"
							style={{
								width: '200px',
								color: ' var(--color-error-main)',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Guardar"
							onClick={() => handleSaveUARelations()}
							disabled={!currentActivity}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
