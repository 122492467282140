import { useEffect, useState } from 'react';
import { Box, CssBaseline, Container } from '@mui/material';
// Components
import AppBarLayout from './appbar-layout.component';
import DrawerLayout from './drawer-layout.component';

interface IProps {
	children?: JSX.Element[] | JSX.Element;
}

const drawerWidth: number = 300;

const Layout = ({ children }: IProps) => {
	const [openMenu, setOpenMenu] = useState(false);
	const [arrowToggle, setArrowToggle] = useState<boolean>(false);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const toggleDrawer = () => {
		setOpenMenu(!openMenu);
	};

	const handleResize = () => {
		setOpenMenu(false);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />

			<AppBarLayout
				toggleDrawer={toggleDrawer}
				drawerWidth={drawerWidth}
				arrowToggle={arrowToggle}
			/>

			<DrawerLayout
				open={openMenu}
				toggleDrawer={toggleDrawer}
				drawerWidth={drawerWidth}
				arrowToggle={arrowToggle}
				setArrowToggle={setArrowToggle}
			/>

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto',
					background: '#F8F9FC',
				}}
			>
				<Container
					style={{
						maxWidth: '90%',
						padding: 0,
					}}
					sx={{ mt: 18, mb: 4 }}
				>
					{children}
				</Container>
			</Box>
		</Box>
	);
};

export default Layout;
