import { ISupervisor } from '../interfaces/project-supervisor.interface';
import { getDateLocalMX } from '../../../utils/dates';
import { isEmpty } from '../../../utils/validators';

export const supervisorAdapter = (
	data: ISupervisor[],
	functionEdit: (item: ISupervisor) => void
): ISupervisor[] => {
	if (isEmpty(data)) return [];
	const newData = data?.map((supervisor: ISupervisor) => {
		return {
			...supervisor,
			status: supervisor.status ? 'Activo' : 'Inactivo',
			employeeName: supervisor.firstName + ' ' + supervisor.lastName,
			date: getDateLocalMX(supervisor.lastConnection),
			acciones: '',
		};
	});
	return newData as unknown as ISupervisor[];
};
