import theme from '../config/theme';

export const returnStyles = (type?: string, variant?: string): React.CSSProperties => {
	const stylesCss: React.CSSProperties = {};

	if (type === 'success') {
		stylesCss[variant === 'solid' || !variant ? 'background' : 'color'] =
			theme.palette.success.main;
		stylesCss['borderColor'] =
			variant === 'outlined' ? theme.palette.success.main : 'transparent';
	}

	if (type === 'warning') {
		stylesCss[variant === 'solid' || !variant ? 'background' : 'color'] =
			theme.palette.warning.dark;
		stylesCss['borderColor'] =
			variant === 'outlined' ? theme.palette.warning.dark : 'transparent';
	}

	if (type === 'error') {
		stylesCss[variant === 'solid' || !variant ? 'background' : 'color'] =
			theme.palette.error.dark;
		stylesCss['borderColor'] =
			variant === 'outlined' ? theme.palette.error.dark : 'transparent';
	}

	if (type === 'black') {
		stylesCss[variant === 'solid' || !variant ? 'background' : 'color'] = '#000000';
		stylesCss['borderColor'] = variant === 'outlined' ? '#000000' : 'transparent';
	}

	if (type === 'blue') {
		stylesCss[variant === 'solid' || !variant ? 'background' : 'color'] =
			theme.palette.blue.main;
		stylesCss['borderColor'] =
			variant === 'outlined' ? theme.palette.blue.main : 'transparent';
	}

	return stylesCss;
};
export const getColorBackground = (severity: string): string => {
	let color = '';
	switch (severity) {
		case 'warning':
			color = theme.palette.warning.light;
			break;
		case 'info':
			color = theme.palette.info.light;
			break;
		case 'error':
			color = theme.palette.error.light;
			break;
		case 'success':
			color = theme.palette.success.light;
			break;
		default:
			break;
	}
	return color;
};

export const getColorLabel = (severity: string): string => {
	let color = '';
	switch (severity) {
		case 'warning':
			color = theme.palette.warning.dark;
			break;
		case 'info':
			color = theme.palette.info.dark;
			break;
		case 'error':
			color = theme.palette.error.dark;
			break;
		case 'success':
			color = theme.palette.success.dark;
			break;
		default:
			break;
	}
	return color;
};
