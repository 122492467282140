/* eslint-disable no-prototype-builtins */
import { IBaseModel, IIndicador } from '../interfaces/project.interface';
import { IStepTwoModel } from '../interfaces/stepTwo.interface';
import { uniqueId } from '../utils/uniqueId';
import { ITable } from '../interfaces/criterios.interface';
import { ICollapsable } from '../interfaces/collapsable.interface';

interface IDataSupervision {
	name: string;
	project: string;
	activity: string;
	functional: string;
	type: string;
	applies: boolean;
	_id?: string;
}
interface IDataSupervisionUnit {
	_id?: string;
	name: string;
	project: string;
	activity: string;
	functional: string;
	indicator: string;
	applies: boolean;
	type: string;
}
export const stepTwoAdapter = (data: IBaseModel): IStepTwoModel[] => {
	return [
		{
			attributes: data.attributes,
			columns: data.columns,
			id: uniqueId(),
			isComplete: false,
			mainAttribute: data.mainAttribute,
			name: data.name,
		},
	];
};

export const sendDataAdapterFunctional = (
	data: IStepTwoModel[],
	projectID: string,
	functionalID?: string | null
): any => {
	return {
		functionals: data.map((i) => ({
			attributes: i.attributes,
			columns: i.columns,
			mainAttribute: i.mainAttribute,
			name: i.name,
			project: projectID,
			functional: functionalID || null,
			...(i._id && { _id: i._id }),
		})),
	};
};

export const sendDataAdapterActivities = (
	data: IStepTwoModel[],
	projectID: string
): any => {
	return {
		activities: data.map((i) => ({
			attributes: i.attributes,
			columns: i.columns,
			mainAttribute: i.mainAttribute,
			name: i.name,
			project: projectID,
			...(i._id && { _id: i._id }),
		})),
	};
};

export const sendDataAdapterIndicator = (
	data: IStepTwoModel[],
	projectID: string,
	isGeneral: boolean,
	activityID?: string
): any => {
	return {
		indicators: data.map((i) => ({
			attributes: i.attributes,
			columns: i.columns,
			mainAttribute: i.mainAttribute,
			name: i.name,
			project: projectID,
			isGeneral: isGeneral,
			...(i._id && { _id: i._id }),
			...(activityID && { activity: activityID }),
		})),
	};
};

interface ISendDataAdapterSupervisionUnit {
	indicators: Array<IIndicador[]>;
	projectID: string;
	currentService: string;
	checkedList: Set<string>;
	defaultItems: IStepTwoModel[];
}
export const sendDataAdapterSupervisionUnit = (
	props: ISendDataAdapterSupervisionUnit
): any => {
	const { checkedList, currentService, indicators, projectID, defaultItems } = props;
	return {
		supervisionUnits: indicators.flat().map((i) => ({
			name: `${currentService}-${i._id}`,
			project: projectID,
			activity: currentService,
			indicator: i._id,
			type: 'A-IG',
			applies: checkedList.has(String(i._id)),
			_id: defaultItems.find((e) => e.indicator === i._id)?._id,
		})),
	};
};

export const updateFunctionalActivities = (
	functionals: Set<string>,
	projectID: string,
	currentService: string,
	criteriosTable: ICollapsable[],
	getU_AValue: any
): any => {
	const functionalsArray = Array.from(functionals);
	const supervision: any[] = [];
	criteriosTable.forEach((i) => {
		const dataFlag: IDataSupervision = {
			name: `${currentService}-${i.id}`,
			project: projectID,
			activity: currentService,
			functional: i.id,
			type: 'U-A',
			applies: functionalsArray.includes(i.id),
		};
		const ele = getU_AValue.find((ele: any) => ele.functional === i.id);

		if (ele) {
			dataFlag._id = ele._id || undefined;
		}
		supervision.push(dataFlag);
		i.items.forEach((item) => {
			const dataFlagI: IDataSupervision = {
				name: `${currentService}-${item.id}`,
				project: projectID,
				activity: currentService,
				functional: item.id,
				type: 'U-A',
				applies: functionalsArray.includes(item.id),
			};
			const ele2 = getU_AValue.find((ele: any) => ele.functional === item.id);
			if (ele2) {
				dataFlagI._id = ele2._id || undefined;
			}
			supervision.push(dataFlagI);
		});
	});
	return {
		supervisionUnits: supervision,
	};
};

export const patchActivitiesAdapter = (
	data: IStepTwoModel[],
	base: IStepTwoModel[],
	isEdit: boolean,
	getIsTeam: (_id: string) => boolean,
	_projectID?: string
) => {
	const _adapted: any = base.map((i, idx) => {
		const isTeamExecutor = getIsTeam(data[idx].columns[0]);
		const isTeamSupervisor = getIsTeam(
			isEdit ? data[idx].columns[1] : data[idx].columns[0]
		);
		const isTeamSubstitute1 = getIsTeam(
			isEdit ? data[idx].columns[2] : data[idx].columns[1]
		);
		const isTeamSubstitute2 = getIsTeam(
			isEdit ? data[idx].columns[3] : data[idx].columns[2]
		);
		return {
			...i,
			_id: i._id,
			project: _projectID,
			executor: isTeamExecutor ? null : data[idx].columns[0],
			executorTeam: isTeamExecutor ? data[idx].columns[0] : null,
			supervisor: isTeamSupervisor
				? null
				: isEdit
				? data[idx].columns[1]
				: data[idx].columns[0],
			supervisorTeam: isTeamSupervisor
				? isEdit
					? data[idx].columns[1]
					: data[idx].columns[0]
				: null,
			substitute1: isTeamSubstitute1
				? null
				: isEdit
				? data[idx].columns[2]
				: data[idx].columns[1],
			substitute1Team: isTeamSubstitute1
				? isEdit
					? data[idx].columns[2]
					: data[idx].columns[1]
				: null,
			substitute2: isTeamSubstitute2
				? null
				: isEdit
				? data[idx].columns[3]
				: data[idx].columns[2],
			substitute2Team: isTeamSubstitute2
				? isEdit
					? data[idx].columns[3]
					: data[idx].columns[2]
				: null,
		};
	});
	return {
		activities: _adapted,
	};
};

export const updateFunctionalIndicator = (
	functional: string,
	indicators: Array<{ id: string; columns: any[]; _id?: string }>,
	checkList: Set<string>,
	projectID: string,
	currentService: string
): any => {
	return {
		supervisionUnits: Array.from(indicators).map((i) => {
			const dataFlag: IDataSupervisionUnit = {
				name: `${currentService}-${i.id}`,
				project: projectID,
				activity: currentService,
				functional: functional,
				indicator: i.id,
				applies: checkList.has(i.id),
				type: 'U-I',
			};
			if (i._id) {
				dataFlag._id = i._id;
			}
			return dataFlag;
		}),
	};
};

export const creacionFrecuenciaAdapter = (data: ITable[], projectID: string): any => {
	return {
		frequencies: data.map((i) => ({
			...(i._id && { _id: i._id }),
			name: i.rows[0].value,
			repeatDay: i.rows[1].value,
			repeatMonth: i.rows[2].value,
			isNotRecurrent: i.rows[3].value,
			project: projectID,
		})),
	};
};

export const creacionNonWorkingDays = (
	days: ITable[],
	selected: Set<string | number>,
	projectID: string,
	_id?: string
): any => {
	return {
		...(_id && { _id: _id }),
		days: Array.from(selected),
		specificDays: days.map((i) => {
			return {
				day: i.rows[0].value.toString(),
				month: i.rows[1].value.toString(),
			};
		}),
		project: projectID,
	};
};

export const sendFreqAdapterActivities = (
	data: IStepTwoModel[],
	projectID: string
): any => {
	return {
		activities: data.map((i) => ({
			frequency: i.columns.slice(-1).at(0),
			project: projectID,
			...(i._id && { _id: i._id }),
		})),
	};
};

export const sendACT_UFAdapter = (
	data: IStepTwoModel[],
	functional: string,
	projectID: string
): any => {
	return {
		supervisionUnits: data.map((i) => ({
			project: projectID,
			functional: functional,
			activity: i.idActivity,
			frequency: i.columns.slice(-1).at(0),
			type: 'U-A',
			...(i._id && { _id: i._id }),
		})),
	};
};

interface IUpdateFreqACT_EFAdapter {
	EF: string;
	ACT: string;
	I: { [key: string]: string };
	projectID: string;
	IndicatorList: string[];
}
export const updateFreqACT_EFAdapter = (props: IUpdateFreqACT_EFAdapter): any => {
	const { ACT, EF, I, projectID, IndicatorList } = props;
	const entities = Object.entries(I);
	return {
		supervisionUnits: entities.map((i, idx) => {
			const [_id, freq] = i;
			return {
				functional: EF,
				activity: ACT,
				//indicator: _id,
				indicator: IndicatorList[idx],
				_id: _id,
				project: projectID,
				type: 'U-I',
				frequency: freq,
			};
		}),
	};
};
