export interface ITypeRoutine {
	RECURRENT: string;
	NON_RECURRENT: string;
	EXTRAORDINARY: string;
}

export const ROUTINE_TYPE_ENUM: ITypeRoutine = {
	RECURRENT: 'RECURRENT',
	NON_RECURRENT: 'NON_RECURRENT',
	EXTRAORDINARY: 'EXTRAORDINARY',
};
