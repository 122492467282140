import { useMediaQuery } from '@mui/material';
import theme from '../../../../../config/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useGetFunctional } from '../../../hooks/useGetFunctional';
import { isEmpty } from '../../../../../utils/validators';
import { useGetActivitiesUA } from './useGetActivitiesUA';
import { useGetIndicators } from './useGetIndicators';
import { useGetUIRelations } from './useGetUIRelations';
import { getNewDataUIRelations } from '../adapters/update-UI-relations.adapter';
import { useUpdateUIRelations } from './useUpdateUIRelations';

/* eslint-disable react-hooks/exhaustive-deps */
export const useEditRelationsUI = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { labelN1, labelN2, levels } = useSelector(
		(state: RootState) => state.projectBase
	);

	const [currentFunctionalLvl1, setCurrentFunctionalLvl1] = useState<string>('');
	const [currentFunctionalLvl2, setCurrentFunctionalLvl2] = useState<string>('');
	const [currentActivity, setCurrentActivity] = useState<string>('');

	const { functionalsSelectOptions } = useGetFunctional({
		functionalParent: '',
		isLvlOne: true,
	});

	const { activitiesOptions, handleGetActivitiesUA } = useGetActivitiesUA({});

	const {
		arrayIndicators,
		matrixIndicators,
		getAllIndicators,
		setMatrixIndicators,
		setArrayIndicators,
		setIdActivity,
	} = useGetIndicators();

	const {
		functionalsSelectOptions: functionalsSelectOptionsLvl2,
		getFunctionalData: getFunctionalDataLvl2,
	} = useGetFunctional({
		functionalParent: '',
		isLvlOne: true,
		execute: false,
	});

	const {
		idsIndicators,
		valueGetUIRelations,
		handleGetUIRelations,
		setIdsIndicators,
		setValue,
	} = useGetUIRelations();

	const { handleUpdateUIRelations } = useUpdateUIRelations({ setValue });

	useEffect(() => {
		setMatrixIndicators([]);
		setArrayIndicators([]);
		if (levels <= 1 && !isEmpty(currentFunctionalLvl1)) {
			handleGetActivitiesUA(currentFunctionalLvl1);
		} else if (!isEmpty(currentFunctionalLvl1)) {
			getFunctionalDataLvl2(currentFunctionalLvl1);
		}
	}, [currentFunctionalLvl1]);

	useEffect(() => {
		setMatrixIndicators([]);
		setArrayIndicators([]);
		if (!isEmpty(currentFunctionalLvl2)) {
			handleGetActivitiesUA(currentFunctionalLvl2);
		}
	}, [currentFunctionalLvl2]);

	useEffect(() => {
		setMatrixIndicators([]);
		setArrayIndicators([]);
		if (!isEmpty(currentActivity)) {
			getAllIndicators(currentActivity);
			handleGetUIRelations(
				currentActivity,
				levels > 1 ? currentFunctionalLvl2 : currentFunctionalLvl1
			);
		} else {
			setIdActivity('');
		}
	}, [currentActivity]);

	const onSelect = (id: string, checked: boolean) => {
		const collection = new Set(idsIndicators);
		if (!checked) collection.delete(id);
		else collection.add(id);
		setIdsIndicators(collection);
	};

	const onSelectColumn = (column: number) => {
		const collection = new Set(idsIndicators);
		for (const item of matrixIndicators[column]) {
			collection.add(String(item._id));
		}
		setIdsIndicators(collection);
	};

	const onSave = () => {
		const newRelationsUA = getNewDataUIRelations(
			arrayIndicators,
			valueGetUIRelations?.data || [],
			idsIndicators,
			idProject,
			currentActivity,
			levels > 1 ? currentFunctionalLvl2 : currentFunctionalLvl1
		);
		handleUpdateUIRelations({ supervisionUnits: newRelationsUA });
	};

	return {
		labelN1,
		labelN2,
		levels,
		currentFunctionalLvl1,
		currentFunctionalLvl2,
		currentActivity,
		matches,
		functionalsSelectOptions,
		functionalsSelectOptionsLvl2,
		activitiesOptions,
		matrixIndicators,
		arrayIndicators,
		idsIndicators,
		navigate,
		setCurrentFunctionalLvl1,
		setCurrentFunctionalLvl2,
		setCurrentActivity,
		onSelect,
		onSelectColumn,
		onSave,
	};
};
