import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../config/theme';
import { useGetUARelations } from './useGetUARelations';
import { isEmpty } from '../../../../../utils/validators';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUpdateUARelations } from './useUpdateUARelations';
import { useGetFunctionalsValue } from './useGetFunctionalsValues';
import { getNewDataUARelations } from '../adapters/update-UA-relations.adapter';
import { useGetActivities } from '../../../../edit-activities/hooks/useGetActivities';
import { IItem as ICollapsableItem } from '../../../../../interfaces/collapsable.interface';

/* eslint-disable react-hooks/exhaustive-deps */
export const useEditRelationsUA = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [selectAll, setSelectAll] = useState(false);

	const { activitiesSelectOptions } = useGetActivities();
	const [currentActivity, setCurrentActivity] = useState('');

	const { functionalsTable, valueGetFunctionalsValue } = useGetFunctionalsValue();
	const {
		idsFunctionals,
		valueGetUARelations,
		handleGetUARelations,
		setIdsFunctionals,
		setValue,
	} = useGetUARelations();

	const { handleUpdateUARelations } = useUpdateUARelations({ setValue });

	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	useEffect(() => {
		if (!isEmpty(currentActivity)) {
			handleGetUARelations(currentActivity);
		}
	}, [currentActivity]);

	const handleCheckColumn = (id: string, items: ICollapsableItem[], check: boolean) => {
		const collection = new Set(idsFunctionals);
		if (check) collection.add(id);
		else collection.delete(String(id));
		items.forEach((item) => {
			if (check) collection.add(item.id);
			else collection.delete(item.id);
		});
		setIdsFunctionals(collection);
	};

	const handleCheck = (row: number, UF_ID: string, EF_ID: string, check: boolean) => {
		const collection = new Set(idsFunctionals);
		if (check) collection.add(EF_ID);
		else collection.delete(EF_ID);

		if (functionalsTable[row].items.some((i) => collection.has(i.id))) {
			collection.add(UF_ID);
		} else collection.delete(UF_ID);
		setIdsFunctionals(collection);
	};

	const handleSelectAll = (selectAll: boolean) => {
		if (selectAll) {
			const collection = new Set(idsFunctionals);
			functionalsTable.forEach((UF) => {
				collection.add(String(UF.id));
				UF.items.forEach((EF) => {
					collection.add(String(EF.id));
				});
			});
			setIdsFunctionals(collection);
		} else {
			setIdsFunctionals(new Set<string>());
		}
	};

	const handleSaveUARelations = () => {
		const UARelations = getNewDataUARelations(
			valueGetFunctionalsValue?.data || [],
			valueGetUARelations?.data || [],
			idsFunctionals,
			idProject,
			currentActivity
		);
		handleUpdateUARelations({ supervisionUnits: UARelations });
	};

	return {
		matches,
		activitiesSelectOptions,
		currentActivity,
		functionalsTable,
		idsFunctionals,
		selectAll,
		setCurrentActivity,
		navigate,
		handleSaveUARelations,
		handleCheck,
		handleSelectAll,
		setSelectAll,
		handleCheckColumn,
	};
};
