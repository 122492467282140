import { useState } from 'react';
import { Paper } from '@mui/material';
import { SelectDate } from '../components/static-date-picker';
import ContinueButton from '../components/custom-btn.component';

interface IProps {
	onContinue: (value: Date) => void;
}

const StartDate = (props: IProps) => {
	const { onContinue } = props;
	const [calendarValue, setCalendarValue] = useState<Date>(new Date());

	const handleContinue = () => {
		onContinue(calendarValue);
	};

	return (
		<Paper
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '100%',
				//height: '75vh',
				padding: '2rem',
				justifyContent: 'center',
			}}
		>
			<h1>Fecha de inicio de supervisión</h1>
			{/* Fecha de inicio de supervisión, fecha de aplicación de cambios */}
			<h4>A continuación elige la fecha de inicio de inicio de supervisión</h4>
			<SelectDate value={calendarValue} setValueDate={setCalendarValue} />
			<ContinueButton id="cronograma6-continuar-btn" onClick={handleContinue} />
		</Paper>
	);
};
export default StartDate;
