/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../../config/theme';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useGetFrequencies } from '../../../hooks/useGetFrequencies';
import { useGetFunctionals } from '../../../hooks/useGetFunctionals';
import { isEmpty } from '../../../../../utils/validators';
import { useGetActivitiesByFunctionals } from '../../../hooks/useGetActivitiesByFunctional';
import { setLoading } from '../../../../../store/modules/loading';
import { useGetIndicators } from './useGetIndicators';
import { useUpdateActivitiesByFunctional } from '../../schedule-by-level/hooks/useUpdateActivititesByFunctional';

export const useEditScheduleByIndicator = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const { labelN1, labelN2, levels } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [functionalLvl1, setFunctionalLvl1] = useState('');
	const [functionalLvl2, setFunctionalLvl2] = useState('');
	const [activity, setActivity] = useState('');

	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const { frequenciesOptions } = useGetFrequencies();
	const { functionalsOptions, handleGetFunctionals } = useGetFunctionals();
	const {
		functionalsOptions: functionalsOptionsLvl2,
		handleGetFunctionals: handleGetFunctionalsLvl2,
	} = useGetFunctionals();

	const { activitiesOptionsSelect, getActivitiesByFunctionals } =
		useGetActivitiesByFunctionals();
	const { indicatorsOptions, getIndicators, setIndicatorsOptions } = useGetIndicators();

	const { handleUpdateActivitiesByFunctional } = useUpdateActivitiesByFunctional({
		activitiesOptions: indicatorsOptions,
		setActivitiesOptions: setIndicatorsOptions,
	});

	useEffect(() => {
		if (!idProject) navigate(-1);
		handleGetFunctionals();
	}, []);

	useEffect(() => {
		if (!isEmpty(functionalLvl1)) {
			setFunctionalLvl2('');
			setActivity('');
			setIndicatorsOptions([]);
			dispatch(setLoading({ loading: true }));
			if (levels > 1) handleGetFunctionalsLvl2(functionalLvl1);
			else
				getActivitiesByFunctionals(
					null,
					`/${idProject}?functional=${functionalLvl1}`
				);
		}
	}, [functionalLvl1]);

	useEffect(() => {
		if (!isEmpty(functionalLvl2)) {
			setActivity('');
			setIndicatorsOptions([]);
			dispatch(setLoading({ loading: true }));
			getActivitiesByFunctionals(
				null,
				`/${idProject}?functional=${functionalLvl2}`
			);
		}
	}, [functionalLvl2]);

	useEffect(() => {
		if (
			!isEmpty(activity) &&
			(levels > 1 ? !isEmpty(functionalLvl2) : !isEmpty(functionalLvl1))
		) {
			dispatch(setLoading({ loading: true }));
			getIndicators(
				null,
				`/${idProject}?functional=${
					levels > 1 ? functionalLvl2 : functionalLvl1
				}&activity=${activity}`
			);
		}
	}, [activity]);

	const handleFrequency = (value: string, item: any) => {
		handleUpdateActivitiesByFunctional(item.id, { frequency: value });
	};

	return {
		frequenciesOptions,
		functionalsOptions,
		functionalsOptionsLvl2,
		activitiesOptionsSelect,
		indicatorsOptions,
		functionalLvl1,
		functionalLvl2,
		activity,
		labelN1,
		labelN2,
		levels,
		matches,
		navigate,
		setFunctionalLvl1,
		setFunctionalLvl2,
		setActivity,
		handleFrequency,
	};
};
