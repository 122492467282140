/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { IActivities } from '../interfaces/get-activities.interface';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { getActivitiesAdapter } from '../adapters/activities-project.adapter';
import { INewInfoProject } from '../interfaces/new-info-project.interface';
import { useLocation } from 'react-router-dom';

interface IUseGetActivities {
	projectData: INewInfoProject | undefined;
}

export const useGetActivities = ({ projectData }: IUseGetActivities) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idUser = location.state.idUser as string;
	const [activitiesOptions, setActivitiesOptions] = useState<IAdaptedValues[]>([]);

	const {
		execute: getActivities,
		status: statusGetActivities,
		value: valueGetActivities,
	} = useApiRequest<unknown, IResponsePayload<IActivities>>({
		path: URLS.USER_PROJECT_MANAGEMENT.GET_ACTIVITIES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetActivities === 'success') {
			dispatch(stopLoading());
			setActivitiesOptions(
				getActivitiesAdapter(valueGetActivities?.data || [], idUser, projectData)
			);
		} else if (statusGetActivities === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al obtener la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetActivities]);

	const handleGetActivities = (user: string, project: string) => {
		dispatch(setLoading({ loading: true }));
		getActivities(null, `?user=${user}&project=${project}`);
	};

	return { activitiesOptions, handleGetActivities, setActivitiesOptions };
};
