/* eslint-disable react-hooks/exhaustive-deps */

import { useDispatch } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { IGetLevels } from '../../../interfaces/get-levels.interface';
import { stopLoading } from '../../../store/modules/loading';
import { useEffect } from 'react';
import { setValueProject } from '../../../store/modules/project';

export function useGetLevels() {
	const dispatch = useDispatch();

	const {
		execute: getLevels,
		status: statusLevels,
		value: valueLevels,
	} = useApiRequest<unknown, IResponsePayload<IGetLevels>>({
		path: URLS.SUPERVISION_UNIT.GET_LEVELS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusLevels === 'success') {
			dispatch(
				setValueProject({
					labelN1: valueLevels?.detail?.levels[0] || '',
					labelN2: valueLevels?.detail?.levels[1] || '',
					levels: valueLevels?.detail?.totalLevels || 0,
				})
			);
			dispatch(stopLoading());
		} else if (statusLevels === 'error') {
			dispatch(stopLoading());
		}
	}, [statusLevels]);

	return { getLevels };
}
