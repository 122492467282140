export interface ISummary {
	valid: number;
	errors: number;
	warnings: number;
	blank_spaces: number;
}

export const DefaultSummary: ISummary = {
	valid: 0,
	errors: 0,
	warnings: 0,
	blank_spaces: 0,
};
