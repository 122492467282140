import CreateIcon from '@mui/icons-material/Create';
import IconButton from '@mui/material/IconButton';

interface IEditProps {
	onClick: () => void;
	disabled?: boolean;
	icon?: JSX.Element;
	styles?: React.CSSProperties;
	id?: string;
}

export const EditButton = (props: IEditProps) => {
	const { onClick, disabled = false, icon, styles, id = 'btn-edit' } = props;
	return (
		<IconButton
			sx={{ color: '#0080F9', background: '#d4eafe', borderRadius: 0, ...styles }}
			aria-label=""
			component="label"
			onClick={onClick}
			disabled={disabled}
			id={id}
		>
			{icon ? icon : <CreateIcon sx={{ fontSize: '15px' }} />}
		</IconButton>
	);
};
