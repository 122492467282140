/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { emailValid } from '../../../utils/validators';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { IResponseLogin } from '../interfaces/login.interface';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { IChangePassword } from '../interfaces/change-password.interface';
import { defaultAlertValue, IAlert } from '../../../interfaces/alert.interface';

export const useResetPassword = () => {
	const [data, setData] = useState({ email: '', password: '', token: '' });
	const [errorEmail, setErrorEmail] = useState({ valid: false, msg: '' });
	const [error, setError] = useState<IAlert>(defaultAlertValue);
	const [isMailSend, setIsMailSend] = useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		execute: resetPass,
		status: statusPass,
		value: dataPass,
	} = useApiRequest<any, IResponseLogin>({
		path: URLS.LOGIN.RESET_PASSWORD,
		method: 'POST',
	});

	const { execute: changePass, status: statusChangePass } = useApiRequest<
		IChangePassword,
		unknown
	>({
		path: URLS.USERS.CHANGE_PASSWORD,
		method: 'POST',
	});

	useEffect(() => {
		if (token) setData({ ...data, token: token });
	}, []);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'email') {
			const validate = emailValid(value);
			setErrorEmail(validate);
		}
		setData({ ...data, [name]: value });
	};

	useEffect(() => {
		if (statusPass === 'success') {
			setErrorEmail({
				valid: true,
				msg: dataPass?.message || 'La contraseña se restableció con éxito',
			});
			setIsMailSend(true);
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message:
						dataPass?.message || 'La contraseña se restableció con éxito',
					severity: 'success',
				})
			);
		} else if (statusPass === 'error') {
			setIsMailSend(false);
			dispatch(
				setAlert({
					show: true,
					message:
						dataPass?.message || 'No se ha podido reiniciar la contraseña',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusPass]);

	useEffect(() => {
		if (statusChangePass === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'La contraseña se restableció correctamente',
					severity: 'success',
				})
			);
			dispatch(stopLoading());
			navigate('/');
		} else if (statusChangePass === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'No se ha podido restablecer la contraseña',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusChangePass]);

	const handleOnSubmit = () => {
		dispatch(setLoading({ loading: true }));
		resetPass({}, `/${data.email}`);
	};

	const handleChangePassword = () => {
		dispatch(setLoading({ loading: true }));
		changePass({ ...data });
	};

	const handleValidate = (): void => {
		if (!errorEmail.valid || !data.email) {
			setErrorEmail({
				valid: false,
				msg: 'Complete correctamente el campo de Correo electrónico',
			});
			return setError({
				show: true,
				message: 'Complete correctamente el campo de Correo electrónico',
				severity: 'error',
			});
		}
		token ? handleChangePassword() : handleOnSubmit();
	};

	const disabledButton = useMemo((): boolean => {
		if (!data.email || !errorEmail.valid || (token && !data.password)) return true;
		return false;
	}, [data]);

	return {
		error,
		data,
		errorEmail,
		isMailSend,
		token,
		disabledButton,
		handleValidate,
		handleSetData,
	};
};
