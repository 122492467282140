/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { getSingleAdaptedData } from '../../../../utils/get-adapted-data';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { IValuesInfo } from '../../../../interfaces/edit-values.interface';

interface IUseCreateIndicator {
	indicatorsOptions: IAdaptedValues[];
	setIndicatorsOptions: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
}

export const useCreateIndicator = ({
	indicatorsOptions,
	setIndicatorsOptions,
}: IUseCreateIndicator) => {
	const dispatch = useDispatch();

	const {
		execute: createIndicator,
		status: statusCreateIndicator,
		value: valueCreateIndicator,
	} = useApiRequest<any, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.INDICATORS.CREATE_INDICATOR,
		method: 'POST',
	});

	useEffect(() => {
		if (statusCreateIndicator === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Indicador agregado con éxito',
					severity: 'success',
				})
			);
			const temporalIndicators = indicatorsOptions;
			temporalIndicators.pop();
			setIndicatorsOptions([
				...temporalIndicators,
				getSingleAdaptedData(valueCreateIndicator?.detail),
			]);
			dispatch(stopLoading());
		} else if (statusCreateIndicator === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al agregar el indicador',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusCreateIndicator]);

	const handleCreatestatusCreateIndicator = (body: any) => {
		dispatch(setLoading({ loading: true }));
		createIndicator(body);
	};

	return { handleCreatestatusCreateIndicator };
};
