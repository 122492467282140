import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { IGetActivitiesUA } from '../interfaces/get-activities-UA.interface';

export const getOptionsActivitiesUA = (data: IGetActivitiesUA[]): IOptionsMenu[] => {
	const filteredData = data.filter((element) => element.activity);
	const activitiesUA = filteredData?.map((e) => {
		return {
			id: e.activity?._id,
			value: e.activity?._id,
			label: e.activity?.columns[0],
		};
	});
	return activitiesUA as IOptionsMenu[];
};
