import { IMenuList, styleIcon, styleIconContainer } from '../utils/resources.utils';
import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/svg/projects.svg';
import { ReactComponent as DevicesIcon } from '../../assets/svg/devices.svg';
import Home from '../../pages/home';
import { UserProfile } from '../../pages/user-profile';
import { SvgIcon } from '@mui/material';
import { ProjectProfile } from '../../pages/profile-project';
import { SupervisionHistory } from '../../pages/supervision-history';
import { SupervisionExtraordinary } from '../../pages/supervision-extraordinary';
import { SupervisionDetail } from '../../pages/supervision-detail-project';
import { NextTasks } from '../../pages/next-tasks';
import { SupervisionDetailTasks } from '../../pages/supervision-detail-project/supervisionDetailTasks';
import { ExtraordinaryIncidence } from '../../pages/extraordinary-incidence';
import { SummaryRoutine } from '../../pages/supervision-project/components/summary-routine/summary-routine.component';
import { SupervisionComponent } from '../../pages/supervision-project';
import NotificationList from '../../pages/notifications';
import { SuspendRoutine } from '../../pages/suspend-routine';

export const RouteSupervisorList: IMenuList[] = [
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Inicio',
		link: '/inicio',
		Component: Home,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Mi Perfil',
		link: '/mi-perfil',
		Component: UserProfile,
		hideMenu: true,
	},
	{
		icon: (
			<SvgIcon>
				<ProjectsIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Perfil de proyecto',
		link: '/perfil-proyecto',
		hideMenu: true,
		Component: ProjectProfile,
		submenu: [
			{
				title: 'Detalle de la supervisión',
				link: '/ver-supervision',
				Component: SummaryRoutine,
				hideMenu: true,
			},
			{
				title: 'Historial de supervisión',
				link: '/historial-supervision',
				Component: SupervisionHistory,
				hideMenu: true,
			},
			{
				title: 'Incidencia extraordinaria',
				link: '/incidencia-extraordinaria',
				Component: ExtraordinaryIncidence,
				hideMenu: true,
			},
			{
				title: 'Supervision',
				link: '/supervision',
				Component: SupervisionComponent,
				hideMenu: true,
			},
			{
				icon: (
					<SvgIcon>
						<ProjectsIcon style={styleIcon} />
					</SvgIcon>
				),
				title: 'Supervisión extraordinaria',
				link: '/supervision-extraordinaria',
				hideMenu: true,
				Component: SupervisionExtraordinary,
				submenu: [
					{
						title: 'Detalle de la supervisión',
						link: '/ver-supervision',
						Component: SupervisionDetail,
						hideMenu: true,
					},
				],
			},
			{
				icon: (
					<SvgIcon>
						<ProjectsIcon style={styleIcon} />
					</SvgIcon>
				),
				title: 'Próximas tareas',
				link: '/proximas-tareas',
				hideMenu: true,
				Component: NextTasks,
				submenu: [
					{
						title: 'Detalle de la supervisión',
						link: '/ver-supervision',
						Component: SupervisionDetailTasks,
						hideMenu: true,
						submenu: [
							{
								title: 'Suspender supervisión',
								link: '/suspender-supervision',
								Component: SuspendRoutine,
								hideMenu: true,
							},
						],
					},
				],
			},
		],
	},
	{
		icon: (
			<SvgIcon>
				<DevicesIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Notificaciones',
		link: '/notificaciones',
		Component: NotificationList,
		hideMenu: true,
	},
];
