/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper, Tab, Tabs } from '@mui/material';
import styles from './styles/view-details-styles.module.css';
import { GoBack } from '../../../../components/go-back-component';
import { useViewDetails } from './hooks/useViewDetails';

export const ViewDetails = () => {
	const { currentTab, matches, tabItems, setCurrentTab, getComponent, undoState } =
		useViewDetails();

	return (
		<>
			<GoBack action={() => undoState()} />
			<Tabs
				value={currentTab}
				variant="scrollable"
				onChange={(_, value) => {
					setCurrentTab(value);
				}}
				TabIndicatorProps={{
					style: { background: '#0080F9' },
				}}
				sx={{
					'& button.Mui-selected': {
						color: '#0080F9',
					},
					width: '100%',
				}}
				orientation={matches ? 'vertical' : 'horizontal'}
			>
				{(tabItems || []).map((element, index: number) => {
					return (
						<Tab
							key={index}
							className={styles.tabsStyle}
							label={element.label}
						/>
					);
				})}
			</Tabs>
			<Paper>
				<Box padding={3}>{getComponent()}</Box>
			</Paper>
		</>
	);
};
