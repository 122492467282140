import { Edit } from '@mui/icons-material';
import ButtonIconSmall from '../../../../components/button-icon-small.component';
import ImageCarousel from '../../../../components/image-carousel.component';
import { IFile, IFindings } from '../interfaces/new-incident.interface';

export const getFilesFindingsAdapter = (
	data: IFindings[],
	onEdit: (finding: IFindings, isSuspension: boolean) => void,
	isSupervisionRoutine: boolean
) => {
	const newOptions = data?.map((element) => {
		return {
			img: <ImageCarousel images={getImages(element?.findings || [])} />,
			typeFinding: element?.typeFinding || '',
			findingDescription: element?.findingDescription || '',
			actions: isSupervisionRoutine && (
				<ButtonIconSmall
					text="Editar"
					type="info"
					icon={<Edit />}
					onPress={() => onEdit(element, false)}
				/>
			),
		};
	});
	return newOptions;
};

export const getFilesSuspensionsAdapter = (
	data: IFindings[],
	onEdit: (finding: IFindings, isSuspension: boolean) => void,
	isSupervisionRoutine: boolean
) => {
	const newOptions = data?.map((element) => {
		return {
			img: <ImageCarousel images={getImages(element?.suspensions || [])} />,
			causeSuspension: element?.causeSuspension || '',
			suspensionDescription: element?.suspensionDescription || '',
			actions: isSupervisionRoutine && (
				<ButtonIconSmall
					text="Editar"
					type="info"
					icon={<Edit />}
					onPress={() => onEdit(element, true)}
				/>
			),
		};
	});
	return newOptions;
};

export const getFilesEvidencesAdapter = (data: IFindings[]) => {
	const newOptions = data?.map((element) => {
		return {
			img: (
				<ImageCarousel
					images={getImages(element?.supervisionRoutineFiles || [])}
				/>
			),
			descriptionEvidence: element?.supervisionRoutineFiles[0]?.description || '',
		};
	});
	return newOptions;
};

const getImages = (images: IFile[]) => {
	const imagesAdapted = images?.map((e) => {
		return { label: e.description, imgPath: e.url, id: e._id || '' };
	});

	return imagesAdapted;
};
