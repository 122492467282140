import { Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from './main-button.component';
import { ProgressBar } from './progress-bar.component';
import { Circle, WorkHistoryOutlined } from '@mui/icons-material';
import styles from './styles/supervision-card-styles.module.css';
import { STATUS_ROUTINE } from '../constants/status-routine';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { isCoordinador, isDirector, isExternal } from '../utils/permissions.utils';

interface ISupervisionComponent {
	supervisionTitle: string;
	supervisor?: string;
	idSupervisor?: string;
	pendingSupervision: number;
	pendingValidation: number;
	suspendedTasks: number;
	completedTasks: number;
	routine?: string;
	routineType?: string;
	value: number;
	isDetail?: boolean;
	showContinue?: boolean;
	showPendiente?: boolean;
	routineStatus?: string;
	idProject?: string;
	unitInfo?: string;
}

export const SupervisionCardComponent = ({
	supervisionTitle,
	supervisor,
	idSupervisor,
	pendingSupervision,
	pendingValidation,
	suspendedTasks,
	completedTasks,
	routine,
	value,
	isDetail,
	showContinue,
	showPendiente,
	routineStatus,
	idProject,
	routineType,
	unitInfo,
}: ISupervisionComponent) => {
	const navigate = useNavigate();
	const { role } = useSelector((state: RootState) => state.users);

	return (
		<Paper elevation={0} className={!isDetail ? styles.backgroundGrey : ''}>
			<Grid
				container
				padding={2}
				justifyContent="space-between"
				className={styles.title}
				id="supervision-card"
			>
				<Grid item xs={12} md>
					<div>
						{!isDetail && !isExternal(role) && (
							<Grid
								item
								sm
								className={styles.blue}
								container
								justifyContent="flex-end"
							>
								<span
									onClick={() => {
										navigate('ver-supervision', {
											state: {
												isTask: false,
												routine: routine,
												supervisor: idSupervisor,
												idProject: idProject,
												supervisorName: supervisor,
											},
										});
									}}
								>
									Ver supervisión
								</span>
							</Grid>
						)}
					</div>
					<div
						style={{
							fontSize: '14px',
							color: '#707070',
							textAlign: 'center',
							margin: '10px 0px',
						}}
					>
						<div> {supervisionTitle}</div>
						<div> {supervisor} </div>
					</div>
				</Grid>
				<Grid item sm={12} md={12}>
					<Grid container alignItems="center" justifyContent="center" gap={2}>
						<div className="center ">
							<Grid item md>
								<ProgressBar type="circular" value={Number(value)} />
							</Grid>
						</div>
						<Grid item md>
							<div className={styles.infoTaskContainer}>
								<span className={styles.grey}>Tareas</span>
								<div className={styles.listTask}>
									<Circle
										className={styles.circleTask}
										style={{
											color: 'var(--color-calendar-executing)',
										}}
									/>
									{pendingSupervision} Pendientes de supervisión
								</div>
								<div className={styles.listTask}>
									<Circle
										className={styles.circleTask}
										style={{
											color: 'var(--color-yellow-main)',
										}}
									/>
									{pendingValidation} Pendientes de validar
								</div>
								<div className={styles.listTask}>
									<Circle
										className={styles.circleTask}
										style={{
											color: 'var(--color-calendar-executing)',
										}}
									/>
									{suspendedTasks} Total suspendidas
								</div>
								<div className={styles.listTask}>
									<Circle
										className={styles.circleTask}
										style={{
											color: 'var(--color-calendar-accomplished)',
										}}
									/>
									{completedTasks} Total completadas
								</div>

								{showPendiente &&
									routineStatus !== STATUS_ROUTINE.ACCOMPLISHED &&
									routineStatus !== STATUS_ROUTINE.PENDING_TO_EXECUTE &&
									value.toString() !== '100.00' &&
									!isExternal(role) && (
										<Button
											icon={WorkHistoryOutlined}
											label="Suspender supervisión"
											onClick={() => {
												navigate('/suspender-supervision', {
													state: {
														routine: routine,
														supervisorName: supervisor,
														unitInfo: unitInfo,
														backAction: true,
													},
												});
											}}
											style={{
												backgroundColor:
													'var(--color-yellow-main)',
											}}
										/>
									)}
								{showContinue &&
									routineStatus !== STATUS_ROUTINE.ACCOMPLISHED &&
									!isExternal(role) &&
									!isDirector(role) &&
									!isCoordinador(role) &&
									value.toString() !== '100.00' && (
										<Button
											label={`${
												(value.toString() === '0.00' ||
													value === 0.0) &&
												routineStatus !== STATUS_ROUTINE.EXECUTING
													? 'Iniciar'
													: 'Continuar'
											} supervisión`}
											onClick={() => {
												navigate('/perfil-proyecto/supervision', {
													state: {
														routine: routine,
														supervisor: idSupervisor,
														status: routineStatus,
														description: supervisionTitle,
														idProject: idProject,
														routineType: routineType,
														supervisorName: supervisor,
													},
												});
											}}
											style={{ backgroundColor: '#0080f9' }}
										/>
									)}
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};
