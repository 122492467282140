import ButtonIconSmall from '../../../components/button-icon-small.component';
import { RemoveRedEye } from '@mui/icons-material';
import { getDateLocalMX } from '../../../utils/dates';
import { ITask } from '../../../interfaces/project.interface';
import { getRoutineDescriptionByType } from '../../../utils/get-title-routines';
import { ITypeRoutine } from '../../../constants/routines.constants';

export const routineSupervisionAdapter = (
	data: ITask[],
	viewDetails: (item: ITask) => void
): ITask[] => {
	const tasks: ITask[] = data.map((routine: ITask) => {
		return {
			...routine,
			routineDescription: getRoutineDescriptionByType(
				routine.type as keyof ITypeRoutine,
				routine.executionDate as unknown as Date
			),
			assignedTo: routine.supervisorName || routine.teamName,
			date: getDateLocalMX(routine.executionDate),
			acciones: (
				<ButtonIconSmall
					text="Ver"
					type="info"
					icon={<RemoveRedEye />}
					style={{ fontSize: '10px' }}
					onPress={() => viewDetails(routine)}
				/>
			),
		};
	});
	return tasks;
};
