import { IColumns } from '../../../../../interfaces/table.insterface';

export const columnsService: IColumns[] = [
	{
		text: 'Servicios',
		nameID: 'activityName',
	},
	{
		text: '',
		nameID: 'acciones',
		align: 'right',
	},
];

export const columnsActivityRoutines: IColumns[] = [
	{
		text: 'Supervisión',
		nameID: 'description',
		width: '400px',
	},
	{
		text: 'Asignado a',
		nameID: 'assignedTo',
		width: '400px',
	},
	{
		text: '',
		nameID: 'acciones',
	},
];
