export const LABELS_LVL_1_1 = Object.freeze({
	title: '1. Enlista y nombra los elementos tipo nivel 1.',
	alertText:
		'Designa el nombre especifico de cada elemento nivel 1. Ejemplo: Urgencias, Gabinetes, Cirugía',
});

export const LABELS_LVL_1_2 = Object.freeze({
	title: '2. Asigna o edita tus atributos.',
	alertText: 'Designe el nombre específico de los atributos de tu espacio nivel 1',
});

export const LABELS_LVL_2_1 = Object.freeze({
	title: '2. Enlista y nombra los elementos del tipo Nivel 2',
	alertText:
		'Designa el nombre específico de cada elemento Nivel 2. Ejemplo: Oficina del jefe del servicio, secretaria, etc',
});

export const LABELS_LVL_2_2 = Object.freeze({
	title: '3. Asigna o edita tus atributos.',
	alertText: 'Designe el nombre específico de los atributos de tu espacio nivel 2',
});
