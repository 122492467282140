import LabelStatus from '../components/label-status.component';
import { STATUS_SELECT_TYPES } from '../constants/status-select-types';

const LabelProps: React.CSSProperties = {
	textAlign: 'center',
	width: 'max-content',
	height: 'auto',
	marginLeft: 'auto',
};

export const getEstatusService = (
	status: boolean | JSX.Element | string
): JSX.Element => {
	switch (status) {
		/* Creacion */
		case STATUS_SELECT_TYPES.COMPLETADO:
		case true:
			return <LabelStatus text="Terminado" type="success" style={LabelProps} />;
		case STATUS_SELECT_TYPES.PENDIENTE:
		case false:
			return <LabelStatus text="Pendiente" type="warning" style={LabelProps} />;
		/* Ejecucion */
		case STATUS_SELECT_TYPES.COMPLETED:
			return <LabelStatus text="Terminada" type="completed" style={LabelProps} />;
		case STATUS_SELECT_TYPES.PENDING_VALIDATION:
			return (
				<LabelStatus
					text="Pendiente de validación"
					type="pending-validation"
					style={LabelProps}
				/>
			);
		case STATUS_SELECT_TYPES.SUSPENDED:
			return <LabelStatus text="Suspendido" type="suspended" style={LabelProps} />;
		case STATUS_SELECT_TYPES.PENDING_SUPERVISION:
			return (
				<LabelStatus
					text="Pendiente de supervisión"
					type="suspended"
					style={LabelProps}
				/>
			);
		default:
			return <LabelStatus text="Pendiente" type="warning" style={LabelProps} />;
	}
};
