export interface INewExtraordinaryIncident {
	findingDescription: string;
	typeFinding: string;
	activity: string;
	supervisor: string | null;
	team: string | null;
	project: string;
	functional?: string;
	functional1?: string;
	functional2?: string;
	indicator: string;
	files: IFiles[];
}

export interface IFiles {
	fileName: string;
	fileBase64: string;
	type: string | number;
	description: string;
	size?: string;
}

export const DefaultNewExtraordinaryIncident: INewExtraordinaryIncident = {
	findingDescription: '',
	typeFinding: '',
	activity: '',
	supervisor: '',
	team: '',
	project: '',
	functional: '',
	functional1: '',
	functional2: '',
	indicator: '',
	files: [],
};

export interface ISelect {
	_id: string;
	description: string | number;
}

export interface IGetLevels {
	totalLevels: number;
	levels: string[];
}

export interface IGetTypesFindings {
	arrayTypes: string[];
	totalLevels: number;
}

export type NewExtraordinaryIncidentKeys = keyof INewExtraordinaryIncident;
