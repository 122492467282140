import { Grid, useMediaQuery } from '@mui/material';
import TextArea from '../../../components/text-area.component';
import SelectComponent from '../../../components/select.component';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import styles from './styles/item-text-area-styles.module.css';
import theme from '../../../config/theme';

interface IItem {
	item: any;
	column: number;
	freqOptions: IOptionsMenu[];
	handleFrecuencia: (column: number, txt: any, item: any) => void;
}

export const ItemTextArea = ({ item, column, freqOptions, handleFrecuencia }: IItem) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid item xs={matches ? 10 : 11} className={styles.renderItem}>
			{item.rows.map((el: any, row: any) => (
				<TextArea
					key={`table-${column}-${row}`}
					RenderTitle={() => {
						if (column === 0) return <p>{el.key}</p>;
						return null;
					}}
					defaultValue={el.value}
					onChange={() => null}
					onPaste={() => null}
					hideCopyPaste
					isReadonly
				/>
			))}
			<SelectComponent
				formSx={{ width: '100%', marginTop: 0 }}
				selectSx={{ height: '3.5rem' }}
				placeholder={'Selecciona un valor'}
				onChange={(e) => {
					handleFrecuencia(column, e.target.value, item);
				}}
				RenderTitle={() => {
					if (column > 0) return null;
					return <p>Frecuencia</p>;
				}}
				value={item.frequency}
				optionsMenu={freqOptions}
				id={`select-${column}`}
			/>
		</Grid>
	);
};
