import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { AreaSelect } from './area-select.component';
import { AreaViewLvl } from './area-view-lvl.component';
import { AreaSelectLvl } from './area-select-lvl.component';
import { SupervisionHistoryDetail } from '../supervision-detail.component';
import {
	IDimensionEspacial,
	INivel,
	IRoutine,
} from '../../../../interfaces/logic.interface';
import { RoutineDetailEvidence } from '../routine/routine-detail-evidence.component';
import { SupervisionViewActions } from '../supervision-view-actions';
import { SupervisionViewReports } from '../supervision-view-reports';
import { SupervisionViewActivities } from '../supervision-view-activities';
import { SupervisionHistoryContext } from '../../contexts/useSupervisionHistoryContext';
import { DefaultDataSelect } from '../../interfaces/supervision-history.interface';

interface IAreaMain {
	setOption: React.Dispatch<React.SetStateAction<string>>;
}

export const AreaMain = ({ setOption }: IAreaMain) => {
	const [areaSelected, setAreaSelected] = useState<IDimensionEspacial>({
		_id: '',
		description: '',
		nivel: [],
	});
	const [levelSelected, setLevelSelected] = useState<INivel>({
		_id: '',
		nombre: '',
		nivel: [],
		actividades: [],
		routine: '',
	});
	const [routineSelected, setRoutineSelected] = useState<IRoutine>({
		_id: '',
		description: '',
	});
	const [dataSelect, setDataSelect] = useState<any>(DefaultDataSelect);
	const step = useSelector((state: RootState) => state.stateStep);

	return (
		<SupervisionHistoryContext.Provider value={{ dataSelect, setDataSelect }}>
			{step.state === 0 && (
				<AreaSelect setOption={setOption} setArea={setAreaSelected} />
			)}
			{step.state === 1 && (
				<AreaSelectLvl
					area={areaSelected}
					setArea={setAreaSelected}
					setLvl={setLevelSelected}
				/>
			)}
			{step.state === 2 && (
				<AreaViewLvl lvl={levelSelected} setRoutine={setRoutineSelected} />
			)}
			{step.state === 3 && (
				<SupervisionHistoryDetail
					routine={routineSelected}
					canBeExecuted={routineSelected.canBeExecuted}
				/>
			)}
			{step.state === 4 && <RoutineDetailEvidence />}
			{step.state === 5 && <SupervisionViewActions />}
			{step.state === 6 && <SupervisionViewReports />}
			{step.state === 7 && <SupervisionViewActivities />}
		</SupervisionHistoryContext.Provider>
	);
};
