/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IActionsSH } from '../interfaces/tables.interface';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { actionsSHAdapter } from '../adapters/actions-adapter-sh.adapter';

export function useAllActions() {
	const { data: dataActions, setData: setDataActions } = useTableCustom<Object>();
	const dispatch = useDispatch();

	const {
		execute: executeAllActions,
		status: statusAllActions,
		value: valueAllActions,
	} = useApiRequest<unknown, IResponsePayload<IActionsSH>>({
		path: URLS.ACTIONS.GET_ACTIONS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusAllActions === 'success') {
			setDataActions({
				...dataActions,
				countRows: valueAllActions?.totalRecords || 0,
				rows: actionsSHAdapter(valueAllActions?.data || []),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusAllActions === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
		dispatch(stopLoading());
	}, [statusAllActions]);

	return {
		dataActions,
		setDataActions,
		executeAllActions,
	};
}
