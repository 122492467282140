/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import theme from '../../../../../config/theme';
import { useDispatch } from 'react-redux';
import { SummaryRoutineDetail } from '../components/summary-routine-detail.component';
import { InitialEvidences } from '../components/initial-evidences.component';
import { Evidences } from '../../evidences.component';
import { Findings } from '../../findings.component';
import { Suspensions } from '../../suspensions.component.';

export function useSummaryRoutine() {
	const [currentTab, setCurrentTab] = useState<number>(0);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();

	const tabItems = [
		{ label: 'Resumen', value: 0 },
		{ label: 'Testigos de inicio', value: 1 },
		{ label: 'Evidencias', value: 2 },
		{ label: 'Hallazgos', value: 3 },
		{ label: 'Suspensiones', value: 4 },
	];

	const getComponent = () => {
		switch (currentTab) {
			case 0:
			default:
				return <SummaryRoutineDetail />;
			case 1:
				return <InitialEvidences />;
			case 2:
				return <Evidences isSupervisionRoutine={false} />;
			case 3:
				return <Findings isSupervisionRoutine={false} isFindings={true} />;
			case 4:
				return <Suspensions isSupervisionRoutine={false} isFindings={false} />;
		}
	};

	return { currentTab, matches, tabItems, setCurrentTab, dispatch, getComponent };
}
