/* eslint-disable react-hooks/exhaustive-deps */
import { SupervisionProjectContext } from './contexts/useSupervisionProjectContext';
import { useSupervisionProject } from './hooks/useSupervisionProject';

export const SupervisionComponent = () => {
	const {
		descriptionRoutine,
		idRoutine,
		mainSelect,
		supervisionAction,
		options,
		subSelect,
		statusChangeStatus,
		supervisionRoutineId,
		statusCompleteSupervision,
		valueCompleteSupervision,
		idProject,
		withStatus,
		supervisor,
		supervisorName,
		setMainSelect,
		setSupervisionAction,
		getComponent,
		setOptions,
		setSubSelect,
		executeChangeStatus,
		setStatus,
		setSupervisionRoutineId,
		executeCompleteSupervision,
		setStatusCompleteSupervision,
		setNewState,
		undoState,
		setWithStatus,
		historyState,
		editFinding,
		setEditFinding,
		unitInfo,
		setUnitInfo,
	} = useSupervisionProject();

	return (
		<SupervisionProjectContext.Provider
			value={{
				descriptionRoutine,
				idRoutine,
				idProject,
				mainSelect,
				supervisor,
				supervisorName,
				setMainSelect,
				supervisionAction,
				setSupervisionAction,
				options,
				setOptions,
				subSelect,
				setSubSelect,
				statusChangeStatus,
				executeChangeStatus,
				setStatus,
				supervisionRoutineId,
				setSupervisionRoutineId,
				statusCompleteSupervision,
				executeCompleteSupervision,
				setStatusCompleteSupervision,
				valueCompleteSupervision,
				setNewState,
				undoState,
				withStatus,
				setWithStatus,
				historyState,
				editFinding,
				setEditFinding,
				unitInfo,
				setUnitInfo,
			}}
		>
			{getComponent()}
		</SupervisionProjectContext.Provider>
	);
};
