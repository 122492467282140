export interface ISupervisionExtraordinaryData {
	idSupervisionExtraordinary: string;
	step: string;
	functionalUnit: string;
}

export const DefaultSupervisionExtraordinary: ISupervisionExtraordinaryData = {
	idSupervisionExtraordinary: '',
	step: 'x',
	functionalUnit: '',
};
