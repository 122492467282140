export interface INewUser {
	firstName: string;
	lastName: string;
	employeeNumber: number;
	email: string;
	position: string;
	role: string;
	password: string;
	token: string;
}

export interface IResponseUser extends INewUser {
	_id: string;
}

export type NewUserModelKeys = keyof INewUser;

export const defaultNewUser = {
	firstName: '',
	lastName: '',
	employeeNumber: 0,
	email: '',
	position: '',
	role: '',
	password: '',
	token: '',
};
