import Webcam from 'react-webcam';
import { useCallback, useRef } from 'react';
import { useAddInfoImage } from '../../hooks/useAddInfoImage';

interface IUseCamera {
	setImg: (imageSrc: string | null | undefined) => void;
	user?: string;
	unitInfo?: string;
}

export const useCamera = ({ setImg, user, unitInfo }: IUseCamera) => {
	const webcamRef = useRef<Webcam | null>(null);
	const { addInfoImage } = useAddInfoImage({ setImg, user, unitInfo });

	const captureImage = () => {
		const webcam = webcamRef.current;
		if (webcam) {
			const imageSrc = webcam.getScreenshot({ width: 1920, height: 1080 });
			addInfoImage(imageSrc);
		}
	};

	const captureWithoutInfo = useCallback(() => {
		const imageSrc = webcamRef.current?.getScreenshot({ width: 1920, height: 1080 });
		setImg(imageSrc);
	}, [webcamRef, setImg]);

	return { captureImage, captureWithoutInfo, webcamRef };
};
