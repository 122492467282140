/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { URLS } from '../../../constants/urls.constants';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { stopLoading } from '../../../store/modules/loading';
import { suspensionAdapter } from '../adapters/suspensions.adapter';
import { setAlert } from '../../../store/modules/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISuspension } from '../interfaces/suspension.interface';
import { RootState } from '../../../store';
import { setPage } from '../../../store/modules/page-table';

export const useSuspension = () => {
	const { page, rowsPerPage: rows } = useSelector(
		(state: RootState) => state.pageTable
	);
	const { data, setData, pagination, rowsPerPage, filters } = useTableCustom<Object>({
		countRows: 0,
		rows: [],
		sort: { col: '', order: '' },
		pagination: page,
		rowsSelect: [],
		rowsPerPage: rows,
		advancedFilter: '',
		activeAdvanceFilter: false,
		filterCustom: {},
	});
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const {
		execute: getSuspensions,
		status: statusSuspensions,
		value: dataSuspensions,
	} = useApiRequest<unknown, IResponsePayload<ISuspension>>({
		path: URLS.SUSPENSION.SUSPENSION_MANAGEMENT,
		method: 'GET',
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location?.state?.params;

	useEffect(() => {
		if (statusSuspensions === 'success') {
			setData({
				...data,
				countRows: dataSuspensions?.totalRecords || 0,
				rows: suspensionAdapter(dataSuspensions?.data || [], viewDetails),
			});
			dispatch(stopLoading());
		} else if (statusSuspensions === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar las suspensiones',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusSuspensions]);

	const viewDetails = (routine: string) => {
		dispatch(
			setPage({
				page: data.pagination,
				rowsPerPage: data.rowsPerPage,
			})
		);
		navigate('ver-suspension', {
			state: {
				routine: routine,
				idProject: state?.idProject || _id,
			},
		});
	};

	return {
		data,
		setData,
		filters,
		pagination,
		rowsPerPage,
		getSuspensions,
	};
};
