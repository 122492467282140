import { IColumns } from '../../../../../interfaces/table.insterface';

export const columnsViewLvl: IColumns[] = [
	{
		text: 'Fecha',
		nameID: 'date',
	},
	{
		text: 'Asignado a',
		nameID: 'assignedTo',
	},
	{
		text: 'Actividades',
		nameID: 'activities',
	},
	{
		text: 'Criterios',
		nameID: 'criterios',
	},
	{
		text: '',
		nameID: 'acciones',
	},
];
