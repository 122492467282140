import { IColumns } from '../../../interfaces/table.insterface';

export const columns: IColumns[] = [
	{
		text: 'No. de empleado',
		nameID: 'employeeNumber',
	},
	{
		text: 'Nombre',
		nameID: 'name',
	},
	{
		text: 'Correo electrónico',
		nameID: 'email',
	},
	{
		text: 'Puesto',
		nameID: 'position',
	},
	{
		text: 'Rol',
		nameID: 'role',
	},
	{
		text: 'Estatus',
		nameID: 'status',
	},
	{
		text: 'Última conexión',
		nameID: 'date',
	},
];

export const columnsActivities: IColumns[] = [
	{
		text: 'Espacios funcionales',
		nameID: 'functional',
	},
	{
		text: 'Actividades supervisadas',
		nameID: 'activities',
	},
];
