import { IColumns } from '../../../interfaces/table.insterface';

export const columnsFindingsSupervisionRoutine: IColumns[] = [
	{
		text: '',
		nameID: 'img',
		align: 'center',
	},
	{
		text: 'Tipo de hallazgo',
		nameID: 'typeFinding',
	},
	{
		text: 'Descripción del hallazgo',
		nameID: 'findingDescription',
		width: '300px',
	},
	{
		text: '',
		nameID: 'actions',
	},
];

export const columnsFindingsRoutine: IColumns[] = [
	{
		text: '',
		nameID: 'img',
		align: 'center',
	},
	{
		text: 'Tipo de hallazgo',
		nameID: 'typeFinding',
	},
	{
		text: 'Descripción del hallazgo',
		nameID: 'findingDescription',
		width: '300px',
	},
];
