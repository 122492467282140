import { IActionsList } from '../interfaces/actions.interface';

export const quickEditList: IActionsList = {
	elements: [
		{
			id: 1,
			label: 'Editar datos generales',
			action: 'navigate',
			value: '/perfil-proyecto/editar-datos-generales',
		},
		{
			id: 2,
			label: 'Editar rápidamente',
			action: 'step',
			value: 2,
		},
	],
};

export const scheduleList: IActionsList = {
	elements: [
		{
			id: 1,
			label: 'Modificar frecuencia para Actividad',
			action: 'navigate',
			value: 'cronograma-actividad',
		},
		{
			id: 2,
			label: 'Modificar frecuencia para Espacio',
			action: 'navigate',
			value: 'cronograma-unidad',
		},
		{
			id: 4,
			label: 'Modificar frecuencia para Indicadores',
			action: 'navigate',
			value: 'cronograma-indicador',
		},
		{
			id: 3,
			label: 'Editar días laborales',
			action: 'navigate',
			value: 'cronograma-editar-dias-laborales',
		},
	],
};

export const personalList: IActionsList = {
	elements: [
		{
			id: 1,
			label: 'Editar roles',
			action: 'navigate',
			value: 'edicion-roles',
		},
		{
			id: 2,
			label: 'Editar asignaciones',
			action: 'navigate',
			value: 'edicion-asignaciones',
		},
		{
			id: 3,
			label: 'Editar equipos',
			action: 'navigate',
			value: 'edicion-equipos',
		},
	],
};
