import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { SortableTask } from './sorteable-task.component';
import { useDroppable } from '@dnd-kit/core';
import styles from './styles/droppable-styles.module.css';

interface IDroppable {
	id: string;
	items: any[];
}

const Droppable = ({ id, items }: IDroppable) => {
	const { setNodeRef } = useDroppable({ id });

	return (
		<SortableContext id={id} items={items} strategy={rectSortingStrategy}>
			<ul className={styles.droppable} ref={setNodeRef}>
				{items.map((item: any) => (
					<SortableTask key={item.id} task={item} id={item.id} />
				))}
			</ul>
		</SortableContext>
	);
};

export default Droppable;
