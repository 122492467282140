import { useEffect, useState } from 'react';
import VerticalStepper from '../../../../components/vertical-stepper.component';
import renderItem from '../../styles/render-item-styles.module.css';
import Grid from '@mui/material/Grid';
import { isEmpty } from '../../../../utils/validators';
import { cloneElement } from '../../../../utils/createCriterio';
import { ICheck } from '../../../../components/table-vertical-stepper.component';
import { IItem } from '../../interfaces/logic.interface';
import { defaultDias } from '../../adapters/data.adapters';
import { ITable } from '../../../../interfaces/criterios.interface';
import SelectComponent from '../../../../components/select.component';
import { Checkbox, FormControlLabel } from '@mui/material';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import { monthOptions, dayOptions as dayList } from '../../../../constants/frecuencia';
import ContinueButton from '../../../../components/custom-btn.component';
import DashedBtn from '../../../../components/dashed-button.component';

const dayOptions: IOptionsMenu[] = Array.from(Array(31), (_, i) => ({
	id: i + 1,
	label: i + 1,
	value: i + 1,
}));

const Title = (item: ICheck, idx?: number) => {
	return (
		<Grid
			item
			xs={2}
			sx={{
				height: '100%',
				backgroundColor: '#fff',
				position: 'sticky !important',
				left: '70px',
				background: '#fff',
				zIndex: 1,
				marginRight: '5rem',
			}}
		>
			<p className={renderItem.renderTitle}>
				{item.label} {(idx || 0) + 1}
			</p>
		</Grid>
	);
};

const ColumnItem = (props: IItem) => {
	const { item, column, handleTable, disabled } = props;
	return (
		<Grid container className={renderItem.renderItem} spacing={2}>
			{item.rows.map((el, row) => (
				<Grid item sx={{ flex: 1 }}>
					<SelectComponent
						id={`select-${column}-${row}`}
						formSx={{ width: '100%' }}
						disabled={disabled}
						RenderTitle={() => {
							return null;
						}}
						placeholder={el.key === 'day' ? 'Día' : 'Mes'}
						value={el.value}
						optionsMenu={el.key === 'day' ? dayOptions : monthOptions}
						onChange={(e) => {
							handleTable(column, row, e.target.value);
						}}
					/>
				</Grid>
			))}
		</Grid>
	);
};

interface IStepProps {
	defaultData?: {
		selectedDays: Set<number | string>;
		days: ITable[];
	} | null;
	isDone: boolean;
	onContinue?: (days: ITable[], selected: Set<number | string>) => void;
}

const StepTwoContent = (props: IStepProps) => {
	const { onContinue, isDone, defaultData } = props;
	const [creacion, setCreacion] = useState<ITable[]>([]);
	const [selectedDays, setSelectedDays] = useState<Set<number | string>>(new Set());

	useEffect(() => {
		if (!defaultData) {
			setCreacion(defaultDias);
			return;
		}
		const { days, selectedDays } = defaultData;
		setSelectedDays(selectedDays);
		setCreacion(days);
	}, [defaultData]);

	const isNextAllowed = true;

	const handleTable = (column: number, row: number, value: any) => {
		setCreacion((prev) =>
			prev
				.map((i, idx) => {
					return {
						...i,
						rows: i.rows.map((r, ydx) => {
							if (idx === column && ydx === row)
								return { ...r, value: value };
							return r;
						}),
					};
				})
				.map((i) => ({
					...i,
					isComplete: i.rows
						.slice(0, 3)
						.every((el) => isEmpty(el.value) === false)
						? true
						: false,
				}))
		);
	};

	const handleContinue = () => {
		if (onContinue) onContinue(creacion, selectedDays);
	};

	const handleAddSpace = () => {
		const new_element = cloneElement({
			id: creacion.length,
			data: creacion,
		});
		const clone = [...creacion, new_element];
		setCreacion(clone);
	};

	const handleChangeDays = (value: number | string) => {
		const newSet = new Set(selectedDays);
		if (newSet.has(value)) newSet.delete(value);
		else newSet.add(value);
		setSelectedDays(newSet);
	};

	return (
		<>
			<h4
				style={{
					margin: '1rem 0 0 0',
					padding: '0.5rem 0 0 0.5rem',
					fontWeight: 600,
				}}
			>
				Todos los días seleccionados del año
			</h4>
			<div style={{ marginTop: '1rem', marginLeft: '1rem' }}>
				{dayList.map((i) => (
					<FormControlLabel
						value="end"
						control={
							<Checkbox
								checked={selectedDays.has(i.value)}
								id={`checkbox-${i.value}`}
							/>
						}
						onChange={() => handleChangeDays(i.value)}
						label={i.label}
						labelPlacement="end"
						disabled={isDone}
					/>
				))}
			</div>
			<h4
				style={{
					margin: '1rem 0 0 0',
					padding: '0.5rem 0 0 0.5rem',
					fontWeight: 600,
				}}
			>
				Días específicos del año
			</h4>
			<div style={{ overflow: 'auto' }}>
				<VerticalStepper
					gridKey="criterios-table"
					sxContainer={{
						flexWrap: 'nowrap',
						alignItems: 'flex-start',
					}}
					hiddeLines
					sxElement={{
						position: 'sticky !important',
						left: '-5px',
						zIndex: 1,
						height: '100%',
						background: '#fff',
						minWidth: '5rem',
					}}
					items={creacion}
					renderTitle={Title}
					renderItem={(item, column) => (
						<ColumnItem
							column={column}
							disabled={isDone}
							handleTable={handleTable}
							item={item}
						/>
					)}
				/>
			</div>
			{!isDone && (
				<div style={{ width: '100%', display: 'flex', margin: '2rem 0' }}>
					<DashedBtn
						onClick={handleAddSpace}
						width="100"
						text="Agregar día no laborable"
					/>
				</div>
			)}
			<div style={{ width: '100%', display: 'flex', marginTop: '2rem' }}>
				<ContinueButton
					customSx={{ marginLeft: 'auto' }}
					id="cronograma2-continue-btn"
					disabled={!isNextAllowed || isDone}
					onClick={handleContinue}
				/>
			</div>
		</>
	);
};

export default StepTwoContent;
