/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { isEmpty } from '../../../../../utils/validators';
import { useGetStatus } from '../../../hooks/useGetStatus';
import { setLoading } from '../../../../../store/modules/loading';
import { summaryAdapter } from '../adapters/summary-table.adapter';
import { DefaultSummary, ISummary } from '../interfaces/summary.interface';
import { AutomaticChargeContext } from '../../../contexts/useAutomaticChargeContext';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { useConfirmProgress } from '../../../hooks/useConfirmProgress';
import { STEPS_AUTOMATIC_CHARGE } from '../../../utils/steps-automatic-charge';
import { useRestartProject } from './useRestartProject';

export const useSummaryView = () => {
	const dispatch = useDispatch();
	const project = useSelector((state: RootState) => state.projectBase);
	const { valueStatus, executeStatus } = useGetStatus({ changeStep: false });
	const [canContinue, setCanContinue] = useState(false);
	const [sheets, setSheets] = useState<string[]>([]);
	const [dataTable, setDataTable] = useState<any[]>([]);
	const [selectedSheet, setSelectedSheet] = useState(0);
	const [errorsFile, setErrorsFile] = useState<string[]>([]);
	const [summaryAdapted, setSummaryAdapted] = useState<ISummary[]>([DefaultSummary]);
	const matches = useMediaQuery(useTheme().breakpoints.down('sm'));
	const { setStep } = useContext(AutomaticChargeContext);
	const { data, setData } = useTableCustom<Object>();

	const { confirmProgress } = useConfirmProgress();
	const { restartProject } = useRestartProject();

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeStatus(null, `/${project._id}`);
	}, []);

	useEffect(() => {
		handleNewData();
	}, [valueStatus]);

	useEffect(() => {
		setData({
			...data,
			countRows: dataTable[selectedSheet]?.length || 0,
			rows: dataTable[selectedSheet] || [],
		});
	}, [selectedSheet]);

	const handleNewData = () => {
		if (!isEmpty(valueStatus?.detail) && valueStatus?.detail) {
			if ((valueStatus?.detail?.errors || []).length > 0) {
				setCanContinue(false);
				saveAdaptedInfo(
					[],
					0,
					[DefaultSummary],
					valueStatus?.detail?.errors || [],
					[]
				);
				return;
			}
			switch (valueStatus?.detail?.step) {
				case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_DATA:
				default:
					summaryAdapter(valueStatus?.detail?.summary, saveAdaptedInfo);
					setCanContinue(valueStatus?.detail?.summary?.canContinue);
					break;
				case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_PERSONAL_DATA:
					summaryAdapter(valueStatus?.detail?.summaryPersonal, saveAdaptedInfo);
					setCanContinue(valueStatus?.detail?.summaryPersonal?.canContinue);
					break;
				case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_RELATIONS:
					summaryAdapter(
						valueStatus?.detail?.summaryRelations,
						saveAdaptedInfo
					);
					setCanContinue(valueStatus?.detail?.summaryRelations?.canContinue);
					break;
			}
		}
	};

	const saveAdaptedInfo = (
		dataTableAdapted: any[],
		selectedSheet: number,
		summaryAdapted: {
			valid: number;
			errors: number;
			warnings: number;
			blank_spaces: number;
		}[],
		errors: string[],
		sheets: string[]
	) => {
		setData({
			...data,
			countRows: dataTableAdapted[selectedSheet]?.length || 0,
			rows: dataTableAdapted[selectedSheet] || [],
		});
		setDataTable(dataTableAdapted);
		setSelectedSheet(selectedSheet);
		setSummaryAdapted(summaryAdapted);
		setSheets(sheets);
		if (sheets.length === 0) setErrorsFile(errors);
	};

	return {
		data,
		sheets,
		summaryAdapted,
		canContinue,
		selectedSheet,
		errorsFile,
		matches,
		setStep,
		setData,
		setSelectedSheet,
		confirmProgress,
		restartProject,
	};
};
