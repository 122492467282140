export const STEPS_AUTOMATIC_CHARGE = Object.freeze({
	NOT_STARTED: '0.0.0',
	STARTED_LOAD_DATA: '1.0.0',
	IN_PROGRESS_DATA: '1.0.1', // FRONTEND STEP
	RELOAD_DATA: '1.0.2', // FRONTEND STEP
	DATA_CONFIRMATION: '1.1.0', // FRONTEND STEP
	STARTED_PERSONAL_DATA_CONFIGURATION: '2.0.0',
	NOT_START_TEAMS: '2.0.1',
	STARTED_TEAMS_DATA_CONFIGURATION: '2.0.2',
	STARTED_LOAD_PERSONAL_DATA: '2.1.0',
	CONFIRMATION_LOAD_PERSONAL: '2.1.1', // FRONTEND STEP
	STARTED_LOAD_RELATIONS: '3.0.0',
	CONFIRMATION_LOAD_RELATIONS: '3.0.1', // FRONTEND STEP
	PROJECT_GENERATION: '3.1.0',
	SUPERVISION_EXECUTION: '4.0.0',
	FINISHED: 'x',
});
