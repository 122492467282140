/* eslint-disable react-hooks/exhaustive-deps */
import {
	FactCheckOutlined,
	InsertDriveFileOutlined,
	PendingActions,
	ReportGmailerrorredOutlined,
	WorkHistoryOutlined,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import TableCustom from '../../../../components/table-custom.component';
import styles from './styles/view-details-styles.module.css';
import { Button } from '../../../../components/main-button.component';
import ButtonIconSmall from '../../../../components/button-icon-small.component';
import { useViewDetailsInfo } from './hooks/useViewDetailsInfo';
import { STATUS_SUPERVISION_TYPES } from '../../../../constants/status-supervision';
import { useEffect } from 'react';

interface IViewDetailsInfo {
	setCurrentState: React.Dispatch<React.SetStateAction<string>>;
}

export const ViewDetailsInfo = ({ setCurrentState }: IViewDetailsInfo) => {
	const {
		data,
		mainSelect,
		options,
		valueSupervisionDetail,
		dinamicColumns,
		setData,
		handleSetAction,
		setNewState,
	} = useViewDetailsInfo();

	useEffect(() => {
		setCurrentState(valueSupervisionDetail?.detail?.status || '');
	}, [valueSupervisionDetail]);

	return (
		<Grid container spacing={3} justifyContent="flex-end">
			<Grid item sm>
				<h2 style={{ margin: 0 }}>
					{
						options.find(
							(element: any) => element.id === mainSelect.valueLabel
						)?.label
					}
				</h2>
			</Grid>
			<Grid item>
				<ButtonIconSmall
					text="Cumple"
					type="success"
					icon={<FactCheckOutlined />}
					style={{ fontSize: '11px' }}
					onPress={() =>
						handleSetAction(
							STATUS_SUPERVISION_TYPES.ACCOMPLISHED,
							valueSupervisionDetail?.detail
						)
					}
					isSelected={
						valueSupervisionDetail?.detail?.status ===
						STATUS_SUPERVISION_TYPES.ACCOMPLISHED
					}
					disabled={
						valueSupervisionDetail?.detail?.status ===
							STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED ||
						valueSupervisionDetail?.detail?.status ===
							STATUS_SUPERVISION_TYPES.SUSPENDED
					}
				/>
			</Grid>
			<Grid item>
				<ButtonIconSmall
					text="No Cumple"
					type="error"
					icon={<ReportGmailerrorredOutlined />}
					style={{ fontSize: '11px' }}
					onPress={() =>
						handleSetAction(
							STATUS_SUPERVISION_TYPES?.NOT_ACCOMPLISHED,
							valueSupervisionDetail?.detail
						)
					}
					isSelected={
						valueSupervisionDetail?.detail?.status ===
						STATUS_SUPERVISION_TYPES?.NOT_ACCOMPLISHED
					}
					disabled={
						valueSupervisionDetail?.detail?.status ===
						STATUS_SUPERVISION_TYPES.SUSPENDED
					}
				/>
			</Grid>
			<Grid item>
				<ButtonIconSmall
					text="Por validar"
					type="warning"
					icon={<WorkHistoryOutlined />}
					style={{ fontSize: '11px' }}
					onPress={() =>
						handleSetAction(
							STATUS_SUPERVISION_TYPES.PENDING_TO_VALIDATE,
							valueSupervisionDetail?.detail
						)
					}
					isSelected={
						valueSupervisionDetail?.detail?.status ===
						STATUS_SUPERVISION_TYPES.PENDING_TO_VALIDATE
					}
					disabled={
						valueSupervisionDetail?.detail?.status ===
							STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED ||
						valueSupervisionDetail?.detail?.status ===
							STATUS_SUPERVISION_TYPES.SUSPENDED
					}
				/>
			</Grid>
			<Grid item>
				<ButtonIconSmall
					text="Suspender"
					type="yellow"
					style={{ fontSize: '11px' }}
					icon={<PendingActions />}
					onPress={() =>
						handleSetAction(
							STATUS_SUPERVISION_TYPES.SUSPENDED,
							valueSupervisionDetail?.detail
						)
					}
					isSelected={
						valueSupervisionDetail?.detail?.status ===
						STATUS_SUPERVISION_TYPES.SUSPENDED
					}
					disabled={
						valueSupervisionDetail?.detail?.status ===
						STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED
					}
				/>
			</Grid>

			<Grid item xs={12}>
				<div
					style={{
						backgroundColor: '#fbfbfb',
						borderRadius: '10px',
					}}
				>
					<TableCustom
						columns={dinamicColumns}
						data={data}
						setData={setData}
						hideHeader
					/>
				</div>
			</Grid>
			<Grid item xs={12} md={6}>
				<Grid
					container
					className={styles.headerContainer}
					columnGap={1}
					rowGap={2}
				>
					<Grid item>
						<InsertDriveFileOutlined style={{ display: 'flex' }} />
					</Grid>
					<Grid item>Documentación</Grid>
				</Grid>
				{/* <Grid
								container
								className={styles.contentContainer}
								columnGap={1}
								rowGap={2}
							>
								<Grid item xs={12} style={{ color: '#0080F9' }}>
									Cédula de supervisión
									<Divider />
								</Grid>
								<Grid item xs={12} style={{ color: '#0080F9' }}>
									Boletín de supervisión
									<Divider />
								</Grid>
							</Grid> */}
			</Grid>
			<Grid item xs={12} md={6} />
			<Grid item>
				<Button
					label="Continuar"
					onClick={() => {
						setNewState(2);
					}}
					variant="outlined"
					style={{
						backgroundColor: ' #0080F9',
						width: '160px',
						color: 'white',
					}}
				/>
			</Grid>
		</Grid>
	);
};
