/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { IDocument } from '../../../../../interfaces/project.interface';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';

export const useDeleteDocument = () => {
	const dispatch = useDispatch();

	const {
		execute: executeDeleteDocument,
		value: valueDocuments,
		status: statusDeleteDocument,
	} = useApiRequest<unknown, IResponsePayload<IDocument>>({
		path: URLS.PROJECT.GET_FILES,
		method: 'DELETE',
	});

	useEffect(() => {
		if (statusDeleteDocument === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Se eliminó correctamente el archivo',
					severity: 'success',
				})
			);
		} else if (statusDeleteDocument === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueDocuments?.message ||
						'Ocurrió un error al eliminar el archivo',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusDeleteDocument]);

	const deleteDocument = (id: string) => {
		dispatch(setLoading({ loading: true }));
		executeDeleteDocument(null, `/${id}`);
	};

	return { deleteDocument, statusDeleteDocument };
};
