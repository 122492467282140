/* eslint-disable react-hooks/exhaustive-deps */
import {
	Toolbar,
	IconButton,
	Badge,
	Box,
	AppBar,
	Avatar,
	useMediaQuery,
	Tooltip,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { NotificationsOutlined, Menu as MenuIcon } from '@mui/icons-material';
//styles
import { useTheme } from '@mui/material/styles';
import BreadcrumbsComponent from './breadcrumbs.component';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetUser } from '../store/modules/user';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../pages/notifications/hooks/useNotifications';
import { isEmpty } from '../utils/validators';
import { useMsal } from '@azure/msal-react';

interface IProps {
	toggleDrawer: () => void;
	drawerWidth: number;
	arrowToggle: boolean;
}

const AppBarLayout = ({ toggleDrawer, drawerWidth, arrowToggle }: IProps) => {
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { instance } = useMsal();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const user = useSelector((state: RootState) => state.users);
	const { number } = useSelector((state: RootState) => state.notifications);
	const { getPendingNotifications } = useNotifications();

	useEffect(() => getPendingNotifications(), []);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleCloseSession = () => {
		if (user.loginMicrosoft) {
			instance
				.logoutPopup({
					postLogoutRedirectUri: '/',
				})
				.then(() => {
					dispatch(resetUser());
					document.cookie = '';
					sessionStorage.clear();
					localStorage.clear();
					navigate('/login');
				})
				.finally(() => window.location.reload());
		} else {
			dispatch(resetUser());
			navigate('/login');
		}
	};

	return (
		<AppBar
			elevation={0}
			position="fixed"
			sx={{ boxShadow: '0px 1px 6px #00000029' }}
		>
			<Toolbar
				sx={{
					ml: { minWidth: `${drawerWidth}px` },
				}}
				style={{
					background: '#FFF',
					color: '#1C1C1E',
					height: 80,
				}}
			>
				<IconButton
					color="inherit"
					aria-label="btnOpenDrawer"
					data-testid="btnOpenDrawer"
					edge="start"
					onClick={toggleDrawer}
					sx={{ mr: 2, display: { md: 'none' } }}
				>
					<MenuIcon />
				</IconButton>

				<Box
					display={'inline-flex'}
					paddingLeft={matches ? '0px' : arrowToggle ? '100px' : '320px'}
					flexGrow={1}
				>
					<BreadcrumbsComponent />
				</Box>

				<IconButton color="inherit" onClick={() => navigate('/notificaciones')}>
					<Badge
						badgeContent={number}
						invisible={isEmpty(number)}
						color="secondary"
					>
						<NotificationsOutlined />
					</Badge>
				</IconButton>

				<Tooltip title="Opciones">
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 3 }}>
						<Avatar alt={user.firstName} />
					</IconButton>
				</Tooltip>
				<Menu
					sx={{ mt: '45px' }}
					id="menu-appbar"
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					<MenuItem onClick={() => handleCloseSession()}>
						<Typography textAlign="center">Cerrar Sesión</Typography>
					</MenuItem>
					<MenuItem
						onClick={() => {
							navigate('/mi-perfil', {
								state: user._id,
							});
						}}
					>
						<Typography textAlign="center">Mi Perfil</Typography>
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
};

export default AppBarLayout;
