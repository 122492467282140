export const tablesSummary = [
	{
		title: 'Actividades por nivel 1',
		subtitle: 'Tareas asignadas',
		columns: [
			{
				name: 'Nivel 1',
				id: 'label',
			},
			{
				name: 'Número de actividades',
				id: 'total',
			},
		],
		name: 'actividadesEspacio',
	},
	{
		title: 'Actividades por servicio',
		subtitle: 'Tareas asignadas',
		columns: [
			{
				name: 'Servicio',
				id: 'label',
			},
			{
				name: 'Número de actividades',
				id: 'total',
			},
		],
		name: 'actividadesServicio',
	},
];
