export const isEmpty = (prop: any) => {
	return (
		prop === false ||
		prop === null ||
		prop === undefined ||
		(typeof prop === 'string' && prop.trim().length === 0) ||
		(Object.prototype.hasOwnProperty.call(prop, 'length') && prop.length === 0) ||
		(prop.constructor === Object && Object.keys(prop).length === 0)
	);
};

export const hasDuplicateElements = (array: string[]): string | null => {
	const seen = new Set();
	for (const element of array) {
		if (seen.has(element)) {
			return element;
		}
		seen.add(element);
	}
	return null;
};

export const emailValid = (value: string): { valid: boolean; msg: string } => {
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (value === '') return { valid: false, msg: '' };
	if (!value.match(reg)) {
		return {
			valid: false,
			msg: 'El correo electrónico que proporcionaste no tiene un formato válido, por favor verifícalo',
		};
	}
	return { valid: true, msg: 'Correo electrónico correcto' };
};

export const isNumber = (value: string): boolean => {
	const reg = /^[0-9]+$/;
	if (value === '') return true;
	if (!value.match(reg)) {
		return false;
	}
	return true;
};
