import { Grid, TextField, styled } from '@mui/material';
import styles from './styles/create-base-styles.module.css';
import { IAdaptedValues } from '../interfaces/edit-adapted-values';
import { Cancel } from '@mui/icons-material';
import { useCreateBaseRow } from '../hooks/useCreateBaseRow';

interface IItem {
	item: IAdaptedValues;
	onSave: (value: string) => void;
	attributes: string[];
	setAttributes: React.Dispatch<React.SetStateAction<string[]>>;
	mainAttribute: string;
	setMainAttribute: React.Dispatch<React.SetStateAction<string>>;
	setName: React.Dispatch<React.SetStateAction<string>>;
}

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

export const CreateBaseRow = ({
	item,
	attributes,
	mainAttribute,
	onSave,
	setAttributes,
	setMainAttribute,
	setName,
}: IItem) => {
	const {
		matches,
		value,
		handleAttributes,
		handleDeleteAttributes,
		setValue,
		handleKeyPress,
	} = useCreateBaseRow({
		attributes,
		mainAttribute,
		setAttributes,
		item,
	});

	return (
		<>
			<Grid item xs>
				<p>{item.mainAttribute}</p>
			</Grid>
			<Grid
				item
				xs={matches ? 8 : 9}
				className={styles.renderItem}
				style={{ display: 'flex', flexDirection: 'column' }}
			>
				<Input
					variant="outlined"
					size="small"
					fullWidth
					placeholder="Escribe aquí"
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
						if (item.id === '0') setName(e.target.value);
						if (item.id === '1') setMainAttribute(e.target.value);
					}}
					onKeyDown={handleKeyPress}
					disabled={item.disabled}
					id={`input-${item.id}`}
				/>
				{item.id === '2' ? (
					<div className={styles.actionsContainer}>
						<span
							onClick={() => handleAttributes()}
							className={styles.labelAttributes}
						>
							+ Agregar otro atributo
						</span>
						{attributes.map((element, index) => {
							return (
								<div
									className={styles.attributesContainer}
									key={`${element}-${index}`}
									id={`attribute-${index}`}
								>
									<span>{element}</span>
									<span
										onClick={() => handleDeleteAttributes(element)}
										style={{ display: 'flex', alignItems: 'center' }}
									>
										<Cancel className={styles.iconAttribute} />
									</span>
								</div>
							);
						})}
					</div>
				) : null}
			</Grid>
		</>
	);
};
