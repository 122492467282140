/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useContext } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { IInitialEvidences } from '../interfaces/initial-evidences.interface';
import { SupervisionProjectContext } from '../../../contexts/useSupervisionProjectContext';
import { setAlert } from '../../../../../store/modules/snackbar';
import { initialEvidencesAdapter } from '../adapters/initial-evidences-adapter';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../../store';

export function useInitialEvidences() {
	const { data, setData, pagination, rowsSelect, rowsPerPage, filters } =
		useTableCustom<Object>();
	const dispatch = useDispatch();

	const location = useLocation();
	const { idRoutine, idProject } = useContext(SupervisionProjectContext);
	const idRoutineParams = location?.state?.routine as string;
	const idProjectParams = location?.state?.idProject as string;
	const idRoutineHistory = location?.state?.params?.routine as string;
	const idProyectHistory = location?.state?.params?.project?._id as string;
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const {
		execute: executeGetInitialEvidences,
		status: statusGetInitialEvidences,
		value: valueGetInitialEvidences,
	} = useApiRequest<unknown, IResponsePayload<IInitialEvidences>>({
		path: URLS.FILES.ROUTINE,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		if (idRoutineHistory !== undefined)
			executeGetInitialEvidences(
				null,
				`?routine=${idRoutineHistory}&page=${pagination}&recordsByPage=${rowsPerPage}&project=${
					idProyectHistory || _id
				}`
			);
		else
			executeGetInitialEvidences(
				null,
				`?routine=${
					idRoutineParams ? idRoutineParams : idRoutine
				}&page=${pagination}&recordsByPage=${rowsPerPage}&project=${
					idProjectParams ? idProjectParams : idProject || _id
				}&advancedSearch=${filters?.advancedSearch || ''}`
			);
	}, [pagination, rowsSelect, rowsPerPage, filters]);

	useEffect(() => {
		if (statusGetInitialEvidences === 'success') {
			dispatch(stopLoading());
			setData({
				...data,
				countRows: valueGetInitialEvidences?.totalRecords || 0,
				rows: initialEvidencesAdapter(valueGetInitialEvidences?.data || []),
			});
		} else if (statusGetInitialEvidences === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetInitialEvidences]);

	return { data, idRoutine, _id, idRoutineParams, idProject, idProjectParams, setData };
}
