/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../config/theme';
import { useGetActivities } from '../../hooks/useGetActivities';
import { getNewValueAdapter } from '../../../../utils/get-new-value';
import { useCreateActivity } from './useCreateActivity';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { DefaultModal, IModal } from '../../../../interfaces/modal.interface';
import { isEmpty } from 'lodash';

interface IUseFormActivitites {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentActivity: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
}

export const useFormEditActivities = ({
	setStep,
	setCurrentActivity,
}: IUseFormActivitites) => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { activitiesOptions, valueGetActivities, setActivitiesOptions } =
		useGetActivities();

	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const { handleCreatestatusCreateActivity } = useCreateActivity({
		modal,
		activitiesOptions,
		setActivitiesOptions,
		setModal,
	});

	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	const handleNewActivity = () => {
		const temporalActivities = activitiesOptions;
		temporalActivities.push({
			id: '',
			description: '',
			rows: [],
			isComplete: false,
		} as unknown as IAdaptedValues);
		setActivitiesOptions([...temporalActivities]);
	};

	const onSave = (value: string, data: { [key: string]: string | null }) => {
		if (isEmpty(data)) return;
		const newActivity = getNewValueAdapter(
			value,
			idProject,
			valueGetActivities?.data
		);
		newActivity.frequency = data.frequency;
		newActivity.executor = data.executor;
		newActivity.executorTeam = data.executorTeam;
		newActivity.supervisor = data.supervisor;
		newActivity.supervisorTeam = data.supervisorTeam;
		newActivity.substitute1 = data.substitute1;
		newActivity.substitute1Team = data.substitute1Team;
		newActivity.substitute2 = data.substitute2;
		newActivity.substitute2Team = data.substitute2Team;
		handleCreatestatusCreateActivity(newActivity);
	};

	const onEdit = (item: IAdaptedValues) => {
		setStep(1);
		setCurrentActivity(item);
	};

	const onDelete = (rowIndex: number) => {
		const newActivities = activitiesOptions;
		newActivities.splice(rowIndex, 1);
		setActivitiesOptions([...newActivities]);
	};

	return {
		activitiesOptions,
		matches,
		modal,
		handleNewActivity,
		navigate,
		onSave,
		onDelete,
		onEdit,
		setModal,
	};
};
