import { useEffect, useState } from 'react';

export const useGetLocation = () => {
	const [position, setPosition] = useState({ latitude: 0, longitude: 0 });

	useEffect(() => {
		getPosition();
	}, []);

	async function getPosition() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(async function (position) {
				const lat = position.coords.latitude;
				const lon = position.coords.longitude;
				setPosition({ latitude: lat, longitude: lon });
			});
		}
	}

	const getFormattedPosition = (
		coord: number,
		type: string,
		originalDegree: number
	) => {
		const degrees = Math.floor(coord);
		const decimalMinutes = (coord - degrees) * 60;
		const minutes = Math.floor(decimalMinutes);
		const seconds = (decimalMinutes - minutes) * 60;

		const direccion =
			type === 'latitude'
				? originalDegree >= 0
					? 'N'
					: 'S'
				: originalDegree >= 0
				? 'E'
				: 'W';

		return `${Math.abs(degrees)}°${minutes}'${seconds.toFixed(0)}"${direccion}`;
	};

	const getLocationDMS = (): string => {
		return `${getFormattedPosition(
			position.latitude >= 0 ? position.latitude : -position.latitude,
			'latitude',
			Math.floor(position.latitude)
		)} ${getFormattedPosition(
			position.longitude >= 0 ? position.longitude : -position.longitude,
			'longitude',
			Math.floor(position.longitude)
		)}`;
	};

	return { getLocationDMS };
};
