/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../constants/urls.constants';
import { areasAdapter } from '../adapters/area-info.adapter';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { useTableCustom } from '../../../../hooks/useTableCustom';
import { IResponsePayload, useApiRequest } from '../../../../hooks/useApiRequest';
import { ISupervisionRoutineData } from '../interfaces/get-supervision-routines.interface';
import { IColumns } from '../../../../interfaces/table.insterface';
import { getTableColumns } from '../../../../utils/tableInfo';
import { Button } from '../../../../components/main-button.component';
import { FactCheckOutlined } from '@mui/icons-material';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import { STATUS_SUPERVISION_TYPES } from '../../../../constants/status-supervision';

interface IUseSupervisionRoutines {
	handleSetAction: (status: string, element: ISupervisionRoutineData) => void;
	getFunctionalId: () => string;
	getFunctionals: () => void;
}

export function useGetSupervisionRoutines({
	handleSetAction,
	getFunctionalId,
	getFunctionals,
}: IUseSupervisionRoutines) {
	const dispatch = useDispatch();
	const {
		data,
		setData,
		pagination,
		rowsSelect,
		rowsPerPage,
		filters,
		handleFilter,
		handleGetFiltersParams,
	} = useTableCustom<Object>();
	const [dinamicColumns, setDinamicColumns] = useState<IColumns[]>([]);
	const { mainSelect, idRoutine, subSelect, executeChangeStatus, idProject } =
		useContext(SupervisionProjectContext);

	const {
		execute: getSupervisionRoutines,
		status: statusSupervisionRoutines,
		value: valueSupervisionRoutines,
	} = useApiRequest<unknown, IResponsePayload<ISupervisionRoutineData>>({
		path: URLS.SUPERVISION_EXECUTION.GET_SUPERVISION_ROUTINES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusSupervisionRoutines === 'success') {
			dispatch(stopLoading());
			getCustomColumns();
			setData({
				...data,
				countRows: valueSupervisionRoutines?.totalRecords || 0,
				rows: areasAdapter(
					valueSupervisionRoutines?.data || [],
					mainSelect.optionLabel,
					handleSetAction
				),
				rowsPerPage,
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusSupervisionRoutines === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusSupervisionRoutines]);

	const getCustomColumns = () => {
		mainSelect.optionLabel === 'servicio'
			? setDinamicColumns(
					getTableColumns(
						[
							'Espacio nivel 1',
							...(valueSupervisionRoutines?.data[0]?.functionalParentAttributes?.slice(
								1
							) || []),
							'Espacio nivel 2',
							...(valueSupervisionRoutines?.data[0]?.functionalAttributes?.slice(
								1
							) || []),
							'Indicador',
							...(valueSupervisionRoutines?.data[0]?.indicatorAttributes?.slice(
								1
							) || []),
							'Acciones',
						],
						valueSupervisionRoutines?.data &&
							valueSupervisionRoutines.data.length > 0 ? (
							<Button
								label="Todos cumplen"
								icon={FactCheckOutlined}
								variant="outlined"
								onClick={() => {
									changeAllStatus();
								}}
								style={{ color: 'var(--color-green-main)' }}
							/>
						) : (
							<></>
						),
						[
							...(valueSupervisionRoutines?.data[0]
								?.functionalParentColumns || []),
							...(valueSupervisionRoutines?.data[0]?.functionalColumns ||
								[]),
							...(valueSupervisionRoutines?.data[0]?.indicatorColumns ||
								[]),
						]
					)
			  )
			: setDinamicColumns(
					getTableColumns(
						[
							'Espacio nivel 2',
							...(valueSupervisionRoutines?.data[0]?.functionalAttributes?.slice(
								1
							) || []),
							'Servicio',
							...(valueSupervisionRoutines?.data[0]?.activityAttributes?.slice(
								1
							) || []),
							'Indicador',
							...(valueSupervisionRoutines?.data[0]?.indicatorAttributes?.slice(
								1
							) || []),
							'Acciones',
						],
						valueSupervisionRoutines?.data &&
							valueSupervisionRoutines.data.length > 0 ? (
							<Button
								label="Todos cumplen"
								icon={FactCheckOutlined}
								variant="outlined"
								onClick={() => {
									changeAllStatus();
								}}
								style={{ color: 'var(--color-green-main)' }}
							/>
						) : (
							<></>
						),
						[
							...(valueSupervisionRoutines?.data[0]?.functionalColumns ||
								[]),
							...(valueSupervisionRoutines?.data[0]?.activityColumns || []),
							...(valueSupervisionRoutines?.data[0]?.indicatorColumns ||
								[]),
						]
					)
			  );
	};

	const changeAllStatus = async () => {
		const functional = getFunctionalId();
		dispatch(setLoading({ loading: true }));
		await executeChangeStatus({
			newStatus: STATUS_SUPERVISION_TYPES.ACCOMPLISHED,
			project: idProject,
			routine: idRoutine,
			functional: functional,
			activity:
				mainSelect.optionLabel === 'espacio'
					? subSelect.service
					: mainSelect.valueLabel,
			//supervisor: supervisor,
		});
		getFunctionals();
	};

	return {
		data,
		pagination,
		rowsSelect,
		rowsPerPage,
		dinamicColumns,
		filters,
		setDinamicColumns,
		getSupervisionRoutines,
		setData,
		handleFilter,
		handleGetFiltersParams,
	};
}
