/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../store';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { RESPONSES_GET_STATUS } from '../utils/response-get-status';
import { INTERNAL_MESSAGES } from '../../../constants/internal-messages';
import { STEPS_AUTOMATIC_CHARGE } from '../utils/steps-automatic-charge';
import { AutomaticChargeContext } from '../contexts/useAutomaticChargeContext';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { IResponseGetStatusAutomatic } from '../interfaces/get-status.interface';

interface IUseGetStatus {
	changeStep?: boolean;
}

export const useGetStatus = ({ changeStep = true }: IUseGetStatus) => {
	const dispatch = useDispatch();
	const project = useSelector((state: RootState) => state.projectBase);
	const { setStep } = useContext(AutomaticChargeContext);

	const {
		execute: executeStatus,
		status: statusStatus,
		value: valueStatus,
	} = useApiRequest<unknown, IResponsePayload<IResponseGetStatusAutomatic>>({
		path: URLS.AUTOMATIC_CHARGE.STATUS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusStatus === 'success') {
			dispatch(stopLoading());
			if (
				valueStatus?.detail?.step === STEPS_AUTOMATIC_CHARGE.PROJECT_GENERATION ||
				valueStatus?.detail?.step ===
					STEPS_AUTOMATIC_CHARGE.SUPERVISION_EXECUTION ||
				valueStatus?.detail?.step === STEPS_AUTOMATIC_CHARGE.FINISHED
			)
				return setStep(valueStatus?.detail?.step);
			if (changeStep) handleStatus();
		} else if (statusStatus === 'error') {
			dispatch(stopLoading());
			if (changeStep)
				dispatch(
					setAlert({
						show: true,
						message: valueStatus?.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					})
				);
		}
	}, [statusStatus]);

	const handleStatus = () => {
		switch (valueStatus?.detail?.lastUploadedCode) {
			case RESPONSES_GET_STATUS.SUCCESS:
			case RESPONSES_GET_STATUS.ERROR:
				setStep(STEPS_AUTOMATIC_CHARGE.DATA_CONFIRMATION);
				break;
			case RESPONSES_GET_STATUS.IN_PROGRESS:
				setStep(STEPS_AUTOMATIC_CHARGE.IN_PROGRESS_DATA);
				break;
			case RESPONSES_GET_STATUS.NOT_STARTED:
				break;
		}
	};

	const getStatus = () => {
		dispatch(setLoading({ loading: true }));
		executeStatus(null, `/${project._id}`);
	};

	return { getStatus, executeStatus, valueStatus };
};
