import { Box } from '@mui/system';
import React, { useContext } from 'react';
import theme from '../../../config/theme';
import { getDateLocalMX } from '../../../utils/dates';
import { Grid, Paper, useMediaQuery } from '@mui/material';
import styles from './styles/header-project-styles.module.css';
import backgroundCard from '../../../assets/imgs/no-image-found.png';
import { isEmpty } from '../../../utils/validators';
import { ProfileProjectContext } from '../context/useProfileProjectContext';

interface IHeader {
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const HeaderProject = ({ setStep }: IHeader) => {
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { data } = useContext(ProfileProjectContext);

	return (
		<Paper elevation={1} style={{ marginBottom: '20px' }}>
			<Box padding={matches ? 3 : 6}>
				<Grid container spacing={3} justifyContent="center" rowSpacing={3}>
					<Grid
						item
						sm={12}
						md={12}
						lg={4}
						xl={2}
						className={styles.imgContainer}
					>
						<img
							src={
								!isEmpty(data.coverImage)
									? data.coverImage
									: backgroundCard
							}
							alt={'imgProject'}
							className={styles.img}
						/>
					</Grid>
					<Grid item sm={12} md className={styles.imgContainer}>
						<Grid container alignItems="center" rowSpacing={2}>
							<Grid item>
								<h3 style={{ margin: 0 }}>{data.shortName}</h3>
								<span style={{ margin: 0 }} className={styles.textLabel}>
									{data.description}
								</span>
							</Grid>
							<Grid item md={12}>
								<Grid
									container
									direction="row"
									columnSpacing={8}
									rowSpacing={2}
								>
									<Grid item>
										<span className={styles.textLabel}>
											No. de contrato
										</span>
										<br />
										<span className={styles.textValue}>
											{data.contractNumber}
										</span>
									</Grid>
									<Grid item>
										<span className={styles.textLabel}>
											Fecha de inicio de contrato
										</span>
										<br />
										<span className={styles.textValue}>
											{getDateLocalMX(data.contractStartDate)}
										</span>
									</Grid>
									<Grid item>
										<span className={styles.textLabel}>
											Fecha de término de contrato
										</span>
										<br />
										<span className={styles.textValue}>
											{getDateLocalMX(data.contractEndDate)}
										</span>
									</Grid>
									<Grid item>
										<span className={styles.textLabel}>Nombre</span>
										<br />
										<span className={styles.textValue}>
											{data.name}
										</span>
									</Grid>
									<Grid item>
										<span className={styles.textLabel}>
											Fecha de aplicación de cambios
										</span>
										<br />
										<span className={styles.textValue}>
											{getDateLocalMX(
												data.nextChangesApplicationDate || ''
											)}
										</span>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
