import {
	Box,
	CircularProgress,
	LinearProgress,
	linearProgressClasses,
	styled,
	Typography,
} from '@mui/material';
import styles from './styles/progress-bar-styles.component.module.css';

type ProgressType = 'circular' | 'linear';

interface IProgress {
	type: ProgressType;
	value: number;
}

export const ProgressBar = ({ type, value }: IProgress) => {
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 20,
		borderRadius: 10,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor:
				theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 10,
			backgroundColor: ' var(--color-green-main)',
		},
	}));

	switch (type) {
		case 'circular':
			return (
				<Box padding={2} className={styles.boxContainer}>
					<CircularProgress
						variant="determinate"
						value={value}
						className={styles.circularProgress}
						thickness={4}
					/>
					<Box className={styles.boxLabel}>
						<Typography
							variant="caption"
							component="div"
							className={styles.valueLabel}
						>
							<span className={styles.labelValue}>{`${Math.round(
								value
							)}%`}</span>
							<span>
								Tareas <br />
								completadas
							</span>
						</Typography>
					</Box>
				</Box>
			);
		case 'linear':
			return <BorderLinearProgress variant="determinate" value={value} />;
	}
};
