import * as React from 'react';
import { useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import CSVIcon from '../assets/icons/CSV.icon';
import Papa from 'papaparse';
import { bytesToMB } from '../utils/converts';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/modules/snackbar';
import { setIsCsvLoaded } from '../store/modules/utils';
import styles from './styles/drag-drop.module.css';
import Modal from './modal.component';
import { DefaultModal, IModal } from '../interfaces/modal.interface';

const style: SxProps<Theme> = {
	p: 4,
	display: 'flex',
	flexDirection: 'column',
};

const FilledButton = styled(Button)<{ customcolor?: string }>(
	({ theme, customcolor = '#0080F9' }) => ({
		textTransform: 'none',
		background: customcolor,
		color: '#FFF',
		marginLeft: 'auto',
		'&:hover': {
			background: customcolor,
		},
	})
);

const Container = styled(Grid)<{ width: number | string }>(({ theme, width }) => ({
	textTransform: 'none',
	border: '1px dashed #9C9FB5',
	color: '#7F8297',
	width: `${width}%`,
	display: 'flex',
	justifyContent: 'center',
	textAlign: 'center',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '2rem',
	margin: '1rem 0',
}));

interface IProps {
	onCancel: () => void;
	onContinue: (data: IResponseData) => void;
	maxColumns?: number;
	customStyles?: React.CSSProperties;
}

export interface IColumns {
	text: string;
	nameID: string;
	width?: number | string;
	sort?: boolean;
	align?: 'left' | 'center' | 'right';
}

export interface IResponseData {
	columns: string[];
	file: any;
	parsed: Array<{ [key: string]: string }>;
}

const AltaCSV = (props: IProps) => {
	const { onCancel, onContinue, maxColumns = 0, customStyles } = props;
	const dispatch = useDispatch();
	const [fileSelected, setFileSelected] = useState<IResponseData>({
		columns: [],
		file: null,
		parsed: [],
	});
	const inputRef = useRef<any>(null);
	const [isDragging, setIsDragging] = useState(false);
	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const handleOpenModal = (file: File) => {
		setModal({
			...modal,
			open: true,
			callback: () => {
				handleSetFile(file, false);
				setModal({ ...modal, open: false });
			},
			onClose: () => {
				handleSetFile(file, true);
				setModal({ ...modal, open: false });
			},
		});
	};

	const handleSetFile = (file: File, withHeader: boolean) => {
		const reader = new FileReader();
		let newFile: any;
		reader.onload = async ({ target }) => {
			try {
				if (target && target.result) {
					const csv = Papa.parse(target.result as string, {
						header: withHeader,
						skipEmptyLines: true,
					});
					const filteredData = csv?.data || [];
					const parsedData: Array<{ [key: string]: string }> =
						filteredData as Array<{ [key: string]: string }>;
					const csvString = Papa.unparse(filteredData);
					const csvData = new Blob([csvString], { type: 'text/csv' });
					newFile = new File([csvData], file.name, { type: file.type });
					const columns = Object.keys(parsedData[0]);
					if (columns.length !== maxColumns) {
						dispatch(
							setAlert({
								show: true,
								message: `El CSV no tiene la misma cantidad de columnas que la estructura CSV:${columns.length}. App:${maxColumns}`,
								severity: 'error',
							})
						);
						return;
					}
					setFileSelected((prev) => ({
						...prev,
						columns,
						file: newFile,
						parsed: parsedData,
					}));
				}
			} catch (error: any) {
				dispatch(
					setAlert({
						show: true,
						message:
							'No se ha podido cargar el archivo, por favor revise que su contenido este con el formato correcto.',
						severity: 'error',
					})
				);
			}
		};
		reader.readAsText(newFile ? newFile : file);
	};

	const { getInputProps } = useDropzone({
		accept: {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.csv'],
		},
		onDropAccepted: (files) => {
			const size = bytesToMB(files[0].size);
			if (parseFloat(size) > 5) {
				dispatch(
					setAlert({
						show: true,
						message: 'No se puede cargar un CSV mayor a 5MB',
						severity: 'error',
					})
				);
			} else {
				handleOpenModal(files[0]);
			}
		},
		onFileDialogOpen: () => {
			setFileSelected({
				columns: [],
				file: null,
				parsed: [],
			});
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error',
					severity: 'error',
				})
			);
		},
	});

	const handleOnChange = (event: any, isDrag: boolean): void => {
		if (!isDrag) event.preventDefault();
		const fileList = event.target.files;
		if (!fileList) {
			dispatch(
				setAlert({
					show: true,
					message: 'Selecciona un archivo.',
					severity: 'error',
				})
			);
			return;
		}
		const extension = fileList[0].type;
		if (extension !== 'text/csv') {
			dispatch(
				setAlert({
					show: true,
					message: 'El archivo debe tener extension CSV',
					severity: 'error',
				})
			);
		} else {
			const newFile: File = fileList[0];
			handleOpenModal(newFile);
		}
	};

	const handleDrop = (event: any) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0] as React.ChangeEvent<HTMLInputElement>;
		handleOnChange({ target: { files: [file] } }, true);
	};

	const handleDragOver = (event: any) => {
		event.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	const handleContinue = () => {
		onContinue(fileSelected);
		dispatch(setIsCsvLoaded(true));
	};

	return (
		<Paper sx={{ ...style, ...customStyles }}>
			<Grid container display="flex" justifyContent="center">
				<Grid item>
					<h2>Alta de CSV</h2>
				</Grid>
			</Grid>
			<span style={{ textAlign: 'center' }}>
				Recuerda que el archivo CSV no debe llevar títulos.
			</span>
			<Container
				width={'100%'}
				id="upload-container"
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				className={`${styles.dragFile} ${isDragging ? styles.dragOver : ''}`}
			>
				<input id="fileInput" type="file" ref={inputRef} {...getInputProps()} />
				{fileSelected.file ? (
					<li key={fileSelected?.file?.name || ''}>
						{fileSelected?.file?.name || ''}
					</li>
				) : (
					<label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
						<div
							style={{
								background: '#E3F4E9',
								borderRadius: '7px',
								padding: '7px',
								cursor: 'pointer',
							}}
							onClick={() => {
								if (inputRef.current) inputRef.current.click();
							}}
						>
							<CSVIcon />
						</div>
						<h3>
							Arrastra y suelta
							<br />
							Tu archivo aquí
						</h3>
						<span style={{ fontSize: '9px' }}>
							O selecciónalo desde el explorador de archivos
						</span>
					</label>
				)}
			</Container>
			<Grid container justifyContent="center">
				<Grid item>
					<FilledButton
						sx={{ margin: '0 1rem' }}
						onClick={onCancel}
						customcolor="#FA4040"
					>
						Cancelar
					</FilledButton>
					<FilledButton
						id="upload-continue"
						onClick={handleContinue}
						customcolor="#0080F9"
					>
						Continuar
					</FilledButton>
				</Grid>
			</Grid>
			<Modal
				open={modal.open}
				title={'Cargar archivo'}
				cancelLabel="Con encabezados"
				confirmLabel="Sin encabezados"
				styleButtonConfirm={{ color: '#0080F9' }}
				width="sm"
				onClose={modal.onClose}
				onConfirm={modal.callback}
				modal={modal}
				setModal={setModal}
			>
				Haz clic en el botón que corresponda
			</Modal>
		</Paper>
	);
};

export default AltaCSV;
