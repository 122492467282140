import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';

export const getProjectsAdapter = (
	data: { _id: string; name: string; shortName: string; description: string }[]
) => {
	const projectsData = (data || []).map((project) => {
		return {
			id: project._id,
			mainAttribute: 'Proyectos',
			description: project.shortName,
			rows: [
				{ key: 'name', value: project.name },
				{ key: 'shortName', value: project.shortName },
				{ key: 'description', value: project.description },
			],
			frequency: '',
			isComplete: true,
		};
	});

	return projectsData as unknown as IAdaptedValues[];
};
