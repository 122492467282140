import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { SxProps, Theme } from '@mui/material';

interface IDeleteProps {
	onClick: () => void;
	disabled: boolean;
	sx?: SxProps<Theme>;
	ref?: React.RefObject<any>;
}

const deleteButtonStyles = {
	color: 'gray',
	'&:hover': {
		backgroundColor: 'transparent', // Change the background color on hover
		color: 'red', // Change the text color on hover
	},
};

export const DeleteBtn = (props: IDeleteProps) => {
	const { onClick, disabled, sx, ref } = props;
	return (
		<IconButton
			aria-label=""
			sx={{ ...deleteButtonStyles, ...sx }}
			component="label"
			onClick={onClick}
			disabled={disabled}
			ref={ref}
		>
			<DeleteIcon />
		</IconButton>
	);
};
