import { Grid } from '@mui/material';
import SelectComponent from '../../../../../components/select.component';
import { DeleteBtn } from '../../../../../components/button-delete.component';
import { IItem } from '../../../../cronograma/interfaces/logic.interface';
import { monthOptions } from '../../../../../constants/frecuencia';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';

const dayListOptions: IOptionsMenu[] = Array.from(Array(31), (_, i) => ({
	id: i + 1,
	label: i + 1,
	value: i + 1,
}));

export const RowItem = (props: IItem) => {
	const { item, column, handleTable, disabled, handleDelete } = props;

	return (
		<>
			<Grid
				item
				style={{
					alignContent: 'center',
					display: 'flex',
					alignItems: 'center',
					fontWeight: 'bold',
				}}
			>
				<span>Día no laborable {column + 1}</span>
			</Grid>
			{item.rows.map((el, row) => (
				<Grid item sx={{ flex: 1 }} key={`grid-${el.key}-${column}`}>
					<SelectComponent
						key={`select-${el.key}-${column}`}
						id={`NWD-${el.key}-${column}`}
						formSx={{ width: '100%' }}
						disabled={disabled}
						RenderTitle={() => {
							return null;
						}}
						placeholder={el.key === 'day' ? 'Día' : 'Mes'}
						value={el.value}
						optionsMenu={el.key === 'day' ? dayListOptions : monthOptions}
						onChange={(e) => {
							handleTable(column, row, e.target.value);
						}}
					/>
				</Grid>
			))}
			<Grid item xs={1}>
				<DeleteBtn
					onClick={() => {
						if (handleDelete) handleDelete(column);
					}}
					disabled={column === 0 ? true : false}
				/>
			</Grid>
		</>
	);
};
