/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
	Box,
	Paper,
	Grid,
	Typography,
	TextField,
	ClickAwayListener,
} from '@mui/material';
import React, {
	useState,
	useEffect,
	useMemo,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { formatDate } from '../utils/dates';
import { isEmpty } from '../utils/validators';
import { Button } from './main-button.component';
import styles from './styles/filter-custom.component.module.css';
import { IFilter, IPropsInput } from '../interfaces/filter.interface';
import SelectAsyncSearch from './select-search.component';

interface IFilterCustom {
	open: boolean;
	data: IFilter[];
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	//onChange?: (data: IFilter) => void;
	handleFilter: (arg: any) => void;
}

type TForwardProps = {
	getFilters: () => string[];
};

const FilterCustom: React.ForwardRefRenderFunction<TForwardProps, IFilterCustom> = (
	props,
	forwardedRef
) => {
	const { open, data, setOpen, handleFilter } = props;
	const [filter, setFilter] = useState<IFilter[]>([]);
	const [active, setActive] = useState(false);
	useEffect(() => {
		setFilter(
			data.map((input) => {
				input.value = '';
				if (input.rageInputs) {
					input.rageInputs.map((rage) => {
						rage.value = '';
						return rage;
					});
				}
				return input;
			})
		);
	}, [data]);

	const getFilters = useMemo(
		() => filter.filter((i) => !isEmpty(i.value)).map((i) => i.label),
		[filter]
	);

	useImperativeHandle(forwardedRef, () => ({
		getFilters() {
			return getFilters;
		},
	}));

	const handleChange = (
		index: number,
		name: string,
		value: any,
		type?: string,
		propsInput?: IPropsInput
	) => {
		//console.log('value: ', value);
		if (type === 'number') {
			const oldNumber = Number(value);
			if (oldNumber < 0) return;
			const decimales = propsInput?.decimals || 0;
			if (isNaN(oldNumber)) return;
			const newValue = Number(oldNumber.toFixed(decimales));
			if (oldNumber !== newValue) return;
		}
		const newInputs = filter.map((f, i) => {
			if (index === i) {
				if (f.name === name) f.value = value;
				if (f.rageInputs) {
					f.rageInputs.forEach((e) => {
						if (e.name === name) e.value = value;
					});
				}
			}
			return f;
		});

		setFilter(newInputs);
	};
	const getFilterData = (cleanForm?: boolean) => {
		if (cleanForm) {
			setFilter(
				data.map((input) => {
					input.value = '';
					if (input.rageInputs) {
						input.rageInputs.map((rage) => {
							rage.value = '';
							return rage;
						});
					}
					return input;
				})
			);
			setActive(false);
			if (handleFilter) handleFilter({});
			return;
		}
		const returnFilter: { [key: string]: any } = {};
		filter.forEach((f) => {
			if (f.value) {
				let newValue: string | number = f.value.toString();

				//En caso de que quieran que el SELECT u otro campo haga procesos (transforme el value) antes de mandar el resultado a backend
				//agregar otro caso en 'returnMode' y agregar otro 'if'

				if (f.returnMode === 'Number') newValue = Number(f.value);

				returnFilter[f.name] = newValue;
			}
			if (f.rageInputs) {
				const rageValues = f.rageInputs
					.filter((d) => d.value !== '')
					.map((r) => r);
				if (rageValues.length > 0) {
					let firstValue = rageValues[0]?.value.toString();
					let secondValue = rageValues[1]?.value.toString();
					//??
					if (rageValues[0].type === 'date' && rageValues[1].type === 'date') {
						firstValue = formatDate(firstValue);
						secondValue = formatDate(secondValue);
					}
					returnFilter[f.name] = `${firstValue || secondValue}:${
						secondValue || firstValue
					}`;
				}
			}
		});
		setActive(Object.keys(returnFilter).length > 0 ? true : false);
		if (handleFilter) handleFilter(returnFilter);
	};

	function pickInput(c: IFilter, index: number) {
		switch (c.type) {
			case 'text':
				return (
					<React.Fragment key={`${c.label}_${index}`}>
						<Grid item xs={12} md={c.md} className={styles.gridContainer}>
							<p className={styles.labelInput}>{c.label}</p>
							<div>
								<TextField
									fullWidth
									name={c.name}
									id={c.id}
									type={c.type}
									value={c.value}
									disabled={c.disabled ? c.disabled : false}
									onChange={(e) =>
										handleChange(
											index,
											c.name,
											e.target.value,
											c.type,
											c.propsInput
										)
									}
									inputProps={c.propsInput}
									placeholder={c.placeholder}
									size="small"
								/>
							</div>
						</Grid>
					</React.Fragment>
				);
			case 'select':
				return (
					<React.Fragment key={`${c.label}_${index}`}>
						<Grid item xs={12} md={c.md} className={styles.gridContainer}>
							<p className={styles.labelInput}>{c.label}</p>
							<select
								id={c.id}
								className={styles.muiSelect}
								value={c.value}
								onChange={(e) => {
									handleChange(
										index,
										c.name,
										e.target.value,
										c.type,
										c.propsInput
									);
								}}
								placeholder={c.placeholder}
							>
								{c.options &&
									c.options.map((x: any, idx: number) => (
										<option
											key={`item_${c.name}_${idx}`}
											value={x.value}
										>
											{x.label}
										</option>
									))}
							</select>
						</Grid>
					</React.Fragment>
				);
			case 'select-search':
				return (
					<React.Fragment key={`${c.label}_${index}`}>
						<Grid item xs={12} md={c.md} className={styles.gridContainer}>
							<SelectAsyncSearch
								id={`select-search-${c.name}`}
								value={c.value}
								onChange={(e) => {
									handleChange(
										index,
										c.name,
										e.target.value,
										c.type,
										c.propsInput
									);
								}}
								options={c.options}
								label={c.label}
								placeholder={c.placeholder}
							/>
						</Grid>
					</React.Fragment>
				);
			default:
				break;
		}
	}

	return (
		<>
			{open && (
				<ClickAwayListener onClickAway={() => setOpen(false)}>
					<Paper>
						<div className={styles.paperRelative}>
							<Box alignItems={'center'} gap={1} className={styles.mainBox}>
								<Grid container className={styles.dividerBox}>
									<Grid item xs={9} display="flex" alignItems="center">
										<Typography
											variant="body1"
											fontWeight={'bold'}
											fontSize={18}
										>
											Filtrar búsqueda
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Button
											id="clean"
											label="Limpiar"
											onClick={() => getFilterData(true)}
											variant="text"
										/>
									</Grid>
								</Grid>
								<Box>
									<Grid
										container
										display="flex"
										flexDirection="row"
										spacing={2}
										className={styles.paddingInputs}
									>
										{data.map((c: any, index: any) => (
											<React.Fragment key={`${c.label}_${index}`}>
												{pickInput(c, index)}
											</React.Fragment>
										))}
									</Grid>
								</Box>
								<Grid
									container
									display="flex"
									justifyContent="space-evenly"
									fontSize="40px"
									marginTop={'20px'}
								>
									<Grid
										item
										xs={6}
										flexDirection="column"
										justifyContent={'flex-start'}
									>
										<Button
											id="button-filter"
											style={{ color: '#0080F9' }}
											label="Filtrar"
											onClick={() => getFilterData()}
											variant="outlined"
										/>
									</Grid>
								</Grid>
							</Box>
						</div>
					</Paper>
				</ClickAwayListener>
			)}
		</>
	);
};

export default forwardRef(FilterCustom);
