/* eslint-disable react-hooks/exhaustive-deps */

import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../../store/modules/loading';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { useChangeStep } from '../../../hooks/useChangeStep';
import { ICreationPage } from '../../../../../interfaces/creation-page-interfaces';
import { statusCreation } from '../../../constants/creation-supervision.component';
import { StepOneSchedule } from '../components/step-one.component';

export function useSchedule() {
	const { modal, idProject, supervisionExtraordinaryData, setModal } = useContext(
		CreateSupervisionContext
	);
	const dispatch = useDispatch();
	const { changeStepSE } = useChangeStep();

	const ScheduleSteps: ICreationPage = {
		header: {
			label: 'Estado de creación',
			stepper: {
				activeStep: 4,
				items: statusCreation,
				completed: {},
			},
		},
		stepOne: {
			title: '1. Selecciona una fecha de supervisión',
			show: true,
			Children: <StepOneSchedule />,
		},
	};

	const undoStep = () => {
		setModal({
			...modal,
			open: true,
			callback: () => {
				dispatch(setLoading({ loading: true }));
				changeStepSE(
					{
						newStep: STEPS_SUPERVISION_EXTRAORDINARY.PER_1,
						extraordinarySupervision:
							supervisionExtraordinaryData.idSupervisionExtraordinary,
					},
					`/${idProject}`
				);
			},
		});
	};

	return { ScheduleSteps, undoStep };
}
