/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { uniqueId } from '../../../utils/uniqueId';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import { RootState } from '../../../store';
import { isEmpty } from '../../../utils/validators';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import useGetUF_UE from './useGetUF_EF';
import { IFunctionalResponse } from '../../../interfaces/project.interface';
import { attachSublevels } from '../../../utils/attachSublevels';
import { ICollapsable } from '../../../interfaces/collapsable.interface';

interface IUseSelector {
	//UF
	setUFIOptions: TReactDispatch<IOptionsMenu[]>;
	setCurrentUFI: TReactDispatch<string>;
	currentUFI: string;
	//EF
	setEFIOptions: TReactDispatch<IOptionsMenu[]>;
	setCurrentEFI: TReactDispatch<string>;
	currentEFI: string;
	//ACT
	setACTIOptions: TReactDispatch<IOptionsMenu[]>;
	setCurrentACTI: TReactDispatch<string>;
	currentACTI: string;
	//Indicator
	setIndicatorList: TReactDispatch<IStepTwoModel[]>;
	setCurrentIndicatorID: TReactDispatch<string[]>;
}
const useOptions = (props: IUseSelector) => {
	const {
		currentACTI,
		currentEFI,
		currentUFI,
		setCurrentUFI,
		setACTIOptions,
		setCurrentACTI,
		setCurrentEFI,
		setEFIOptions,
		setUFIOptions,
		setIndicatorList,
		setCurrentIndicatorID,
	} = props;
	const dispatch = useDispatch<any>();
	const _id = useSelector((root: RootState) => root.projectBase._id);
	const { value: UFvalue, execute: getUF_UE } = useGetUF_UE();
	const [rawSelectData, setRawSelectData] = useState<ICollapsable[]>([]);
	const [isLevelTwo, setIsLevelTwo] = useState<boolean>(true);

	const {
		execute: updateIndicators,
		status: updatedStatusIndicator,
		value: updatedValueIndicator,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_SUPERVISION_UNIT,
		method: 'POST',
	});

	const {
		execute: getIndicators,
		//status: statusIndicators,
		value: valueIndicators,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.DEFAULT_FREQ,
		method: 'GET',
	});

	const {
		execute: getActivity,
		status: getStatusActivity,
		value: getValueActivity,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	function showError() {
		dispatch(
			setAlert({
				show: true,
				message: 'Ocurrio un error al actualizar el proyecto',
				severity: 'error',
			})
		);
	}

	const handleGetUF_UE = () => {
		getUF_UE(null, `?project=${_id}`);
	};

	//SEARCHING UF
	useEffect(() => {
		if (!UFvalue || UFvalue.data.length < 1) return;
		const _adapted: IFunctionalResponse[] = UFvalue.data;
		const resultArray = attachSublevels(_adapted);
		setRawSelectData(resultArray);
		setUFIOptions(
			resultArray.map((i) => ({
				id: i?.id || '',
				label: i?.value || '',
				value: i?.id || '',
				status: false,
			}))
		);
		setCurrentUFI(resultArray[0]?.id || '');
		if (isEmpty(resultArray[0]?.items)) {
			setIsLevelTwo(false);
			setCurrentEFI(resultArray[0]?.id || '');
		} else {
			setCurrentEFI(resultArray[0]?.items[0]?.id || '');
		}
	}, [UFvalue]);

	//SET EF LIST
	useEffect(() => {
		if (isLevelTwo) {
			const findElement = rawSelectData.find((i) => i.id === currentUFI) || null;
			if (!findElement) return;
			setEFIOptions(
				findElement.items.map((i) => ({
					id: i.id,
					label: i.value,
					value: i.id,
					status: false,
				}))
			);
			if (findElement.items.length > 0) setCurrentEFI(findElement.items[0].id);
		} else {
			setCurrentEFI(currentUFI);
		}
	}, [currentUFI]);

	//SEARCH ACTIVITIES
	useEffect(() => {
		if (isEmpty(currentEFI)) return;
		dispatch(setLoading({ loading: true }));
		getActivity(
			null,
			`?project=${_id}&type=U-A&functional=${currentEFI}&populate=1&applies=true`
		);
	}, [currentEFI]);

	//GET ACTIVITIES VALUES
	useEffect(() => {
		if (getStatusActivity === 'success') {
			dispatch(stopLoading());
			if (!getValueActivity) return;
			const _adapted = getValueActivity.data.map((element) => {
				const { activity: i } = element;
				return {
					id: i._id,
					label: i.columns[0],
					value: i._id,
					status: false,
				};
			});
			setACTIOptions(_adapted);
			if (_adapted.length > 0) {
				setCurrentACTI(_adapted[0].value);
				getCurrentIndicators(_adapted[0].value);
			}
		} else if (getStatusActivity === 'error') {
			dispatch(stopLoading());
			showError();
		}
	}, [getStatusActivity]);

	//SELECT INDICATORS
	useEffect(() => {
		if (isEmpty(currentACTI)) return;
		dispatch(setLoading({ loading: true }));
		getCurrentIndicators();
	}, [currentACTI]);

	useEffect(() => {
		dispatch(stopLoading());
		const indicatorList: any = [];
		const indicatorID: string[] = [];

		if (valueIndicators && valueIndicators?.data?.length > 0) {
			valueIndicators.data.forEach((element) => {
				const { indicator: i } = element;
				indicatorID.push(i._id);
				indicatorList.push({
					id: uniqueId(),
					_id: element._id,
					name: i.columns[0],
					attributes: [...i.attributes, 'freq'],
					columns: [...i.columns, element.frequency],
					isComplete: true,
					mainAttribute: i.mainAttribute,
					project: i.project,
				});
			});
		}
		setIndicatorList(indicatorList);
		setCurrentIndicatorID(indicatorID);
	}, [valueIndicators, getValueActivity, updatedValueIndicator]);

	const getCurrentIndicators = (acti?: string) => {
		dispatch(setLoading({ loading: true }));
		if (isLevelTwo) {
			getIndicators(
				null,
				`?project=${_id}&functionalParent=${currentUFI}&functional=${currentEFI}&activity=${
					acti || currentACTI
				}&applies=true&type=U-I`
			);
		} else {
			getIndicators(
				null,
				`?project=${_id}&functional=${currentUFI}&activity=${
					acti || currentACTI
				}&applies=true&type=U-I`
			);
		}
	};
	return {
		//handle
		handleGetUF_UE,
		updateIndicators,
		updatedStatusIndicator,
		updatedValueIndicator,
		getCurrentIndicators,
		isLevelTwo,
	};
};

export default useOptions;
