/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import theme from './../../../config/theme';
import { useDispatch } from 'react-redux';
import { InitialEvidences } from '../../../pages/supervision-project/components/summary-routine/components/initial-evidences.component';
import { Evidences } from '../../../pages/supervision-project/components/evidences.component';
import { Findings } from '../../../pages/supervision-project/components/findings.component';
import { Suspensions } from '../../../pages/supervision-project/components/suspensions.component.';

export function useDetailEvidences() {
	const [currentTab, setCurrentTab] = useState<number>(0);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();

	const tabItems = [
		{ label: 'Testigos de inicio', value: 0 },
		{ label: 'Evidencias', value: 1 },
		{ label: 'Hallazgos', value: 2 },
		{ label: 'Suspensiones', value: 3 },
	];

	const getComponent = () => {
		switch (currentTab) {
			case 0:
				return <InitialEvidences />;
			case 1:
				return <Evidences isSupervisionRoutine={false} />;
			case 2:
				return <Findings isSupervisionRoutine={false} isFindings={true} />;
			case 3:
				return <Suspensions isSupervisionRoutine={false} isFindings={false} />;
			default:
				return <InitialEvidences />;
		}
	};

	return {
		currentTab,
		matches,
		tabItems,
		setCurrentTab,
		dispatch,
		getComponent,
	};
}
