import { IVerticalStepperAttributes } from '../../../interfaces/vertical.steper.attributes.interface';
import { TCriteriosTable } from '../../../interfaces/criterios.interface';

export const DefaultTabItems = [
	{
		label: 'Criterios Generales',
		value: 0,
		isDisabled: false,
	},
	{
		label: 'Criterios Específicos',
		value: 1,
		isDisabled: false,
	},
	{
		label: 'Geográficos y servicios',
		value: 2,
		isDisabled: false,
	},
	{
		label: 'Tipos de hallazgos',
		value: 3,
		isDisabled: false,
	},
];

export const generalCriterio: IVerticalStepperAttributes[] = [
	{
		index: 0,
		label: 'Criterio',
		placeholder: 'Nombre del criterio',
		value: '',
		isComplete: false,
	},
	{
		index: 1,
		label: 'Atributo Principal',
		placeholder: 'Nombre del atributo',
		value: '',
		isComplete: false,
	},
];

export const defaultHallazgo: TCriteriosTable = [
	{
		id: 0,
		isComplete: false,
		label: 'Tipo de hallazgo',
		rows: [
			{
				key: 'Nombre',
				value: '',
			},
		],
	},
];

interface IService {
	id: number | string;
	name: string;
	value: boolean;
}
export type TServices = IService[];

export const DefaultAsignacionCriterios = (): TServices => {
	const data: any = [];

	for (let i = 0; i < 60; i++) {
		data.push({
			id: i,
			name: `IG-${i}`,
			value: false,
		});
	}

	data.push({
		id: 61,
		name: `IG-${61}`,
		value: true,
	});
	data.push({
		id: 62,
		name: `IG-${62}`,
		value: false,
	});
	data.push({
		id: 63,
		name: `IG-${63}`,
		value: false,
	});
	data.push({
		id: 64,
		name: `IG-${64}`,
		value: true,
	});

	return data;
};
