import { ICollapsable } from '../../../interfaces/collapsable.interface';
import { IFunctionalResponse } from '../../../interfaces/project.interface';

export function attachSublevels(array: IFunctionalResponse[]): ICollapsable[] {
	const parent: IFunctionalResponse[] = [];
	const children: IFunctionalResponse[] = [];

	array.forEach((i) => {
		if (!i.functional) parent.push(i);
		else children.push(i);
	});
	const copy: ICollapsable[] = parent.map((i) => ({
		id: i._id,
		isComplete: true,
		items: [],
		name: i.name,
		value: i.columns[0],
	}));
	parent.forEach((i, idx) => {
		children.forEach((j) => {
			if (i._id === j.functional) {
				copy[idx].items.push({
					id: j._id,
					name: j.name,
					isComplete: true,
					items: [],
					value: j.columns[0],
				});
			}
		});
	});
	return copy;
}
