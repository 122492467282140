import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useGetLocation } from './useGetLocation';
import { getFormattedDate, getHour } from '../utils/dates';
import imageLoge from '../assets/imgs/ic_currie_letter.png';

interface IAddInfoImage {
	setImg: (
		imageSrc: string | null | undefined,
		name?: string,
		size?: string,
		extension?: string
	) => void;
	user?: string;
	unitInfo?: string;
}

export const useAddInfoImage = ({ setImg, user, unitInfo }: IAddInfoImage) => {
	const { getLocationDMS } = useGetLocation();
	const { shortName } = useSelector((state: RootState) => state.projectBase);

	const addInfoImage = (
		imageSrc: string | null,
		isImage?: boolean,
		name?: string,
		size?: string,
		extension?: string
	) => {
		const canvas = document.createElement('canvas');
		canvas.width = 1920;
		canvas.height = 1080;
		const context = canvas.getContext('2d');

		const image = new Image();
		image.onload = () => {
			context?.drawImage(image, 0, 0, canvas.width, canvas.height);

			if (context) {
				context.font = '25px Arial';
				context.fillStyle = 'white';
			}
			context?.fillText(
				getHour() +
					' ' +
					getFormattedDate() +
					' - ' +
					Intl.DateTimeFormat().resolvedOptions().timeZone,
				20,
				930
			);
			context?.fillText(getLocationDMS(), 20, 960);
			context?.fillText(user || '-', 20, 990);
			context?.fillText(shortName, 20, 1020);
			context?.fillText(unitInfo || '-', 20, 1050);

			const icon = new Image();
			icon.src = imageLoge;
			icon.onload = () => {
				context?.drawImage(icon, 20, 20);

				const base64Image = canvas.toDataURL('image/jpeg');
				setImg(
					isImage ? base64Image.split(',')[1] : base64Image,
					name,
					size,
					extension
				);
			};
		};

		image.src = isImage ? `data:image/jpeg;base64,${imageSrc}` : imageSrc || '';
	};

	return { addInfoImage };
};
