/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import { useLocation } from 'react-router-dom';
import { IFrequencies } from '../interfaces/get-frequencies.interface';
import { getFrequenciesAdapter } from '../adapters/get-frequencies.adapter';

export const useGetFunctionals = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [functionalsOptions, setFunctionalsOptions] = useState<IOptionsMenu[]>([]);

	const {
		execute: getFunctionals,
		status: statusGetFunctionals,
		value: valueGetFunctionals,
	} = useApiRequest<unknown, IResponsePayload<IFrequencies>>({
		path: URLS.EDIT_PROJECT.CHRONOGRAM.GET_FUNCTIONALS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetFunctionals === 'success') {
			dispatch(stopLoading());
			setFunctionalsOptions(getFrequenciesAdapter(valueGetFunctionals?.data || []));
		} else if (statusGetFunctionals === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetFunctionals]);

	const handleGetFunctionals = (functionalParent?: string) => {
		dispatch(setLoading({ loading: true }));
		getFunctionals(
			null,
			`/${idProject}?${
				functionalParent
					? `functionalParent=${functionalParent}`
					: 'isFunctionalParent=true'
			}`
		);
	};

	return { functionalsOptions, handleGetFunctionals };
};
