/* eslint-disable react-hooks/exhaustive-deps*/
import { URLS } from '../../../constants/urls.constants';
import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../store/modules/snackbar';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { SupervisionProjectContext } from '../contexts/useSupervisionProjectContext';
import { useNavigate } from 'react-router-dom';

export function useStopTiming() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { execute: stopSupervision, status: statusStopSupervision } = useApiRequest<
		any,
		IResponsePayload<any>
	>({
		path: URLS.SUPERVISION_EXECUTION.STOP_TIMING,
		method: 'POST',
	});

	const { idRoutine } = useContext(SupervisionProjectContext);

	useEffect(() => {
		if (statusStopSupervision === 'success') {
			navigate(-1);
		} else if (statusStopSupervision === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error al detener la supervisión.',
					severity: 'error',
				})
			);
		}
	}, [statusStopSupervision]);

	const executeStopTiming = (id?: string) => {
		stopSupervision(null, `/${id ?? idRoutine}`);
	};

	return { executeStopTiming };
}
