/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { RootState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useGetStatus } from '../../../hooks/useGetStatus';
import { setLoading } from '../../../../../store/modules/loading';
import { STEPS_AUTOMATIC_CHARGE } from '../../../utils/steps-automatic-charge';
import { ALERTS_STARTED_LOAD } from '../constants/messages';

interface IUseStartedData {
	consultStatus?: boolean;
}

export const useStartedData = ({ consultStatus = true }: IUseStartedData) => {
	const dispatch = useDispatch();
	const { executeStatus } = useGetStatus({});
	const project = useSelector((state: RootState) => state.projectBase);

	useEffect(() => {
		if (consultStatus) {
			dispatch(setLoading({ loading: true }));
			executeStatus(null, `/${project._id}`);
		}
	}, []);

	const getLabel = () => {
		switch (project.automaticStep) {
			case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_DATA:
			default:
				return ALERTS_STARTED_LOAD.BASE;
			case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_PERSONAL_DATA:
				return ALERTS_STARTED_LOAD.PERSONAL;
			case STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_RELATIONS:
				return ALERTS_STARTED_LOAD.RELATIONS;
		}
	};

	return { getLabel };
};
