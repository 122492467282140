import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Button } from './main-button.component';
import { IModal } from '../interfaces/modal.interface';
import styles from './styles/modal-styles.component.module.css';

interface IModalProps {
	open: boolean;
	title?: React.ReactChild;
	width?: 'md' | 'lg' | 'xl' | 'sm' | 'xs';
	onClose?: () => void;
	hideCloseButton?: boolean;
	disabledButton?: boolean;
	hideDialogTitle?: boolean;
	contentDividers?: boolean;
	children?: React.ReactChild;
	confirmLabel?: string;
	onConfirm?: () => void;
	hideConfirm?: boolean;
	cancelLabel?: string;
	hideCancel?: boolean;
	styleButton?: React.CSSProperties;
	styleButtonConfirm?: React.CSSProperties;
	modal: IModal;
	setModal: React.Dispatch<React.SetStateAction<IModal>>;
}

const Modal = ({
	open,
	title,
	width = 'md',
	onClose = () => {},
	hideCloseButton = false,
	hideDialogTitle = false,
	contentDividers = false,
	children,
	confirmLabel = 'Continuar',
	onConfirm = () => {},
	hideConfirm = false,
	cancelLabel = 'Cancelar',
	hideCancel = false,
	styleButton,
	styleButtonConfirm,
	modal,
	setModal,
}: IModalProps) => {
	return (
		<Dialog
			fullWidth
			open={open}
			onClose={() => setModal({ ...modal, open: false })}
			maxWidth={width}
		>
			{!hideDialogTitle && (
				<DialogTitle className={styles.headerModal}>
					<div
						className={styles.titleContainerModal}
						style={{
							justifyContent: !hideCloseButton ? 'space-between' : 'center',
						}}
					>
						<h3 className={styles.h3Modal}>{title}</h3>
						{!hideCloseButton && (
							<IconButton
								onClick={onClose}
								className={styles.closeIconModal}
							>
								<Close />
							</IconButton>
						)}
					</div>
					<hr className={styles.decorationLineModal} />
				</DialogTitle>
			)}
			{children && (
				<DialogContent dividers={contentDividers}>
					<div className={styles.childrenContainerModal}>{children}</div>
				</DialogContent>
			)}
			{(!hideCancel || !hideConfirm) && (
				<DialogActions className={styles.footerModal}>
					{!hideCancel && (
						<Button
							id="cancel-btn-modal"
							style={styleButton}
							variant="outlined"
							label={cancelLabel}
							onClick={onClose}
						/>
					)}
					{!hideConfirm && (
						<Button
							id="confirm-btn-modal"
							style={{ ...styleButtonConfirm, margin: '0' }}
							variant="outlined"
							label={confirmLabel}
							onClick={onConfirm}
						/>
					)}
				</DialogActions>
			)}
		</Dialog>
	);
};

export default Modal;
