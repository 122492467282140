import { useNavigate } from 'react-router-dom';
import Inicio from '../../components/Inicio-page.component';
import { IInicio } from '../../interfaces/creation-page-interfaces';

const ProyectSuccesful = () => {
	const navigate = useNavigate();
	const HayCriteriosComunes: IInicio = {
		title: <>Proyecto creado con éxito</>,
		rightButtonLabel: 'Continuar',
		isLeftBtn: false,
		onRightButtonClick: () => {
			navigate('/inicio');
		},
	};

	return <Inicio {...HayCriteriosComunes} />;
};

export default ProyectSuccesful;
