/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { URLS } from '../../../../../constants/urls.constants';
import { stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { serviceSelectAdapter } from '../adapters/service-select-adapter';

export function useSuspensionsService(handleView: any) {
	const { data: dataSuspensionsService, setData: setDataSuspensionsService } =
		useTableCustom<Object>();
	const dispatch = useDispatch();

	const {
		execute: executeSuspensionsService,
		status: statusSuspensionsService,
		value: valueSuspensionsService,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.SUPERVISION_HISTORY.HISTORY_BY_ACTIVITIES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusSuspensionsService === 'success') {
			setDataSuspensionsService({
				...dataSuspensionsService,
				countRows: valueSuspensionsService?.totalRecords || 0,
				rows: serviceSelectAdapter(
					valueSuspensionsService?.data || [],
					handleView
				),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusSuspensionsService === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
		dispatch(stopLoading());
	}, [statusSuspensionsService]);

	return {
		dataSuspensionsService,
		setDataSuspensionsService,
		executeSuspensionsService,
	};
}
