/* eslint-disable react-hooks/exhaustive-deps */
import { URLS } from '../../../constants/urls.constants';
import {
	IChangeStatusParams,
	IChangeStatusResp,
} from '../components/interfaces/change-status.interface';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { useEffect } from 'react';
import { setAlert } from './../../../store/modules/snackbar';
import { stopLoading } from './../../../store/modules/loading';
import { useDispatch } from 'react-redux';

export function useChangeStatus() {
	const dispatch = useDispatch();
	const {
		execute: executeChangeStatus,
		status: statusChangeStatus,
		setStatus,
	} = useApiRequest<IChangeStatusParams, IResponsePayload<IChangeStatusResp>>({
		path: URLS.SUPERVISION_EXECUTION.CHANGE_STATUS,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusChangeStatus === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Actualización realizada con éxito.',
					severity: 'success',
				})
			);
		} else if (statusChangeStatus === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, intente de nuevo.',
					severity: 'error',
				})
			);
		}
	}, [statusChangeStatus]);

	return {
		executeChangeStatus,
		statusChangeStatus,
		setStatus,
	};
}
