import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { DayComponent } from './day.component';
import styles from './styles/daily-styles.module.css';
import { ISupervisionDay } from '../interfaces/supervisionRoutines.interface';
import { getRoutineDescriptionByType } from '../../../../../utils/get-title-routines';
import { ITypeRoutine } from '../../../../../constants/routines.constants';
import { IActivitiesByRoutine } from '../interfaces/activitiesLabels.interface';

interface ITRoutinesComponent {
	supervisions: ISupervisionDay[];
	activitiesLabels?: IActivitiesByRoutine[];
}

export const DailyComponent = ({
	supervisions,
	activitiesLabels,
}: ITRoutinesComponent) => {
	const days = [
		'Domingo',
		'Lunes',
		'Martes',
		'Miércoles',
		'Jueves',
		'Viernes',
		'Sábado',
	];

	return (
		<Paper elevation={0} style={{ backgroundColor: '#f8f8f8', height: '100%' }}>
			<Box padding={2}>
				<div className={styles.containerDay}>
					<div className={styles.numberCalendar}>
						<span>{new Date().getDate()}</span>
					</div>
					<span>{days[new Date().getDay()]}</span>
				</div>
				<h4 style={{ margin: 0 }}>
					{supervisions.length > 0
						? 'Lo que está sucediendo hoy'
						: 'Hoy no hay supervisiones'}
				</h4>
				{supervisions?.map((e: any) => (
					<DayComponent
						key={`daily-component-${e.routine}`}
						supervisionTitle={getRoutineDescriptionByType(
							e.routineType as keyof ITypeRoutine,
							e.routineExecutionDate as unknown as Date
						)}
						supervision={
							activitiesLabels
								?.find((routine) => routine.routine === e.routine)
								?.activitiesDescription.join() || ''
						}
						commissioned={e.supervisorDescription}
						stylesDivider={
							e.routineType === 'RECURRENT'
								? { backgroundColor: '#fa3761' }
								: {}
						}
					/>
				))}
			</Box>
		</Paper>
	);
};
