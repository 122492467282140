import { IOptionsMenu } from '../interfaces/select.interface';
import { IGetPersonalOptions } from '../interfaces/user.interface';

export const getUsersByProject = (data: IGetPersonalOptions[]): IOptionsMenu[] => {
	const userData = data.map((user) => {
		return {
			id: user?._id || '',
			value: user?._id || '',
			label: user?.fullName || '',
		};
	});

	return userData;
};
