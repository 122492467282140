import { Box, Divider, Grid, Paper } from '@mui/material';
import Alert from '../../../components/info.alert.component';
import styles from './styles/upload-files-styles.module.css';
import { useUploadFile } from './hooks/useUploadFile.component';
import { DragNDrop } from '../../../components/drag-drop.component';
import { UploadSuccessful } from './upload-successful';

interface IUploadFile {
	alertDescription: string;
}

export const UploadFile = ({ alertDescription }: IUploadFile) => {
	const { matches, nameFile, handleOnSubmit, handleOnChange, setNameFile } =
		useUploadFile();

	return (
		<Paper>
			<Box padding={3}>
				<form onSubmit={handleOnSubmit}>
					<Grid container className={styles.mainContainerUploadFile}>
						<Grid item xs={12}>
							<span className={styles.titleUploadFile}>
								Subir documento
							</span>
						</Grid>
						<Grid item xs={12}>
							<Divider className={styles.dividerUploadFile} />
						</Grid>
						<Grid item>
							<Alert
								severity="info"
								sx={{ margin: '0px', width: 'min-content' }}
							>
								{alertDescription}
							</Alert>
						</Grid>
						{nameFile ? (
							<UploadSuccessful
								nameFile={nameFile}
								setNameFile={setNameFile}
							/>
						) : (
							<Grid item xs={12} className={styles.dragNDropContainer}>
								<DragNDrop
									element={{
										accept: '.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
									}}
									onChange={handleOnChange}
								/>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							display="flex"
							justifyContent={matches ? 'center' : 'flex-end'}
						>
							<Grid item>
								<button type="submit" className={styles.buttonUploadFile}>
									Continuar
								</button>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Paper>
	);
};
