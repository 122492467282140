import { IStats } from '../interfaces/stats.interface';

export const resumeStatsAdapter = (data?: IStats) => {
	if (!data) return { totalTime: 0, totalTasks: 0, projects: [] };
	let acum = 0;
	data?.performancePerProject.forEach((value) => (acum += value.totalTimeSupervised));
	const totalTime = acum;
	acum = 0;
	Object.values(data?.performancePerProject).forEach(
		(value) => (acum += value.totalTasksCompleted)
	);
	const totalTasks = acum;
	const newData = {
		totalTime: totalTime,
		totalTasks: totalTasks,
		projects: data?.performancePerProject.map((value) => {
			return value.projectShortName;
		}),
	};
	return newData;
};
