import { Paper, Box } from '@mui/material';
import { useCreatePersonal } from './hooks/useCreatePersonal';
import { Stepper } from '../../../../components/stepper.component';
import { estadoCreacion } from '../../../../constants/estado-creacion';
import { Tabs } from '../../../../components/tabs.component';
import { DefaultTabItems } from '../../constants/data.constants';

export const CreatePersonal = () => {
	const { isEdit, isAutomaticCharge, getComponent } = useCreatePersonal();

	return (
		<>
			{!isEdit && !isAutomaticCharge && (
				<>
					<Paper>
						<Box padding={3}>
							<Stepper
								steps={estadoCreacion}
								activeStep={3}
								completed={{ 0: true, 1: true }}
							/>
						</Box>
					</Paper>
					<Tabs
						items={DefaultTabItems}
						value={0}
						onChange={() => null}
						sx={{ position: 'static !important', marginTop: '30px' }}
					/>
				</>
			)}
			{getComponent()}
		</>
	);
};
