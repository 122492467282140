/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { ITask } from '../../../interfaces/project.interface';
import { stopLoading } from '../../../store/modules/loading';
import { resetState } from '../../../store/modules/steps';
import { setAlert } from '../../../store/modules/snackbar';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { tasksAdapter } from '../adapters/tasks.adapter';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
import { setPage } from '../../../store/modules/page-table';

export function useNextTasks() {
	const [currentTab, setCurrentTab] = useState<number>(0);
	const { page, rowsPerPage } = useSelector((state: RootState) => state.pageTable);
	const {
		data: dataTable,
		setData: setDataTable,
		filters,
		handleGetFiltersParams,
	} = useTableCustom<Object>({
		countRows: 0,
		rows: [],
		sort: { col: '', order: '' },
		pagination: page,
		rowsSelect: [],
		rowsPerPage: rowsPerPage,
		filters: {},
		advancedFilter: '',
		activeAdvanceFilter: false,
		filterCustom: {},
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tabItems = [
		{ label: 'Próximas tareas', value: 0 },
		{ label: 'Tareas no recurrentes', value: 1 },
		{ label: 'Tareas no recurrentes base', value: 2 },
	];

	const {
		execute: executeGetTask,
		status: statusGetTask,
		value: valueTask,
	} = useApiRequest<unknown, IResponsePayload<ITask>>({
		path: URLS.SUPERVISION_EXECUTION.INCOMING_ROUTINES,
		method: 'GET',
	});

	const viewDetails = (task: ITask) => {
		dispatch(
			setPage({ page: dataTable.pagination, rowsPerPage: dataTable.rowsPerPage })
		);
		navigate('ver-supervision', {
			state: {
				params: {
					idRoutine: task?.routine,
					isTask: true,
					isRecurring: false,
					isTaskBase: false,
				},
			},
		});
	};

	useEffect(() => {
		if (statusGetTask === 'success') {
			dispatch(stopLoading());
			setDataTable({
				...dataTable,
				countRows: valueTask?.totalRecords || 0,
				rows: tasksAdapter(valueTask?.data || [], viewDetails),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
			dispatch(resetState());
		} else if (statusGetTask === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueTask?.message || 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetTask]);

	return {
		dataTable,
		setDataTable,
		currentTab,
		tabItems,
		filters,
		setCurrentTab,
		executeGetTask,
		handleGetFiltersParams,
		dispatch,
	};
}
