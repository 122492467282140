import { IActionsList } from '../../planning/interfaces/actions.interface';

export const generalData: IActionsList = {
	elements: [
		{
			id: 1,
			label: 'Editar datos generales',
			action: 'navigate',
			value: 'editar-datos-generales',
		},
		{
			id: 2,
			label: 'Resumen de tareas',
			action: 'navigate',
			value: 'resumen-tareas',
		},
	],
};

export const activitiesList: IActionsList = {
	elements: [
		{
			id: 1,
			label: 'Modificar actividades',
			action: 'navigate',
			value: 'edicion-actividades',
		},
	],
};

export const standarsList: IActionsList = {
	elements: [
		{
			id: 1,
			label: 'Editar criterios generales',
			action: 'navigate',
			value: 'edicion-criterios-generales',
			structureData: 1,
		},
		{
			id: 2,
			label: 'Editar criterios específicos',
			action: 'navigate',
			value: 'edicion-criterios-especificos',
			structureData: 2,
		},
		{
			id: 3,
			label: 'Editar tipos de hallazgos',
			action: 'navigate',
			value: 'edicion-hallazgos',
		},
	],
};

export const dimensionsListLvl2: IActionsList = {
	elements: [
		{
			id: 1,
			label: 'Nivel 1',
			action: 'navigate',
			value: 'dimensiones-nivel-uno',
			structureData: 3,
		},
		{
			id: 2,
			label: 'Nivel 2',
			action: 'navigate',
			value: 'dimensiones-nivel-dos',
			structureData: 4,
		},
		{
			id: 3,
			label: 'Relaciones espacios-actividades',
			action: 'navigate',
			value: 'relaciones-espacios-actividades',
		},
		{
			id: 4,
			label: 'Relaciones espacios-indicadores',
			action: 'navigate',
			value: 'relaciones-espacios-indicadores',
		},
	],
};

export const dimensionsListLvl1: IActionsList = {
	elements: [
		{
			id: 1,
			label: 'Nivel 1',
			action: 'navigate',
			value: 'dimensiones-nivel-uno',
			structureData: 3,
		},
		{
			id: 3,
			label: 'Relaciones espacios-actividades',
			action: 'navigate',
			value: 'relaciones-espacios-actividades',
		},
		{
			id: 4,
			label: 'Relaciones espacios-indicadores',
			action: 'navigate',
			value: 'relaciones-espacios-indicadores',
		},
	],
};
