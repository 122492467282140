/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import usePatchActivities from './usePatchActivities';

interface IGet {
	setActividadList: TReactDispatch<IOptionsMenu[]>;
	currentService: string;
}

const useUpdateUnits_Acts = (props: IGet) => {
	const { setActividadList, currentService } = props;
	const dispatch = useDispatch<any>();
	const { execute, status, value } = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_SUPERVISION_UNIT,
		method: 'POST',
	});
	const { execute: patchActivities } = usePatchActivities();

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			setActividadList((prev) =>
				prev.map((i) => {
					if (i.id === currentService)
						return {
							...i,
							status: true,
						};
					return i;
				})
			);
			if ((value?.data || []).length > 0 && value?.data[0]?.activity) {
				patchActivities(
					{
						isFunctionalAssociated: true,
					},
					`/${currentService}`
				);
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, status, value };
};

export default useUpdateUnits_Acts;
