import axios from 'axios';
import { useState } from 'react';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useGetStatus } from '../../hooks/useGetStatus';
import { useMediaQuery, useTheme } from '@mui/material';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading } from '../../../../store/modules/loading';
import { INTERNAL_MESSAGES } from '../../../../constants/internal-messages';
import { URLS } from '../../../../constants/urls.constants';

export const useUploadFile = () => {
	const dispatch = useDispatch();
	const [nameFile, setNameFile] = useState('');
	const [loadFileInput, setFile] = useState<File | null>(null);
	const matches = useMediaQuery(useTheme().breakpoints.down('sm'));
	const project = useSelector((state: RootState) => state.projectBase);
	const user = useSelector((state: RootState) => state.users);

	const { executeStatus } = useGetStatus({});

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.files) {
			const file = e?.target?.files[0];
			setFile(file);
			setNameFile(e?.target?.files[0].name);
		}
	};

	const handleOnSubmit = async (event: any) => {
		event.preventDefault();

		if (loadFileInput && project._id) {
			dispatch(setLoading({ loading: true }));
			const formData = new FormData();
			formData.append('file', loadFileInput);
			formData.append('project', project._id);

			try {
				await axios.post(
					`${process.env.REACT_APP_API_URL}/${URLS.AUTOMATIC_CHARGE.LOAD_DATA}`,
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				executeStatus(null, `/${project._id}`);
			} catch (error: any) {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message:
							error?.response?.data?.message ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					})
				);
			}
		}
	};

	return { matches, nameFile, handleOnSubmit, handleOnChange, setNameFile };
};
