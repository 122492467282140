/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { ICreationPage, IInicio } from '../../../../interfaces/creation-page-interfaces';
import { estadoCreacion } from '../../../../constants/estado-creacion';
import { TReactDispatch } from '../../../../interfaces/utils';
import { IItem } from '../../../../components/tabs.component';
import Nivel1StepOne from './step-one.component';
import Nivel1StepTwo from './step-two.component';
import { StepOneHandler } from '../../../../components/step-one-fixed';
import { IVerticalStepperAttributes } from '../../../../interfaces/vertical.steper.attributes-fixed.interface';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';
import { URLS } from '../../../../constants/urls.constants';
import useUpdateProject from '../../../../hooks/useUpdateProject';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { sendDataAdapterFunctional } from '../../../../adapters/step-two.adapter';
import { RootState } from '../../../../store';
import useUpdateUF from '../../hooks/useUpdateUF';
import CreationPage from '../../../../components/creation-page.component';
import Inicio from '../../../../components/Inicio-page.component';
import useGetUF from '../../hooks/useGetUF';

interface IProps {
	currentTab: number;
	setCurrentTab: TReactDispatch<number>;
	tabItems: IItem[];
}
const Level1 = (props: IProps) => {
	const { currentTab, setCurrentTab, tabItems } = props;
	const dispatch = useDispatch<any>();
	const [showCsvUF_2, setShowCsvUF_2] = useState(false);
	const UFRef = useRef<StepOneHandler>(null);
	const [UF_1] = useState<IVerticalStepperAttributes[] | null>(null);
	const [isUFDone, setIsNivelOneDone] = useState(false);
	const [isNext1Disabled, setIsNext1Disabled] = useState<boolean | null>(null);
	const { handleUpdateProject } = useUpdateProject();
	const {
		step: projectStep,
		_id: _projectID,
		functionalParentBase,
	} = useSelector((state: RootState) => state.projectBase);
	const [showAskPage, setIsAskPage] = useState(false);
	const { execute: updateProject, UF_2, setUF_2, setIsSave } = useUpdateUF();
	const { execute: getProject, setIsUFshow, isUFshow } = useGetUF({ setUF_2 });

	useEffect(() => {
		getProject(null, `?project=${_projectID}&isSubFunctional=0`);
	}, []);

	useEffect(() => {
		if (projectStep === '1.1.1') {
			setIsAskPage(true);
			setIsNivelOneDone(true);
			setIsNext1Disabled(true);
		}
		if (projectStep === '1.2.0') {
			setIsNivelOneDone(true);
			setIsNext1Disabled(true);
		}
	}, [projectStep]);

	useEffect(() => {
		if (functionalParentBase) {
			setIsUFshow(true);
			UFRef.current?.disableAll();
		}
	}, [functionalParentBase]);

	const handleUpdateUF = async (
		data: IStepTwoModel[],
		p_isSave: boolean,
		_functionalID?: string
	) => {
		dispatch(setLoading({ loading: true }));
		try {
			await setIsSave(p_isSave);
			const _adapted = sendDataAdapterFunctional(
				data,
				_projectID || '',
				_functionalID || null
			);
			if (!_functionalID) {
				await updateProject(_adapted);
				if (p_isSave)
					await handleUpdateProject(
						{
							functionalParentBase: {
								name: data[0].name,
								mainAttribute: data[0].mainAttribute,
								attributes: data[0].attributes,
							},
						},
						_projectID || ''
					);
			}
			dispatch(stopLoading());
		} catch (e) {
			dispatch(stopLoading());
		}
	};

	const AskLevel: IInicio = {
		title: <>¿Deseas continuar con el número de niveles actual?</>,
		description: (
			<>
				Agregar un nivel adicional significa que existe una subdivisión dentro del
				nivel actual. Si hay espacios más pequeños a considerar, agrega un nivel
			</>
		),
		leftButtonLabel: 'Agregar otro nivel',
		rightButtonLabel: 'Continuar con número de niveles actual',
		onLeftButtonClick: () => {
			dispatch(setLoading({ loading: true }));
			handleUpdateProject({ step: '1.2.0' }, _projectID || '');
		},
		onRightButtonClick: () => {
			handleUpdateProject({ step: '2.0.0' }, _projectID || '');
		},
	};

	const Nivel1: ICreationPage = {
		header: {
			show: !showCsvUF_2,
			label: 'Estado de creación',
			stepper: {
				activeStep: 0,
				items: estadoCreacion,
				completed: {},
			},
			tabs: {
				items: tabItems,
				value: currentTab,
				onTabChange: (_, value) => setCurrentTab(value),
			},
		},
		stepOne: {
			title: '1. Nombra tu nivel 1, así como su atributo principal y los secundarios',
			alertLabel:
				'Designa el nombre genérico de los espacios a supervisar según lo indique el contrato. NO utilices el nombre de un área específica',
			show: !showCsvUF_2,
			Children: (
				<Nivel1StepOne
					refComponent={UFRef}
					disableNext={isNext1Disabled}
					defaultCriterio={UF_1}
					onContinue={(data) => {
						setIsUFshow(true);
						setUF_2(data);
					}}
				/>
			),
		},
		stepTwo: {
			title: !showCsvUF_2 ? (
				'2. Enlista y nombra todos los espacios de tipo Nivel 1'
			) : (
				<></>
			),
			show: isUFshow,
			showExitButon: !isUFDone,
			onExit: () => {
				setIsUFshow(false);
				setIsNext1Disabled(null);
				UFRef.current?.unDone();
			},
			Children: (
				<Nivel1StepTwo
					data={UF_2}
					url={URLS.PROJECT.FUNCTIONAL}
					showAltaCsv={showCsvUF_2}
					setShowAltaCsv={setShowCsvUF_2}
					labelItem={
						'Designa el nombre específico de cada elemento nivel 1. Ejemplo: Urgencias, Cirugía, etc.'
					}
					isDone={isUFDone}
					onContinue={async (data) => {
						handleUpdateUF(data, false);
					}}
					onSave={(data) => {
						handleUpdateUF(data, true);
					}}
					setData={setUF_2}
					onCVSUpload={() => {
						setShowCsvUF_2(true);
					}}
				/>
			),
		},
	};
	return (
		<div>
			{showAskPage && <Inicio {...AskLevel} />}
			{!showAskPage && <CreationPage {...Nivel1} />}
		</div>
	);
};

export default Level1;
