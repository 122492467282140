/* eslint-disable react-hooks/exhaustive-deps */
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../config/theme';
import { useGetFunctional } from '../../hooks/useGetFunctional';
import { useCreateFunctional } from './useCreateFunctional';
import { INewFunctional } from '../interfaces/new-functional';
import { IIndicatorModel } from '../../../../interfaces/project.interface';

interface IUseViewFunctionals {
	isLvlOne: boolean;
	functionalParent: string;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentFunctional: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	structureData: IIndicatorModel;
}

export const useViewFunctionals = ({
	isLvlOne,
	functionalParent,
	structureData,
	setStep,
	setCurrentFunctional,
}: IUseViewFunctionals) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const { functionalsOptions, setFunctionalsOptions } = useGetFunctional({
		functionalParent,
		isLvlOne,
	});

	const { handleCreateCreateFunctional } = useCreateFunctional({
		functionalsOptions,
		setFunctionalsOptions,
	});

	const onEdit = (item: IAdaptedValues) => {
		setStep(2);
		setCurrentFunctional(item);
	};

	const onSave = (value: string) => {
		const newFunctional: INewFunctional = {
			project: idProject,
			name: structureData.name || '',
			mainAttribute: structureData.mainAttribute || '',
			attributes: structureData.attributes || [],
			functional: functionalParent,
			columns:
				structureData.attributes.map((element, index) => {
					return index === 0 ? value : '';
				}) || [],
		};
		if (isLvlOne) delete newFunctional['functional'];
		handleCreateCreateFunctional(newFunctional);
	};

	const onDelete = (rowIndex: number) => {
		const newFunctionals = functionalsOptions;
		newFunctionals.splice(rowIndex, 1);
		setFunctionalsOptions([...newFunctionals]);
	};

	const handleNewFunctional = () => {
		const temporalFunctionals = functionalsOptions;
		temporalFunctionals.push({
			id: '',
			description: '',
			rows: [],
			isComplete: false,
		} as unknown as IAdaptedValues);
		setFunctionalsOptions([...temporalFunctionals]);
	};

	return {
		functionalsOptions,
		matches,
		onEdit,
		onSave,
		onDelete,
		handleNewFunctional,
		navigate,
	};
};
