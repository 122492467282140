/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { SupervisionCardComponent } from '../../../../../components/supervision-card.component';
import styles from './styles/execution-main-styles.module.css';
import HistoryIcon from '@mui/icons-material/History';
import { TasksComponents } from './tasks.component';
import TableCustom from '../../../../../components/table-custom.component';
import { columns, columnsTeams } from '../../../utils/columns.utils';
import { useEffect, useContext } from 'react';
import { DailyComponent } from './daily.component';
import { InfoCards } from '../../info-cards.component';
import { ListAltRounded, PriorityHighOutlined } from '@mui/icons-material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetState } from '../../../../../store/modules/steps';
import { setLoading } from '../../../../../store/modules/loading';
import { useGetSupervisors } from './../hooks/useGetSupervisors';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';
import {
	isAdmin,
	isCoordinador,
	isDirector,
	isExternal,
	isGerente,
} from '../../../../../utils/permissions.utils';
import { RootState } from '../../../../../store';
import { resetPage } from '../../../../../store/modules/page-table';

export const ExecutionMain = () => {
	const matches = useMediaQuery(useTheme().breakpoints.up('lg'));
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { data: dataProject } = useContext(ProfileProjectContext);
	const {
		setDataTable,
		setTeamsTable,
		dataTable,
		summary,
		todaySupervisions,
		chartSupervisions,
		valueActivitiesLabels,
		teamsTable,
		executeGetSupervision,
	} = useGetSupervisors();
	const { role } = useSelector((state: RootState) => state.users);

	useEffect(() => {
		if (dataProject._id && !isCoordinador(role)) {
			dispatch(setLoading({ loading: true }));
			executeGetSupervision(
				null,
				`?page=${dataTable.pagination}&recordsByPage=${dataTable.rowsPerPage}&project=${dataProject._id}`
			);
		}
	}, [dataProject._id, dataTable.pagination, dataTable.rowsPerPage]);

	return (
		<Grid container spacing={2}>
			<Grid
				item
				xs={12}
				lg={isExternal(role) ? 12 : 6}
				xl={isExternal(role) ? 12 : 6}
			>
				<span
					onClick={() => {
						dispatch(resetState());
						navigate('historial-supervision', {
							state: {
								params: {
									project: dataProject,
								},
							},
						});
					}}
					style={{ cursor: 'pointer' }}
				>
					<InfoCards
						icon={HistoryIcon}
						label="Historial de supervisión"
						subtitle="Visualiza las supervisiones pasadas"
						stylesCard={{
							backgroundColor: 'rgba(181, 18, 51, 1)',
							borderRadius: '10px',
							color: 'white',
						}}
						stylesIcon={{
							color: 'rgba(255, 255, 255, 0.6)',
							fontSize: '9em',
							position: 'relative',
							transform: 'rotate(60deg)',
							left: '-25px',
							top: '15px',
						}}
					/>
				</span>
			</Grid>
			{!isExternal(role) && !isDirector(role) && !isCoordinador(role) && (
				<>
					<Grid item xs={12} lg={6} xl={6}>
						<span
							onClick={() => {
								dispatch(resetPage());
								navigate('supervision-extraordinaria', {
									state: dataProject._id,
								});
							}}
							style={{ cursor: 'pointer' }}
						>
							<InfoCards
								icon={PeopleAltOutlinedIcon}
								label="Supervisiones extraordinarias"
								subtitle="Visualiza y crea supervisiones extraordinarias"
								stylesCard={{
									backgroundColor: 'rgba(95, 95, 95, 1)',
									borderRadius: '10px',
									color: 'white',
								}}
								stylesIcon={{
									color: 'rgba(255, 255, 255, 0.6)',
									fontSize: '9em',
									position: 'relative',
									left: '-25px',
									top: '15px',
								}}
							/>
						</span>
					</Grid>

					<>
						<Grid item xs={12} lg={6} xl={6}>
							<span
								onClick={() => {
									dispatch(resetPage());
									navigate('proximas-tareas', {
										state: {
											params: {
												isTask: true,
												project: dataProject,
											},
										},
									});
								}}
								style={{ cursor: 'pointer' }}
							>
								<InfoCards
									icon={ListAltRounded}
									label="Próximas tareas"
									subtitle="Visualiza las tareas por venir"
									stylesCard={{
										backgroundColor: 'rgba(221, 112, 112, 1)',
										borderRadius: '10px',
										color: 'white',
									}}
									stylesIcon={{
										color: 'rgba(255, 255, 255, 0.6)',
										fontSize: '9em',
										position: 'relative',
										transform: 'rotate(20deg)',
										left: '-25px',
										top: '15px',
									}}
								/>
							</span>
						</Grid>

						<Grid item xs={12} lg={6} xl={6}>
							<span
								onClick={() => {
									navigate('incidencia-extraordinaria', {
										state: {
											params: {
												project: dataProject._id,
											},
										},
									});
								}}
								style={{ cursor: 'pointer' }}
							>
								<InfoCards
									icon={PriorityHighOutlined}
									label="Incidencia extraordinaria"
									subtitle="Reporta un hallazgo"
									stylesCard={{
										backgroundColor: 'rgba(176, 175, 177, 1)',
										borderRadius: '10px',
										color: 'white',
									}}
									stylesIcon={{
										color: 'rgba(255, 255, 255, 0.6)',
										fontSize: '9em',
										position: 'relative',
										transform: 'rotate(20deg)',
										left: '-25px',
										top: '-8px',
									}}
								/>
							</span>
						</Grid>
					</>

					{(isAdmin(role) || isGerente(role)) && (
						<Grid item xs={12} lg={6} xl={6}>
							<span
								onClick={() => {
									dispatch(resetPage());
									navigate('suspensiones', {
										state: {
											params: {
												idProject: dataProject._id,
											},
										},
									});
								}}
								style={{ cursor: 'pointer' }}
							>
								<InfoCards
									icon={HistoryIcon}
									label="Gestión de suspensiones"
									subtitle="Gestiona las suspensiones"
									stylesCard={{
										backgroundColor: 'rgba(237, 173, 167, 1)',
										borderRadius: '10px',
										color: 'white',
									}}
									stylesIcon={{
										color: 'rgba(255, 255, 255, 0.6)',
										fontSize: '9em',
										position: 'relative',
										transform: 'rotateZ(308deg) rotateY(190deg)',
										left: '-35px',
										top: '10px',
									}}
								/>
							</span>
						</Grid>
					)}
				</>
			)}
			{matches && <Grid xl={9} />}
			{!isCoordinador(role) && (
				<>
					<Grid item sm={12} />
					<Grid item xs={12} md={4} id="calendar-info">
						<DailyComponent
							supervisions={todaySupervisions}
							activitiesLabels={
								valueActivitiesLabels?.detail?.activitiesByRoutine
							}
						/>
					</Grid>
					<Grid item xs={12} md={8}>
						<Paper elevation={0} className={styles.backgroundGrey}>
							{teamsTable.countRows > 0 && (
								<Box padding={2}>
									<h3 style={{ margin: 0 }}>Equipos en el proyecto</h3>
									<TableCustom
										columns={columnsTeams}
										data={teamsTable}
										setData={setTeamsTable}
										hideHeader
									/>
								</Box>
							)}
							<Box padding={2}>
								<h3 style={{ margin: 0 }}>Supervisores en el proyecto</h3>
								<span className={`${styles.subtitle} ${styles.grey}`}>
									Supervisores a cargo del proyecto
								</span>
								<TableCustom
									columns={columns}
									data={dataTable}
									setData={setDataTable}
									hideHeader
								/>
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={12} md={4}>
						<TasksComponents
							value={summary.value}
							activeTasks={summary.activeTasks}
							pendingTasks={summary.pendingTasks}
						/>
					</Grid>
					{chartSupervisions?.map((e: any) => (
						<Grid
							item
							sm={12}
							md={6}
							lg={4}
							key={`supervision-card-${e.routine}`}
						>
							<SupervisionCardComponent
								supervisionTitle={e.routineDescription}
								supervisor={e.supervisorDescription}
								idSupervisor={e.supervisor}
								pendingSupervision={e.totalPendingSupervision}
								pendingValidation={e.totalPendingValidation}
								suspendedTasks={e.totalSuspended}
								completedTasks={e.totalCompleted}
								value={e.accomplishedPercentage}
								routine={e.routine}
								routineType={e.routineType}
								routineStatus={e.routineStatus}
								idProject={dataProject._id}
								showContinue
							/>
						</Grid>
					))}
				</>
			)}
		</Grid>
	);
};
