/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField, styled } from '@mui/material';
import styles from './styles/item-text-area-styles.module.css';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';
import { Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import InputSelect from '../../../../../components/select-input.component';
import { activeOptions } from './utils/active-options';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { EditButton } from '../../../../../components/buttons.component';
import { useItemCreatePersonal } from './hooks/useItemCreatePersonal';
import { isEmpty } from '../../../../../utils/validators';
import Modal from '../../../../../components/modal.component';

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

interface IItem {
	item: IAdaptedValues;
	rolName: string;
	column: number;
	usersOptions: IOptionsMenu[];
	onEdit: (item: IAdaptedValues) => void;
	onSave: (value: { [key: string]: string }, number: number, _id?: string) => void;
	onDelete: (_id: string) => void;
	setPersonalData: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
	personalData: IAdaptedValues[];
	isEdit: boolean;
}

export const ItemCreatePersonal = ({
	item,
	rolName,
	column,
	usersOptions,
	personalData,
	isEdit,
	onSave,
	onDelete,
	onEdit,
	setPersonalData,
}: IItem) => {
	const { modal, value, matches, disabledButton, handleValue, setModal } =
		useItemCreatePersonal({
			item,
			personalData,
			isEdit,
			setPersonalData,
		});

	return (
		<Grid item xs={matches ? 10 : 11} className={styles.renderItem}>
			<Modal
				open={modal.open}
				title={'Desactivar usuario'}
				cancelLabel="Cancelar"
				confirmLabel="Continuar"
				styleButtonConfirm={{ color: '#0080F9' }}
				width="sm"
				onClose={modal.onClose}
				onConfirm={modal.callback}
				modal={modal}
				setModal={setModal}
			>
				<>
					¿Estás seguro que deseas desactivar a este usuario?{' '}
					<span style={{ fontWeight: 'bold' }}>
						{usersOptions?.find((user) => value['Nombre'] === user.id)
							?.label || ''}
					</span>
				</>
			</Modal>
			<Grid item xs="auto">
				<span style={{ fontWeight: 'bold' }}>{rolName + ' ' + (column + 1)}</span>
			</Grid>
			{item.rows.map((element, index) => {
				return (
					<Grid item key={`personal-${column}-${index}`}>
						{index === 0 || index === 1 ? (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{index === 0 ? (
									<span style={{ fontWeight: 'bold' }}>Nombre</span>
								) : (
									<span style={{ fontWeight: 'bold' }}>Estatus</span>
								)}
								<InputSelect
									id={`select-${column}-${index}`}
									optionsMenu={
										index === 0 ? usersOptions : activeOptions
									}
									onChange={(e) =>
										handleValue(
											e?.target?.value?.toString() || '',
											e.target.name,
											column,
											element,
											item.id
										)
									}
									value={
										index === 0
											? usersOptions.length > 0
												? value[element.key]
												: ''
											: value[element.key]
									}
									name={element.key}
									placeholder="Selecciona"
									width="350px"
								/>
							</div>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span style={{ fontWeight: 'bold' }}>{element.key}</span>
								<Input
									id={`input-${column}`}
									variant="outlined"
									name={element.key}
									fullWidth
									placeholder="Escribe aquí"
									value={value[element.key]}
									onChange={(e) =>
										handleValue(
											e?.target?.value?.toString() || '',
											e.target.name,
											column,
											element,
											item.id
										)
									}
									style={{
										width: '400px',
										height: '50px',
										justifyContent: 'center',
									}}
								/>
							</div>
						)}
					</Grid>
				);
			})}
			<Grid item>
				<div style={{ display: 'flex', gap: '15px' }}>
					<EditButton
						id={`btn-save-${column}`}
						onClick={() => onSave(value, column, item.idPersonal)}
						icon={<SaveIcon sx={{ fontSize: '15px' }} />}
						disabled={disabledButton}
					/>

					<EditButton
						id={`btn-delete-${column}`}
						onClick={() => onDelete(item.idPersonal || '')}
						icon={<Delete sx={{ fontSize: '15px' }} />}
						styles={{
							background: 'var(--color-error-light)',
							color: 'var(--color-error-main)',
						}}
						disabled={!isEmpty(item.id) && isEdit}
					/>
				</div>
			</Grid>
		</Grid>
	);
};
