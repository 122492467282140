export const LABELS_GENERAL_INDICATOR_1 = Object.freeze({
	title: '1. Nombra tus criterios generales',
	alertText:
		'Designe el nombre específico de los criterios de evaluación generales a considerar. Ejemplo: IG - 01',
});

export const LABELS_GENERAL_INDICATOR_2 = Object.freeze({
	title: '2. Asigna o edita tus atributos.',
	alertText: 'Designe el nombre específico de los atributos de tu indicador',
});

export const LABELS_SPECIFIC_INDICATOR_1 = Object.freeze({
	title: '2. Nombra tus criterios específicos',
	alertText:
		'Designe el nombre de los criterios de evaluación específicos a considerar. Ejemplo: IE - 01',
});

export const LABELS_SPECIFIC_INDICATOR_2 = Object.freeze({
	title: '3. Asigna o edita tus atributos.',
	alertText: 'Designe el nombre específico de los atributos de tu indicador',
});
