/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import useUpdateProject from '../../../hooks/useUpdateProject';
import { RootState } from '../../../store';
import useUpdateFunctionalUnit from './useUpdateFunctionalUnit';

interface IGet {
	setEF_2: TReactDispatch<IStepTwoModel[]>;
	EF_2: IStepTwoModel[];
}

const useUpdateEF = (props: IGet) => {
	const { EF_2, setEF_2 } = props;
	const [isSave, setIsSave] = useState<boolean>(false);
	const { _id: _projectID } = useSelector((state: RootState) => state.projectBase);
	const { handleUpdateProject } = useUpdateProject();
	const { handleUpdateFunctionalUnit } = useUpdateFunctionalUnit();
	const { execute, status, value } = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_FUNCTIONAL,
		method: 'POST',
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (value?.data && value.data.length > 0) {
				const _adapted = EF_2.map((i, _idx) => ({
					...i,
					_id: value.data[_idx]._id,
				}));
				setEF_2(_adapted);
			}
			if (value?.data[0].functional) {
				handleUpdateFunctionalUnit(
					{ isFunctionalAssociated: true },
					value?.data[0].functional || ''
				);
			}
			if (!isSave) {
				handleUpdateProject(
					{
						step: '2.0.0',
						levels: 2,
						functionalBase: {
							name: EF_2[0].name,
							mainAttribute: EF_2[0].mainAttribute,
							attributes: EF_2[0].attributes,
						},
					},
					_projectID || ''
				);
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, isSave, setIsSave };
};

export default useUpdateEF;
