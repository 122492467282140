/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { IActivities, IAdaptedActivities } from '../interfaces/get-activities.interface';
import {
	getActivitiesAdapter,
	getActivitiesSelectAdapter,
} from '../adapters/get-activities.adapter';
import { IOptionsMenu } from '../../../interfaces/select.interface';

export const useGetActivitiesByFunctionals = () => {
	const dispatch = useDispatch();
	const [activitiesOptions, setActivitiesOptions] = useState<IAdaptedActivities[]>([]);
	const [activitiesOptionsSelect, setActivitiesOptionsSelect] = useState<
		IOptionsMenu[]
	>([]);

	const {
		execute: getActivitiesByFunctionals,
		status: statusGetActivitiesByFunctionals,
		value: valueGetActivitiesByFunctionals,
	} = useApiRequest<unknown, IResponsePayload<IActivities>>({
		path: URLS.EDIT_PROJECT.CHRONOGRAM.GET_ACTIVITIES_BY_FUNCTIONAL,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetActivitiesByFunctionals === 'success') {
			dispatch(stopLoading());
			setActivitiesOptions(
				getActivitiesAdapter(valueGetActivitiesByFunctionals?.data || [])
			);
			setActivitiesOptionsSelect(
				getActivitiesSelectAdapter(valueGetActivitiesByFunctionals?.data || [])
			);
		} else if (statusGetActivitiesByFunctionals === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetActivitiesByFunctionals]);

	return {
		activitiesOptions,
		activitiesOptionsSelect,
		getActivitiesByFunctionals,
		setActivitiesOptions,
	};
};
