/* eslint-disable array-callback-return */
import { isEmpty } from '../../../../../utils/validators';
import { ISummary as ISummaryInfo } from '../../../interfaces/get-status.interface';
import { ISummary } from '../interfaces/summary.interface';

export const summaryAdapter = (
	data: ISummaryInfo,
	saveAdaptedInfo: (
		dataTableAdapted: any[],
		selectedSheet: number,
		summaryAdapted: ISummary[],
		errors: string[],
		sheets: string[]
	) => void
) => {
	let sheets: string[] = [];
	let errors: string[] | undefined = undefined;
	const dataTableAdapted: any[] = [];
	const summaryAdapted: ISummary[] = [];

	data?.data.forEach((sheet) => {
		if (!sheet.canContinue) {
			sheets.push(sheet.itemTitle);
			const sheetColumns: any[] = [];
			let temporalValid = 0;
			let temporalSpaces = 0;
			if (isEmpty(sheet?.columns)) {
				errors = sheet?.errorsMessages;
				sheets = [];
				return;
			} else {
				sheet?.columns.forEach((column) => {
					temporalValid = temporalValid + column.validRows;
					if (!column.canContinue) {
						temporalSpaces = temporalSpaces + column.blankSpaces;
						sheetColumns.push({
							column: column.itemTitle,
							blankSpaces: column.blankSpaces,
							warnings: column.warningMessages.length > 0 && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									{column.warningMessages.map((e, index) => {
										return (
											<span key={`${column.itemTitle}-${index}`}>
												- {e}
											</span>
										);
									})}
								</div>
							),
							errors: column.errorsMessages.length > 0 && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									{column.errorsMessages.map((e, index) => {
										return (
											<span key={`${column.itemTitle}-${index}`}>
												- {e}
											</span>
										);
									})}
								</div>
							),
						});
					}
				});
			}
			summaryAdapted.push({
				valid: temporalValid || 0,
				errors: sheet?.errorsMessages?.length || 0,
				warnings: sheet?.warningMessages?.length || 0,
				blank_spaces: temporalSpaces || 0,
			});
			dataTableAdapted.push(sheetColumns);
		}
	});

	saveAdaptedInfo(
		dataTableAdapted,
		0,
		summaryAdapted,
		errors || data?.errorsMessages || [],
		sheets
	);
};
