import { INewUser } from '../../../interfaces/new-user.interface';
import { IUser } from '../../../interfaces/user.interface';

export const newUserAdapter = (data: IUser): INewUser => {
	const newData: INewUser = {
		firstName: data.firstName,
		lastName: data.lastName,
		employeeNumber: data.employeeNumber,
		email: data.email,
		position: data.position,
		role: data.role,
		password: '123456',
		token: '',
	};
	return newData;
};
