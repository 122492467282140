import CreationPage from '../../../../components/creation-page.component';
import { GoBack } from '../../../../components/go-back-component';
import { useSchedule } from './hooks/useSchedule';

export const Schedule = () => {
	const { ScheduleSteps, undoStep } = useSchedule();

	return (
		<>
			<GoBack action={undoStep} />
			<div>
				<CreationPage {...ScheduleSteps} />
			</div>
		</>
	);
};
