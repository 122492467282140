/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { stopLoading } from '../../../store/modules/loading';
import { IFunctionalResponse } from '../../../interfaces/project.interface';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';

const useGetUF_UE = () => {
	const dispatch = useDispatch<any>();
	const { execute, status, value } = useApiRequest<
		any,
		IResponsePayload<IFunctionalResponse>
	>({
		path: URLS.PROJECT.FUNCTIONAL,
		method: 'GET',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, value };
};

export default useGetUF_UE;
