/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../config/theme';
import { URLS } from '../../../constants/urls.constants';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setValuePP } from '../../../store/modules/profile-project';
import { setAlert } from '../../../store/modules/snackbar';
import { IProject, defaultProjectBase } from '../../../interfaces/project.interface';
import { AttributesList } from '../components/attributes/attributes.component';
import { Documentation } from '../components/documentation/documentation.component';
import { ExecutionComponent } from '../components/execution/execution.component';
import { PlanningComponent } from '../components/planning/planning.component';
import { ReportsComponent } from '../components/reports/reports.component';
import { useGetLevels } from './useGetLevels';
import { RootState } from '../../../store';
import { USER_ROLES } from '../../../utils/permissions.utils';
import { setValueProject } from '../../../store/modules/project';

export function useProjectProfile() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [step, setStep] = useState(-1);
	const state = location.state as string;
	const [currentTab, setCurrentTab] = useState<number>(0);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [data, setData] = useState<IProject>(defaultProjectBase);

	const tabItems = [
		{ label: 'Atributos del proyecto', value: 0 },
		{ label: 'Planeación de supervisión', value: 1 },
		{ label: 'Ejecución de la supervisión', value: 2 },
		{ label: 'Documentación', value: 3 },
		{ label: 'Reporteo', value: 4 },
	];
	const tabItemsExternal = [{ label: 'Ejecución de la supervisión', value: 0 }];
	const tabItemsSupervisor = [
		{ label: 'Planeación de supervisión', value: 0 },
		{ label: 'Ejecución de la supervisión', value: 1 },
		{ label: 'Documentación', value: 2 },
		{ label: 'Reporteo', value: 3 },
	];
	const tabItemsGerente = [
		{ label: 'Planeación de supervisión', value: 1 },
		{ label: 'Ejecución de la supervisión', value: 2 },
		{ label: 'Documentación', value: 3 },
		{ label: 'Reporteo', value: 4 },
	];
	const tabItemsDirector = [{ label: 'Planeación de supervisión', value: 1 }];

	const {
		execute: getProject,
		status: statusProject,
		value: valueProject,
	} = useApiRequest<unknown, IResponsePayload<IProject>>({
		path: URLS.PROJECT.CREATE_PROJECT,
		method: 'GET',
	});
	const { role } = useSelector((state: RootState) => state.users);
	const { _id } = useSelector((state: RootState) => state.projectBase);
	const { getLevels } = useGetLevels();

	useEffect(() => {
		if (!state && !_id) {
			navigate(-1);
		}
		dispatch(setLoading({ loading: true }));
		getProject({}, `/${state || _id}`);
	}, []);

	useEffect(() => {
		if (statusProject === 'success') {
			setData({ ...data, ...valueProject?.detail, files: [] });
			dispatch(setValuePP({ step: 0 }));
			dispatch(setValueProject({ ...valueProject?.detail }));
			getLevels({}, `/${state || _id}`);
			dispatch(stopLoading());
			setStep(0);
			setCurrentTab(0);
		} else if (statusProject === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusProject]);

	const getTabs = () => {
		switch (role) {
			case USER_ROLES.ADMIN:
				return tabItems;
			case USER_ROLES.EXTERNAL:
				return tabItemsExternal;
			case USER_ROLES.SUPERVISOR:
				return tabItemsSupervisor;
			case USER_ROLES.GERENTE:
				return tabItemsGerente;
			case USER_ROLES.DIRECTOR:
				return tabItemsDirector;
			default:
				return tabItems;
		}
	};
	const getComponent = () => {
		switch (role) {
			case USER_ROLES.ADMIN:
				return getComponentAdmin();
			case USER_ROLES.EXTERNAL:
				return getComponentExternal();
			case USER_ROLES.SUPERVISOR:
				return getComponentSupervisor();
			case USER_ROLES.GERENTE:
				return getComponentManager();
			case USER_ROLES.DIRECTOR:
				return getComponentDirector();
			default:
				return getComponentAdmin();
		}
	};

	const getComponentDirector = () => {
		switch (step) {
			case -1:
				return <></>;
			case 0:
			default:
				return <PlanningComponent />;
			case 1:
				return <ExecutionComponent />;
		}
	};

	const getComponentManager = () => {
		switch (step) {
			case -1:
				return <></>;
			case 0:
			default:
				return <PlanningComponent />;
			case 1:
				return <ExecutionComponent />;
			case 2:
				return <Documentation />;
			case 3:
				return <ReportsComponent />;
		}
	};
	const getComponentAdmin = () => {
		switch (step) {
			case -1:
				return <></>;
			case 0:
				return <AttributesList />;
			case 1:
				return <PlanningComponent />;
			case 2:
				return <ExecutionComponent />;
			case 3:
				return <Documentation />;
			case 4:
				return <ReportsComponent />;
			default:
				return <AttributesList />;
		}
	};

	const getComponentExternal = () => {
		return <ExecutionComponent />;
	};

	const getComponentSupervisor = () => {
		switch (step) {
			case -1:
				return <></>;
			case 0:
				return <PlanningComponent />;
			case 1:
				return <ExecutionComponent />;
			case 2:
				return <Documentation />;
			case 3:
				return <ReportsComponent />;
			default:
				return <AttributesList />;
		}
	};

	return {
		currentTab,
		matches,
		step,
		data,
		setData,
		setStep,
		dispatch,
		setCurrentTab,
		getComponent,
		getComponentExternal,
		getComponentSupervisor,
		getProject,
		getTabs,
	};
}
