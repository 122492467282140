/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { generateZIP } from '../../../../utils/generateZIP';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../hooks/useApiRequest';

export const useDownloadImageZip = () => {
	const dispatch = useDispatch();
	const [typeImg, setTypeImg] = useState('');

	const {
		execute: executeGetZip,
		status: statusGetZip,
		value: valueGetZip,
	} = useApiRequest<unknown, IResponsePayload<string>>({
		path: URLS.FILES.FILES_FINDINGS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetZip === 'success') {
			dispatch(stopLoading());
			if (valueGetZip?.detail)
				generateZIP(valueGetZip?.detail, 'zip', typeImg || 'Zip');
		} else if (statusGetZip === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al descargar el zip',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetZip]);

	const handleDownloadZip = (
		typeImg:
			| 'finding-files'
			| 'routine-files'
			| 'supervision-routine-files'
			| 'suspension-files',
		routine: string,
		project: string,
		page: string,
		recordsByPage: string
	) => {
		dispatch(setLoading({ loading: true }));
		setTypeImg(typeImg);
		executeGetZip(
			null,
			`/${typeImg}/zip?routine=${routine}&page=${page}&recordsByPage=${recordsByPage}&project=${project}`
		);
	};

	return { handleDownloadZip };
};
