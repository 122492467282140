import { Grid } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import styles from './styles/file-button-styles.component.module.css';
import { IFileButtonInterface } from '../interfaces/file-button.interface';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setAlert } from '../store/modules/snackbar';
import { setState } from '../store/modules/steps';
import { PanoramaOutlined } from '@mui/icons-material';

interface IFileButton {
	element: IFileButtonInterface;
	value?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
}

export const FileButton = ({ element, value, onChange }: IFileButton) => {
	const dispatch = useDispatch();
	const [isFilePicked, setIsFilePicked] = useState(false);

	const handleOnChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		id: string
	): void => {
		const fileList = event.target.files;
		if (!fileList) return setIsFilePicked(false);
		const extension = fileList[0].type;
		if (element.accept !== extension) {
			dispatch(
				setAlert({
					show: true,
					message: `El archivo debe tener extension ${element.accept}`,
					severity: 'error',
				})
			);
		} else {
			setIsFilePicked(true);
			onChange(event, id);
		}
	};

	return (
		<Grid
			container
			className={`${styles.uploadPDF} ${element.step ? styles.cursorStyle : ''}`}
			onClick={() =>
				element.step ? dispatch(setState({ state: element.step })) : null
			}
			xs={12}
			md={6}
			lg={5}
			component="div"
			item
			sx={{
				display: 'flex',
				gap: { xs: '10px' },
			}}
			alignItems={'center'}
		>
			<label htmlFor={element.step ? '' : element.id} style={{ cursor: 'pointer' }}>
				<Grid item xs={2} md={2} lg={2}>
					<div
						className={`${styles.cardImage} ${
							isFilePicked || value ? styles.cardImagePicked : ''
						}`}
					>
						{element.icon === 'ImageIcon' ? (
							<PanoramaOutlined />
						) : (
							<UploadFileOutlinedIcon />
						)}
						<span>{element.iconText}</span>
					</div>
				</Grid>
			</label>
			<Grid item xs={8} md={4} lg={2}>
				<div className={styles.cardInformation}>
					<InfoOutlinedIcon />
					<p>{element.information}</p>
				</div>
				<input
					id={element.id}
					data-testid={element.id}
					type="file"
					onChange={
						element.step
							? () => null
							: (event) => handleOnChange(event, element.id)
					}
					style={{ display: 'none' }}
					accept={element.accept}
				/>
			</Grid>
		</Grid>
	);
};
