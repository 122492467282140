/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from './../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { URLS } from '../../../constants/urls.constants';
import { IFunctional } from '../../../interfaces/project.interface';

const useUpdateFunctionalUnit = () => {
	const dispatch = useDispatch<any>();
	const { execute, status, value } = useApiRequest<
		Partial<IFunctional>,
		IResponsePayload<IFunctional>
	>({
		path: URLS.PROJECT.FUNCTIONAL,
		method: 'PATCH',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Se actualizó la unidad funcional correctamente',
					severity: 'success',
				})
			);
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'No se ha podido actualizar la unidad funcional',
					severity: 'error',
				})
			);
		}
	}, [status]);

	const handleUpdateFunctionalUnit = (
		dataValue: Partial<IFunctional> | undefined,
		id: string
	) => {
		dispatch(setLoading({ loading: true }));
		execute({ ...dataValue }, `/${id}`);
	};

	return { handleUpdateFunctionalUnit, value, status };
};

export default useUpdateFunctionalUnit;
