/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';

interface IGet {
	isSave: boolean;
}

const useUpdateIG_Act = (props: IGet) => {
	const dispatch = useDispatch<any>();
	const { execute, status } = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_SUPERVISION_UNIT,
		method: 'POST',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			setAlert({
				severity: 'success',
				show: true,
				message: 'Proyecto guardado con éxito',
			});
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute };
};

export default useUpdateIG_Act;
