import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import VerticalStepper from '../../../../../components/vertical-stepper-fixed.component';
import DashedBtn from '../../../../../components/dashed-button.component';
import { Button } from '../../../../../components/main-button.component';
import { dayOptions } from '../../../../../constants/frecuencia';
import { useGetNonWorkingDays } from './hooks/useGetNonWorkingDays';
import styles from '../../schedule-activity/components/styles/view-activities-styles.module.css';
import { RowItem } from './row-item.component';

export const FormNonWorkingDays = () => {
	const {
		daysSelected,
		specificDays,
		handleNewDay,
		handleDay,
		handleTable,
		handleDelete,
		handleUpdateNonWorkingDays,
	} = useGetNonWorkingDays();

	return (
		<>
			<Grid item xs={12}>
				<h4
					style={{
						margin: '1rem 0 0 0',
						fontWeight: 600,
					}}
				>
					Todos los días seleccionados del año
				</h4>
			</Grid>
			<Grid item xs={12}>
				{dayOptions.map((i) => (
					<FormControlLabel
						key={`day-${i.value}`}
						value="end"
						control={
							<Checkbox
								id={`day-${i.value}`}
								checked={daysSelected.has(i.value)}
							/>
						}
						onChange={() => handleDay(i.value)}
						label={i.label}
						labelPlacement="end"
					/>
				))}
			</Grid>
			<Grid item xs={12}>
				<h4
					style={{
						margin: '1rem 0 0 0',
						fontWeight: 600,
					}}
				>
					Días específicos del año
				</h4>
			</Grid>
			<Grid item xs={12}>
				<VerticalStepper
					gridKey="criterios-table"
					sxContainer={styles.containerVerticalStepper}
					sxElement={styles.verticalStepperSxElement}
					spacing={6}
					items={specificDays}
					renderItem={(item, column) => (
						<RowItem
							item={item}
							column={column}
							handleTable={handleTable}
							handleDelete={handleDelete}
						/>
					)}
					hiddeLines
				/>
			</Grid>
			<Grid item xs={12}>
				<DashedBtn
					onClick={() => handleNewDay()}
					width="100"
					text="Agregar día no laborable"
				/>
			</Grid>
			<Grid item>
				<Button
					label="Guardar"
					onClick={() => handleUpdateNonWorkingDays()}
					variant="outlined"
					style={{
						backgroundColor: ' #0080F9',
						width: '200px',
						color: 'white',
					}}
				/>
			</Grid>
		</>
	);
};
