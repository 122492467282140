import { IColumns } from '../../../interfaces/table.insterface';

export const columnsNotifications: IColumns[] = [
	{
		text: 'Dispositivo',
		nameID: 'device',
	},
	{
		text: 'Titulo',
		nameID: 'title',
	},
	{
		text: 'Fecha de creación',
		nameID: 'date',
	},
	{
		text: 'Mensaje',
		nameID: 'body',
		width: 400,
	},
];

export const columnsNotificationsAdmin: IColumns[] = [
	{
		text: 'Dispositivo',
		nameID: 'device',
	},
	{
		text: 'Usuario',
		nameID: 'user',
	},
	{
		text: 'Titulo',
		nameID: 'title',
	},
	{
		text: 'Fecha de creación',
		nameID: 'date',
	},
	{
		text: 'Mensaje',
		nameID: 'body',
		width: 400,
	},
];
