import TableCustom from '../../../../../components/table-custom.component';
import { useInitialEvidences } from '../hooks/useInitialEvidences';
import { columnsInitialEvidences } from '../utils/columns-initial-evidences.utils';
import { Image } from '@mui/icons-material';
import { useDownloadImageZip } from '../../hooks/useDownloadImageZip';

export const InitialEvidences = () => {
	const { data, _id, idRoutine, idRoutineParams, idProject, idProjectParams, setData } =
		useInitialEvidences();
	const { handleDownloadZip } = useDownloadImageZip();

	return (
		<TableCustom
			titleHeader="Testigos de inicio"
			columns={columnsInitialEvidences}
			data={data}
			setData={setData}
			showPagination
			showLimitPerPage
			showSearch={false}
			buttons={
				data.rows.length !== 0
					? [
							{
								icon: Image,
								label: 'Descargar testigos de inicio',
								id: 'descarga-testigos',
								execute: () => {
									handleDownloadZip(
										'routine-files',
										idRoutineParams || idRoutine,
										_id || idProjectParams || idProject,
										data.pagination,
										data.rowsPerPage || '0'
									);
								},
								type: 'blue',
								width: 250,
							},
					  ]
					: []
			}
		/>
	);
};
