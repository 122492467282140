import CreationPage from '../../../../components/creation-page.component';
import { useGeographicalDimensions } from './hooks/useGeographicalDimensions';

export const GeographicalDimensions = () => {
	const { GeographicalDimensionsSteps } = useGeographicalDimensions();

	return (
		<div>
			<CreationPage {...GeographicalDimensionsSteps} />
		</div>
	);
};
