import { Check, Update } from '@mui/icons-material';
import ButtonIconSmall from '../../../../components/button-icon-small.component';
import { ISuspensionByRoutine } from '../interfaces/suspension-by-routine.interface';
import { Box } from '@mui/material';

interface SuspensionByRoutineAdapter extends ISuspensionByRoutine {
	acciones: React.ReactNode;
}
export const suspensioByRoutineAdapter = (
	data: ISuspensionByRoutine[],
	handleConfirm: (supervisionRoutine: string) => void,
	handleReschedule: (supervisionRoutine: string) => void
): SuspensionByRoutineAdapter[] => {
	const newData: SuspensionByRoutineAdapter[] = data.map(
		(suspension: ISuspensionByRoutine) => {
			return {
				...suspension,
				acciones: (
					<Box display="flex" gap={1}>
						<ButtonIconSmall
							text="Confirmar suspensión"
							type="success"
							icon={<Check />}
							style={{ fontSize: '10px' }}
							onPress={() => handleConfirm(suspension.supervisionRoutine)}
						/>
						<ButtonIconSmall
							text="Reprogramar"
							type="grey"
							icon={<Update />}
							style={{ fontSize: '10px' }}
							onPress={() =>
								handleReschedule(suspension.supervisionRoutine)
							}
						/>
					</Box>
				),
			};
		}
	);
	return newData;
};
