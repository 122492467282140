/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { IGetLevels } from '../../../../../interfaces/get-levels.interface';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { SplitArrayInNumbers } from '../../../../../utils/splitArray';
import { statusCreation } from '../../../constants/creation-supervision.component';
import { ISpatialUnit } from '../../../interfaces/get-supervision.interfaces';
import { StepOneGD } from '../components/step-one.component';
import { StepTwoGD } from '../components/step-two.component';
import { useGetFunctionals } from './useGetFunctionals';
import { ICreationPage } from '../../../../../interfaces/creation-page-interfaces';
import { useNavigate } from 'react-router-dom';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';
import { isEmpty } from '../../../../../utils/validators';
import { useChangeStep } from '../../../hooks/useChangeStep';

export function useGeographicalDimensions() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [valueUnit, setValueUnit] = useState<string>('');
	const [valueUnitN2, setValueUnitN2] = useState<string[]>([]);
	const { idProject, modal, supervisionExtraordinaryData, setModal } = useContext(
		CreateSupervisionContext
	);

	const [steps, setSteps] = useState<{
		step1: boolean;
		step2: boolean;
	}>({ step1: true, step2: false });

	const { functionalUnit, getFunctionals } = useGetFunctionals({
		setFuncionals: setValueUnit,
		isLevelOne: true,
	});
	const { functionalUnit: functionalN2, getFunctionals: getfunctionalsN2 } =
		useGetFunctionals({
			setFuncionals: setValueUnitN2,
			isLevelOne: false,
		});

	const { changeStepSE } = useChangeStep();

	const {
		execute: getLevels,
		status: statusLevels,
		value: valueLevels,
	} = useApiRequest<unknown, IResponsePayload<IGetLevels>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.GET_LEVELS,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getLevels({}, `/${idProject}`);
	}, []);

	useEffect(() => {
		if (statusLevels === 'success') {
			getFunctionals(
				{},
				`?project=${idProject}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&functional=null`
			);
		} else if (statusLevels === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear la supervisión extraordinaria.',
					severity: 'error',
				})
			);
			navigate(-1);
		}
	}, [statusLevels]);

	useEffect(() => {
		if (
			!isEmpty(valueUnit) &&
			supervisionExtraordinaryData.step === STEPS_SUPERVISION_EXTRAORDINARY.DG_2
		) {
			dispatch(setLoading({ loading: true }));
			getfunctionalsN2(
				{},
				`?project=${idProject}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&functional=${valueUnit}`
			);
		}
	}, [valueUnit]);

	useEffect(() => {
		if (supervisionExtraordinaryData.step === STEPS_SUPERVISION_EXTRAORDINARY.DG_1) {
			return setSteps({ step1: true, step2: false });
		}
		setSteps({ step1: false, step2: true });
	}, [supervisionExtraordinaryData.step]);

	useEffect(() => {
		if (!isEmpty(supervisionExtraordinaryData.functionalUnit)) {
			getfunctionalsN2(
				{},
				`?project=${idProject}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&functional=${supervisionExtraordinaryData.functionalUnit}`
			);
		}
	}, [supervisionExtraordinaryData.functionalUnit]);

	const GeographicalDimensionsSteps: ICreationPage = {
		header: {
			label: 'Estado de creación',
			stepper: {
				activeStep: 0,
				items: statusCreation,
				completed: {},
			},
		},
		stepOne: {
			title: `1. Elige ${valueLevels?.detail?.levels[0]}`,
			show: true,
			Children: (
				<StepOneGD
					items={SplitArrayInNumbers(functionalUnit, 5)}
					labels={functionalUnit as unknown as ISpatialUnit[]}
					step={steps}
					initialValue={valueUnit}
					levels={valueLevels?.detail.totalLevels || 0}
				/>
			),
		},
		stepTwo: {
			title: `2. Elige ${valueLevels?.detail?.levels[1]}`,
			show: steps.step2,
			Children: (
				<StepTwoGD
					items={SplitArrayInNumbers(functionalN2, 5)}
					rawData={functionalN2 as unknown as ISpatialUnit[]}
					setSteps={setSteps}
					initialValue={valueUnitN2}
				/>
			),
			showExitButon: true,
			onExit: () => {
				setModal({
					...modal,
					open: true,
					callback: () => {
						dispatch(setLoading({ loading: true }));
						changeStepSE(
							{
								newStep: STEPS_SUPERVISION_EXTRAORDINARY.DG_1,
								extraordinarySupervision:
									supervisionExtraordinaryData.idSupervisionExtraordinary,
							},
							`/${idProject}`
						);
					},
				});
			},
		},
	};

	return { GeographicalDimensionsSteps };
}
