import { useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import style from './styles/table-general-info-styles.module.css';

interface IDataTableProps {
	headers: { id: string; name: string }[];
	data: any[];
	align?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
	labelStyles?: React.CSSProperties;
}

export default function DataTable({
	headers,
	data,
	labelStyles,
	align,
}: IDataTableProps) {
	const [scroll, setScroll] = useState<boolean>(false);

	const useHorizontalScrollEvent = (callback: any) => {
		const positionRef = useRef(0);
		return (e: any) => {
			const x = e.currentTarget.scrollLeft;
			if (x !== positionRef.current) {
				positionRef.current = x;
				callback(positionRef.current);
			}
		};
	};

	const handleScroll = useHorizontalScrollEvent((e: any) => {
		e === 0 ? setScroll(false) : setScroll(true);
	});

	return (
		<>
			<TableContainer
				id={style.tabla}
				component={Paper}
				sx={{ ...labelStyles }}
				onScroll={handleScroll}
			>
				<Table>
					<TableHead className={style.header} style={{ textAlign: align }}>
						<tr>
							{headers.map((header, index) => (
								<th
									className={`${style.headerTitle} ${
										index === 0 && scroll ? style.scroll : ''
									}`}
									key={header.id}
									style={{ width: '50%' }}
								>
									{header.name}
								</th>
							))}
						</tr>
					</TableHead>
					<TableBody style={{ textAlign: align }}>
						{data.length > 0 ? (
							data.map((emp, index) => (
								<TableRow key={index}>
									{headers.map((header, index2) => (
										<td
											key={`${header.id}-${index2}`}
											style={{ ...labelStyles }}
											className={` ${
												index2 === 0 ? style.sticky : ''
											}
											${index2 === 0 && scroll ? style.scroll : ''}
											`}
										>
											{emp[header.id] ? emp[header.id] : '-'}
										</td>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<td
									style={{
										textAlign: 'center',
									}}
									colSpan={headers.length}
								>
									Sin resultados
								</td>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
