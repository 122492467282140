/* eslint-disable no-unused-vars */
export const getDay = (day: string) => {
	switch (day) {
		case 'SUNDAY':
			return 'DOM';
		case 'MONDAY':
			return 'LUN';
		case 'TUESDAY':
			return 'MAR';
		case 'WEDNESDAY':
			return 'MIER';
		case 'THURSDAY':
			return 'JUE';
		case 'FRIDAY':
			return 'VIE';
		case 'SATURDAY':
			return 'SAB';
	}
};
