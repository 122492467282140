import { NOT_STARTED_TEAMS } from './constants/messages';
import { MessagesView } from '../../components/messages-view.component';
import { useConfirmProgress } from '../../hooks/useConfirmProgress';
import { STEPS_AUTOMATIC_CHARGE } from '../../utils/steps-automatic-charge';

export const NotStartedTeams = () => {
	const { confirmProgress } = useConfirmProgress();

	return (
		<MessagesView
			descriptionLabel={NOT_STARTED_TEAMS.DESCRIPTION}
			cancelAction={() =>
				confirmProgress({
					newStep: STEPS_AUTOMATIC_CHARGE.STARTED_LOAD_PERSONAL_DATA,
				})
			}
			continueAction={() => confirmProgress()}
			cancelLabel="Continuar sin equipos"
			continueLabel="Sí, hacer equipos"
		/>
	);
};
