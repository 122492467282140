import { IActivities } from '../interfaces/get-activities.interface';
import { INewInfoProject } from '../interfaces/new-info-project.interface';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';

export const getActivitiesAdapter = (
	data: IActivities[],
	userToDelete: string,
	projectData?: INewInfoProject
) => {
	const activitiesData = (data || []).map((activity) => {
		const activityInfo = projectData?.activities?.find(
			(act) => act?._id === activity.activity
		);
		return {
			id: activity.activity,
			mainAttribute: 'Actividad',
			description: activity.activityDescription,
			rows: [
				{
					key: 'Ejecutor',
					value:
						activityInfo?.executor ||
						activity.executor ||
						activityInfo?.executorTeam ||
						activity.executorTeam,
					isDisabled: activity.executor !== userToDelete,
				},
				{
					key: 'Supervisor',
					value:
						activityInfo?.supervisor ||
						activity.supervisor ||
						activityInfo?.supervisorTeam ||
						activity.supervisorTeam,
					isDisabled: activity.supervisor !== userToDelete,
				},
				{
					key: 'Sustituto 1',
					value:
						activityInfo?.substitute1 ||
						activity.substitute1 ||
						activityInfo?.substitute1Team ||
						activity.substitute1Team,
					isDisabled: activity.substitute1 !== userToDelete,
				},
				{
					key: 'Sustituto 2',
					value:
						activityInfo?.substitute2 ||
						activity.substitute2 ||
						activityInfo?.substitute2Team ||
						activity.substitute2Team,
					isDisabled: activity.substitute2 !== userToDelete,
				},
			],
			frequency: '',
			isComplete: true,
		};
	});

	return activitiesData as unknown as IAdaptedValues[];
};

export const getDataProjectAdapted = (
	data: IAdaptedValues[],
	idProject: string,
	getIsTeam: (_id: string | null) => boolean
) => {
	const activitiesData = (data || []).map((activity) => {
		const isExecutorTeam = getIsTeam(activity.rows[0].value);
		const isSupervisorTeam = getIsTeam(activity.rows[1].value);
		const isSubstitute1Team = getIsTeam(activity.rows[2].value);
		const isSubstitute2Team = getIsTeam(activity.rows[3].value);
		const activityData: { [key: string]: string | null } = {
			_id: activity.id,
			executor: isExecutorTeam ? null : activity.rows[0].value,
			executorTeam: isExecutorTeam ? activity.rows[0].value : null,
			supervisor: isSupervisorTeam ? null : activity.rows[1].value,
			supervisorTeam: isSupervisorTeam ? activity.rows[1].value : null,
			substitute1: isSubstitute1Team ? null : activity.rows[2].value,
			substitute1Team: isSubstitute1Team ? activity.rows[2].value : null,
			substitute2: isSubstitute2Team ? null : activity.rows[3].value,
			substitute2Team: isSubstitute2Team ? activity.rows[3].value : null,
		};
		return activityData;
	});

	return {
		project: idProject,
		activities: activitiesData,
	} as unknown as INewInfoProject;
};
