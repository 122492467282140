import { IColumns } from '../../../../../interfaces/table.insterface';

export const columnsAreas: IColumns[] = [
	{
		text: 'Unidad funcional',
		nameID: 'description',
	},
	{
		text: '',
		nameID: 'acciones',
		align: 'right',
	},
];
