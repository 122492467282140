/* eslint-disable react-hooks/exhaustive-deps */
import { Add, OpenInBrowser, Edit, RemoveRedEye } from '@mui/icons-material';
import { Button } from '../../../../../components/main-button.component';
import { Grid, useMediaQuery } from '@mui/material';
import { getDateLocalMX } from '../../../../../utils/dates';
import { formatISO, startOfMonth, endOfMonth, format, secondsToMinutes } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import theme from '../../../../../config/theme';
import { useState, useEffect, useContext } from 'react';
import InputSelect from '../../../../../components/select-input.component';
import { useDispatch, useSelector } from 'react-redux';
import { setValuePP } from '../../../../../store/modules/profile-project';
import { useNavigate } from 'react-router-dom';
import { setState } from '../../../../../store/modules/steps';
import { useGetPlannings } from '../hooks/useGetPlannings';
import { useGetSupervisors } from '../hooks/useGetSupervisors';
import { setLoading } from '../../../../../store/modules/loading';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';
import { isAdmin, isDirector, isGerente } from '../../../../../utils/permissions.utils';
import { RootState } from '../../../../../store';
import { useExportPlanning } from '../hooks/useExportPlanning';
import Modal from '../../../../../components/modal.component';
import ExportPlanning from './exportPlanning';
import Calendar from '../../../../../components/calendar.component';
import { NewPlanningKeys, ICalendarDay } from '../interfaces/calendar.interface';

export const PlanningMain = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { data } = useContext(ProfileProjectContext);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { role } = useSelector((state: RootState) => state.users);
	const [dataCalendar, setDataCalendar] = useState<ICalendarDay[]>([]);
	const [startMonth, setStartMonth] = useState(startOfMonth(new Date()));
	const [endMonth, setEndMonth] = useState(endOfMonth(new Date()));
	const [calendar, setCalendar] = useState<any[]>();
	const { modal, openModal, setModal } = useExportPlanning();
	const [fullParams, setFullParams] = useState('');
	const [infoPophover, setInfoPophover] = useState<string | null>(null);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetSupervisors({}, `/${data._id}`);
		executeGetActivities({}, `?project=${data._id}`);
	}, []);

	const { executeGetPlannings, dataPlanning } = useGetPlannings();
	const {
		executeGetSupervisors,
		executeGetActivities,
		activities,
		supervisors,
		getIsTeam,
	} = useGetSupervisors();

	const [filters, setFilters] = useState({
		activitie: '',
		supervisor: '',
	});

	const handleFilters = (e: any, property: NewPlanningKeys) => {
		let params: string = '';
		(filters[property] as any) = e.target.value;

		if (property === 'supervisor') {
			if (e.target.value === 'all') {
				filters.supervisor = '';
				filters.activitie !== ''
					? (params = `&activitie=${filters.activitie}`)
					: (filters.activitie = '');
			} else {
				params = getIsTeam(e.target.value)
					? `&team=${e.target.value}`
					: `&supervisor=${e.target.value}`;
			}
		}
		if (property === 'activitie') {
			if (e.target.value === 'all') {
				filters.activitie = '';
				filters.supervisor !== ''
					? (params = getIsTeam(filters.supervisor)
							? `&team=${filters.supervisor}`
							: `&supervisor=${filters.supervisor}`)
					: (filters.supervisor = '');
			} else params = `&activity=${e.target.value}`;
		}
		if (filters.activitie !== '' && filters.supervisor !== '') {
			params = `&${getIsTeam(filters.supervisor) ? 'team=' : 'supervisor='}${
				filters.supervisor
			}&activity=${filters.activitie}`;
		}

		setFilters({ ...filters });
		dispatch(setLoading({ loading: true }));
		setFullParams(params);
		executeGetPlannings(
			{},
			`?project=${data._id}
			&executionDateFrom=${formatISO(new Date(startMonth))}
			&executionDateTo=${formatISO(new Date(endMonth))}` + params
		);
	};

	const onRedirect = (e: any) => {
		e.jsEvent.preventDefault();
		const ePopover = document.getElementsByClassName('popover');

		while (ePopover.length > 0) {
			const boxP: any = ePopover[0];
			boxP.remove();
		}
		const routine: any = e?.event?._def?.extendedProps?.routine;
		const dateRutine: string = e?.event?._def?.extendedProps?.dateRutine;
		const statusRutine: boolean = e?.event?._def?.extendedProps?.status;
		const supervisor: string = e?.event?._def?.extendedProps?.idSupervisor;

		if (dateRutine < formatISO(new Date()) && statusRutine === false) {
			dispatch(setValuePP({ step: 3 }));
			navigate('/perfil-proyecto', {
				state: {
					params: {
						project: data._id,
						routine: routine,
						planeacion: true,
					},
				},
			});
		}

		if (dateRutine <= formatISO(new Date()) && statusRutine === true)
			navigate('/perfil-proyecto/ver-supervision', {
				state: {
					project: data._id,
					routine: routine,
					supervisor: supervisor,
					planeacion: true,
				},
			});

		if (dateRutine > formatISO(new Date()))
			navigate('/perfil-proyecto/proximas-tareas/ver-supervision', {
				state: {
					params: {
						project: data._id,
						idRoutine: routine,
						supervisor: supervisor,
						planeacion: true,
					},
				},
			});
	};

	useEffect(() => {
		const getView: any = calendar;
		const data: ICalendarDay[] = [];
		dataPlanning.forEach((e) => {
			const formattedDate = getDateLocalMX(e.executionDate)
				.split('/')
				.reverse()
				.join('-');
			const supervisor = e.supervisorName || e.teamName;
			const idSupervisor = e.supervisor || e.team;
			data.push({
				id: e.routine,
				textColor: 'black',
				title:
					e.type === 'RECURRENT'
						? 'Supervisión recurrente'
						: e.type === 'EXTRAORDINARY'
						? 'Supervisión extraordinaria'
						: 'Supervisión no recurrente',
				supervisor: supervisor,
				idSupervisor: idSupervisor,
				date: formattedDate,
				allDay: true,
				backgroundColor: 'transparent',
				borderColor:
					getView?.view?.type === 'dayGridMonth'
						? 'transparent'
						: e.status === 'ACCOMPLISHED'
						? '#15b512'
						: e.status === 'EXECUTING'
						? '#ffe203'
						: '#B51233',
				eventColor:
					e.status === 'ACCOMPLISHED'
						? '#15b512'
						: e.status === 'EXECUTING'
						? '#ffe203'
						: '#B51233',
				routine: e.routine,
				dateRutine: e.executionDate,
				status: e.canBeExecuted ? e.canBeExecuted : false,
				dataPopover:
					'<p>Supervisor: ' +
					supervisor +
					'<p>Fecha inicio: ' +
					(e.initialDate !== null
						? format(new Date(e.initialDate), 'EEEE PPP, HH:mm', {
								locale: esLocale,
						  })
						: 'Pendiente') +
					'</p><p>Fecha fin: ' +
					(e.endDate !== null
						? format(new Date(e.endDate), 'EEEE PPP, HH:mm', {
								locale: esLocale,
						  })
						: 'Pendiente') +
					'</p><p>Tiempo estimado: ' +
					secondsToMinutes(e.timeTakenInSeconds) +
					' minutos',
			});
		});

		setDataCalendar(data);
	}, [dataPlanning]);

	const getDates = (dates: any) => {
		setCalendar(dates);
		setDataCalendar([]);
		setStartMonth(dates?.startStr);
		setEndMonth(dates?.endStr);
		dispatch(setLoading({ loading: true }));
		executeGetPlannings(
			{},
			`?project=${data._id}
			&executionDateFrom=${dates?.startStr}
			&executionDateTo=${dates?.endStr}${fullParams}`
		);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'space-between'}
					alignItems="center"
				>
					<Grid item>
						<b
							style={{
								fontSize: '25px',
							}}
						>
							Calendario de supervisión
						</b>
					</Grid>
					<Grid item>
						<Grid container justifyContent="center" spacing={1}>
							{(isAdmin(role) || isGerente(role)) && (
								<Grid item>
									<Button
										label=""
										onClick={() => navigate('resumen-tareas')}
										style={{
											textTransform: 'none',
											color: '#0080F9',
											backgroundColor: '#cbe5fb',
										}}
										icon={RemoveRedEye}
										tooltip="Ver tareas asignadas"
										variant="text"
									/>
								</Grid>
							)}
							<Grid item>
								<Button
									label=""
									onClick={() => openModal()}
									style={{
										textTransform: 'none',
										color: '#0080F9',
										backgroundColor: '#cbe5fb',
									}}
									icon={OpenInBrowser}
									tooltip="Exportar planeación"
									variant="text"
								/>
							</Grid>
							{(isAdmin(role) || isGerente(role)) && !isDirector(role) && (
								<Grid item>
									<Button
										label=""
										onClick={() => {
											dispatch(setValuePP({ step: 1 }));
										}}
										style={{
											textTransform: 'none',
											color: '#0080F9',
											backgroundColor: '#cbe5fb',
										}}
										icon={Edit}
										tooltip="Editar supervisión"
										variant="text"
									/>
								</Grid>
							)}
							{(isAdmin(role) || isGerente(role)) && (
								<Grid item>
									<Button
										label="Crear supervisión extraordinaria"
										style={{ backgroundColor: '#0080F9' }}
										icon={Add}
										onClick={() => {
											navigate(
												'supervision-extraordinaria/crear-supervision-extraordinaria',
												{
													state: data._id,
												}
											);
											dispatch(setState({ state: 0 }));
										}}
										id="btn-create-extraordinary"
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} marginBottom={2}>
				<Grid item>
					<Grid container spacing={3} justifyContent="flex-end">
						<Grid item>
							Actividad
							<br />
							<InputSelect
								id="activity"
								placeholder="Actividad"
								value={filters.activitie}
								optionsMenu={activities}
								onChange={(e) => {
									handleFilters(e, 'activitie');
								}}
								height="auto"
								width="200px"
							/>
						</Grid>
						<Grid item>
							Supervisor
							<br />
							<InputSelect
								id="supervisor"
								value={filters.supervisor}
								optionsMenu={supervisors}
								placeholder="Supervisor"
								onChange={(e) => {
									handleFilters(e, 'supervisor');
								}}
								height="auto"
								width="200px"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Calendar
				events={dataCalendar}
				datesSet={(info) => getDates(info)}
				eventClick={(e) => onRedirect(e)}
				dataCalendar={calendar}
				infoPophover={infoPophover}
				setInfoPophover={setInfoPophover}
			/>
			{modal.open && (
				<Modal
					open={modal.open}
					title={'Exportar planeación'}
					onClose={modal.onClose}
					hideCancel
					hideConfirm
					width="md"
					modal={modal}
					setModal={setModal}
				>
					<ExportPlanning supervisors={supervisors} />
				</Modal>
			)}
		</Grid>
	);
};
