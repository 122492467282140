import { getDateLocalMX } from '../../../utils/dates';
import { IDevice, IDeviceRow } from '../interfaces/device.interface';

export const deviceAdapter = (data: IDeviceRow[]): IDeviceRow[] => {
	const newData: IDeviceRow[] = data.map((device: IDevice) => {
		return {
			...device,
			lastConnection: getDateLocalMX(device.lastConnection),
		};
	});
	return newData;
};
