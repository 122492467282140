import { IValuesInfo } from '../interfaces/edit-values.interface';
import { NewActivity } from '../pages/edit-activities/interfaces/get-activities.interface';

export const getNewValueAdapter = (
	newLabel: string,
	idProject: string,
	data?: IValuesInfo[]
): NewActivity => {
	if (!data)
		return {
			attributes: [],
			columns: [],
			name: newLabel,
			description: '',
			mainAttribute: '',
		};
	const activityReference = data[0];
	const newValue: NewActivity = {
		attributes: activityReference.attributes,
		columns: activityReference.attributes.map((element, index) => {
			return index === 0 ? newLabel : '';
		}),
		description: newLabel,
		name: activityReference.name,
		mainAttribute: activityReference.mainAttribute,
		project: idProject,
	};
	return newValue;
};
