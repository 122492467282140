/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

export interface IColumns {
	text: string | React.ReactElement;
	nameID: string;
	width?: number | string;
	sort?: boolean;
	align?: 'left' | 'center' | 'right';
}

interface ISort {
	col: string;
	order: 'ASC' | 'DESC' | '';
}

export interface IDataTable<I> {
	countRows: number;
	rows: I[];
	sort: ISort;
	pagination: string;
	rowsSelect?: any[];
	rowsPerPage?: string;
	filters?: { [key: string]: string | number };
	advancedFilter?: string;
	activeAdvanceFilter?: boolean;
	filterCustom?: { [key: string]: string | number };
}

export const useTableCustom = <IData>(
	initialState: IDataTable<IData> = {
		countRows: 0,
		rows: [],
		sort: { col: '', order: '' },
		pagination: '1',
		rowsSelect: [],
		rowsPerPage: '10',
		filters: {},
		advancedFilter: '',
		activeAdvanceFilter: false,
		filterCustom: {},
	}
) => {
	const [data, setData] = useState<IDataTable<IData>>(initialState);
	let actionTimeout: any = null;

	const handleFilter = (args: { [key: string]: string }) => {
		clearTimeout(actionTimeout);
		setData({
			...data,
			pagination: '1',
			activeAdvanceFilter: false,
			filters: {
				...args,
				...data.filterCustom,
				advancedSearch: data?.advancedFilter || '',
			},
		});
	};

	const handleGetFiltersParams = (): any => {
		let filtersString = '';
		const dataFilters = Object.entries(data?.filters || {});
		const noEmpty = dataFilters?.filter((e) => e[1] !== '');
		noEmpty?.forEach(([key, value]) => {
			filtersString =
				filtersString + ('&' + key.toString() + '=' + value.toString());
		});
		return filtersString;
	};

	const handleSearch = () => {
		actionTimeout = setTimeout(() => {
			const newFilter = data?.filters || {};
			setData({
				...data,
				pagination: '1',
				activeAdvanceFilter: false,
				filters: {
					...newFilter,
					...data.filterCustom,
					advancedSearch: data?.advancedFilter || '',
				},
			});
			return null;
		}, 2000);
	};

	useEffect(() => {
		if (data.activeAdvanceFilter) {
			handleSearch();
			return () => clearTimeout(actionTimeout);
		}
	}, [data.advancedFilter]);

	return {
		...data,
		data,
		setData,
		handleFilter,
		handleGetFiltersParams,
	};
};
