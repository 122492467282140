import { styled } from '@mui/material/styles';
import ChipMUI from '@mui/material/Chip';

const ListItem = styled('li')(({ theme }) => ({
	margin: theme.spacing(0.5),
}));

const Chip = styled(ChipMUI)(({ theme }) => ({
	background: '#C1E0FE',
	color: '#0080F9',
	svg: {
		color: '#0080F9 !important',
		opacity: 0.5,
	},
}));

interface IChipList {
	id: string | number;
	label: string;
	disabled?: boolean;
	onDelete?: (key: string | number) => void;
}
const ChipList = (props: IChipList) => {
	const { id, label, onDelete, disabled = false } = props;
	return (
		<ListItem>
			<Chip
				label={label}
				disabled={disabled}
				onDelete={() => onDelete && onDelete(id)}
			/>
		</ListItem>
	);
};

export default ChipList;
