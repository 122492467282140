/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useApiRequest, IResponsePayload } from './../../../hooks/useApiRequest';
import { URLS } from './../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { stopLoading } from './../../../store/modules/loading';
import { setAlert } from './../../../store/modules/snackbar';
import { useNavigate } from 'react-router-dom';

export const useCreateExtraordinaryIncidence = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		execute: executeCreateIncidence,
		status: statusCreateIncidence,
		value: valueCreateIncidence,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.EXTRAORDINARY_INCIDENCE.CREATE_EXTRAORDINARY_INCIDENCE,
		method: 'POST',
	});

	useEffect(() => {
		if (statusCreateIncidence === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Incidencia extraordinaria creada con éxito',
					severity: 'success',
				})
			);
			navigate(-1);
		} else if (statusCreateIncidence === 'error')
			dispatch(
				setAlert({
					show: true,
					message: valueCreateIncidence?.message
						? valueCreateIncidence?.message
						: 'Ocurrió un error al crear incidencia extraordinaria',
					severity: 'error',
				})
			);

		dispatch(stopLoading());
	}, [statusCreateIncidence]);

	return {
		executeCreateIncidence,
	};
};
