/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IGetIGs } from '../interfaces/get-IGs.interface';
import { getIGsAdapter } from '../adapters/get-IGs-adapter';

export const useGetIG = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const [IGsList, setIGsList] = useState<{ _id: string; value: string }[]>([]);

	const {
		execute: executeGetIGs,
		status: statusGetIGs,
		value: valueGetIGs,
	} = useApiRequest<unknown, IResponsePayload<IGetIGs>>({
		path: URLS.PROJECT.INDICATOR,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetIGs === 'success') {
			dispatch(stopLoading());
			setIGsList(getIGsAdapter(valueGetIGs?.data || []));
		} else if (statusGetIGs === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los criterios',
					severity: 'error',
				})
			);
		}
	}, [statusGetIGs]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetIGs(null, `?project=${idProject}&isGeneral=true`);
	}, []);

	return { IGsList, valueGetIGs };
};
