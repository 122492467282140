/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { uniqueId } from '../../../utils/uniqueId';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import { isEmpty } from 'lodash';

interface IGet {
	setIG: TReactDispatch<IStepTwoModel[]>;
	isSave: boolean;
}

const useUpdateIndicator = (props: IGet) => {
	const { setIG } = props;
	const dispatch = useDispatch<any>();
	const { execute, status, value } = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_INDICATORS,
		method: 'POST',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (value?.data && value.data.length > 0) {
				const _adapted: IStepTwoModel[] = value.data.map((i, _idx) => ({
					...i,
					isComplete: i.columns.every((i: any) => !isEmpty(i)),
					id: uniqueId(),
					_id: i._id,
				}));
				setIG(_adapted);
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, status };
};

export default useUpdateIndicator;
