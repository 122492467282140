import { ITeam } from '../../../../personal/components/create-teams/interfaces/team.interface';

export const getTeamsTable = (teams: ITeam[]) => {
	const tableInfo = teams.map((team) => {
		return {
			teamName: team.name,
			names: team.names || '',
		};
	});

	return tableInfo;
};
