/* eslint-disable react-hooks/exhaustive-deps */
import { ICreationPage } from '../../../../interfaces/creation-page-interfaces';
import { TReactDispatch } from '../../../../interfaces/utils';
import AsignacionServiciosStepTwo from './step-two.component';
import { estadoCreacion } from '../../../../constants/estado-creacion';
import { DefaultTabItems } from '../../constants/data.constants';
import { useEffect, useState } from 'react';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import CreationPage from '../../../../components/creation-page.component';
import useGetActivities from '../../hooks/useGetActivities';
import { IActivity } from '../../../../interfaces/project.interface';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import useUpdateProject from '../../../../hooks/useUpdateProject';
import AsignacionCriteriosDetalle from '../../asignación-criterio-detalle';
import { setLoading } from '../../../../store/modules/loading';

interface IProps {
	currentTab: number;
	setCurrentTab: TReactDispatch<number>;
}
const GeograficosServicios = (props: IProps) => {
	const { currentTab, setCurrentTab } = props;
	const [serviceFunctionalOptions, setServiceFunctionalOptions] = useState<
		IOptionsMenu[]
	>([]);
	const { step: projectStep, _id: _projectID } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [showAsignacionDetalles, setShowAsignacionDetalles] = useState(false);
	const [isGeograficosDone, setIsGeograficosDone] = useState<boolean | null>(null);
	const { value: valueActivities } = useGetActivities();
	const { handleUpdateProject } = useUpdateProject();
	const dispatch = useDispatch();

	useEffect(() => {
		if (projectStep === '3.2.1') {
			setShowAsignacionDetalles(true);
		} else if (projectStep === '3.3.0') {
			setShowAsignacionDetalles(false);
			setIsGeograficosDone(true);
			setCurrentTab(3);
		}
	}, [projectStep]);

	const GeograficosYServicios: ICreationPage = {
		header: {
			label: 'Estado de creación',
			stepper: {
				activeStep: 2,
				items: estadoCreacion,
				completed: { 0: true, 1: true },
			},
			tabs: {
				items: DefaultTabItems,
				value: currentTab,
				onTabChange: (_, index) => {
					setCurrentTab(index);
				},
			},
		},
		stepTwo: {
			title: '1. Relación Actividades - Espacios',
			show: true,
			alertLabel:
				'A continuación aparecerán en el selector las actividades previamente creadas. Selecciona los espacios tipo nivel 1 en los que deba ser supervisada la actividad que se muestra en el selector. A pesar de que la actividad no deba ser supervisada en todos los sub-espacios, si aplica para la mayoría seleccione el nivel 1. En una pantalla posterior podrás hacer modificaciones de mayor precisión.',
			Children: (
				<AsignacionServiciosStepTwo
					actividadesOptions={serviceFunctionalOptions}
					isGeograficosDone={isGeograficosDone}
				/>
			),
			showExitButon: false,
		},
	};

	useEffect(() => {
		if (!valueActivities?.data || valueActivities.data.length < 1) return;
		const _serviceFunctionalOptions: IOptionsMenu[] = [];

		valueActivities.data.forEach((i: IActivity) => {
			const data = {
				id: i._id || '',
				label: i.columns[0],
				value: i._id || '',
			};
			_serviceFunctionalOptions.push({
				...data,
				status: i.isFunctionalAssociated || false,
			});
		});
		setServiceFunctionalOptions(_serviceFunctionalOptions);
	}, [valueActivities]);

	return (
		<div>
			{showAsignacionDetalles && (
				<CreationPage
					header={{
						label: 'Estado de creación',
						stepper: {
							activeStep: 2,
							items: estadoCreacion,
							completed: { 0: true, 1: true },
						},
						tabs: {
							items: DefaultTabItems,
							value: currentTab,
							onTabChange: (_, index) => {
								setCurrentTab(index);
							},
						},
					}}
					stepTwo={{
						showExitButon: false,
						title: '1. Relación Actividades - Criterios - Espacios (Detallado)',
						alertLabel: `A continuación aparecerán selectores correspondientes a los
							niveles de espacios previamente creados. Para cada combinación
							de niveles aparecerán las actividades a supervisar asociadas
							así como los indicadores de dicha actividad. Selecciona o
							deselecciona los indicadores según apliquen.`,
						show: true,
						Children: (
							<AsignacionCriteriosDetalle
								onContinue={() => {
									dispatch(setLoading({ loading: true }));
									handleUpdateProject(
										{ step: '3.3.0' },
										_projectID || ''
									);
								}}
							/>
						),
					}}
				/>
			)}
			{!showAsignacionDetalles && <CreationPage {...GeograficosYServicios} />}
		</div>
	);
};

export default GeograficosServicios;
