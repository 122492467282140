import { useState } from 'react';
import { IVerticalStepperAttributes } from '../../../../interfaces/vertical.steper.attributes-fixed.interface';
import StepOneComponent, {
	TFunctionHandler,
} from '../../../../components/step-one-fixed';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';

const especificoCriterio: IVerticalStepperAttributes[] = [
	{
		index: 0,
		name: 'Criterio',
		placeholder: 'Nombre del criterio',
		value: '',
		isComplete: false,
	},
	{
		index: 1,
		name: 'Atributo Principal',
		placeholder: 'Nombre del atributo',
		value: '',
		isComplete: false,
	},
];

interface IStep {
	onContinue?: (data: IStepTwoModel[]) => void;
	defaultData: IVerticalStepperAttributes[] | null;
	disableNext: boolean | null;
	resetDone?: null | true;
	isDefaultDone?: null | true;
	refComponent: React.RefObject<TFunctionHandler>;
}

function StepOneContent(props: IStep) {
	const {
		onContinue,
		defaultData,
		disableNext,
		resetDone,
		isDefaultDone,
		refComponent,
	} = props;
	const [criteriosEspecificos, setCriteriosEspecificos] =
		useState<IVerticalStepperAttributes[]>(especificoCriterio);
	return (
		<StepOneComponent
			ref={refComponent}
			data={criteriosEspecificos}
			setData={setCriteriosEspecificos}
			defaultData={defaultData}
			onContinue={onContinue}
			disableNext={disableNext}
			resetDone={resetDone}
			isDefaultDone={isDefaultDone}
		/>
	);
}

export default StepOneContent;
