/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useState, useMemo } from 'react';
import theme from '../../../../../../config/theme';
import { getInputsRowPersonal } from '../adapters/get-inputs-row-personal';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { DefaultModal, IModal } from '../../../../../../interfaces/modal.interface';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

interface IUseItemCreatePersonal {
	item: IAdaptedValues;
	personalData: IAdaptedValues[];
	isEdit: boolean;
	setPersonalData: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
}

export const useItemCreatePersonal = ({
	item,
	personalData,
	isEdit,
	setPersonalData,
}: IUseItemCreatePersonal) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [value, setValue] = useState(getInputsRowPersonal(item));
	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);

	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const disabledButton = useMemo((): boolean => {
		if (Object.values(value).includes(undefined || '')) return true;
		return false;
	}, [value]);

	const handleValue = (
		newValue: string,
		name: string,
		column: number,
		element: { key: string; value: string },
		idUser: string
	) => {
		if (isEdit && name === 'Active' && newValue === '0') {
			setModal({
				...modal,
				open: true,
				callback: () => {
					navigate('desactivar-usuario', {
						state: {
							idUser: idUser,
							newStatus: 0,
							isDeleted: true,
							idProject: idProject,
						},
					});
				},
			});
			return;
		}
		setValue({
			...value,
			[name]: newValue || '',
		});
		setPersonalData(
			personalData.map((personal, i) => {
				if (i === column) {
					return {
						...personal,
						rows: personal.rows.map((row) => {
							if (row.key === element.key) {
								return {
									...row,
									value: newValue || '',
								};
							} else return row;
						}),
					};
				} else return personal;
			})
		);
	};

	return { modal, value, matches, disabledButton, handleValue, setModal };
};
