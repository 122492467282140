import { Paper, Box, Grid } from '@mui/material';
import { Button } from '../../../../../components/main-button.component';
import VerticalStepper from '../../../../../components/vertical-stepper-fixed.component';
import { useAssginPersonal } from './hooks/useAssignPersonal';
import Alert from '../../../../../components/info.alert.component';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';
import { ItemCreatePersonal } from './item-create-personal.component';
import DashedBtn from '../../../../../components/dashed-button.component';
import { HeaderEdit } from '../../../../../components/header-edit.component';
import PersonalIcon from '../../../../../assets/icons/personal.icon';
import { LABELS_ASSIGN_PERSONAL } from './constants/labels';

interface IAssignPersonal {
	role: IAdaptedValues;
	isEdit: boolean;
	isAutomaticCharge: boolean;
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const AssignPersonal = ({
	role,
	isEdit,
	isAutomaticCharge,
	setStep,
}: IAssignPersonal) => {
	const {
		usersOptions,
		personalData,
		backButton,
		handleNewPersonal,
		saveNewPersonal,
		deletePersonal,
		setPersonalData,
		matches,
	} = useAssginPersonal({
		role,
	});

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					{!isEdit && !isAutomaticCharge ? (
						<>
							<Grid item xs={12}>
								<h4
									style={{
										margin: 0,
										padding: '0.5rem 0 0 0.5rem',
										fontWeight: 600,
									}}
								>
									{LABELS_ASSIGN_PERSONAL.TITLE}
								</h4>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="info" sx={{ marginTop: '1rem' }}>
									{LABELS_ASSIGN_PERSONAL.ALERT}
								</Alert>
							</Grid>
						</>
					) : (
						<HeaderEdit
							section="Personal"
							title={LABELS_ASSIGN_PERSONAL.TITLE}
							alertText={LABELS_ASSIGN_PERSONAL.ALERT}
							icon={<PersonalIcon size="23px" />}
						/>
					)}
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							rootStyle={{ overflowX: 'scroll', width: '100%' }}
							items={personalData}
							renderItem={(item, column) => (
								<ItemCreatePersonal
									item={item}
									rolName={role.description}
									column={column}
									usersOptions={usersOptions}
									onEdit={() => null}
									onSave={saveNewPersonal}
									onDelete={deletePersonal}
									setPersonalData={setPersonalData}
									personalData={personalData}
									isEdit={isEdit}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<DashedBtn
							id="add-personal"
							onClick={handleNewPersonal}
							width="100"
							text={`+ Añadir miembro a ${role.description}`}
							disabled={!personalData.every((e) => e.id)}
						/>
					</Grid>
					<Grid item>
						<Button
							id="btn-back"
							label="Regresar"
							onClick={() => setStep(backButton ? 0 : 1)}
							variant="outlined"
							style={{
								color: ' var(--color-error-main)',
								width: '200px',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
