/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { IGetSupervisionUnit } from '../../interfaces/get-supervision-unit.interface';
import { getCheckedListRelations } from '../../adapters/get-relations.adapter';

export const useGetUARelations = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const [idsFunctionals, setIdsFunctionals] = useState<Set<string>>(new Set<string>());

	const {
		execute: getUARelations,
		status: statusGetUARelations,
		value: valueGetUARelations,
		setValue,
	} = useApiRequest<unknown, IResponsePayload<IGetSupervisionUnit>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetUARelations === 'success') {
			dispatch(stopLoading());
			setIdsFunctionals(getCheckedListRelations(valueGetUARelations?.data || []));
		} else if (statusGetUARelations === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusGetUARelations]);

	const handleGetUARelations = (idActivity: string) => {
		dispatch(setLoading({ loading: true }));
		getUARelations(null, `?project=${idProject}&activity=${idActivity}&type=U-A`);
	};

	return {
		idsFunctionals,
		valueGetUARelations,
		handleGetUARelations,
		setIdsFunctionals,
		setValue,
	};
};
