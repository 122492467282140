import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { IGetRoles } from '../interfaces/get-roles.interface';

export const getRolesAdaptedData = (data: IGetRoles[]) => {
	if (data.length === 0) {
		return [
			{
				id: '',
				description: '',
				rows: [
					{
						key: '1',
						value: '',
					},
				],
				isComplete: false,
				mainAttribute: 'Rol',
			},
		] as IAdaptedValues[];
	}

	const newOptions = data.map((element) => {
		return {
			id: element._id || '',
			description: element.name,
			rows: element.attributes.map((attribute, index) => {
				return {
					key: attribute,
					value: '',
				};
			}),
			isComplete: true,
			mainAttribute: element.mainAttribute,
			attributes: element.attributes,
		};
	});
	return newOptions as IAdaptedValues[];
};

export const getRolesInputsAdapter = (data?: IGetRoles[]): { [key: string]: string } => {
	const myObject: { [key: string]: string } = {};
	if (!data || data.length === 0) return { 0: '' };
	for (let i = 0; i < data.length; i++) {
		myObject[i] = data[i].name;
	}
	return myObject;
};
