/* eslint-disable react-hooks/exhaustive-deps */
import {
	ArrowBack,
	CalendarMonthOutlined,
	MyLocationOutlined,
	PortraitRounded,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useMemo, useState, useEffect, useContext, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../../components/main-button.component';
import InputSelect from '../../../../../components/select-input.component';
import { setValuePP } from '../../../../../store/modules/profile-project';
import { NewReportKeys } from '../interfaces/report.interface';
import { typeOptions } from '../utils/options.utils';
import styles from './styles/generate-report-styles.module.css';
import { useGetDataFilters } from '../hooks/useGetDataFilters';
import { useGenerateReports } from '../hooks/useGenerateReports';
import { setLoading } from '../../../../../store/modules/loading';
import { datePickers } from '../utils/columns.utils';
import TextInput from '../../../../../components/text-input.component';
import { parseISO, formatISO } from 'date-fns';
import { SuccessfulReport } from './successful-report.component';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';

export const GenerateReport = () => {
	const dispatch = useDispatch();
	const { data: dataProject } = useContext(ProfileProjectContext);
	const { urlReport, executeGenerateReport } = useGenerateReports();
	const [data, setData] = useState({
		tipoReporte: '',
		unidadFuncional: '',
		espacioFuncional: '',
		actividad: '',
		servicio: '',
		date1: new Date().toISOString(),
		date2: new Date().toISOString(),
		selectAllNivel1: false,
		selectAllNivel2: false,
		selectAllActivitie: false,
	});

	const {
		units,
		fuctionals,
		activities,
		executeGetFuctionals,
		executeGetFuctionals2,
		executeGetActivities,
	} = useGetDataFilters();

	useEffect(() => {
		executeGetFuctionals({}, `?project=${dataProject._id}&isFunctionalParent=1`);
		executeGetActivities({}, `?project=${dataProject._id}`);
	}, []);

	const handleChange = (e: any, property: NewReportKeys) => {
		(data[property] as any) = e.target.value;

		if (property === 'unidadFuncional')
			executeGetFuctionals2(
				{},
				`?project=${dataProject._id}&isFunctionalParent=${e.target.value}`
			);

		if (property === 'selectAllNivel1') {
			data.selectAllNivel1 = e.target.checked;
			if (e.target.checked) data.unidadFuncional = '';
		}
		if (property === 'selectAllNivel2') {
			data.selectAllNivel2 = e.target.checked;
			if (e.target.checked) data.espacioFuncional = '';
		}

		if (property === 'selectAllActivitie') {
			data.selectAllActivitie = e.target.checked;
			if (e.target.checked) data.actividad = '';
		}

		setData({ ...data });
	};

	const getValue = (property: NewReportKeys): string => {
		return (data[property] || '').toString();
	};

	const disabledButton = useMemo((): boolean => {
		if (data.tipoReporte !== '' && data.date1 !== '' && data.date2 !== '')
			if (data.unidadFuncional !== '' || data.selectAllNivel1 === true)
				if (data.espacioFuncional !== '' || data.selectAllNivel2 === true)
					if (data.actividad !== '' || data.selectAllActivitie === true)
						return false;
		return true;
	}, [
		data.tipoReporte,
		data.date1,
		data.date2,
		data.unidadFuncional,
		data.selectAllNivel1,
		data.espacioFuncional,
		data.selectAllNivel2,
		data.actividad,
		data.selectAllActivitie,
	]);

	const onGenerate = () => {
		dispatch(setLoading({ loading: true }));
		const startDate = new Date(data.date1).toDateString();
		const endDate = new Date(data.date2).toDateString();
		const dataReport = {
			reportFormat: 'excel',
			reportType: data.tipoReporte,
			project: dataProject._id,
			functional:
				data.espacioFuncional !== ''
					? data.espacioFuncional
					: data.unidadFuncional !== ''
					? data.unidadFuncional
					: undefined,
			activity: data.selectAllActivitie ? undefined : data.actividad,
			executionDateFrom: formatISO(new Date(startDate)),
			executionDateTo: formatISO(new Date(endDate)),
		};
		executeGenerateReport(dataReport);
	};

	return (
		<>
			{urlReport === '' ? (
				<>
					<div
						onClick={() => dispatch(setValuePP({ stepReporteo: 0 }))}
						style={{
							display: 'flex',
							gap: '5px',
							padding: '10px',
							cursor: 'pointer',
							width: 'max-content',
							color: 'var(--color-highlight-blue)',
						}}
					>
						<ArrowBack />
						<span>Regresar</span>
					</div>
					<Grid
						container
						padding="10px"
						columnSpacing={4}
						rowSpacing={3}
						alignItems="center"
					>
						<Grid item xs={12}>
							<span style={{ fontSize: '18px', fontWeight: 'bold' }}>
								Reporteo
							</span>
							<br />
							<span
								style={{
									fontSize: '17px',
									color: '#A1A5B6',
								}}
							>
								Para adquirir un reporte siga los siguientes pasos:
							</span>
						</Grid>
						<Grid item xs={12} md={5}>
							<div className={styles.selectsContainer}>
								<span style={{ textAlign: 'center' }}>
									Tipo de reporte
								</span>
								<InputSelect
									id="tipoReporte"
									value={data.tipoReporte}
									placeholder="Tipo de reporte"
									onChange={(e) => handleChange(e, 'tipoReporte')}
									optionsMenu={typeOptions}
									height="40px"
								/>
							</div>
						</Grid>
						<Grid item>
							<MyLocationOutlined
								className={styles.selectsIcon}
								style={{
									position: 'relative',
									left: '15px',
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<span
								style={{
									position: 'relative',
									top: '-15px',
								}}
							>
								Seleccionar todos
								<input
									type="checkbox"
									id="selectAllNivel1"
									onChange={(e) => handleChange(e, 'selectAllNivel1')}
								/>
							</span>
							<span
								style={{
									position: 'relative',
									top: '-13px',
								}}
							>
								<InputSelect
									id="unidadFuncional"
									value={data.unidadFuncional}
									placeholder={dataProject?.functionalParentBase?.name}
									onChange={(e) => handleChange(e, 'unidadFuncional')}
									optionsMenu={units}
									height="40px"
									isDisabled={data.selectAllNivel1}
								/>
							</span>
						</Grid>

						<Grid item xs={12} md={3}>
							<span
								style={{
									position: 'relative',
									top: '-15px',
								}}
							>
								Seleccionar todos
								<input
									type="checkbox"
									id="selectAllNivel2"
									onChange={(e) => handleChange(e, 'selectAllNivel2')}
								/>
							</span>
							<span
								style={{
									position: 'relative',
									top: '-13px',
								}}
							>
								<InputSelect
									id="espacioFuncional"
									value={data.espacioFuncional}
									placeholder={dataProject?.functionalBase?.name}
									onChange={(e) => handleChange(e, 'espacioFuncional')}
									optionsMenu={fuctionals}
									height="40px"
									isDisabled={data.selectAllNivel2}
								/>
							</span>
						</Grid>
						<Grid item>
							<PortraitRounded
								className={styles.selectsIcon}
								style={{
									position: 'relative',
									bottom: '-6px',
									left: '20px',
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<span
								style={{
									position: 'relative',
									top: '-13px',
								}}
							>
								Seleccionar todos
								<input
									type="checkbox"
									id="selectAllActivitie"
									onChange={(e) =>
										handleChange(e, 'selectAllActivitie')
									}
								/>
							</span>
							<span
								style={{
									position: 'relative',
									top: '-10px',
								}}
							>
								<InputSelect
									id="actividad"
									value={data.actividad}
									placeholder="Actividades"
									onChange={(e) => handleChange(e, 'actividad')}
									optionsMenu={activities}
									height="40px"
									isDisabled={data.selectAllActivitie}
								/>
							</span>
						</Grid>
						<Grid item xs={12} md={6}>
							<div className={styles.selectsContainer}>
								<CalendarMonthOutlined className={styles.selectsIcon} />
								{(datePickers || []).map((e: any) => (
									<Fragment key={e.id}>
										<Grid component="div" item xs={12}>
											<TextInput
												key={e.id}
												id={e.id}
												data-testid={e.id}
												name={e.name}
												label={e.label}
												placeholder={e.placeholder}
												value={parseISO(getValue(e.name))}
												type={e.type}
												onChange={(newValue: any) => {
													try {
														handleChange(
															{
																target: {
																	name: e.name,
																	value: newValue.toISOString(),
																},
															} as unknown as React.ChangeEvent<HTMLInputElement>,
															e.name
														);
													} catch (error) {
														// eslint-disable-next-line no-console
														console.log(error);
													}
												}}
												which="datePicker"
												sx={{
													backgroundColor: '#F7F7F7',
												}}
												inputFormat={e.inputFormat}
											/>
										</Grid>
									</Fragment>
								))}
							</div>
						</Grid>
						<Grid item alignSelf="flex-end" xs={12} md={2}>
							<Button
								id="generar"
								label="Generar"
								onClick={() => onGenerate()}
								style={{
									backgroundColor: '#0080F9',
									position: 'relative',
									top: '-10px',
								}}
								disabled={disabledButton}
							/>
						</Grid>
					</Grid>
				</>
			) : (
				<SuccessfulReport url={urlReport} type={data.tipoReporte} />
			)}
		</>
	);
};
