/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { RootState } from '../../../store';

const useGetActivities = () => {
	const dispatch = useDispatch();
	const _id = useSelector((root: RootState) => root.projectBase._id);
	const { execute, status, value } = useApiRequest<
		any,
		IResponsePayload<IStepTwoModel>
	>({
		path: URLS.PROJECT.ACTIVITY,
		method: 'GET',
	});

	useEffect(() => {
		execute(null, `?project=${_id}`);
	}, []);

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, value };
};

export default useGetActivities;
