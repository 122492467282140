/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import { useEffect, useContext, useState } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { useTableCustom } from '../../../../hooks/useTableCustom';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../hooks/useApiRequest';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import { setAlert } from '../../../../store/modules/snackbar';
import { useLocation } from 'react-router-dom';
import { IButtonsAction } from '../../../../components/table-custom.component';
import { getFilesEvidencesAdapter } from '../adapters/files.adapter';
import { IFindings } from '../interfaces/new-incident.interface';
import { STATUS_SUPERVISION_TYPES } from '../../../../constants/status-supervision';
import { RootState } from '../../../../store';

interface IUseGetEvidences {
	currentState?: string;
	isSupervisionRoutine: boolean;
}

export function useGetEvidences({
	currentState,
	isSupervisionRoutine,
}: IUseGetEvidences) {
	const dispatch = useDispatch();
	const location = useLocation();
	const { data, setData, pagination, rowsSelect, rowsPerPage, filters } =
		useTableCustom<Object>();
	const {
		idRoutine,
		idProject,
		setStatus,
		supervisionRoutineId,
		setNewState,
		setWithStatus,
		setEditFinding,
	} = useContext(SupervisionProjectContext);
	const idRoutineParams = location?.state?.routine as string;
	const idProjectParams = location?.state?.idProject as string;
	const idRoutineHistory = location?.state?.params?.routine as string;
	const idProyectHistory = location?.state?.params?.project?._id as string;
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const [buttons] = useState<IButtonsAction[]>(
		isSupervisionRoutine &&
			currentState !== STATUS_SUPERVISION_TYPES.NOT_ACCOMPLISHED &&
			currentState !== STATUS_SUPERVISION_TYPES.SUSPENDED
			? [
					{
						icon: Add,
						label: 'Agregar evidencia',
						id: 'agregar-evidencia',
						execute: () => {
							handleNewEvidences();
						},
						type: 'blue',
						width: 200,
					},
			  ]
			: []
	);

	const {
		execute: executeGetEvidences,
		status: statusGetEvidences,
		value: valueGetEvidences,
	} = useApiRequest<unknown, IResponsePayload<IFindings>>({
		path: URLS.SUPERVISION_ROUTINE.FILES,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		if (idRoutine && idProject && isSupervisionRoutine) {
			executeGetEvidences(null, `/by-supervision-routine/${supervisionRoutineId}`);
		} else if (idRoutineHistory !== undefined)
			executeGetEvidences(
				null,
				`?routine=${idRoutineHistory}&page=${pagination}&recordsByPage=${rowsPerPage}&project=${
					idProyectHistory || _id
				}`
			);
		else {
			executeGetEvidences(
				null,
				`?routine=${
					idRoutineParams ? idRoutineParams : idRoutine
				}&page=${pagination}&recordsByPage=${rowsPerPage}&project=${
					idProjectParams ? idProjectParams : idProject || _id
				}&advancedSearch=${filters?.advancedSearch || ''}`
			);
		}
	}, [pagination, rowsSelect, rowsPerPage, filters]);

	useEffect(() => {
		if (statusGetEvidences === 'success') {
			dispatch(stopLoading());
			setData({
				...data,
				countRows: valueGetEvidences?.totalRecords || 0,
				rows: getFilesEvidencesAdapter(valueGetEvidences?.data || []),
			});
		} else if (statusGetEvidences === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetEvidences]);

	const handleNewEvidences = () => {
		setEditFinding(null);
		setWithStatus(null);
		setStatus('pending');
		setNewState(4);
	};

	return {
		data,
		idRoutine,
		_id,
		idRoutineParams,
		idProject,
		idProjectParams,
		buttons,
		setData,
	};
}
