import { IReportsSH } from '../interfaces/tables.interface';

export const reportsSHAdapter = (
	data: IReportsSH[],
	downloadAction: (fileName: string) => void
): IReportsSH[] => {
	const newData: IReportsSH[] = data.map((report: IReportsSH) => {
		return {
			...report,
			name: (
				<div style={{ display: 'flex', gap: '40px', color: '#0080F9' }}>
					<span
						style={{ cursor: 'pointer' }}
						onClick={() => {
							downloadAction(report.fileNameKey);
						}}
					>
						{report.name}
					</span>
				</div>
			),
		};
	});
	return newData;
};
