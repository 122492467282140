import { IColumns } from '../../../interfaces/table.insterface';

export const columnsRoutineMonitoring: IColumns[] = [
	{
		text: 'Supervisión',
		nameID: 'routineDescription',
		width: '400px',
	},
	{
		text: 'Asignado a',
		nameID: 'assignedTo',
		width: '400px',
	},
	{
		text: '',
		nameID: 'acciones',
	},
];

export const optionsSupervisionHistory = [
	{
		id: 1,
		label: 'Por rutina de supervisión',
		value: 0,
	},
	{
		id: 2,
		label: 'Por espacio',
		value: 1,
	},
	{
		id: 3,
		label: 'Por servicio',
		value: 2,
	},
];

export const optionsMonthsSupervisionHistory = [
	{ id: 0, label: 'Selecciona', value: '' },
	{
		id: 1,
		label: 'Enero',
		value: '1',
	},
	{
		id: 2,
		label: 'Febrero',
		value: '2',
	},
	{
		id: 3,
		label: 'Marzo',
		value: '3',
	},
	{
		id: 4,
		label: 'Abril',
		value: '4',
	},
	{
		id: 5,
		label: 'Mayo',
		value: '5',
	},
	{
		id: 6,
		label: 'Junio',
		value: '6',
	},
	{
		id: 7,
		label: 'Julio',
		value: '7',
	},
	{
		id: 8,
		label: 'Agosto',
		value: '8',
	},
	{
		id: 9,
		label: 'Septiembre',
		value: '9',
	},
	{
		id: 10,
		label: 'Octubre',
		value: '10',
	},
	{
		id: 11,
		label: 'Noviembre',
		value: '11',
	},
	{
		id: 12,
		label: 'Diciembre',
		value: '12',
	},
];

export const optionsYearsSupervisionHistory = [
	{
		id: 1,
		label: '2023',
		value: '2023',
	},
	{
		id: 2,
		label: '2022',
		value: '2022',
	},
	{
		id: 3,
		label: '2021',
		value: '2021',
	},
];

export const optionsTypeSupervisionHistory = [
	{
		id: 1,
		label: 'Completada',
		value: 'true',
	},
	{
		id: 2,
		label: 'Pendiente',
		value: 'false',
	},
];
