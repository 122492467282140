import { Button } from '../../../components/main-button.component';
import { IColumns } from '../../../interfaces/table.insterface';
import { FactCheckOutlined } from '@mui/icons-material';

export const columnsSupervision: IColumns[] = [
	{
		text: 'Espacio funcional',
		nameID: 'espacioFuncional',
	},
	{
		text: 'Unidad funcional',
		nameID: 'unidadFuncional',
	},
	{
		text: 'Indicador',
		nameID: 'indicador',
	},
	{
		text: 'Frecuencia indicador',
		nameID: 'frecuenciaIndicador',
	},
	{
		text: 'Tipo de supervisión',
		nameID: 'tipoSupervision',
	},
	{
		text: 'Descripción',
		nameID: 'descripcion',
	},
	{
		text: (
			<Button
				label="Todos cumplen"
				icon={FactCheckOutlined}
				variant="outlined"
				onClick={() => null}
				style={{ color: 'var(--color-green-main)' }}
			/>
		),
		nameID: 'actions',
	},
];

export const columnsAreas: IColumns[] = [
	{
		text: 'Espacio funcional',
		nameID: 'espacioFuncional',
	},
	{
		text: 'Servicio',
		nameID: 'servicio',
	},
	{
		text: 'Indicador',
		nameID: 'indicador',
	},
	{
		text: 'Clave del servicio',
		nameID: 'claveServicio',
	},
	{
		text: 'Frecuencia indicador',
		nameID: 'frecuenciaIndicador',
	},
	{
		text: 'Tipo de supervisión',
		nameID: 'tipoSupervision',
	},
	{
		text: 'Descripción',
		nameID: 'descripcion',
	},
	{
		text: (
			<Button
				label="Todos cumplen"
				icon={FactCheckOutlined}
				variant="outlined"
				onClick={() => null}
				style={{ color: 'var(--color-green-main)' }}
			/>
		),
		nameID: 'actions',
	},
];

export const columnSupervision: IColumns[] = [
	{
		text: 'Espacio funcional',
		nameID: 'espacioFuncional',
		width: '100px',
	},
	{
		text: 'Unidad funcional',
		nameID: 'unidadFuncional',
	},
	{
		text: 'Indicador',
		nameID: 'indicador',
	},
	{
		text: 'Frecuencia indicador',
		nameID: 'frecuenciaIndicador',
	},
	{
		text: 'Tipo de supervisión',
		nameID: 'tipoSupervision',
	},
	{
		text: 'Descripción',
		nameID: 'descripcion',
	},
];

export const columnsActivities: IColumns[] = [
	{
		text: 'Espacios funcionales',
		nameID: 'espacioFuncionales',
	},
	{
		text: 'Actividades supervisadas',
		nameID: 'actividadesSupervisadas',
	},
];

export const columnsEvidences: IColumns[] = [
	{
		text: 'Evidencias',
		nameID: 'evidencias',
	},
	{
		text: 'Descripción',
		nameID: 'descripcion',
	},
	{
		text: 'Unidad funcional',
		nameID: 'unidadFuncional',
	},
	{
		text: 'Servicio',
		nameID: 'servicio',
	},
];
export const columnsSuspension: IColumns[] = [
	{
		text: 'Descripción',
		nameID: 'description',
		width: 700,
	},
	{
		text: '',
		nameID: 'acciones',
		width: 100,
	},
];
