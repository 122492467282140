import { Grid, FormControlLabel, styled } from '@mui/material';
import CheckboxMUI from '@mui/material/Checkbox';

interface IListIGs {
	matrixIGs: { _id: string; value: string }[][];
	checkedList: Set<string>;
	selectColumn: (column: number) => void;
	onSelect: (id: string, checked: boolean) => void;
}

const CheckBox = styled(CheckboxMUI)(({ theme }) => ({
	color: 'var(--color-highlight-blue)',
	'&.Mui-checked': {
		color: 'var(--color-highlight-blue)',
	},
}));

export const ListIGs = ({ matrixIGs, checkedList, selectColumn, onSelect }: IListIGs) => {
	return (
		<Grid container columnGap={4}>
			{matrixIGs.map((el, idx) => (
				<Grid
					item
					key={`form-${idx}`}
					display="flex"
					flexDirection="column"
					alignItems="center"
				>
					<span
						style={{ fontSize: '12px', color: '#0080F9', cursor: 'pointer' }}
						onClick={() => selectColumn(idx)}
					>
						Seleccionar Columna
					</span>
					{el.map((prop: any, row) => (
						<FormControlLabel
							sx={{
								fontSize: '27px',
								marginTop: '10px',
							}}
							key={`formControl-${row}-${idx}`}
							checked={checkedList.has(prop._id || '')}
							value={prop._id || ''}
							control={<CheckBox id={`checkbox-${row}-${idx}`} />}
							label={
								<p
									style={{
										fontSize: '24px',
										margin: 0,
									}}
								>
									{prop.value}
								</p>
							}
							onChange={(_, checked) => onSelect(prop._id || '', checked)}
						/>
					))}
				</Grid>
			))}
		</Grid>
	);
};
