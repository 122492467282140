import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Drawer, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
//Iconos
import {
	KeyboardArrowDown,
	KeyboardArrowRight,
	CloseOutlined,
	KeyboardDoubleArrowLeft,
	KeyboardDoubleArrowRight,
	FiberManualRecord,
} from '@mui/icons-material';
// Styles
import styles from './styles/drawer-layout.component.module.css';
// Assets
import logo from '../assets/svg/logo.svg';
// Components
import { getRouteList } from '../router/routes';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

interface IProps {
	open: boolean;
	toggleDrawer: () => void;
	drawerWidth: number;
	arrowToggle: boolean;
	setArrowToggle: (arg: boolean) => void;
	window?: () => Window;
}

const widthArrow: number = 80;

const DrawerLayout = ({
	open,
	toggleDrawer,
	drawerWidth,
	arrowToggle,
	setArrowToggle,
	window,
}: IProps) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const navigate = useNavigate();
	const container = window !== undefined ? () => window().document.body : undefined;
	const [arrowToggleMain, setArrowToggleMain] = useState<boolean>(false);
	const [selectMenu, setSelectMenu] = useState<number>(0);
	const location = useLocation();
	const { role } = useSelector((state: RootState) => state.users);

	const toogleMenu = (menu: number, redirect?: string) => {
		setSelectMenu(menu);
		navigate(redirect || '/inicio');
		toggleDrawer();
	};

	const toggleArrow = () => {
		setArrowToggle(!arrowToggle);
		setArrowToggleMain(!arrowToggleMain);
	};
	return (
		<Box
			component="nav"
			sx={{
				width: { md: arrowToggle && !matches ? widthArrow : drawerWidth },
				flexShrink: { md: 0 },
				transform: {
					width: { md: arrowToggle && !matches ? widthArrow : drawerWidth },
				},
				transition: (theme) =>
					theme.transitions.create('all', {
						duration: theme.transitions.duration.shortest,
					}),
			}}
			aria-label="mailbox folders"
			position={'relative'}
		>
			{!matches && (
				<Box className={styles.arrowMaximus} onClick={toggleArrow}>
					{arrowToggle ? (
						<KeyboardDoubleArrowRight />
					) : (
						<KeyboardDoubleArrowLeft />
					)}
				</Box>
			)}
			<Drawer
				onMouseEnter={() => arrowToggleMain && setArrowToggle(false)}
				onMouseLeave={() => arrowToggleMain && setArrowToggle(true)}
				container={matches ? container : null}
				variant={matches ? 'temporary' : 'permanent'}
				open={matches ? open : true}
				onClose={toggleDrawer}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					display: {
						xs: matches ? 'block' : 'none',
						md: matches ? 'none' : 'block',
					},
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: arrowToggle && !matches ? widthArrow : drawerWidth,
						background: '#1D1C27',
						color: '#FFFFFF',
						transform: {
							width: arrowToggle && !matches ? widthArrow : drawerWidth,
						},
						transition: (theme) =>
							theme.transitions.create('all', {
								duration: theme.transitions.duration.shortest,
							}),
					},
				}}
			>
				<Box padding={arrowToggle && !matches ? 2 : 2.5}>
					<Box
						className={styles.containerTitleDrawer}
						style={{ width: '100%', height: 80 }}
						display="flex"
						alignItems="center"
					>
						<Box
							display="flex"
							flexGrow={1}
							alignItems="center"
							justifyContent="center"
						>
							<img
								src={logo}
								alt="logo"
								style={{ width: 220, cursor: 'pointer' }}
								onClick={() => toogleMenu(0, '/inicio')}
							/>
						</Box>
						<IconButton
							color="inherit"
							aria-label="btnOpenDrawer"
							data-testid="btnOpenDrawer"
							edge="start"
							onClick={toggleDrawer}
							sx={{ display: { md: 'none' } }}
						>
							<CloseOutlined />
						</IconButton>
					</Box>
					<ul
						className={styles.menuDrawer}
						data-small={arrowToggle && !matches ? 'true' : 'false'}
					>
						{getRouteList(role)?.map(
							(menu, i) =>
								!menu.hideMenu && (
									<li key={i}>
										<div
											data-active={
												selectMenu === i ? 'true' : 'false'
											}
											style={
												arrowToggle
													? {
															display: 'flex',
															justifyContent: 'center',
															gap: '10px',
													  }
													: { display: 'flex', gap: '10px' }
											}
											onClick={() =>
												toogleMenu(
													i,
													!menu.submenu ? menu.link : undefined
												)
											}
										>
											{menu.icon}
											<span>{menu.title}</span>
											{arrowToggle && !matches
												? null
												: selectMenu === i
												? menu.submenu && <KeyboardArrowDown />
												: menu.submenu && <KeyboardArrowRight />}
										</div>
										{menu.submenu && (
											<ul
												data-active={
													selectMenu === i ? 'true' : 'false'
												}
												className={styles.submenuDrawer}
											>
												{menu.submenu.map(
													(submenu: any) =>
														!submenu.hideMenu && (
															<li
																key={
																	menu.link +
																	submenu.link
																}
																onClick={() =>
																	toogleMenu(
																		i,
																		menu.link +
																			submenu.link
																	)
																}
																data-active={
																	location.pathname ===
																	menu.link +
																		submenu.link
																}
															>
																<span
																	style={
																		arrowToggle
																			? {
																					fontSize:
																						'10px',
																			  }
																			: {
																					fontSize:
																						'12px',
																			  }
																	}
																>
																	<FiberManualRecord
																		style={{
																			fontSize: 7,
																			marginRight: 10,
																		}}
																	/>
																	{submenu.title}
																</span>
															</li>
														)
												)}
											</ul>
										)}
									</li>
								)
						)}
					</ul>
				</Box>
			</Drawer>
		</Box>
	);
};

export default DrawerLayout;
