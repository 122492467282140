/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';

import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { IValuesInfo } from '../../../../interfaces/edit-values.interface';
import { getSingleAdaptedData } from '../../../../utils/get-adapted-data';

interface IUseUpdateActivity {
	setCurrentActivity: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
}

export const useUpdateActivity = ({ setCurrentActivity }: IUseUpdateActivity) => {
	const dispatch = useDispatch();

	const {
		execute: updateActivity,
		status: statusUpdateActivity,
		value,
	} = useApiRequest<{ columns: string[] }, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.ACTIVITIES.GET_ACTIVITIES,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusUpdateActivity === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Información actualizada con éxito',
					severity: 'success',
				})
			);
			setCurrentActivity(getSingleAdaptedData(value?.detail));
			dispatch(stopLoading());
		} else if (statusUpdateActivity === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateActivity]);

	const handleUpdateActivity = (idActivity: string, body: { columns: string[] }) => {
		dispatch(setLoading({ loading: true }));
		updateActivity(body, `/${idActivity}`);
	};

	return { handleUpdateActivity };
};
