/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import theme from '../../../../../config/theme';
import { useGetActivities } from './useGetActivities';
import { setLoading } from '../../../../../store/modules/loading';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import {
	ICollapsable,
	IItem as ICollapsableItem,
} from '../../../../../interfaces/collapsable.interface';
import { useGetUnits } from './useGetUnits';
import { isEmpty } from 'lodash';
import { useSaveUnits } from './useSaveUnits';
import { useChangeStep } from '../../../hooks/useChangeStep';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';

export function useStepTwo() {
	const { idProject, supervisionExtraordinaryData } = useContext(
		CreateSupervisionContext
	);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [currentService, setCurrentService] = useState('');
	const [currentFunctionalUnit, setCurrentFunctionalUnit] = useState('');
	const [criteriosTable, setCriteriosTable] = useState<ICollapsable[]>([]);
	const [elementsChecked, setElementsChecked] = useState<Set<string>>(new Set());
	const [optionsChanged, setOptionsChanged] = useState<number>(0);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { activitiesOptions, getActivities } = useGetActivities({
		getOptions: true,
	});
	const { getUnits } = useGetUnits({
		units: criteriosTable,
		setUnits: setCriteriosTable,
		isLevelOne: true,
		checkedElements: elementsChecked,
		setCheckedElements: setElementsChecked,
		setCurrentUnit: setCurrentFunctionalUnit,
	});
	const { getUnits: getUnitsN2 } = useGetUnits({
		units: criteriosTable,
		setUnits: setCriteriosTable,
		isLevelOne: false,
		checkedElements: elementsChecked,
		setCheckedElements: setElementsChecked,
	});

	const handleNewOptions = () => {
		setOptionsChanged(optionsChanged + 1);
	};

	const { executeSavUnits } = useSaveUnits({
		setOptionsChanged: handleNewOptions,
	});
	const { changeStepSE } = useChangeStep();

	const handleCheck = (row: number, UF_ID: string, EF_ID: string, check: boolean) => {
		const collection = new Set(elementsChecked);
		if (check) collection.add(EF_ID);
		else collection.delete(EF_ID);

		if (criteriosTable[row].items.some((i) => collection.has(i.id))) {
			collection.add(UF_ID); //ADD THE FUNCTIONAL UNIT
		} else collection.delete(UF_ID); //DELETE THE FUNCTIONAL UNIT
		setElementsChecked(collection);
	};

	const handleCheckColumn = (id: string, items: ICollapsableItem[], check: boolean) => {
		const collection = new Set(elementsChecked);
		if (check) collection.add(id);
		else collection.delete(String(id));
		items.forEach((item) => {
			if (check) collection.add(item.id);
			else collection.delete(item.id);
		});
		setElementsChecked(collection);
	};

	const handleOnSave = () => {
		const adaptUnits = criteriosTable[0]?.items?.map((e) => {
			return {
				_id: e?._id || '',
				applies: elementsChecked.has(e?.id),
			};
		});
		adaptUnits.unshift({
			_id: criteriosTable[0]?._id || '',
			applies: elementsChecked.has(criteriosTable[0].id),
		});
		executeSavUnits(
			adaptUnits,
			activitiesOptions.find((e) => e.value === currentService)?.id.toString() || ''
		);
	};

	const handleOnContinue = () => {
		dispatch(setLoading({ loading: true }));
		changeStepSE(
			{
				newStep: STEPS_SUPERVISION_EXTRAORDINARY.CRT_1,
				extraordinarySupervision:
					supervisionExtraordinaryData.idSupervisionExtraordinary,
			},
			`/${idProject}`
		);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getActivities(
			{},
			`?project=${idProject}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&activity=null&applies=true`
		);
	}, [optionsChanged]);

	useEffect(() => {
		if (!isEmpty(currentService)) {
			dispatch(setLoading({ loading: true }));
			setElementsChecked(new Set());
			setCurrentFunctionalUnit('');
			getUnits(
				{},
				`?project=${idProject}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&functionalParent=null&activity=${currentService}&type=U-A`
			);
		}
	}, [currentService]);

	useEffect(() => {
		if (!isEmpty(currentFunctionalUnit)) {
			dispatch(setLoading({ loading: true }));
			getUnitsN2(
				{},
				`?project=${idProject}&extraordinarySupervision=${supervisionExtraordinaryData.idSupervisionExtraordinary}&functionalParent=${currentFunctionalUnit}&activity=${currentService}&type=U-A`
			);
		}
	}, [criteriosTable[0]?._id, currentFunctionalUnit]);

	return {
		criteriosTable,
		matches,
		activitiesOptions,
		currentService,
		elementsChecked,
		navigate,
		handleCheck,
		handleCheckColumn,
		setCurrentService,
		handleOnSave,
		handleOnContinue,
	};
}
