/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../config/theme';
import { getInputs } from '../../../../utils/get-inputs';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { useUpdateIndicator } from './useUpdateIndicator';

interface IUseEditIndicatorView {
	setCurrentIndicator: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	currentIndicator: IAdaptedValues;
}

export const useEditIndicatorView = ({
	currentIndicator,
	setCurrentIndicator,
}: IUseEditIndicatorView) => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [inputs, setInputs] = useState(getInputs(currentIndicator));

	const matches = useMediaQuery(theme.breakpoints.down('md'));
	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	const { handleUpdateIndicator } = useUpdateIndicator({ setCurrentIndicator });

	const onChangeValue = (name: string, value: string) => {
		setInputs(
			inputs.map((element: any) => {
				if (name === element.id)
					return {
						...element,
						value: value,
					};
				return element;
			})
		);
	};

	const updateIndicator = () => {
		const values = inputs.map((element: any) => {
			return element.value;
		});
		handleUpdateIndicator(currentIndicator.id, { columns: values });
	};

	return {
		matches,
		inputs,
		onChangeValue,
		updateIndicator,
	};
};
