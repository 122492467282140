/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { URLS } from '../../../../../constants/urls.constants';
import { stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { areaSelectAdapter } from '../adapters/area-select-adapter';

export function useAreaDetail(handleView: any) {
	const { data: dataAreaDetail, setData: setAreaDetail } = useTableCustom<Object>();
	const dispatch = useDispatch();

	const {
		execute: executeAreaDetail,
		status: statusAreaDetail,
		value: valueAreaDetail,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.SUPERVISION_HISTORY.HISTORY_FUNCTIONALS_BY_FUNCTIONALS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusAreaDetail === 'success') {
			dispatch(stopLoading());
			setAreaDetail({
				...dataAreaDetail,
				countRows: valueAreaDetail?.totalRecords || 0,
				rows: areaSelectAdapter(valueAreaDetail?.data || [], handleView),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusAreaDetail === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusAreaDetail]);

	return {
		dataAreaDetail,
		setAreaDetail,
		executeAreaDetail,
	};
}
