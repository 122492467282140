/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { useDispatch } from 'react-redux';
import { useGetListRoutine } from '../../hooks/useGetRoutineFunctionals';
import { setAlert } from '../../../../store/modules/snackbar';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import {
	getActivitiesAdapter,
	getFunctionalsAdapter,
} from '../adapters/functionals.adapter';
import { isEmpty } from '../../../../utils/validators';

export function useGetFunctionals() {
	const dispatch = useDispatch();
	const [withTwoLevels, setWithTwoLevels] = useState<boolean>(false);

	const [functionalsLvl1, setFunctionalsLvl1] = useState<IOptionsMenu[]>([]);
	const [functionalsLvl2, setFunctionalsLvl2] = useState<IOptionsMenu[]>([]);
	const [activitiesList, setActivitiesList] = useState<IOptionsMenu[]>([]);
	const [labelService, setLabelService] = useState<string>('');

	const { idProject, idRoutine, mainSelect, subSelect } = useContext(
		SupervisionProjectContext
	);

	const {
		getListRoutine: getFunctionalsLvl1,
		statusListRoutine: statusFunctionalLvl1,
		valueListRoutine: valueFunctionalLvl1,
	} = useGetListRoutine();
	const {
		getListRoutine: getFunctionalsLvl2,
		statusListRoutine: statusFunctionalLvl2,
		valueListRoutine: valueFunctionalLvl2,
	} = useGetListRoutine();
	const {
		getListRoutine: getActivities,
		statusListRoutine: statusActivities,
		valueListRoutine: valueActivities,
	} = useGetListRoutine();

	useEffect(() => {
		getFunctionals();
	}, []);

	useEffect(() => {
		if (statusFunctionalLvl1 === 'success') {
			dispatch(stopLoading());
			setFunctionalsLvl1(getFunctionalsAdapter(valueFunctionalLvl1?.data || []));
			if ((valueFunctionalLvl1?.data[0]?.functionalChildren || 0) > 0) {
				setWithTwoLevels(true);
			} else if (
				(valueFunctionalLvl1?.data[0]?.functionalChildren || 0) === 0 &&
				mainSelect.optionLabel === 'espacio'
			) {
				getActivities(
					null,
					`/activities/by-functional?project=${idProject}&routine=${idRoutine}&functional=${mainSelect.valueLabel}`
				);
			}
		} else if (statusFunctionalLvl1 === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusFunctionalLvl1]);

	useEffect(() => {
		if (
			!isEmpty(subSelect.functionalN1) &&
			withTwoLevels &&
			mainSelect.optionLabel === 'servicio'
		) {
			dispatch(setLoading({ loading: true }));
			getFunctionalsLvl2(
				null,
				`/functionals/by-activity?project=${idProject}&routine=${idRoutine}&activity=${mainSelect.valueLabel}&functionalParent=${subSelect.functionalN1}`
			);
		} else if (
			!withTwoLevels &&
			mainSelect.optionLabel === 'espacio' &&
			!isEmpty(subSelect.functionalN1)
		) {
			dispatch(setLoading({ loading: true }));
			getActivities(
				null,
				`/activities/by-functional?project=${idProject}&routine=${idRoutine}&functional=${mainSelect.valueLabel}`
			);
		}
	}, [subSelect.functionalN1]);

	useEffect(() => {
		if (statusFunctionalLvl2 === 'success') {
			dispatch(stopLoading());
			setFunctionalsLvl2(getFunctionalsAdapter(valueFunctionalLvl2?.data || []));
			if ((valueFunctionalLvl2?.data?.length || 0) > 0) {
				setWithTwoLevels(true);
				if (
					!isEmpty(subSelect.functionalN2) &&
					mainSelect.optionLabel === 'espacio'
				) {
					getActivities(
						null,
						`/activities/by-functional?project=${idProject}&routine=${idRoutine}&functional=${subSelect.functionalN2}`
					);
				}
			} else if (mainSelect.optionLabel === 'espacio') {
				getActivities(
					null,
					`/activities/by-functional?project=${idProject}&routine=${idRoutine}&functional=${mainSelect.valueLabel}`
				);
			}
		} else if (statusFunctionalLvl2 === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusFunctionalLvl2]);

	useEffect(() => {
		if (!isEmpty(subSelect.functionalN2) && mainSelect.optionLabel === 'espacio') {
			dispatch(setLoading({ loading: true }));
			getActivities(
				null,
				`/activities/by-functional?project=${idProject}&routine=${idRoutine}&functional=${subSelect.functionalN2}`
			);
		}
	}, [subSelect.functionalN2]);

	useEffect(() => {
		if (statusActivities === 'success') {
			dispatch(stopLoading());
			setActivitiesList(getActivitiesAdapter(valueActivities?.data || []));
			setLabelService(valueActivities?.data[0]?.activityName || '');
		} else if (statusActivities === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusActivities]);

	const getFunctionals = () => {
		dispatch(setLoading({ loading: true }));
		if (mainSelect.optionLabel === 'servicio') {
			// Solicitar ambos niveles
			getFunctionalsLvl1(
				null,
				`/functionals/by-activity?project=${idProject}&routine=${idRoutine}&activity=${mainSelect.valueLabel}&functionalParent=null`
			);
			if (!isEmpty(subSelect.functionalN2) && !isEmpty(subSelect.functionalN1)) {
				getFunctionalsLvl2(
					null,
					`/functionals/by-activity?project=${idProject}&routine=${idRoutine}&activity=${mainSelect.valueLabel}&functionalParent=${subSelect.functionalN1}`
				);
			}
		} else if (mainSelect.optionLabel === 'espacio') {
			// Solicitar nivel 2
			getFunctionalsLvl2(
				null,
				`/functionals/by-activity?project=${idProject}&routine=${idRoutine}&functionalParent=${mainSelect.valueLabel}`
			);
		}
	};

	return {
		getFunctionals,
		functionalsLvl1,
		functionalsLvl2,
		activitiesList,
		withTwoLevels,
		labelService,
	};
}
