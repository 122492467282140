/* eslint-disable react-hooks/exhaustive-deps */
import { useChangeStep } from '../../../hooks/useChangeStep';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../../store/modules/loading';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';

export function useCompleteCreation() {
	const dispatch = useDispatch();

	const { idProject, supervisionExtraordinaryData } = useContext(
		CreateSupervisionContext
	);

	const { changeStepSE } = useChangeStep();

	const handleOnContinue = () => {
		dispatch(setLoading({ loading: true }));
		changeStepSE(
			{
				newStep: STEPS_SUPERVISION_EXTRAORDINARY.FIN_2,
				extraordinarySupervision:
					supervisionExtraordinaryData.idSupervisionExtraordinary,
			},
			`/${idProject}`
		);
	};

	return { handleOnContinue };
}
