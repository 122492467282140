/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { stopLoading } from '../../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { IGetUnitsResp } from '../../activities/interfaces/get-units.interfaces';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { TServicesStandars } from '../interfaces/standars.interfaces';
import { SplitArrayInNumbers } from '../../../../../utils/splitArray';

interface IUseGetUnits {
	withIndicators?: boolean;
}

export function useGetUnitsOptions({ withIndicators = false }: IUseGetUnits) {
	const dispatch = useDispatch();
	const [unitsOptions, setUnitsOptions] = useState<IOptionsMenu[]>([]);
	const [idsIndicators, setIdsIndicators] = useState<Set<string>>(new Set());
	const [checkedList, setCheckedList] = useState<Set<number | string>>(new Set());
	const [items, setItems] = useState<
		{
			servicio: string;
			criterios: TServicesStandars[];
		}[]
	>([]);

	const {
		execute: getUnits,
		status: statusUnits,
		value: valueUnits,
	} = useApiRequest<unknown, IResponsePayload<IGetUnitsResp>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.GET_UNITS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusUnits === 'success') {
			withIndicators ? adaptInfoIndicators() : adaptInfoOptions();
		} else if (statusUnits === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusUnits]);

	const adaptInfoOptions = () => {
		dispatch(stopLoading());
		setUnitsOptions(
			valueUnits?.data.map((e) => {
				return {
					id: e._id,
					label: e.activityDescription,
					value: e.activity,
					status: e.isIndicatorAssociated,
				};
			}) || []
		);
	};

	const adaptInfoIndicators = () => {
		const temporalIds = new Set<string>();
		const temporalChecked = new Set<string>();
		dispatch(stopLoading());
		setItems([
			{
				servicio: valueUnits?.data[0]?.activityDescription || '',
				criterios: SplitArrayInNumbers(
					(valueUnits?.data.map((e) => {
						temporalIds.add(e._id.toString());
						if (e.applies) temporalChecked.add(e._id);
						return {
							id: e._id,
							name: e.indicatorDescription,
							value: e.applies,
						};
					}) as unknown as TServicesStandars[]) || [],
					5
				),
			},
		]);
		setIdsIndicators(temporalIds);
		setCheckedList(temporalChecked);
	};

	return {
		unitsOptions,
		items,
		idsIndicators,
		checkedList,
		valueUnits,
		setCheckedList,
		setItems,
		getUnits,
	};
}
