import { IColumns } from '../../../interfaces/table.insterface';

export const columnsEvidencesDetail: IColumns[] = [
	{
		text: '',
		nameID: 'img',
		align: 'center',
	},
	{
		text: 'Descripción de la evidencia',
		nameID: 'descriptionEvidence',
		width: '800px',
	},
];

export const columnsAllActions: IColumns[] = [
	{
		text: 'Acción',
		nameID: 'type',
	},
	{
		text: 'Descripción',
		nameID: 'description',
	},
	{
		text: 'Día',
		nameID: 'date',
	},
	{
		text: 'Hora',
		nameID: 'hour',
	},
	{
		text: 'Ejecutó',
		nameID: 'createdBy',
	},
];
