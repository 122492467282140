/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../config/theme';
import { IAdaptedValues } from '../interfaces/edit-adapted-values';
import { IIndicatorModel } from '../interfaces/project.interface';

interface IUseCreateBase {
	label: string;
	inputsCustom?: IAdaptedValues[];
	onSaveCustom: (newBase: IIndicatorModel) => void;
}

export const useCreateBase = ({ label, inputsCustom, onSaveCustom }: IUseCreateBase) => {
	const navigate = useNavigate();
	const [attributes, setAttributes] = useState<string[]>([]);
	const [mainAttribute, setMainAttribute] = useState<string>('');
	const [name, setName] = useState<string>('');

	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const [inputs] = useState<IAdaptedValues[]>(
		inputsCustom
			? inputsCustom
			: [
					{
						id: '0',
						mainAttribute: label,
						description: '',
						rows: [],
						frequency: '',
					} as unknown as IAdaptedValues,
					{
						id: '1',
						mainAttribute: 'Atributo principal',
						description: '',
						rows: [],
						frequency: '',
					} as unknown as IAdaptedValues,
					{
						id: '2',
						mainAttribute: 'Atributos secundarios',
						description: '',
						rows: [],
						frequency: '',
					} as unknown as IAdaptedValues,
			  ]
	);

	useEffect(() => {
		if (inputsCustom) {
			setMainAttribute(inputsCustom[1].description);
			setName(inputsCustom[0].description);

			if (inputsCustom.length >= 2) {
				const newArray = [];
				for (let i = 0; i < inputsCustom[2].rows.length; i++) {
					newArray.push(inputsCustom[2].rows[i].value);
				}
				setAttributes(newArray);
			}
		}
	}, [inputsCustom]);

	const disabledButton = useMemo((): boolean => {
		if (mainAttribute !== '' && name !== '') return false;
		return true;
	}, [mainAttribute, name]);

	const onSave = () => {
		const newBase = {
			name: name,
			mainAttribute: mainAttribute,
			attributes: [mainAttribute, ...attributes],
		};

		onSaveCustom(newBase);
	};

	return {
		inputs,
		matches,
		attributes,
		mainAttribute,
		name,
		disabledButton,
		navigate,
		setAttributes,
		setMainAttribute,
		setName,
		onSave,
	};
};
