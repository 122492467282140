import { Paper, Box, Grid, FormControlLabel, Switch } from '@mui/material';
import styles from './styles/stepper-styles.module.css';
import { useViewProjects } from './hooks/useViewProjects';
import { Button } from '../../../components/main-button.component';
import { HeaderEdit } from '../../../components/header-edit.component';
import VerticalStepper from '../../../components/vertical-stepper-fixed.component';
import { ReactComponent as ProjectsIcon } from '../../../assets/svg/projects.svg';
import { ViewProjectsItemRow } from './item-view-project.component';
import { INewInfoProject } from './interfaces/new-info-project.interface';
import Modal from '../../../components/modal.component';

interface IViewProject {
	newInfoProject: INewInfoProject[];
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentProject: React.Dispatch<React.SetStateAction<string>>;
}

export const ViewProjects = ({
	newInfoProject,
	setStep,
	setCurrentProject,
}: IViewProject) => {
	const {
		active,
		projectsData,
		matches,
		disabledButton,
		modal,
		idProject,
		navigate,
		onContinue,
		setModal,
		setActive,
	} = useViewProjects({
		newInfoProject,
	});

	return (
		<Paper>
			<Modal
				open={modal.open}
				title={modal.title}
				cancelLabel="Cancelar"
				confirmLabel="Continuar"
				styleButtonConfirm={{ color: '#0080F9' }}
				width="sm"
				hideCancel={modal.hideCancel}
				hideConfirm={modal.hideConfirm}
				onClose={modal.onClose}
				onConfirm={modal.callback}
				modal={modal}
				setModal={setModal}
			>
				{modal.body}
			</Modal>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					<HeaderEdit
						section="Proyectos"
						title="1. Selecciona el proyecto a modificar."
						alertText="A continuación, podrás editar aquellos proyectos en los cuales hemos detectado que el usuario participa. Asegúrate de asignar un nuevo usuario para sustituir al actual."
						icon={
							<ProjectsIcon
								style={{ width: '25px', height: '25px', color: 'white' }}
							/>
						}
					/>
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="projects-table"
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={projectsData}
							renderItem={(item, column) => (
								<ViewProjectsItemRow
									item={item}
									column={column}
									onEdit={(item) => {
										setCurrentProject(item.id);
										setStep(2);
									}}
								/>
							)}
						/>
					</Grid>
					{idProject && (
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Switch
										checked={active}
										onChange={() => setActive(!active)}
									/>
								}
								label={'Desactivar permanentemente'}
							/>
						</Grid>
					)}
					<Grid item>
						<Button
							label="Regresar"
							onClick={() => navigate(-1)}
							variant="outlined"
							style={{
								width: '200px',
								color: 'var(--color-error-main)',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Continuar"
							onClick={() => onContinue()}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
							disabled={disabledButton}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
