export interface IMenuList {
	icon?: JSX.Element | JSX.Element[];
	title: string;
	submenu?: IMenuList[];
	link: string;
	parameter?: string;
	Component?: () => JSX.Element;
	noProtected?: boolean;
	hideMenu?: boolean;
}

export const styleIcon: React.CSSProperties = {
	margin: 0,
	padding: 0,
	color: '#FFF',
	fontSize: 30,
};

export const styleIconContainer: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '20px',
};
