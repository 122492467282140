/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetUsers } from './useGetUsers';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../../../../../config/theme';
import { RootState } from '../../../../../../store';
import { useDeletePersonal } from './useDeletePersonal';
import { useCreatePersonal } from './useCreatePersonal';
import { useUpdatePersonal } from './useUpdatePersonal';
import { isEmpty } from '../../../../../../utils/validators';
import { useGetPersonalByRole } from './useGetPersonalByRole';
import { IGetPersonal } from '../../../../../../interfaces/user.interface';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';

interface IAssignPersonal {
	role: IAdaptedValues;
}

export const useAssginPersonal = ({ role }: IAssignPersonal) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);

	const { personalData, setPersonalData } = useGetPersonalByRole({ role });

	const { usersOptions } = useGetUsers();
	const { handleCreatePersonal } = useCreatePersonal({
		personalData,
		setPersonalData,
		role,
	});
	const { handleDeletePersonal } = useDeletePersonal({ personalData, setPersonalData });
	const { handleUpdatePersonal } = useUpdatePersonal();

	const handleNewPersonal = () => {
		const temporalPersonal = personalData;
		temporalPersonal.push({
			id: '',
			description: role.description,
			rows: role?.rows?.map((e) => {
				if (e.key === 'Active') return { key: e.key, value: '1' };
				else return { key: e.key, value: '' };
			}),
			isComplete: false,
			mainAttribute: role.mainAttribute,
		} as unknown as IAdaptedValues);
		setPersonalData([...temporalPersonal]);
	};

	const saveNewPersonal = (
		item: { [key: string]: string },
		number: number,
		_id?: string
	) => {
		const newPersonal: IGetPersonal = {
			active: item['Active'] === '0' ? false : true,
			rol: role.id,
			user: item['Nombre'],
			columns: Object.entries(item).map(([element, value]) => {
				return value;
			}),
			project: idProject || '',
		};
		if (_id) {
			handleUpdatePersonal(
				{
					active: item['Active'] === '0' ? false : true,
					user: item['Nombre'],
					columns: Object.entries(item).map(([element, value]) => {
						return value;
					}),
				},
				_id
			);
		} else {
			handleCreatePersonal(newPersonal, number);
		}
	};

	const deletePersonal = (_id?: string) => {
		if (isEmpty(_id) || !_id) {
			const newPersonal = personalData;
			newPersonal.pop();
			setPersonalData([...newPersonal]);
		} else {
			handleDeletePersonal(_id);
		}
	};

	const backButton = useMemo((): boolean => {
		return personalData.some((personal) => !isEmpty(personal.id));
	}, [personalData]);

	return {
		usersOptions,
		personalData,
		matches,
		backButton,
		handleNewPersonal,
		saveNewPersonal,
		deletePersonal,
		setPersonalData,
		navigate,
	};
};
