import { useStartedData } from './hooks/useStartedData';
import { UploadFile } from '../../components/upload-file.component';

interface IStartedLoadData {
	consultStatus?: boolean;
}

export const StartedLoadData = ({ consultStatus = true }: IStartedLoadData) => {
	const { getLabel } = useStartedData({ consultStatus });

	return <UploadFile alertDescription={getLabel()} />;
};
