/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import {
	IActivities,
	IAdaptedActivities,
} from '../../../../interfaces/get-activities.interface';

interface IUpdateActivitiesFrequencies {
	activitiesOptions: IAdaptedActivities[];
	setActivitiesOptions: React.Dispatch<React.SetStateAction<IAdaptedActivities[]>>;
}

export const useUpdateActivitiesFrequencies = ({
	activitiesOptions,
	setActivitiesOptions,
}: IUpdateActivitiesFrequencies) => {
	const dispatch = useDispatch();

	const {
		execute: updateActivitiesFrequencies,
		status: statusUpdateActivitiesFrequencies,
		value,
	} = useApiRequest<{ frequency: string }, IResponsePayload<IActivities>>({
		path: URLS.EDIT_PROJECT.CHRONOGRAM.UPDATE_ACTIVITY_FREQUENCY,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusUpdateActivitiesFrequencies === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Frecuencia actualizada con éxito',
					severity: 'success',
				})
			);
			setActivitiesOptions(
				activitiesOptions.map((activity) => {
					return {
						...activity,
						frequency:
							activity.id === value?.detail?._id
								? value?.detail?.frequency
								: activity.frequency,
					};
				})
			);
			dispatch(stopLoading());
		} else if (statusUpdateActivitiesFrequencies === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la frecuencia',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateActivitiesFrequencies]);

	const handleUpdateActivitiesFrequencies = (
		idActivity: string,
		body: { frequency: string }
	) => {
		dispatch(setLoading({ loading: true }));
		updateActivitiesFrequencies(body, `/${idActivity}`);
	};

	return { handleUpdateActivitiesFrequencies };
};
