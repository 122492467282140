/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { RootState } from '../../../../../../store';
import { stopLoading, setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { ITeam } from '../../interfaces/team.interface';
import { getTeamsAdapter, getTeamsOptions } from '../adapters/get-teams.adapter';
import { IOptionsMenu } from '../../../../../../interfaces/select.interface';

interface IGetTeams {
	usersData?: IOptionsMenu[];
	setUsersData?: React.Dispatch<React.SetStateAction<IOptionsMenu[]>>;
	execute?: boolean;
}

export const useGetTeams = ({ usersData, execute = true, setUsersData }: IGetTeams) => {
	const dispatch = useDispatch();

	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);
	const [teamsData, setTeamsData] = useState<ITeam[]>([]);

	const {
		execute: getTeams,
		status: statusGetTeams,
		value: valueGetTeams,
	} = useApiRequest<unknown, IResponsePayload<ITeam>>({
		path: URLS.EDIT_PROJECT.TEAMS.GET_TEAMS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetTeams === 'success') {
			dispatch(stopLoading());
			setTeamsData(
				getTeamsAdapter(valueGetTeams?.data || [], idProject || '', usersData)
			);
			if (setUsersData && usersData) {
				setUsersData([
					...usersData,
					...getTeamsOptions(valueGetTeams?.data || [], usersData),
				]);
			}
		} else if (statusGetTeams === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los equipos',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetTeams]);

	const handleGetTeams = () => {
		dispatch(setLoading({ loading: true }));
		getTeams(null, `?project=${idProject}`);
	};

	useEffect(() => {
		if (execute) {
			dispatch(setLoading({ loading: true }));
			getTeams(null, `?project=${idProject}`);
		}
	}, []);

	return {
		teamsData,
		valueGetTeams,
		setTeamsData,
		handleGetTeams,
	};
};
