import { createTheme, Grid, TextField, ThemeProvider } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { parseISO } from 'date-fns';
import style from './styles/static-date.module.css';

interface ISelectDate {
	value: Date;
	setValueDate: React.Dispatch<React.SetStateAction<Date>>;
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#0080f9',
			dark: '#0080f9',
		},
	},
});

export const SelectDate = ({ value, setValueDate }: ISelectDate) => {
	return (
		<Grid>
			<ThemeProvider theme={theme}>
				<StaticDatePicker
					componentsProps={{
						actionBar: {
							actions: [],
						},
					}}
					toolbarTitle=""
					disableOpenPicker
					disablePast
					showToolbar={false}
					onChange={(newValue: any) => {
						setValueDate(newValue);
					}}
					value={parseISO(value.toISOString())}
					renderInput={() => (
						<TextField fullWidth style={{ display: 'none' }} />
					)}
					inputFormat={'dd/MM/yyyy'}
					className={style.editButton}
				/>
			</ThemeProvider>
		</Grid>
	);
};
