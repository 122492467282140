import { KeyboardArrowRight } from '@mui/icons-material';
import { IDimensionEspacial, INivel } from '../../../../../interfaces/logic.interface';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';

export const areaSelectLvlAdapter = (
	data: INivel[],
	functionView: (item: INivel) => void
): INivel[] => {
	const niveles: INivel[] = data.map((nivel: INivel) => {
		return {
			...nivel,
			acciones: (
				<span onClick={() => functionView(nivel)} style={{ cursor: 'pointer' }}>
					<KeyboardArrowRight />
				</span>
			),
		};
	});
	return niveles;
};

export const optionsAreasAdapter = (data: IDimensionEspacial[]): IOptionsMenu[] => {
	const niveles: IOptionsMenu[] = data.map((nivel: IDimensionEspacial) => {
		return {
			id: nivel._id,
			label: nivel.description,
			value: nivel._id,
		};
	});
	return niveles;
};
