/* eslint-disable react-hooks/exhaustive-deps */

import { useContext } from 'react';
import { ICreationPage } from '../../../../../interfaces/creation-page-interfaces';
import { setLoading } from '../../../../../store/modules/loading';
import { statusCreation } from '../../../constants/creation-supervision.component';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';
import { CreateSupervisionContext } from '../../../contexts/useCreateSupervisionExtraordinaryContext';
import { StepOnePersonal } from '../components/step-one.component';
import { useDispatch } from 'react-redux';
import { useChangeStep } from '../../../hooks/useChangeStep';

export function usePersonal() {
	const { modal, idProject, supervisionExtraordinaryData, setModal } = useContext(
		CreateSupervisionContext
	);

	const dispatch = useDispatch();
	const { changeStepSE } = useChangeStep();

	const PersonalSteps: ICreationPage = {
		header: {
			label: 'Estado de creación',
			stepper: {
				activeStep: 3,
				items: statusCreation,
				completed: {},
			},
		},
		stepOne: {
			title: '1. Elige un supervisor',
			show: true,
			Children: <StepOnePersonal />,
		},
	};

	const undoStep = () => {
		setModal({
			...modal,
			open: true,
			callback: () => {
				dispatch(setLoading({ loading: true }));
				changeStepSE(
					{
						newStep: STEPS_SUPERVISION_EXTRAORDINARY.CRT_1,
						extraordinarySupervision:
							supervisionExtraordinaryData.idSupervisionExtraordinary,
					},
					`/${idProject}`
				);
			},
		});
	};

	return { PersonalSteps, undoStep };
}
