export const selectDatePickers = [
	{
		id: 'suspensionStartDate',
		name: 'suspensionStartDate',
		label: 'Fecha de inicio',
		placeholder: 'Seleccionar',
		type: 'text',
		inputFormat: 'dd/MM/yyyy',
	},
	{
		id: 'suspensionEndDate',
		name: 'suspensionEndDate',
		label: 'Fecha de terminación',
		placeholder: 'Seleccionar',
		type: 'text',
		inputFormat: 'dd/MM/yyyy',
	},
];
