import { Grid, useMediaQuery } from '@mui/material';
import TextArea from '../../../components/text-area.component';
import styles from '../../../components/styles/item-text-area-styles.module.css';
import { EditButton } from '../../../components/buttons.component';
import theme from '../../../config/theme';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';

interface IItem {
	item: IAdaptedValues;
	column: number;
	onEdit: (item: IAdaptedValues) => void;
}

export const ViewProjectsItemRow = ({ item, column, onEdit }: IItem) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			<Grid item xs={matches ? 9 : 10} className={styles.renderItem}>
				<TextArea
					key={`table-${column}-${item.id}`}
					RenderTitle={() => {
						if (column === 0) return <p>{item.mainAttribute}</p>;
						return null;
					}}
					defaultValue={item.description}
					onChange={() => null}
					onPaste={() => null}
					hideCopyPaste
					isReadonly
				/>
			</Grid>
			<Grid xs item>
				<EditButton onClick={() => onEdit(item)} />
			</Grid>
		</>
	);
};
