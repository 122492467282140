/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { IGetSupervisionUnit } from '../../interfaces/get-supervision-unit.interface';

interface IUpdateUARelations {
	setValue: React.Dispatch<
		React.SetStateAction<IResponsePayload<IGetSupervisionUnit> | null>
	>;
}

export const useUpdateUARelations = ({ setValue }: IUpdateUARelations) => {
	const dispatch = useDispatch();

	const {
		execute: updateUARelations,
		status: statusUpdateUARelations,
		value: valueUpdateUARelations,
	} = useApiRequest<unknown, IResponsePayload<IGetSupervisionUnit>>({
		path: URLS.PROJECT.MANY_SUPERVISION_UNIT,
		method: 'POST',
	});

	useEffect(() => {
		if (statusUpdateUARelations === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Información actualizada con éxito',
					severity: 'success',
				})
			);
			setValue(valueUpdateUARelations);
		} else if (statusUpdateUARelations === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusUpdateUARelations]);

	const handleUpdateUARelations = (body: { supervisionUnits: any }) => {
		dispatch(setLoading({ loading: true }));
		updateUARelations(body);
	};

	return { handleUpdateUARelations };
};
