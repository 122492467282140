import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultAlertValue, IAlert } from '../../interfaces/alert.interface';

export const snackbarSlice = createSlice({
	name: 'utils',
	initialState: defaultAlertValue,
	reducers: {
		setAlert: (state, action: PayloadAction<IAlert>) => ({
			...state,
			...action.payload,
		}),
	},
});

export const { setAlert } = snackbarSlice.actions;

export default snackbarSlice.reducer;
