/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { resetState } from '../../../../../store/modules/steps';
import { setAlert } from '../../../../../store/modules/snackbar';
import { stopLoading } from '../../../../../store/modules/loading';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { IDocument } from '../../../../../interfaces/project.interface';
import { documentationAdapter } from '../adapters/documentation.adapter';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { useDeleteDocument } from './useDeleteDocument';
import { useSetCoverImage } from './useSetCoverImage';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';

export const useGetDocuments = () => {
	const dispatch = useDispatch();
	const { data, setData, getProject } = useContext(ProfileProjectContext);
	const { data: dataTable, setData: setDataTable } = useTableCustom<Object>();

	const { deleteDocument, statusDeleteDocument } = useDeleteDocument();
	const { setCoverImage, statusSetCoverImage } = useSetCoverImage();

	const {
		execute: executeGetDocuments,
		status: statusGetDocuments,
		value: valueDocuments,
	} = useApiRequest<unknown, IResponsePayload<IDocument>>({
		path: URLS.PROJECT.GET_FILES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetDocuments === 'success') {
			dispatch(stopLoading());
			setDataTable({
				...dataTable,
				countRows: valueDocuments?.totalRecords || 0,
				rows: documentationAdapter(
					valueDocuments?.data || [],
					deleteDocument,
					setCoverImage
				),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
			setData({ ...data, files: [] });
			dispatch(resetState());
		} else if (statusGetDocuments === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los documentos',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetDocuments]);

	useEffect(() => {
		if (statusDeleteDocument === 'success') {
			executeGetDocuments(
				{},
				`?page=${dataTable.pagination}&recordsByPage=${dataTable.rowsPerPage}&project=${data._id}`
			);
		}
	}, [statusDeleteDocument]);

	useEffect(() => {
		if (statusSetCoverImage === 'success') {
			executeGetDocuments(
				{},
				`?page=${dataTable.pagination}&recordsByPage=${dataTable.rowsPerPage}&project=${data._id}`
			);
			getProject({}, `/${data._id}`);
		}
	}, [statusSetCoverImage]);

	return { dataTable, setDataTable, executeGetDocuments };
};
