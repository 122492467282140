/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import { useLocation } from 'react-router-dom';
import { IFrequencies } from '../interfaces/get-frequencies.interface';
import { getFrequenciesAdapter } from '../adapters/get-frequencies.adapter';

export const useGetFrequencies = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [frequenciesOptions, setFrequenciesOptions] = useState<IOptionsMenu[]>([]);

	const {
		execute: getFrequencies,
		status: statusGetFrequencies,
		value: valueGetFrequencies,
	} = useApiRequest<unknown, IResponsePayload<IFrequencies>>({
		path: URLS.EDIT_PROJECT.CHRONOGRAM.GET_FREQUENCIES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetFrequencies === 'success') {
			dispatch(stopLoading());
			setFrequenciesOptions(getFrequenciesAdapter(valueGetFrequencies?.data || []));
		} else if (statusGetFrequencies === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar las frecuencias',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetFrequencies]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getFrequencies(null, `/${idProject}`);
	}, []);

	return { frequenciesOptions };
};
