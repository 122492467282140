/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { ConfirmationStatus } from '../components/confirmation-status.component';
import { ViewProjects } from '../components/view-projects.component';
import { INewInfoProject } from '../components/interfaces/new-info-project.interface';
import { ViewAssignments } from '../components/view-assignments.component';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../store/modules/snackbar';

export const useUserManagement = () => {
	const dispatch = useDispatch();
	const [step, setStep] = useState(0);
	const [currentProject, setCurrentProject] = useState('');
	const [newInfoProject, setNewInfoProject] = useState<INewInfoProject[]>([]);

	const saveProjectData = (newDataProject: INewInfoProject) => {
		if (newInfoProject.length === 0) setNewInfoProject([newDataProject]);
		else if (
			!newInfoProject.find((project) => project.project === newDataProject.project)
		) {
			return setNewInfoProject([...newInfoProject, newDataProject]);
		} else {
			const temporalDataProjects = newInfoProject.map((project) => {
				if (project.project === newDataProject.project) return newDataProject;
				else return project;
			});
			setNewInfoProject(temporalDataProjects);
		}
		dispatch(
			setAlert({
				show: true,
				message: 'Información actualizada con éxito',
				severity: 'success',
			})
		);
	};

	const getComponent = () => {
		switch (step) {
			case 0:
			default:
				return <ConfirmationStatus setStep={setStep} />;
			case 1:
				return (
					<ViewProjects
						newInfoProject={newInfoProject}
						setStep={setStep}
						setCurrentProject={setCurrentProject}
					/>
				);
			case 2:
				return (
					<ViewAssignments
						currentProject={currentProject}
						projectData={
							newInfoProject.find(
								(project) => project.project === currentProject
							) || { project: currentProject, activities: [] }
						}
						setStep={setStep}
						saveProjectData={saveProjectData}
					/>
				);
		}
	};

	return { getComponent };
};
