import { Add } from '@mui/icons-material';
import { Box, Divider, Paper } from '@mui/material';
import TableCustom from '../../components/table-custom.component';
import { useViewProjects } from './hooks/useViewProjects';
import { columnsProjects } from './utils/columns-projects.utils';

export const ViewProjects = () => {
	const { data, setData, handleNewProject } = useViewProjects();

	return (
		<Paper>
			<Box padding={3}>
				<h3>Proyectos</h3>
				<Divider />
				<TableCustom
					columns={columnsProjects}
					data={data}
					setData={setData}
					buttons={[
						{
							icon: Add,
							label: 'Creación de proyecto automática',
							id: 'crear-proyecto',
							execute: () => {
								handleNewProject('automatic');
							},
							type: 'blue',
						},
						{
							icon: Add,
							label: 'Creación de proyecto manual',
							id: 'crear-proyecto',
							execute: () => {
								handleNewProject('manual');
							},
							type: 'blue',
						},
					]}
					showPagination
					showLimitPerPage
				/>
			</Box>
		</Paper>
	);
};
