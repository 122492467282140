import { Grid } from '@mui/material';
import { Button } from '../../../../../components/main-button.component';
import InputSelect from '../../../../../components/select-input.component';
import { useStepOne } from '../hooks/useStepOne';

export const StepOnePersonal = () => {
	const {
		matches,
		personalSelected,
		personalOptions,
		navigate,
		setPersonalSelected,
		handleOnContinue,
	} = useStepOne();

	return (
		<Grid
			container
			marginTop={2}
			spacing={2}
			style={{
				padding: '8px 0px 0px 8px',
			}}
		>
			<Grid item xs={12}>
				<InputSelect
					optionsMenu={personalOptions}
					value={personalSelected}
					placeholder="Selecciona un supervisor"
					onChange={(e) => {
						setPersonalSelected(e.target.value?.toString() || '');
					}}
					id="select-personal"
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'flex-end'}
					spacing={2}
				>
					<Grid item>
						<Button
							label="Cancelar"
							onClick={() => navigate(-1)}
							style={{
								backgroundColor: 'var(--color-error-main)',
								width: '200px',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Finalizar personal"
							onClick={() => {
								// dispatch(setState({ state: 5 }));
								handleOnContinue();
							}}
							style={{
								backgroundColor: 'var(--color-highlight-blue)',
								width: '200px',
							}}
							disabled={!personalSelected}
							id="finish-personal"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
