import { isEmpty } from '../../../utils/validators';
import { IUser } from '../../../interfaces/user.interface';

export const getLetter = (props: IUser | null) => {
	if (!props) return '';
	const { firstName, lastName } = props;
	let letter = '';
	if (!isEmpty(firstName)) letter += firstName[0].toUpperCase();
	if (!isEmpty(lastName)) letter += lastName[0].toUpperCase();
	return letter;
};
