/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { RootState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { getPersonalAdaptedData } from '../adapters/get-personal.adapter';
import { IGetPersonal } from '../../../../../../interfaces/user.interface';
import { stopLoading, setLoading } from '../../../../../../store/modules/loading';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';

interface IGetPersonalByRole {
	role: IAdaptedValues;
	setStep?: React.Dispatch<React.SetStateAction<number>>;
}

export const useGetPersonalByRole = ({ role, setStep }: IGetPersonalByRole) => {
	const dispatch = useDispatch();
	const [personalData, setPersonalData] = useState<IAdaptedValues[]>([]);

	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);

	const {
		execute: getPersonalByRole,
		status: statusGetPersonalByRole,
		value: valueGetPersonalByRole,
	} = useApiRequest<unknown, IResponsePayload<IGetPersonal>>({
		path: URLS.EDIT_PROJECT.PERSONALS.GET_PERSONAL_BY_ROLE,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetPersonalByRole === 'success') {
			dispatch(stopLoading());
			setPersonalData(
				getPersonalAdaptedData(valueGetPersonalByRole?.data || [], role)
			);
			if ((valueGetPersonalByRole?.totalRecords || 0) > 0 && setStep) setStep(2);
		} else if (statusGetPersonalByRole === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar el personal',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetPersonalByRole]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getPersonalByRole(null, `?project=${idProject}&rol=${role.id}`);
	}, []);

	return {
		personalData,
		valueGetPersonalByRole,
		setPersonalData,
	};
};
