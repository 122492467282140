/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useMemo, useState, useImperativeHandle } from 'react';
import VerticalStepper from './vertical-stepper-fixed.component';
import { IVerticalStepperAttributes } from '../interfaces/vertical.steper.attributes-fixed.interface';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { styled, SxProps } from '@mui/material/styles';
import { hasDuplicateElements, isEmpty } from '../utils/validators';
import { uniqueId } from '../utils/uniqueId';
import { setAlert } from '../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import ContinueButton from './custom-btn.component';
import DashedBtn from './dashed-button.component';
import AddAttrBtn from './add-attribute-btn.component';
import ChipList from './chip-list.component';
import { DeleteBtn } from './button-delete.component';
import { IBaseModel } from '../interfaces/project.interface';
import { stepTwoAdapter } from '../adapters/step-two.adapter';
import { IStepTwoModel } from '../interfaces/stepTwo.interface';
import { TReactDispatch } from '../interfaces/utils';

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

const gridProperties: SxProps = {
	display: 'flex',
	justifyContent: 'flex-start',
	flexWrap: 'wrap',
	listStyle: 'none',
	p: 0.5,
	m: 0,
};

export interface IStep {
	onContinue?: (data: IStepTwoModel[]) => void;
	onChange?: (data: IVerticalStepperAttributes[]) => void;
	onSecondaryAttributesChange?: (
		attributes: Array<{ key: number; label: string }>
	) => void;
	defaultData?: IVerticalStepperAttributes[] | null;
	data: IVerticalStepperAttributes[];
	disableNext?: boolean | null;
	setData?:
		| TReactDispatch<IVerticalStepperAttributes[]>
		| ((val: IVerticalStepperAttributes[]) => void);
	resetDone?: null | true;
	isDefaultDone?: null | boolean;
	disableRows?: number[];
}

export type TFunctionHandler = {
	resetValues: () => void;
	unDone: () => void;
	disableAll: () => void;
};
export type StepOneHandler = React.ElementRef<typeof StepOneContent>;
const StepOneContent = forwardRef<TFunctionHandler, IStep>((props, ref) => {
	const {
		onContinue,
		onChange,
		resetDone,
		defaultData,
		disableNext,
		data,
		setData,
		isDefaultDone,
		disableRows = [],
		onSecondaryAttributesChange,
	} = props;
	const dispatch = useDispatch();
	const [isElementAdded, setIsElementAdded] = useState<boolean>(false);
	const [secondaryAttributes, setSecondaryAttributes] = useState<
		Array<{ key: number; label: string }>
	>([]);
	const [isDone, setIsDone] = useState(false);
	const isNextAllowed = useMemo(() => data.every((i) => i.isComplete), [data]);

	useEffect(() => {
		if (!resetDone) return;
		setIsDone(true);
	}, [resetDone]);

	useEffect(() => {
		if (onChange) onChange(data);
	}, [data]);

	useImperativeHandle(ref, () => ({
		resetValues() {
			setSecondaryAttributes([]);
			setIsElementAdded(false);
			setIsDone(false);
		},
		unDone() {
			setIsDone(false);
		},
		disableAll() {
			setIsDone(true);
		},
	}));

	useEffect(() => {
		if (onSecondaryAttributesChange) onSecondaryAttributesChange(secondaryAttributes);
	}, [secondaryAttributes]);

	useEffect(() => {
		if (isDefaultDone === null) return;
		setIsDone(isDefaultDone ?? false);
	}, [isDefaultDone]);

	const handleCriterio = (index: number, value: string) => {
		const newPrev = [...data];
		newPrev[index].value = value;
		//Si es el ultimo (Atributos secundarios) no actualizar el complete, ya que ese tiene otros criterios para actualizarse
		if (index < 2) newPrev[index].isComplete = !isEmpty(value);

		if (setData) setData(newPrev);
	};

	//Esto actualiza atributos secundarios para habilitar el continuar
	useEffect(() => {
		if (!isElementAdded) return;
		const newPrev = [...data];
		newPrev[2].isComplete = secondaryAttributes.length > 0;
		if (setData) setData(newPrev);
	}, [secondaryAttributes]);

	useEffect(() => {
		if (!defaultData) return;
		if (setData) setData(defaultData);
	}, [defaultData]);

	const handleSecondary = () => {
		if (isEmpty(data[2].value)) return;

		//Si ya existe (mandar notificacion de que ya existe el atributo ?)
		if (secondaryAttributes.some((i) => i.label === data[2].value)) return;

		const newCriterios = [...data];

		const newItem = {
			key: uniqueId(8),
			label: newCriterios[2].value,
		};
		newCriterios[2].value = '';
		if (setData) setData(newCriterios);
		setSecondaryAttributes((prev) => [...prev, newItem]);
	};

	const handleSecondaryAttributes = (key: number) => {
		setSecondaryAttributes((attr) => attr.filter((item) => item.key !== key));
	};

	const handleContinue = () => {
		const mainAttr = data[1].value;
		const table: IBaseModel = {
			name: data[0].value,
			mainAttribute: mainAttr,
			columns: ['', ...secondaryAttributes.map((_) => '')],
			attributes: [mainAttr, ...secondaryAttributes.map((i) => i.label)],
		};
		const elements = [table.name, ...table.attributes];
		const has = hasDuplicateElements(elements);
		if (has) {
			dispatch(
				setAlert({
					show: true,
					message: `El atributo '${has}' está repetido.`,
					severity: 'error',
				})
			);
			return;
		}
		setIsDone(true);
		const _adapted = stepTwoAdapter(table);
		if (onContinue) onContinue(_adapted);
	};

	const onElementAdded = () => {
		setIsElementAdded(true);
		const newCriterio: IVerticalStepperAttributes = {
			index: 2,
			name: 'Atributos Secundarios',
			placeholder: 'Escribe el tipo de espacio',
			value: '',
			isComplete: false,
		};
		if (setData) {
			const new_data = [...data, newCriterio];
			setData(new_data);
		}
	};

	return (
		<>
			<VerticalStepper
				items={data}
				gridKey="criterios"
				sxContainer={{ alignItems: 'center' }}
				renderTitle={(item) => (
					<Grid item xs={2}>
						<p style={{ margin: 0 }}>{item.name}</p>
					</Grid>
				)}
				renderItem={(item, _row) => (
					<Grid container item xs={9}>
						<Grid item xs={_row === 2 ? 11 : 12}>
							<Input
								variant="outlined"
								id={`ig-input-${item.index}`}
								size="small"
								fullWidth
								placeholder={item.placeholder}
								value={item.value}
								disabled={
									isDone ||
									!!disableNext ||
									!!disableRows.includes(_row)
								}
								onChange={(e) =>
									handleCriterio(item.index, e.target.value)
								}
								onKeyDown={(e) => {
									if (_row === 2 && e.key === 'Enter')
										handleSecondary();
								}}
							/>
						</Grid>
						{_row === 2 && (
							<Grid
								xs={1}
								sx={{ display: 'flex', justifyContent: 'center' }}
							>
								<DeleteBtn
									disabled={isDone || !!disableNext}
									onClick={() => {
										setSecondaryAttributes([]);
										setIsElementAdded(false);
										const _filtered = data.filter(
											(_, idx) => idx !== _row
										);
										if (setData) {
											setData(_filtered);
										}
									}}
								/>
							</Grid>
						)}
					</Grid>
				)}
			/>
			{isElementAdded && (
				<Grid container spacing={1} display="flex" alignItems="flex-start">
					<Grid item xs={1} />
					<Grid item xs={2} />
					<Grid item xs={2}>
						<AddAttrBtn
							onClick={handleSecondary}
							disabled={isDone || !!disableNext}
						/>
					</Grid>
					<Grid item xs={7} sx={gridProperties} component="ul" display="flex">
						{secondaryAttributes.map((data) => {
							return (
								<ChipList
									key={data.key}
									id={data.key}
									label={data.label}
									disabled={isDone || !!disableNext}
									onDelete={() => handleSecondaryAttributes(data.key)}
								/>
							);
						})}
					</Grid>
				</Grid>
			)}
			{!isElementAdded && (
				<div style={{ width: '100%', display: 'flex', margin: '2rem 0' }}>
					<DashedBtn
						onClick={onElementAdded}
						width="85"
						disabled={isDone || !!disableNext}
					/>
				</div>
			)}
			{!isDone && (
				<div style={{ width: '100%', display: 'flex', marginTop: '2rem' }}>
					<ContinueButton
						disabled={!isNextAllowed || !!disableNext}
						onClick={handleContinue}
						id="ig-continue-btn"
						customSx={{
							marginLeft: 'auto',
						}}
					/>
				</div>
			)}
		</>
	);
});

export default StepOneContent;
