import { useState } from 'react';
import { IVerticalStepperAttributes } from '../../../../interfaces/vertical.steper.attributes-fixed.interface';
import StepOneComponent, {
	TFunctionHandler,
} from '../../../../components/step-one-fixed';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';

interface IStep {
	onContinue?: (data: IStepTwoModel[], raw: IVerticalStepperAttributes[]) => void;
	onChange?: (data: IVerticalStepperAttributes[]) => void;
	onSecondaryAttributesChange?: (
		val: Array<{
			key: number;
			label: string;
		}>
	) => void;
	refComponent: React.RefObject<TFunctionHandler>;
}

const defaultNivel2: IVerticalStepperAttributes[] = [
	{
		index: 0,
		name: 'Nivel 2',
		placeholder: 'Ingrese nombre',
		value: '',
		isComplete: false,
	},
	{
		index: 1,
		name: 'Atributo Principal',
		placeholder: 'Nombre del atributo',
		value: '',
		isComplete: false,
	},
];

const StepOneContent = (props: IStep) => {
	const { onContinue, onChange, onSecondaryAttributesChange, refComponent } = props;
	const [dimensiones, setDimensiones] =
		useState<IVerticalStepperAttributes[]>(defaultNivel2);

	return (
		<StepOneComponent
			ref={refComponent}
			data={dimensiones}
			setData={setDimensiones}
			onContinue={(transformed) =>
				onContinue && onContinue(transformed, dimensiones)
			}
			onChange={(data) => onChange && onChange(data)}
			onSecondaryAttributesChange={(val) =>
				onSecondaryAttributesChange && onSecondaryAttributesChange(val)
			}
		/>
	);
};

export default StepOneContent;
