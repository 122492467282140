import { IAdaptedValues } from '../interfaces/edit-adapted-values';
import { IValuesInfo } from '../interfaces/edit-values.interface';
import { IOptionsMenu } from '../interfaces/select.interface';

export const getAdaptedData = (data: IValuesInfo[]) => {
	const newOptions = data.map((element) => {
		return {
			id: element._id,
			description: element.description,
			rows: element.attributes.map((attribute, index) => {
				return {
					key: attribute,
					value: element.columns[index],
				};
			}),
			isComplete: true,
			mainAttribute: element.mainAttribute,
		};
	});
	return newOptions as IAdaptedValues[];
};

export const getSingleAdaptedData = (data?: IValuesInfo): IAdaptedValues => {
	const adaptedData = {
		id: data?._id || '',
		description: data?.description || '',
		rows:
			data?.attributes.map((attribute, index) => {
				return {
					key: attribute,
					value: data.columns[index],
				};
			}) || [],
		isComplete: true,
		mainAttribute: data?.mainAttribute || '',
	};
	return adaptedData as IAdaptedValues;
};

export const getSelectOptionsData = (data: IValuesInfo[]): IOptionsMenu[] => {
	const newOptions = data.map((element) => {
		return {
			id: element._id,
			label: element.description,
			value: element._id,
		};
	});
	return newOptions as IOptionsMenu[];
};
