/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid, Paper } from '@mui/material';
import { setState } from '../../../../store/modules/steps';
import { columnsSelectLvl } from './utils/columns-select-lvl';
import { GoBack } from '../../../../components/go-back-component';
import TableCustom from '../../../../components/table-custom.component';
import InputSelect from '../../../../components/select-input.component';
import { IDimensionEspacial, INivel } from '../../../../interfaces/logic.interface';
import { useAreaDetail } from './hooks/useAreaDetail';
import { useLocation } from 'react-router-dom';
import { setLoading } from './../../../../store/modules/loading';
import { optionsAreasAdapter } from './adapters/area-select-lvl-adapter';
import { mockUnidadFuncional } from './utils/mock-nivel';
import { RootState } from '../../../../store';
import { SupervisionHistoryContext } from '../../contexts/useSupervisionHistoryContext';
import { DefaultDataSelect } from '../../interfaces/supervision-history.interface';
import { resetPage } from '../../../../store/modules/page-table';
import { isEmpty } from '../../../../utils/validators';

interface IAreaSelectLvl {
	area: IDimensionEspacial;
	setArea: React.Dispatch<React.SetStateAction<IDimensionEspacial>>;
	setLvl: React.Dispatch<React.SetStateAction<INivel>>;
}

export const AreaSelectLvl = ({ area, setArea, setLvl }: IAreaSelectLvl) => {
	const [selectedArea] = useState<IDimensionEspacial[]>([area]);
	const options = optionsAreasAdapter(selectedArea);
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location?.state?.params;

	const { _id } = useSelector((state: RootState) => state.projectBase);
	const { setDataSelect } = useContext(SupervisionHistoryContext);

	const handleChange = (e: any) => {
		setArea(
			mockUnidadFuncional.find((element) => element._id === e.target.value) || area
		);
	};

	const handleView = (area: INivel) => {
		setDataSelect({ ...DefaultDataSelect });
		dispatch(resetPage());
		dispatch(setState({ state: 2 }));
		setLvl(area);
	};

	const { dataAreaDetail, setAreaDetail, executeAreaDetail } =
		useAreaDetail(handleView);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeAreaDetail(
			{},
			`?project=${state?.project?._id || _id}&functionalParent=${area?._id}&page=${
				dataAreaDetail.pagination
			}&recordsByPage=${dataAreaDetail.rowsPerPage}${
				isEmpty(dataAreaDetail?.advancedFilter)
					? ''
					: `&advancedSearch=${dataAreaDetail?.advancedFilter}`
			}`
		);
	}, [dataAreaDetail.pagination, dataAreaDetail.rowsPerPage, dataAreaDetail?.filters]);

	return (
		<>
			<GoBack />
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Supervisión por espacio
							</span>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item style={{ fontWeight: 'bold' }}>
							Unidad funcional
						</Grid>
						<Grid item xs>
							<InputSelect
								value={area._id}
								placeholder="Unidad funcional"
								optionsMenu={options}
								onChange={(e) => handleChange(e)}
								isDisabled
							/>
						</Grid>
						<Grid item xs={12}>
							<TableCustom
								columns={columnsSelectLvl}
								data={dataAreaDetail}
								setData={setAreaDetail}
								showLimitPerPage
								showPagination
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
