/* eslint-disable no-restricted-globals */
import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
//Assets
import { Button } from '../../components/main-button.component';
import sistemaLogo from '../../assets/svg/logo.svg';
export class ErrorBoundary extends React.Component<
	{ children: JSX.Element },
	{ hasError: boolean }
> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch() {
		this.setState({
			hasError: true,
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
					<AppBar
						elevation={0}
						position="fixed"
						sx={{ boxShadow: '0px 1px 6px #00000029' }}
					>
						<Toolbar
							style={{
								background: '#1C1C1E',
								color: '#FFF',
							}}
						>
							<Box>
								<img
									src={sistemaLogo}
									alt="logo"
									style={{
										width: 220,
										cursor: 'pointer',
									}}
									onClick={() => window.location.reload()}
								/>
							</Box>
						</Toolbar>
					</AppBar>
					<Box
						component="main"
						sx={{
							flexGrow: 1,
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Container
							style={{
								maxWidth: '100%',
							}}
							sx={{ mt: 15, mb: 4 }}
						>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Box
									display="flex"
									width="100%"
									border="1px solid #E8E8E8"
									borderRadius="4px"
									alignItems="center"
									justifyContent="center"
								>
									<Box
										display="flex"
										flexDirection="column"
										padding="50px 150px"
										textAlign="center"
										alignItems="center"
										justifyContent="center"
									>
										<Typography variant="h1" sx={{ fontSize: 18 }}>
											Currie and Brown
										</Typography>
										<Typography
											variant="h1"
											sx={{
												color: 'secondary.light',
												fontSize: 18,
												marginTop: 2,
											}}
										>
											Ha ocurrido un error, da clic en el botón de
											regresar.
										</Typography>
										<br />
										<Button
											label="Actualizar"
											variant="solid"
											onClick={() => {
												window.location.reload();
											}}
											style={{ width: 250 }}
										/>
									</Box>
								</Box>
							</Box>
						</Container>
					</Box>
				</>
			);
		}

		return this.props.children;
	}
}
