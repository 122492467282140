/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import theme from '../../../../../../config/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetRoles } from './useGetRoles';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { getFindignsAdapter } from '../../../../../edit-indicators/components/edit-findings/adapters/get-findings.adapter';
import { useCreateRoles } from './useCreateRoles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { isEmpty } from '../../../../../../utils/validators';
import { ICreateRoles } from '../interfaces/create-roles.interface';
import { useUpdateRoles } from './useUpdateRole';
import { getInputsCustom } from '../adapters/get-base-inputs';
import { useEffect } from 'react';
import { setAlert } from '../../../../../../store/modules/snackbar';
import useUpdateProject from '../../../../../../hooks/useUpdateProject';
import { useConfirmProgress } from '../../../../../automatic-charge/hooks/useConfirmProgress';
import { useGetPersonalByProject } from '../../../../../../hooks/usePersonalByProject';

export const useListRoles = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const isAutomaticCharge = location.pathname.includes('creacion-automatica');
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);

	const { rolesOptions, rolesInputs, setRolesOptions, setRolesInputs } = useGetRoles();

	const { handleCreateRoles } = useCreateRoles({ rolesOptions, setRolesOptions });
	const { handleUpdateRoles } = useUpdateRoles({ rolesOptions, setRolesOptions });
	const { totalUsers, getTotalUsers, setTotalUsers } = useGetPersonalByProject({});
	const { handleUpdateProject } = useUpdateProject();
	const { confirmProgress } = useConfirmProgress();

	const handleNewRol = () => {
		const temporalRol = rolesOptions;
		temporalRol.push({
			id: '',
			description: '',
			rows: [
				{
					key: 0,
					value: '',
				},
			],
			isComplete: false,
			mainAttribute: 'Rol',
		} as unknown as IAdaptedValues);
		setRolesInputs({
			...rolesInputs,
			[Object.keys(rolesInputs).length]: '',
		});
		setRolesOptions([...temporalRol]);
	};

	const handleDeleteRol = (row: number) => {
		const temporalRol = rolesOptions;
		const temporalRolInputs = rolesInputs;

		temporalRol.splice(row, 1);
		delete temporalRolInputs[row];

		const newRols: string[] = [];

		Object.entries(temporalRolInputs).forEach(([element, value]) => {
			newRols.push(value);
		});

		setRolesInputs(getFindignsAdapter(newRols));
		setRolesOptions([...temporalRol]);
	};

	const handleSaveRole = (value: string, index: number, _id?: string) => {
		const newRole: ICreateRoles = {
			name: value,
			active: true,
			mainAttribute: 'Nombre',
			attributes: ['Nombre', 'Active'],
			project: idProject || '',
		};
		if (!isEmpty(_id)) {
			newRole._id = _id;
			handleUpdateRoles({ name: value }, index, _id || '');
		} else {
			handleCreateRoles(newRole, index);
		}
	};

	useEffect(() => {
		if (totalUsers) {
			if (isAutomaticCharge) {
				confirmProgress();
			} else {
				handleUpdateProject({ step: '4.0.1' }, idProject || '');
				setTotalUsers(undefined);
			}
		} else if (totalUsers === false) {
			dispatch(
				setAlert({
					show: true,
					message: 'No tienes usuarios registrados',
					severity: 'error',
				})
			);
			setTotalUsers(undefined);
		}
	}, [totalUsers]);

	return {
		rolesOptions,
		rolesInputs,
		matches,
		setRolesInputs,
		handleDeleteRol,
		handleNewRol,
		handleCreateRoles,
		handleSaveRole,
		getInputsCustom,
		getTotalUsers,
		navigate,
	};
};
