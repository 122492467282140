/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { uniqueId } from '../../../utils/uniqueId';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import useUpdateProject from '../../../hooks/useUpdateProject';

interface IGet {
	setActivities: TReactDispatch<IStepTwoModel[]>;
	isSave: boolean;
}

const useUpdateActivity = (props: IGet) => {
	const { isSave, setActivities } = props;
	const dispatch = useDispatch<any>();
	const { handleUpdateProject } = useUpdateProject();

	const { execute, status, value } = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_ACTIVITIES,
		method: 'POST',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (value) {
				const _adapted: IStepTwoModel[] = value.data.map((i, _idx) => ({
					...i,
					isComplete: true,
					id: uniqueId(),
					_id: i._id,
				}));
				setActivities(_adapted);
				if (isSave) return;
				handleUpdateProject({ step: '3.0.0' }, _adapted[0].project || '');
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, value };
};

export default useUpdateActivity;
