/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../store';
import { useEffect, useState } from 'react';
import { URLS } from '../constants/urls.constants';
import { setAlert } from '../store/modules/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { IOptionsMenu } from '../interfaces/select.interface';
import { useApiRequest, IResponsePayload } from './useApiRequest';
import { setLoading, stopLoading } from '../store/modules/loading';
import { IGetPersonalByProject, IGetPersonalOptions } from '../interfaces/user.interface';
import { getUsersByProject } from '../adapters/get-users-project.adapter';
import { getUsersType, teamToPersonal } from '../adapters/team-to-personal.adapter';
import { isEmpty } from '../utils/validators';

interface IUseGetPersonalByProject {
	idProjectProp?: string;
	addTeams?: boolean;
	addAllOption?: boolean;
}

export const useGetPersonalByProject = ({
	idProjectProp,
	addTeams,
	addAllOption,
}: IUseGetPersonalByProject) => {
	const dispatch = useDispatch();
	const [usersData, setUsersData] = useState<IOptionsMenu[]>([]);
	const [usersRelations, setUsersRelations] = useState<boolean[]>([]);
	const [totalUsers, setTotalUsers] = useState<boolean | undefined>(undefined);
	const { _id: idProject } = useSelector((state: RootState) => state.projectBase);

	const {
		execute: getPersonalByProject,
		status: statusGetPersonalByProject,
		value: valueGetPersonalByProject,
	} = useApiRequest<unknown, IResponsePayload<IGetPersonalByProject>>({
		path: URLS.PROJECT.SUPERVISORS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetPersonalByProject === 'success') {
			dispatch(stopLoading());
			setTotalUsers(
				(valueGetPersonalByProject?.detail?.supervisors || []).length > 0
			);
			setUsersRelations(
				getUsersType(
					valueGetPersonalByProject?.detail?.supervisors || [],
					valueGetPersonalByProject?.detail?.teams || [],
					addAllOption
				)
			);
			let teamsAdapted: IGetPersonalOptions[] = [];
			if (addTeams) {
				teamsAdapted = teamToPersonal(
					valueGetPersonalByProject?.detail?.teams || []
				);
			}
			const allOption = addAllOption
				? [
						{
							_id: 'all',
							firstName: 'Todos',
							lastName: 'Todos',
							fullName: 'Todos',
						},
				  ]
				: [];
			setUsersData(
				getUsersByProject([
					...allOption,
					...(valueGetPersonalByProject?.detail?.supervisors || []),
					...teamsAdapted,
				])
			);
		} else if (statusGetPersonalByProject === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar el personal',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetPersonalByProject]);

	const getTotalUsers = () => {
		dispatch(setLoading({ loading: true }));
		getPersonalByProject(null, `/${idProjectProp || idProject}`);
	};

	const getIsTeam = (_id: string | null): boolean => {
		if (isEmpty(_id)) return false;
		const index = usersData.findIndex((user) => user.id === _id);
		return usersRelations[index];
	};

	return {
		getTotalUsers,
		getPersonalByProject,
		usersData,
		totalUsers,
		usersRelations,
		setTotalUsers,
		getIsTeam,
	};
};
