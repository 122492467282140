import { getTableValues } from '../../../../utils/tableInfo';
import { ISupervisionRoutineDetail } from '../interfaces/get-supervision-detail.interface';

export const supervisionDetailAdapter = (
	type: string,
	data?: ISupervisionRoutineDetail
) => {
	const rowsWithoutFormat = getValuesWithoutFormat(type, data);
	const newData = [{ ...getTableValues(rowsWithoutFormat) }];

	return newData;
};

const getValuesWithoutFormat = (type: string, element?: ISupervisionRoutineDetail) => {
	const rows =
		type === 'servicio'
			? [
					...(element?.functionalParent?.columns || []),
					...(element?.functional?.columns || []),
					...(element?.indicator?.columns || []),
			  ]
			: [
					...(element?.functional?.columns || []),
					...(element?.activity?.columns || []),
					...(element?.indicator?.columns || []),
			  ];
	return rows;
};
