import { Box, Grid, Paper } from '@mui/material';
import { Tabs } from '../../../../../components/tabs.component';
import { DefaultTabItems } from '../../../constants/data.constants';
import { Stepper } from '../../../../../components/stepper.component';
import { estadoCreacion } from '../../../../../constants/estado-creacion';
import { Button } from '../../../../../components/main-button.component';
import DashedBtn from '../../../../../components/dashed-button.component';
import styles from './styles/stepper-styles.module.css';
import { useCreateTeamsForm } from './hooks/useCreateTeamsForm';
import Alert from '../../../../../components/info.alert.component';
import { ItemCreateTeam } from './item-create-team.component';
import VerticalStepper from '../../../../../components/vertical-stepper-fixed.component';
import { HeaderEdit } from '../../../../../components/header-edit.component';
import PersonalIcon from '../../../../../assets/icons/personal.icon';
import { LABELS_CREATE_TEAM } from './constants/labels';

interface ICreateTeamsForm {
	isEdit: boolean;
	isAutomaticCharge?: boolean;
}

export const CreateTeamsForm = ({ isEdit, isAutomaticCharge }: ICreateTeamsForm) => {
	const {
		teamsData,
		usersData,
		matches,
		setTeamsData,
		handleNewTeam,
		handleOnContinue,
	} = useCreateTeamsForm({ isEdit, isAutomaticCharge });

	return (
		<>
			{!isEdit && (
				<>
					<Paper>
						<Box padding={3}>
							<Stepper
								steps={estadoCreacion}
								activeStep={3}
								completed={{ 0: true, 1: true }}
							/>
						</Box>
					</Paper>
					<Tabs
						items={DefaultTabItems}
						value={1}
						onChange={() => null}
						sx={{ position: 'static !important', marginTop: '30px' }}
					/>
				</>
			)}
			<Paper>
				<Box padding={3}>
					<Grid
						container
						spacing={3}
						justifyContent={matches ? 'center' : 'flex-end'}
					>
						{!isEdit && !isAutomaticCharge ? (
							<>
								<Grid item xs={12}>
									<h4
										style={{
											margin: 0,
											padding: '0.5rem 0 0 0.5rem',
											fontWeight: 600,
										}}
									>
										{LABELS_CREATE_TEAM.AUTOMATIC_CHARGE.TITLE}
									</h4>
								</Grid>
								<Grid item xs={12}>
									<Alert severity="info" sx={{ marginTop: '1rem' }}>
										{LABELS_CREATE_TEAM.AUTOMATIC_CHARGE.ALERT}
									</Alert>
								</Grid>
							</>
						) : (
							<HeaderEdit
								section="Personal"
								title={
									isAutomaticCharge
										? LABELS_CREATE_TEAM.AUTOMATIC_CHARGE.TITLE
										: LABELS_CREATE_TEAM.EDIT.TITLE
								}
								alertText={
									isAutomaticCharge
										? LABELS_CREATE_TEAM.AUTOMATIC_CHARGE.ALERT
										: LABELS_CREATE_TEAM.EDIT.ALERT
								}
								icon={<PersonalIcon size="23px" />}
							/>
						)}
						<Grid item xs={12}>
							<VerticalStepper
								gridKey="criterios-table"
								sxContainer={styles.containerVerticalStepper}
								sxElement={styles.verticalStepperSxElement}
								rootStyle={{ overflowX: 'scroll', width: '100%' }}
								items={teamsData}
								renderItem={(item, column) => (
									<ItemCreateTeam
										item={item}
										column={column}
										usersOptions={usersData}
										teamData={teamsData}
										setTeamData={setTeamsData}
										isEdit={isEdit}
									/>
								)}
								withCollapsable
							/>
						</Grid>
						<Grid item xs={12}>
							<DashedBtn
								onClick={() => handleNewTeam()}
								width="100"
								text="Agregar equipo"
							/>
						</Grid>
						<Grid item>
							<Button
								id="btn-continue-team"
								label="Continuar"
								onClick={() => handleOnContinue()}
								variant="outlined"
								style={{
									backgroundColor: ' #0080F9',
									width: '200px',
									color: 'white',
								}}
								disabled={!teamsData.some((team) => team._id)}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
