import { Paper, Grid } from '@mui/material';
import { Button } from '../../../components/main-button.component';
import confirmationImage from '../../../assets/imgs/confirmation.png';
import styles from './styles/confirmation-status.module.css';
import { useConfirmationStatus } from './hooks/useConfirmationStatus';
import Modal from '../../../components/modal.component';

interface IConfirmationStatus {
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const ConfirmationStatus = ({ setStep }: IConfirmationStatus) => {
	const { modal, title, descriptionButton, handleContinue, setModal, navigate } =
		useConfirmationStatus({
			setStep,
		});

	return (
		<Paper>
			<Modal
				open={modal.open}
				title={modal.title}
				cancelLabel="Cancelar"
				confirmLabel="Continuar"
				styleButtonConfirm={{ color: '#0080F9' }}
				width="sm"
				onClose={modal.onClose}
				onConfirm={modal.callback}
				modal={modal}
				setModal={setModal}
			>
				{modal.body}
			</Modal>
			<Grid container spacing={2} style={{ height: '80vh' }}>
				<Grid item xs={12}>
					<div className={styles.mainContainer}>
						<div className={styles.infoContainer}>
							<img
								className={styles.img}
								src={confirmationImage}
								alt="actions"
							/>
							<h3>{title}</h3>
							<div className={styles.buttonsContainer}>
								<Button
									label="Cancelar"
									onClick={() => navigate(-1)}
									style={{
										width: '100%',
									}}
								/>
								<Button
									label={descriptionButton}
									onClick={() => handleContinue()}
									variant="solid"
									style={{
										width: '100%',
										backgroundColor: '#0080F9',
									}}
								/>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};
