import { ListNextTasks } from './components/list-next-tasks.component';
import { Box, Paper } from '@mui/material';

const SuspencionComponent = () => {
	return (
		<Paper elevation={0}>
			<Box padding={3}>
				<ListNextTasks />
			</Box>
		</Paper>
	);
};

export default SuspencionComponent;
