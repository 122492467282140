import { useDispatch } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import { undoState } from '../store/modules/steps';

interface IGoBack {
	action?: () => void;
}

export const GoBack = ({ action }: IGoBack) => {
	const dispatch = useDispatch();

	return (
		<div
			onClick={() => {
				action ? action() : dispatch(undoState());
			}}
			style={{
				display: 'flex',
				gap: '5px',
				padding: '10px',
				cursor: 'pointer',
				width: 'max-content',
				color: 'var(--color-highlight-blue)',
			}}
		>
			<ArrowBack />
			<span>Regresar</span>
		</div>
	);
};
