import { IDimensionEspacial } from '../../../../../interfaces/logic.interface';

export const mockUnidadFuncional: IDimensionEspacial[] = [
	{
		_id: '0',
		description: 'Urgencias',
		nivel: [
			{
				_id: '0',
				nombre: 'Archivo',
				nivel: [],
				actividades: ['S-01', 'S-02', 'S-03', 'S-04', 'S-05', 'S-06'],
				routine: '',
			},
		],
	},
];

export const mockActividades = [
	{
		fecha: '06/16/22',
		supervisor: 'Erick D. Hernández Almazán',
		actividades: 'S-03',
		criterios: 'IE-01, IE-12, IE-25',
	},
	{
		fecha: '06/16/22',
		supervisor: 'Erick D. Hernández Almazán',
		actividades: 'S-03',
		criterios: 'IE-01, IE-12, IE-25',
	},
	{
		fecha: '06/16/22',
		supervisor: 'Erick D. Hernández Almazán',
		actividades: 'S-03',
		criterios: 'IE-01, IE-12, IE-25',
	},
	{
		fecha: '06/16/22',
		supervisor: 'Erick D. Hernández Almazán',
		actividades: 'S-03',
		criterios: 'IE-01, IE-12, IE-25',
	},
];
