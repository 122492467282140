/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from 'react';
import {
	cardsUploadFiles,
	datePickers,
	descriptionNewProject,
	editDatePickers,
	formNewProject,
} from '../utils/new-project.utils';
import { parseISO } from 'date-fns';
import { Grid } from '@mui/material';
import { useNewProject } from '../hooks/useNewProject';
import styles from './styles/form-new-project.module.css';
import TextInput from '../../../components/text-input.component';
import { Button } from '../../../components/main-button.component';
import { FileButton } from '../../../components/file-button.component';
import LayoutNewProject from '../components/layout/layout-new-projects';
import { DOCS_TYPES } from '../../../constants/files-type';

interface IFormNewProject {
	state: any;
}

function FormNewProject({ state }: IFormNewProject) {
	const {
		disabledButton,
		getValue,
		handleSetData,
		handleFile,
		handleCreateProject,
		data,
	} = useNewProject({ state });

	return (
		<>
			<LayoutNewProject
				title={state?.params.isEditing ? 'Editar proyecto' : 'Nuevo proyecto'}
			>
				<>
					<Grid container component="section" spacing={3}>
						{(formNewProject || []).map((e: any) => (
							<Fragment key={e.id}>
								<Grid component="div" item xs={12} sm={12} md={4}>
									<TextInput
										key={e.id}
										id={e.id}
										name={e.name}
										label={e.label}
										placeholder={e.placeholder}
										value={getValue(e.name)}
										type={e.type}
										onChange={handleSetData}
										autoComplete={e.autoComplete}
										contentStyles={{
											width: {
												xs: '100%',
												sm: '350px',
												md: '100%',
											},
											height: '100px',
										}}
										sx={{
											width: '100%',
										}}
										InputProps={{
											sx: {
												backgroundColor: '#F7F7F7',
												width: { xs: '100%' },
												fontSize: '15px',
											},
										}}
									/>
								</Grid>
							</Fragment>
						))}
						{(state?.params?.isEditing
							? editDatePickers
							: datePickers || []
						).map((e: any) => (
							<Fragment key={e.id}>
								<Grid component="div" item xs={12} sm={12} gap={4} md={4}>
									<TextInput
										key={e.id}
										id={e.id}
										data-testid={e.id}
										name={e.name}
										label={e.label}
										placeholder={e.placeholder}
										value={parseISO(getValue(e.name))}
										type={e.type}
										onChange={(newValue: any) => {
											try {
												handleSetData({
													target: {
														name: e.name,
														value: newValue.toISOString(),
													},
												} as unknown as React.ChangeEvent<HTMLInputElement>);
											} catch (error) {
												console.log(error);
											}
										}}
										which="datePicker"
										contentStyles={{
											width: {
												xs: '100%',
												sm: '350px',
												md: '200px',
											},
											height: '100px',
										}}
										sx={{
											backgroundColor: '#F7F7F7',
											width: { xs: '100%' },
											fontSize: '15px',
										}}
										inputFormat={e.inputFormat}
									/>
								</Grid>
							</Fragment>
						))}
					</Grid>

					<Grid component="section" item xs={12} md={6}>
						{(descriptionNewProject || []).map((e: any) => (
							<Fragment key={e.id}>
								<TextInput
									key={e.id}
									id={e.id}
									name={e.name}
									label={e.label}
									placeholder={e.placeholder}
									value={getValue(e.name)}
									type={e.type}
									onChange={handleSetData}
									InputProps={{
										sx: {
											backgroundColor: '#F7F7F7',
										},
									}}
									multiline={e.multiline}
									rows={e.rows}
								/>
							</Fragment>
						))}
					</Grid>
					<Grid
						container
						className={styles.uploadFiles}
						sx={{
							display: 'flex',
							gap: { xs: '20px', md: '10px', lg: '0px' },
							flexDirection: { md: 'column', lg: 'row' },
						}}
					>
						{!state?.params?.isEditing &&
							(cardsUploadFiles || []).map((e: any) => (
								<Fragment key={e.id}>
									<FileButton
										element={e}
										onChange={handleFile}
										value={
											e.multiple
												? (
														data?.files.find(
															(element) =>
																element.type ===
																DOCS_TYPES.PDF
														)?.type || ''
												  ).length > 0
													? 'Archivos'
													: ''
												: (
														data?.files.find(
															(element) =>
																element.type ===
																DOCS_TYPES.JPG
														)?.type || ''
												  ).length > 0
												? 'Foto'
												: ''
										}
									/>
								</Fragment>
							))}
						<Grid
							item
							xs={6}
							md={8}
							lg={2}
							sx={{
								display: 'flex',
								justifyContent: { lg: 'flex-end' },
								alignItems: 'center',
							}}
						>
							<Button
								label={state?.params.isEditing ? 'Guardar' : 'Continuar'}
								variant="solid"
								style={{
									width: '100%',
									backgroundColor: '#0080F9',
									padding: '20px',
								}}
								onClick={handleCreateProject}
								disabled={disabledButton}
							/>
						</Grid>
					</Grid>
				</>
			</LayoutNewProject>
		</>
	);
}

export default FormNewProject;
