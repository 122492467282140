/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSummaryDetail } from './useSummaryDetail';
import { useDispatch, useSelector } from 'react-redux';
import { getTableColumns } from '../../../utils/tableInfo';
import { setLoading } from '../../../store/modules/loading';
import { IParamsSupervisionDetail } from '../interfaces/supervision-detail.interface';

export function useSupervisionDetail() {
	const { state } = useLocation() as IParamsSupervisionDetail;
	const dispatch = useDispatch();

	const { functionalBase, functionalParentBase } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [columnsActivities] = useState(
		getTableColumns(
			functionalBase
				? [
						'Actividades Supervisadas',
						functionalParentBase?.name || 'Nivel 1',
						functionalBase?.name || 'Nivel 2',
				  ]
				: ['Actividades Supervisadas', functionalParentBase?.name || 'Nivel 1']
		)
	);

	useEffect(() => {
		if (!state) {
			navigate(-1);
		}
		dispatch(setLoading({ loading: true }));
		handleGetDetails(state.params.idSupervision);
	}, []);

	const {
		dataActivites,
		graphic,
		supervisor,
		routine,
		navigate,
		setDataActivities,
		setSupervisor,
		handleGetDetails,
	} = useSummaryDetail();

	return {
		state,
		dataActivites,
		supervisor,
		graphic,
		routine,
		columnsActivities,
		setDataActivities,
		setSupervisor,
	};
}
