import { ITable } from '../../../interfaces/criterios.interface';

export const DefaultTabItems = [
	{
		label: 'Creación de frecuencias',
		value: 0,
		isDisabled: false,
	},
	{
		label: 'Frecuencia de servicios',
		value: 1,
		isDisabled: false,
	},
	{
		label: 'Frecuencia por unidad funcional',
		value: 2,
		isDisabled: true,
	},
];

export const defaultCreacion: ITable[] = [
	{
		id: 0,
		isComplete: false,
		label: 'Frecuencia ',
		rows: [
			{
				key: 'name',
				value: '',
			},
			{
				key: 'repeat_day',
				value: '',
			},
			{
				key: 'repeat_month',
				value: '',
			},
			{
				key: 'is_recurrent',
				value: false,
			},
		],
	},
];

export const defaultDias: ITable[] = [
	{
		id: 0,
		isComplete: false,
		label: 'Día no laborable ',
		rows: [
			{
				key: 'day',
				value: '',
			},
			{
				key: 'month',
				value: '',
			},
		],
	},
];

export interface IFrecuenciaService extends ITable {
	//frecuencia: TFrecuencia;
	frecuencia: string;
}

export type TFrecuenciaServices = IFrecuenciaService[];
