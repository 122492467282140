import styles from './styles/inicio-page-styles.component.module.css';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import EmotionStyled from '@emotion/styled';
import { IInicio } from '../interfaces/creation-page-interfaces';
import ModalImage from '../assets/svg/ModalImage.svg';

const ContainedButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: 'white',
	textTransform: 'none',
	backgroundColor: 'var(--color-secondary-main)',
	'&:hover': {
		backgroundColor: 'var(--color-secondary-main)',
	},
	theme,
}));

const TextButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: 'var(--color-secondary-main)',
	textTransform: 'none',
	fontWeight: 600,
	theme,
}));

const Title = EmotionStyled.h3`
	color: var(--color-black-title);
	font-weight: bold;
	font-family: Arial;
	font-size: 20px;
`;

const Description = EmotionStyled.p`
	margin-top: 0; 
	color: var(--color-grey-text);
`;

const Inicio = (props: IInicio) => {
	const {
		title,
		description,
		leftButtonLabel,
		rightButtonLabel,
		sxLeftBtn,
		sxRightBtn,
		leftBtnVariant,
		rightBtnVariant,
		onLeftButtonClick,
		onRightButtonClick,
		isLeftBtn = true,
		isRightBtn = true,
	} = props;
	return (
		<Paper sx={{ flexGrow: 1 }}>
			<Grid
				container
				spacing={2}
				columnGap={2}
				sx={{ alignItems: 'center', textAlign: 'center', padding: '10% 20px' }}
				justifyContent={'center'}
			>
				<Grid item xs={12}>
					<img src={ModalImage} alt="criterios generales" />
				</Grid>
				<Grid item xs={12}>
					<Title>{title}</Title>
				</Grid>
				<Grid item xs={12}>
					<Description>{description}</Description>
				</Grid>
				{isLeftBtn && (
					<Grid item className={styles.buttonLeft}>
						<TextButton
							variant={leftBtnVariant || 'outlined'}
							sx={sxLeftBtn}
							onClick={onLeftButtonClick}
						>
							{leftButtonLabel}
						</TextButton>
					</Grid>
				)}
				{isRightBtn && (
					<Grid item className={styles.buttonRight}>
						<ContainedButton
							variant={rightBtnVariant || 'contained'}
							sx={sxRightBtn}
							onClick={onRightButtonClick}
							style={{ margin: '0px' }}
						>
							{rightButtonLabel}
						</ContainedButton>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};

export default Inicio;
