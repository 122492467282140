import { SelectDate } from '../../../../../components/select-date.component';
import { Button } from '../../../../../components/main-button.component';
import { useStepOne } from '../hooks/useStepOne';
import { Grid } from '@mui/material';

export const StepOneSchedule = () => {
	const { date, matches, handleOnContinue, navigate, setDate } = useStepOne();

	return (
		<Grid
			container
			marginTop={2}
			spacing={2}
			style={{
				padding: '8px 0px 0px 8px',
			}}
		>
			<Grid item>
				<SelectDate value={date} setValueDate={setDate} withMinDate />
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'flex-end'}
					spacing={2}
				>
					<Grid item>
						<Button
							label="Cancelar"
							onClick={() => navigate(-1)}
							style={{
								backgroundColor: 'var(--color-error-main)',
								width: '150px',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Finalizar"
							onClick={() => {
								//dispatch(setState({ state: 6 }));
								handleOnContinue();
							}}
							style={{
								backgroundColor: 'var(--color-highlight-blue)',
								width: '150px',
							}}
							disabled={!date}
							id="finish-schedule"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
