/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { stopLoading } from '../../../../../store/modules/loading';
import { activitiesAdapter } from '../adapters/get-activities.adapter';
import { TSpatialUnit } from '../../../interfaces/get-supervision.interfaces';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { IGetActivitiesResp } from '../interfaces/get-activities.interfaces';
import { setAlert } from '../../../../../store/modules/snackbar';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';

interface IUseGetActivities {
	setActivitiesChecked?: React.Dispatch<React.SetStateAction<string[]>>;
	getOptions?: boolean;
}

export function useGetActivities({
	setActivitiesChecked,
	getOptions = false,
}: IUseGetActivities) {
	const dispatch = useDispatch();
	const [activities, setActivities] = useState<TSpatialUnit[]>([]);
	const [activitiesOptions, setActivitiesOptions] = useState<IOptionsMenu[]>([]);

	const {
		execute: getActivities,
		status: statusActivities,
		value: valueActivities,
	} = useApiRequest<unknown, IResponsePayload<IGetActivitiesResp>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.GET_ACTIVITIES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusActivities === 'success') {
			dispatch(stopLoading());
			setActivities(activitiesAdapter(valueActivities?.data || []));
			getOptions ? getValuesOptions() : getValuesSelected();
		} else if (statusActivities === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusActivities]);

	const getValuesSelected = (): void => {
		const activitiesChecked = valueActivities?.data?.filter(
			(e) => e.applies === true
		);
		if (setActivitiesChecked) {
			setActivitiesChecked(
				activitiesChecked?.map((e) => {
					return e.activity;
				}) || []
			);
		}
	};

	const getValuesOptions = (): void => {
		setActivitiesOptions(
			valueActivities?.data?.map((e) => {
				return {
					id: e._id,
					label: e.description,
					value: e.activity,
					status: e.isFunctionalAssociated,
				};
			}) || []
		);
	};

	return { activities, activitiesOptions, getActivities };
}
