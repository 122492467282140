import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { GenerateReport } from './components/generate-report.component';
import { ReportsMainComponent } from './components/reports-main.component';
import { SuccessfulReport } from './components/successful-report.component';
import { ViewReports } from './components/view-reports.component';

export const ReportsComponent = () => {
	const planningProject = useSelector((state: RootState) => state.profileProject);

	return (
		<>
			{planningProject.stepReporteo === 0 && <ReportsMainComponent />}
			{planningProject.stepReporteo === 1 && <GenerateReport />}
			{planningProject.stepReporteo === 2 && <SuccessfulReport />}
			{planningProject.stepReporteo === 3 && <ViewReports />}
		</>
	);
};
