import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../../components/header-edit.component';
import ActividadesIcon from '../../../assets/icons/actividades.icon';
import { useFormEditActivities } from './hooks/useFormEditActivities';
import { Button } from '../../../components/main-button.component';
import VerticalStepper from '../../../components/vertical-stepper-fixed.component';
import styles from './styles/stepper-styles.module.css';
import DashedBtn from '../../../components/dashed-button.component';
import { EditItemRow } from '../../../components/edit-item-row.component';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import { SelectFrequency } from './select-frequency.component';

interface IFormActivitites {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentActivity: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
}

export const FormEditActivities = ({ setStep, setCurrentActivity }: IFormActivitites) => {
	const {
		activitiesOptions,
		matches,
		modal,
		handleNewActivity,
		navigate,
		onSave,
		onDelete,
		onEdit,
		setModal,
	} = useFormEditActivities({ setStep, setCurrentActivity });

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					<HeaderEdit
						section="Actividades"
						title="1. Nombra tus actividades"
						alertText="A continuación podrás agregar o editar tus actividades."
						icon={<ActividadesIcon size="23px" />}
					/>
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={activitiesOptions}
							renderItem={(item, column) => (
								<EditItemRow
									item={item}
									column={column}
									onEdit={onEdit}
									onSave={(value) =>
										setModal({
											...modal,
											open: true,
											title: value,
										})
									}
									onDelete={onDelete}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<DashedBtn
							onClick={() => handleNewActivity()}
							width="100"
							text="Agregar actividad"
							disabled={!activitiesOptions.every((element) => element.id)}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Salir"
							onClick={() => navigate(-1)}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
						/>
					</Grid>
				</Grid>
				<SelectFrequency modal={modal} setModal={setModal} onSave={onSave} />
			</Box>
		</Paper>
	);
};
