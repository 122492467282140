/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants/urls.constants';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { IUser } from '../../../interfaces/user.interface';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useMediaQuery } from '@mui/material';
import theme from '../../../config/theme';
import { IStats } from '../interfaces/stats.interface';
import { resumeStatsAdapter } from '../adapter/resume-stats-adapter';

interface IUseGetStats {
	user: IUser;
}

export function useGetStats({ user }: IUseGetStats) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [data, setData] = useState<{
		totalSupervisionsFinished: number;
		averageTimePerSupervision: number;
		totalReportsSubmitted: number;
		totalProjectsSupervised: number;
		resume: { totalTime: number; totalTasks: number; projects: string[] };
	}>({
		totalSupervisionsFinished: 0,
		averageTimePerSupervision: 0,
		totalReportsSubmitted: 0,
		totalProjectsSupervised: 0,
		resume: { totalTime: 0, totalTasks: 0, projects: [] },
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStats({}, `?user=${user._id}`);
	}, []);

	const {
		execute: getStats,
		status: statusStats,
		value: dataStats,
	} = useApiRequest<unknown, IResponsePayload<IStats>>({
		path: URLS.USERS.STATS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusStats === 'success') {
			dispatch(stopLoading());
			setData({
				totalSupervisionsFinished:
					dataStats?.detail?.totalSupervisionsFinished || 0,
				averageTimePerSupervision:
					dataStats?.detail?.averageTimePerSupervision || 0,
				totalReportsSubmitted: dataStats?.detail?.totalReportsSubmitted || 0,
				totalProjectsSupervised: dataStats?.detail?.totalProjectsSupervised || 0,
				resume: resumeStatsAdapter(dataStats?.detail),
			});
		} else if (statusStats === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consulta el usuario',
					severity: 'error',
				})
			);
			navigate(-1);
			dispatch(stopLoading());
		}
	}, [statusStats]);

	return { data, matches };
}
