export interface IProject {
	_id?: string;
	name: string;
	shortName: string;
	contractNumber: string;
	location: string;
	contractStartDate: string;
	contractEndDate: string;
	supervisionStartDate: string;
	description: string;
	step: string;
	typeLoadProject: string;
	files: IDocument[];
	functionalBase: IBaseModel | IIndicatorModel | undefined;
	functionalParentBase: IBaseModel | IIndicatorModel | undefined;
	activityBase: IBaseModel | IIndicatorModel | undefined;
	generalIndicatorBase: IBaseModel | IIndicatorModel | undefined;
	specificIndicatorBase: IBaseModel | IIndicatorModel | undefined;
	automaticStep?: string;
	coverImage?: string;
	typeFindings?: string[];
	labelN1?: string;
	labelN2?: string;
	levels: number;
	nextChangesApplicationDate?: string;
}

export interface IBaseModel {
	_id?: string;
	name: string;
	mainAttribute: string;
	attributes: string[];
	columns: any[];
	project?: string; //The id of the project
}

export interface IIndicatorModel {
	name: string;
	mainAttribute: string;
	attributes: string[];
}

export interface IIndicador extends IBaseModel {
	isGeneral: boolean;
	_idSupervisionUnit: string;
}

export interface IFunctional extends IBaseModel {
	functional?: string | null;
	isFunctionalAssociated?: boolean;
	isFrequencyAssociated?: boolean;
}

export interface IFunctionalResponse {
	isFrequencyAssociated: boolean;
	isIndicatorAssociated: boolean;
	_id: string;
	name: string;
	mainAttribute: string;
	functional: string | null;
	isFunctionalAssociated: boolean;
	attributes: string[];
	columns: string[];
}

export interface IActivity extends IBaseModel {
	isGeneralAssociated?: boolean;
	isSpecificAssociated?: boolean;
	isFunctionalAssociated?: boolean;
}

export interface IDocument {
	_id?: string;
	isCoverImage?: boolean;
	projectId: string;
	type: string;
	url: string;
	name: string;
	size: string;
	modifiedDate: string;
	createdAt?: string;
	description: string;
}

export const defaultProjectBase: IProject = {
	name: '',
	shortName: '',
	contractNumber: '',
	location: '',
	contractStartDate: new Date().toISOString(),
	contractEndDate: new Date().toISOString(),
	supervisionStartDate: '',
	description: '',
	step: '0.0.0',
	typeLoadProject: '',
	automaticStep: '0.0.0',
	functionalBase: undefined,
	functionalParentBase: undefined,
	activityBase: undefined,
	generalIndicatorBase: undefined,
	specificIndicatorBase: undefined,
	files: [],
	levels: 0,
};

export interface ITask {
	_id?: string;
	projectId: string;
	type: string;
	space: string;
	name: string;
	activities: string;
	criterion: string;
	supervisor: string;
	supervisorName: string;
	team: string;
	teamName: string;
	executionDate: string;
	routine: string;
	supervisionRoutine: string;
	canBeExecuted: boolean;
}
