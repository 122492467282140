import React from 'react';

interface IProps {
	size?: string;
}
function Icon(props: IProps) {
	const { size = '25.902px' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 25.902 25.902"
		>
			<path
				fill="#fff"
				d="M14.164 28v-2.215a10.711 10.711 0 01-9.847-9.847H2.1v-1.774h2.218a10.711 10.711 0 019.847-9.847V2.1h1.774v2.218a10.711 10.711 0 019.847 9.847H28v1.774h-2.215a10.711 10.711 0 01-9.847 9.847V28zm.887-3.962a8.652 8.652 0 006.343-2.646 8.652 8.652 0 002.646-6.343 8.652 8.652 0 00-2.646-6.343 8.652 8.652 0 00-6.343-2.646 8.652 8.652 0 00-6.342 2.649 8.652 8.652 0 00-2.646 6.343 8.652 8.652 0 002.646 6.343 8.652 8.652 0 006.342 2.645zm0-4.554a4.392 4.392 0 01-4.435-4.435 4.392 4.392 0 014.435-4.435 4.392 4.392 0 014.435 4.435 4.392 4.392 0 01-4.435 4.435zm0-1.774a2.676 2.676 0 001.892-4.554 2.676 2.676 0 00-3.785 3.785 2.572 2.572 0 001.893.772zm0-2.659z"
				transform="translate(-2.1 -2.1)"
			></path>
		</svg>
	);
}

export default Icon;
