// Dependencies
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

// Resources
import { RootState } from '../store';

// Components
import ModalLoading from '../components/loading.component';
import ProtectedRoute from './protected';
/* Pages */
import LoginPage from '../pages/login';
import CustomSnackbar from '../components/custom-snack-bar.component';
import ResetPassword from '../pages/reset-password';
import { getRoutes } from './utils/transform-routes.utils';
import { getRouteList } from './routes';

export const Router = () => {
	const { isLogged, role } = useSelector((state: RootState) => state.users);
	const routes: Array<{
		component: () => JSX.Element;
		link: string;
	}> = [];
	return (
		<>
			<Routes>
				<Route
					path="*"
					element={isLogged ? <Navigate to="inicio" /> : <LoginPage />}
				/>
				<Route
					path="/"
					element={isLogged ? <Navigate to="inicio" /> : <LoginPage />}
				/>
				<Route path="/cambio-contrasenia" element={<ResetPassword />} />
				{getRoutes(getRouteList(role), undefined, routes).map((route, i) => (
					<Route
						key={i}
						path={route.link}
						element={<ProtectedRoute component={route.component} />}
					/>
				))}
			</Routes>
			<CustomSnackbar />
			<ModalLoading />
		</>
	);
};
