/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants/urls.constants';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { IUser } from '../../../interfaces/user.interface';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { usersAdapter } from '../adapters/users.adapter';

export function useUsersPage() {
	const { data, setData, pagination, rowsPerPage, filters, handleGetFiltersParams } =
		useTableCustom<Object>();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		execute: getUsers,
		status: statusUsers,
		value: dataUsers,
	} = useApiRequest<unknown, IResponsePayload<IUser>>({
		path: URLS.USERS.GET_USERS,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getUsers(
			null,
			`?page=${pagination}&recordsByPage=${rowsPerPage}${handleGetFiltersParams()}`
		);
	}, [pagination, rowsPerPage, filters]);

	useEffect(() => {
		if (statusUsers === 'success') {
			setData({
				...data,
				countRows: dataUsers?.totalRecords || 0,
				rows: usersAdapter(dataUsers?.data || [], handleClickEdit),
			});
			dispatch(stopLoading());
		} else if (statusUsers === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los usuarios',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUsers]);

	const handleClickEdit = (user: IUser) => {
		navigate('perfil-usuario', {
			state: user._id,
		});
	};

	return { data, setData, navigate };
}
