/* eslint-disable react-hooks/exhaustive-deps */
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest } from '../../../../hooks/useApiRequest';
import { ITypeFindingsData } from '../interfaces/get-type-findings.interface';

export function useGetTypeFindings() {
	const {
		execute: executeGetTypeFindings,
		status: statusGetTypeFindings,
		value: valueGetTypeFindings,
	} = useApiRequest<unknown, ITypeFindingsData>({
		path: URLS.PROJECT.GET_TYPE_FINDINGS,
		method: 'GET',
	});

	return {
		executeGetTypeFindings,
		valueGetTypeFindings,
		statusGetTypeFindings,
	};
}
