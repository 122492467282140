import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../../../components/header-edit.component';
import InputSelect from '../../../../components/select-input.component';
import { Button } from '../../../../components/main-button.component';
import VerticalStepper from '../../../../components/vertical-stepper-fixed.component';
import styles from '../schedule-activity/components/styles/view-activities-styles.module.css';
import { useEditScheduleByIndicator } from './hooks/useScheduleByIndicator';
import { RowItemIndicator } from './row-info-indicator.component';
import CronogramaIcon from '../../../../assets/icons/cronograma.icon';

export const EditScheduleByIndicator = () => {
	const {
		frequenciesOptions,
		functionalsOptions,
		functionalLvl1,
		functionalLvl2,
		activity,
		functionalsOptionsLvl2,
		activitiesOptionsSelect,
		indicatorsOptions,
		labelN1,
		labelN2,
		levels,
		matches,
		navigate,
		setFunctionalLvl1,
		setFunctionalLvl2,
		setActivity,
		handleFrequency,
	} = useEditScheduleByIndicator();

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={3} alignItems="center">
					<HeaderEdit
						section="Cronograma"
						title="Selecciona la frecuencia de tus servicios"
						alertText="A continuación podrás editar la frecuencia de las actividades."
						icon={<CronogramaIcon size="23px" />}
					/>
					<Grid
						item
						xs={12}
						lg={6}
						display={'flex'}
						alignItems={'center'}
						gap={3}
					>
						<Grid item xs={2}>
							<span style={{ fontWeight: 'bold' }}>
								Elige {labelN1 || 'nivel 1'}:
							</span>
						</Grid>
						<Grid item xs>
							<InputSelect
								value={functionalLvl1}
								optionsMenu={functionalsOptions}
								onChange={(e) => {
									setFunctionalLvl1(e?.target?.value?.toString() || '');
								}}
								placeholder="Selecciona"
								id="select-lvl1"
							/>
						</Grid>
					</Grid>
					{levels > 1 && (
						<Grid
							item
							xs={12}
							lg={6}
							display={'flex'}
							alignItems={'center'}
							gap={3}
						>
							<Grid item xs={2}>
								<span style={{ fontWeight: 'bold' }}>
									Elige {labelN2 || 'nivel 2'}:
								</span>
							</Grid>
							<Grid item xs>
								<InputSelect
									value={functionalLvl2}
									optionsMenu={functionalsOptionsLvl2}
									onChange={(e) => {
										setFunctionalLvl2(
											e?.target?.value?.toString() || ''
										);
									}}
									placeholder="Selecciona"
									id="select-lvl2"
								/>
							</Grid>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						lg={6}
						display={'flex'}
						alignItems={'center'}
						gap={3}
					>
						<Grid item xs={2}>
							<span style={{ fontWeight: 'bold' }}>Elige servicio:</span>
						</Grid>
						<Grid item xs>
							<InputSelect
								value={activity}
								optionsMenu={activitiesOptionsSelect}
								onChange={(e) => {
									setActivity(e?.target?.value?.toString() || '');
								}}
								placeholder="Selecciona"
								id="select-activity"
							/>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							rootStyle={{ overflowX: 'scroll', width: '100%' }}
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={indicatorsOptions}
							renderItem={(item, column) => (
								<RowItemIndicator
									item={item}
									column={column}
									frequenciesOptions={frequenciesOptions}
									handleUpdateFrequency={handleFrequency}
								/>
							)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						display={'flex'}
						justifyContent={matches ? 'center' : 'end'}
					>
						<Grid item>
							<Button
								label="Regresar"
								onClick={() => navigate(-1)}
								variant="outlined"
								style={{
									backgroundColor: ' #0080F9',
									width: '200px',
									color: 'white',
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
