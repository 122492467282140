import { RemoveRedEye } from '@mui/icons-material';
import ButtonIconSmall from '../../../../../components/button-icon-small.component';
import { getDateLocalMX } from '../../../../../utils/dates';

export const serviceViewAdapter = (
	data: any,
	functionView: (item: any) => void
): any[] => {
	const newData = data.map((actividad: any) => {
		return {
			...actividad,
			date: getDateLocalMX(actividad.executionDate),
			assignedTo: actividad.supervisorName || actividad.teamName,
			acciones: (
				<ButtonIconSmall
					text="Ver"
					type="info"
					icon={<RemoveRedEye />}
					style={{ fontSize: '11px' }}
					onPress={() => functionView(actividad)}
				/>
			),
		};
	});
	return newData;
};
