/* eslint-disable react-hooks/exhaustive-deps */

import { useDispatch } from 'react-redux';
import { setAlert } from '../store/modules/snackbar';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import theme from '../config/theme';
import { IAdaptedValues } from '../interfaces/edit-adapted-values';

interface IUseCreateBase {
	attributes: string[];
	setAttributes: React.Dispatch<React.SetStateAction<string[]>>;
	mainAttribute: string;
	item: IAdaptedValues;
}

export const useCreateBaseRow = ({
	attributes,
	mainAttribute,
	setAttributes,
	item,
}: IUseCreateBase) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [value, setValue] = useState(item.description || '');
	const dispatch = useDispatch();

	const handleAttributes = () => {
		if (!value) return;
		if (attributes.find((e) => e === value) || value === mainAttribute) {
			dispatch(
				setAlert({
					show: true,
					message: `El atributo '${value}' está repetido.`,
					severity: 'error',
				})
			);
			return;
		}
		setAttributes([...attributes, value.replace(/\s/g, '')]);
		setValue('');
	};

	const handleDeleteAttributes = (value: string) => {
		const newAttributes = attributes.filter((e) => e !== value);
		setAttributes(newAttributes);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			if (item.id === '2') handleAttributes();
		}
	};

	return {
		matches,
		value,
		handleAttributes,
		handleDeleteAttributes,
		setValue,
		handleKeyPress,
	};
};
