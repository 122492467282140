/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../../config/theme';

export const useEditNonWorkingDays = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const matches = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	return {
		matches,
		navigate,
	};
};
