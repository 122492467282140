/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { AreaMain } from './components/area/area-main.component';
import { RoutineMain } from './components/routine/routine-main.component';
import { ServiceMain } from './components/service/service-main.component';
import { SupervisionHistoryComponent } from './components/supervision-history.component';

export const SupervisionHistory = () => {
	const [option, setOption] = useState('');

	return (
		<>
			{option === '' && <SupervisionHistoryComponent setOption={setOption} />}
			{option.toString() === '0' && <RoutineMain setOption={setOption} />}
			{option.toString() === '1' && <AreaMain setOption={setOption} />}
			{option.toString() === '2' && <ServiceMain setOption={setOption} />}
		</>
	);
};
