/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { RootState } from '../../../../../store';
import { useGetDocuments } from './useGetDocuments';
import { useSaveDocuments } from './useSaveDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../../store/modules/loading';
import { IDocument } from '../../../../../interfaces/project.interface';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';
import { resetState } from '../../../../../store/modules/steps';

export const useDocumentation = () => {
	const step = useSelector((state: RootState) => state.stateStep);
	const { data, setData } = useContext(ProfileProjectContext);
	const dispatch = useDispatch();

	const { executeSaveDocuments } = useSaveDocuments();
	const { dataTable, setDataTable, executeGetDocuments } = useGetDocuments();

	const saveProjectDocuments = () => {
		if (data?.files?.length === 0) return dispatch(resetState());
		dispatch(setLoading({ loading: true }));
		executeSaveDocuments({ files: [...data.files] }, `/${data._id}`);
	};

	const deleteDocumentLocal = (document: IDocument) => {
		setData({
			...data,
			files: data.files.filter((doc: IDocument) => doc.name !== document.name),
		});
	};

	useEffect(() => {
		if (step.state === 0) {
			dispatch(setLoading({ loading: true }));
			executeGetDocuments(
				{},
				`?page=${dataTable.pagination}&recordsByPage=${dataTable.rowsPerPage}&project=${data._id}`
			);
		}
	}, [dataTable.pagination, dataTable.rowsPerPage, step.state]);

	return {
		data,
		step,
		dataTable,
		setDataTable,
		dispatch,
		deleteDocumentLocal,
		saveProjectDocuments,
	};
};
