/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../config/theme';
import { useGetIG } from './useGetIG';
import { SplitArrayInNumbers } from '../../../../utils/splitArray';
import { useGetIGbyActivity } from './useGetIGByActivity';
import { useUpdateIGsActivity } from './useUpdateIndicators';
import { saveNewIGsAdapter, updateSavedIGsAdapter } from '../adapters/update-IGs-adapter';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';

interface IUseFormActivitites {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	currentActivity: IAdaptedValues;
}

export const useEditIG = ({ setStep, currentActivity }: IUseFormActivitites) => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const { IGsList, valueGetIGs } = useGetIG();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [matrixIGs, setMatrixIGs] = useState<{ _id: string; value: string }[][]>([]);

	const { checkedList, valueGetIGsByActivity, setCheckedList, setValueIGsByActivity } =
		useGetIGbyActivity({
			idActivity: currentActivity.id,
		});

	const { handleUpdateIGsActivity } = useUpdateIGsActivity({
		setCheckedList,
		setValueIGsByActivity,
	});

	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	useEffect(() => {
		setMatrixIGs(SplitArrayInNumbers(IGsList, 10));
	}, [IGsList]);

	const selectColumn = (column: number) => {
		const collection = new Set(checkedList);
		for (const item of matrixIGs[column]) {
			collection.add(String(item._id));
		}
		setCheckedList(collection);
	};

	const onSelect = (id: string, checked: boolean) => {
		const collection = new Set(checkedList);

		if (checked) collection.add(id);
		else collection.delete(id);

		setCheckedList(collection);
	};

	const onSave = () => {
		const newInfoIGs =
			(valueGetIGsByActivity?.data?.length || 0) > 0
				? updateSavedIGsAdapter(valueGetIGsByActivity?.data || [], checkedList)
				: saveNewIGsAdapter(
						valueGetIGs?.data || [],
						checkedList,
						currentActivity.id,
						idProject
				  );
		handleUpdateIGsActivity({ supervisionUnits: newInfoIGs || [] });
	};

	return {
		matrixIGs,
		matches,
		checkedList,
		navigate,
		selectColumn,
		onSelect,
		onSave,
	};
};
