import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { ProgressBar } from '../../../../../components/progress-bar.component';
import styles from './styles/tasks-styles.module.css';

interface ITaskComponent {
	value: number;
	activeTasks: number;
	pendingTasks: number;
}

export const TasksComponents = ({ value, activeTasks, pendingTasks }: ITaskComponent) => {
	return (
		<Paper elevation={0} style={{ backgroundColor: '#f8f8f8', height: '100%' }}>
			<Box padding={2}>
				<div className={styles.container}>
					<div className={styles.activeTasksContainer}>
						<span className={styles.activeTasks}>{activeTasks}</span>
						<span className={styles.label}>Tareas completadas</span>
					</div>
					<div className={styles.pendingTasksContainer}>
						<span>{pendingTasks} completadas</span>
						<span>{`${Math.round(value)}%`}</span>
					</div>
					<ProgressBar type="linear" value={Number(value)} />
				</div>
			</Box>
		</Paper>
	);
};
