import { Button } from '../../../../../components/main-button.component';
import { InfoCards } from '../../info-cards.component';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import theme from '../../../../../config/theme';
import {
	BoltRounded,
	CalendarMonthOutlined,
	PeopleAltOutlined,
} from '@mui/icons-material';
import { personalList, scheduleList } from '../utils/actions.utils';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setValuePP } from '../../../../../store/modules/profile-project';

export const EditList = () => {
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'space-between'}
					alignItems="center"
				>
					<Grid item>
						<h3>Edición de supervisión</h3>
					</Grid>
					<Grid item>
						<Button
							label="Regresar"
							onClick={() => dispatch(setValuePP({ step: 0 }))}
							style={{ width: '150px', backgroundColor: '#0080F9' }}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12} md={6} xl={3}>
				<span
					style={{ cursor: 'pointer' }}
					onClick={() => dispatch(setValuePP({ step: 2 }))}
				>
					<InfoCards icon={BoltRounded} label="Edición rápida" actions />
				</span>
			</Grid>
			<Grid item xs={12} md={6} xl={3}>
				<InfoCards
					icon={CalendarMonthOutlined}
					label="Cronograma"
					actions
					actionsList={scheduleList}
				/>
			</Grid>
			<Grid item xs={12} md={6} xl={3}>
				<InfoCards
					icon={PeopleAltOutlined}
					label="Personal"
					actions
					actionsList={personalList}
				/>
			</Grid>
		</Grid>
	);
};
