/* eslint-disable react-hooks/exhaustive-deps */
import styles from './index.module.css';
import { useDispatch } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import { useContext, useEffect } from 'react';
import { setAlert } from '../../store/modules/snackbar';
import { stopLoading } from '../../store/modules/loading';
import { Button } from '../../components/main-button.component';
import confirmationImage from '../../assets/imgs/confirmation.png';
import { SupervisionProjectContext } from '../supervision-project/contexts/useSupervisionProjectContext';

export const SupervisionAction = () => {
	const {
		supervisionAction,
		statusChangeStatus,
		statusCompleteSupervision,
		valueCompleteSupervision,
		undoState,
		setNewState,
	} = useContext(SupervisionProjectContext);

	const dispatch = useDispatch();

	useEffect(() => {
		if (statusChangeStatus === 'success') {
			undoState();
		} else if (statusChangeStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusChangeStatus]);

	useEffect(() => {
		if (statusCompleteSupervision === 'success') {
			setNewState(3);
			dispatch(stopLoading());
		} else if (statusCompleteSupervision === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueCompleteSupervision?.message ||
						'Ha ocurrido un error, vuelva a intentar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusCompleteSupervision]);

	return (
		<Paper>
			<Grid container spacing={2} style={{ height: '80vh' }}>
				<Grid item xs={12}>
					<div className={styles.mainContainer}>
						<div className={styles.infoContainer}>
							<img
								className={styles.img}
								src={confirmationImage}
								alt="actions"
							/>
							<h3>{supervisionAction.descriptionLabel}</h3>
							<div className={styles.buttonsContainer}>
								<Button
									label="Cancelar"
									onClick={() => supervisionAction.cancelActionButton()}
									style={{
										width: '100%',
									}}
									id="cancel-btn"
								/>
								<Button
									label={supervisionAction.labelButton}
									onClick={() =>
										supervisionAction.continueActionButton()
									}
									variant="solid"
									style={{
										width: '100%',
										backgroundColor: '#0080F9',
									}}
									id="continue-btn"
								/>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};
