/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { URLS } from '../../../constants/urls.constants';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { INotification } from '../interfaces/notifications.interface.utils';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { RootState } from '../../../store';
import { notificationsAdapter } from '../adapters/notifications.adapter';
import { setAlert } from '../../../store/modules/snackbar';
import { setNumberNotification } from '../../../store/modules/notifications';
import { IFilter } from '../../../interfaces/filter.interface';
import { Check, FilterList } from '@mui/icons-material';
import { IDeviceNotifications } from '../../devices/interfaces/device.interface';
import { isEmpty } from '../../../utils/validators';
import { IColumns } from '../../../interfaces/table.insterface';
import { isAdmin } from '../../../utils/permissions.utils';
import {
	columnsNotifications,
	columnsNotificationsAdmin,
} from '../utils/columns-notifications.utils';
import { Button } from '../../../components/main-button.component';

export const useNotifications = () => {
	const [filterInputs, setFilterInputs] = useState<IFilter[]>([]);
	const [displayFilterModal, setDisplayFilterModal] = useState<boolean>(false);
	const [dinamicColumns, setDinamicColumns] = useState<IColumns[]>([]);

	const { data, setData, pagination, rowsPerPage, filters, handleGetFiltersParams } =
		useTableCustom<Object>();
	const dispatch = useDispatch();
	const { _id, role } = useSelector((state: RootState) => state.users);

	const {
		execute: executePendingNotifications,
		status: statusPendingNotifications,
		value: valuePendingNotifications,
	} = useApiRequest<unknown, IResponsePayload<number>>({
		path: URLS.NOTIFICATIONS.PENDING_NOTIFICATIONS,
		method: 'GET',
	});

	const {
		execute: executeNotificationsUser,
		status: statusNotificationsUser,
		value: valueNotificationsUser,
	} = useApiRequest<unknown, IResponsePayload<INotification>>({
		path: URLS.NOTIFICATIONS.NOTIFICATIONS_USER,
		method: 'GET',
	});

	const {
		execute: executeNotificationsUserDevices,
		status: statusNotificationsUserDevices,
		value: valueNotificationsUserDevices,
	} = useApiRequest<unknown, IResponsePayload<IDeviceNotifications>>({
		path: URLS.NOTIFICATIONS.NOTIFICATIONS_USER_DEVICES,
		method: 'GET',
	});

	const {
		execute: executeNotificationStatus,
		status: statusNotificationStatus,
		value: valueNotificationStatus,
	} = useApiRequest<Partial<INotification>, IResponsePayload<INotification>>({
		path: URLS.NOTIFICATIONS.NOTIFICATIONS,
		method: 'PUT',
	});

	useEffect(() => {
		if (statusPendingNotifications === 'success') {
			dispatch(
				setNumberNotification({ number: valuePendingNotifications?.detail || '' })
			);
		}
	}, [statusPendingNotifications]);

	useEffect(() => {
		if (statusNotificationsUser === 'success') {
			dispatch(stopLoading());
			setData({
				...data,
				countRows: valueNotificationsUser?.totalRecords || 0,
				rows: notificationsAdapter(
					valueNotificationsUser?.data || [],
					changeNotificationStatus
				),
			});
			setDinamicColumns(
				!isAdmin(role)
					? [
							...columnsNotifications,
							{
								text:
									(valueNotificationsUser?.totalRecords || 0) > 0 ? (
										<Button
											label="Marcar todas"
											icon={Check}
											variant="outlined"
											onClick={changeAllNotifications}
											style={{
												color: 'var(--color-secondary-dark)',
												minWidth: '200px',
											}}
										/>
									) : (
										''
									),
								nameID: 'actions',
							},
					  ]
					: [
							...columnsNotificationsAdmin,
							{
								text:
									(valueNotificationsUser?.totalRecords || 0) > 0 ? (
										<Button
											label="Marcar todas"
											icon={Check}
											variant="outlined"
											onClick={changeAllNotifications}
											style={{
												color: 'var(--color-secondary-dark)',
												minWidth: '200px',
											}}
										/>
									) : (
										''
									),
								nameID: 'actions',
							},
					  ]
			);
		} else if (statusNotificationsUser === 'error') {
			dispatch(stopLoading());
		}
	}, [statusNotificationsUser]);

	useEffect(() => {
		if (statusNotificationStatus === 'success') {
			dispatch(stopLoading());
			getNotificationsUserDevices();
			getNotificationsUser();
			getPendingNotifications();
		} else if (statusNotificationStatus === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					message:
						valueNotificationStatus?.message ||
						'No se ha podido actualizar el estatus',
					severity: 'error',
					show: true,
				})
			);
		}
	}, [statusNotificationStatus]);

	useEffect(() => {
		if (statusNotificationsUserDevices === 'success') {
			setFilterInputs([
				{
					label: 'Dispositivos',
					name: 'name',
					type: 'select',
					options: [
						{ label: 'Selecciona una opción', value: '' },
						...(valueNotificationsUserDevices?.data.map((element) => ({
							label: element.name,
							value: element.deviceIdentifier,
						})) || []),
					],
					value: '',
					md: 12,
					id: 'select_dispositivos',
					placeholder: 'Dispositivos',
				},
				{
					label: 'Filtrar',
					name: 'filtrar',
					value: '',
					type: 'button',
					md: 12,
					propsInput: { backgroundColor: '#BBB7B7 !important' },
					icon: FilterList,
				},
			]);
		}
	}, [statusNotificationsUserDevices]);

	const getPendingNotifications = () => {
		executePendingNotifications();
	};

	const getNotificationsUser = (filterDevice?: { name: string }) => {
		dispatch(setLoading({ loading: true }));
		let url = `/${_id}?page=${pagination}&recordsByPage=${rowsPerPage}${handleGetFiltersParams()}`;
		if (!isEmpty(filterDevice)) {
			url = `${url}&deviceIdentifier=${filterDevice?.name}`;
		}
		executeNotificationsUser(null, url);
	};

	const getNotificationsUserDevices = () => {
		executeNotificationsUserDevices();
	};

	const changeNotificationStatus = (notificationID: string) => {
		dispatch(setLoading({ loading: true }));
		executeNotificationStatus({ state: 'read' }, `/${notificationID}`);
	};

	const changeAllNotifications = () => {
		dispatch(setLoading({ loading: true }));
		executeNotificationStatus({ state: 'read' });
	};

	const handleDisplayFilterModal = () => {
		setDisplayFilterModal(!displayFilterModal);
	};

	return {
		getPendingNotifications,
		getNotificationsUserDevices,
		getNotificationsUser,
		data,
		setData,
		pagination,
		rowsPerPage,
		filters,
		filterInputs,
		handleDisplayFilterModal,
		displayFilterModal,
		dinamicColumns,
	};
};
