import ButtonIconSmall from '../../../components/button-icon-small.component';
import { ITask } from '../../../interfaces/project.interface';
import { RemoveRedEye } from '@mui/icons-material';
import { getRoutineDescriptionByType } from '../../../utils/get-title-routines';
import { ITypeRoutine } from '../../../constants/routines.constants';

export const supervisionExtraordinaryAdapter = (
	data: ITask[],
	viewDetails: (idSupervision: string, idSupervisor: string) => void
): ITask[] => {
	const supervisionExtraordinary = data.map((task) => {
		return {
			...task,
			routineDescription: getRoutineDescriptionByType(
				task.type as keyof ITypeRoutine,
				task.executionDate as unknown as Date
			),
			acciones: (
				<ButtonIconSmall
					text="Ver"
					type="info"
					icon={<RemoveRedEye />}
					onPress={() => viewDetails(task.routine || '', task.supervisor)}
				/>
			),
		};
	});
	return supervisionExtraordinary;
};
