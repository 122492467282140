/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { RootState } from '../../../store';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setValueProject } from '../../../store/modules/project';
import { INTERNAL_MESSAGES } from '../../../constants/internal-messages';
import { AutomaticChargeContext } from '../contexts/useAutomaticChargeContext';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { IResponseConfirmProgress } from '../interfaces/confirm-progress.interface';

export const useConfirmProgress = () => {
	const dispatch = useDispatch();
	const { setStep } = useContext(AutomaticChargeContext);
	const project = useSelector((state: RootState) => state.projectBase);

	const {
		execute: executeConfirmation,
		status: statusConfirmation,
		value: valueConfirmation,
	} = useApiRequest<unknown, IResponsePayload<IResponseConfirmProgress>>({
		path: URLS.AUTOMATIC_CHARGE.CONFIRMATION,
		method: 'POST',
	});

	useEffect(() => {
		if (statusConfirmation === 'success') {
			dispatch(stopLoading());
			if (valueConfirmation?.detail?.newStep) {
				dispatch(
					setValueProject({
						...project,
						automaticStep: valueConfirmation?.detail?.newStep,
					})
				);
				setStep(valueConfirmation?.detail?.newStep);
			}
		} else if (statusConfirmation === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueConfirmation?.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusConfirmation]);

	const confirmProgress = (body?: any) => {
		dispatch(setLoading({ loading: true }));
		executeConfirmation(body ? body : null, `/${project._id}`);
	};

	return { executeConfirmation, confirmProgress };
};
