/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { CameraAltOutlined, UploadFileOutlined } from '@mui/icons-material';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ActionComponent } from '../../components/action.component';
import { Button } from '../../components/main-button.component';
import InputSelect from '../../components/select-input.component';
import TextInput from '../../components/text-input.component';
import theme from '../../config/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useExtraordinaryIncidence } from './hooks/useExtraordinaryIncidence';
import { useCreateExtraordinaryIncidence } from './hooks/useCreateExtraordinaryIncidence';
import { useEvidences } from './hooks/useEvidences';
import CustomCamera from './../../components/custom-camera.component';
import { DragNDrop } from './../../components/drag-drop.component';
import styles from './styles/extraordinary-incidence-styles.module.css';
import CloseIcon from '@mui/icons-material/Close';
import { setAlert } from './../../store/modules/snackbar';
import {
	INewExtraordinaryIncident,
	NewExtraordinaryIncidentKeys,
} from './interfaces/extraordinary-incidence.interface';
import { RootState } from '../../store';

export const ExtraordinaryIncidence = () => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location?.state?.params;
	const { _id } = useSelector((state: RootState) => state?.projectBase);

	const { executeCreateIncidence } = useCreateExtraordinaryIncidence();
	const {
		executeTypesFindings,
		executeGetLevels,
		executeGetFindings,
		executeGetSpaces,
		executeGetActivities,
		executeGetIndicators,
		executeGetSupervisors,
		setSpaces,
		setActivities,
		setIndicators,
		getIsTeam,
		supervisors,
		typesFindings,
		levels,
		findings,
		spaces,
		activities,
		indicators,
	} = useExtraordinaryIncidence();

	const { data, setData, handleFile, setCamera, handlePhoto, camera, deleteFile } =
		useEvidences();

	useEffect(() => {
		executeGetSupervisors();
		executeTypesFindings({}, `?_id=${state?.project || _id}`);
		executeGetLevels({}, `/${state?.project || _id}`);
		executeGetFindings({}, `?project=${state?.project || _id}&isFunctionalParent=1`);
	}, []);

	const { functionalBase } = useSelector((state: RootState) => state.projectBase);

	const handleChangeFilters = (e: any, property: NewExtraordinaryIncidentKeys) => {
		(data[property] as any) = e.target.value;

		if (property === 'functional1') {
			setSpaces([]);
			executeGetSpaces(
				{},
				`?project=${state?.project || _id}&functionalParent=${e.target.value}`
			);
		}
		if (property === 'functional2') {
			setActivities([]);
			executeGetActivities(
				{},
				`?project=${state?.project || _id}&functional=${e.target.value}`
			);
		}
		if (property === 'activity') {
			setIndicators([]);
			executeGetIndicators(
				{},
				`?project=${state?.project || _id}&functional=${
					data.functional2
				}&activity=${e.target.value}`
			);
		}
		if (property === 'findingDescription') {
			data.files.filter((e) => {
				e.description
					? (e.description = data.findingDescription)
					: (e.description = data.findingDescription);
			});
		}
		setData({ ...data });
	};

	const onCreateIncidence = () => {
		const isTeam = getIsTeam(data.supervisor);
		const newData: INewExtraordinaryIncident = {
			findingDescription: data.findingDescription,
			typeFinding: data.typeFinding,
			activity: data.activity,
			supervisor: isTeam ? null : data.supervisor,
			team: isTeam ? data.supervisor : null,
			project: state?.project || _id,
			functional: functionalBase ? data?.functional2 : data?.functional1,
			indicator: data.indicator,
			files: data.files,
		};

		if (
			newData.typeFinding !== '' &&
			newData.supervisor !== '' &&
			newData.functional !== '' &&
			newData.activity !== '' &&
			newData.indicator !== '' &&
			newData.findingDescription !== ''
		)
			executeCreateIncidence({ ...newData }, '');
		else
			dispatch(
				setAlert({
					show: true,
					message: 'Favor de llenar todos los campos en el formulario.',
					severity: 'error',
				})
			);
	};

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={2} justifyContent="flex-end" alignItems="center">
					<Grid item xs={12}>
						<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
							Incidencia extraordinaria
						</span>
						<br />
						<span style={{ fontSize: '18px', color: '#707070' }}>
							Incidencia extraordinaria
						</span>
					</Grid>
					<Grid item xs={12} md={6}>
						<span>Tipo de hallazgo</span>
						<InputSelect
							value={data.typeFinding}
							optionsMenu={typesFindings}
							onChange={(e) => handleChangeFilters(e, 'typeFinding')}
							id="select-typeFinding"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<span>Supervisor</span>
						<InputSelect
							value={data.supervisor}
							optionsMenu={supervisors}
							onChange={(e) => handleChangeFilters(e, 'supervisor')}
							id="select-supervisor"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<span>Asocia el hallazgo a {levels?.[0]}</span>
						<InputSelect
							value={data.functional1}
							optionsMenu={findings}
							onChange={(e) => handleChangeFilters(e, 'functional1')}
							isDisabled={
								data.typeFinding !== '' && findings.length > 0
									? false
									: true
							}
							id="select-functional1"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<span>Asocia el hallazgo a {levels?.[1]}</span>
						<InputSelect
							value={data.functional2}
							optionsMenu={spaces}
							onChange={(e) => handleChangeFilters(e, 'functional2')}
							isDisabled={
								data.functional1 !== '' && spaces.length > 0
									? false
									: true
							}
							id="select-functional2"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<span>Asocia el hallazgo a una actividad</span>
						<InputSelect
							value={data.activity}
							optionsMenu={activities}
							onChange={(e) => handleChangeFilters(e, 'activity')}
							isDisabled={
								data.functional2 !== '' && activities.length > 0
									? false
									: true
							}
							id="select-activity"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<span>Asocia el hallazgo a un criterio</span>
						<InputSelect
							value={data.indicator}
							optionsMenu={indicators}
							onChange={(e) => handleChangeFilters(e, 'indicator')}
							isDisabled={
								data.activity !== '' && indicators.length > 0
									? false
									: true
							}
							id="select-indicator"
						/>
					</Grid>
					<Grid item xs={12}>
						<span>Descripción de la incidencia</span>
						<TextInput
							value={data.findingDescription}
							id="descripcion"
							name="descripcion"
							placeholder="Escribe, si es necesario, la descripción de la incidencia"
							onChange={(e) => handleChangeFilters(e, 'findingDescription')}
							multiline
							rows={6}
						/>
					</Grid>
					<Grid item xs={12}>
						<span>Evidencias</span>
						<Grid
							container
							spacing={2}
							justifyContent={matches ? 'center' : ''}
						>
							<Grid item>
								{camera ? (
									<CustomCamera
										setImg={handlePhoto}
										stylesCamera={{
											width: '244px',
											height: '221px',
											borderRadius: '10px',
										}}
									/>
								) : (
									<ActionComponent
										icon={CameraAltOutlined}
										label="Tomar foto"
										onClick={() => setCamera(true)}
									/>
								)}
							</Grid>
							<Grid item>
								<DragNDrop
									element={{ accept: 'image/jpeg' }}
									onChange={handleFile}
									icon={
										<div className={styles.dragNDropIconContainer}>
											<UploadFileOutlined
												style={{
													fontSize: '3em',
												}}
											/>
											<span style={{ fontSize: '12px' }}>JPEG</span>
										</div>
									}
									stylesContainer={{
										height: '100%',
										width: matches ? '244px' : '100%',
										padding: '20px',
									}}
									stylesTitle={{
										fontSize: '21px',
										color: '#7f8297',
										fontWeight: 'bold',
									}}
									stylesSubTitle={{ fontSize: '9px', color: '#7f8297' }}
								/>
							</Grid>
						</Grid>
					</Grid>
					{data.files.length > 0 && (
						<Grid item xs={12} marginY={2}>
							<Grid container spacing={2}>
								{data.files.map((element, index) => (
									<Grid
										item
										key={index}
										md={6}
										lg={3}
										style={{
											position: 'relative',
											display: 'flex',
										}}
									>
										<img
											src={`data:image/jpeg;base64,${element.fileBase64}`}
											alt={element.fileName}
											style={{ width: '100%' }}
										/>
										<div
											className={styles.badgeCustom}
											onClick={() => deleteFile(index)}
										>
											<CloseIcon style={{ color: 'white' }} />
										</div>
									</Grid>
								))}
							</Grid>
						</Grid>
					)}
					<Grid item>
						<Button
							label="Cancelar"
							onClick={() => navigate(-1)}
							style={{
								width: '150px',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Continuar"
							onClick={() => onCreateIncidence()}
							variant="solid"
							style={{
								width: '150px',
								backgroundColor: '#0080F9',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
