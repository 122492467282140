import React from 'react';

interface IProps {
	size?: string;
}
function Icon(props: IProps) {
	const { size = '18.38px' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 18.387 12.918"
		>
			<path
				fill="#fff"
				d="M1.9 21.867v-1.955a2.423 2.423 0 01.374-1.321 2.279 2.279 0 011.04-.884 16.491 16.491 0 012.736-.957 10.732 10.732 0 012.506-.291 10.6 10.6 0 012.5.291 16.632 16.632 0 012.725.957 2.341 2.341 0 011.05.884 2.374 2.374 0 01.385 1.321v1.955zm14.56 0v-1.955a3.367 3.367 0 00-.666-2.153 4.872 4.872 0 00-1.747-1.359 20.627 20.627 0 012.7.489 9.531 9.531 0 012.059.738 3.219 3.219 0 011.082.978 2.28 2.28 0 01.4 1.31v1.955zm-7.9-6.677a2.992 2.992 0 01-3.12-3.12 2.992 2.992 0 013.12-3.12 2.992 2.992 0 013.12 3.12 2.992 2.992 0 01-3.12 3.12zm7.488-3.12a2.992 2.992 0 01-3.12 3.12 4.652 4.652 0 01-.51-.031 2.2 2.2 0 01-.51-.114 3.414 3.414 0 00.759-1.279 5.21 5.21 0 00.26-1.7 4.842 4.842 0 00-.26-1.654A4.422 4.422 0 0011.9 9.1a4.39 4.39 0 01.51-.1 3.505 3.505 0 01.51-.042 2.992 2.992 0 013.12 3.12zm-12.9 8.549h10.816v-.707a1.19 1.19 0 00-.2-.645 1.087 1.087 0 00-.489-.437 14.044 14.044 0 00-2.517-.894 10.058 10.058 0 00-2.2-.229 10.2 10.2 0 00-2.215.229 13.921 13.921 0 00-2.527.894 1.032 1.032 0 00-.478.437 1.237 1.237 0 00-.187.645zm5.408-6.677a1.872 1.872 0 100-3.744 1.872 1.872 0 100 3.744zm0 6.677zm0-8.549z"
				transform="translate(-1.9 -8.949)"
			></path>
		</svg>
	);
}

export default Icon;
