import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IOptionsMenu } from '../interfaces/select.interface';
import { getEstatusService } from '../utils/labelStatus';
import stylesSelect from './styles/select-styles.component.module.css';
import { Fragment } from 'react';

interface ISelectProps {
	backgroundColor?: string;
	optionsMenu: IOptionsMenu[];
	onChange: (e: SelectChangeEvent<string | number | null>) => void;
	height?: string;
	placeholder?: string;
	name?: string;
	width?: string;
	value: string | number | undefined | null;
	isDisabled?: boolean;
	withStatus?: boolean;
	id?: string;
}

function InputSelect({
	backgroundColor = '#F7F7F7',
	optionsMenu,
	onChange,
	height = '50px',
	width = '100%',
	value,
	placeholder,
	isDisabled,
	withStatus,
	name,
	id,
}: ISelectProps) {
	const styles = {
		height: height,
		width: width,
		backgroundColor: backgroundColor,
		padding: '0px 10px',
	};

	return (
		<Fragment>
			<Select
				value={value}
				size="small"
				onChange={onChange}
				name={name}
				id={id}
				style={styles}
				fullWidth
				disabled={isDisabled}
				displayEmpty
				renderValue={
					value !== ''
						? undefined
						: () => <span style={{ color: '#8B8B8B' }}>{placeholder}</span>
				}
				inputProps={{ id: `input-${id}` }}
			>
				{optionsMenu?.map((e: any) => (
					<MenuItem key={e.id} value={e.value}>
						<div className={stylesSelect.selectContainer}>
							<span className={stylesSelect.labelSelectContainer}>
								{e.label}
							</span>
							{withStatus && (
								<span className={stylesSelect.statusSelectContainer}>
									{getEstatusService(e.status)}
								</span>
							)}
						</div>
					</MenuItem>
				))}
			</Select>
		</Fragment>
	);
}

export default InputSelect;
