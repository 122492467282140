/* eslint-disable react-hooks/exhaustive-deps */
import { URLS } from '../../../constants/urls.constants';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import {
	ICompleteSupervisionParams,
	ICompleteSupervisionResp,
} from '../interfaces/complete-supervision.interface';

export function useCompleteSupervision() {
	const {
		execute: executeCompleteSupervision,
		status: statusCompleteSupervision,
		value: valueCompleteSupervision,
		setStatus: setStatusCompleteSupervision,
	} = useApiRequest<
		ICompleteSupervisionParams,
		IResponsePayload<ICompleteSupervisionResp>
	>({
		path: URLS.SUPERVISION_EXECUTION.COMPLETE_SUPERVISION,
		method: 'POST',
	});

	return {
		executeCompleteSupervision,
		setStatusCompleteSupervision,
		valueCompleteSupervision,
		statusCompleteSupervision,
	};
}
