/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { Divider, Grid } from '@mui/material';
import { Board } from '../../../../../components/board/board.component';
import InputSelect from '../../../../../components/select-input.component';
import TextInput from '../../../../../components/text-input.component';
import { monthsOptions } from '../utils/options.utils';
import { useGetPlannings } from '../hooks/useGetPlannings';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../../store/modules/loading';
import { startOfWeek, endOfWeek, addDays, formatISO, endOfDay } from 'date-fns';
import { useRescheduleFilters } from '../hooks/useGetServices';
import { RootState } from '../../../../../store';
import { Button } from '../../../../../components/main-button.component';
import { CalendarMonth } from '@mui/icons-material';
import Modal from '../../../../../components/modal.component';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { SelectDate } from '../../../../../components/static-date-picker';
import { useRescheduleRoutine } from '../../../../../components/board/hooks/useRescheduleRoutine';
import { DefaultModal, IModal } from './../../../../../interfaces/modal.interface';
import { getDateLocalMX } from '../../../../../utils/dates';
import { monthsTitle } from '../utils/months.utils';
import {
	IFiltersEditSupervision,
	FiltersEditSupervisionKeys,
	defaultFilters,
} from '../interfaces/actions.interface';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';
import { isEmpty } from '../../../../../utils/validators';

export const BoardComponent = () => {
	const dispatch = useDispatch();
	const { data } = useContext(ProfileProjectContext);
	const [datesSelect, setDatesSelect] = useState<IOptionsMenu[]>([]);
	const { firstName, lastName } = useSelector((state: RootState) => state.users);
	const { executeGetPlannings, planning, dataPlanning } = useGetPlannings();
	const { executeGetServices, services } = useRescheduleFilters();
	const [filters, setFilters] = useState<IFiltersEditSupervision>(defaultFilters);
	const [routineModal, setRoutineModal] = useState<string>('');
	const [idsActivities, setIdsActivities] = useState<string[]>([]);
	const [calendarValue, setCalendarValue] = useState<Date>(new Date());
	const { executeReschedule, statusReschedule } = useRescheduleRoutine();
	const [endWeek] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }));
	const [startWeek, setStartWeek] = useState(
		startOfWeek(new Date(), { weekStartsOn: 1 })
	);
	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});
	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const openModal = () => {
		setModal({
			...modal,
			open: true,
			hideConfirm: true,
		});
	};

	useEffect(() => {
		const datesModal: any = dataPlanning;
		setDatesSelect(
			(datesModal || [])?.map((i: any, idx: any) => ({
				id: idx,
				label:
					getDateLocalMX(i.executionDate) +
					' - ' +
					i.activities +
					' - ' +
					i.supervisorName,
				value: i.routine,
			})) || []
		);
	}, [dataPlanning]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		setFilters({ ...defaultFilters });
		//startWeek.setHours(6, 0, 0);
		//endWeek.setHours(6, 0, 0);
		executeGetPlannings(
			{},
			`?project=${data._id}
			&executionDateFrom=${formatISO(startWeek)}&executionDateTo=${formatISO(
				endWeek
			)}&isFastEdition=1`
		);
		executeGetServices({}, `?project=${data._id}`);
	}, []);

	useEffect(() => {
		if (statusReschedule === 'error' || statusReschedule === 'success')
			onUpdateBoard(startWeek, endOfWeek(startWeek, { weekStartsOn: 1 }));
	}, [statusReschedule]);

	const handleChange = (e: any, property: FiltersEditSupervisionKeys) => {
		(filters[property] as any) = e.target.value;
		if (property === 'month') {
			const year = new Date().getFullYear();
			const startMonth = startOfWeek(
				new Date(
					isEmpty(e.target.value) ? new Date() : `${year}-${e.target.value}-01`
				),
				{
					weekStartsOn: 1,
				}
			);

			setStartWeek(startMonth);
			filters.service = '';
			filters.advancedSearch = '';
			onUpdateBoard(startMonth, endOfDay(addDays(startMonth, 6)));
		}
		if (property === 'service') {
			dispatch(setLoading({ loading: true }));
			executeGetPlannings(
				{},
				`?project=${data._id}
			&executionDateFrom=${formatISO(startWeek)}
			&executionDateTo=${formatISO(endOfWeek(startWeek, { weekStartsOn: 1 }))}${
					isEmpty(e.target.value) ? '' : `&activity=${e.target.value}`
				}&isFastEdition=1`
			);
		}
		setFilters({ ...filters });
	};

	const handleChangeSelectDate = (e: any) => {
		setRoutineModal(e.target.value);

		const idActivities = dataPlanning.filter(
			(item) => item.routine === e.target.value
		);
		setIdsActivities(idActivities[0].activitiesId);
		setModal({
			...modal,
			hideConfirm: false,
		});
	};

	const onAdvancedSearch = (value: string) => {
		dispatch(setLoading({ loading: true }));
		executeGetPlannings(
			{},
			`?project=${data._id}
		&executionDateFrom=${formatISO(startWeek)}
		&executionDateTo=${formatISO(
			addDays(startWeek, 6)
		)}&advancedSearch=${value}&isFastEdition=1`
		);
	};

	const onUpdateBoard = (dateFrom: Date, dateTo: Date) => {
		dispatch(setLoading({ loading: true }));
		setStartWeek(dateFrom);
		executeGetPlannings(
			{},
			`?project=${data._id}
			&executionDateFrom=${formatISO(dateFrom)}&executionDateTo=${formatISO(dateTo)}${
				isEmpty(filters.service) ? '' : `&activity=${filters.service}`
			}&isFastEdition=1`
		);
	};

	const onCalendar = () => {
		dispatch(setLoading({ loading: true }));
		executeReschedule(
			{
				newExecutionDate: calendarValue.toISOString(),
				activitiesId: idsActivities,
			},
			`/${routineModal}`
		);
		//onUpdateBoard(startWeek, endOfDay(addDays(startWeek, 6)));
		closeModal();
	};

	const getYear = (year: any) => {
		return `${monthsTitle[year.getMonth()]}, ${year.getFullYear()}`;
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<h3 style={{ margin: '0' }}>Edición de supervisión</h3>
			</Grid>
			<Grid item xs={12}>
				<h3 style={{ margin: '0' }}>Bienvenido, {`${firstName} ${lastName}`}</h3>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2} justifyContent="flex-end">
					<Grid item>
						<Button
							icon={CalendarMonth}
							label="Cambiar de semana o mes"
							onClick={() => openModal()}
							style={{ backgroundColor: '#0080F9' }}
						/>
					</Grid>
					<Grid item>
						<InputSelect
							optionsMenu={monthsOptions}
							onChange={(e) => handleChange(e, 'month')}
							value={filters.month}
							height="auto"
							width="180px"
							placeholder="Mes"
							id="select-month"
						/>
					</Grid>
					<Grid item>
						<InputSelect
							optionsMenu={services}
							onChange={(e) => handleChange(e, 'service')}
							value={filters.service}
							height="auto"
							width="180px"
							placeholder="Servicio"
							id="select-service"
						/>
					</Grid>
					<Grid item>
						<TextInput
							id="advancedSearch"
							name="advancedSearch"
							value={filters.advancedSearch}
							placeholder="Buscar"
							onChange={(e) => handleChange(e, 'advancedSearch')}
							type="search"
							size="small"
							onClickSearch={() => onAdvancedSearch(filters.advancedSearch)}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				<div style={{ width: '100%', textAlign: 'center', fontSize: '22px' }}>
					{getYear(startWeek)}
				</div>
			</Grid>
			<Grid item xs={12}>
				<Board
					tasks={Object(planning)}
					setFilters={setFilters}
					setSendDate={startWeek}
					onUpdateBoard={onUpdateBoard}
				/>
			</Grid>
			{modal.open && (
				<Modal
					open={modal.open}
					title={'Cambiar fecha a otra semana o mes'}
					hideConfirm={modal.hideConfirm}
					confirmLabel={'Actualizar fecha'}
					styleButtonConfirm={{ color: '#0080F9' }}
					onClose={modal.onClose}
					onConfirm={() => onCalendar()}
					modal={modal}
					setModal={setModal}
				>
					<Grid>
						<Grid item>
							<InputSelect
								optionsMenu={datesSelect}
								onChange={(e) => handleChangeSelectDate(e)}
								value={routineModal}
								height="auto"
								placeholder="Selecciona el servicio que se desea actualizar"
								id="select-activity"
							/>
						</Grid>
						<Grid>
							<SelectDate
								value={calendarValue}
								setValueDate={setCalendarValue}
							/>
						</Grid>
					</Grid>
				</Modal>
			)}
		</Grid>
	);
};
