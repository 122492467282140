/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField, styled, useMediaQuery } from '@mui/material';
import styles from './styles/item-text-area-styles.module.css';
import theme from '../../../../../config/theme';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';
import { Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { EditButton } from '../../../../../components/buttons.component';
import { useEffect, useState } from 'react';
import { isEmpty } from '../../../../../utils/validators';

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

interface IItem {
	item: IAdaptedValues;
	values: { [key: number]: string };
	column: number;
	setValues: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>;
	onEdit: (item: IAdaptedValues) => void;
	onSave: (value: string, number: number, _id?: string) => void;
	onDelete: (index: number) => void;
}

export const ItemCreateRole = ({
	item,
	column,
	values,
	setValues,
	onSave,
	onDelete,
	onEdit,
}: IItem) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [value, setValue] = useState(values[column]);

	const keys = Object.keys(values);

	useEffect(() => {
		setValue(values[column]);
	}, [values]);

	return (
		<Grid
			item
			xs={matches ? 10 : 11}
			className={styles.renderItem}
			alignItems={'center'}
		>
			<Grid item>
				<span style={{ fontWeight: 'bold' }}>Rol {column + 1}</span>
			</Grid>
			<Grid item xs key={`rol-${column}`}>
				<Input
					id={`role-${column}`}
					variant="outlined"
					size="small"
					fullWidth
					name={keys[column]}
					placeholder="Escribe aquí"
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
						setValues({ ...values, [e.target.name]: e.target.value });
					}}
				/>
			</Grid>
			<Grid item>
				<div style={{ display: 'flex', gap: '15px' }}>
					{item.id && (
						<EditButton
							id={`btn-edit-${column}`}
							onClick={() => onEdit(item)}
						/>
					)}
					<EditButton
						id={`btn-save-${column}`}
						onClick={() => onSave(value, column, item.id)}
						icon={<SaveIcon sx={{ fontSize: '15px' }} />}
						disabled={isEmpty(value)}
					/>
					{!item.id && (
						<EditButton
							id={`btn-delete-${column}`}
							onClick={() => onDelete(column)}
							icon={<Delete sx={{ fontSize: '15px' }} />}
							styles={{
								background: 'var(--color-error-light)',
								color: 'var(--color-error-main)',
							}}
						/>
					)}
				</div>
			</Grid>
		</Grid>
	);
};
