import { Paper, Box, Grid } from '@mui/material';
import CriteriosIcon from '../../../assets/icons/criterios.icon';
import { EditItemRow } from '../../../components/edit-item-row.component';
import { HeaderEdit } from '../../../components/header-edit.component';
import VerticalStepper from '../../../components/vertical-stepper-fixed.component';
import styles from './styles/stepper-styles.module.css';
import { Button } from '../../../components/main-button.component';
import { useViewActivities } from './hooks/useViewActivities';

interface IViewActivities {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentIndicator: React.Dispatch<React.SetStateAction<string>>;
}

export const ViewActivities = ({ setStep, setCurrentIndicator }: IViewActivities) => {
	const { activitiesOptions, matches, navigate } = useViewActivities();

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
					alignItems="center"
				>
					<HeaderEdit
						section="Criterios"
						title="1. Selecciona tu actividad"
						alertText="Selecciona la actividad a la cual deseas modificar sus criterios específicos. Da clic en el botón el ícono correspondiente"
						icon={<CriteriosIcon size="23px" />}
					/>
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={activitiesOptions}
							renderItem={(item, column) => (
								<EditItemRow
									item={item}
									column={column}
									onEdit={() => {
										setStep(1);
										setCurrentIndicator(item.id);
									}}
									onSave={() => null}
									onDelete={() => null}
								/>
							)}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Salir"
							onClick={() => navigate(-1)}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
