// Dependencies
import React from 'react';
import es from 'date-fns/locale/es';

// Components
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Router } from './router/config';
import store, { persistor } from './store';

// Material Components
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Assets
import theme from './config/theme';
import { PersistGate } from 'redux-persist/integration/react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_CLIENT_ID || '',
		authority: process.env.REACT_APP_AUTHORITY || '',
		// redirectUri: window.location.origin,
		redirectUri: process.env.REACT_APP_ROUTER_BASE || '/',
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: false,
	},
	staySignedIn: true,
};

function App(): React.ReactElement {
	const msalInstance = new PublicClientApplication(msalConfig);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<ThemeProvider theme={theme}>
						<LocalizationProvider
							adapterLocale={es}
							dateAdapter={AdapterDateFns}
						>
							<MsalProvider instance={msalInstance}>
								<Router />
							</MsalProvider>
						</LocalizationProvider>
					</ThemeProvider>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	);
}
//@ts-ignore
if (window.Cypress) {
	//@ts-ignore
	window.store = store;
}
export default App;
