/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { RootState } from '../../../../store';
import { useLocation } from 'react-router-dom';
import { isEmpty } from '../../../../utils/validators';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid, Paper } from '@mui/material';
import { setState } from '../../../../store/modules/steps';
import { useSuspensionsArea } from './hooks/useSuspensionsArea';
import { columnsAreas } from './utils/columns-area-supervision';
import { setLoading } from './../../../../store/modules/loading';
import { GoBack } from '../../../../components/go-back-component';
import TableCustom from '../../../../components/table-custom.component';
import { IDimensionEspacial } from '../../../../interfaces/logic.interface';

interface IAreaMain {
	setOption: React.Dispatch<React.SetStateAction<string>>;
	setArea: React.Dispatch<React.SetStateAction<IDimensionEspacial>>;
}

export const AreaSelect = ({ setOption, setArea }: IAreaMain) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location?.state?.params;

	const { _id } = useSelector((state: RootState) => state.projectBase);

	const handleView = (area: IDimensionEspacial) => {
		setArea(area);
		dispatch(setState({ state: 1 }));
	};

	const { dataSuspensionsArea, setDataSuspensionsArea, executeSuspensionsArea } =
		useSuspensionsArea(handleView);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeSuspensionsArea(
			{},
			`?project=${state?.project?._id || _id}&page=${
				dataSuspensionsArea?.pagination
			}&recordsByPage=${dataSuspensionsArea?.rowsPerPage}${
				isEmpty(dataSuspensionsArea?.advancedFilter)
					? ''
					: `&descriptionName=${dataSuspensionsArea?.advancedFilter}`
			}`
		);
	}, [
		dataSuspensionsArea?.pagination,
		dataSuspensionsArea?.rowsPerPage,
		dataSuspensionsArea?.filters,
	]);

	return (
		<>
			<GoBack action={() => setOption('')} />
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Supervisión por espacio
							</span>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<TableCustom
								columns={columnsAreas}
								data={dataSuspensionsArea}
								setData={setDataSuspensionsArea}
								showLimitPerPage
								showPagination
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
