import { IActivitiesSH } from '../interfaces/tables.interface';

export const activitiesSHAdapter = (data: IActivitiesSH[]) => {
	const newData = data.map((element) => {
		return {
			0: element.activities
				.map((element) => element.activityDescription)
				.toString(),
			1: element.functionalParentName,
			2: element.functionalDescription,
		};
	});
	return newData;
};
