import { ICreationPage } from '../../../../interfaces/creation-page-interfaces';
import { TReactDispatch } from '../../../../interfaces/utils';
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { StepOneHandler } from '../../../../components/step-one-fixed';
import { useEffect, useRef, useState } from 'react';
import { DefaultTabItems } from '../../constants/data.constants';
import { estadoCreacion } from '../../../../constants/estado-creacion';
import EspecificosStepOne from './step-one.component';
import EspecificosStepTwo from '../../components/criterios-step-two.component';
import { URLS } from '../../../../constants/urls.constants';
import { IVerticalStepperAttributes } from '../../../../interfaces/vertical.steper.attributes-fixed.interface';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';
import { sendDataAdapterIndicator } from '../../../../adapters/step-two.adapter';
import useUpdateIndicator from '../../hooks/useUpdateIG';
import CreationPage from '../../../../components/creation-page.component';
import useGetIE from '../../hooks/useGetIE';
import usePatchActivities from '../../hooks/usePatchActivities';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import useUpdateProject from '../../../../hooks/useUpdateProject';
import { IActivity } from '../../../../interfaces/project.interface';
import useGetActivities from '../../hooks/useGetActivities';
import { isEmpty } from '../../../../utils/validators';

interface IHandleUpdateIndicator {
	p_data: IStepTwoModel[];
	p_isSave: boolean;
	p_isGeneral: boolean;
	p_idService?: string;
}
interface IProps {
	currentTab: number;
	setCurrentTab: TReactDispatch<number>;
}
const CriteriosEspecificos = (props: IProps) => {
	const dispatch = useDispatch<any>();
	const { currentTab, setCurrentTab } = props;
	const [IE, setIE] = useState<IStepTwoModel[]>([]);
	const {
		step: projectStep,
		_id: _projectID,
		specificIndicatorBase: specificIndicator,
	} = useSelector((state: RootState) => state.projectBase);
	const [showCsvEspecifico, setShowCsvEspecifico] = useState(false);
	const [isSave, setIsSave] = useState<boolean>(false);
	const { execute: updateIE, status: statusUpdateSpecificIndicator } =
		useUpdateIndicator({
			setIG: setIE,
			isSave,
		});
	const [isNextIEDisabled, setIsNextIEDisabled] = useState<boolean | null>(null);
	const [defaultIE] = useState<IVerticalStepperAttributes[] | null>(null);
	const [isEspecificosDone, setIsEspecificosDone] = useState(false);
	const { handleUpdateProject } = useUpdateProject();
	const { execute: patchActivities } = usePatchActivities();
	const [resetIE, setResetIE] = useState<null | true>(null);
	const [isIEEShow, setIsIEShow] = useState(false);
	const IERef = useRef<StepOneHandler>(null);
	const [isDoneIE, setIsDoneIE] = useState<null | true>(null);
	const [serviceIEOptions, setServiceIEOptions] = useState<IOptionsMenu[]>([]);
	const { value: valueActivities } = useGetActivities();
	const { execute: getIE } = useGetIE({ setIE });
	const [isBaseCreated, setIsBaseCreated] = useState<boolean>(false);

	const handleUpdateIndicator = async (props: IHandleUpdateIndicator) => {
		const { p_data, p_isGeneral, p_isSave, p_idService } = props;
		await dispatch(setLoading({ loading: true }));
		await setIsSave(p_isSave);
		try {
			const _adapted = sendDataAdapterIndicator(
				p_data,
				_projectID || '',
				p_isGeneral,
				p_idService
			);
			await updateIE(_adapted, `?project=${_projectID}&type=A-IE`);
			await patchActivities(
				{
					isSpecificAssociated: true,
				},
				`/${p_idService}`
			);
			await setServiceIEOptions((prev) =>
				prev.map((i) => {
					if (i.id === p_idService) {
						return {
							...i,
							status: true,
						};
					}
					return i;
				})
			);
			dispatch(stopLoading());
		} catch (e) {
			dispatch(stopLoading());
		}
	};

	useEffect(() => {
		if (!valueActivities?.data || valueActivities.data.length < 1) return;
		const _serviceIEOptions: IOptionsMenu[] = [];

		valueActivities.data.forEach((i: IActivity) => {
			const data = {
				id: i._id || '',
				label: i.columns[0],
				value: i._id || '',
			};
			_serviceIEOptions.push({
				...data,
				status: i.isSpecificAssociated || false,
			});
		});

		setServiceIEOptions(_serviceIEOptions);
		if (valueActivities.data.some((i: IActivity) => i.isSpecificAssociated)) {
			setIsIEShow(true);
			setIsDoneIE(true);
			if (projectStep !== '3.1.0') {
				setIsEspecificosDone(true);
				IERef.current?.disableAll();
			} else {
				IERef.current?.resetValues();
			}
		}
	}, [valueActivities]);

	useEffect(() => {
		if (statusUpdateSpecificIndicator === 'success') {
			dispatch(stopLoading());
			if (currentTab === 1 && !isSave) {
				handleUpdateProject({ step: '3.2.0' }, _projectID || '');
			}
		} else if (statusUpdateSpecificIndicator === 'error') dispatch(stopLoading());
	}, [statusUpdateSpecificIndicator]);

	useEffect(() => {
		if (!isEmpty(specificIndicator)) {
			setResetIE(null);
			setIsIEShow(true);
			setIsNextIEDisabled(true);
			setIsBaseCreated(true);
		}
	}, [specificIndicator]);

	const CriteriosEspecificos: ICreationPage = {
		header: {
			show: !showCsvEspecifico,
			label: 'Estado de creación',
			stepper: {
				activeStep: 2,
				items: estadoCreacion,
				completed: { 0: true, 1: true },
			},
			tabs: {
				items: DefaultTabItems,
				value: currentTab,
				onTabChange: (_, index) => {
					setCurrentTab(index);
				},
			},
		},
		stepOne: {
			title: `1. Nombra tus criterios, así como su atributo principal y los
			secundarios`,
			alertLabel:
				'Designa el nombre genérico de los criterios de evaluación específicos a considerar en la supervisión según lo indique el contrato',
			show: !showCsvEspecifico,
			Children: (
				<EspecificosStepOne
					refComponent={IERef}
					disableNext={isNextIEDisabled}
					defaultData={defaultIE}
					onContinue={(data) => {
						setIE(data);
						handleUpdateProject(
							{
								specificIndicatorBase: {
									name: data[0].name,
									mainAttribute: data[0].mainAttribute,
									attributes: data[0].attributes,
								},
							},
							_projectID || ''
						);
					}}
					resetDone={resetIE}
					isDefaultDone={isDoneIE}
				/>
			),
		},
		stepTwo: {
			title: !showCsvEspecifico ? '2. Nombra tus criterios específicos' : <></>,
			show: isIEEShow,
			Children: (
				<EspecificosStepTwo
					data={IE}
					labelItem="Designa el el nombre específico de los criterios de evaluación específico a considerar. Ejemplo: IE - 01"
					setData={setIE}
					showAltaCsv={showCsvEspecifico}
					setShowAltaCsv={setShowCsvEspecifico}
					onContinue={(data, actID) => {
						handleUpdateIndicator({
							p_data: data,
							p_isSave: false,
							p_isGeneral: false,
							p_idService: actID,
						});
					}}
					onSave={(data, actID) => {
						handleUpdateIndicator({
							p_data: data,
							p_isSave: true,
							p_isGeneral: false,
							p_idService: actID,
						});
					}}
					onSelectChange={(actID) => {
						dispatch(setLoading({ loading: true }));
						getIE(
							null,
							`?project=${_projectID}&activity=${actID}&isGeneral=false`
						);
					}}
					isDone={isEspecificosDone}
					onCVSUpload={() => {
						setShowCsvEspecifico(true);
					}}
					url={URLS.PROJECT.INDICATOR}
					selectData={{
						label: 'Elige la actividad',
						data: {
							options: serviceIEOptions,
							withStatus: true,
						},
					}}
				/>
			),
			showExitButon: !isEspecificosDone && !showCsvEspecifico && !isBaseCreated,
			onExit: () => {
				setIsIEShow(false);
				setIsNextIEDisabled(null);
				IERef.current?.unDone();
			},
		},
	};
	return <CreationPage {...CriteriosEspecificos} />;
};

export default CriteriosEspecificos;
