import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../components/header-edit.component';
import { useEditAssigments } from './hooks/useEditAssigments';
import PersonalIcon from '../../assets/icons/personal.icon';
import AsignacionSuperviciones from '../personal/components/asignacion-supervisiones';

export const EditAssigments = () => {
	const { matches } = useEditAssigments();

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					<HeaderEdit
						section="Personal"
						title="1. Edita al personal responsable de la supervisión para cada actividad"
						alertText="Edita al personal que estará encargado de cada supervisión de actividad, así como los sustitutos"
						icon={<PersonalIcon size="23px" />}
					/>
					<Grid item xs={12}>
						<AsignacionSuperviciones currentTab={0} isEdit />
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
