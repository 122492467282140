/* eslint-disable react-hooks/exhaustive-deps */
import { IN_PROGRESS } from './constants/messages';
import { useInProgress } from './hooks/useInProgress';
import { MessagesView } from '../../components/messages-view.component';

export const InProgress = () => {
	const { remaining, navigate } = useInProgress();

	return (
		<MessagesView
			descriptionLabel={IN_PROGRESS.DESCRIPTION}
			remainingLabel={`Consultando información en 0:${
				remaining < 10 ? '0' + remaining : remaining
			} segundos`}
			cancelAction={() => navigate(-1)}
			continueAction={() => null}
			isAutomaticProcess
		/>
	);
};
