/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';
import { setState } from '../../../../store/modules/steps';
import DragDocument from '../../../save-docs/drag-document';
import { useDocumentation } from './hooks/useDocumentation';
import ReviewDocuments from '../../../save-docs/review-documents';
import { columnsDocumentation } from './utils/columns-documentation';
import TableCustom from '../../../../components/table-custom.component';
import DocumentUploadSuccesfull from '../../../save-docs/document-upload-succesfull';

export const Documentation = () => {
	const {
		data,
		step,
		dataTable,
		setDataTable,
		dispatch,
		deleteDocumentLocal,
		saveProjectDocuments,
	} = useDocumentation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<span
					style={{
						margin: '0px',
						color: '#707070',
						fontSize: '20px',
						fontWeight: 'bold',
					}}
				>
					Documentos
				</span>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				{step.state === 0 && (
					<TableCustom
						columns={columnsDocumentation}
						data={dataTable}
						setData={setDataTable}
						buttons={[
							{
								icon: Add,
								label: 'Añadir documento',
								execute: () => {
									dispatch(setState({ state: 1 }));
								},
								type: 'blue',
								variant: 'outlined',
								width: 200,
							},
						]}
						showSearch={false}
						showPagination
						showLimitPerPage
					/>
				)}
				{step.state === 1 && <DragDocument withImages={true} />}
				{step.state === 2 && (
					<ReviewDocuments
						data={data}
						deleteDocument={deleteDocumentLocal}
						saveData={saveProjectDocuments}
						withImages={true}
					/>
				)}
				{step.state === 3 && <DocumentUploadSuccesfull />}
			</Grid>
		</Grid>
	);
};
