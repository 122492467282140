import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';

export const getInputsRowPersonal = (
	currentRol: IAdaptedValues
): { [key: string]: string } => {
	const myObject: { [key: string]: string } = {};

	if (!currentRol || currentRol?.rows?.length === 0) return { 0: '' };
	for (let i = 0; i < (currentRol?.rows?.length || 0); i++) {
		myObject[currentRol?.rows[i]?.key] = currentRol?.rows[i]?.value;
	}

	return myObject;
};
