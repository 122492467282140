/* eslint-disable react-hooks/exhaustive-deps */
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../config/theme';
import { useGetFunctional } from '../../hooks/useGetFunctional';

interface IUseListFunctionalLvl1 {
	isLvlOne: boolean;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setFunctionalParent: React.Dispatch<React.SetStateAction<string>>;
}

export const useListFunctionalLvl1 = ({
	isLvlOne,
	setStep,
	setFunctionalParent,
}: IUseListFunctionalLvl1) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const { functionalsOptions } = useGetFunctional({
		functionalParent: '',
		isLvlOne,
	});

	const onEdit = (item: IAdaptedValues) => {
		setStep(1);
		setFunctionalParent(item.id);
	};

	return {
		functionalsOptions,
		matches,
		onEdit,
		navigate,
	};
};
