/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApiRequest, IResponsePayload } from './../../../hooks/useApiRequest';
import { IOptionsMenu } from './../../../interfaces/select.interface';
import {
	ISelect,
	IGetLevels,
	IGetTypesFindings,
} from '../interfaces/extraordinary-incidence.interface';
import { URLS } from './../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { stopLoading } from './../../../store/modules/loading';
import { setAlert } from './../../../store/modules/snackbar';
import { useGetPersonalByProject } from '../../../hooks/usePersonalByProject';

export const useExtraordinaryIncidence = () => {
	const dispatch = useDispatch();
	const [typesFindings, setTypesFindings] = useState<IOptionsMenu[]>([]);
	const [levels, setLevels] = useState<string[]>();
	const [findings, setFindings] = useState<IOptionsMenu[]>([]);
	const [spaces, setSpaces] = useState<IOptionsMenu[]>([]);
	const [activities, setActivities] = useState<IOptionsMenu[]>([]);
	const [indicators, setIndicators] = useState<IOptionsMenu[]>([]);

	const {
		usersData: supervisors,
		getTotalUsers: executeGetSupervisors,
		getIsTeam,
	} = useGetPersonalByProject({ addTeams: true });

	const {
		execute: executeTypesFindings,
		status: statusTypesFindings,
		value: valueTypesFindings,
	} = useApiRequest<unknown, IGetTypesFindings>({
		path: URLS.PROJECT.GET_TYPE_FINDINGS,
		method: 'GET',
	});

	const {
		execute: executeGetLevels,
		status: statusLevels,
		value: valueLevels,
	} = useApiRequest<unknown, IResponsePayload<IGetLevels>>({
		path: URLS.SUPERVISION_UNIT.GET_LEVELS,
		method: 'GET',
	});

	const {
		execute: executeGetFindings,
		status: statusFindings,
		value: valueFindings,
	} = useApiRequest<any, IResponsePayload<ISelect>>({
		path: URLS.SUPERVISION_UNIT.GET_FUNCTIONALS,
		method: 'GET',
	});

	const {
		execute: executeGetSpaces,
		status: statusSpaces,
		value: valueSpaces,
	} = useApiRequest<any, IResponsePayload<ISelect>>({
		path: URLS.SUPERVISION_UNIT.GET_FUNCTIONALS,
		method: 'GET',
	});

	const {
		execute: executeGetActivities,
		status: statusActivities,
		value: valueActivities,
	} = useApiRequest<any, IResponsePayload<ISelect>>({
		path: URLS.SUPERVISION_UNIT.GET_ACTIVITIES,
		method: 'GET',
	});

	const {
		execute: executeGetIndicators,
		status: statusIndicators,
		value: valueIndicators,
	} = useApiRequest<any, IResponsePayload<ISelect>>({
		path: URLS.SUPERVISION_UNIT.GET_INDICATORS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusTypesFindings === 'success') {
			dispatch(stopLoading());
			setTypesFindings(
				(valueTypesFindings?.arrayTypes || [])?.map((i, idx) => ({
					id: idx || '',
					label: i,
					value: i || '',
				})) || []
			);
		} else if (statusTypesFindings === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al obtener tipos de hallazgos',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusTypesFindings]);

	useEffect(() => {
		if (statusLevels === 'success') {
			dispatch(stopLoading());
			setLevels(valueLevels?.detail?.levels || []);
		} else if (statusLevels === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueLevels?.message
						? valueLevels?.message
						: 'Ocurrió un error al obtener tipos de hallazgos',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusLevels]);

	useEffect(() => {
		if (statusFindings === 'success') {
			dispatch(stopLoading());
			setFindings(
				(valueFindings?.data || [])?.map((i, idx) => ({
					id: idx || '',
					label: i.description,
					value: i._id || '',
				})) || []
			);
		} else if (statusFindings === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueFindings?.message
						? valueFindings?.message
						: 'Ocurrió un error al obtener tipos de hallazgos',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusFindings]);

	useEffect(() => {
		if (statusSpaces === 'success') {
			setSpaces(
				(valueSpaces?.data || [])?.map((i, idx) => ({
					id: idx || '',
					label: i.description,
					value: i._id || '',
				})) || []
			);
		} else if (statusSpaces === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueSpaces?.message
						? valueSpaces?.message
						: 'Ocurrió un error al obtener espacios',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusSpaces]);

	useEffect(() => {
		if (statusActivities === 'success') {
			setActivities(
				(valueActivities?.data || [])?.map((i, idx) => ({
					id: idx || '',
					label: i.description,
					value: i._id || '',
				})) || []
			);
		} else if (statusActivities === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueActivities?.message
						? valueActivities?.message
						: 'Ocurrió un error al obtener actividades',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusActivities]);

	useEffect(() => {
		if (statusIndicators === 'success') {
			setIndicators(
				(valueIndicators?.data || [])?.map((i, idx) => ({
					id: idx || '',
					label: i.description,
					value: i._id || '',
				})) || []
			);
		} else if (statusIndicators === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueIndicators?.message
						? valueIndicators?.message
						: 'Ocurrió un error al obtener indicadores',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusIndicators]);

	return {
		executeTypesFindings,
		executeGetLevels,
		executeGetFindings,
		executeGetSpaces,
		executeGetActivities,
		executeGetIndicators,
		executeGetSupervisors,
		setSpaces,
		setActivities,
		setIndicators,
		getIsTeam,
		typesFindings,
		levels,
		findings,
		spaces,
		activities,
		indicators,
		supervisors,
	};
};
