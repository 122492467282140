/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import { ViewFunctionals } from '../components/view-functionals';
import { isEmpty } from 'lodash';
import { ListFunctionalsLvl1 } from '../components/list-functionals-lvl1';
import { EditFunctional } from '../components/edit-functional.component';
import { IIndicatorModel } from '../../../interfaces/project.interface';
import useUpdateProject from '../../../hooks/useUpdateProject';
import { useDispatch } from 'react-redux';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { CreateBase } from '../../../components/create-base.component';
import { HeaderEdit } from '../../../components/header-edit.component';
import DimensionesIcon from '../../../assets/icons/dimensiones.icon';

interface IUseEditFunctionals {
	isLvlOne: boolean;
}

export const useEditFunctionals = ({ isLvlOne }: IUseEditFunctionals) => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const idProject = location.state.params.idProject as string;

	const [structureData, setStructureData] = useState(
		location.state.params.structureData as IIndicatorModel
	);

	const [step, setStep] = useState<number>(!structureData ? -1 : isLvlOne ? 1 : 0);
	const [functionalParent, setFunctionalParent] = useState('');
	const [currentEditFunctional, setCurrentEditFunctional] = useState<IAdaptedValues>({
		id: '',
		mainAttribute: '',
		description: '',
		rows: [],
		frequency: '',
	} as unknown as IAdaptedValues);

	const {
		handleUpdateProject,
		status: statusProject,
		value: valueProject,
	} = useUpdateProject();

	useEffect(() => {
		if (isEmpty(idProject)) navigate(-1);
	}, []);

	useEffect(() => {
		if (statusProject === 'success') {
			dispatch(stopLoading());
			if (valueProject?.detail?.functionalParentBase && isLvlOne)
				setStructureData(valueProject.detail?.functionalParentBase);
			if (valueProject?.detail?.functionalBase && !isLvlOne)
				setStructureData(valueProject.detail.functionalBase);
			isLvlOne ? setStep(1) : setStep(0);
		} else if (statusProject === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar la base',
					severity: 'error',
				})
			);
		}
	}, [statusProject]);

	const onSave = (newBase: IIndicatorModel) => {
		dispatch(setLoading({ loading: true }));
		const body = isLvlOne
			? { functionalParentBase: newBase }
			: { functionalBase: newBase };
		handleUpdateProject(body, idProject);
	};

	const getComponent = () => {
		switch (step) {
			case -1:
				return (
					<CreateBase
						header={
							<HeaderEdit
								section="Dimensiones geográficas"
								title={
									isLvlOne
										? 'Nombra tu nivel 1, así como su atributo principal y los secundarios pertinentes.'
										: 'Nombra tu nivel 2, así como su atributo principal y los secundarios pertinentes.'
								}
								alertText={
									isLvlOne
										? 'Designa el nombre genérico de los espacios a supervisar según lo indique el contrato. NO utilice el nombre de un área específica'
										: 'Designa el nombre genérico de los espacios a supervisar según lo indique el contrato. NO utilice el nombre de un área específica'
								}
								icon={<DimensionesIcon size="23px" />}
							/>
						}
						label={isLvlOne ? 'Nivel 1' : 'Nivel 2'}
						onSaveCustom={onSave}
					/>
				);
			case 0:
				return (
					<ListFunctionalsLvl1
						setStep={setStep}
						setFunctionalParent={setFunctionalParent}
					/>
				);
			case 1:
			default:
				return (
					<ViewFunctionals
						functionalParent={functionalParent}
						setStep={setStep}
						setCurrentFunctional={setCurrentEditFunctional}
						isLvlOne={isLvlOne}
						structureData={structureData}
					/>
				);
			case 2:
				return (
					<EditFunctional
						setStep={setStep}
						setCurrentFunctional={setCurrentEditFunctional}
						isLvlOne={isLvlOne}
						currentFunctional={currentEditFunctional}
					/>
				);
		}
	};

	return { getComponent };
};
