/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { SelectService } from '../components/select-service.component';
import { TableSupervision } from '../components/table-supervision.component';
import { NewIncident } from '../components/new-incident.component';
import { ViewDetails } from '../components/view-details/view-details.component';
import { SuspendActivity } from '../components/suspend-activity.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SupervisionAction } from '../../supervision-action';
import { ISelect, ISupervisionAction } from '../interfaces/supervision-project.interface';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import { useChangeStatus } from './useChangeStatus';
import {
	DESCRIPTION_SUPERVISION,
	LABEL_BUTTON_SUPERVISION,
} from '../constants/labels-supervision';
import { useCompleteSupervision } from './useCompleteSupervision';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { setAlert } from '../../../store/modules/snackbar';
import { stopLoading } from '../../../store/modules/loading';
import { useHistoryState } from './useHistoryState';
import { IStartSupervisionResp } from '../interfaces/start-supervision.interface';
import { IChangeStatusParams } from '../components/interfaces/change-status.interface';
import { STATUS_ROUTINE } from '../../../constants/status-routine';
import { SummaryRoutine } from '../components/summary-routine/summary-routine.component';
import { getRoutineDescriptionByType } from '../../../utils/get-title-routines';
import { ITypeRoutine } from '../../../constants/routines.constants';
import { INewIncident } from '../components/interfaces/new-incident.interface';

export function useSupervisionProject() {
	const dispatch = useDispatch();
	const location = useLocation();
	const idRoutine = location?.state?.routine as string;
	const supervisor = location?.state?.supervisor as string;
	const routineStatus = location?.state?.status as string;
	const isEdit = location?.state?.isEdit as boolean;
	const supervisorName = (location?.state?.supervisorName as string) || '-';
	const [options, setOptions] = useState<IOptionsMenu[]>([]);
	const [idProject, setIdProject] = useState(location?.state?.idProject || '');
	const [descriptionRoutine, setDescriptionRoutine] = useState(
		getRoutineDescriptionByType(
			location?.state?.routineType as keyof ITypeRoutine,
			location?.state?.executionDate as unknown as Date
		)
	);
	const [withStatus, setWithStatus] = useState<IChangeStatusParams | null>(null);
	const [supervisionRoutineId, setSupervisionRoutineId] = useState('');
	const [unitInfo, setUnitInfo] = useState('');
	const navigate = useNavigate();
	const [editFinding, setEditFinding] = useState<INewIncident | null>(null);

	const { historyState, setNewState, undoState } = useHistoryState({
		initialState: 1,
		ignoreStep: 0,
	});
	const { executeChangeStatus, statusChangeStatus, setStatus } = useChangeStatus();
	const {
		executeCompleteSupervision,
		setStatusCompleteSupervision,
		valueCompleteSupervision,
		statusCompleteSupervision,
	} = useCompleteSupervision();

	const {
		execute: startSupervision,
		status,
		value: valueStartSupervision,
	} = useApiRequest<any, IResponsePayload<IStartSupervisionResp>>({
		path: URLS.SUPERVISION_EXECUTION.START_SUPERVISION,
		method: 'POST',
	});

	const [mainSelect, setMainSelect] = useState<ISelect>({
		optionLabel: '',
		valueLabel: '',
	});

	const [subSelect, setSubSelect] = useState<{
		functionalN1: string;
		functionalN2: string;
		service: string;
	}>({ functionalN1: '', functionalN2: '', service: '' });

	const [supervisionAction, setSupervisionAction] = useState<ISupervisionAction>({
		descriptionLabel: DESCRIPTION_SUPERVISION.START_SUPERVISION,
		labelButton: LABEL_BUTTON_SUPERVISION.START_SUPERVISION,
		continueActionButton: () => setNewState(1),
		cancelActionButton: () => navigate(-1),
	});

	const getComponent = () => {
		switch (historyState.currentStep) {
			case 0:
				return <SupervisionAction />;
			case 1:
				return <SelectService />;
			case 2:
				return <TableSupervision />;
			case 3:
				return <SummaryRoutine />;
			case 4:
				return <NewIncident />;
			case 5:
				return <ViewDetails />;
			case 6:
				return <SuspendActivity />;
			default:
				return <SelectService />;
		}
	};

	useEffect(() => {
		if (isEdit) setNewState(1);
		if (
			routineStatus !== STATUS_ROUTINE.EXECUTING &&
			routineStatus !== STATUS_ROUTINE.ACCOMPLISHED
		) {
			startSupervision({ findings: [] }, `/${idRoutine}`);
		} else {
			setSupervisionAction({
				...supervisionAction,
				descriptionLabel: `${DESCRIPTION_SUPERVISION.RESUME_SUPERVISION} ${descriptionRoutine}?`,
				labelButton: LABEL_BUTTON_SUPERVISION.RESUME_SUPERVISION,
			});
		}

		/* return () => {
			executeStopTiming(idRoutine);
		}; */
	}, []);

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Se ha dado inicio a la supervisión',
					severity: 'success',
				})
			);
			setDescriptionRoutine(
				getRoutineDescriptionByType(
					valueStartSupervision?.detail.type as keyof ITypeRoutine,
					valueStartSupervision?.detail.executionDate as unknown as Date
				)
			);
			setIdProject(valueStartSupervision?.detail.project || '');
			setSupervisionAction({
				...supervisionAction,
				descriptionLabel: `${
					DESCRIPTION_SUPERVISION.START_SUPERVISION
				} ${getRoutineDescriptionByType(
					valueStartSupervision?.detail.type as keyof ITypeRoutine,
					valueStartSupervision?.detail.executionDate as unknown as Date
				)}?`,
			});
		} else if (status === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						'Ha ocurrido un error al iniciar la supervisión, vuelva a recargar.',
					severity: 'error',
				})
			);
			navigate(-1);
		}
	}, [status]);

	return {
		descriptionRoutine,
		supervisor,
		supervisorName,
		idRoutine,
		mainSelect,
		supervisionAction,
		options,
		subSelect,
		statusChangeStatus,
		supervisionRoutineId,
		statusCompleteSupervision,
		valueCompleteSupervision,
		idProject,
		withStatus,
		setMainSelect,
		setSupervisionAction,
		dispatch,
		getComponent,
		setOptions,
		setSubSelect,
		executeChangeStatus,
		setStatus,
		setSupervisionRoutineId,
		executeCompleteSupervision,
		setStatusCompleteSupervision,
		setNewState,
		undoState,
		setWithStatus,
		historyState,
		editFinding,
		setEditFinding,
		unitInfo,
		setUnitInfo,
	};
}
