/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useGetFrequencies } from '../../../edit-schedule/hooks/useGetFrequencies';
import { useGetPersonalByProject } from '../../../../hooks/usePersonalByProject';

export const useSelectFrequency = () => {
	const [data, setData] = useState<{
		frequency: string;
		executor: string | null;
		executorTeam: string | null;
		supervisor: string | null;
		supervisorTeam: string | null;
		substitute1: string | null;
		substitute1Team: string | null;
		substitute2: string | null;
		substitute2Team: string | null;
	}>({
		frequency: '',
		executor: '',
		executorTeam: '',
		supervisor: '',
		supervisorTeam: '',
		substitute1: '',
		substitute1Team: '',
		substitute2: '',
		substitute2Team: '',
	});
	const { frequenciesOptions } = useGetFrequencies();

	const { usersData, getTotalUsers, getIsTeam } = useGetPersonalByProject({
		addTeams: true,
	});

	useEffect(() => {
		getTotalUsers();
	}, []);

	return { usersData, data, frequenciesOptions, setData, getIsTeam };
};
