/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import { Link, useNavigate } from 'react-router-dom';

// Components
import { Box, Grid } from '@mui/material';
import TextInput from '../../components/text-input.component';
import { Button } from '../../components/main-button.component';
import CustomAlert from '../../components/alert.component';

// Resources
import styles from './styles/styles.module.css';
import { Email } from '@mui/icons-material';

import theme from '../../config/theme';
import { useLogin } from './hooks/useLogin';
import { useSendValidation } from './hooks/useSendValidation';
import { useEffect, useState } from 'react';

const Login = () => {
	const [code, setCode] = useState('');
	const [step, setStep] = useState<number>(1);
	const [countDown, setCountDown] = useState(60);

	const {
		handleValidate,
		handleSetData,
		alert,
		errorEmail,
		data,
		isMailSend,
		handleRequestAccessToken,
		instance,
		accounts,
	} = useSendValidation();
	const { handleValidate: handleLogin, alert: alertLogin } = useLogin({
		email: data.email,
		password: data.password,
		code: code,
	});
	const navigate = useNavigate();
	const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCode(e.target.value);
	};
	useEffect(() => {
		if (isMailSend) setStep(2);
	}, [isMailSend]);

	useEffect(() => {
		if (step === 2 && countDown > 0) {
			const interval = setInterval(() => {
				setCountDown((prevCount) => prevCount - 1);
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [step, countDown]);

	return (
		<Grid className={styles.subContainer} container spacing={0}>
			<Grid className={styles.mainContainerImgs} item xs={12} md={6} />
			<Grid className={styles.mainContainerForm} item xs={12} md={6}>
				<div className={styles.subContainerForm}>
					<div className={styles.subContainerFormInfo}>
						{step === 1 && (
							<>
								<div className={styles.containerTitle}>
									<label className={styles.title}>INICIA SESIÓN</label>
								</div>
								<Button
									id="validate"
									label="Ingresa con outlook"
									onClick={handleRequestAccessToken}
									variant="solid"
									icon={Email}
									style={{
										marginBottom: 10,
										height: 50,
										backgroundColor: theme.palette.secondary.dark,
									}}
								/>
								{accounts.length >= 1 && (
									<Box
										width="100%"
										display="flex"
										justifyContent="space-between"
									>
										<Box
											color={theme.palette.primary.main}
											fontSize={16}
											marginBottom={2}
										>
											Sesión iniciada:{' '}
											{accounts.length >= 1
												? accounts[0].username
												: ''}
										</Box>
										<span
											onClick={() =>
												instance.logoutPopup().then(() => {
													navigate('/login');
												})
											}
											style={{
												fontSize: 16,
												textDecoration: 'underline',
												color: theme.palette.error.main,
												cursor: 'pointer',
											}}
										>
											Cerrar sesión
										</span>
									</Box>
								)}
								<div className={styles.orContainer}>
									<hr />
									<p>ó</p>
									<hr />
								</div>
								<TextInput
									id="email"
									name="email"
									label="Ingresa tu correo electrónico"
									placeholder="Correo electrónico"
									value={data.email}
									onChange={handleSetData}
									contentStyles={{ marginBottom: 40 }}
									error={!errorEmail.valid}
									helperText={!errorEmail.valid ? errorEmail.msg : ''}
								/>
								<TextInput
									id="password"
									name="password"
									label="Ingresa tu contraseña"
									placeholder="Contraseña"
									value={data.password}
									type="password"
									onChange={handleSetData}
								/>
								<CustomAlert
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
									id="alert-message-login"
								/>
								<div className={styles.containerForgotPass}>
									<Link
										to="/cambio-contrasenia"
										className={styles.labelForgotPass}
										id="link-password"
									>
										¿Olvidaste tu contraseña?
									</Link>
								</div>
								<Button
									label="Validar credenciales"
									onClick={handleValidate}
									//onClick={handleLogin}
									variant="solid"
									style={{
										height: 50,
										backgroundColor: theme.palette.secondary.main,
									}}
								/>
							</>
						)}
						{isMailSend && step === 2 && (
							<div>
								<p>Código de acceso</p>
								<p>
									Ingrese los <strong>6 dígitos</strong> que hemos
									mandado al correo electrónico {data.email}.
								</p>
								<TextInput
									id="code"
									name="code"
									label="Ingresa tu código de acceso"
									placeholder="Código de acceso"
									value={code}
									onChange={handleCode}
								/>
								<CustomAlert
									message={alertLogin.message}
									severity={alertLogin.severity}
									show={alertLogin.show}
									id="alert-message-login"
								/>
								<Button
									id="login"
									label="Ingresar"
									variant="solid"
									disabled={code?.length !== 6}
									style={{
										marginTop: 20,
										height: 50,
										backgroundColor: theme.palette.secondary.main,
									}}
									onClick={handleLogin}
								/>
								<div className={styles.containerErrorPass}>
									<p className={styles.messageNoCode}>
										¿No has recibido tu código?{' '}
										{countDown === 0 ? (
											<span
												onClick={() => {
													setCountDown(60);
													handleValidate();
												}}
												className={styles.linkResendCode}
											>
												Solicita uno nuevo
											</span>
										) : (
											<>
												solicita uno nuevo en{' '}
												<strong>{countDown}</strong> segundos.
											</>
										)}
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</Grid>
		</Grid>
	);
};
export default Login;
