import {
	AccessTimeOutlined,
	Check,
	FormatListBulletedOutlined,
	ListAltRounded,
	PeopleAltOutlined,
} from '@mui/icons-material';
import { Box, Divider, Grid, Paper } from '@mui/material';
import { CardComponent } from './card.component';
import styles from './styles/card-styles.module.css';
import imageBackground from '../utils/grafica.svg';
import { useGetStats } from '../hooks/useGetStats';
import { IUser } from '../../../interfaces/user.interface';
import { getTime } from '../utils/getTime';

interface IPerformanceHistory {
	user: IUser;
}

export const PerformanceHistory = ({ user }: IPerformanceHistory) => {
	const { data, matches } = useGetStats({ user });

	return (
		<Paper elevation={0}>
			<Box padding={matches ? 3 : 6}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<h3 id="header-name" style={{ margin: 0 }}>
							Historial de desempeño
						</h3>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item sm={12} md={6} lg={4}>
						<CardComponent
							icon={
								<PeopleAltOutlined
									className={styles.icon}
									style={{ backgroundColor: '#1FC459' }}
								/>
							}
							title="Supervisiones"
							subtitle="Número total de supervisiones efectuadas (en toda la historia)"
							stats={data.totalSupervisionsFinished.toString()}
						/>
					</Grid>
					<Grid item sm={12} md={6} lg={4}>
						<CardComponent
							icon={
								<AccessTimeOutlined
									className={styles.icon}
									style={{ backgroundColor: 'transparent' }}
								/>
							}
							title="Tiempo promedio"
							subtitle="Tiempo promedio por supervisiónes efectuadas (en toda la historia)"
							stats={getTime(data.averageTimePerSupervision)}
							isHour
							stylesContainer={{
								backgroundImage: `url(${imageBackground}), linear-gradient(#FCB39D,#FA234D)`,
								backgroundSize: '100%',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center bottom',
							}}
							stylesColorFont={{ color: 'white' }}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<CardComponent
							icon={
								<Check
									className={styles.icon}
									style={{ backgroundColor: '#1FC459' }}
								/>
							}
							title="Resumen"
							subtitle="Resumen de las supervisiones efectuadas (en toda la historia)"
							body={
								<Grid item md>
									<span className={styles.subtitle}>
										Tiempo total de supervisión
									</span>
									<h4 className={styles.title}>
										{getTime(data.resume.totalTime)} HRS
									</h4>
									<br />
									<span className={styles.subtitle}>
										Tareas completadas
									</span>
									<h4 className={styles.title}>
										{data.resume.totalTasks}
									</h4>
									{data.resume.projects.map((element, index) => {
										return (
											<span
												className={styles.subtitleProject}
												key={`project-${index}`}
											>
												<br />
												{element}
											</span>
										);
									})}
								</Grid>
							}
						/>
					</Grid>
					<Grid item sm={12} md={6} lg={4}>
						<CardComponent
							icon={
								<FormatListBulletedOutlined
									className={styles.icon}
									style={{ backgroundColor: 'transparent' }}
								/>
							}
							title="Proyectos"
							subtitle="Número total de proyectos supervisados (en toda la historia)"
							stats={data.totalProjectsSupervised.toString()}
							stylesContainer={{
								background: 'linear-gradient(#B51233, #5B091A)',
							}}
							stylesColorFont={{ color: 'white' }}
						/>
					</Grid>
					<Grid item sm={12} md={6} lg={4}>
						<CardComponent
							icon={
								<ListAltRounded
									className={styles.icon}
									style={{ backgroundColor: '#1FC459' }}
								/>
							}
							title="Reportes"
							subtitle="Número total de reportes generados"
							stats={data.totalReportsSubmitted.toString()}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
