import { CloudUploadOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import styles from './styles/drag-drop.module.css';
import { IDragNDropInterface } from '../interfaces/drag-drop.interface';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/modules/snackbar';

interface IDragNDrop {
	element: IDragNDropInterface;
	value?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	icon?: JSX.Element;
	stylesContainer?: React.CSSProperties;
	stylesTitle?: React.CSSProperties;
	stylesSubTitle?: React.CSSProperties;
}

export const DragNDrop = ({
	element,
	value,
	onChange,
	icon,
	stylesContainer,
	stylesTitle,
	stylesSubTitle,
}: IDragNDrop) => {
	const dispatch = useDispatch();
	const [isDragging, setIsDragging] = useState(false);
	const fileInputRef = useRef<any>(null);
	const [key, setKey] = useState(0);

	const handleOnChange = (event: any, isDrag: boolean): void => {
		if (!isDrag) event.preventDefault();
		const fileList = event.target.files;
		if (!fileList) {
			dispatch(
				setAlert({
					show: true,
					message: 'Selecciona un archivo.',
					severity: 'error',
				})
			);
			return;
		}
		const extension = fileList[0] ? fileList[0].type : '';
		const acceptedTypes = fileInputRef.current.accept.split(',');
		if (acceptedTypes.includes(extension)) {
			onChange(event);
			setKey(key + 1);
		} else {
			dispatch(
				setAlert({
					show: true,
					message: `El archivo debe tener extension ${element.accept}`,
					severity: 'error',
				})
			);
		}
	};

	const handleDrop = (event: any) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0] as React.ChangeEvent<HTMLInputElement>;
		handleOnChange({ target: { files: [file] } }, true);
	};

	const handleDragOver = (event: any) => {
		event.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	return (
		<>
			<Grid
				item
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				xs={12}
				md={8}
				className={`${styles.dragFile} ${isDragging ? styles.dragOver : ''}`}
				style={{ ...stylesContainer }}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				onClick={() => {
					if (fileInputRef.current) fileInputRef.current.click();
				}}
			>
				<div className={styles.dragFileInformation}>
					<div>
						{icon ? (
							icon
						) : (
							<CloudUploadOutlined
								className={styles.dragFileInformationSVG}
							/>
						)}
					</div>
					<Typography variant="h5" style={{ ...stylesTitle }}>
						Arrastra y suelta tu archivo aquí.
					</Typography>
					<Typography variant="body1" style={{ ...stylesSubTitle }}>
						O selecciona desde el explorador de archivos
					</Typography>
				</div>
			</Grid>
			<input
				type="file"
				ref={fileInputRef}
				id="loadFileInput"
				accept={element.accept}
				onChange={(event) => handleOnChange(event, false)}
				style={{ display: 'none' }}
				key={key}
			/>
		</>
	);
};
