/* eslint-disable react-hooks/exhaustive-deps */
import { getColumns } from '../utils/columns-base-tasks.utils';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetFunctional } from '../../edit-functionals/hooks/useGetFunctional';
import { isEmpty } from '../../../utils/validators';
import { useGetActivitiesUA } from '../../edit-functionals/components/functionals-relations-UI/hooks/useGetActivitiesUA';
import { useGetBaseTasks } from './useGetBaseTasks';

export const useViewBaseTasks = () => {
	const { functionalBase, functionalParentBase, activityBase } = useSelector(
		(state: RootState) => state.projectBase
	);

	const [dataValues, setDataValues] = useState<{
		[key: string]: string;
	}>({ functionalParent: '', functional: '', activity: '' });

	const { functionalsSelectOptions } = useGetFunctional({
		functionalParent: '',
		isLvlOne: true,
		loading: false,
	});

	const {
		functionalsSelectOptions: functionalsSelectOptionsLvl2,
		getFunctionalData: getFunctionalDataLvl2,
	} = useGetFunctional({
		functionalParent: '',
		isLvlOne: false,
		execute: false,
		loading: false,
	});

	const { activitiesOptions, handleGetActivitiesUA } = useGetActivitiesUA({
		loading: false,
	});
	const { data, setData } = useGetBaseTasks({ dataValues });

	const columns = getColumns(
		functionalParentBase?.name || '',
		activityBase?.name || '',
		functionalBase?.name
	);

	useEffect(() => {
		if (!functionalBase && !isEmpty(dataValues.functionalParent)) {
			handleGetActivitiesUA(dataValues.functionalParent);
		} else if (!isEmpty(dataValues.functionalParent)) {
			getFunctionalDataLvl2(dataValues.functionalParent);
		}
	}, [dataValues.functionalParent]);

	useEffect(() => {
		if (!isEmpty(dataValues.functional)) {
			handleGetActivitiesUA(dataValues.functional);
		}
	}, [dataValues.functional]);

	return {
		columns,
		data,
		dataValues,
		functionalParentBase,
		functionalBase,
		activityBase,
		functionalsSelectOptions,
		functionalsSelectOptionsLvl2,
		activitiesOptions,
		setData,
		setDataValues,
	};
};
