import CancelIcon from '@mui/icons-material/Cancel';
import { Paper, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import { INewUser } from '../../../interfaces/new-user.interface';
import { useNewUser } from '../hooks/useNewUser';
import styles from '../styles/tab.module.css';
import { NewUserForm } from './new-user-form.component';

export const NewUserComponent = () => {
	const {
		users,
		currentUser,
		currentTabValue,
		handleChangeTab,
		handleCloseTab,
		handleSetData,
		addUser,
		createUsers,
	} = useNewUser();
	return (
		<>
			<Tabs value={currentTabValue} onChange={handleChangeTab}>
				{(users || []).map((usuario: INewUser, index: number) => {
					return (
						<Tab
							key={index}
							className={styles.tabsStyle}
							icon={
								<>
									{index !== 0 && (
										<CancelIcon
											style={{ marginLeft: '10px' }}
											onClick={(e) => handleCloseTab(e, index)}
										/>
									)}
								</>
							}
							iconPosition="end"
							label={
								index === 0
									? 'Nuevo Usuario'
									: usuario.firstName
									? usuario.firstName
									: `Usuario ${index}`
							}
						/>
					);
				})}
			</Tabs>
			<Paper elevation={0}>
				<Box padding={3}>
					<NewUserForm
						currentUser={currentUser}
						currentTabValue={currentTabValue}
						users={users}
						addUser={addUser}
						handleSetData={handleSetData}
						createUser={createUsers}
					/>
				</Box>
			</Paper>
		</>
	);
};
