/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { useDebounce } from '../hooks/useDebounce';

interface ITextArea {
	defaultValue: string;
	onChange: (value: string) => void;
	onCopy?: (text: string) => void;
	onPaste?: (text: string) => void;
	RenderTitle?: () => JSX.Element | null;
	hideCopyPaste?: boolean;
	isReadonly?: boolean;
	disabled?: boolean;
	maxRows?: number;
	id?: string;
	debounceDelay?: number;
	disableDebounce?: boolean;
}

const TextAreaInput = styled(TextField)(() => ({
	background: '#F7F7F7',
	overflow: 'hidden',
	resize: 'none',
	border: 'none',
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			border: 'none',
		},
	},
}));

const BtnGroup = styled(ButtonGroup)(() => ({
	width: '55px',
	height: '21px',
	marginLeft: '0.5rem',
	minWidth: 'unset !important',
}));

const Btn = styled(Button)(() => ({
	font: 'normal normal normal 6px/6px Arial',
	textTransform: 'none',
	padding: '2px 2px',
	minWidth: 'unset !important',
	color: '#0080F9',
	borderColor: '#0080F9',
	'&:hover': {
		border: 'solid 1px #0080F9 !important',
	},
}));

export const TextArea = (props: ITextArea) => {
	const {
		defaultValue,
		onChange,
		onCopy,
		onPaste,
		RenderTitle,
		hideCopyPaste = false,
		isReadonly = false,
		disabled = false,
		maxRows = 4,
		id,
		debounceDelay = 300,
	} = props;
	const inputRef = useRef<HTMLDivElement>(null);
	const textAreaValue = inputRef.current?.querySelector('textarea');
	const [textValue, setTextValue] = useState<string>('');
	const debouncedText = useDebounce<string>(textValue, debounceDelay);
	useEffect(() => {
		setTextValue(defaultValue);
	}, []);

	// IF disableDebounce is true
	useEffect(() => {
		onChange(debouncedText);
	}, [debouncedText]);

	const CopyText = () => {
		const textValue = textAreaValue?.value || '';
		const type = 'text/plain';
		const blob = new Blob([textValue], { type });
		const data = [new ClipboardItem({ [type]: blob })];
		navigator.clipboard.write(data).then(
			() => {
				if (onCopy) onCopy(textValue);
			},
			(e) => {}
		);
	};

	const handlePaste = async () => {
		if (isReadonly || disabled) return;
		const textValue = textAreaValue?.value || '';
		const txt = await navigator.clipboard.readText();
		const concatValue = textValue.concat(txt);
		if (onPaste) onPaste(txt);
		if (textAreaValue) {
			textAreaValue.value = concatValue;
			textAreaValue.dispatchEvent(new Event('input', { bubbles: true }));
		}
		setTextValue((prev) => prev.concat(txt));
	};

	return (
		<div>
			{RenderTitle && <RenderTitle />}
			<div style={{ display: 'flex', marginRight: '20px' }}>
				<TextAreaInput
					ref={inputRef}
					id={id}
					multiline
					maxRows={maxRows}
					defaultValue={defaultValue}
					disabled={disabled}
					onChange={(e) => {
						setTextValue(e.target.value);
					}}
					InputProps={{
						readOnly: isReadonly,
					}}
					placeholder="Escribe aquí..."
					fullWidth
				/>

				{!hideCopyPaste && (
					<BtnGroup
						disableElevation
						size="small"
						variant="outlined"
						aria-label="outlined button group"
					>
						<>
							<Btn onClick={CopyText}>Copiar</Btn>
							<Btn onClick={handlePaste}>Pegar</Btn>
						</>
					</BtnGroup>
				)}
			</div>
		</div>
	);
};

export default TextArea;
