/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField, styled } from '@mui/material';
import styles from './styles/item-text-area-styles.module.css';
import { Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import InputSelect from '../../../../../components/select-input.component';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';
import { EditButton } from '../../../../../components/buttons.component';
import { ITeam } from '../interfaces/team.interface';
import { isEmpty } from '../../../../../utils/validators';
import { useItemCreateTeam } from './hooks/useItemCreateTeam';

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

interface IItem {
	item: ITeam;
	column: number;
	usersOptions: IOptionsMenu[];
	teamData: ITeam[];
	isEdit: boolean;
	setTeamData: React.Dispatch<React.SetStateAction<ITeam[]>>;
}

export const ItemCreateTeam = ({
	item,
	column,
	usersOptions,
	teamData,
	isEdit,
	setTeamData,
}: IItem) => {
	const {
		value,
		personal,
		matches,
		handleValue,
		handleNewPersonal,
		handleDeletePersonal,
		handleDeleteTeam,
		handleCreateTeam,
	} = useItemCreateTeam({
		item,
		teamData,
		setTeamData,
	});

	return (
		<Grid item xs={matches ? 10 : 11} className={styles.containerScroll}>
			<Grid item xs="auto" display={'flex'} gap={3}>
				<div>
					<span style={{ fontWeight: 'bold' }}>Equipo</span>
					<Input
						id={`name-team-${column}`}
						variant="outlined"
						size="small"
						fullWidth
						name="name"
						placeholder="Escribe aquí"
						value={value}
						onChange={(e) =>
							handleValue(
								e.target.value?.toString() || '',
								column,
								0,
								e.target.name
							)
						}
					/>
				</div>
				<EditButton
					id={`save-team-${column}`}
					onClick={() => handleCreateTeam(item, column)}
					icon={<SaveIcon sx={{ fontSize: '15px' }} />}
					disabled={
						isEmpty(value) ||
						isEmpty(personal.length > 1) ||
						personal.some((value) => isEmpty(value))
					}
					styles={{
						height: 'max-content',
					}}
				/>
				{!isEdit && (
					<EditButton
						onClick={() => handleDeleteTeam(column, item._id)}
						icon={<Delete sx={{ fontSize: '15px' }} />}
						styles={{
							background: 'var(--color-error-light)',
							color: 'var(--color-error-main)',
							height: 'max-content',
						}}
					/>
				)}
			</Grid>
			<Grid item xs="auto">
				{item.personals.map((element, index) => {
					return (
						<div key={`team-${column}-${index}`}>
							{index === 0 && (
								<span style={{ fontWeight: 'bold' }}>Integrantes</span>
							)}
							<Grid item marginTop={'10px'}>
								<InputSelect
									id={`select-user-${column}-${index}`}
									optionsMenu={usersOptions}
									onChange={(e) =>
										handleValue(
											e.target.value?.toString() || '',
											column,
											index
										)
									}
									value={usersOptions.length > 0 ? personal[index] : ''}
									placeholder="Selecciona"
									width="350px"
								/>

								<EditButton
									id={`delete-user-${column}-${index}`}
									onClick={() => handleDeletePersonal(column, index)}
									icon={<Delete sx={{ fontSize: '15px' }} />}
									styles={{
										background: 'var(--color-error-light)',
										color: 'var(--color-error-main)',
										marginLeft: '20px',
									}}
									disabled={!((item?.personals?.length || 0) > 1)}
								/>
							</Grid>
						</div>
					);
				})}
				<span
					className={styles.labelTeam}
					onClick={() => handleNewPersonal(column)}
					id={`add-personal-${column}`}
				>
					+ Añadir miembro al equipo
				</span>
			</Grid>
		</Grid>
	);
};
