/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import { IFunctionalResponse } from '../../../interfaces/project.interface';
import { attachSublevels } from '../../../utils/attachSublevels';

interface ICronogramaProps {
	setUFOptions: TReactDispatch<IOptionsMenu[]>;
}
const useCronograma = (props: ICronogramaProps) => {
	const { setUFOptions } = props;
	const dispatch = useDispatch<any>();

	const {
		execute: getFreq,
		status: getStatusFreq,
		value: getValueFreq,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.FRECUENCY,
		method: 'GET',
	});

	const {
		execute: updateFreq,
		status: updatedStatusFreq,
		value: updatedValueFreq,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_FRECUENCY,
		method: 'POST',
	});

	const {
		execute: updateNonWorkingDays,
		status: updatedStatusNonWorkingDays,
		value: updatedValueNonWorkingDays,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.NON_WORKING_DAYS,
		method: 'POST',
	});

	const {
		execute: getNonWorkingDays,
		status: getStatusNonWorkingDays,
		value: getValueNonWorkingDays,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.NON_WORKING_DAYS,
		method: 'GET',
	});

	const {
		execute: getActivity,
		status: statusActivity,
		value: valueActivity,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.ACTIVITY,
		method: 'GET',
	});

	const {
		execute: updateActivity,
		status: updatedStatusActivity,
		value: updatedValueActivity,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_ACTIVITIES,
		method: 'POST',
	});

	const {
		execute: getUF,
		status: statusGetUF,
		value: valueUF,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.FUNCTIONAL,
		method: 'GET',
	});

	const {
		execute: getACT_UF,
		status: statusGetACT_UF,
		value: valueGetACT_UF,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	const {
		execute: updatedACT_UF,
		status: statusUpdatedACT_UF,
		value: valueUpdatedACT_UF,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_SUPERVISION_UNIT,
		method: 'POST',
	});

	const {
		execute: updateProject,
		status: statusUpdateProject,
		value: valueUpdatedProject,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.CREATE_PROJECT,
		method: 'PATCH',
	});

	useEffect(() => {
		if (updatedStatusFreq === 'success') {
			dispatch(stopLoading());
		} else if (updatedStatusFreq === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [updatedStatusFreq]);

	useEffect(() => {
		if (updatedStatusNonWorkingDays === 'success') {
			dispatch(stopLoading());
		} else if (updatedStatusNonWorkingDays === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [updatedStatusNonWorkingDays]);

	useEffect(() => {
		if (getStatusNonWorkingDays === 'success') {
			dispatch(stopLoading());
		} else if (getStatusNonWorkingDays === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [getStatusNonWorkingDays]);

	useEffect(() => {
		if (statusActivity === 'success') {
			dispatch(stopLoading());
		} else if (statusActivity === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusActivity]);

	useEffect(() => {
		if (getStatusFreq === 'success') {
			dispatch(stopLoading());
		} else if (getStatusFreq === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [getStatusFreq]);

	useEffect(() => {
		if (updatedStatusActivity === 'success') {
			dispatch(stopLoading());
		} else if (updatedStatusActivity === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [updatedStatusActivity]);

	useEffect(() => {
		if (!valueUF || (valueUF?.data?.length || 0) < 1) return;
		dispatch(stopLoading());
		const _adapted: IFunctionalResponse[] = valueUF.data;
		const resultArray = attachSublevels(_adapted);
		setUFOptions(
			resultArray.map((i) => ({
				id: i.id,
				label: i.value,
				value: i.id,
				status: i.status,
			}))
		);
	}, [valueUF]);

	useEffect(() => {
		if (statusGetACT_UF === 'success') {
			dispatch(stopLoading());
		} else if (statusGetACT_UF === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusGetACT_UF]);

	useEffect(() => {
		if (statusUpdatedACT_UF === 'success') {
			dispatch(stopLoading());
		} else if (statusUpdatedACT_UF === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusUpdatedACT_UF]);

	useEffect(() => {
		if (statusUpdateProject === 'success') {
			dispatch(stopLoading());
		} else if (statusUpdateProject === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrio un error al actualizar el proyecto',
					severity: 'error',
				})
			);
		}
	}, [statusUpdateProject]);

	return {
		//FREQ
		getFreq,
		getStatusFreq,
		getValueFreq,
		//IG
		updateFreq,
		updatedStatusFreq,
		updatedValueFreq,
		//NON WORKING DAYS
		updateNonWorkingDays,
		updatedStatusNonWorkingDays,
		updatedValueNonWorkingDays,
		//ACTIVITY
		getActivity,
		statusActivity,
		valueActivity,
		//UPDATE ACTIVITY
		updateActivity,
		updatedStatusActivity,
		updatedValueActivity,
		//UF
		getUF,
		statusGetUF,
		valueUF,
		//ACT-UF
		getACT_UF,
		statusGetACT_UF,
		valueGetACT_UF,
		//UPDATE-ACT-UF
		updatedACT_UF,
		statusUpdatedACT_UF,
		valueUpdatedACT_UF,
		//UPDATE PROJECT
		updateProject,
		statusUpdateProject,
		valueUpdatedProject,
		//GET
		getNonWorkingDays,
		getStatusNonWorkingDays,
		getValueNonWorkingDays,
	};
};

export default useCronograma;
