/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import backgroundCard from '../assets/imgs/no-image-found.png';

//@ts-ignore
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { useEffect, useState } from 'react';
import Modal from './modal.component';
import { IModal, DefaultModal } from '../interfaces/modal.interface';
import { Badge } from '@mui/material';

interface IImageCarousel {
	images: { label: string; imgPath: string; id: string }[];
}

const ImageCarousel = ({ images }: IImageCarousel) => {
	const theme = useTheme();
	const [imgPreviewValid, setImgPreviewValid] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = images?.length || 0;

	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};

	useEffect(() => {
		if (modal.open) {
			setTimeout(() => {
				setActiveStep(activeStep === images.length - 1 ? 0 : activeStep + 1);
			}, 3000);
		}
	}, [modal.open, activeStep]);

	const downloadImage = (url: string) => {
		document.location = url;
	};

	const isImageValid = (url: string) => {
		return new Promise((resolve) => {
			const img = new Image();
			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
			img.src = url;
		});
	};

	useEffect(() => {
		isImageValid(images[0].imgPath).then((isValid) =>
			isValid ? setImgPreviewValid(true) : setImgPreviewValid(false)
		);
	}, []);

	return (
		<>
			{maxSteps > 0 && (
				<>
					<Badge badgeContent={maxSteps} color="primary">
						<img
							src={imgPreviewValid ? images[0].imgPath : backgroundCard}
							alt={images[0].label}
							onClick={() => {
								setModal({ ...modal, open: true });
							}}
							style={{
								cursor: 'pointer',
								height: '200px',
								width: 'max-content',
								backgroundColor: 'red',
							}}
						/>
					</Badge>
					<Modal
						open={modal.open}
						title={'Fotos'}
						width="lg"
						modal={modal}
						setModal={setModal}
						onClose={modal.onClose}
						hideCancel
						hideConfirm
					>
						<Box
							sx={{
								height: 'auto',
							}}
						>
							<SwipeableViews
								axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={activeStep}
								springConfig={{
									duration: '0.8s',
									delay: '0s',
									easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
								}}
								onChangeIndex={handleStepChange}
								enableMouseEvents
							>
								{images.map((step, index) => (
									<div key={`button-${index}`}>
										<Button
											onClick={() => downloadImage(step.imgPath)}
											style={{
												color: '#0080F9',
												textTransform: 'initial',
											}}
										>
											Descargar imagen
										</Button>
										<div
											key={step.id}
											style={{
												display: 'flex',
												justifyContent: 'center',
												height: '75vh',
											}}
										>
											{Math.abs(activeStep - index) <= 2 ? (
												<Box
													component="img"
													sx={{
														display: 'block',
														overflow: 'hidden',
													}}
													src={step.imgPath}
													alt={step.label}
												/>
											) : null}
										</div>
									</div>
								))}
							</SwipeableViews>
							<MobileStepper
								steps={maxSteps}
								position="static"
								activeStep={activeStep}
								nextButton={
									<Button
										size="small"
										onClick={handleNext}
										disabled={activeStep === maxSteps - 1}
									>
										{theme.direction === 'rtl' ? (
											<KeyboardArrowLeft />
										) : (
											<KeyboardArrowRight />
										)}
									</Button>
								}
								backButton={
									<Button
										size="small"
										onClick={handleBack}
										disabled={activeStep === 0}
									>
										{theme.direction === 'rtl' ? (
											<KeyboardArrowRight />
										) : (
											<KeyboardArrowLeft />
										)}
									</Button>
								}
							/>
						</Box>
					</Modal>
				</>
			)}
		</>
	);
};

export default ImageCarousel;
