import { IOptionsMenu } from '../../../../../../interfaces/select.interface';

export const activeOptions: IOptionsMenu[] = [
	{
		id: 0,
		label: 'Inactivo',
		value: 0,
	},
	{
		id: 1,
		label: 'Activo',
		value: 1,
	},
];
