import { Paper, Box, Grid } from '@mui/material';
import { Button } from './main-button.component';
import { useCreateBase } from '../hooks/useCreateBase';
import VerticalStepper from './vertical-stepper-fixed.component';
import styles from './styles/stepper-styles.module.css';
import { CreateBaseRow } from './create-base-row.component';
import { IIndicatorModel } from '../interfaces/project.interface';
import { IAdaptedValues } from '../interfaces/edit-adapted-values';

interface ICreateBase {
	header?: JSX.Element;
	label: string;
	inputsCustom?: IAdaptedValues[];
	button?: JSX.Element;
	onSaveCustom: (newBase: IIndicatorModel) => void;
	onBackCustom?: () => void;
}

export const CreateBase = ({
	header,
	label,
	inputsCustom,
	button,
	onSaveCustom,
	onBackCustom,
}: ICreateBase) => {
	const {
		inputs,
		matches,
		attributes,
		mainAttribute,
		disabledButton,
		navigate,
		setAttributes,
		setMainAttribute,
		setName,
		onSave,
	} = useCreateBase({ label, inputsCustom, onSaveCustom });

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					{header}
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="criterios-table"
							sxContainer={styles.containerVerticalStepper}
							sxElement={styles.verticalStepperSxElement}
							items={inputs}
							renderItem={(item, column) => (
								<CreateBaseRow
									item={item}
									onSave={() => null}
									attributes={attributes}
									setAttributes={setAttributes}
									mainAttribute={mainAttribute}
									setMainAttribute={setMainAttribute}
									setName={setName}
								/>
							)}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Regresar"
							onClick={() => (onBackCustom ? onBackCustom() : navigate(-1))}
							variant="outlined"
							style={{
								color: ' var(--color-error-main)',
								width: '200px',
							}}
						/>
					</Grid>
					{button}
					<Grid item>
						<Button
							label="Guardar"
							onClick={onSave}
							variant="outlined"
							style={{
								backgroundColor: ' #0080F9',
								width: '200px',
								color: 'white',
							}}
							disabled={disabledButton}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
