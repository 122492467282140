/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper } from '@mui/material';
import { useDevices } from './hooks/useDevices';
import { columns } from './utils/columns-devices.utils';
import TableCustom from '../../components/table-custom.component';

const Devices = () => {
	const { data, setData } = useDevices();

	return (
		<Paper elevation={0}>
			<Box padding={3}>
				<TableCustom
					titleHeader="Dispositivos"
					columns={columns}
					data={data}
					setData={setData}
					showPagination
					showLimitPerPage
				/>
			</Box>
		</Paper>
	);
};

export default Devices;
