import theme from '../../../../config/theme';
import { InfoCards } from '../info-cards.component';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import {
	GpsFixedOutlined,
	InfoOutlined,
	ListAltRounded,
	PortraitRounded,
} from '@mui/icons-material';
import {
	activitiesList,
	dimensionsListLvl1,
	dimensionsListLvl2,
	generalData,
	standarsList,
} from './utils/actions-attributes.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export const AttributesList = () => {
	const { levels } = useSelector((state: RootState) => state.projectBase);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'space-between'}
					alignItems="center"
				>
					<Grid item>
						<h3>Atributos del proyecto</h3>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12} md={6} xl={3}>
				<InfoCards
					icon={InfoOutlined}
					label="Datos generales"
					actions
					actionsList={generalData}
				/>
			</Grid>
			<Grid item xs={12} md={6} xl={3}>
				<InfoCards
					icon={PortraitRounded}
					label="Actividades"
					actions
					actionsList={activitiesList}
				/>
			</Grid>
			<Grid item xs={12} md={6} xl={3}>
				<InfoCards
					icon={ListAltRounded}
					label="Criterios"
					actions
					actionsList={standarsList}
				/>
			</Grid>
			<Grid item xs={12} md={6} xl={3}>
				<InfoCards
					icon={GpsFixedOutlined}
					label="Dimensiones geográficas"
					actions
					actionsList={levels > 1 ? dimensionsListLvl2 : dimensionsListLvl1}
				/>
			</Grid>
		</Grid>
	);
};
