import { ITable } from '../../../interfaces/criterios.interface';

export interface IRol extends ITable {
	isActive: number;
}

export type TRolTable = IRol[];

export const DefaultTabItems = [
	{
		label: 'Creación de roles',
		value: 0,
		isDisabled: false,
	},
	{
		label: 'Creación de equipos',
		value: 1,
		isDisabled: false,
	},
	{
		label: 'Asignación de supervisiones',
		value: 2,
		isDisabled: false,
	},
];
