import { Divider, Grid } from '@mui/material';
import { InsertDriveFileOutlined, RemoveRedEye } from '@mui/icons-material';
import { Button } from '../../../../../components/main-button.component';
import TableCustom from '../../../../../components/table-custom.component';
import { columnsAllActions } from '../../../utils/columns-supervision';
import { SupervisionCardComponent } from '../../../../../components/supervision-card.component';
import { useSummaryRoutineDetail } from '../hooks/useSummaryRoutineDetail';
import { useLocation } from 'react-router-dom';
import { ViewAllActions } from './view-all-actions.component';

export const SummaryRoutineDetail = () => {
	const location = useLocation();
	const supervisorName = location?.state?.supervisorName as string;
	const {
		dataActivites,
		graphic,
		descriptionRoutine,
		reports,
		columnsActivities,
		stepRoutine,
		navigate,
		setDataActivities,
		downloadDocument,
		dataActions,
		setDataActions,
		supervisorDescription,
		setStepRoutine,
	} = useSummaryRoutineDetail();

	return (
		<Grid container spacing={2}>
			<Grid item sm={12}>
				<h2 style={{ margin: 0 }}>Resumen de rutina</h2>
			</Grid>
			<Grid item sm={12}>
				<h4 style={{ margin: 0 }}>{descriptionRoutine}</h4>
				<h5 style={{ padding: '1px' }}>
					{supervisorName || supervisorDescription}
				</h5>
			</Grid>
			{stepRoutine === '1' ? (
				<>
					<Grid item sm={12} md={6}>
						<SupervisionCardComponent
							supervisionTitle={descriptionRoutine}
							value={graphic.percentage}
							completedTasks={graphic.completedTasks}
							pendingSupervision={graphic.pendingSupervision}
							pendingValidation={graphic.pendingValidation}
							suspendedTasks={graphic.suspendedTasks}
							isDetail
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid
							container
							style={{
								backgroundColor: '#eaeaea',
								borderRadius: '15px',
							}}
							paddingX={2}
							paddingY={1}
							columnGap={1}
							rowGap={2}
							alignItems="center"
						>
							<Grid item>
								<InsertDriveFileOutlined style={{ display: 'flex' }} />
							</Grid>
							<Grid item>Documentacion</Grid>
						</Grid>
						<Grid
							container
							style={{
								backgroundColor: '#fbfbfb',
								borderRadius: '15px',
							}}
							paddingX={2}
							paddingY={1}
							columnGap={1}
							rowGap={2}
							alignItems="center"
						>
							{reports.map((e: any) => (
								<Grid key={e._id} item xs={12}>
									<span
										style={{ color: '#0080F9', cursor: 'pointer' }}
										onClick={() => downloadDocument(e._id)}
									>
										{e.name}
									</span>
									<Divider />
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<div
							style={{
								backgroundColor: '#fbfbfb',
							}}
						>
							<TableCustom
								columns={columnsActivities}
								data={dataActivites}
								setData={setDataActivities}
								hideHeader
							/>
						</div>
					</Grid>
					<Grid item xs={12} md={6}>
						<div
							style={{
								backgroundColor: '#fbfbfb',
							}}
						>
							<TableCustom
								columns={columnsAllActions}
								data={dataActions}
								setData={setDataActions}
								buttons={[
									{
										icon: RemoveRedEye,
										label: 'Ver todas',
										execute: () => {
											setStepRoutine('2');
											setDataActions({
												...dataActions,
												rowsPerPage: '10',
											});
										},
										type: 'blue',
										variant: 'outlined',
										width: 150,
										id: 'view-all-action',
									},
								]}
								showSearch={false}
							/>
						</div>
					</Grid>
				</>
			) : (
				<ViewAllActions
					dataActions={dataActions}
					setDataActions={setDataActions}
					setStepRoutine={setStepRoutine}
				/>
			)}
			<Grid item sm={12}>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<Button
							label="Regresar"
							onClick={() => navigate(-1)}
							variant="solid"
							style={{
								width: '100%',
								backgroundColor: '#0080F9',
								padding: '20px',
								marginTop: '20px',
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
