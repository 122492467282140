/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAdaptedData, getSelectOptionsData } from '../../../utils/get-adapted-data';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import { IValuesInfo } from '../../../interfaces/edit-values.interface';
import { IOptionsMenu } from '../../../interfaces/select.interface';

export const useGetActivities = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [activitiesOptions, setActivitiesOptions] = useState<IAdaptedValues[]>([]);
	const [activitiesSelectOptions, setActivitiesSelectOptions] = useState<
		IOptionsMenu[]
	>([]);

	const {
		execute: getActivities,
		status: statusGetActivities,
		value: valueGetActivities,
	} = useApiRequest<unknown, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.ACTIVITIES.GET_ACTIVITIES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetActivities === 'success') {
			dispatch(stopLoading());
			setActivitiesOptions(getAdaptedData(valueGetActivities?.data || []));
			setActivitiesSelectOptions(
				getSelectOptionsData(valueGetActivities?.data || [])
			);
		} else if (statusGetActivities === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar las frecuencias',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetActivities]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getActivities(null, `/${idProject}`);
	}, []);

	return {
		activitiesOptions,
		activitiesSelectOptions,
		valueGetActivities,
		setActivitiesOptions,
	};
};
