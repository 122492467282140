/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { stopLoading, setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { ITeam } from '../../interfaces/team.interface';

export const useUpdateTeams = () => {
	const dispatch = useDispatch();

	const { execute: updateTeam, status: statusUpdateTeam } = useApiRequest<
		Partial<ITeam>,
		IResponsePayload<ITeam>
	>({
		path: URLS.EDIT_PROJECT.TEAMS.GET_TEAMS,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusUpdateTeam === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Equipo actualizado con éxito',
					severity: 'success',
				})
			);
		} else if (statusUpdateTeam === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar el equipo',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUpdateTeam]);

	const handleUpdateTeam = (body: Partial<ITeam>, _id: string) => {
		dispatch(setLoading({ loading: true }));
		updateTeam(body, `/${_id}`);
	};

	return {
		handleUpdateTeam,
	};
};
