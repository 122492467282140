import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const AddButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	color: '#0080F9',
}));

interface IProps {
	onClick: () => void;
	disabled: boolean;
	id?: string;
	text?: string;
	sx?: React.CSSProperties;
}
const AddBtn = (props: IProps) => {
	const {
		onClick,
		disabled,
		id = 'btn-submit-attr',
		text = '+ Agregar otro atributo',
		sx = {},
	} = props;
	return (
		<AddButton id={id} variant="text" sx={sx} onClick={onClick} disabled={disabled}>
			{text}
		</AddButton>
	);
};

export default AddBtn;
