export interface IModal {
	title?: string;
	open: boolean;
	body: string | JSX.Element;
	type: string;
	labelButton: string;
	hideConfirm?: boolean;
	hideCancel?: boolean;
	callback?: () => void;
	onClose?: () => void;
}

export const DefaultModal: IModal = {
	title: '',
	open: false,
	hideConfirm: true,
	body: '',
	type: '',
	labelButton: '',
	callback: () => null,
	onClose: () => null,
};
