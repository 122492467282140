/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { IResponseUser } from '../../../../../../interfaces/new-user.interface';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { IOptionsMenu } from '../../../../../../interfaces/select.interface';

export const useGetUsers = () => {
	const dispatch = useDispatch();
	const [usersOptions, setUsersOptions] = useState<IOptionsMenu[]>([]);

	const {
		execute: getUsers,
		status: statusGetUsers,
		value: valueGetUsers,
	} = useApiRequest<any, IResponsePayload<IResponseUser>>({
		path: URLS.USERS.GET_USERS,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getUsers();
	}, []);

	useEffect(() => {
		if (statusGetUsers === 'success') {
			dispatch(stopLoading());
			setUsersOptions(
				(valueGetUsers?.data || []).map((user) => {
					return {
						id: user._id,
						label: user.firstName + ' ' + user.lastName,
						value: user._id,
					};
				})
			);
		} else if (statusGetUsers === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error consultar los usuarios',
					severity: 'error',
				})
			);
		}
	}, [statusGetUsers]);

	return {
		usersOptions,
	};
};
