import { IGetPersonalOptions, ITeam } from '../interfaces/user.interface';

export const teamToPersonal = (data: ITeam[]): IGetPersonalOptions[] => {
	const userData: IGetPersonalOptions[] = data.map((team) => {
		return {
			_id: team?._id || '',
			firstName: team?.teamName || '',
			lastName: team?.teamName || '',
			fullName: team?.teamName || '',
		};
	});

	return userData;
};

export const getUsersType = (
	dataPersonal: IGetPersonalOptions[],
	dataTeam: ITeam[],
	addAllOption?: boolean
): boolean[] => {
	const relationsUsers: boolean[] = [];
	if (addAllOption) relationsUsers.push(false);
	dataPersonal.forEach(() => {
		relationsUsers.push(false);
	});
	dataTeam.forEach(() => {
		relationsUsers.push(true);
	});

	return relationsUsers;
};
