import { useState } from 'react';
import { IVerticalStepperAttributes } from '../../../interfaces/vertical.steper.attributes-fixed.interface';
import StepOneFixed, { TFunctionHandler } from '../../../components/step-one-fixed';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';

export const defaultActivities: IVerticalStepperAttributes[] = [
	{
		index: 0,
		name: 'Actividades',
		placeholder: 'Nombre de la actividad',
		value: '',
		isComplete: false,
	},
	{
		index: 1,
		name: 'Atributo Principal',
		placeholder: 'Nombre del atributo',
		value: '',
		isComplete: false,
	},
];

interface IStep {
	onContinue?: (data: IStepTwoModel[]) => void;
	defaultActividad: IVerticalStepperAttributes[] | null;
	disableNext: boolean | null;
	refComponent: React.RefObject<TFunctionHandler>;
}

const StepOneContent = (props: IStep) => {
	const { onContinue, defaultActividad, disableNext, refComponent } = props;
	const [activities, setActivities] =
		useState<IVerticalStepperAttributes[]>(defaultActivities);
	return (
		<StepOneFixed
			ref={refComponent}
			data={activities}
			disableNext={disableNext}
			setData={setActivities}
			onContinue={onContinue}
			defaultData={defaultActividad}
		/>
	);
};

export default StepOneContent;
