/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper } from '@mui/material';
import TableCustom from '../../../components/table-custom.component';
import { columnsAllActions } from '../utils/table-columns.utils';
import { useEffect } from 'react';
import { GoBack } from '../../../components/go-back-component';
import { useLocation } from 'react-router-dom';
import { useAllActions } from '../hooks/useAllActions';
import { setValuePP } from '../../../store/modules/profile-project';
import { useDispatch } from 'react-redux';

export const SupervisionViewActions = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location.state.params;
	const { dataActions, setDataActions, executeAllActions } = useAllActions();

	useEffect(() => {
		executeAllActions(
			{},
			`/?routine=${state?.routine}&page=${dataActions?.pagination}&recordsByPage=${dataActions?.rowsPerPage}`
		);
	}, []);

	return (
		<>
			{state.planeacion ? (
				<GoBack action={() => dispatch(setValuePP({ step: 3 }))} />
			) : (
				<GoBack />
			)}
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Historial de supervisión
							</span>
							<br />
							<span style={{ fontSize: '17px', color: '#707070' }}>
								{state.routine.routineDescription}
							</span>
						</Grid>
						<Grid item xs={12}>
							<TableCustom
								columns={columnsAllActions}
								data={dataActions}
								setData={setDataActions}
								showLimitPerPage
								showPagination
								hideHeader
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
