import { configureStore, compose, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';

import userReducer from './modules/user';
import loadingReducer from './modules/loading';
import profileProject from './modules/profile-project';
import stateProject from './modules/steps';
import snackbar from './modules/snackbar';
import utilsSlice from './modules/utils';
import projectBase from './modules/project';
import notificationReducer from './modules/notifications';
import pageTable from './modules/page-table';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}
export const mainReducer = combineReducers({
	users: userReducer,
	loading: loadingReducer,
	projectBase: projectBase,
	profileProject: profileProject,
	stateStep: stateProject,
	snackbar: snackbar,
	utils: utilsSlice,
	notifications: notificationReducer,
	pageTable: pageTable,
});

const persistedReducer = persistReducer(
	{ key: 'root', storage, whitelist: ['users', 'projectBase'], version: 1 },
	mainReducer
);

const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunkMiddleware],
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
