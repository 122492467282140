//Resources
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
	Box,
	Divider,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { resetState, setState } from '../../store/modules/steps';
import { Button } from '../../components/main-button.component';
import { openBase64InNewTab } from '../../utils/openBase64';
import { GoBack } from '../../components/go-back-component';
import styles from './styles/review-documents.module.css';
import { useDispatch } from 'react-redux';
import { IDocument, IProject } from '../../interfaces/project.interface';
import { DOCS_TYPES } from '../../constants/files-type';

interface IReviewDocuments {
	data: IProject;
	saveData?: () => void;
	deleteDocument: (document: IDocument) => void;
	withImages?: boolean;
}

function ReviewDocuments({
	data,
	saveData,
	deleteDocument,
	withImages = false,
}: IReviewDocuments) {
	const dispatch = useDispatch();
	const temporalFiles = withImages
		? data.files
		: data.files.filter((file) => file.type === DOCS_TYPES.PDF);
	const matches = useMediaQuery(useTheme().breakpoints.down('sm'));

	return (
		<>
			<GoBack
				action={() => {
					dispatch(setState({ state: 1 }));
				}}
			/>
			<Paper>
				<Box padding={3}>
					<Grid
						container
						component="section"
						display="flex"
						alignContent="space-between"
						alignItems="center"
						justifyContent="center"
						sx={{ height: '100%' }}
						gap={3}
					>
						<Grid item xs={12}>
							<span style={{ fontSize: '20px', fontWeight: 'bold' }}>
								Subir documento
							</span>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						{temporalFiles.length > 0 ? (
							temporalFiles.map((element: IDocument, key: number) => {
								return (
									<Grid
										className={styles.eachDocument}
										container
										item
										xs={12}
										p={2}
										display="flex"
										alignContent="space-between"
										sx={{
											height: { xs: 'auto' },
											backgroundColor: '#F3F4F5',
										}}
										key={key}
									>
										<Grid
											xs={12}
											sm={9}
											lg={10}
											item
											container
											className={styles.eachDocumentInformation}
											display="flex"
											flexDirection="column"
										>
											<Grid item xs={12} lg={12}>
												<Typography
													variant="subtitle1"
													sx={{
														textAlign: 'left',
														whiteSpace: 'normal',
														overflowWrap: 'break-word',
													}}
												>
													{element.name}
												</Typography>
											</Grid>
											<Grid item xs={12} lg={12}>
												<ul
													className={styles.eachDocumentDetails}
												>
													<li>Documento</li>
													<li>{element.size + 'Mb'}</li>
													<li>{element.type}</li>
												</ul>
											</Grid>
										</Grid>
										<Grid
											xs={10}
											sm={3}
											md={3}
											lg={2}
											className={styles.eachDocumentActions}
											item
										>
											<div
												className={styles.actions}
												onClick={() =>
													element._id
														? window.open(
																element.url,
																'_blank'
														  )
														: openBase64InNewTab(
																element.url,
																'application/pdf'
														  )
												}
											>
												<RemoveRedEyeOutlinedIcon />
											</div>

											<div
												className={styles.actions}
												onClick={() => deleteDocument(element)}
											>
												<DeleteOutlineOutlinedIcon />
											</div>
										</Grid>
									</Grid>
								);
							})
						) : (
							<span>Sin documentos cargados</span>
						)}
						<Grid
							item
							xs={12}
							display="flex"
							justifyContent={matches ? 'center' : 'flex-end'}
							mt={5}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: matches ? 'column' : 'row',
									gap: '10px',
								}}
							>
								<Button
									label="Subir otro archivo"
									variant="outlined"
									style={{
										width: matches ? '100%' : '160px',
										color: '#0080F9',
										padding: '20px',
									}}
									onClick={() => dispatch(setState({ state: 1 }))}
								/>
								<Button
									label="Continuar"
									variant="solid"
									style={{
										width: matches ? '100%' : '160px',
										backgroundColor: '#0080F9',
										padding: '20px',
									}}
									onClick={() =>
										saveData ? saveData() : dispatch(resetState())
									}
								/>
							</div>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
}
export default ReviewDocuments;
