/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies

// Components
import { Grid } from '@mui/material';
import TextInput from '../../components/text-input.component';
import { Button } from '../../components/main-button.component';
import CustomAlert from '../../components/alert.component';

// Resources
import styles from './styles/styles.module.css';

import theme from '../../config/theme';
import { useResetPassword } from './hooks/useResetPassword';

const Login = () => {
	const {
		error,
		data,
		errorEmail,
		token,
		disabledButton,
		handleValidate,
		handleSetData,
	} = useResetPassword();

	return (
		<Grid className={styles.subContainer} container spacing={0}>
			<Grid className={styles.mainContainerImgs} item xs={12} md={6} />
			<Grid className={styles.mainContainerForm} item xs={12} md={6}>
				<div className={styles.subContainerForm}>
					<div className={styles.subContainerFormInfo}>
						<div className={styles.containerTitle}>
							<label className={styles.title}>REINICIAR CONTRASEÑA</label>
						</div>
						<TextInput
							id="email"
							name="email"
							label="Ingresa tu correo electrónico"
							placeholder="Correo electrónico"
							value={data.email}
							onChange={handleSetData}
							contentStyles={{ marginBottom: 40 }}
							error={!errorEmail.valid}
							helperText={!errorEmail.valid ? errorEmail.msg : ''}
						/>
						{token && (
							<TextInput
								id="password"
								name="password"
								label="Ingresa tu contraseña"
								placeholder="Contraseña"
								value={data.password}
								contentStyles={{ marginBottom: 40 }}
								type="password"
								onChange={handleSetData}
							/>
						)}
						<CustomAlert
							message={error.message}
							severity={error.severity}
							show={error.show}
							id="alert-message-login"
						/>
						<Button
							id="reset-btn"
							label="Reiniciar contraseña"
							onClick={handleValidate}
							variant="solid"
							style={{
								height: 50,
								backgroundColor: theme.palette.secondary.main,
							}}
							disabled={disabledButton}
						/>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};
export default Login;
