/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { getTableColumns } from '../../../utils/tableInfo';
import { setAlert } from '../../../store/modules/snackbar';
import { stopLoading } from '../../../store/modules/loading';
import { setLoading } from './../../../store/modules/loading';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { activitiesSHAdapter } from '../adapters/activities-adapter-sh.adapter';
import { evidencesSHAdapter } from '../adapters/evidences-adapter-sh.adapter';
import { reportsSHAdapter } from '../adapters/reports-adapter-sh.adapter';
import { actionsSHAdapter } from '../adapters/actions-adapter-sh.adapter';
import { editRoutineSupervisionAdapter } from '../adapters/editRoutineSupervisionAdapter.adapter';

export function useSupervisionHistorySummary() {
	const { data: dataAction, setData: setDataAction } = useTableCustom<Object>();
	const { data: dataEvidences, setData: setDataEvidences } = useTableCustom<Object>();
	const { data: dataActivities, setData: setDataActivities } = useTableCustom<Object>();
	const { data: dataReports, setData: setDataReports } = useTableCustom<Object>();
	const dispatch = useDispatch();

	const { functionalBase, functionalParentBase } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [columnsActivities] = useState(
		getTableColumns(
			functionalBase
				? [
						'Actividades Supervisadas',
						functionalParentBase?.name || 'Nivel 1',
						functionalBase?.name || 'Nivel 2',
				  ]
				: ['Actividades Supervisadas', functionalParentBase?.name || 'Nivel 1']
		)
	);

	const [dataRoutine, setDataRoutine] = useState<{
		routine: string;
		supervisor: string;
		supervisorName: string;
		status: string;
		idProject: string;
		description: string;
		canBeExecuted: boolean;
	}>({
		routine: '',
		supervisor: '',
		supervisorName: '',
		status: '',
		idProject: '',
		description: '',
		canBeExecuted: false,
	});

	const {
		execute: executeGetHistory,
		status: statusGetHistory,
		value: valueHistory,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.SUPERVISION_HISTORY.HISTORY_SUMMARY,
		method: 'GET',
	});

	const downloadReport = (fileNameKey: string) => {
		dispatch(setLoading({ loading: true }));
		executeGetReport({ key: fileNameKey });
	};

	useEffect(() => {
		if (statusGetHistory === 'success') {
			dispatch(stopLoading());

			setDataAction({
				...dataAction,
				countRows: valueHistory?.totalRecords || 0,
				rows: actionsSHAdapter(valueHistory?.detail?.actions || []),
				sort: {
					col: '',
					order: 'ASC',
				},
			});

			setDataActivities({
				...dataActivities,
				countRows: valueHistory?.totalRecords || 0,
				rows: activitiesSHAdapter(valueHistory?.detail?.units || []),
			});

			setDataEvidences({
				...dataEvidences,
				countRows: valueHistory?.totalRecords || 0,
				rows: evidencesSHAdapter(valueHistory?.detail?.findingFiles || []),
			});

			setDataReports({
				...dataReports,
				countRows: valueHistory?.totalRecords || 0,
				rows: reportsSHAdapter(
					valueHistory?.detail?.reports || [],
					downloadReport
				),
			});

			setDataRoutine(editRoutineSupervisionAdapter(valueHistory));
		} else if (statusGetHistory === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueHistory?.message || 'Ocurrió un error al cargar los datos.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetHistory]);

	const {
		execute: executeGetReport,
		status: statusGetReport,
		value: valueGet,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.REPORTS.GET_URL_REPORT,
		method: 'POST',
	});

	useEffect(() => {
		if (statusGetReport === 'success') {
			dispatch(stopLoading());
			document.location = valueGet?.detail;
		} else if (statusGetReport === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al descargar el reporte',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetReport]);

	return {
		dataAction,
		setDataAction,
		dataActivities,
		setDataActivities,
		dataEvidences,
		setDataEvidences,
		dataReports,
		setDataReports,
		executeGetHistory,
		dataRoutine,
		columnsActivities,
	};
}
