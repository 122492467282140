import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { SxProps, Theme } from '@mui/material';

interface ISaveButton {
	id?: string;
	disabled: boolean;
	onClick: () => void;
	sx?: SxProps<Theme>;
}

const sxDefault = {
	background: '#00a3ff21',
	borderRadius: '5px',
	paddingTop: '6px',
	paddingBottom: '7px',
	width: '100%',
};

const SaveButton = (props: ISaveButton) => {
	const { disabled, id, onClick, sx } = props;

	return (
		<IconButton
			sx={{
				...sxDefault,
				...sx,
			}}
			id={id}
			disabled={disabled}
			onClick={onClick}
		>
			<SaveIcon
				sx={{ color: disabled ? '#00000042' : '#0080F9', marginRight: 1 }}
			/>
			<span style={{ color: disabled ? '#00000042' : '#0080F9', fontSize: '12px' }}>
				Guardar
			</span>
		</IconButton>
	);
};

export default SaveButton;
