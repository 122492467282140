import React from 'react';
import { AlertColor } from '@mui/material';

export interface IAlert {
	show: boolean;
	message?: string | React.ReactChild;
	severity: AlertColor;
}

export const defaultAlertValue: IAlert = {
	show: false,
	message: '',
	severity: 'success',
};
