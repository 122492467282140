/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from 'react-router-dom';
import { Personal } from '../components/personal/personal.component';
import { Standars } from '../components/standars/standars.component';
import { Activities } from '../components/activities/activities.component';
import { CompleteCreation } from '../components/complete-creation/complete-creation.component';
import { GeographicalDimensions } from '../components/geographical-dimensions/geographical-dimensions.component';
import { useEffect, useState } from 'react';
import { Schedule } from '../components/schedule/schedule.component';
import { StartCreation } from '../components/start-creation/start-creation.component';
import {
	DefaultSupervisionExtraordinary,
	ISupervisionExtraordinaryData,
} from '../interfaces/supervision-extraordinary.interface';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../constants/steps-supervision-extraordinary';
import { IModal, DefaultModal } from '../../../interfaces/modal.interface';

export function useCreateSupervisionExtraordinary() {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state as string;
	const [supervisionExtraordinaryData, setSupervisionExtraordinaryData] =
		useState<ISupervisionExtraordinaryData>(DefaultSupervisionExtraordinary);

	const [modal, setModal] = useState<IModal>({
		...DefaultModal,
		onClose: () => closeModal(),
	});

	const closeModal = () => {
		setModal({ ...modal, open: false });
	};

	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	const getComponent = () => {
		switch (supervisionExtraordinaryData.step) {
			case STEPS_SUPERVISION_EXTRAORDINARY.DG_1:
			case STEPS_SUPERVISION_EXTRAORDINARY.DG_2:
				return <GeographicalDimensions />;
			case STEPS_SUPERVISION_EXTRAORDINARY.ACT_1:
			case STEPS_SUPERVISION_EXTRAORDINARY.ACT_2:
				return <Activities />;
			case STEPS_SUPERVISION_EXTRAORDINARY.CRT_1:
				return <Standars />;
			case STEPS_SUPERVISION_EXTRAORDINARY.PER_1:
				return <Personal />;
			case STEPS_SUPERVISION_EXTRAORDINARY.CRO_1:
				return <Schedule />;
			case STEPS_SUPERVISION_EXTRAORDINARY.FIN_1:
				return <CompleteCreation />;
			case STEPS_SUPERVISION_EXTRAORDINARY.FIN_2:
			default:
				return <StartCreation />;
		}
	};

	return {
		idProject,
		supervisionExtraordinaryData,
		modal,
		getComponent,
		setSupervisionExtraordinaryData,
		setModal,
	};
}
