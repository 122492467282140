/* eslint-disable react-hooks/exhaustive-deps */
import Webcam from 'react-webcam';
import { useCamera } from './hooks/useCamera';
import { Button } from './main-button.component';

interface ICamera {
	setImg: (imageSrc: string | null | undefined) => void;
	stylesCamera?: React.CSSProperties;
	user?: string;
	unitInfo?: string;
	withInfo?: boolean;
}

const CustomCamera = ({ setImg, stylesCamera, user, unitInfo, withInfo }: ICamera) => {
	const { webcamRef, captureImage, captureWithoutInfo } = useCamera({
		setImg,
		user,
		unitInfo,
	});

	return (
		<div
			style={{
				...stylesCamera,
			}}
		>
			<Webcam
				audio={false}
				ref={webcamRef}
				screenshotFormat="image/jpeg"
				style={{ borderRadius: '10px', width: '100%' }}
			/>
			<Button
				label="Capturar"
				onClick={withInfo ? captureImage : captureWithoutInfo}
				id="camara"
				style={{ marginBottom: 10 }}
			/>
		</div>
	);
};
export default CustomCamera;
