/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { CreateTeams } from './components/create-teams/create-teams.component';
import AsignacionSuperviciones from './components/asignacion-supervisiones/index';
import { CreatePersonal } from './components/create-personal/create-personal.component';

const ProjectPersonal = () => {
	const { step: projectStep } = useSelector((state: RootState) => state.projectBase);

	const getStep = () => {
		switch (projectStep) {
			case '4.0.0':
			default:
				return 0;
			case '4.0.1':
			case '4.1.0':
				return 1;
			case '4.2.0':
				return 2;
		}
	};

	const [currentTab, setCurrentTab] = useState<number>(getStep());

	useEffect(() => {
		setCurrentTab(getStep());
	}, [projectStep]);

	const RenderPage = (): JSX.Element => {
		let component: JSX.Element = <></>;
		switch (currentTab) {
			case 0:
				component = <CreatePersonal />;
				break;
			case 1:
				component = <CreateTeams />;
				break;
			case 2:
				component = (
					<AsignacionSuperviciones
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
				);
				break;
			default:
				break;
		}
		return component;
	};

	return (
		<div>
			<RenderPage />
		</div>
	);
};

export default ProjectPersonal;
