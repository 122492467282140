import { CSSProperties } from 'react';
import { SvgIconTypeMap, Tooltip } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import styles from './styles/main-button-styles.component.module.css';

interface IButton {
	label: string;
	onClick: any;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	icon?: (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }) | null;
	variant?: 'solid' | 'outlined' | 'text';
	disabled?: boolean;
	size?: 'sm';
	style?: CSSProperties;
	id?: string;
	tooltip?: string;
}
export const Button = (props: IButton) => {
	const {
		onClick,
		label,
		disabled,
		size,
		icon: Icon,
		style,
		variant,
		onMouseEnter,
		onMouseLeave,
		id,
		tooltip,
	} = props;
	return (
		<Tooltip
			title={tooltip}
			componentsProps={{
				tooltip: {
					sx: {
						backgroundColor: '#0080F9',
					},
				},
			}}
		>
			<span style={{ width: '100%' }}>
				<button
					data-style={variant}
					data-size={size}
					onClick={onClick}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					disabled={disabled}
					style={style}
					className={styles.solidButton}
					id={id}
				>
					{Icon && (
						<Icon
							data-testid={`button-${label
								.toLowerCase()
								.replace(/ /g, '-')}`}
							className={label !== '' ? styles.iconComponent : ''}
						/>
					)}
					{label}
				</button>
			</span>
		</Tooltip>
	);
};
