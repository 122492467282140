import { IColumns } from '../../../../../hooks/useTableCustom';

export const columnsReports: IColumns[] = [
	{
		text: 'Nombre',
		nameID: 'name',
	},
	{
		text: 'Fecha de creación',
		nameID: 'creationDate',
	},
	{
		text: 'Tipo de archivo',
		nameID: 'format',
	},
	{
		text: 'Documentación',
		nameID: 'documentacion',
	},
];

export const datePickers = [
	{
		id: 'date1',
		name: 'date1',
		placeholder: 'Seleccionar',
		type: 'text',
	},
	{
		id: 'date2',
		name: 'date2',
		placeholder: 'Seleccionar',
		type: 'text',
	},
];
