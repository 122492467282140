import { InsertDriveFileOutlined } from '@mui/icons-material';
import { IColumns } from '../../../interfaces/table.insterface';

export const columnsEvidences: IColumns[] = [
	{
		text: 'Evidencias',
		nameID: 'evidencias',
	},
	{
		text: 'Descripción',
		nameID: 'description',
	},
	{
		text: 'Unidad funcional',
		nameID: 'unidadFuncional',
	},
	{
		text: 'Servicio',
		nameID: 'servicio',
	},
];

export const columnsActions: IColumns[] = [
	{
		text: 'Acción',
		nameID: 'type',
	},
	{
		text: 'Día',
		nameID: 'date',
	},
	{
		text: 'Hora',
		nameID: 'hour',
	},
	{
		text: 'Ejecutó',
		nameID: 'createdBy',
	},
];
export const columnsAllActions: IColumns[] = [
	{
		text: 'Acción',
		nameID: 'type',
	},
	{
		text: 'Descripción',
		nameID: 'description',
	},
	{
		text: 'Día',
		nameID: 'date',
	},
	{
		text: 'Hora',
		nameID: 'hour',
	},
	{
		text: 'Ejecutó',
		nameID: 'createdBy',
	},
];
export const columnsActivities: IColumns[] = [
	{
		text: 'Actividades supervisadas',
		nameID: 'activities',
	},
	{
		text: 'Espacios funcionales',
		nameID: 'functional',
	},
];

export const columnsReports: IColumns[] = [
	{
		text: (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '10px',
				}}
			>
				<InsertDriveFileOutlined />
				Reportes
			</div>
		),
		nameID: 'name',
	},
];
