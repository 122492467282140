/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { getTableColumns } from '../../../utils/tableInfo';
import { setAlert } from '../../../store/modules/snackbar';
import { stopLoading } from '../../../store/modules/loading';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { activitiesSHAdapter } from '../adapters/activities-adapter-sh.adapter';

export function useAllActivities() {
	const { data: dataActivities, setData: setDataActivities } = useTableCustom<Object>();
	const dispatch = useDispatch();

	const { functionalBase, functionalParentBase } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [columnsActivities] = useState(
		getTableColumns(
			functionalBase
				? [
						'Actividades Supervisadas',
						functionalParentBase?.name || 'Nivel 1',
						functionalBase?.name || 'Nivel 2',
				  ]
				: ['Actividades Supervisadas', functionalParentBase?.name || 'Nivel 1']
		)
	);

	const {
		execute: executeAllActivities,
		status: statusAllActivities,
		value: valueAllActivities,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.SUPERVISION_EXECUTION.GET_ROUTINE_ACTIVITIES,
		method: 'GET',
	});

	useEffect(() => {
		if (statusAllActivities === 'success') {
			setDataActivities({
				...dataActivities,
				countRows: valueAllActivities?.totalRecords || 0,
				rows: activitiesSHAdapter(valueAllActivities?.data || []),
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusAllActivities === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
		dispatch(stopLoading());
	}, [statusAllActivities]);

	return {
		dataActivities,
		columnsActivities,
		setDataActivities,
		executeAllActivities,
	};
}
