import { IUser } from '../../../../interfaces/user.interface';
import { getDateLocalMX } from '../../../../utils/dates';
import {
	IReports,
	ISummaryRoutineResp,
	UnitsDetail,
} from '../summary-routine/interfaces/summary-routine.interface';

export const activitiesAdapter = (data: UnitsDetail[]) => {
	const activitiesInfo = data.map((element) => {
		return {
			0: element.activities
				.map((element) => element.activityDescription)
				.toString(),
			1: element.functionalParentName,
			2: element.functionalDescription,
		};
	});
	return activitiesInfo;
};

export const supervisorAdapter = (data?: ISummaryRoutineResp): IUser[] => {
	const supervisorInfo = data?.supervisors?.map((element: any) => {
		return {
			...element,
			name: element.firstName + ' ' + element.lastName,
			date: getDateLocalMX(element.lastConnection),
			status: element.status ? 'Activo' : 'Inactivo',
		};
	});
	return supervisorInfo || [];
};

export const reportsAdapter = (data?: IReports[]) => {
	const reportsInfo = data?.map((element: IReports) => {
		return {
			name: element.name,
			_id: element.fileNameKey,
		};
	});
	return reportsInfo || [];
};
