/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import CreationPage from '../../../../components/creation-page.component';
import { ICreationPage } from '../../../../interfaces/creation-page-interfaces';
import { estadoCreacion } from '../../../../constants/estado-creacion';
import { DefaultTabItems } from '../../constants/data.constants';
import { TReactDispatch } from '../../../../interfaces/utils';
import TipoHallazgos from './step-two.component';
import { useDispatch, useSelector } from 'react-redux';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import useHallazgos from '../../hooks/useHallazgos';
import { RootState } from '../../../../store';
import { URLS } from '../../../../constants/urls.constants';
import useUpdateProject from '../../../../hooks/useUpdateProject';
import { useNavigate } from 'react-router-dom';

interface IProps {
	currentTab: number;
	setCurrentTab: TReactDispatch<number>;
}
const Hallazgos = (props: IProps) => {
	const { currentTab, setCurrentTab } = props;
	const [showCsvHallazgos, setShowCsvHallazgos] = useState(false);
	const dispatch = useDispatch<any>();
	const { handleUpdateProject } = useUpdateProject();
	const navigate = useNavigate();
	const { _id: _projectID } = useSelector((state: RootState) => state.projectBase);
	const [isSave, setIsSave] = useState<boolean>(false);
	const {
		updateHallazgos,
		statusUpdateHallazgos,
		getHallazgos,
		hallazgos,
		setHallazgos,
	} = useHallazgos();

	const handleUpdateHallazgos = (data: IStepTwoModel[], p_isSave: boolean) => {
		dispatch(setLoading({ loading: true }));
		setIsSave(p_isSave);
		const _adapted = data.map((ele) => ele.columns[0]);
		updateHallazgos({ typeFindings: [..._adapted] }, `/${_projectID}`);
	};

	useEffect(() => {
		if (statusUpdateHallazgos === 'success' && !isSave) {
			dispatch(stopLoading());
			handleUpdateProject({ step: '4.0.0' }, _projectID || '');
			navigate('/nuevo-proyecto/personal');
		} else if (statusUpdateHallazgos === 'error') dispatch(stopLoading());
	}, [statusUpdateHallazgos, isSave]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getHallazgos(null, `/${_projectID}`);
	}, []);

	const TipoHallazgosPage: ICreationPage = {
		header: {
			show: !showCsvHallazgos,
			label: 'Estado de creación',
			stepper: {
				activeStep: 2,
				items: estadoCreacion,
				completed: { 0: true, 1: true },
			},
			tabs: {
				items: DefaultTabItems,
				value: currentTab,
				onTabChange: (_, index) => setCurrentTab(index),
			},
		},
		stepTwo: {
			title: !showCsvHallazgos ? '1. Nombre los tipos de hallazgos' : <></>,
			show: true,
			Children: (
				<TipoHallazgos
					showAltaCsv={showCsvHallazgos}
					labelItem="A continuación designa el nombre de los tipos de hallazgos asociados al proyecto. Estos aparecerán dentro de una lista desplegable durante la supervisión y permitirán identificarlos y clasificarlos."
					setShowAltaCsv={setShowCsvHallazgos}
					onContinue={(data) => {
						handleUpdateHallazgos(data, false);
					}}
					onSave={(data) => handleUpdateHallazgos(data, true)}
					data={hallazgos}
					setData={setHallazgos}
					url={URLS.PROJECT.FINDINGS}
					isDone={false}
					onCVSUpload={() => {
						setShowCsvHallazgos(true);
					}}
				/>
			),
			showExitButon: false,
		},
	};
	return <CreationPage {...TipoHallazgosPage} />;
};

export default Hallazgos;
