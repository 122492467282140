/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, NavLink } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
/** Styles and Images */
import styles from './styles/breadcrumbs.component.module.css';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { IMenuList } from '../router/utils/resources.utils';
import { getRouteList } from '../router/routes';

interface IBreadcrumbs {
	url: string;
	title: string;
}

const routes: IBreadcrumbs[] = [];

const getBreadcrumbs = (menu: IMenuList[], path?: string): IBreadcrumbs[] => {
	menu.forEach((r) => {
		routes.push({
			title: r.title,
			url: r.Component ? (path || '') + r.link : '/',
		});
		if (r.submenu) {
			return getBreadcrumbs(r.submenu, (path || '') + r.link);
		}
	});
	return routes;
};

const BreadcrumbsComponent = () => {
	const URLPath = useLocation().pathname;
	const [location, setLocation] = useState<IBreadcrumbs[]>([]);
	const { role } = useSelector((state: RootState) => state.users);
	const newBreadcrumbs = getBreadcrumbs(getRouteList(role));

	useEffect(() => {
		let path = '';
		const newUrl = URLPath.split('/')
			.filter((f) => f !== 'inicio')
			.map((url) => {
				if (url === '') {
					return {
						title: 'Inicio',
						url: '/',
					};
				}
				path += '/' + url;
				return {
					title: newBreadcrumbs.find((f) => f.url === path)?.title || '',
					url: path,
				};
			})
			.filter((e) => e.title !== '');
		setLocation(newUrl);
	}, [URLPath]);

	return (
		<>
			{location.length > 0 && (
				<>
					<Breadcrumbs
						aria-label="breadcrumb"
						separator="·"
						className={styles.breadcrumbs}
					>
						{location.map((e, i) =>
							i === location.length - 1 ? (
								<span
									key={i}
									style={{ fontWeight: '600', color: '#020221' }}
								>
									{e.title}
								</span>
							) : (
								<NavLink to={e.url} key={i} className={'disabled'}>
									{e.title}
								</NavLink>
							)
						)}
					</Breadcrumbs>

					{/* {!matches && location.length > 1 && (
						<div
							className={styles.arrowFunction}
							onClick={() => navigate(location[location.length - 2].url)}
						>
							<div className={styles.arrowReturn}>
								<ArrowBackIosOutlined
									style={{ fontSize: '8px', padding: 0, margin: 0 }}
								/>
							</div>
							<span>Regresar</span>
						</div>
					)} */}
				</>
			)}
		</>
	);
};

export default BreadcrumbsComponent;
