/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles/successful-report-styles.module.css';
import { Button } from '../../../../../components/main-button.component';
import confirmationImage from '../../../../../assets/imgs/confirmation.png';
import { setValuePP } from '../../../../../store/modules/profile-project';
import { useEffect } from 'react';
import { RootState } from '../../../../../store';
import { useGenerateReports } from '../hooks/useGenerateReports';
import { setLoading } from '../../../../../store/modules/loading';

export const SuccessfulReport = (url: any) => {
	const dispatch = useDispatch();
	const planningProject = useSelector((state: RootState) => state.profileProject);
	const { downloadReport } = useGenerateReports();

	const onDownload = (url: any) => {
		dispatch(setLoading({ loading: true }));
		downloadReport(url.url);
	};

	useEffect(() => {
		if (planningProject.stepReporteo === 2) dispatch(setValuePP({ stepReporteo: 1 }));
	}, []);

	return (
		<Grid container spacing={2} style={{ height: '50vh' }}>
			<Grid item sm={12}>
				<div className={styles.mainContainer}>
					<div className={styles.infoContainer}>
						<img
							className={styles.img}
							src={confirmationImage}
							alt="actions"
						/>
						<h3>Tu reporte ha sido generado</h3>
						<div className={styles.buttonsContainer}>
							<Button
								label="Regresar"
								onClick={() => dispatch(setValuePP({ stepReporteo: 2 }))}
								style={{
									width: '100%',
								}}
							/>
							<Button
								id="download"
								label="Descargar"
								onClick={() => onDownload(url)}
								variant="solid"
								style={{
									width: '100%',
									backgroundColor: '#0080F9',
								}}
							/>
						</div>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};
