import styles from './styles/sheets-styles.module.css';

interface ISheets {
	sheets: string[];
	selectedSheet: number;
	setSelectedSheet: React.Dispatch<React.SetStateAction<number>>;
}

export const Sheets = ({ sheets, selectedSheet, setSelectedSheet }: ISheets) => {
	return (
		<div className={styles.sheetsContainer}>
			{(sheets?.length || 0) > 0 ? (
				sheets.map((sheet, index) => {
					return (
						<span
							key={index}
							style={{
								backgroundColor:
									index === selectedSheet ? '#70707040' : 'transparent',
							}}
							onClick={() => setSelectedSheet(index)}
						>
							{sheet}
						</span>
					);
				})
			) : (
				<span style={{ cursor: 'auto' }}>
					No detectamos errores en las hojas de tu documento
				</span>
			)}
		</div>
	);
};
