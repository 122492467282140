/* eslint-disable react-hooks/exhaustive-deps */

import { URLS } from '../../../constants/urls.constants';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { ISupervisionFunctionalsData } from '../interfaces/supervision-functionals.interface';

export function useGetListRoutine() {
	const {
		execute: getListRoutine,
		status: statusListRoutine,
		value: valueListRoutine,
	} = useApiRequest<unknown, IResponsePayload<ISupervisionFunctionalsData>>({
		path: URLS.SUPERVISION_EXECUTION.GET_FUNCTIONALS,
		method: 'GET',
	});

	return { getListRoutine, statusListRoutine, valueListRoutine };
}
