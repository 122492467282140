/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { URLS } from '../../../../../constants/urls.constants';
import { stopLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { ICalendar, ICalendarList } from '../interfaces/calendar.interface';
import { getRandomColor } from '../../../../../utils/colors';
import { getRoutineDescriptionByType } from '../../../../../utils/get-title-routines';

export const useGetPlannings = () => {
	const dispatch = useDispatch<any>();
	const [dataPlanning, setDataPlanning] = useState<any[]>([]);
	const {
		execute: executeGetPlannings,
		status,
		value: valuePlannings,
	} = useApiRequest<unknown, IResponsePayload<ICalendar>>({
		path: URLS.SUPERVISION_ROUTINE.CALENDAR_PERIOD,
		method: 'GET',
	});

	const weekdays = [
		'sunday',
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
	];

	const [planning, setPlanning] = useState<ICalendarList>({
		monday: [],
		tuesday: [],
		wednesday: [],
		thursday: [],
		friday: [],
		saturday: [],
		sunday: [],
	});
	const getListPlanning = (data: any) => {
		const dataCalendar: ICalendarList = {
			monday: [],
			tuesday: [],
			wednesday: [],
			thursday: [],
			friday: [],
			saturday: [],
			sunday: [],
		};

		data.forEach((item: any) => {
			if (weekdays[new Date(item.executionDate).getDay()] === 'monday')
				dataCalendar.monday.push({
					id: item.routine,
					title: getRoutineDescriptionByType(item.type, item.executionDate),
					services: item.activities,
					supervisor: item.supervisorName ?? item.teamName,
					color: getRandomColor(),
					rol: 'Supervisor',
					activitiesId: item.activitiesId,
				});
			if (weekdays[new Date(item.executionDate).getDay()] === 'tuesday')
				dataCalendar.tuesday.push({
					id: item.routine,
					title: getRoutineDescriptionByType(item.type, item.executionDate),
					services: item.activities,
					supervisor: item.supervisorName ?? item.teamName,
					color: getRandomColor(),
					rol: 'Supervisor',
					activitiesId: item.activitiesId,
				});

			if (weekdays[new Date(item.executionDate).getDay()] === 'wednesday')
				dataCalendar.wednesday.push({
					id: item.routine,
					title: getRoutineDescriptionByType(item.type, item.executionDate),
					services: item.activities,
					supervisor: item.supervisorName ?? item.teamName,
					color: getRandomColor(),
					rol: 'Supervisor',
					activitiesId: item.activitiesId,
				});
			if (weekdays[new Date(item.executionDate).getDay()] === 'thursday')
				dataCalendar.thursday.push({
					id: item.routine,
					title: getRoutineDescriptionByType(item.type, item.executionDate),
					services: item.activities,
					supervisor: item.supervisorName ?? item.teamName,
					color: getRandomColor(),
					rol: 'Supervisor',
					activitiesId: item.activitiesId,
				});
			if (weekdays[new Date(item.executionDate).getDay()] === 'friday')
				dataCalendar.friday.push({
					id: item.routine,
					title: getRoutineDescriptionByType(item.type, item.executionDate),
					services: item.activities,
					supervisor: item.supervisorName ?? item.teamName,
					color: getRandomColor(),
					rol: 'Supervisor',
					activitiesId: item.activitiesId,
				});

			if (weekdays[new Date(item.executionDate).getDay()] === 'saturday')
				dataCalendar.saturday.push({
					id: item.routine,
					title: getRoutineDescriptionByType(item.type, item.executionDate),
					services: item.activities,
					supervisor: item.supervisorName ?? item.teamName,
					color: getRandomColor(),
					rol: 'Supervisor',
					activitiesId: item.activitiesId,
				});
			if (weekdays[new Date(item.executionDate).getDay()] === 'sunday')
				dataCalendar.sunday.push({
					id: item.routine,
					title: getRoutineDescriptionByType(item.type, item.executionDate),
					services: item.activities,
					supervisor: item.supervisorName ?? item.teamName,
					color: getRandomColor(),
					rol: 'Supervisor',
					activitiesId: item.activitiesId,
				});
		});
		setPlanning(dataCalendar);
	};

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (valuePlannings?.data) {
				getListPlanning(valuePlannings.data);
				setDataPlanning(valuePlannings.data);
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al cagar la planeación',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { executeGetPlannings, planning, dataPlanning };
};
