import {
	DeleteOutlineOutlined,
	FileDownloadOutlined,
	InsertPhotoOutlined,
} from '@mui/icons-material';
import { getDateLocalMX } from '../../../../../utils/dates';
import { IDocument } from '../../../../../interfaces/project.interface';
import ButtonIconSmall from '../../../../../components/button-icon-small.component';
import { DOCS_TYPES } from '../../../../../constants/files-type';

export const documentationAdapter = (
	data: IDocument[],
	deleteDocument: (id: string) => void,
	setCoverImage: (id: string) => void
): IDocument[] => {
	const newData: IDocument[] = data.map((document: IDocument) => {
		return {
			...document,
			date: getDateLocalMX(document?.createdAt || ''),
			acciones: (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '10px',
						justifyContent: 'center',
					}}
				>
					<ButtonIconSmall
						text="Descargar"
						type="info"
						icon={<FileDownloadOutlined />}
						style={{ fontSize: '11px', width: '100px' }}
						onPress={() => window.open(document.url, '_blank')}
					/>
					<ButtonIconSmall
						text="Eliminar"
						type="error"
						icon={<DeleteOutlineOutlined />}
						style={{ fontSize: '11px', width: '100px' }}
						onPress={() => {
							if (document._id) deleteDocument(document._id);
						}}
						disabled={document.isCoverImage}
					/>
					{(document.type === DOCS_TYPES.JPG ||
						document.type === DOCS_TYPES.JPEG ||
						document.type === DOCS_TYPES.PNG) && (
						<ButtonIconSmall
							text="Seleccionar como principal"
							type="warning"
							icon={<InsertPhotoOutlined />}
							style={{ fontSize: '11px', width: '100px' }}
							onPress={() => {
								if (document.isCoverImage) return;
								if (document._id) setCoverImage(document._id);
							}}
							isSelected={document.isCoverImage}
						/>
					)}
				</div>
			),
		};
	});
	return newData;
};
