import {
	CheckBoxOutlineBlankOutlined,
	CheckCircleOutline,
	ErrorOutline,
	WarningAmberRounded,
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import styles from './styles/summary-detail-styles.module.css';
import { ISummary } from '../interfaces/summary.interface';

interface ISumaryDetail {
	summary: ISummary;
}

export const SummaryDetail = ({ summary }: ISumaryDetail) => {
	return (
		<div className={styles.summaryDetailContainer}>
			<span>Resumen</span>
			<Divider className={styles.dividerSummary} />
			<div className={styles.iconSummaryContainer} style={{ color: '#049D10' }}>
				<CheckCircleOutline className={styles.iconSize} />
				{summary?.valid || 0} válidos
			</div>
			<div className={styles.iconSummaryContainer} style={{ color: '#690015' }}>
				<ErrorOutline className={styles.iconSize} /> {summary?.errors || 0}{' '}
				errores
			</div>
			<div className={styles.iconSummaryContainer} style={{ color: '#FCA603' }}>
				<WarningAmberRounded className={styles.iconSize} />{' '}
				{summary?.warnings || 0} advertencias
			</div>
			<div
				className={styles.iconSummaryContainer}
				style={{ color: 'var(--color-highlight-blue)' }}
			>
				<CheckBoxOutlineBlankOutlined className={styles.iconSize} />
				{summary?.blank_spaces || 0} Espacios en blanco
			</div>
		</div>
	);
};
