// Dependencies

// Resources
import styles from './styles/label-status-styles.component.module.css';
import theme from '../config/theme';
import { LabelType } from '../interfaces/label-component.interface';

interface ILabelStatus {
	type: LabelType;
	text: string | JSX.Element;
	style?: React.CSSProperties;
	icon?: JSX.Element;
	onPress?: () => void;
}

const LabelStatus = (props: ILabelStatus) => {
	const { type, text, style, icon, onPress } = props;

	const getColorBackground = (): string => {
		let color = '';
		switch (type) {
			case 'success':
			case 'completed':
				color = '#C3E8D0';
				break;
			case 'warning':
				color = theme.palette.yellow.light;
				break;
			case 'pending-validation':
				color = '#FFDA95';
				break;
			case 'suspended':
				color = '#E4E4E4';
				break;
			default:
				break;
		}
		return color;
	};

	const getColorLabel = (): string => {
		let color = '';
		switch (type) {
			case 'success':
			case 'completed':
				color = '#10A243';
				break;
			case 'warning':
				color = theme.palette.yellow.main;
				break;
			case 'pending-validation':
				color = '#A56C00';
				break;
			case 'suspended':
				color = '#5F5F5F';
				break;
		}
		return color;
	};

	if (!text) return null;
	return (
		<label
			className={styles.labelStatus}
			style={
				icon
					? {
							...style,
							color: getColorLabel(),
							backgroundColor: getColorBackground(),
							display: 'flex',
							flexDirection: 'row',
							gap: '5px',
							cursor: onPress ? 'pointer' : 'inherit',
					  }
					: {
							...style,
							color: getColorLabel(),
							backgroundColor: getColorBackground(),
							cursor: onPress ? 'pointer' : 'inherit',
					  }
			}
			onClick={onPress}
		>
			{icon}
			{text}
		</label>
	);
};

export default LabelStatus;
