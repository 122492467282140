/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { URLS } from '../../../../constants/urls.constants';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IGetIGsByActivity } from '../interfaces/get-IGs-by-activity.interface';

interface IUseGetIGsByActivity {
	idActivity: string;
}

export const useGetIGbyActivity = ({ idActivity }: IUseGetIGsByActivity) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const {
		execute: executeGetIGsByActivity,
		status: statusGetIGsByActivity,
		value: valueGetIGsByActivity,
		setValue: setValueIGsByActivity,
	} = useApiRequest<unknown, IResponsePayload<IGetIGsByActivity>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	const [checkedList, setCheckedList] = useState<Set<string>>(new Set());

	useEffect(() => {
		if (statusGetIGsByActivity === 'success') {
			dispatch(stopLoading());
			const temporalCollection = new Set<string>();
			valueGetIGsByActivity?.data?.forEach((element) => {
				if (element.applies) temporalCollection.add(element.indicator);
			});
			setCheckedList(temporalCollection);
		} else if (statusGetIGsByActivity === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los criterios',
					severity: 'error',
				})
			);
		}
	}, [statusGetIGsByActivity]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetIGsByActivity(
			null,
			`?project=${idProject}&activity=${idActivity}&type=A-IG`
		);
	}, []);

	return { checkedList, valueGetIGsByActivity, setCheckedList, setValueIGsByActivity };
};
