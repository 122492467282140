/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../config/theme';
import { useGetFindings } from './useGetFindings';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';
import useUpdateProject from '../../../../../hooks/useUpdateProject';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../../store/modules/loading';
import {
	getFindignsAdapter,
	getFindingsAdaptedData,
} from '../adapters/get-findings.adapter';

export const useEditFindings = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { findignsInputs, findignsOptions, setFindignsInputs, setFindignsOptions } =
		useGetFindings();

	const { handleUpdateProject, status, value } = useUpdateProject();

	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	const handleNewFinding = () => {
		const temporalFinding = findignsOptions;
		temporalFinding.push({
			id: '',
			description: '',
			rows: [],
			isComplete: false,
			mainAttribute: 'Tipo de hallazgo',
		} as unknown as IAdaptedValues);
		setFindignsInputs({
			...findignsInputs,
			[Object.keys(findignsInputs).length]: '',
		});
		setFindignsOptions([...temporalFinding]);
	};

	const handleDeleteFinding = (row: number) => {
		const temporalFinding = findignsOptions;
		const temporalFindingInputs = findignsInputs;

		temporalFinding.pop();
		delete temporalFindingInputs[row];

		const newFindings: string[] = [];

		Object.entries(temporalFindingInputs).forEach(([element, value]) => {
			newFindings.push(value);
		});

		setFindignsInputs(getFindignsAdapter(newFindings));
		setFindignsOptions([...temporalFinding]);
	};

	const updateFindings = () => {
		const newFindings: string[] = [];

		Object.entries(findignsInputs).forEach(([element, value]) => {
			newFindings.push(value);
		});

		dispatch(setLoading({ loading: true }));
		handleUpdateProject({ typeFindings: [...newFindings] }, `${idProject}`);
	};

	useEffect(() => {
		if (status === 'success') {
			setFindignsInputs(getFindignsAdapter(value?.detail?.typeFindings));
			setFindignsOptions(getFindingsAdaptedData(value?.detail?.typeFindings || []));
		}
	}, [status]);

	return {
		findignsInputs,
		findignsOptions,
		matches,
		setFindignsInputs,
		navigate,
		handleNewFinding,
		handleDeleteFinding,
		updateFindings,
	};
};
