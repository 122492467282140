/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetStatus } from '../../../hooks/useGetStatus';

export const useInProgress = () => {
	const navigate = useNavigate();
	const { getStatus } = useGetStatus({});

	const [remaining, setRemaining] = useState(15);

	useEffect(() => {
		const sendStatus = setInterval(() => {
			getStatus();
		}, 15000);

		return () => clearInterval(sendStatus);
	}, []);

	useEffect(() => {
		const timer = setInterval(() => {
			if (remaining > 0) {
				setRemaining(remaining - 1);
			} else {
				setRemaining(15);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [remaining]);

	return { remaining, navigate };
};
