export const optionsAreas = [
	{
		id: 1,
		label: 'Suspensión por causas imputables al proveedor',
		value: 'Suspensión por causas imputables al proveedor',
	},
	{
		id: 2,
		label: 'Suspensión por causas imputables al supervisor',
		value: 'Suspensión por causas imputables al supervisor',
	},
	{
		id: 3,
		label: 'Suspensión por causas imputables al cliente',
		value: 'Suspensión por causas imputables al cliente',
	},
	{
		id: 4,
		label: 'Suspensión por variables no controlables',
		value: 'Suspensión por variables no controlables',
	},
];
