import { IColumns } from '../../../interfaces/table.insterface';
import { isEmpty } from '../../../utils/validators';

export const getColumns = (lvl1: string, activity: string, lvl2?: string): IColumns[] => {
	const columns = [
		{
			text: 'Indicador',
			nameID: 'indicator',
		},
		{
			text: 'Asignado a',
			nameID: 'assignedTo',
		},
		{
			text: 'Frecuencia Indicador',
			nameID: 'frequency',
		},
		{
			text: 'Última fecha de supervisión',
			nameID: 'lastExecutionDate',
		},
	];
	columns.unshift({ text: activity || 'Clave del servicio', nameID: 'activity' });
	if (!isEmpty(lvl2))
		columns.unshift({ text: lvl2 || 'Nivel 2', nameID: 'functional' });
	columns.unshift({ text: lvl1 || 'Nivel 1', nameID: 'functionalParent' });
	return columns;
};
