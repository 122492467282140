import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../../components/header-edit.component';
import { GoBack } from '../../../components/go-back-component';
import { Button } from '../../../components/main-button.component';
import TextInput from '../../../components/text-input.component';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import { useEditFunctional } from './hooks/useEditFunctional';
import { LABELS_LVL_1_2, LABELS_LVL_2_2 } from '../utils/labels-header';
import DimensionesIcon from '../../../assets/icons/dimensiones.icon';

interface IFormActivitites {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentFunctional: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	isLvlOne: boolean;
	currentFunctional: IAdaptedValues;
}

export const EditFunctional = ({
	setStep,
	setCurrentFunctional,
	isLvlOne,
	currentFunctional,
}: IFormActivitites) => {
	const { inputs, matches, onChangeValue, updateFunctional } = useEditFunctional({
		setCurrentFunctional,
		currentFunctional,
	});

	return (
		<>
			<GoBack action={() => setStep(1)} />
			<Paper>
				<Box padding={3}>
					<Grid
						container
						spacing={3}
						justifyContent={matches ? 'center' : 'flex-end'}
					>
						<HeaderEdit
							section="Dimensiones geográficas"
							title={isLvlOne ? LABELS_LVL_1_2.title : LABELS_LVL_2_2.title}
							alertText={
								isLvlOne
									? LABELS_LVL_1_2.alertText
									: LABELS_LVL_2_2.alertText
							}
							icon={<DimensionesIcon size="23px" />}
						/>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								{inputs.map((input: any) => {
									return (
										<Grid
											item
											xs={12}
											md={6}
											key={`input-text-${input.id}`}
										>
											<TextInput
												key={`input-text-${input.id}`}
												id={input.id
													.toLowerCase()
													.replace(/ /g, '-')}
												name={input.id}
												label={input.label}
												placeholder={input.placeholder}
												value={input.value}
												type="text"
												onChange={(e) =>
													onChangeValue(
														e.target.name,
														e.target.value
													)
												}
												contentStyles={{
													width: {
														xs: '100%',
														sm: '350px',
														md: '100%',
													},
												}}
												sx={{
													width: '100%',
												}}
												InputProps={{
													sx: {
														backgroundColor: '#F7F7F7',
														width: { xs: '100%' },
														fontSize: '15px',
													},
												}}
											/>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
						<Grid item>
							<Button
								label="Guardar"
								onClick={() => updateFunctional()}
								variant="outlined"
								style={{
									backgroundColor: ' #0080F9',
									width: '200px',
									color: 'white',
								}}
								disabled={inputs.some((input: any) => input.value === '')}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
