// Dependencies

// Resources
import styles from './styles/button-icon-small.component.module.css';
import theme from '../config/theme';
import { LabelType } from '../interfaces/label-component.interface';

interface IButtonIconSmall {
	type: LabelType;
	text: string | JSX.Element;
	style?: React.CSSProperties;
	icon?: JSX.Element;
	disabled?: boolean;
	onPress?: () => void;
	isSelected?: boolean;
}

const ButtonIconSmall = (props: IButtonIconSmall) => {
	const { type, text, style, icon, disabled, isSelected, onPress } = props;

	const getColorBackground = (): string => {
		let color = '';
		switch (type) {
			case 'success':
			case 'warning':
			case 'error':
			case 'info':
				color = theme.palette.info.contrastText;
				break;
			case 'grey':
				color = '#eaeaea';
				break;
			default:
				break;
		}
		return color;
	};

	const getColorLabel = (): string => {
		let color = '';
		switch (type) {
			case 'success':
				color = theme.palette.success.main;
				break;
			case 'warning':
				color = theme.palette.warning.dark;
				break;
			case 'error':
				color = theme.palette.error.dark;
				break;
			case 'info':
				color = theme.palette.blue.main;
				break;
			case 'yellow':
				color = theme.palette.yellow.dark;
				break;
			case 'grey':
				color = '#949494';
				break;
			default:
				break;
		}
		return color;
	};

	const getBorderColor = (): string => {
		let color = '';
		switch (type) {
			case 'success':
				color = theme.palette.success.main;
				break;
			case 'warning':
				color = theme.palette.warning.dark;
				break;
			case 'error':
				color = theme.palette.error.dark;
				break;
			case 'info':
				color = theme.palette.info.main;
				break;
			case 'yellow':
				color = theme.palette.yellow.dark;
				break;
			case 'grey':
				color = '#949494';
				break;
			default:
				break;
		}
		return color;
	};

	return (
		<span
			className={styles.buttonIconSmall}
			style={
				disabled
					? {
							...style,
							color: '#fff',
							backgroundColor: '#e6e6e9',
							borderColor: '#d8d8dc',
							cursor: 'default',
					  }
					: {
							color: isSelected ? 'white' : getColorLabel(),
							backgroundColor: isSelected
								? getBorderColor()
								: getColorBackground(),
							borderColor: getBorderColor(),
							...style,
					  }
			}
			onClick={disabled ? () => {} : onPress}
			aria-disabled={disabled}
		>
			{icon}
			{text}
		</span>
	);
};

export default ButtonIconSmall;
