/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from '../../../../config/theme';
import { DOCS_EXTENSIONS, DOCS_TYPES } from '../../../../constants/files-type';
import { bytesToMB } from '../../../../utils/converts';
import { fileToBase64 } from '../../../../utils/fileToBase64';
import { INewIncident } from '../interfaces/new-incident.interface';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { useUpdateSuspension } from './useUpdateSuspension';
import { validateImages } from '../utils/validate-images';
import { useAddInfoImage } from '../../../../hooks/useAddInfoImage';

export function useSupendActivity() {
	const dispatch = useDispatch();
	const {
		idProject,
		supervisionRoutineId,
		executeChangeStatus,
		statusChangeStatus,
		withStatus,
		supervisorName,
		unitInfo,
		undoState,
		editFinding,
	} = useContext(SupervisionProjectContext);
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [camera, setCamera] = useState(false);
	const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

	const [data, setData] = useState<INewIncident>({
		name: '',
		type: editFinding?.type || '',
		description: editFinding?.description || '',
		project: idProject,
		files: editFinding?.files || [],
	});

	const { executeUpdateSuspension } = useUpdateSuspension();

	useEffect(() => {
		if (statusChangeStatus === 'success') {
			undoState();
		} else if (statusChangeStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusChangeStatus]);

	const handleChange = (event: any) => {
		const { name } = event.target;
		if (name === undefined) {
			setData({
				...data,
				type: event.target.value,
			});
		} else {
			setData({
				...data,
				[name]: event.target.value,
			});
		}
	};

	const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (data.files.length === 4) {
			dispatch(
				setAlert({
					show: true,
					message: 'No se pueden cargar más fotos.',
					severity: 'error',
				})
			);
			return;
		}
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const fullName = file.name.split('.');
			const size = file.size;
			const reader = new FileReader();
			const extension = file ? fileList[0].type : '';

			reader.onload = (e) => {
				const srcData = reader.result;
				if (srcData) {
					addInfoImage(
						fileToBase64(srcData),
						true,
						fullName[0] +
							'_' +
							Date.now() +
							'.' +
							DOCS_EXTENSIONS[extension || 'image/jpeg'],
						bytesToMB(size),
						extension
					);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const setFileImage = (
		imageSrc: string | null | undefined,
		name?: string,
		size?: string,
		extension?: string
	) => {
		if (imageSrc) {
			setData({
				...data,
				files: [
					...data.files,
					{
						fileName: name || 'photo_user',
						type: DOCS_EXTENSIONS[extension || 'image/jpeg'],
						fileBase64: imageSrc,
						description: data.description,
						size: size || '0',
					},
				],
			});
		}
	};

	const { addInfoImage } = useAddInfoImage({
		setImg: setFileImage,
		user: supervisorName,
		unitInfo,
	});

	const deleteFile = (index: number) => {
		const newFindings = data.files;
		if (newFindings[index]._id)
			setFilesToDelete([...filesToDelete, newFindings[index]?._id || '']);
		newFindings.splice(index, 1);
		setData({ ...data, files: newFindings });
	};

	const handlePhoto = (imageSrc: string | null | undefined) => {
		if (data.files.length === 4) {
			dispatch(
				setAlert({
					show: true,
					message: 'No se pueden cargar más fotos.',
					severity: 'error',
				})
			);
			return;
		}
		if (imageSrc) {
			setData({
				...data,
				files: [
					...data.files,
					{
						fileName: `photo_user_${Date.now()}.jpeg`,
						type: DOCS_TYPES.JPEG,
						fileBase64: fileToBase64(imageSrc),
						description: data.description,
						size: '',
					},
				],
			});
			setCamera(false);
		}
	};

	const changeStatus = () => {
		dispatch(setLoading({ loading: true }));
		executeChangeStatus({
			...withStatus,
			causeSuspension: data.type,
			suspensionDescription: data.description,
			files: validateImages(data.files, data.description),
		});
	};

	const updateSuspension = () => {
		dispatch(setLoading({ loading: true }));
		executeUpdateSuspension({
			supervisionRoutine: supervisionRoutineId,
			causeSuspension: data.type,
			suspensionDescription: data.description,
			files: validateImages(data.files, data.description).filter((img) => !img._id),
			filesToDelete: filesToDelete,
		});
	};

	const disabledButton = useMemo((): boolean => {
		if (data.description !== '' && data.type !== '' && data.files.length > 0)
			return false;
		return true;
	}, [data]);

	return {
		data,
		matches,
		camera,
		disabledButton,
		editFinding,
		supervisorName,
		unitInfo,
		changeStatus,
		dispatch,
		handleFile,
		handleChange,
		deleteFile,
		setCamera,
		handlePhoto,
		undoState,
		updateSuspension,
	};
}
