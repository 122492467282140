import { getDateLocalMX } from '../../../../../utils/dates';
import { IActionsSH } from '../interfaces/summary-routine.interface';

export const actionsSHAdapter = (data: IActionsSH[]): IActionsSH[] => {
	const action: IActionsSH[] = data.map((action: IActionsSH) => {
		return {
			...action,
			date: getDateLocalMX(action.createdAt),
			hour: getDateLocalMX(action.createdAt, 'HH:mm'),
		};
	});
	return action;
};
