/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { URLS } from '../../../../../../constants/urls.constants';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { IGetPersonal } from '../../../../../../interfaces/user.interface';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';

interface IUseCreatePersonal {
	role: IAdaptedValues;
	personalData: IAdaptedValues[];
	setPersonalData: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
}

export const useCreatePersonal = ({
	role,
	personalData,
	setPersonalData,
}: IUseCreatePersonal) => {
	const dispatch = useDispatch();
	const [index, setIndex] = useState(0);
	const [body, setBody] = useState<IGetPersonal | undefined>(undefined);

	const {
		execute: createPersonal,
		status: statusExecutePersonal,
		value: valueExecutePersonal,
	} = useApiRequest<IGetPersonal, IResponsePayload<IGetPersonal>>({
		path: URLS.EDIT_PROJECT.PERSONALS.GET_PERSONAL_BY_ROLE,
		method: 'POST',
	});

	useEffect(() => {
		if (statusExecutePersonal === 'success') {
			setPersonalData(
				personalData.map((personal, i) => {
					if (i === index)
						return {
							id: valueExecutePersonal?.detail?.user || '',
							mainAttribute: valueExecutePersonal?.detail?.columns[0] || '',
							description: valueExecutePersonal?.detail?.user || '',
							rows:
								role?.attributes?.map((e, index) => {
									return {
										key: e,
										value: valueExecutePersonal?.detail?.columns[
											index
										],
									};
								}) || [],
							isComplete: true,
							frequency: '',
							idPersonal: valueExecutePersonal?.detail?._id || '',
						} as unknown as IAdaptedValues;
					else return personal;
				})
			);
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Usuario creado con éxito',
					severity: 'success',
				})
			);
		} else if (statusExecutePersonal === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear usuario',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusExecutePersonal]);

	useEffect(() => {
		if (body) {
			dispatch(setLoading({ loading: true }));
			createPersonal(body);
		}
	}, [body]);

	const handleCreatePersonal = (body: IGetPersonal, index: number) => {
		setBody(body);
		setIndex(index);
	};

	return { handleCreatePersonal };
};
