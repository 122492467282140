/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import {
	IAdaptedValues,
	IAdaptValue,
} from '../../../../../../interfaces/edit-adapted-values';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { IGetRoles } from '../interfaces/get-roles.interface';
import { isEmpty } from '../../../../../../utils/validators';
import { getInputsCustom } from '../adapters/get-base-inputs';

interface IUseUpdateRoles {
	rolesOptions?: IAdaptedValues[];
	setRolesOptions?: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
	currentRole?: IAdaptedValues;
	setCurrentRole?: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	setCurrenBase?: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
}

export const useUpdateRoles = ({
	rolesOptions,
	setRolesOptions,
	currentRole,
	setCurrentRole,
	setCurrenBase,
}: IUseUpdateRoles) => {
	const dispatch = useDispatch();
	const [index, setIndex] = useState(0);
	const [id, setId] = useState('');
	const [body, setBody] = useState<any | undefined>(undefined);

	const {
		execute: createRoles,
		status: statusExecuteRoles,
		value: valueExecuteRoles,
	} = useApiRequest<unknown, IResponsePayload<IGetRoles>>({
		path: URLS.EDIT_PROJECT.ROLES.GET_ROLES,
		method: 'PATCH',
	});

	useEffect(() => {
		if (statusExecuteRoles === 'success') {
			const newRole = {
				id: valueExecuteRoles?.detail?._id || '',
				mainAttribute: valueExecuteRoles?.detail?.mainAttribute || '',
				description: valueExecuteRoles?.detail?.name || '',
				rows:
					valueExecuteRoles?.detail?.attributes?.map((e) => {
						return { key: e, value: '' };
					}) || [],
				isComplete: true,
				frequency: '',
				attributes: valueExecuteRoles?.detail?.attributes,
			} as unknown as IAdaptedValues;
			if (setRolesOptions && rolesOptions)
				setRolesOptions(
					rolesOptions.map((rol, i) => {
						if (i === index) return newRole;
						else return rol;
					})
				);
			if (setCurrentRole && currentRole) setCurrentRole(newRole || IAdaptValue);
			if (setCurrenBase) setCurrenBase(getInputsCustom(newRole));
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Rol actualizado con éxito',
					severity: 'success',
				})
			);
		} else if (statusExecuteRoles === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar los roles',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusExecuteRoles]);

	useEffect(() => {
		if (body && !isEmpty(id)) {
			dispatch(setLoading({ loading: true }));
			createRoles(body, `/${id}`);
		}
	}, [body, id]);

	const handleUpdateRoles = (body: any, index: number, _id: string) => {
		setBody(body);
		setIndex(index);
		setId(_id);
	};

	return { handleUpdateRoles };
};
