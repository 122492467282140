export interface IActions {
	id: number;
	label: string;
	action: 'navigate' | 'step';
	value: string | number;
	structureData?: number;
}

export interface IActionsList {
	elements: IActions[];
}

export interface IServices {
	_id: string;
	description: string;
}

export interface IFiltersEditSupervision {
	service: string;
	month: string;
	advancedSearch: string;
}
export type FiltersEditSupervisionKeys = keyof IFiltersEditSupervision;

export const defaultFilters: IFiltersEditSupervision = {
	service: '',
	month: '',
	advancedSearch: '',
};

export interface IRoutine {
	_id?: string;
	description?: string;
	routine?: string;
}
