import { isEmpty } from '../../../../utils/validators';
import { IImage } from '../interfaces/new-incident.interface';

export const validateImages = (files: IImage[], description: string): IImage[] => {
	if (files.every((image) => !isEmpty(image.description))) return files;

	const newImages = files.map((image) => {
		return { ...image, description: description };
	});
	return newImages;
};
