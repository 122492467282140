import { IFunctionalResponse } from '../../../../../interfaces/project.interface';
import { IGetSupervisionUnit } from '../../interfaces/get-supervision-unit.interface';

export const getNewDataUARelations = (
	functionalsData: IFunctionalResponse[],
	UARelations: IGetSupervisionUnit[],
	checkedList: Set<string>,
	idProject: string,
	currentActivity: string
) => {
	const newUARelations = functionalsData.map((e) => {
		const temporalUARelation = UARelations.find(
			(element) => element.functional === e._id
		);
		if (temporalUARelation) {
			return {
				...temporalUARelation,
				applies: checkedList.has(temporalUARelation.functional),
			};
		}
		return {
			name: `${currentActivity}-${e._id}`,
			project: idProject,
			activity: currentActivity,
			functional: e._id,
			type: 'U-A',
			applies: checkedList.has(e._id),
		};
	});

	return newUARelations;
};
