//This is a variation from the original "Hider"
interface IProps {
	id?: string;
	isVisible: boolean;
	Component: JSX.Element;
	disabledRender?: boolean;
}

const Hider = (props: IProps) => {
	const { id, isVisible, Component, disabledRender = false } = props;

	if (disabledRender) {
		return (
			<div id={id} style={{ width: '100%', display: 'block' }}>
				{isVisible && Component}
			</div>
		);
	}

	return (
		<div id={id} style={{ width: '100%', display: isVisible ? 'block' : 'none' }}>
			{Component}
		</div>
	);
};

export default Hider;
