/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { resetState } from '../../../../../store/modules/steps';
import { setAlert } from '../../../../../store/modules/snackbar';
import { stopLoading } from '../../../../../store/modules/loading';
import { IDocument } from '../../../../../interfaces/project.interface';
import { ISaveDocumentsParams } from '../interfaces/save-documents.interface';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';

export const useSaveDocuments = () => {
	const dispatch = useDispatch();
	const { data, setData } = useContext(ProfileProjectContext);

	const { execute: executeSaveDocuments, status: statusDocuments } = useApiRequest<
		ISaveDocumentsParams,
		IResponsePayload<IDocument>
	>({
		path: URLS.PROJECT.GET_FILES,
		method: 'POST',
	});

	useEffect(() => {
		if (statusDocuments === 'success') {
			dispatch(stopLoading());
			setData({ ...data, files: [] });
			dispatch(resetState());
		} else if (statusDocuments === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al guardar los archivos',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusDocuments]);

	return { executeSaveDocuments };
};
