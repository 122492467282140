/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { IGetBaseTasks } from '../interfaces/get-base-tasks.interface';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { baseTaskAdapter } from '../adapters/get-base-tasks.adapter';
import { isEmpty } from '../../../utils/validators';

interface IUseGetBaseTasks {
	dataValues: {
		[key: string]: string;
	};
}

export const useGetBaseTasks = ({ dataValues }: IUseGetBaseTasks) => {
	const dispatch = useDispatch();
	const { data, setData, pagination, rowsPerPage } = useTableCustom<Object>();
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const {
		execute: getBaseTasks,
		status: statusBaseTasks,
		value: valueBaseTasks,
	} = useApiRequest<unknown, IResponsePayload<IGetBaseTasks>>({
		path: URLS.SUPERVISION_HISTORY.BASE_TASKS,
		method: 'GET',
	});

	const getFilters = (): string => {
		const filter = Object.keys(dataValues).map((element) => {
			if (!isEmpty(dataValues[element]))
				return `&${element}=${dataValues[element]}`;
			else return '';
		});
		return filter.join('');
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getBaseTasks(
			null,
			`?project=${_id}&page=${pagination}&recordsByPage=${rowsPerPage}${getFilters()}`
		);
	}, [pagination, rowsPerPage, dataValues]);

	useEffect(() => {
		if (statusBaseTasks === 'success') {
			dispatch(stopLoading());
			setData({
				...data,
				countRows: valueBaseTasks?.totalRecords || 0,
				rows: baseTaskAdapter(valueBaseTasks?.data || []) || [],
			});
		} else if (statusBaseTasks === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusBaseTasks]);

	return { data, setData };
};
