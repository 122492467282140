import styles from './styles/day-styles.module.css';

interface IDayComponent {
	supervisionTitle: string;
	supervision: string;
	commissioned: string;
	stylesDivider?: React.CSSProperties;
}

export const DayComponent = ({
	supervisionTitle,
	supervision,
	commissioned,
	stylesDivider,
}: IDayComponent) => {
	return (
		<div className={styles.dayContainer}>
			<hr className={styles.dayDivider} style={{ ...stylesDivider }} />
			<div className={styles.dayLabels}>
				<span>{supervisionTitle}:</span>
				<span className={styles.supervisionContainer}>{supervision}</span>
				<span>Encargado: {commissioned}</span>
			</div>
		</div>
	);
};
