/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { StepOneHandler } from '../../../../components/step-one-fixed';
import { useEffect, useRef, useState } from 'react';

import useGeneralIndicator from '../../hooks/useGeneralndicator';
import { ICreationPage, IInicio } from '../../../../interfaces/creation-page-interfaces';
import { DefaultTabItems } from '../../constants/data.constants';
import { estadoCreacion } from '../../../../constants/estado-creacion';
//GENERALES
import IGOne from '../../components/criterios-generales/step-one.component';
import IGTwo from '../../components/criterios-step-two.component';
import { URLS } from '../../../../constants/urls.constants';
import { IVerticalStepperAttributes } from '../../../../interfaces/vertical.steper.attributes-fixed.interface';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';
import { sendDataAdapterIndicator } from '../../../../adapters/step-two.adapter';
import useUpdateIndicator from '../../hooks/useUpdateIG';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import CreationPage from '../../../../components/creation-page.component';
import { TReactDispatch } from '../../../../interfaces/utils';
import useUpdateProject from '../../../../hooks/useUpdateProject';
import useGetActivities from '../../hooks/useGetActivities';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import { IActivity } from '../../../../interfaces/project.interface';
import AsignacionCriterios from '../../asignacion-criterios';
import Inicio from '../../../../components/Inicio-page.component';
import { isEmpty } from '../../../../utils/validators';

interface IHandleUpdateIndicator {
	p_data: IStepTwoModel[];
	p_isSave: boolean;
	p_isGeneral: boolean;
	p_idService?: string;
}

interface IProps {
	currentTab: number;
	setCurrentTab: TReactDispatch<number>;
}
const CriteriosGenerales = (props: IProps) => {
	const { currentTab, setCurrentTab } = props;
	const {
		step: projectStep,
		_id: _projectID,
		generalIndicatorBase,
	} = useSelector((state: RootState) => state.projectBase);
	const [showAreCriteriosComunes, setShowAreCriteriosComunes] = useState(true); //true
	const [showAreCriteriosEspecificos, setShowAreCriteriosEspecificos] = useState(false);
	const IGRef = useRef<StepOneHandler>(null);
	const [showCsvGeneral, setShowCsvGeneral] = useState(false);
	const [isSave, setIsSave] = useState<boolean>(false);

	//GENERALES
	const [defaultIG, setDefaultIG] = useState<IVerticalStepperAttributes[] | null>(null);
	const [isNextIGDisabled, setIsNextIGDisabled] = useState<boolean | null>(null);
	const [isIG2Show, setIsIG2Show] = useState<boolean>(false);
	const { value: valueActivities } = useGetActivities();
	const [isBaseCreated, setIsBaseCreated] = useState<boolean>(false);
	//GEOGRAFICOS
	const {
		execute: getGeneralIndicator,
		generalIndicator,
		setGeneralIndicator,
		isGeneralDone,
		setIsGeneralDone,
		status: statusGeneralIndicator,
	} = useGeneralIndicator({ setIsIG2Show });
	const [showAsignacion, setShowAsignacion] = useState(false);
	const { execute: updateIG, status: statusUpdateIndicator } = useUpdateIndicator({
		setIG: setGeneralIndicator,
		isSave,
	});
	const [serviceIGOptions, setServiceIGOptions] = useState<IOptionsMenu[]>([]);
	const dispatch = useDispatch<any>();
	const { handleUpdateProject } = useUpdateProject();

	useEffect(() => {
		if (statusUpdateIndicator === 'success') {
			dispatch(stopLoading());
			if (currentTab === 0 && !isSave) {
				setShowAsignacion(true);
			}
		} else if (statusUpdateIndicator === 'error') dispatch(stopLoading());
	}, [statusUpdateIndicator]);

	useEffect(() => {
		if (generalIndicator.some((indicator) => !isEmpty(indicator._id)))
			setIsBaseCreated(true);
		else setIsBaseCreated(false);
	}, [generalIndicator]);

	useEffect(() => {
		if (statusGeneralIndicator === 'success') {
			dispatch(stopLoading());
			if (isSave) return;
			if (projectStep !== '3.0.1') {
				setIsGeneralDone(true);
				IGRef.current?.disableAll();
			} else if (generalIndicatorBase) {
				setIsIG2Show(true);
				setDefaultIG([
					{
						index: -1,
						name: 'Nombre',
						placeholder: '',
						value: generalIndicatorBase.name,
						isComplete: true,
					},
					...generalIndicatorBase.attributes.map((element, i) => {
						return {
							index: i,
							name:
								i === 0 ? 'Atributo principal' : 'Atributos secundarios',
							placeholder: '',
							value: element,
							isComplete: true,
						};
					}),
				]);
				IGRef.current?.disableAll();
			} else {
				setIsGeneralDone(false);
				IGRef.current?.resetValues();
			}
		} else if (statusGeneralIndicator === 'error') dispatch(stopLoading());
	}, [statusGeneralIndicator, isSave, projectStep]);

	useEffect(() => {
		if (currentTab === 0) {
			dispatch(setLoading({ loading: true }));
			getGeneralIndicator(null, `?project=${_projectID}&isGeneral=true`);
		}
	}, [currentTab]);

	useEffect(() => {
		if (!valueActivities?.data || valueActivities.data.length < 1) return;
		const _serviceIGOptions: IOptionsMenu[] = [];

		valueActivities.data.forEach((i: IActivity) => {
			const data = {
				id: i._id || '',
				label: i.columns[0],
				value: i._id || '',
			};
			_serviceIGOptions.push({
				...data,
				status: i.isGeneralAssociated || false,
			});
		});
		setServiceIGOptions(_serviceIGOptions);
	}, [valueActivities]);

	useEffect(() => {
		if (projectStep !== '3.0.0') {
			setShowAreCriteriosComunes(false);
			setIsGeneralDone(false);
		} else {
			setIsGeneralDone(true);
		}
		if (projectStep === '3.0.2') {
			setShowAreCriteriosEspecificos(true);
			setShowAsignacion(false);
		}
	}, [projectStep]);

	const handleUpdateIndicator = async (props: IHandleUpdateIndicator) => {
		const { p_data, p_isGeneral, p_isSave } = props;
		await dispatch(setLoading({ loading: true }));
		await setIsSave(p_isSave);
		try {
			const _adapted = sendDataAdapterIndicator(
				p_data,
				_projectID || '',
				p_isGeneral
			);
			await updateIG(_adapted, `?project=${_projectID}`);
			dispatch(stopLoading());
		} catch (e) {
			dispatch(stopLoading());
		}
	};

	const HayCriteriosComunes: IInicio = {
		title: (
			<>
				¿Hay criterios de evaluación que sean <br />
				comunes para varias actividades?
			</>
		),
		description: (
			<>
				Que haya criterios generales significa que existen varias actividades que
				comparten un mismo
				<br />
				criterio para ser evaluadas.
			</>
		),
		leftButtonLabel: 'No, no hay criterios comunes',
		rightButtonLabel: 'Sí, hay criterios comunes',
		onLeftButtonClick: () => {
			setShowAreCriteriosComunes(false);
			setCurrentTab(1);
			handleUpdateProject({ step: '3.1.0' }, _projectID || '');
		},
		onRightButtonClick: () => {
			handleUpdateProject({ step: '3.0.1' }, _projectID || '');
			setShowAreCriteriosComunes(false);
			//setIsGeneralDone(false);
			//console.log('test 3');
		},
	};

	const CriteriosGenerales: ICreationPage = {
		header: {
			show: !showCsvGeneral,
			label: 'Estado de creación',
			stepper: {
				activeStep: 2,
				items: estadoCreacion,
				completed: { 0: true, 1: true },
			},
			tabs: {
				items: DefaultTabItems,
				value: currentTab,
				onTabChange: (_, index) => {
					//setGeneralIndicator([]);
					setCurrentTab(index);
				},
			},
		},
		stepOne: {
			title: `1. Nombra tus criterios, así como su atributo principal y los
			secundarios`,
			alertLabel: `Designa el nombre genérico de los criterios de evaluación a
			considerar en la supervisión según lo indique el contrato`,
			show: !showCsvGeneral,
			Children: (
				<IGOne
					refComponent={IGRef}
					onContinue={(data) => {
						setGeneralIndicator(data);
						setIsIG2Show(true);
						handleUpdateProject(
							{
								generalIndicatorBase: {
									name: data[0].name,
									mainAttribute: data[0].mainAttribute,
									attributes: data[0].attributes,
								},
							},
							_projectID || ''
						);
					}}
					defaultActividad={defaultIG}
					disableNext={isNextIGDisabled}
					//isDefaultDone={isGeneralDone}
				/>
			),
		},
		stepTwo: {
			title: !showCsvGeneral ? '2. Nombra tus criterios generales' : <></>,
			show: isIG2Show,
			Children: (
				<IGTwo
					data={generalIndicator}
					url={URLS.PROJECT.INDICATOR}
					setData={setGeneralIndicator}
					showAltaCsv={showCsvGeneral}
					setShowAltaCsv={setShowCsvGeneral}
					isDone={isGeneralDone}
					labelItem={`Designa el nombre genérico de los espacios a supervisar según lo
					indique el contrato. No utilice el nombre de un área específica`}
					onContinue={(data) => {
						handleUpdateIndicator({
							p_data: data,
							p_isSave: false,
							p_isGeneral: true,
						});
					}}
					onSave={(data) => {
						handleUpdateIndicator({
							p_data: data,
							p_isSave: true,
							p_isGeneral: true,
						});
					}}
					onCVSUpload={() => {
						setShowCsvGeneral(true);
					}}
				/>
			),
			showExitButon: !isGeneralDone && !showCsvGeneral && !isBaseCreated,
			onExit: () => {
				setIsIG2Show(false);
				setIsNextIGDisabled(null);
				IGRef.current?.unDone();
			},
		},
	};

	const HayCriteriosEspecificos: IInicio = {
		title: <>¿Hay criterios específicos?</>,
		description: (
			<>
				Que haya criterios específicos significa que existen actividades con
				algunos criterios
				<br />
				únicos para ser evaluadas.
			</>
		),
		leftButtonLabel: 'No, no hay criterios específicos',
		rightButtonLabel: 'Sí, hay criterios específicos',
		leftBtnVariant: 'contained',
		sxLeftBtn: { backgroundColor: '#FD0000', color: '#fff' },
		onLeftButtonClick: () => {
			handleUpdateProject({ step: '3.2.0' }, _projectID || '');
			setShowAreCriteriosEspecificos(false);
		},
		onRightButtonClick: () => {
			handleUpdateProject({ step: '3.1.0' }, _projectID || '');
			setShowAreCriteriosEspecificos(false);
		},
	};

	return (
		<div>
			{showAreCriteriosComunes && <Inicio {...HayCriteriosComunes} />}
			{showAreCriteriosEspecificos && <Inicio {...HayCriteriosEspecificos} />}
			{showAsignacion && (
				<AsignacionCriterios
					setShowAsignacion={setShowAsignacion}
					defaultItems={generalIndicator}
					setCurrentTab={setCurrentTab}
					serviceIGOptions={serviceIGOptions}
					setServiceIGOptions={setServiceIGOptions}
				/>
			)}
			{!showAreCriteriosComunes &&
				!showAsignacion &&
				!showAreCriteriosEspecificos && <CreationPage {...CriteriosGenerales} />}
		</div>
	);
};

export default CriteriosGenerales;
