/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { ICollapsable } from '../interfaces/collapsable.interface';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

const ExpandBtn = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	color: '#0080F9',
	fontWeight: 'bold',
	margin: 0,
	':hover': {
		background: 'unset',
	},
}));

const CheckBox = styled(Checkbox)(({ theme, disabled }) => {
	const _color = disabled ? '#00000042' : 'var(--color-highlight-blue) !important';
	return {
		color: _color,
		'&.Mui-checked': {
			color: _color,
		},
		'&.Mui-indeterminate': {
			color: 'var(--color-highlight-blue) !important',
		},
	};
});

interface IProps extends ICollapsable {
	column: number | string;
	activeElements: Set<string>;
	disabled?: boolean;
}

const Collapsable = (props: IProps) => {
	const {
		id,
		value,
		items,
		onCheck,
		onSelectColumn,
		activeElements,
		disabled = false,
	} = props;
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
	const btnCollapsedText = isCollapsed ? 'Expandir' : 'Comprimir';

	const handleCheckChange = (EF_ID: string, value: boolean) => {
		if (onCheck) onCheck(id, EF_ID, value);
	};
	const handleIsAllSelected = (_: any, checked: boolean) => {
		if (onSelectColumn) onSelectColumn(id, items, checked);
	};
	type TisAllCheck = {
		count: number;
		isAllCheck: boolean;
		length: number;
	};
	const isAllCheck: TisAllCheck = useMemo(() => {
		let count = 0;
		let isAllCheckFlag = false;
		if (items.length === 0) {
			isAllCheckFlag = activeElements.has(String(id));
		} else {
			items.forEach((i) => {
				if (activeElements.has(String(i.id))) {
					count++;
				}
			});
			if (count === items.length) {
				isAllCheckFlag = true;
			}
		}

		return {
			isAllCheck: isAllCheckFlag,
			count: count,
			length: items.length,
		};
	}, [items, activeElements]);

	return (
		<>
			<Grid item xs={isAllCheck.length > 0 ? 8 : 10}>
				<Input
					id="main-option"
					value={value}
					fullWidth
					disabled={disabled}
					InputProps={{
						readOnly: true,
						...(isAllCheck.length > 0 && {
							endAdornment: isCollapsed ? (
								<IconButton
									onClick={() => setIsCollapsed((current) => !current)}
								>
									<ArrowDropDownIcon />
								</IconButton>
							) : (
								<IconButton
									onClick={() => setIsCollapsed((current) => !current)}
								>
									<ArrowDropUpIcon />
								</IconButton>
							),
						}),
					}}
				/>
			</Grid>
			{isAllCheck.length > 0 ? (
				<Grid item xs={3} display="flex">
					<ExpandBtn
						id={`expand-btn-${id}`}
						variant="text"
						onClick={() => setIsCollapsed((current) => !current)}
					>
						{btnCollapsedText}
					</ExpandBtn>
				</Grid>
			) : (
				<Grid item xs={0} lg={1} display="flex" />
			)}
			<Grid item xs={1}>
				<CheckBox
					checked={isAllCheck.isAllCheck}
					disabled={disabled}
					indeterminate={
						!isAllCheck.isAllCheck &&
						isAllCheck.count > 0 &&
						isAllCheck.count < isAllCheck.length
					}
					onChange={handleIsAllSelected}
					id="select-all-collapsable"
				/>
			</Grid>
			<Grid container item xs={12} gap={2}>
				{!isCollapsed &&
					items.map((element, row) => (
						<ItemRender
							disabled={disabled}
							row={row}
							item={element}
							activeElements={activeElements}
							handleCheckChange={handleCheckChange}
							key={`collapsable-${element.id}`}
							id={`collapsable-${element.id}`}
						/>
					))}
			</Grid>
		</>
	);
};

export default Collapsable;

type TItemRender = {
	id: string;
	item: ICollapsable;
	row: number;
	disabled: boolean;
	activeElements: Set<string>;
	handleCheckChange: (EF_ID: string, value: boolean) => void;
};
const ItemRender = (props: TItemRender) => {
	const { id, item, row, disabled, activeElements, handleCheckChange } = props;
	const { name, id: idItem, value: valueItem } = item;

	return (
		<Grid container item columns={16} display={'flex'} alignItems="center">
			<Grid item xs={3}>
				<Typography variant="body1" fontWeight="bold">
					{name} {row + 1}
				</Typography>
			</Grid>
			<Grid item xs={7}>
				<Input
					id={id}
					value={valueItem}
					fullWidth
					placeholder=""
					disabled={disabled}
					InputProps={{
						readOnly: true,
					}}
				/>
			</Grid>
			<Grid item xs={1}>
				<CheckBox
					disabled={disabled}
					checked={activeElements.has(String(idItem))}
					onChange={(_, checked) => handleCheckChange(String(idItem), checked)}
				/>
			</Grid>
		</Grid>
	);
};
