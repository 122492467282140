import { Add } from '@mui/icons-material';
import { Box, Divider, Paper } from '@mui/material';
import TableCustom from '../../components/table-custom.component';
import { useSupervisionExtraordinary } from './hooks/useSupervisionExtraordinary';
import { columnsSupervisionExtraordinary } from './utils/columns-supervision-extraordinary.utils';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { isAdmin, isGerente } from '../../utils/permissions.utils';

export const SupervisionExtraordinary = () => {
	const { data, setData, createSupervision } = useSupervisionExtraordinary();
	const { role } = useSelector((state: RootState) => state.users);

	return (
		<Paper>
			<Box padding={3}>
				<h3>Supervisiones extraordinarias</h3>
				<Divider />
				<TableCustom
					columns={columnsSupervisionExtraordinary}
					data={data}
					setData={setData}
					buttons={[
						{
							icon: Add,
							label: 'Crear supervisión extraordinaria',
							id: 'crear-SE',
							execute: () => createSupervision(),
							type: 'blue',
							isHidden: !(isAdmin(role) || isGerente(role)),
						},
					]}
					showPagination
					showLimitPerPage
				/>
			</Box>
		</Paper>
	);
};
