/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

export interface HistoryStateValues {
	currentStep: number;
	initialState: number;
	ignoreStep: undefined | number;
	items: any[];
}

interface IUseHistoryState {
	initialState: number;
	ignoreStep: number | undefined;
}

export function useHistoryState({ initialState, ignoreStep }: IUseHistoryState) {
	const [historyState, setHistoryState] = useState<HistoryStateValues>({
		currentStep: 0,
		initialState: initialState,
		ignoreStep: ignoreStep,
		items: [],
	});

	const setNewState = (newState: number): void => {
		const newItems: any = historyState.items;
		const lastElement = newItems.at(-1);
		if (
			historyState.ignoreStep !== historyState.currentStep &&
			historyState.currentStep !== lastElement
		) {
			newItems.push(historyState.currentStep);
		}
		setHistoryState({
			...historyState,
			currentStep: newState,
			items: newItems,
		});
	};

	const undoState = (): void => {
		setHistoryState({
			...historyState,
			currentStep: historyState.items.at(-1) || historyState.initialState,
			items: historyState.items.slice(0, historyState.items.length - 1),
		});
	};

	return { historyState, setNewState, undoState };
}
