/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import { getDateLocalMX } from '../../../utils/dates';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { resetState } from '../../../store/modules/steps';
import { getTableColumns } from '../../../utils/tableInfo';
import { setAlert } from '../../../store/modules/snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { stopLoading } from '../../../store/modules/loading';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { STATUS_ROUTINE } from '../../../constants/status-routine';
import { ITypeRoutine } from '../../../constants/routines.constants';
import { IUser, defaultValueUser } from '../../../interfaces/user.interface';
import { IResponsePayload, useApiRequest } from '../../../hooks/useApiRequest';
import { getRoutineDescriptionByType } from '../../../utils/get-title-routines';
import {
	IParamsSupervisionDetail,
	IResponseSupervisionDetail,
} from '../interfaces/supervision-detail.interface';

export function useSupervisionDetailTask() {
	const { state } = useLocation() as IParamsSupervisionDetail;
	const dispatch = useDispatch();
	const [routineInfo, setRoutineInfo] = useState({
		supervisorDescription: '',
		unitInfo: '',
		canBeSuspended: false,
	});
	const { data: dataTable, setData: setDataTable } = useTableCustom<Object>();
	const { data: supervisor, setData: setSupervisor } = useTableCustom<Object>();
	const [isTeam, setIsTeam] = useState(false);
	const [dataSupervisor, setDataSupervisor] = useState<IUser>({
		...defaultValueUser,
	});

	const navigate = useNavigate();
	const { functionalBase, functionalParentBase } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [columnsActivities] = useState(
		getTableColumns(
			functionalBase
				? [
						'Actividades Supervisadas',
						functionalParentBase?.name || 'Nivel 1',
						functionalBase?.name || 'Nivel 2',
				  ]
				: ['Actividades Supervisadas', functionalParentBase?.name || 'Nivel 1']
		)
	);

	const {
		execute: executeGetDetail,
		status,
		value,
	} = useApiRequest<unknown, IResponsePayload<IResponseSupervisionDetail>>({
		path: URLS.SUPERVISION_EXECUTION.GET_SUMMARY_ROUTINE,
		method: 'GET',
	});

	const {
		execute: assignDate,
		status: statusDate,
		value: valueDate,
	} = useApiRequest<unknown, IResponsePayload<IResponseSupervisionDetail>>({
		path: URLS.INCOMING_ROUTINES.ASSIGN_SUPERVISION_DATE,
		method: 'POST',
	});

	useEffect(() => {
		if (!state) {
			navigate(-1);
		}
		if (status === 'success') {
			dispatch(stopLoading());
			setRoutineInfo({
				supervisorDescription: value?.detail?.supervisorDescription || '',
				unitInfo: getRoutineDescriptionByType(
					value?.detail?.routine?.type as keyof ITypeRoutine,
					value?.detail?.routine?.executionDate as unknown as Date
				),
				canBeSuspended:
					value?.detail?.routine?.status === STATUS_ROUTINE.EXECUTING &&
					value?.detail?.circularDashboard?.accomplishedPercentage < 100.0,
			});
			const data: IUser[] = [];
			value?.detail?.supervisors.forEach((item: any) => {
				data.push({
					...item,
					name: item.firstName + ' ' + item.lastName,
					date: getDateLocalMX(item.lastConnection),
					status: item.status ? 'Activo' : 'Inactivo',
				});
			});

			setIsTeam(value?.detail?.isAssignedToTeam || false);
			setDataSupervisor(data[0] || []);

			setSupervisor({
				...supervisor,
				rows: data || [],
				sort: {
					col: '',
					order: 'ASC',
				},
			});

			const activities: { [key: string]: string }[] = [];
			value?.detail?.units.forEach((item: any) => {
				const dataA: any = [];
				item.activities.forEach((i: any) => {
					dataA.push(i.activityDescription);
				});
				activities.push({
					0: dataA.join(),
					1: item.functionalParentName,
					2: item.functionalDescription,
				});
			});

			setDataTable({
				...dataTable,
				rows: activities || [],
				sort: {
					col: '',
					order: 'ASC',
				},
			});
			dispatch(resetState());
		} else if (status === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [status]);

	useEffect(() => {
		if (statusDate === 'success') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Se ha asignado la fecha correctamente',
					severity: 'success',
				})
			);

			dispatch(resetState());
		} else if (statusDate === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueDate?.message || 'Ocurrió un error al asignar fecha',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusDate]);

	return {
		state,
		dataTable,
		routineInfo,
		isTeam,
		columnsActivities,
		setDataTable,
		setSupervisor,
		supervisor,
		dataSupervisor,
		executeGetDetail,
		assignDate,
	};
}
