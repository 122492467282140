import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../../components/header-edit.component';
import ActividadesIcon from '../../../assets/icons/actividades.icon';
import { GoBack } from '../../../components/go-back-component';
import { Button } from '../../../components/main-button.component';
import { useEditIG } from './hooks/useEditIG';
import { ListIGs } from './list-IGs.component';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';

interface IEditIG {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	currentActivity: IAdaptedValues;
}

export const EditIG = ({ setStep, currentActivity }: IEditIG) => {
	const { matrixIGs, matches, checkedList, selectColumn, onSelect, onSave } = useEditIG(
		{
			setStep,
			currentActivity,
		}
	);

	return (
		<>
			<GoBack action={() => setStep(1)} />
			<Paper>
				<Box padding={3}>
					<Grid
						container
						spacing={3}
						justifyContent={matches ? 'center' : 'flex-end'}
					>
						<HeaderEdit
							section="Actividad"
							title="3. Asigna o edita los criterios asociados a tu actividad."
							alertText="Asocia los criterios generales que aplican a tu actividad."
							icon={<ActividadesIcon size="23px" />}
						/>
						<Grid item xs={12}>
							<h2 style={{ margin: '0px' }}>
								{currentActivity.description}
							</h2>
						</Grid>
						<Grid item xs={12}>
							<ListIGs
								matrixIGs={matrixIGs}
								checkedList={checkedList}
								selectColumn={selectColumn}
								onSelect={onSelect}
							/>
						</Grid>
						<Grid item>
							<Button
								label="Guardar"
								onClick={() => onSave()}
								variant="outlined"
								style={{
									backgroundColor: ' #0080F9',
									width: '200px',
									color: 'white',
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
