/* eslint-disable react-hooks/exhaustive-deps */
import { IColumns, useTableCustom } from '../../../../hooks/useTableCustom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../store/modules/snackbar';
import { IResponsePayload, useApiRequest } from '../../../../hooks/useApiRequest';
import { URLS } from '../../../../constants/urls.constants';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { suspensioByRoutineAdapter } from '../adapters/supervisionDetail';
import { ISuspensionByRoutine } from '../interfaces/suspension-by-routine.interface';
import { getSizeColumn } from '../../../../utils/tableInfo';

export function useSuspensionByRoutine() {
	const [columnsDynamic, setColumnsDynamic] = useState<IColumns[]>([]);
	const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
	const [allReschedule, setAllReschedule] = useState<boolean>(false);
	const [calendarValue, setCalendarValue] = useState<Date>(new Date());
	const [supervisionRoutineActive, setSupervisionRoutineActive] = useState<string>('');

	const dispatch = useDispatch();
	const {
		data: dataTable,
		setData: setDataTable,
		pagination,
		rowsPerPage,
		filters,
	} = useTableCustom<Object>();
	const location = useLocation();
	const idRoutineParams = location.state.routine as string;

	const {
		execute: getByRoutine,
		status: statusByRoutine,
		value: valueByRoutine,
	} = useApiRequest<
		unknown,
		IResponsePayload<{ supervisionRoutines: ISuspensionByRoutine[] }>
	>({
		path: URLS.SUSPENSION.SUSPENSION_MANAGEMENT_BY_ROUTINE,
		method: 'GET',
	});

	const {
		execute: confirmBySupervisionRoutine,
		status: statusConfirmBySupervisionRoutine,
		value: valueConfirmBySupervisionRoutine,
	} = useApiRequest<{ supervisionRoutine: string }, { message: string }>({
		path: URLS.SUSPENSION.SUSPENSION_MANAGEMENT_CONFIRM_BY_SUPERVISION_ROUTINE,
		method: 'POST',
	});

	const {
		execute: confirmByRoutine,
		status: statusConfirmByRoutine,
		value: valueConfirmByRoutine,
	} = useApiRequest<{ routine: string }, { message: string }>({
		path: URLS.SUSPENSION.SUSPENSION_MANAGEMENT_CONFIRM_BY_ROUTINE,
		method: 'POST',
	});

	const {
		execute: rescheduleBySupervisionRoutine,
		status: statusRescheduleBySupervisionRoutine,
		value: valueRescheduleBySupervisionRoutine,
	} = useApiRequest<
		{ supervisionRoutine: string; newSuspensionDate: string | Date },
		{ message: string }
	>({
		path: URLS.SUSPENSION.SUSPENSION_MANAGEMENT_RESCHEDULE_BY_SUPERVISION_ROUTINE,
		method: 'POST',
	});

	const {
		execute: rescheduleByRoutine,
		status: statusRescheduleByRoutine,
		value: valueRescheduleByRoutine,
	} = useApiRequest<
		{ routine: string; newSuspensionDate: string | Date },
		{ message: string }
	>({
		path: URLS.SUSPENSION.SUSPENSION_MANAGEMENT_RESCHEDULE_BY_ROUTINE,
		method: 'POST',
	});

	useEffect(() => {
		handleGetByRoutine();
	}, [pagination, rowsPerPage, filters]);

	useEffect(() => {
		if (statusByRoutine === 'success') {
			if ((valueByRoutine?.detail?.supervisionRoutines || []).length > 0) {
				let columnsFlag: IColumns[] = [];
				const supervisionRoutine = valueByRoutine?.detail?.supervisionRoutines[0];
				if (
					supervisionRoutine?.functionalParentDescription &&
					supervisionRoutine?.functionalParentName
				) {
					columnsFlag = [
						...columnsFlag,
						{
							text: supervisionRoutine?.functionalParentName,
							nameID: 'functionalParentDescription',
						},
					];
				}
				columnsFlag = [
					...columnsFlag,
					{
						text: supervisionRoutine?.functionalName || '',
						nameID: 'functionalDescription',
						width: getSizeColumn(supervisionRoutine?.functionalName || ''),
					},
					{
						text: supervisionRoutine?.indicatorName || '',
						nameID: 'indicatorDescription',
						width: getSizeColumn(supervisionRoutine?.indicatorName || ''),
					},
					{
						text: supervisionRoutine?.activityName || '',
						nameID: 'activityDescription',
						width: getSizeColumn(supervisionRoutine?.activityName || ''),
					},
					{
						text: '',
						nameID: 'acciones',
					},
				];
				setColumnsDynamic(columnsFlag);
			}
			setDataTable({
				...dataTable,
				countRows: valueByRoutine?.totalRecords || 0,
				rows: suspensioByRoutineAdapter(
					valueByRoutine?.detail?.supervisionRoutines || [],
					handleConfirmBySupervisionRoutine,
					handlOnRescheduleBySupervisionRoutine
				),
			});
			dispatch(stopLoading());
		} else if (statusByRoutine === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar las suspensiones',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusByRoutine]);

	useEffect(() => {
		if (statusConfirmBySupervisionRoutine === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Se confirmo la suspensión de forma correcta',
					severity: 'success',
				})
			);
			handleGetByRoutine();
		} else if (statusConfirmBySupervisionRoutine === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueConfirmBySupervisionRoutine?.message ||
						'Ocurrió un error al actualizar la suspensión',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusConfirmBySupervisionRoutine]);

	useEffect(() => {
		if (statusConfirmByRoutine === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Se confirmo la suspensión de forma correcta',
					severity: 'success',
				})
			);
			handleGetByRoutine();
		} else if (statusConfirmByRoutine === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueConfirmByRoutine?.message ||
						'Ocurrió un error al actualizar las suspensiones',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusConfirmByRoutine]);

	useEffect(() => {
		if (statusRescheduleBySupervisionRoutine === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Se reprogramo la suspensión de forma correcta',
					severity: 'success',
				})
			);
			handleGetByRoutine();
		} else if (statusRescheduleBySupervisionRoutine === 'error') {
			dispatch(
				setAlert({
					show: true,
					message:
						valueRescheduleBySupervisionRoutine?.message ||
						'Ocurrió un error al reprogramar la suspensión',
					severity: 'error',
				})
			);
			setAllReschedule(false);
			setShowCalendarModal(false);
			setCalendarValue(new Date());
			dispatch(stopLoading());
		}
	}, [statusRescheduleBySupervisionRoutine]);

	useEffect(() => {
		if (statusRescheduleByRoutine === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Se reprogramo la suspensión de forma correcta',
					severity: 'success',
				})
			);
			handleGetByRoutine();
		} else if (statusRescheduleByRoutine === 'error') {
			setAllReschedule(false);
			setShowCalendarModal(!showCalendarModal);
			dispatch(
				setAlert({
					show: true,
					message:
						valueRescheduleByRoutine?.message ||
						'Ocurrió un error al reprogramar la suspensión',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusRescheduleByRoutine]);

	const handleGetByRoutine = () => {
		dispatch(setLoading({ loading: true }));
		setSupervisionRoutineActive('');
		setAllReschedule(false);
		setShowCalendarModal(false);
		setCalendarValue(new Date());
		getByRoutine(
			null,
			`/${idRoutineParams}?page=${pagination}&recordsByPage=${rowsPerPage}&advancedSearch=${
				filters?.advancedSearch || ''
			}`
		);
	};

	const handleConfirmBySupervisionRoutine = (supervisionRoutine: string) => {
		dispatch(setLoading({ loading: true }));
		confirmBySupervisionRoutine({ supervisionRoutine });
	};

	const handleConfirmByRoutine = () => {
		dispatch(setLoading({ loading: true }));
		confirmByRoutine({ routine: idRoutineParams });
	};

	const handleRescheduleBySupervisionRoutine = () => {
		dispatch(setLoading({ loading: true }));
		setShowCalendarModal(!showCalendarModal);
		setCalendarValue(new Date());
		rescheduleBySupervisionRoutine({
			supervisionRoutine: supervisionRoutineActive,
			newSuspensionDate: calendarValue,
		});
	};

	const handleRescheduleByRoutine = () => {
		dispatch(setLoading({ loading: true }));
		rescheduleByRoutine({
			routine: idRoutineParams,
			newSuspensionDate: calendarValue,
		});
	};

	const handlOnRescheduleBySupervisionRoutine = (supervisionRoutine: string) => {
		setShowCalendarModal(true);
		setAllReschedule(false);
		setSupervisionRoutineActive(supervisionRoutine);
	};

	return {
		columnsDynamic,
		dataTable,
		setDataTable,
		handleConfirmBySupervisionRoutine,
		handleRescheduleBySupervisionRoutine,
		showCalendarModal,
		setShowCalendarModal,
		calendarValue,
		setCalendarValue,
		handleConfirmByRoutine,
		setAllReschedule,
		allReschedule,
		handleRescheduleByRoutine,
	};
}
