import { createSlice } from '@reduxjs/toolkit';

interface IStateProject {
	state: number;
	previousStates: number[];
}

const defaultValueStateProject: IStateProject = {
	state: 0,
	previousStates: [],
};
export const StateProjectSlice = createSlice({
	name: 'stateProject',
	initialState: defaultValueStateProject,
	reducers: {
		setState: (state, action) => ({
			...state,
			...action.payload,
			previousStates: [...state.previousStates, state.state],
		}),
		undoState: (state) => ({
			state: state.previousStates[state.previousStates.length - 1] | 0,
			previousStates: state.previousStates.slice(
				0,
				state.previousStates.length - 1
			),
		}),
		resetState: () => defaultValueStateProject,
	},
});

export const { setState, undoState, resetState } = StateProjectSlice.actions;

export default StateProjectSlice.reducer;
