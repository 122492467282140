import Modal from '../../components/modal.component';
import { CreateSupervisionContext } from './contexts/useCreateSupervisionExtraordinaryContext';
import { useCreateSupervisionExtraordinary } from './hooks/useCreateSupervisionExtraordinary';

export const CreateSupervisionExtraordinary = () => {
	const {
		idProject,
		supervisionExtraordinaryData,
		modal,
		getComponent,
		setSupervisionExtraordinaryData,
		setModal,
	} = useCreateSupervisionExtraordinary();

	return (
		<CreateSupervisionContext.Provider
			value={{
				idProject,
				supervisionExtraordinaryData,
				modal,
				setSupervisionExtraordinaryData,
				setModal,
			}}
		>
			{getComponent()}
			<Modal
				open={modal.open}
				title={'Regresar al paso anterior'}
				cancelLabel="Cancelar"
				confirmLabel="Continuar"
				styleButtonConfirm={{ color: '#0080F9' }}
				width="sm"
				onClose={modal.onClose}
				onConfirm={modal.callback}
				modal={modal}
				setModal={setModal}
			>
				¿Estás seguro que deseas regresar al paso anterior? El progreso actual se
				perderá.
			</Modal>
		</CreateSupervisionContext.Provider>
	);
};
