/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { stopLoading } from '../../../../../store/modules/loading';
import { functionalsAdapter } from '../adapters/get-functional.adapter';
import { TSpatialUnit } from '../../../interfaces/get-supervision.interfaces';
import { IResponsePayload, useApiRequest } from '../../../../../hooks/useApiRequest';
import { IFunctionalsExtraordinary } from '../interfaces/get-functionals.interface';
import { setAlert } from '../../../../../store/modules/snackbar';
import { IOptionsMenu } from '../../../../../interfaces/select.interface';

interface IUseGetFunctionals {
	setFuncionals?: React.Dispatch<React.SetStateAction<any>>;
	isLevelOne: boolean;
	getOptions?: boolean;
}

export function useGetFunctionals({
	setFuncionals,
	isLevelOne,
	getOptions = false,
}: IUseGetFunctionals) {
	const dispatch = useDispatch();
	const [functionalUnit, setFunctionalUnit] = useState<TSpatialUnit[]>([]);
	const [functionalOptions, setFunctionalOptions] = useState<IOptionsMenu[]>([]);

	const {
		execute: getFunctionals,
		status: statusFunctionals,
		value: valueFunctionals,
	} = useApiRequest<unknown, IResponsePayload<IFunctionalsExtraordinary>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.GET_FUNCTIONALS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusFunctionals === 'success') {
			dispatch(stopLoading());
			setFunctionalUnit(functionalsAdapter(valueFunctionals?.data || []));
			getOptions ? getValuesOptions() : getValuesSelected();
		} else if (statusFunctionals === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusFunctionals]);

	const getValuesSelected = () => {
		if (isLevelOne) {
			if (setFuncionals)
				setFuncionals(
					valueFunctionals?.data?.find((element) => element.applies === true)
						?.functional || ''
				);
		} else {
			const functionlsUnitN2 = valueFunctionals?.data?.filter(
				(e) => e.applies === true
			);
			if (setFuncionals)
				setFuncionals(
					functionlsUnitN2?.map((e) => {
						return e.functional;
					})
				);
		}
	};

	const getValuesOptions = (): void => {
		setFunctionalOptions(
			valueFunctionals?.data?.map((e) => {
				return {
					id: e._id,
					label: e.description,
					value: e.functional,
					status: e.isIndicatorAssociated,
				};
			}) || []
		);
	};

	return { functionalUnit, functionalOptions, getFunctionals };
}
