/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewActivities } from '../components/viewActivities.component';

export const useEditScheduleActivity = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;

	useEffect(() => {
		if (!idProject) navigate(-1);
	}, []);

	const getComponent = () => {
		return <ViewActivities />;
	};

	return { getComponent };
};
