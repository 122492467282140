/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { columns } from './utils/columns.utils';
import { setLoading } from '../../store/modules/loading';
import styles from './styles/execution-styles.module.css';
import { GoBack } from '../../components/go-back-component';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '../../components/main-button.component';
import { SelectDate } from '../../components/static-date-picker';
import TableCustom from '../../components/table-custom.component';
import { WorkHistoryOutlined, CalendarMonth } from '@mui/icons-material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useSupervisionDetailTask } from './hooks/useSupervisionDetailTask';

export const SupervisionDetailTasks = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const stateSelected = location?.state?.params;
	const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
	const [calendarValue, setCalendarValue] = useState<Date>(new Date());

	const {
		dataTable,
		routineInfo,
		isTeam,
		columnsActivities,
		setDataTable,
		setSupervisor,
		supervisor,
		dataSupervisor,
		executeGetDetail,
		assignDate,
	} = useSupervisionDetailTask();

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetDetail({}, `/${stateSelected?.idRoutine}`);
	}, [dataTable.pagination, dataTable.rowsPerPage]);

	const onCalendar = () => {
		dispatch(setLoading({ loading: true }));
		assignDate(
			{ supervisor: dataSupervisor?._id, executionDate: calendarValue },
			`/${stateSelected?.idSupervisionRoutine}`
		);
		setShowCalendarModal(false);
	};

	return (
		<>
			<GoBack
				action={() => {
					navigate(-1);
				}}
			/>
			<Paper elevation={0}>
				<Box padding={4}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Grid
										container
										justifyContent={'flex-end'}
										spacing={3}
									>
										<Grid item>
											{stateSelected?.isTaskBase === true ? (
												<Grid item>
													<Button
														icon={CalendarMonth}
														label="Asignar fecha para tarea no recurrente"
														onClick={() =>
															setShowCalendarModal(true)
														}
														style={{
															backgroundColor: '#0080F9',
															padding: '10px',
														}}
													/>
													{showCalendarModal && (
														<Paper
															elevation={1}
															sx={{
																display: 'flex',
																flexDirection: 'column',
																alignItems: 'center',
																padding: '1rem',
																justifyContent: 'center',
																position: 'absolute',
																zIndex: 9,
															}}
														>
															<SelectDate
																value={calendarValue}
																setValueDate={
																	setCalendarValue
																}
															/>
															<Grid item xs={12}>
																<Grid
																	container
																	justifyContent={
																		'center'
																	}
																	spacing={2}
																>
																	<Grid item>
																		<Button
																			label="Cancelar"
																			onClick={() =>
																				setShowCalendarModal(
																					false
																				)
																			}
																			style={{
																				backgroundColor:
																					'var(--color-error-main)',
																				width: '150px',
																			}}
																		/>
																	</Grid>
																	<Grid item>
																		<Button
																			label="OK"
																			onClick={() =>
																				onCalendar()
																			}
																			style={{
																				backgroundColor:
																					'var(--color-highlight-blue)',
																				width: '150px',
																			}}
																		/>
																	</Grid>
																</Grid>
															</Grid>
														</Paper>
													)}
												</Grid>
											) : (
												routineInfo.canBeSuspended && (
													<Button
														icon={WorkHistoryOutlined}
														label="Suspender supervisión"
														onClick={() => {
															navigate(
																'/perfil-proyecto/proximas-tareas/ver-supervision/suspender-supervision',
																{
																	state: {
																		routine:
																			stateSelected.idRoutine,
																		supervisorName:
																			routineInfo.supervisorDescription,
																		unitInfo:
																			routineInfo.unitInfo,
																		backAction: true,
																	},
																}
															);
														}}
														style={{
															backgroundColor:
																'var(--color-yellow-main)',
														}}
													/>
												)
											)}
										</Grid>
									</Grid>

									<Grid container>
										<Grid item xs={12}>
											<Grid
												item
												container
												justifyContent={'flex-start'}
											>
												<PeopleAltOutlinedIcon
													className={styles.iconExecution}
												/>
												<Grid item xs>
													<h3 style={{ margin: 0 }}>
														{isTeam
															? 'Equipo encargado'
															: 'Supervisor encargado'}
													</h3>
													<span
														className={`${styles.subtitle} ${styles.grey}`}
													>
														Vizualiza a los supervisores del
														proyecto
													</span>
												</Grid>
											</Grid>
											<Grid item>
												<TableCustom
													columns={columns}
													data={supervisor}
													setData={setSupervisor}
													hideHeader
												/>
											</Grid>
										</Grid>
									</Grid>
									<Box padding={4}>
										<Grid item container spacing={4}>
											<Grid item xs={12}>
												<Paper
													elevation={0}
													className={styles.backgroundGrey}
												>
													<TableCustom
														columns={columnsActivities}
														data={dataTable}
														setData={setDataTable}
														hideHeader
													/>
												</Paper>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
