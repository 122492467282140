/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { URLS } from '../../../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../../store/modules/snackbar';
import { stopLoading } from '../../../../../store/modules/loading';

export const useDownloadPlanning = (period: string) => {
	const dispatch = useDispatch<any>();
	const {
		execute: executeReportPlanning,
		status,
		value,
	} = useApiRequest<any, IResponsePayload<Blob>>({
		path: URLS.REPORTS.DOWNLOAD_REPORTS,
		method: 'POST',
	});

	useEffect(() => {
		if (status === 'success') {
			const linkSource =
				'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
				value?.detail;
			const downloadLink = document.createElement('a');
			downloadLink.hidden = true;
			downloadLink.href = linkSource;
			downloadLink.download = `Planeación ${period}.xlsx`;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		} else if (status === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: value?.message
						? value?.message
						: 'Ocurrio un error al exportar la planeación',
					severity: 'error',
				})
			);
		}
		dispatch(stopLoading());
	}, [status]);

	return { executeReportPlanning };
};
