import { format } from 'date-fns';
import { ITypeRoutine, ROUTINE_TYPE_ENUM } from '../constants/routines.constants';

export const getRoutineDescriptionByType = (
	type: keyof ITypeRoutine,
	executionDate?: Date
) => {
	switch (type) {
		case ROUTINE_TYPE_ENUM.RECURRENT:
			if (!executionDate) return 'Supervisión recurrente';
			return `Supervisión recurrente: ${format(
				new Date(executionDate),
				'yyyy-MM-dd'
			)}`;
		case ROUTINE_TYPE_ENUM.NON_RECURRENT:
			if (!executionDate) return 'Supervisión no recurrente';
			return `Supervisión no recurrente: ${format(
				new Date(executionDate),
				'yyyy-MM-dd'
			)}`;
		case ROUTINE_TYPE_ENUM.EXTRAORDINARY:
			if (!executionDate) return 'Supervisión extraordinaria';
			return `Supervisión extraordinaria: ${format(
				new Date(executionDate),
				'yyyy-MM-dd'
			)}`;
		default:
			return 'Supervisión';
	}
};
