/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useMemo } from 'react';
import { setAlert } from '../../../../store/modules/snackbar';
import { useGetListRoutine } from '../../hooks/useGetRoutineFunctionals';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import {
	getGetRoutineActivitiesAdapter,
	getGetRoutineFunctionalsAdapter,
} from '../../adapters/routine-functionals.adapter';
import { STATUS_SELECT_TYPES } from '../../../../constants/status-select-types';
import { useLocation } from 'react-router-dom';

export function useSelectService() {
	const dispatch = useDispatch();
	const location = useLocation();
	const isEdit = location.state.isEdit as boolean;
	const user = useSelector((state: RootState) => state.users);
	const { idRoutine, idProject, mainSelect, options, setMainSelect, setOptions } =
		useContext(SupervisionProjectContext);

	const { getListRoutine, statusListRoutine, valueListRoutine } = useGetListRoutine();

	useEffect(() => {
		if (mainSelect.optionLabel === 'espacio') {
			dispatch(setLoading({ loading: true }));
			getListRoutine(
				{},
				`/functionals?routine=${idRoutine}&project=${idProject}&functionalParent=null`
			);
		} else if (mainSelect.optionLabel === 'servicio') {
			dispatch(setLoading({ loading: true }));
			getListRoutine({}, `/activities?routine=${idRoutine}&project=${idProject}`);
		}
	}, [mainSelect.optionLabel]);

	useEffect(() => {
		if (statusListRoutine === 'success') {
			dispatch(stopLoading());
			if (mainSelect.optionLabel === 'espacio') {
				setOptions(getGetRoutineFunctionalsAdapter(valueListRoutine?.data || []));
			} else if (mainSelect.optionLabel === 'servicio') {
				setOptions(getGetRoutineActivitiesAdapter(valueListRoutine?.data || []));
			}
		} else if (statusListRoutine === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error, vuelva a recargar.',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusListRoutine]);

	const handleChange = (event: any) => {
		const { name } = event.target;
		if (name === undefined) {
			setMainSelect({
				...mainSelect,
				valueLabel: event.target.value,
			});
		} else {
			setMainSelect({
				...mainSelect,
				[name]: event.target.value,
				valueLabel: '',
			});
		}
	};

	const getStartTiming = (): boolean => {
		const valueStatus = options.find(
			(e) => e.value === mainSelect.valueLabel
		)?.status;
		if (valueStatus === STATUS_SELECT_TYPES.COMPLETED || isEdit) return false;
		else return true;
	};

	const disabledButton = useMemo((): boolean => {
		if (mainSelect.optionLabel !== '' && mainSelect.valueLabel !== '') return false;
		return true;
	}, [mainSelect]);

	return {
		user,
		mainSelect,
		disabledButton,
		options,
		dispatch,
		handleChange,
		getStartTiming,
	};
}
