/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { setAlert } from '../../../../../store/modules/snackbar';
import {
	IActivitiesLabels,
	IActivitiesLabelsResponse,
} from '../interfaces/activitiesLabels.interface';

export const useGetActivitiesLabels = () => {
	const dispatch = useDispatch();

	const {
		execute: executeGetActivitiesLabels,
		status: statusActivitiesLabels,
		value: valueActivitiesLabels,
	} = useApiRequest<IActivitiesLabels, IResponsePayload<IActivitiesLabelsResponse>>({
		path: URLS.SUPERVISION_EXECUTION.ACTIVITIES_LABELS,
		method: 'POST',
	});

	useEffect(() => {
		if (statusActivitiesLabels === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al cargar la supervisión de hoy',
					severity: 'error',
				})
			);
		}
	}, [statusActivitiesLabels]);

	return { valueActivitiesLabels, executeGetActivitiesLabels };
};
