import { Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement } from 'react';
import styles from './styles/card-styles.module.css';

export interface ICard {
	icon?: ReactElement;
	title: string;
	subtitle: string;
	stats?: string;
	isHour?: boolean;
	stylesContainer?: React.CSSProperties;
	stylesColorFont?: React.CSSProperties;
	body?: JSX.Element;
	name?: string;
}

export const CardComponent = ({
	icon,
	title,
	subtitle,
	stats,
	isHour,
	stylesContainer,
	stylesColorFont,
	body,
}: ICard) => {
	return (
		<Paper className={styles.cardContainer} style={{ ...stylesContainer }}>
			<Box padding={3}>
				<Grid container spacing={4}>
					{icon && <Grid item>{icon}</Grid>}
					{body ? (
						body
					) : (
						<Grid item md>
							<h4
								id={title.toLowerCase().replaceAll(' ', '-')}
								className={styles.title}
								style={stylesColorFont}
							>
								{title}
							</h4>
							<span className={styles.subtitle} style={stylesColorFont}>
								{subtitle}
							</span>
							<br />
							{isHour && <br />}
							<span className={styles.stats} style={stylesColorFont}>
								{stats}{' '}
								{isHour && (
									<span
										className={styles.hours}
										style={stylesColorFont}
									>
										HRS
									</span>
								)}
							</span>
						</Grid>
					)}
				</Grid>
			</Box>
		</Paper>
	);
};
