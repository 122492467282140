/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useContext, useState } from 'react';
import theme from '../../../../../config/theme';
import { Evidences } from '../../evidences.component';
import { Findings } from '../../findings.component';
import { ViewDetailsInfo } from '../view-details-info.component';
import { SupervisionProjectContext } from '../../../contexts/useSupervisionProjectContext';
import { Suspensions } from '../../suspensions.component.';

export function useViewDetails() {
	const [currentTab, setCurrentTab] = useState<number>(0);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [currentState, setCurrentState] = useState('');

	const { undoState } = useContext(SupervisionProjectContext);

	const tabItems = [
		{ label: 'Detalles', value: 0 },
		{ label: 'Evidencias', value: 1 },
		{ label: 'Hallazgos', value: 2 },
		{ label: 'Suspensiones', value: 3 },
	];

	const getComponent = () => {
		switch (currentTab) {
			case 0:
				return <ViewDetailsInfo setCurrentState={setCurrentState} />;
			case 1:
				return (
					<Evidences currentState={currentState} isSupervisionRoutine={true} />
				);
			case 2:
				return <Findings isSupervisionRoutine={true} isFindings={true} />;
			case 3:
				return <Suspensions isSupervisionRoutine={true} isFindings={false} />;
			default:
				return <ViewDetailsInfo setCurrentState={setCurrentState} />;
		}
	};

	return {
		currentTab,
		matches,
		tabItems,
		setCurrentTab,
		getComponent,
		undoState,
	};
}
