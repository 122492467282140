import { Box, Paper, Tab, Tabs } from '@mui/material';
import { useSummaryRoutine } from './hooks/useSummaryRoutine';
import styles from './styles/summary-routine-styles.module.css';
import { resetPP } from '../../../../store/modules/profile-project';

export const SummaryRoutine = () => {
	const { currentTab, matches, tabItems, setCurrentTab, dispatch, getComponent } =
		useSummaryRoutine();

	return (
		<>
			<Tabs
				value={currentTab}
				variant="scrollable"
				onChange={(_, value) => {
					dispatch(resetPP());
					setCurrentTab(value);
				}}
				TabIndicatorProps={{
					style: { background: '#0080F9' },
				}}
				sx={{
					'& button.Mui-selected': {
						color: '#0080F9',
					},
					width: '100%',
				}}
				orientation={matches ? 'vertical' : 'horizontal'}
			>
				{(tabItems || []).map((element, index: number) => {
					return (
						<Tab
							key={index}
							className={styles.tabsStyle}
							label={element.label}
						/>
					);
				})}
			</Tabs>
			<Paper>
				<Box padding={3}>{getComponent()}</Box>
			</Paper>
		</>
	);
};
