import { ModeEdit } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Paper, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Button } from '../../../components/main-button.component';
import TextInput from '../../../components/text-input.component';
import theme from '../../../config/theme';
import { IUser } from '../../../interfaces/user.interface';
import { newUserAdapter } from '../adapter/new-user-adapter';
import styles from './styles/header-user-styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import { isAdmin } from '../../../utils/permissions.utils';
import { RootState } from '../../../store';

interface IPersonalInfo {
	user: IUser;
	temporalData: IUser;
	setTemporalData: React.Dispatch<React.SetStateAction<IUser>>;
	handleSetData: (e: React.ChangeEvent<HTMLInputElement>) => void;
	updateUser: any;
}

export const PersonalInfoComponent = ({
	user,
	temporalData,
	setTemporalData,
	handleSetData,
	updateUser,
}: IPersonalInfo) => {
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { role } = useSelector((state: RootState) => state.users);

	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);

	return (
		<Paper elevation={0}>
			<Box padding={matches ? 3 : 6}>
				<Grid container spacing={3}>
					<Grid item sm={12}>
						<Grid
							container
							justifyContent={matches ? 'center' : 'space-between'}
							alignItems="center"
							rowSpacing={2}
						>
							<Grid item>
								<h3 id="header-name" style={{ margin: 0 }}>
									{user.firstName + ' ' + user.lastName}
								</h3>
							</Grid>
							<Grid item>
								{isEditing ? (
									<div style={{ display: 'flex', gap: 10 }}>
										<Button
											label="Cancelar"
											id="cancel-edit-company"
											onClick={() => setIsEditing(false)}
											variant="outlined"
											style={{
												color: 'var(--color-error-main)',
												width: '120px',
											}}
										/>
										<Button
											label="Guardar"
											id="save-edit-user"
											onClick={() => {
												dispatch(setLoading({ loading: true }));
												updateUser(
													{
														...newUserAdapter(temporalData),
														employeeNumber: parseInt(
															temporalData.employeeNumber.toString()
														),
													},
													`/${temporalData._id}`
												);
												setIsEditing(false);
											}}
											style={{
												width: '120px',
												backgroundColor: '#0080F9',
											}}
										/>
									</div>
								) : (
									<>
										{isAdmin(role) && (
											<IconButton
												onClick={() => {
													setIsEditing(true);
													setTemporalData({ ...user });
												}}
												aria-label="edit-user"
												size="medium"
												className={styles.editIcon}
											>
												<ModeEdit />
											</IconButton>
										)}
									</>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={matches ? 1 : 3} alignItems="center">
							<Grid item xs={12} sm={3}>
								<span id="row-name" className={styles.grey}>
									Nombre
								</span>
							</Grid>
							<Grid item xs={12} sm={9}>
								{isEditing ? (
									<TextInput
										id="firstName"
										name="firstName"
										placeholder="Ingresar nombre"
										value={temporalData.firstName}
										onChange={handleSetData}
									/>
								) : (
									<span id="span-firstName">{user.firstName}</span>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={matches ? 1 : 3} alignItems="center">
							<Grid item xs={12} sm={3}>
								<span id="row-last-name" className={styles.grey}>
									Apellidos
								</span>
							</Grid>
							<Grid item xs={12} sm={9}>
								{isEditing ? (
									<TextInput
										id="lastName"
										name="lastName"
										placeholder="Ingresar apellidos"
										value={temporalData.lastName}
										onChange={handleSetData}
									/>
								) : (
									<span id="span-lastName">{user.lastName}</span>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={matches ? 1 : 3} alignItems="center">
							<Grid item xs={12} sm={3}>
								<span id="row-no-employee" className={styles.grey}>
									No. de empleado
								</span>
							</Grid>
							<Grid item xs={12} sm={9}>
								{isEditing ? (
									<TextInput
										id="employeeNumber"
										name="employeeNumber"
										placeholder="Escribe el número de empleado"
										value={temporalData.employeeNumber}
										onChange={handleSetData}
									/>
								) : (
									<span id="span-employeeNumber">
										{user.employeeNumber}
									</span>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={matches ? 1 : 3} alignItems="center">
							<Grid item xs={12} sm={3}>
								<span id="row-email" className={styles.grey}>
									Correo electrónico
								</span>
							</Grid>
							<Grid item xs={12} sm={9}>
								{isEditing ? (
									<TextInput
										id="email"
										name="email"
										placeholder="Ingresar correo electrónico"
										value={temporalData.email}
										onChange={handleSetData}
									/>
								) : (
									<span id="span-email">{user.email}</span>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
