import { Paper, Box } from '@mui/material';
import { useEditScheduleActivity } from './hooks/useEditScheduleActivity';

export const EditScheduleActivity = () => {
	const { getComponent } = useEditScheduleActivity();

	return (
		<Paper>
			<Box padding={3}>{getComponent()}</Box>
		</Paper>
	);
};
