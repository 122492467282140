/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest } from '../../../../../hooks/useApiRequest';
import { IGetFindings } from '../interfaces/get-findings.interface';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAlert } from '../../../../../store/modules/snackbar';
import {
	getFindignsAdapter,
	getFindingsAdaptedData,
} from '../adapters/get-findings.adapter';
import { IAdaptedValues } from '../../../../../interfaces/edit-adapted-values';

export function useGetFindings() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [findignsInputs, setFindignsInputs] = useState<{ [key: string]: string }>({});
	const [findignsOptions, setFindignsOptions] = useState<IAdaptedValues[]>([]);

	const {
		execute: executeGetFindings,
		status: statusGetFindings,
		value: valueGetFindings,
	} = useApiRequest<unknown, IGetFindings>({
		path: URLS.PROJECT.GET_TYPE_FINDINGS,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetFindings({}, `?_id=${idProject}`);
	}, []);

	useEffect(() => {
		if (statusGetFindings === 'success') {
			dispatch(stopLoading());
			setFindignsInputs(getFindignsAdapter(valueGetFindings?.arrayTypes));
			setFindignsOptions(
				getFindingsAdaptedData(valueGetFindings?.arrayTypes || [])
			);
		} else if (statusGetFindings === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error al consultar la información.',
					severity: 'error',
				})
			);
			navigate(-1);
		}
	}, [statusGetFindings]);

	return { findignsInputs, findignsOptions, setFindignsInputs, setFindignsOptions };
}
