/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../../constants/urls.constants';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { IGetPersonal } from '../../../../../../interfaces/user.interface';
import { IAdaptedValues } from '../../../../../../interfaces/edit-adapted-values';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';

interface IUseDeletePersonal {
	personalData: IAdaptedValues[];
	setPersonalData: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
}

export const useDeletePersonal = ({
	personalData,
	setPersonalData,
}: IUseDeletePersonal) => {
	const dispatch = useDispatch();

	const {
		execute: deletePersonal,
		status: statusDeletePersonal,
		value: valueDeletePersonal,
	} = useApiRequest<unknown, IResponsePayload<IGetPersonal>>({
		path: URLS.EDIT_PROJECT.PERSONALS.GET_PERSONAL_BY_ROLE,
		method: 'DELETE',
	});

	useEffect(() => {
		if (statusDeletePersonal === 'success') {
			setPersonalData(
				personalData.filter(
					(personal) => personal.idPersonal !== valueDeletePersonal?.detail?._id
				)
			);
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Usuario eliminado con éxito',
					severity: 'success',
				})
			);
		} else if (statusDeletePersonal === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al eliminar al usuario',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusDeletePersonal]);

	const handleDeletePersonal = (_id: string) => {
		dispatch(setLoading({ loading: true }));
		deletePersonal(null, `/${_id}`);
	};

	return { handleDeletePersonal };
};
