/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from 'react';
import { RootState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { setValueProject } from '../../../../../store/modules/project';
import { INTERNAL_MESSAGES } from '../../../../../constants/internal-messages';
import { stopLoading, setLoading } from '../../../../../store/modules/loading';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { AutomaticChargeContext } from '../../../contexts/useAutomaticChargeContext';
import { IResponseConfirmProgress } from '../../../interfaces/confirm-progress.interface';

export const useRestartProject = () => {
	const dispatch = useDispatch();
	const project = useSelector((state: RootState) => state.projectBase);
	const { setStep } = useContext(AutomaticChargeContext);

	const {
		execute: executeRestart,
		status: statusRestart,
		value: valueRestart,
	} = useApiRequest<unknown, IResponsePayload<IResponseConfirmProgress>>({
		path: URLS.AUTOMATIC_CHARGE.RESTART,
		method: 'POST',
	});

	useEffect(() => {
		if (statusRestart === 'success') {
			dispatch(stopLoading());
			if (valueRestart?.detail?.newStep) {
				dispatch(
					setValueProject({
						...project,
						automaticStep: valueRestart?.detail?.newStep,
					})
				);
				setStep(valueRestart?.detail?.newStep);
			}
		} else if (statusRestart === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: valueRestart?.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				})
			);
		}
	}, [statusRestart]);

	const restartProject = () => {
		dispatch(setLoading({ loading: true }));
		executeRestart(null, `/${project._id}`);
	};

	return { restartProject };
};
