/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { emailValid, isEmpty } from '../../../utils/validators';
import { defaultAlertValue, IAlert } from '../../../interfaces/alert.interface';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IParamsLogin, IResponseLogin } from '../interfaces/login.interface';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/modules/user';
import { setLoading, stopLoading } from '../../../store/modules/loading';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { useGetInfoUser } from './useGetInfoUser';

export const useSendValidation = () => {
	const [data, setData] = useState({ email: '', password: '', code: '' });
	const [errorEmail, setErrorEmail] = useState({ valid: false, msg: '' });
	const [alert, setAlert] = useState<IAlert>(defaultAlertValue);
	const [isMailSend, setIsMailSend] = useState(false);
	const dispatch = useDispatch();
	const { instance, accounts } = useMsal();
	const { getInfoUser } = useGetInfoUser();
	const {
		execute: sendCode,
		status: statusLogin,
		value: dataLogin,
	} = useApiRequest<IParamsLogin, IResponseLogin>({
		path: URLS.LOGIN.SEND_MAIL_VERIFICATION,
		method: 'POST',
	});

	useEffect(() => {
		if (statusLogin === 'success') {
			const dataFlag = {
				...dataLogin?.user,
				token: dataLogin?.access_token,
			};
			dispatch(setUser({ ...dataFlag }));
			setIsMailSend(true);
			dispatch(stopLoading());
		} else if (statusLogin === 'error') {
			setIsMailSend(false);
			setAlert({
				show: true,
				message: dataLogin?.message || 'No se ha podido enviar el correo',
				severity: 'error',
			});
			dispatch(stopLoading());
		}
	}, [statusLogin]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'email') {
			const validate = emailValid(value);
			setErrorEmail(validate);
		}
		setData({ ...data, [name]: value });
	};

	const handleOnSubmit = () => {
		dispatch(setLoading({ loading: true }));
		sendCode({ ...data });
	};

	const handleValidate = (): void => {
		if (!errorEmail.valid || !data.email) {
			return setAlert({
				show: true,
				message: 'Complete correctamente el campo de Correo electrónico',
				severity: 'error',
			});
		} else if (!data.password) {
			return setAlert({
				show: true,
				message: 'Complete el campo de contraseña',
				severity: 'error',
			});
		}
		handleOnSubmit();
	};

	async function handleRequestAccessToken() {
		dispatch(setLoading({ loading: true }));
		try {
			const request = {
				scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/Files.Read`],
			};
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					hasTokenLogin(response);
				})
				.catch(() => {
					instance.loginRedirect(request);
				});
		} catch (error) {
			setAlert({
				show: true,
				message: 'Error con login con Microsoft',
				severity: 'error',
			});
		} finally {
			sessionStorage.removeItem('msal.idtoken');
			document.cookie = '';
			dispatch(setLoading({ loading: false }));
		}
	}

	useEffect(() => {
		if (!isEmpty(accounts)) {
			dispatch(setLoading({ loading: true }));
			instance
				.acquireTokenSilent({
					scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/Files.Read`],
					account: accounts[0] || '',
				})
				.then((response) => {
					hasTokenLogin(response);
				});
		}
	}, [accounts]);

	const hasTokenLogin = (response: any) => {
		const decodedToken = jwtDecode(response.accessToken) as {
			preferred_username: string;
		};

		dispatch(
			setUser({
				email: decodedToken.preferred_username,
				token: response.accessToken,
				loginMicrosoft: true,
			})
		);
		getInfoUser(response.accessToken);
	};

	return {
		handleValidate,
		handleSetData,
		alert,
		errorEmail,
		data,
		isMailSend,
		handleRequestAccessToken,
		instance,
		accounts,
	};
};
