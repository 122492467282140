import { IReportsTable } from '../interfaces/report.interface';
import { getDateLocalMX } from '../../../../../utils/dates';

export const reportsAdapter = (
	data: IReportsTable[],
	downloadAction: (fileName: string, routine?: string, isZip?: boolean) => void,
	addDowloadAll?: boolean
): IReportsTable[] => {
	const newData: IReportsTable[] = data.map((report: IReportsTable) => {
		return {
			...report,
			creationDate: report.createdAt ? getDateLocalMX(report.createdAt) : '',
			documentacion: (
				<div style={{ display: 'flex', gap: '40px', color: '#0080F9' }}>
					<span
						style={{ cursor: 'pointer' }}
						onClick={() => {
							downloadAction(report.fileNameKey, report.routine, false);
						}}
					>
						Descargar
					</span>
					{addDowloadAll && (
						<span
							style={{ cursor: 'pointer' }}
							onClick={() => {
								downloadAction(report.fileNameKey, report.routine, true);
							}}
						>
							Descarga indiviual (zip)
						</span>
					)}
				</div>
			),
		};
	});
	return newData;
};
