export const monthsOptions = [
	{ id: 0, value: '', label: 'Todos' },
	{ id: 1, value: 1, label: 'Enero' },
	{ id: 2, value: 2, label: 'Febrero' },
	{ id: 3, value: 3, label: 'Marzo' },
	{ id: 4, value: 4, label: 'Abril' },
	{ id: 5, value: 5, label: 'Mayo' },
	{ id: 6, value: 6, label: 'Junio' },
	{ id: 7, value: 7, label: 'Julio' },
	{ id: 8, value: 8, label: 'Agosto' },
	{ id: 9, value: 9, label: 'Septiembre' },
	{ id: 10, value: 10, label: 'Octubre' },
	{ id: 11, value: 11, label: 'Noviembre' },
	{ id: 12, value: 12, label: 'Diciembre' },
];
