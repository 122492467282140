import { Box, Grid, Paper } from '@mui/material';
import styles from './styles/messages-view-styles.module.css';
import { Button } from '../../../components/main-button.component';
import confirmationImage from '../../../assets/imgs/confirmation.png';
import loadingImage from '../../../assets/svg/loading.svg';

interface IMessageView {
	descriptionLabel: string;
	cancelLabel?: string;
	continueLabel?: string;
	remainingLabel?: string;
	isAutomaticProcess?: boolean;
	cancelAction: () => void;
	continueAction: () => void;
}

export const MessagesView = ({
	descriptionLabel,
	cancelLabel,
	continueLabel,
	remainingLabel,
	isAutomaticProcess,
	cancelAction,
	continueAction,
}: IMessageView) => {
	return (
		<Paper>
			<Box padding={3}>
				<Grid item xs={12}>
					<div className={styles.mainContainer}>
						<div className={styles.infoContainer}>
							{!isAutomaticProcess ? (
								<img
									className={styles.img}
									src={confirmationImage}
									alt="actions"
								/>
							) : (
								<img
									className={styles.img}
									src={loadingImage}
									alt="loading"
								/>
							)}
							{remainingLabel && <span>{remainingLabel}</span>}
							<span>{descriptionLabel}</span>
							<div className={styles.buttonsContainer}>
								<Button
									label={cancelLabel || 'Cancelar'}
									onClick={() => cancelAction()}
									style={{
										width: '100%',
									}}
								/>
								{!isAutomaticProcess && (
									<Button
										label={continueLabel || 'Continuar'}
										onClick={() => continueAction()}
										variant="solid"
										style={{
											width: '100%',
											backgroundColor: '#0080F9',
										}}
									/>
								)}
							</div>
						</div>
					</div>
				</Grid>
			</Box>
		</Paper>
	);
};
