/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useTableSupervision } from './hooks/useTableSupervision';
import { Button } from '../../../components/main-button.component';
import InputSelect from '../../../components/select-input.component';
import TableCustom from '../../../components/table-custom.component';
import { GoBack } from '../../../components/go-back-component';
import FilterCustom from '../../../components/filter-custom.component';

export const TableSupervision = () => {
	const {
		mainSelect,
		subSelect,
		options,
		data,
		dinamicColumns,
		openFilters,
		filterInputs,
		setData,
		completeSupervision,
		setOpenFilters,
		handleFilter,
		setSubSelect,
		handleStopTiming,
		functionalsLvl1,
		functionalsLvl2,
		activitiesList,
		withTwoLevels,
		labelN1,
		labelN2,
		labelService,
		isEdit,
	} = useTableSupervision();

	return (
		<>
			<GoBack action={() => handleStopTiming()} />
			<Paper>
				<Box padding={3}>
					<Grid container spacing={2}>
						{mainSelect.optionLabel === 'servicio' && (
							<Grid item sm={12}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>Elige {labelN1 || 'nivel 1'}</Grid>
									<Grid item xs>
										<InputSelect
											value={
												functionalsLvl1.length > 0
													? subSelect.functionalN1
													: ''
											}
											optionsMenu={functionalsLvl1}
											onChange={(e) =>
												setSubSelect({
													functionalN1:
														e.target.value?.toString() || '',
													functionalN2: '',
													service: '',
												})
											}
											placeholder="Selecciona"
											id="select-lvl1"
											withStatus
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
						{withTwoLevels && (
							<Grid item sm={12}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>Elige {labelN2 || 'nivel 2'}</Grid>
									<Grid item xs>
										<InputSelect
											value={
												functionalsLvl2.length > 0
													? subSelect.functionalN2
													: ''
											}
											optionsMenu={functionalsLvl2}
											onChange={(e) =>
												setSubSelect({
													...subSelect,
													functionalN2:
														e.target.value?.toString() || '',
													service: '',
												})
											}
											placeholder="Selecciona"
											id="select-lvl2"
											withStatus
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
						{mainSelect.optionLabel === 'espacio' && (
							<Grid item sm={12}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>Elige el {labelService}</Grid>
									<Grid item xs>
										<InputSelect
											value={subSelect.service}
											optionsMenu={activitiesList}
											onChange={(e) =>
												setSubSelect({
													...subSelect,
													service:
														e.target.value?.toString() || '',
												})
											}
											placeholder="Selecciona"
											withStatus
											id="select-activity"
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item sm={12}>
							<h2 style={{ margin: 0 }}>
								{
									options.find(
										(element: any) =>
											element.id === mainSelect.valueLabel
									)?.label
								}
							</h2>
						</Grid>
						<Grid item xs={12}>
							<TableCustom
								columns={dinamicColumns}
								data={data}
								setData={setData}
								buttons={[
									{
										icon: FilterList,
										label: 'Filtrar',
										execute: () => setOpenFilters(!openFilters),
										type: 'blue',
										variant: 'outlined',
										width: 150,
									},
								]}
								modal={
									<FilterCustom
										open={openFilters}
										data={filterInputs}
										setOpen={setOpenFilters}
										handleFilter={handleFilter}
									/>
								}
								showPagination
								showLimitPerPage
							/>
						</Grid>
						<Grid item sm={12}>
							<Grid container justifyContent="flex-end" spacing={2}>
								<Grid item>
									<Button
										label={'Guardar y salir'}
										onClick={() => handleStopTiming(true)}
										variant="solid"
										style={{
											minWidth: '300px',
											padding: '20px',
										}}
									/>
								</Grid>
								<Grid item>
									<Button
										label={
											isEdit
												? 'Finalizar edición'
												: 'Terminar supervisión del día'
										}
										onClick={() => completeSupervision()}
										variant="solid"
										style={{
											minWidth: '300px',
											backgroundColor: '#0080F9',
											padding: '20px',
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
