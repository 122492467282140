import { createSlice } from '@reduxjs/toolkit';
import { defaultProjectBase } from '../../interfaces/project.interface';

export const ProjectSlice = createSlice({
	name: 'projectBase',
	initialState: defaultProjectBase,
	reducers: {
		setValueProject: (state, action) => ({
			...state,
			...action.payload,
		}),
		resetProject: () => defaultProjectBase,
	},
});

export const { setValueProject, resetProject } = ProjectSlice.actions;

export default ProjectSlice.reducer;
