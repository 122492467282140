import { Button, Paper, Typography, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from '../../styles/layout-new-projects.module.css';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TextInput from '../../../../components/text-input.component';
import CancelIcon from '@mui/icons-material/Cancel';
interface Layout {
	children?: JSX.Element | JSX.Element[];
	title: string;
	type?: string;
}

function LayoutNewProject({ children, title, type }: Layout) {
	return (
		<Paper elevation={0} className={styles.containerPaper}>
			<Grid
				component="section"
				mb={3}
				className={styles.titleContainer}
				display="flex"
				justifyContent="space-between"
				sx={{
					flexDirections: { xs: 'column', sm: 'row' },
				}}
			>
				<Typography
					variant="subtitle1"
					className={styles.bold}
					sx={{ fontWeight: 'bold', marginBottom: '15px' }}
				>
					{title}
				</Typography>
				{type === 'filters' && (
					<Grid
						container
						item
						xs={12}
						sm={10}
						md={8}
						lg={6}
						sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
					>
						<Grid item xs={5}>
							<TextInput
								id="text"
								name="text"
								placeholder="Buscar"
								value=""
								onChange={() => {}}
								type="search"
								contentStyles={{ marginBottom: 0 }}
								InputProps={{
									sx: {
										backgroundColor: '#F7F7F7',
										height: '45px',
										width: { xs: '100%' },
										fontSize: '15px',
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xs={3}
							display="flex"
							justifyContent="flex-end"
							alignItems="center"
							mt={1}
						>
							<Button
								sx={{
									width: '140px',
									height: '44px',
									color: '#0080F9',
									backgroundColor: 'white',
									marginBottom: '15px',
									textTransform: 'capitalize',
									gap: '8px',
									border: '1px solid #0080F9',
								}}
							>
								<FilterAltIcon /> Filtrar
							</Button>
						</Grid>
						<Grid
							item
							xs={4}
							display="flex"
							justifyContent="flex-end"
							alignItems="center"
							mt={1}
						>
							<Button
								sx={{
									width: '178px',
									height: '44px',
									color: 'white',
									backgroundColor: '#0080F9',
									fontSize: '14px',
									marginBottom: '15px',
									textTransform: 'capitalize',
									gap: '8px',
								}}
							>
								<AddIcon /> Crear Proyecto
							</Button>
						</Grid>
					</Grid>
				)}
				{type === 'cancelIcon' && (
					<div className={styles.cancelIcon}>
						<CancelIcon />
					</div>
				)}
			</Grid>
			{children}
		</Paper>
	);
}

export default LayoutNewProject;
