import { TSpatialUnit } from '../../../interfaces/get-supervision.interfaces';
import { IGetActivitiesResp } from '../interfaces/get-activities.interfaces';

export const activitiesAdapter = (data: IGetActivitiesResp[]): TSpatialUnit[] => {
	const newData: TSpatialUnit[] = data.map((element: IGetActivitiesResp) => {
		return {
			id: element._id,
			name: element.description,
			value: element.applies,
			functional: element.activity,
		} as unknown as TSpatialUnit;
	});
	return newData;
};
