/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../hooks/useApiRequest';
import { stopLoading, setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { NewActivity } from '../../interfaces/get-activities.interface';
import { getSingleAdaptedData } from '../../../../utils/get-adapted-data';
import { IAdaptedValues } from '../../../../interfaces/edit-adapted-values';
import { IValuesInfo } from '../../../../interfaces/edit-values.interface';
import { IModal } from '../../../../interfaces/modal.interface';

interface IUseFormActivitites {
	modal: IModal;
	activitiesOptions: IAdaptedValues[];
	setActivitiesOptions: React.Dispatch<React.SetStateAction<IAdaptedValues[]>>;
	setModal: React.Dispatch<React.SetStateAction<IModal>>;
}

export const useCreateActivity = ({
	modal,
	activitiesOptions,
	setActivitiesOptions,
	setModal,
}: IUseFormActivitites) => {
	const dispatch = useDispatch();

	const {
		execute: createActivity,
		status: statusCreateActivity,
		value: valueCreateActivity,
	} = useApiRequest<NewActivity, IResponsePayload<IValuesInfo>>({
		path: URLS.EDIT_PROJECT.ACTIVITIES.CREATE_ACTIVITY,
		method: 'POST',
	});

	useEffect(() => {
		if (statusCreateActivity === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: 'Actividad agregada con éxito',
					severity: 'success',
				})
			);
			setModal({ ...modal, open: false });
			const temporalActivities = activitiesOptions;
			temporalActivities.pop();
			setActivitiesOptions([
				...temporalActivities,
				getSingleAdaptedData(valueCreateActivity?.detail),
			]);
			dispatch(stopLoading());
		} else if (statusCreateActivity === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al agregar la actividad',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusCreateActivity]);

	const handleCreatestatusCreateActivity = (body: NewActivity) => {
		dispatch(setLoading({ loading: true }));
		createActivity(body);
	};

	return { handleCreatestatusCreateActivity };
};
