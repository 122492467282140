/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../hooks/useApiRequest';
import { IStepTwoModel } from '../interfaces/stepTwo.interface';
import { setLoading, stopLoading } from '../store/modules/loading';
import { setAlert } from '../store/modules/snackbar';
import { TReactDispatch } from '../interfaces/utils';

interface IDeleteElement {
	data: IStepTwoModel[];
	setData?: TReactDispatch<IStepTwoModel[]> | ((val: IStepTwoModel[]) => void);
}

const useDeleteElement = (props: IDeleteElement) => {
	const { data, setData } = props;
	const dispatch = useDispatch<any>();
	const { execute, status, value } = useApiRequest<
		unknown,
		IResponsePayload<{ _id: string }>
	>({
		path: '',
		method: 'DELETE',
	});

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (value?.detail) {
				const copy_data = [...data];
				const index = copy_data?.findIndex(
					(ele) => ele._id === value?.detail?._id
				);
				if (index > -1) {
					copy_data.splice(index, 1);
					if (setData) setData(copy_data);
				}
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'No se ha podido eliminar el elemento',
					severity: 'error',
				})
			);
		}
	}, [status]);

	const handleDeleteElement = (url: string, id: string) => {
		dispatch(setLoading({ loading: true }));
		execute(null, `${url}/${id}`);
	};

	return { handleDeleteElement, value, status };
};

export default useDeleteElement;
