/* eslint-disable react-hooks/exhaustive-deps */
import { AddOutlined } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import TableCustom from '../../components/table-custom.component';
import { useUsersPage } from './hooks/useUsersPage';
import { columns } from './utils/columns-users.utils';

export const UsersPage = () => {
	const { data, setData, navigate } = useUsersPage();

	return (
		<Paper elevation={0}>
			<Box padding={3}>
				<TableCustom
					titleHeader="Usuarios"
					columns={columns}
					data={data}
					setData={setData}
					buttons={[
						// {
						// 	icon: FilterList,
						// 	label: 'Filtrar',
						// 	execute: () => null,
						// 	type: 'blue',
						// 	variant: 'outlined',
						// 	width: 150,
						// },
						{
							icon: AddOutlined,
							label: 'Crear usuario',
							execute: () => navigate('crear-usuario'),
							type: 'blue',
							width: 150,
						},
					]}
					showPagination
					showLimitPerPage
				/>
			</Box>
		</Paper>
	);
};
