import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { SupervisionHistoryDetail } from '../supervision-detail.component';
import { IRoutine } from '../../../../interfaces/logic.interface';
import { ServiceSelect } from './service-select.component';
import { ServiceView } from './service-view';
import { RoutineDetailEvidence } from '../routine/routine-detail-evidence.component';
import { SupervisionViewActions } from '../supervision-view-actions';
import { SupervisionViewReports } from '../supervision-view-reports';
import { SupervisionViewActivities } from '../supervision-view-activities';
import { SupervisionHistoryContext } from '../../contexts/useSupervisionHistoryContext';
import { DefaultDataSelect } from '../../interfaces/supervision-history.interface';

interface IAreaMain {
	setOption: React.Dispatch<React.SetStateAction<string>>;
}

export const ServiceMain = ({ setOption }: IAreaMain) => {
	const [areaSelected, setAreaSelected] = useState<IRoutine>({
		_id: '',
		description: '',
	});
	const step = useSelector((state: RootState) => state.stateStep);
	const [routineSelected, setRoutineSelected] = useState<IRoutine>({
		_id: '',
		description: '',
	});

	const [dataSelect, setDataSelect] = useState<any>(DefaultDataSelect);

	return (
		<SupervisionHistoryContext.Provider value={{ dataSelect, setDataSelect }}>
			{step.state === 0 && (
				<ServiceSelect
					areaSelected={areaSelected}
					setAreaSelected={setAreaSelected}
					setOption={setOption}
				/>
			)}
			{step.state === 1 && (
				<ServiceView
					areaSelected={areaSelected}
					setRoutine={setRoutineSelected}
				/>
			)}
			{step.state === 3 && (
				<SupervisionHistoryDetail
					routine={routineSelected}
					canBeExecuted={routineSelected.canBeExecuted}
				/>
			)}
			{step.state === 4 && <RoutineDetailEvidence />}
			{step.state === 5 && <SupervisionViewActions />}
			{step.state === 6 && <SupervisionViewReports />}
			{step.state === 7 && <SupervisionViewActivities />}
		</SupervisionHistoryContext.Provider>
	);
};
