/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../../../constants/urls.constants';
import { setAlert } from '../../../../../store/modules/snackbar';
import { stopLoading } from '../../../../../store/modules/loading';
import { IGetUnitsResp } from '../interfaces/get-units.interfaces';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { ICollapsable } from '../../../../../interfaces/collapsable.interface';

interface IUseGetUnits {
	units: ICollapsable[];
	setUnits: React.Dispatch<React.SetStateAction<ICollapsable[]>>;
	isLevelOne: boolean;
	checkedElements: Set<string>;
	setCheckedElements: React.Dispatch<React.SetStateAction<Set<string>>>;
	setCurrentUnit?: React.Dispatch<React.SetStateAction<string>>;
}

export function useGetUnits({
	units,
	setUnits,
	isLevelOne,
	checkedElements,
	setCheckedElements,
	setCurrentUnit,
}: IUseGetUnits) {
	const dispatch = useDispatch();

	const {
		execute: getUnits,
		status: statusUnits,
		value: valueUnits,
	} = useApiRequest<unknown, IResponsePayload<IGetUnitsResp>>({
		path: URLS.SUPERVISION_EXTRAORDINARY.GET_UNITS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusUnits === 'success') {
			dispatch(stopLoading());
			adaptInfoCollapsable();
		} else if (statusUnits === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar la información.',
					severity: 'error',
				})
			);
		}
	}, [statusUnits]);

	const adaptInfoCollapsable = () => {
		const temporalCollection = new Set(checkedElements);
		if (isLevelOne) {
			setUnits(
				valueUnits?.data?.map((element) => {
					if (element.applies) temporalCollection.add(element.functional);
					return {
						_id: element._id,
						id: element.functional,
						name: element.functionalDescription,
						value: element.functionalDescription,
						items: [],
						isComplete: element.applies,
					};
				}) || []
			);
			if (setCurrentUnit) setCurrentUnit(valueUnits?.data[0]?.functional || '');
		} else {
			setUnits([
				{
					...units[0],
					items:
						valueUnits?.data?.map((element) => {
							if (element.applies)
								temporalCollection.add(element.functional);
							return {
								_id: element._id,
								id: element.functional,
								name: '',
								value: element.functionalDescription,
								items: [],
								isComplete: element.applies,
							};
						}) || [],
				},
			]);
		}
		setCheckedElements(temporalCollection);
	};

	return { getUnits };
}
