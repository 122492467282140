/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { arrayMove, insertAtIndex, removeAtIndex } from './utils/array';
import Droppable from './components/droppable.component';
import { Task } from './components/task.component';
import styles from './board-styles.module.css';
import { getDay } from './utils/days';
import { addDays, addWeeks, startOfWeek, endOfWeek, subWeeks } from 'date-fns';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { IDates } from './../board/components/interfaces/task.interface';
import { useRescheduleRoutine } from './hooks/useRescheduleRoutine';
import { useDispatch } from 'react-redux';
import { setLoading } from './../../store/modules/loading';
import { defaultFilters } from '../../pages/profile-project/components/planning/interfaces/actions.interface';

interface IBoard {
	tasks: any;
	setFilters: any;
	setSendDate: any;
	onUpdateBoard: any;
}

export const Board = ({ tasks, setFilters, setSendDate, onUpdateBoard }: IBoard) => {
	const dispatch = useDispatch();
	const [itemGroups, setItemGroups] = useState<any>(tasks);
	const [currentTask, setcurrentTask] = useState<any>();
	const [activeId, setActiveId] = useState(null);
	const [activeContainer, setActiveContainer] = useState(null);
	const currentDay = new Date().setHours(0, 0, 0, 0);
	const [startWeek, setStartWeek] = useState(
		startOfWeek(new Date(), { weekStartsOn: 1 })
	);
	const [dates, setDates] = useState<IDates[]>();
	const { executeReschedule, statusReschedule } = useRescheduleRoutine();

	useEffect(() => {
		setItemGroups([]);
		setItemGroups(tasks);
		setStartWeek(startOfWeek(setSendDate, { weekStartsOn: 1 }));
		const dataDates: IDates[] = [
			{ day: 'monday', date: addDays(setSendDate, 0) },
			{ day: 'tuesday', date: addDays(setSendDate, 1) },
			{ day: 'wednesday', date: addDays(setSendDate, 2) },
			{ day: 'thursday', date: addDays(setSendDate, 3) },
			{ day: 'friday', date: addDays(setSendDate, 4) },
			{ day: 'saturday', date: addDays(setSendDate, 5) },
			{ day: 'sunday', date: addDays(setSendDate, 6) },
		];

		setDates(dataDates || []);
	}, [setSendDate, tasks]);

	useEffect(() => {
		if (statusReschedule === 'error' || statusReschedule === 'success')
			onUpdateBoard(startWeek, endOfWeek(startWeek, { weekStartsOn: 1 }));
	}, [statusReschedule]);

	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragStart = ({ active }: { active: any }) => {
		setActiveId(active.id);
		const taskSelected = itemGroups[active.data.current.sortable.containerId].find(
			(element: any) => {
				if (element.id === active.id) return element;
				return null;
			}
		);
		setcurrentTask(taskSelected);
		setActiveContainer(active.data.current.sortable.containerId);
	};

	const handleDragCancel = () => setActiveId(null);

	const handleDragOver = ({ active, over }: { active: any; over: any }) => {
		const overId = over?.id;

		if (!overId) {
			return;
		}

		const activeContainer = active.data.current.sortable.containerId;
		const overContainer = over.data.current?.sortable.containerId || over.id;

		if (activeContainer !== overContainer) {
			setItemGroups((itemGroups: any) => {
				const activeIndex = active.data.current.sortable.index;
				const overIndex =
					over.id in itemGroups
						? itemGroups[overContainer].length + 1
						: over.data.current.sortable.index;

				return moveBetweenContainers(
					itemGroups,
					activeContainer,
					activeIndex,
					overContainer,
					overIndex,
					currentTask
				);
			});
		}
	};

	const handleDragEnd = ({ active, over }: { active: any; over: any }) => {
		if (!over) {
			setActiveId(null);
			return;
		}

		if (
			activeContainer !==
			(over?.data?.current?.sortable?.containerId ||
				active?.data?.current?.sortable?.containerId ||
				activeContainer)
		) {
			const activeContainer = active.data.current.sortable.containerId;
			const overContainer = over.data.current?.sortable.containerId || over.id;
			const activeIndex = active.data.current.sortable.index;
			const overIndex =
				over.id in itemGroups
					? itemGroups[overContainer].length + 1
					: over.data.current.sortable.index;

			setItemGroups((itemGroups: any) => {
				let newItems;
				if (activeContainer === overContainer) {
					newItems = {
						...itemGroups,
						[overContainer]: arrayMove(
							itemGroups[overContainer],
							activeIndex,
							overIndex
						),
					};
				} else {
					newItems = moveBetweenContainers(
						itemGroups,
						activeContainer,
						activeIndex,
						overContainer,
						overIndex,
						currentTask
					);
				}
				return newItems;
			});

			const dateEnd = dates?.find(isDay);
			dispatch(setLoading({ loading: true }));
			executeReschedule(
				{
					newExecutionDate: dateEnd?.date.toISOString(),
					activitiesId: currentTask.activitiesId,
				},
				`/${active.id}`
			);
		}

		setActiveId(null);

		function isDay(item: any) {
			return item.day === active.data.current.sortable.containerId;
		}
	};

	const moveBetweenContainers = (
		items: any,
		activeContainer: any,
		activeIndex: any,
		overContainer: any,
		overIndex: any,
		item: any
	) => {
		return {
			...items,
			[activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
			[overContainer]: insertAtIndex(items[overContainer], overIndex, item),
		};
	};

	const newWeek = (operation: boolean) => {
		setFilters({ ...defaultFilters });
		setItemGroups([]);
		if (operation) {
			setStartWeek(addWeeks(startWeek, 1));
			onUpdateBoard(
				addWeeks(startWeek, 1),
				endOfWeek(addWeeks(startWeek, 1), { weekStartsOn: 1 })
			);
		} else {
			setStartWeek(subWeeks(startWeek, 1));
			onUpdateBoard(
				subWeeks(startWeek, 1),
				startOfWeek(subWeeks(startWeek, 0), { weekStartsOn: 1 })
			);
		}
	};

	return (
		<div className={styles.boardMainContainer}>
			<DndContext
				sensors={sensors}
				onDragStart={handleDragStart}
				onDragCancel={handleDragCancel}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
			>
				{Object.keys(itemGroups).map((group, index) => (
					<div key={group} className={styles.boardColumn}>
						<div className={styles.calendarContainer}>
							<div className={styles.dayLabel} id={`day-${index}`}>
								{getDay(group.toUpperCase())}
								{getDay(group.toUpperCase()) === 'LUN' && (
									<span
										onClick={() => newWeek(false)}
										className={styles.leftArrow}
									>
										<ArrowBackIosRounded />
									</span>
								)}
								{getDay(group.toUpperCase()) === 'DOM' && (
									<span
										onClick={() => newWeek(true)}
										className={styles.rightArrow}
									>
										<ArrowForwardIosRounded />
									</span>
								)}
							</div>
							<div
								className={`${styles.numberLabel} ${
									new Date(currentDay).getTime() ===
									addDays(startWeek, index).getTime()
										? styles.currentDay
										: ''
								}`}
							>
								{addDays(startWeek, index).getDate()}
							</div>
						</div>
						<Droppable id={group} items={itemGroups[group]} key={group} />
					</div>
				))}
				<DragOverlay>
					{activeId ? (
						<Task id={activeId} task={currentTask} dragOverlay />
					) : null}
				</DragOverlay>
			</DndContext>
		</div>
	);
};
