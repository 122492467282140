import { IColumns } from '../../../../../interfaces/table.insterface';

export const columnsInitialEvidences: IColumns[] = [
	{
		text: '',
		nameID: 'img',
		align: 'center',
	},
	{
		text: 'Descripción',
		nameID: 'description',
		width: '700px',
	},
];
