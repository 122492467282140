/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { columnsNoRecurringTasks } from '../utils/columns-tasks.utils';
import TableCustom from '../../../components/table-custom.component';
import { useNoRecurringTasks } from '../hooks/useNoRecurringTasks';
import { setLoading } from '../../../store/modules/loading';
import { RootState } from '../../../store';

export const NoRecurringTasks = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location?.state?.params;
	const { dataTable, setDataTable, executeGetTask, handleGetFiltersParams } =
		useNoRecurringTasks();
	const { _id } = useSelector((state: RootState) => state.projectBase);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetTask(
			{},
			`?page=${dataTable.pagination}&recordsByPage=${
				dataTable.rowsPerPage
			}&routineType=NON_RECURRENT&project=${
				state?.project?._id || _id
			}${handleGetFiltersParams()}`
		);
	}, [
		dataTable.pagination,
		dataTable.rowsPerPage,
		state?.project?._id,
		dataTable.filters,
	]);

	return (
		<TableCustom
			titleHeader="Tareas no recurrentes"
			columns={columnsNoRecurringTasks}
			data={dataTable}
			setData={setDataTable}
			showPagination
			showLimitPerPage
		/>
	);
};
