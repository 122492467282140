import React from 'react';

interface IProps {
	size?: string;
}
function Icon(props: IProps) {
	const { size = '16.58px' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16.58 18.422"
		>
			<path
				fill="#fff"
				d="M7.382 22.423A1.417 1.417 0 016 21.041V6.763a1.417 1.417 0 011.382-1.381h1.5V4h1.5v1.382H18.2V4h1.5v1.382h1.5a1.417 1.417 0 011.38 1.381v14.278a1.417 1.417 0 01-1.38 1.382zm0-1.382H21.2v-9.9H7.382zm0-11.284H21.2V6.763H7.382zm0 0zm6.908 5.3a.9.9 0 11.656-.265.891.891 0 01-.656.262zm-3.684 0a.9.9 0 11.656-.265.891.891 0 01-.656.262zm7.369 0a.9.9 0 11.656-.265.891.891 0 01-.656.262zm-3.685 3.681a.9.9 0 11.656-.265.891.891 0 01-.656.265zm-3.684 0a.9.9 0 11.656-.265.891.891 0 01-.656.265zm7.369 0a.9.9 0 11.656-.265.891.891 0 01-.656.265z"
				transform="translate(-6 -4)"
			></path>
		</svg>
	);
}

export default Icon;
