/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { columnsNoRecurringTasksBase } from '../utils/columns-tasks.utils';
import TableCustom from '../../../components/table-custom.component';
import { useNoRecurringTasksBase } from '../hooks/useNoRecurringTasksBase';
import { setLoading } from '../../../store/modules/loading';
import { RootState } from '../../../store';

export const NoRecurringTasksBase = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location?.state?.params;
	const { dataTable, setDataTable, executeGetTaskBase, handleGetFiltersParams } =
		useNoRecurringTasksBase();
	const { _id } = useSelector((state: RootState) => state.projectBase);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetTaskBase(
			{},
			`?page=${dataTable.pagination}&recordsByPage=${
				dataTable.rowsPerPage
			}&project=${state?.project?._id || _id}${handleGetFiltersParams()}`
		);
	}, [
		dataTable.pagination,
		dataTable.rowsPerPage,
		state?.project?._id,
		dataTable.filters,
	]);

	return (
		<TableCustom
			titleHeader="Tareas no recurrentes base"
			columns={columnsNoRecurringTasksBase}
			data={dataTable}
			setData={setDataTable}
			showPagination
			showLimitPerPage
		/>
	);
};
