import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';

const useCriterios = () => {
	const {
		execute: getIGs,
		status: getStatusIGs,
		value: getValueIGs,
		setValue: setValueIGs,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	const {
		execute: getIEs,
		status: getStatusIEs,
		value: getValueIEs,
		setValue: setValueIEs,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.INDICATOR,
		method: 'GET',
	});

	const {
		execute: updateIndicators,
		status: updatedStatusIndicator,
		value: updatedValueIndicator,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.MANY_SUPERVISION_UNIT,
		method: 'POST',
	});

	const {
		execute: updatedIndicatorAssociated,
		status: statusUpdatedIndicatorAssociated,
		value: valueUpdatedIndicatorAssociated,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'PATCH',
	});
	const {
		execute: updatedIndicatorAssociatedLvl2,
		status: statusUpdatedIndicatorAssociatedLvl2,
		value: valueUpdatedIndicatorAssociatedLvl2,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.FUNCTIONAL,
		method: 'PATCH',
	});
	const {
		execute: updatedIndicatorAssociatedLvl1,
		status: statusUpdatedIndicatorAssociatedLvl1,
		value: valueUpdatedIndicatorAssociatedLvl1,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.FUNCTIONAL,
		method: 'PATCH',
	});

	const {
		execute: getIsCreated,
		status: statusIsCreated,
		value: valueIsCreated,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	const {
		execute: getActivity,
		status: statusActivity,
		value: valueActivities,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.PROJECT.SUPERVISION_UNIT,
		method: 'GET',
	});

	return {
		//IG
		getIGs,
		getStatusIGs,
		getValueIGs,
		setValueIGs,
		//IE
		getIEs,
		getStatusIEs,
		getValueIEs,
		setValueIEs,
		//ACT
		getActivity,
		statusActivity,
		valueActivities,
		//UPDATE
		updatedIndicatorAssociated,
		statusUpdatedIndicatorAssociated,
		valueUpdatedIndicatorAssociated,
		updateIndicators,
		updatedStatusIndicator,
		updatedValueIndicator,
		//IS_CREATED
		getIsCreated,
		statusIsCreated,
		valueIsCreated,
		updatedIndicatorAssociatedLvl2,
		statusUpdatedIndicatorAssociatedLvl2,
		valueUpdatedIndicatorAssociatedLvl2,
		updatedIndicatorAssociatedLvl1,
		statusUpdatedIndicatorAssociatedLvl1,
		valueUpdatedIndicatorAssociatedLvl1,
	};
};

export default useCriterios;
