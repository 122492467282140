import { SyntheticEvent } from 'react';
import MUITabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface IItem {
	label: string;
	value: number;
	isDisabled?: boolean;
}

export interface ITabProps {
	value?: number;
	sx?: SxProps;
	ariaLabel?: string;
	items: IItem[];
	onChange?: (e: SyntheticEvent, value: number) => void;
	currentValue?: number;
}

const StyledTabs = styled(MUITabs)({
	'& .MuiTabs-indicator': {
		display: 'none',
		justifyContent: 'center',
	},
	'& .MuiTabs-indicatorSpan': {
		maxWidth: 40,
		width: '100%',
	},
	position: 'absolute',
	left: 0,
	zIndex: 0,
	bottom: '-65px',
});

const StyledTab = styled(Tab)(({ theme }) => ({
	textTransform: 'none',
	fontWeight: theme.typography.fontWeightRegular,
	fontSize: theme.typography.pxToRem(15),
	marginRight: '12px',
	background: '#fff',
	borderRadius: '5px 5px 0 0',
	color: '#A1A5B6',
	boxShadow: 'inset 0 -5px 10px -10px rgb(0 0 0 / 20%)',
	'&.Mui-selected': {
		color: '#0080F9',
		//background: '#fff',
		boxShadow: 'unset',
	},
	'&.Mui-focusVisible': {
		backgroundColor: 'rgba(100, 95, 228, 0.32)',
	},
}));

export const Tabs = (props: ITabProps) => {
	const { value, sx, items, onChange } = props;

	return (
		<StyledTabs
			value={value}
			onChange={onChange}
			textColor="secondary"
			sx={{ ...sx }}
		>
			{items.map((el: any, idx: any) => (
				<StyledTab
					disableRipple
					//disabled={idx > !!value}
					disableTouchRipple
					key={`tab-${idx}`}
					value={el.value}
					label={el.label}
				/>
			))}
		</StyledTabs>
	);
};
