import styles from './styles/card-styles.component.module.css';
import backgroundCard from '../assets/imgs/no-image-found.png';
import { useNavigate } from 'react-router-dom';

interface ICard {
	title: string;
	image: string;
	keyValue: string;
	description: string;
	idProject: string;
	onClickSeeMore?: () => void;
}

const Card = (props: ICard) => {
	const { title, keyValue, description, image, idProject } = props;
	const navigate = useNavigate();

	return (
		<div className={styles.container}>
			<div className={styles.containerImage}>
				<img
					src={image ? image : backgroundCard}
					alt={keyValue}
					className={styles.imgContainer}
				/>
			</div>
			<div className={styles.containerInfo}>
				<p className={styles.title}>{title}</p>
				<p className={styles.textInfo}>{keyValue}</p>
				<p className={`${styles.textInfo} ${styles.infoContainer}`}>
					{description}
				</p>
				<span
					className={styles.buttonSeeMore}
					onClick={() => navigate('/perfil-proyecto', { state: idProject })}
				>
					VER
				</span>
			</div>
		</div>
	);
};

export default Card;
