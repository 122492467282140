import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Task } from './task.component';

interface ISorteableTask {
	id: string;
	task: any;
}

export const SortableTask = ({ id, task }: ISorteableTask) => {
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
		useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0.5 : 1,
	};

	return (
		<li style={style} ref={setNodeRef} {...attributes} {...listeners}>
			<Task id={id} task={task} />
		</li>
	);
};
