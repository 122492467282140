import { IColumns } from '../../../interfaces/table.insterface';

export const columns: IColumns[] = [
	{
		text: 'No. de empleado',
		nameID: 'employeeNumber',
	},
	{
		text: 'Nombre del Empleado',
		nameID: 'firstName',
	},
	{
		text: 'Correo electrónico',
		nameID: 'email',
	},
	{
		text: 'Puesto',
		nameID: 'position',
	},
	{
		text: 'Rol',
		nameID: 'roleName',
	},
	{
		text: 'Estatus',
		nameID: 'statusName',
	},
	{
		text: 'Última conexión',
		nameID: 'lastConnection',
	},
	{
		text: 'Acciones',
		nameID: 'actions',
	},
];
