/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/modules/user';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../store/modules/loading';

export const useGetInfoUser = () => {
	const dispatch = useDispatch();

	const getInfoUser = async (token: string) => {
		dispatch(setLoading({ loading: true }));
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/auth/login-ad`,
				config
			);
			dispatch(setUser({ ...response.data.user, token: token, isLogged: true }));
			dispatch(stopLoading());
		} catch (error) {
			dispatch(
				setAlert({
					show: true,
					message: 'Error al iniciar sesión',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	};

	return { getInfoUser };
};
