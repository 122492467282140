import { IMenuList } from './resources.utils';
export const getRoutes = (
	menu: IMenuList[],
	path?: string,
	routes: Array<{
		component: () => JSX.Element;
		link: string;
	}> = []
): Array<{
	component: () => JSX.Element;
	link: string;
}> => {
	menu.forEach((r) => {
		if (r.Component) {
			routes.push({
				component: r.Component,
				link: (path || '') + r.link + (r.parameter || ''),
			});
			if (r.parameter) {
				routes.push({
					component: r.Component,
					link: (path || '') + r.link,
				});
			}
		}
		if (r.submenu) {
			return getRoutes(r.submenu, (path || '') + r.link, routes);
		}
	});
	return routes;
};
