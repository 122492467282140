import { IGetSupervisionUnit } from '../../interfaces/get-supervision-unit.interface';
import { INewRelationUI } from '../interfaces/get-activities-UA.interface';

export const getNewDataUIRelations = (
	indicatorsData: { _id: string; value: string }[],
	UIRelations: IGetSupervisionUnit[],
	checkedList: Set<string>,
	idProject: string,
	currentActivity: string,
	currentFunctional: string
) => {
	const newUIRelations = indicatorsData.map((indicator) => {
		const temporalUIRelation: INewRelationUI = {
			name: `${currentActivity}-${indicator._id}`,
			project: idProject,
			activity: currentActivity,
			functional: currentFunctional,
			indicator: indicator._id,
			applies: checkedList.has(indicator._id),
			type: 'U-I',
		};
		const relationUICreated = UIRelations.find(
			(element) => element.indicator === indicator._id
		);
		if (relationUICreated) {
			temporalUIRelation._id = relationUICreated._id;
		}
		return temporalUIRelation;
	});

	return newUIRelations;
};
