import React from 'react';

interface IProps {
	size?: string;
}
function Icon(props: IProps) {
	const { size = '17.693' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 17.693 17.693"
		>
			<path
				fill="#fff"
				d="M4 8.246V5.327A1.361 1.361 0 015.327 4h2.919v1.327H5.327v2.919zm4.246 13.447H5.327A1.361 1.361 0 014 20.366v-2.92h1.327v2.919h2.919zm9.2 0v-1.327h2.919v-2.92h1.327v2.919a1.361 1.361 0 01-1.327 1.327zm2.92-13.447V5.327h-2.92V4h2.919a1.361 1.361 0 011.327 1.327v2.919zm-6.192 2.079a1.611 1.611 0 01-1.139-2.753 1.611 1.611 0 112.277 2.278 1.554 1.554 0 01-1.139.475zm-.885 7.94h-3.163l1.017-5.2-2.211 1.042v2.963H7.6V13.2l3.627-1.526a7.941 7.941 0 011.006-.387 2.414 2.414 0 01.608-.077 1.606 1.606 0 01.785.188 1.641 1.641 0 01.586.564l.929 1.482a5.441 5.441 0 001.57 1.614 3.992 3.992 0 002.322.686v1.326a5.1 5.1 0 01-2.643-.686 7.323 7.323 0 01-2.156-2.057z"
				transform="translate(-4 -4)"
			></path>
		</svg>
	);
}

export default Icon;
