import { IGetSupervisionUnit } from '../interfaces/get-supervision-unit.interface';

export const getCheckedListRelations = (
	data: IGetSupervisionUnit[],
	isIndicator?: boolean
): Set<string> => {
	const checkedList = new Set<string>();

	data?.forEach((element) => {
		if (element.applies === true)
			checkedList.add(isIndicator ? element.indicator : element.functional);
	});

	return checkedList;
};
