import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../../components/header-edit.component';
import { GoBack } from '../../../components/go-back-component';
import { Button } from '../../../components/main-button.component';
import TextInput from '../../../components/text-input.component';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import { useEditIndicatorView } from './hooks/useEditIndicatorView';
import CriteriosIcon from '../../../assets/icons/criterios.icon';
import {
	LABELS_GENERAL_INDICATOR_2,
	LABELS_SPECIFIC_INDICATOR_2,
} from '../utils/labels-header';

interface IFormActivitites {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	setCurrentIndicator: React.Dispatch<React.SetStateAction<IAdaptedValues>>;
	isGeneralIndicator: boolean;
	currentIndicator: IAdaptedValues;
}

export const EditIndicator = ({
	setStep,
	setCurrentIndicator,
	isGeneralIndicator,
	currentIndicator,
}: IFormActivitites) => {
	const { inputs, matches, onChangeValue, updateIndicator } = useEditIndicatorView({
		setCurrentIndicator,
		currentIndicator,
	});

	return (
		<>
			<GoBack action={() => setStep(1)} />
			<Paper>
				<Box padding={3}>
					<Grid
						container
						spacing={3}
						justifyContent={matches ? 'center' : 'flex-end'}
					>
						<HeaderEdit
							section="Criterios"
							title={
								isGeneralIndicator
									? LABELS_GENERAL_INDICATOR_2.title
									: LABELS_SPECIFIC_INDICATOR_2.title
							}
							alertText={
								isGeneralIndicator
									? LABELS_GENERAL_INDICATOR_2.alertText
									: LABELS_SPECIFIC_INDICATOR_2.alertText
							}
							icon={<CriteriosIcon size="23px" />}
						/>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								{inputs.map((input: any) => {
									return (
										<Grid
											item
											xs={12}
											md={6}
											key={`input-text-${input.id}`}
										>
											<TextInput
												key={`input-text-${input.id}`}
												id={input.id
													.toLowerCase()
													.replace(/ /g, '-')}
												name={input.id}
												label={input.label}
												placeholder={input.placeholder}
												value={input.value}
												type="text"
												onChange={(e) =>
													onChangeValue(
														e.target.name,
														e.target.value
													)
												}
												contentStyles={{
													width: {
														xs: '100%',
														sm: '350px',
														md: '100%',
													},
												}}
												sx={{
													width: '100%',
												}}
												InputProps={{
													sx: {
														backgroundColor: '#F7F7F7',
														width: { xs: '100%' },
														fontSize: '15px',
													},
												}}
											/>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
						<Grid item>
							<Button
								label="Guardar"
								onClick={() => updateIndicator()}
								variant="outlined"
								style={{
									backgroundColor: ' #0080F9',
									width: '200px',
									color: 'white',
								}}
								disabled={inputs.some((input: any) => input.value === '')}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
