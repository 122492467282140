import { Grid } from '@mui/material';
import { ListAltRounded, Restore } from '@mui/icons-material';
import { InfoCards } from '../../info-cards.component';
import { useDispatch } from 'react-redux';
import { setValuePP } from '../../../../../store/modules/profile-project';

export const ReportsMainComponent = () => {
	const dispatch = useDispatch();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={6}>
				<span
					style={{ cursor: 'pointer' }}
					onClick={() => {
						dispatch(setValuePP({ stepReporteo: 1 }));
					}}
				>
					<InfoCards icon={Restore} label="Generar reporte" actions />
				</span>
			</Grid>
			<Grid item xs={12} md={6}>
				<span
					style={{ cursor: 'pointer' }}
					onClick={() => dispatch(setValuePP({ stepReporteo: 3 }))}
				>
					<InfoCards icon={ListAltRounded} label="Ver archivos" actions />
				</span>
			</Grid>
		</Grid>
	);
};
