import { IOptionsMenu } from '../interfaces/select.interface';

export const monthOptions: IOptionsMenu[] = [
	{
		id: 0,
		label: 'Enero',
		value: 1,
	},
	{
		id: 1,
		label: 'Febrero',
		value: 2,
	},
	{
		id: 2,
		label: 'Marzo',
		value: 3,
	},
	{
		id: 3,
		label: 'Abril',
		value: 4,
	},
	{
		id: 4,
		label: 'Mayo',
		value: 5,
	},
	{
		id: 5,
		label: 'Junio',
		value: 6,
	},
	{
		id: 6,
		label: 'Julio',
		value: 7,
	},
	{
		id: 7,
		label: 'Agosto',
		value: 8,
	},
	{
		id: 8,
		label: 'Septiembre',
		value: 9,
	},
	{
		id: 9,
		label: 'Octubre',
		value: 10,
	},
	{
		id: 10,
		label: 'Noviembre',
		value: 11,
	},
	{
		id: 11,
		label: 'Diciembre',
		value: 12,
	},
];

export const dayOptions: Array<{ label: string; value: number }> = [
	{
		label: 'Lunes',
		value: 1,
	},
	{
		label: 'Martes',
		value: 2,
	},
	{
		label: 'Miércoles',
		value: 3,
	},
	{
		label: 'Jueves',
		value: 4,
	},
	{
		label: 'Viernes',
		value: 5,
	},
	{
		label: 'Sábado',
		value: 6,
	},
	{
		label: 'Domingo',
		value: 7,
	},
];
