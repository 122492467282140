/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { URLS } from '../../../constants/urls.constants';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { stopLoading } from '../../../store/modules/loading';
import { uniqueId } from '../../../utils/uniqueId';
import { setAlert } from '../../../store/modules/snackbar';
import { TReactDispatch } from '../../../interfaces/utils';

interface IGet {
	setUF_2: TReactDispatch<IStepTwoModel[]>;
}

const useGetUF = (props: IGet) => {
	const { setUF_2 } = props;
	const [isUFshow, setIsUFshow] = useState(false);
	const { execute, status, value } = useApiRequest<
		any,
		IResponsePayload<IStepTwoModel>
	>({
		path: URLS.PROJECT.FUNCTIONAL,
		method: 'GET',
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (status === 'success') {
			dispatch(stopLoading());
			if (value?.data && value?.data.length > 0) {
				const dataFlag = (value?.data || []).map((ele) => ({
					...ele,
					id: uniqueId(),
					isComplete: true,
				}));
				setUF_2(dataFlag);
				setIsUFshow(true);
			}
		} else if (status === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al crear el proyecto',
					severity: 'error',
				})
			);
		}
	}, [status]);

	return { execute, isUFshow, setIsUFshow };
};

export default useGetUF;
