/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../../config/theme';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useGetFrequencies } from '../../../hooks/useGetFrequencies';
import { useGetFunctionals } from '../../../hooks/useGetFunctionals';
import { isEmpty } from '../../../../../utils/validators';
import { useGetActivitiesByFunctionals } from '../../../hooks/useGetActivitiesByFunctional';
import { setLoading } from '../../../../../store/modules/loading';
import { useUpdateActivitiesByFunctional } from './useUpdateActivititesByFunctional';

export const useEditScheduleByLevel = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const { labelN1 } = useSelector((state: RootState) => state.projectBase);
	const [functionalLvl1, setFunctionalLvl1] = useState('');

	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const { frequenciesOptions } = useGetFrequencies();
	const { functionalsOptions, handleGetFunctionals } = useGetFunctionals();

	const { activitiesOptions, getActivitiesByFunctionals, setActivitiesOptions } =
		useGetActivitiesByFunctionals();

	const { handleUpdateActivitiesByFunctional } = useUpdateActivitiesByFunctional({
		activitiesOptions,
		setActivitiesOptions,
	});

	useEffect(() => {
		if (!idProject) navigate(-1);
		handleGetFunctionals();
	}, []);

	useEffect(() => {
		if (!isEmpty(functionalLvl1)) {
			dispatch(setLoading({ loading: true }));
			getActivitiesByFunctionals(
				null,
				`/${idProject}?functional=${functionalLvl1}`
			);
		}
	}, [functionalLvl1]);

	const handleFrequency = (column: number, value: string, item: any) => {
		handleUpdateActivitiesByFunctional(item.id, { frequency: value });
	};

	return {
		frequenciesOptions,
		functionalsOptions,
		activitiesOptions,
		functionalLvl1,
		labelN1,
		matches,
		navigate,
		setFunctionalLvl1,
		handleFrequency,
	};
};
