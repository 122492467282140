/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { URLS } from '../../../../../../constants/urls.constants';
import { useApiRequest, IResponsePayload } from '../../../../../../hooks/useApiRequest';
import { setLoading, stopLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import {
	INonWorkingDays,
	SpecificDays,
} from '../interfaces/get-non-working-days.interface';
import { specificDaysAdapter } from '../adapters/specific-days.adapter';
import { useUpdateNonWorkingDays } from './useUpdateNonWorkingDays';

export const useGetNonWorkingDays = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const idProject = location.state.params.idProject as string;
	const [daysSelected, setDaysSelected] = useState<Set<number>>(new Set());
	const [specificDays, setSpecificDays] = useState<SpecificDays[]>([]);

	const { updateNonWorkingDays } = useUpdateNonWorkingDays();

	const {
		execute: getNonWorkingDays,
		status: statusGetNonWorkingDays,
		value: valueGetNonWorkingDays,
	} = useApiRequest<unknown, IResponsePayload<INonWorkingDays>>({
		path: URLS.EDIT_PROJECT.CHRONOGRAM.GET_NON_WORKING_DAYS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetNonWorkingDays === 'success') {
			dispatch(stopLoading());
			setDaysSelected(
				new Set(valueGetNonWorkingDays?.detail?.days) || daysSelected
			);
			setSpecificDays(specificDaysAdapter(valueGetNonWorkingDays?.detail));
		} else if (statusGetNonWorkingDays === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al consultar las frecuencias',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetNonWorkingDays]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getNonWorkingDays(null, `/${idProject}`);
	}, []);

	const handleDay = (value: number) => {
		const newSet = new Set(daysSelected);
		if (newSet.has(value)) newSet.delete(value);
		else newSet.add(value);
		setDaysSelected(newSet);
	};

	const handleNewDay = () => {
		setSpecificDays([
			...specificDays,
			{
				_id: specificDays[0]._id,
				label: 'Dia no laborable',
				isComplete: true,
				rows: [
					{
						key: 'day',
						value: 1,
					},
					{
						key: 'month',
						value: 1,
					},
				],
			} as unknown as SpecificDays,
		]);
	};

	const handleTable = (column: number, row: number, txt: any) => {
		const newData = specificDays.map((dateValue, index) => {
			if (index === column)
				return {
					...dateValue,
					rows: dateValue.rows.map((rowData, indexColumn) => {
						if (indexColumn === row) return { ...rowData, value: txt };
						return rowData;
					}),
				};
			return dateValue;
		});
		setSpecificDays(newData);
	};

	const handleDelete = (row: number | string) => {
		const newDays = specificDays;
		newDays.splice(parseInt(row.toString()), 1);
		setSpecificDays([...newDays]);
	};

	const handleUpdateNonWorkingDays = () => {
		dispatch(setLoading({ loading: true }));
		updateNonWorkingDays(
			{
				_id: specificDays[0]._id,
				days: Array.from(daysSelected),
				specificDays: specificDays.map((dateValue) => {
					return {
						day: dateValue.rows[0].value.toString(),
						month: dateValue.rows[1].value.toString(),
					};
				}),
				project: idProject,
			},
			`/${specificDays[0]._id}`
		);
	};

	return {
		daysSelected,
		specificDays,
		handleNewDay,
		handleDay,
		handleTable,
		handleDelete,
		handleUpdateNonWorkingDays,
	};
};
