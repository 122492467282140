import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
	name: 'notifications',
	initialState: { number: '' },
	reducers: {
		setNumberNotification: (state, action) => ({ ...state, ...action.payload }),
		resetNumberNotification: () => ({ number: '' }),
	},
});

export const { setNumberNotification, resetNumberNotification } =
	notificationSlice.actions;

export default notificationSlice.reducer;
