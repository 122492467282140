import React from 'react';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { isEmpty } from '../utils/validators';
import Layout from '../components/layout.component';

interface IProtectedRoute {
	component: React.ComponentType;
	ignoreToken?: boolean;
}

const ProtectedRoute = ({
	component: RouteComponent,
	ignoreToken = false,
}: IProtectedRoute) => {
	const user = useSelector((state: RootState) => state.users);

	if (!isEmpty(user.token) || ignoreToken) {
		return (
			<Layout>
				<RouteComponent />
			</Layout>
		);
	}

	return <Navigate to="/" />;
};

export default ProtectedRoute;
