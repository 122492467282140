/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from 'react';
import VerticalStepper from '../../../../components/vertical-stepper-fixed.component';
import renderItem from '../../styles/render-item-styles.module.css';
import Button from '@mui/material/Button';
import Collapsable from '../../../../components/collapsable.component';
import {
	ICollapsable,
	IItem as ICollapsableItem,
} from '../../../../interfaces/collapsable.interface';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import InputSelect from '../../../../components/select-input.component';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import ContinueButton from '../../../../components/custom-btn.component';
import SaveButton from '../../../../components/save-button.component';
import { isEmpty } from '../../../../utils/validators';
import useGetUF_UE from '../../hooks/useGetUF_EF';
import { IFunctionalResponse } from '../../../../interfaces/project.interface';
import useUpdateUnits_Acts from '../../hooks/useUpdateUnits_Acts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { updateFunctionalActivities } from '../../../../adapters/step-two.adapter';
import useGetUnits_Acts from '../../hooks/useGetUnits_Acts';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading } from '../../../../store/modules/loading';
import useUpdateProject from '../../../../hooks/useUpdateProject';
import { attachSublevels } from '../../utils/attachSublevels';

const ExpandBtn = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	color: '#0080F9',
	fontWeight: 'bold',
	margin: 0,
	':hover': {
		background: 'unset',
	},
}));

interface ITitle {
	item: ICollapsable;
	column?: number;
}

const Title = (props: ITitle) => {
	const { item, column } = props;
	return (
		<Grid
			item
			xs={2}
			sx={{
				height: '100%',
				backgroundColor: '#fff',
				position: 'sticky !important',
				left: '70px',
				background: '#fff',
				zIndex: 1,
				display: 'flex',
				alignItems: 'center',
				paddingTop: '0px !important',
			}}
		>
			<p className={renderItem.renderTitle}>
				{item.name} {(column || 0) + 1}
			</p>
		</Grid>
	);
};

interface IItem {
	item: ICollapsable;
	column: number;
	activeElements: Set<string>;
	onCheck: (idx: number, UF_ID: string, EF_ID: string, check: boolean) => void;
	onSelectColumn: (id: string, items: ICollapsable[], check: boolean) => void;
	disabled: boolean;
}

const Item = (props: IItem) => {
	const { item, column, onCheck, onSelectColumn, activeElements, disabled } = props;
	return (
		<Grid container item className={renderItem.renderItem} rowSpacing={2}>
			<Collapsable
				activeElements={activeElements}
				id={item.id}
				items={item.items}
				column={column}
				isComplete={true}
				name={item.name}
				value={item.value}
				onCheck={(UF, EF, Check) => onCheck(column, UF, EF, Check)}
				disabled={disabled}
				onSelectColumn={onSelectColumn}
			/>
		</Grid>
	);
};

interface IProps {
	actividadesOptions: IOptionsMenu[];
	isGeograficosDone: boolean | null;
}

const StepTwoContent = (props: IProps) => {
	const { actividadesOptions, isGeograficosDone } = props;
	const dispatch = useDispatch();
	const projectID = useSelector((root: RootState) => root.projectBase._id);
	const [actividadList, setActividadList] = useState<IOptionsMenu[]>([]);
	const [criteriosTable, setCriteriosTable] = useState<ICollapsable[]>([]);
	const [currentService, setCurrentService] = useState<string>('');
	const [isContinue, setIsContinue] = useState<boolean>(false);
	const [checkedItems, setCheckedItems] = useState<Set<string>>(new Set());
	const { value: funtionalValue } = useGetUF_UE();
	const { execute: updateUnitActs, status: statusUnitsActs } = useUpdateUnits_Acts({
		setActividadList,
		currentService,
	});
	const {
		execute: getUnits_Acts,
		urlParams,
		status: getU_AStatus,
		value: getU_AValue,
	} = useGetUnits_Acts({});
	const isNext = useMemo(() => {
		return actividadList.every((i) => i.status);
	}, [actividadList]);
	const { handleUpdateProject } = useUpdateProject();

	useEffect(() => {
		setActividadList(actividadesOptions);
	}, [actividadesOptions]);

	useEffect(() => {
		if (getU_AStatus === 'error' || getU_AStatus === 'pending') return;
		const collection = new Set<string>();
		getU_AValue?.data.forEach((i) => {
			if (i.applies) {
				collection.add(i.functional);
			}
		});

		setCheckedItems(collection);
		setCurrentService(urlParams?.get('activity') || '');
	}, [getU_AStatus]);

	useEffect(() => {
		if (!funtionalValue?.data) return;
		const _adapted: IFunctionalResponse[] = funtionalValue.data;
		const resultArray = attachSublevels(_adapted);
		setCriteriosTable(resultArray);
	}, [funtionalValue]);

	useEffect(() => {
		if (!isEmpty(currentService) || actividadesOptions.length < 1) return;
		setCurrentService(String(actividadesOptions[0].id));
		dispatch(setLoading({ loading: true }));
		getUnits_Acts(
			null,
			`?project=${projectID}&activity=${actividadesOptions[0].id}&type=U-A`
		);
	}, [actividadesOptions, currentService]);

	useEffect(() => {
		if (statusUnitsActs === 'success') {
			if (isContinue) {
				handleUpdateProject({ step: '3.2.1' }, projectID || '');
			} else {
				handleActividad(currentService);
			}
		}
	}, [statusUnitsActs]);

	const handleCheck = (row: number, UF_ID: string, EF_ID: string, check: boolean) => {
		const collection = new Set(checkedItems);
		if (check) collection.add(EF_ID);
		else collection.delete(EF_ID);

		if (criteriosTable[row].items.some((i) => collection.has(i.id))) {
			collection.add(UF_ID); //ADD THE FUNCTIONAL UNIT
		} else collection.delete(UF_ID); //DELETE THE FUNCTIONAL UNIT
		setCheckedItems(collection);
	};

	const handleCheckColumn = (id: string, items: ICollapsableItem[], check: boolean) => {
		const collection = new Set(checkedItems);
		if (check) collection.add(id);
		else collection.delete(String(id));
		items.forEach((item) => {
			if (check) collection.add(item.id);
			else collection.delete(item.id);
		});
		setCheckedItems(collection);
	};

	const handleSelectAll = () => {
		const collection = new Set(checkedItems);
		criteriosTable.forEach((UF) => {
			collection.add(String(UF.id)); //ADD THE FUNCTIONAL UNITS
			UF.items.forEach((EF) => {
				collection.add(String(EF.id)); //ADD ALL THE FUNCITONAL SPACES
			});
		});
		setCheckedItems(collection);
	};

	const handleActividad = async (value: string) => {
		dispatch(setLoading({ loading: true }));
		await getUnits_Acts(null, `?project=${projectID}&activity=${value}&type=U-A`);
	};

	const handleUpdateFuncitonal = async () => {
		const _adapted = updateFunctionalActivities(
			checkedItems,
			projectID || '',
			currentService,
			criteriosTable,
			getU_AValue?.data
		);

		await updateUnitActs(_adapted);
	};

	const handleSave = async () => {
		try {
			setIsContinue(false);
			dispatch(setLoading({ loading: true }));
			await handleUpdateFuncitonal();
		} catch (e) {
			dispatch(
				setAlert({
					show: true,
					severity: 'error',
					message: 'Error al actualizar el proyecto',
				})
			);
		}
	};

	const handleContinue = async () => {
		try {
			setIsContinue(true);
			dispatch(setLoading({ loading: true }));
			await handleUpdateFuncitonal();
		} catch (e) {
			dispatch(
				setAlert({
					show: true,
					severity: 'error',
					message: 'Error al actualizar el proyecto',
				})
			);
		}
	};

	return (
		<>
			<div style={{ overflow: 'auto' }}>
				<Grid
					container
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						zIndex: 2,
						position: 'relative',
						margin: '3rem 0px 3rem 0',
					}}
				>
					<Grid item xs={2}>
						<p style={{ fontWeight: 'bold' }}>Elige una actividad: </p>
					</Grid>
					<Grid item xs={6}>
						<InputSelect
							onChange={(e) => {
								handleActividad(String(e.target.value));
							}}
							value={currentService}
							optionsMenu={actividadList}
							withStatus
						/>
					</Grid>
					<Grid item xs={1} />
					<Grid item xs={2} />
					<Grid item xs={2} />
					<Grid item>
						<ExpandBtn
							id="ie-select-all"
							variant="text"
							disabled={!!isGeograficosDone}
							onClick={handleSelectAll}
						>
							Seleccionar todo
						</ExpandBtn>
					</Grid>
				</Grid>
				<VerticalStepper
					rootStyle={{
						margin: 0,
					}}
					sxContainer={{
						flexWrap: 'nowrap',
						alignItems: 'flex-start',
						justifyContent: 'space-between !important',
					}}
					sxElement={{
						position: 'sticky !important',
						left: '-5px',
						zIndex: 2,
						height: '60%',
						background: '#fff',
						display: 'flex',
						alignItems: 'center',
						paddingTop: '0px !important',
					}}
					items={criteriosTable}
					renderTitle={(item, column) => <Title item={item} column={column} />}
					renderItem={(item, column) => (
						<Item
							item={item}
							column={column}
							activeElements={checkedItems}
							disabled={!!isGeograficosDone}
							onCheck={handleCheck}
							onSelectColumn={handleCheckColumn}
						/>
					)}
				/>
			</div>
			<Grid sx={{ display: 'flex', justifyContent: 'flex-end' }} columnGap={2}>
				<Grid item>
					<SaveButton
						id="ie3-guardar-btn"
						sx={{ width: 'auto' }}
						disabled={!!isGeograficosDone}
						onClick={handleSave}
					/>
				</Grid>
				<Grid item>
					<ContinueButton
						id="ie3-continue-btn"
						disabled={!isNext || !!isGeograficosDone}
						onClick={handleContinue}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default StepTwoContent;
