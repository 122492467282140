/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../../constants/urls.constants';
import { undoState } from '../../../../store/modules/steps';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import { IResponsePayload, useApiRequest } from '../../../../hooks/useApiRequest';
import { useTableCustom } from '../../../../hooks/useTableCustom';
import { IFindings } from '../interfaces/new-incident.interface';
import { useLocation } from 'react-router-dom';
import { SupervisionProjectContext } from '../../contexts/useSupervisionProjectContext';
import {
	getFilesFindingsAdapter,
	getFilesSuspensionsAdapter,
} from '../adapters/files.adapter';
import { RootState } from '../../../../store';

interface IGetFindingTable {
	isSupervisionRoutine: boolean;
	isFindings: boolean;
}

export function useGetFindingsTable({
	isSupervisionRoutine,
	isFindings,
}: IGetFindingTable) {
	const { data, setData, pagination, rowsSelect, rowsPerPage, filters } =
		useTableCustom<Object>();

	const dispatch = useDispatch();
	const location = useLocation();
	const {
		idRoutine,
		idProject,
		supervisionRoutineId,
		setEditFinding,
		setSupervisionRoutineId,
		setStatus,
		setNewState,
		setWithStatus,
	} = useContext(SupervisionProjectContext);
	const idRoutineParams = location?.state?.routine as string;
	const idProjectParams = location?.state?.idProject as string;
	const idRoutineHistory = location?.state?.params?.routine as string;
	const idProyectHistory = location?.state?.params?.project?._id as string;
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const {
		execute: executeGetFindings,
		status: statusGetFindings,
		value: valueGetFindings,
	} = useApiRequest<unknown, IResponsePayload<IFindings>>({
		path: URLS.FILES.FILES_FINDINGS,
		method: 'GET',
	});

	useEffect(() => {
		getFiles();
	}, [pagination, rowsSelect, rowsPerPage, filters]);

	useEffect(() => {
		if (statusGetFindings === 'success') {
			dispatch(stopLoading());
			setData({
				...data,
				countRows: valueGetFindings?.totalRecords || 0,
				rows: isFindings
					? getFilesFindingsAdapter(
							valueGetFindings?.data || [],
							handleEdit,
							isSupervisionRoutine
					  )
					: getFilesSuspensionsAdapter(
							valueGetFindings?.data || [],
							handleEdit,
							isSupervisionRoutine
					  ),
			});
		} else if (statusGetFindings === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ha ocurrido un error.',
					severity: 'error',
				})
			);
			undoState();
		}
	}, [statusGetFindings]);

	const handleEdit = (finding: IFindings, isSuspension: boolean) => {
		setSupervisionRoutineId(finding.supervisionRoutine);
		setWithStatus(null);
		setStatus('pending');
		setEditFinding({
			name: '',
			description: isSuspension
				? finding?.suspensionDescription || ''
				: finding?.findingDescription || '',
			type: isSuspension
				? finding?.causeSuspension || ''
				: finding?.typeFinding || '',
			project: '',
			files: isSuspension ? finding?.suspensions || [] : finding?.findings || [],
		});
		setNewState(isSuspension ? 6 : 4);
	};

	const getFiles = () => {
		dispatch(setLoading({ loading: true }));
		if (idRoutineHistory)
			executeGetFindings(
				null,
				`/${
					isFindings ? 'finding-files' : 'suspension-files'
				}?routine=${idRoutineHistory}&page=${pagination}&recordsByPage=${rowsPerPage}&project=${
					idProyectHistory || _id
				}`
			);
		else if (!isSupervisionRoutine) {
			executeGetFindings(
				null,
				`/${isFindings ? 'finding-files' : 'suspension-files'}?routine=${
					idRoutineParams ? idRoutineParams : idRoutine
				}&page=${pagination}&recordsByPage=${rowsPerPage}&project=${
					idProjectParams ? idProjectParams : idProject || _id
				}&advancedSearch=${filters?.advancedSearch || ''}`
			);
		} else {
			if (supervisionRoutineId) {
				executeGetFindings(
					null,
					`/${
						isFindings ? 'finding-files' : 'suspension-files'
					}/by-supervision-routine/${supervisionRoutineId}`
				);
			}
		}
	};

	return {
		data,
		_id,
		idRoutine,
		idRoutineParams,
		idProject,
		idProjectParams,
		setData,
	};
}
