import { Button, Grid, styled, useMediaQuery } from '@mui/material';
import styles from './styles/row-info-indicator.module.css';
import theme from '../../../../config/theme';
import { IAdaptedActivities } from '../../interfaces/get-activities.interface';
import { IOptionsMenu } from '../../../../interfaces/select.interface';

const ContainedBtn = styled(Button)<{ background: string; customcolor: string }>(
	({ theme, customcolor, background }) => ({
		textTransform: 'none',
		backgroundColor: background,
		color: customcolor,
		marginLeft: 'auto',
		borderRadius: '8px',
		minWidth: '157px',
		minHeight: '45px',
		':hover': {
			'background-color': background,
			color: customcolor,
		},
	})
);

interface IRowItemIndicator {
	handleUpdateFrequency: (frequency: string, item: IAdaptedActivities) => void;
	frequenciesOptions: IOptionsMenu[];
	column: number;
	item: IAdaptedActivities;
}

export const RowItemIndicator = (props: IRowItemIndicator) => {
	const { frequenciesOptions, column, handleUpdateFrequency, item } = props;
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			item
			xs={matches ? 10 : 11}
			className={styles.renderItem}
			alignItems={'center'}
		>
			<Grid item>
				<span style={{ fontWeight: 'bold' }}>{item.description}</span>
			</Grid>
			{frequenciesOptions.map((i, idx) => {
				return (
					<Grid item key={`freq-${column}-${idx}-${i.value}`}>
						<ContainedBtn
							customcolor={item.frequency === i.value ? '#fff' : '#727272'}
							background={
								item.frequency === i.value ? '#0080F9' : '#CECECE'
							}
							onClick={() => handleUpdateFrequency(String(i.value), item)}
						>
							{i.label}
						</ContainedBtn>
					</Grid>
				);
			})}
		</Grid>
	);
};
