/* eslint-disable react-hooks/exhaustive-deps */
import CheckboxMUI from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup, Grid, styled, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button } from '../../../../../components/main-button.component';
import theme from '../../../../../config/theme';
import {
	ISpatialUnit,
	TSpatialUnit,
} from '../../../interfaces/get-supervision.interfaces';
import { useSaveFunctionals } from '../hooks/useSaveFunctionals';
import { STEPS_SUPERVISION_EXTRAORDINARY } from '../../../constants/steps-supervision-extraordinary';

interface IStepTwoGD {
	items: TSpatialUnit[];
	rawData: ISpatialUnit[];
	initialValue: string[];
	setSteps: React.Dispatch<React.SetStateAction<{ step1: boolean; step2: boolean }>>;
}

const CheckBox = styled(CheckboxMUI)(({ theme }) => ({
	color: 'var(--color-highlight-blue)',
	'&.Mui-checked': {
		color: 'var(--color-highlight-blue)',
	},
}));

export const StepTwoGD = ({ items, initialValue, rawData }: IStepTwoGD) => {
	const [checkedList, setCheckedList] = useState<Set<number | string>>(new Set());
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	const { executeSaveFunctionals } = useSaveFunctionals({});

	const onSelect = (index: number | string, checked: boolean) => {
		const collection = new Set(checkedList);

		if (checked) collection.add(index);
		else collection.delete(index);

		setCheckedList(collection);
	};

	const selectAllColumns = () => {
		const collection = new Set(checkedList);

		for (const column of items) {
			for (const row of column) {
				if (!collection.has(row.functional)) collection.add(row.functional);
			}
		}

		setCheckedList(collection);
	};

	useEffect(() => {
		const collection = new Set(initialValue);
		setCheckedList(collection);
	}, [initialValue]);

	const adaptValues = (): { _id: string; applies: boolean }[] => {
		return rawData.map((e) => {
			return {
				_id: e.id.toString(),
				applies: checkedList.has(e.functional) ? true : false,
			};
		});
	};

	return (
		<Grid
			container
			marginTop={2}
			spacing={2}
			style={{
				padding: '8px 0px 0px 8px',
			}}
		>
			<Grid item xs={12}>
				<FormControlLabel
					sx={{ fontSize: '20px' }}
					checked={selectAll}
					value={0}
					control={<CheckBox />}
					label={
						<p
							style={{
								fontSize: '20px',
								margin: 0,
								color: 'var(--color-highlight-blue)',
							}}
						>
							Seleccionar todo
						</p>
					}
					onChange={() => {
						setSelectAll(!selectAll);
						if (!selectAll) selectAllColumns();
						else setCheckedList(new Set());
					}}
				/>
			</Grid>
			<Grid item>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
					}}
				>
					{items.map((item, column) => (
						<div key={`formGroup-${column}`}>
							<FormGroup>
								{item.map((prop, row) => (
									<FormControlLabel
										sx={{ fontSize: '20px' }}
										key={`formControl-${column}-${row}`}
										checked={checkedList.has(prop.functional)}
										value={prop.id}
										control={<CheckBox id={`checkbox-${prop.id}`} />}
										label={
											<p style={{ fontSize: '18px', margin: 0 }}>
												{prop.name}
											</p>
										}
										onChange={(_, checked) =>
											onSelect(prop.functional, checked)
										}
									/>
								))}
							</FormGroup>
						</div>
					))}
				</div>
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent={matches ? 'center' : 'flex-end'}
					spacing={2}
				>
					<Grid item>
						<Button
							label="Finalizar dimensiones geográficas"
							onClick={() => {
								//dispatch(setState({ state: 2 }));
								executeSaveFunctionals(
									adaptValues(),
									STEPS_SUPERVISION_EXTRAORDINARY.ACT_1
								);
							}}
							style={{
								backgroundColor: 'var(--color-highlight-blue)',
							}}
							disabled={checkedList.size < 1}
							id="btn-continue-geographical"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
