export const LABELS_CREATE_TEAM = Object.freeze({
	AUTOMATIC_CHARGE: {
		TITLE: '1. Escoge los integrantes del equipo',
		ALERT: 'Selecciona al personal que estará encargado de cada una de las relaciones, así como a los sustitutos.',
	},
	EDIT: {
		TITLE: '1. Edita los integrantes del equipo',
		ALERT: 'Selecciona al personal que estará encargado de cada una de las relaciones, así como a los sustitutos.',
	},
});
