import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const DashedButton = styled(Button)<{ width: number | string; disabled: boolean }>(
	({ theme, width, disabled }) => ({
		textTransform: 'none',
		border: `1px dashed ${disabled ? '#00000042' : '#0080F9'}`,
		color: '#3F9FFA',
		width: `${width}%`,
		margin: 'auto',
	})
);

interface IProps {
	onClick: () => void;
	disabled?: boolean;
	id?: string;
	text?: string;
	width?: string;
}
const DashedBtn = (props: IProps) => {
	const {
		onClick,
		disabled = false,
		id = 'btn-add-attr',
		text = 'Agregar atributo secundario',
		width = '85',
	} = props;
	return (
		<DashedButton
			width={width}
			id={id}
			variant="text"
			onClick={onClick}
			disabled={disabled}
		>
			{text}
		</DashedButton>
	);
};

export default DashedBtn;
