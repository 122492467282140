export const URLS = {
	AUTOMATIC_CHARGE: {
		LOAD_DATA: 'automatic-charge/load-data',
		CONFIRMATION: 'automatic-charge/confirm',
		STATUS: 'automatic-charge/status',
		RESTART: 'automatic-charge/restart',
	},
	LOGIN: {
		AUTH: 'auth/login',
		AUTH_INFO: 'auth/login-ad',
		SEND_MAIL_VERIFICATION: 'auth/send-mail-verification',
		RESET_PASSWORD: 'auth/reset-password',
	},
	PROJECT: {
		CREATE_PROJECT: 'projects',
		PROJECT_BY_SUPERVISOR: 'projects/by-supervisor',
		MANY_FUNCTIONAL: 'functional/many',
		FUNCTIONAL: 'functional',
		FUNCTIONAL_SHORT: 'functional/short',
		ACTIVITY: 'activity',
		MANY_ACTIVITIES: 'activity/many',
		INDICATOR: 'indicator',
		FINDINGS: 'findings',
		MAN_FINDINGS: 'findings/many',
		MANY_INDICATORS: 'indicator/many',
		SUPERVISION_UNIT: 'supervision-unit',
		DEFAULT_FREQ: 'supervision-unit/default-frequency',
		MANY_SUPERVISION_UNIT: 'supervision-unit/many',
		SUPERVISORS: 'projects/supervisors-by-project',
		PERSONAL: 'personal',
		PERSONAL_MANY: 'personal/many',
		FRECUENCY: 'frequency',
		MANY_FRECUENCY: 'frequency/many',
		NON_WORKING_DAYS: 'non-working-days',
		GET_TYPE_FINDINGS: 'projects/types-findings',
		GET_FILES: 'projects/files',
		SET_COVER_IMAGE: 'projects/files/make-cover-image',
	},
	EDIT_PROJECT: {
		CHRONOGRAM: {
			GET_FREQUENCIES: 'project-edit/frequencies',
			GET_ACTIVITIES: 'project-edit/chronogram/activities',
			UPDATE_ACTIVITY_FREQUENCY: 'project-edit/chronogram/activity',
			GET_FUNCTIONALS: 'project-edit/chronogram/functionals',
			GET_ACTIVITIES_BY_FUNCTIONAL:
				'project-edit/chronogram/activities-by-functional',
			GET_NON_WORKING_DAYS: 'project-edit/chronogram/non-working-days',
			GET_INDICATORS_BY_FUNCTIONAL:
				'project-edit/chronogram/indicators-by-activity-and-functional',
		},
		ACTIVITIES: {
			GET_ACTIVITIES: 'project-edit/activity/activities',
			CREATE_ACTIVITY: 'project-edit/activity',
		},
		INDICATORS: {
			GET_GENERAL_INDICATORS: 'project-edit/indicator',
			UPDATE_INDICATOR: 'project-edit/indicator/indicators',
			CREATE_INDICATOR: 'project-edit/indicator',
		},
		FUNCTIONALS: {
			GET_FUNCTIONAL: 'project-edit/functional',
			UPDATE_FUNCTIONAL: 'project-edit/functional/functionals',
			CREATE_FUNCTIONAL: 'project-edit/functional',
		},
		ROLES: { GET_ROLES: 'roles' },
		PERSONALS: {
			GET_PERSONAL_BY_ROLE: 'personals',
			GET_PERSONAL_BY_PROJECT: 'personals/by-project',
		},
		TEAMS: {
			GET_TEAMS: 'teams',
		},
	},
	USERS: {
		GET_USERS: 'users',
		CHANGE_PASSWORD: 'users/change-password',
		STATS: 'stats/monthly-performance',
	},
	DEVICES: {
		GET_DEVICES: 'devices',
	},
	FILES: {
		GET_FILES: 'file',
		DOWNLOAD_FILE: 'files/find',
		ROUTINE: 'files/routine-files',
		FILES_FINDINGS: 'files',
		FILES_SUPERVISION: 'files/supervision-routine-files',
	},
	SUPERVISION_ROUTINE: {
		CALENDAR_PERIOD: 'supervision-routine/calendar',
		FILES: 'files/supervision-routine-files',
		UPDATE_FINDINGS: 'files/finding-files',
		UPDATE_SUSPENSIONS: 'files/suspension-files',
	},
	SUPERVISION_EXECUTION: {
		GET_FUNCTIONALS: 'supervision-execution',
		GET_SUPERVISION_ROUTINES: 'supervision-execution/supervision-routines',
		GET_SUMMARY_ROUTINE: 'supervision-execution/routine/summary',
		CHANGE_STATUS: 'supervision-execution/change-status',
		START_SUPERVISION: 'supervision-execution/routine/start-supervision',
		START_TIMING: 'supervision-execution/routine/start-timing',
		STOP_TIMING: 'supervision-execution/routine/stop-timing',
		INCOMING_ROUTINES: 'incoming-routines',
		ROUTINE_DETAIL: 'supervision-execution/supervision-routines/detail',
		SUPERVISION_DETAIL: 'supervision-execution/supervision-routines',
		CHANGE_STATUS_ROUTINE: 'supervision-execution/change-status-routine',
		DASHBOARD: 'supervision-execution/dashboard',
		COMPLETE_SUPERVISION: 'supervision-execution/routine/complete-supervision',
		GET_FINDINGS: 'supervision-execution/routine/findings',
		GET_ROUTINE_ACTIVITIES: 'supervision-execution/routine/summary-data',
		GET_STATS: 'supervision-execution/routine/stats',
		ACTIVITIES_LABELS: 'supervision-execution/dashboard/activities-description',
	},
	SUPERVISION_UNIT: {
		GET_FUNCTIONALS: 'supervision-unit/functionals',
		GET_ACTIVITIES: 'supervision-unit/activities',
		GET_INDICATORS: 'supervision-unit/indicators',
		GET_LEVELS: 'supervision-unit/functionals-levels',
		GET_FUNCTIONALS_ACTIVITIES: 'supervision-unit/functionals-activities',
	},
	REPORTS: {
		GENERATE_REPORTS: 'reports/generate',
		GET_URL_REPORT: 'reports/get-file',
		GET_REPORTS: 'reports',
		DOWNLOAD_REPORTS: 'reports/download',
		DOWNLOAD_ZIP: 'reports/download-zip',
	},
	SUPERVISION_EXTRAORDINARY: {
		IN_PROGRESS: 'extraordinary-supervision/in-process',
		START: 'extraordinary-supervision/start',
		CHANGE_STEP: 'extraordinary-supervision/change-step',
		GET_LEVELS: 'extraordinary-supervision/functionals-levels',
		GET_FUNCTIONALS: 'extraordinary-supervision/functionals',
		SAVE_FUNCTIONALS: 'extraordinary-supervision/functionals/many',
		GET_ACTIVITIES: 'extraordinary-supervision/activities',
		SAVE_ACTIVITIES: 'extraordinary-supervision/activities/many',
		GET_UNITS: 'extraordinary-supervision/extraordinary-units',
		SAVE_UNITS: 'extraordinary-supervision/extraordinary-units/many',
	},
	INCOMING_ROUTINES: {
		NON_RECURRENT_TEMPLATE: 'incoming-routines/non-recurrent-template-routines',
		ASSIGN_SUPERVISION_DATE:
			'incoming-routines/create-non-recurrent-supervision-routine',
		RESCHEDULE_ROUTINE: 'incoming-routines/reschedule/routine',
	},
	SUPERVISION_HISTORY: {
		HISTORY_SUMMARY: 'supervision-history/summary',
		HISTORY_BY_ROUTINE: 'supervision-history/by-routine',
		HISTORY_ROUTINES_COMPLETE: 'supervision-history/routines/completed',
		HISTORY_ROUTINES_PENDING: 'supervision-history/routines/pending',
		HISTORY_BY_ACTIVITIES: 'supervision-history/by-activity/activities',
		HISTORY_FUNCTIONALS: 'supervision-history/by-functional/functionals',
		HISTORY_FUNCTIONALS_BY_FUNCTIONALS:
			'supervision-history/by-functional/functionals-by-functionals',
		HISTORY_BY_FUNCTIONAL_ROUTINES: 'supervision-history/by-functional/routines',
		HISTORY_BY_ACTIVITY_ROUTINES: 'supervision-history/by-activity/routines',
		ALL_ACTIVITIES_BY_SUPERVISOR: 'supervision-history/by-supervisor/activities',
		BASE_TASKS: 'supervision-history/by-project/activities',
		DASHBOARD: 'supervision-history/by-project/dashboard',
	},
	ACTIONS: {
		GET_ACTIONS: 'actions',
	},
	EXTRAORDINARY_INCIDENCE: {
		CREATE_EXTRAORDINARY_INCIDENCE: 'extraordinary-incidence',
	},
	NOTIFICATIONS: {
		PENDING_NOTIFICATIONS: 'notifications/user/pending-notifications',
		NOTIFICATIONS_USER_DEVICES: 'notifications/user/devices',
		NOTIFICATIONS_USER: 'notifications/user',
		NOTIFICATIONS: 'notifications',
	},
	SUSPENSION: {
		SUSPENSION_MANAGEMENT: 'suspension-management',
		SUSPENSION_MANAGEMENT_BY_ROUTINE: 'suspension-management/by-routine',
		SUSPENSION_MANAGEMENT_CONFIRM_BY_ROUTINE:
			'suspension-management/confirm-by-routine',
		SUSPENSION_MANAGEMENT_CONFIRM_BY_SUPERVISION_ROUTINE:
			'suspension-management/confirm-by-supervision-routine',
		SUSPENSION_MANAGEMENT_RESCHEDULE_BY_ROUTINE:
			'suspension-management/reschedule-by-routine',
		SUSPENSION_MANAGEMENT_RESCHEDULE_BY_SUPERVISION_ROUTINE:
			'suspension-management/reschedule-by-supervision-routine',
	},
	USER_PROJECT_MANAGEMENT: {
		VALIDATE_SUSPENSION: 'user-project-management/validate-suspension',
		GET_ACTIVITIES: 'user-project-management/activities',
		PERMANENT_SUSPENSION: 'user-project-management/permanent-user-suspension',
		TEMPORARY_SUSPENSION: 'user-project-management/temporary-user-suspension',
	},
};
