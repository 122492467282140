/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import theme from '../../../../../../config/theme';
import { useGetActivities } from './useGetActivities';
import { useGetFrequencies } from '../../../../hooks/useGetFrequencies';
import { useNavigate } from 'react-router-dom';
import { useUpdateActivitiesFrequencies } from './useUpdateActivititesFrequencies';

export const useViewActivities = () => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();

	const { frequenciesOptions } = useGetFrequencies();
	const { activitiesOptions, setActivitiesOptions } = useGetActivities();
	const { handleUpdateActivitiesFrequencies } = useUpdateActivitiesFrequencies({
		activitiesOptions,
		setActivitiesOptions,
	});

	const handleFrequency = (column: number, value: string, item: any) => {
		handleUpdateActivitiesFrequencies(item.id, { frequency: value });
	};

	return {
		frequenciesOptions,
		activitiesOptions,
		matches,
		handleFrequency,
		navigate,
	};
};
