import { ICheck } from '../components/vertical-stepper-fixed.component';

export interface IAdaptedValues extends ICheck {
	id: string;
	mainAttribute: string;
	description: string;
	rows: { key: string; value: string; isDisabled?: boolean }[];
	frequency: string;
	disabled?: boolean;
	attributes?: string[];
	idPersonal?: string;
}

export const IAdaptValue: IAdaptedValues = {
	id: '',
	mainAttribute: '',
	description: '',
	rows: [],
	frequency: '',
	disabled: false,
	attributes: [],
	name: '',
	isComplete: false,
};
