/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants/urls.constants';
import { useApiRequest } from '../../../hooks/useApiRequest';
import {
	defaultNewUser,
	INewUser,
	NewUserModelKeys,
} from '../../../interfaces/new-user.interface';
import { stopLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { isNumber } from '../../../utils/validators';

export function useNewUser() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [users, setUsers] = useState<INewUser[]>([{ ...defaultNewUser }]);
	const [currentTabValue, setCurrentTabValue] = useState<number>(0);
	const [currentUser, setCurrentUser] = useState<INewUser>({ ...defaultNewUser });

	const {
		execute: createUsers,
		value: valueUsers,
		status: statusUsers,
	} = useApiRequest<INewUser, { message: string }>({
		path: URLS.USERS.GET_USERS,
		method: 'POST',
	});

	useEffect(() => {
		if (statusUsers === 'success') {
			dispatch(stopLoading());
			navigate(-1);
		} else if (statusUsers === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: valueUsers?.message || 'Ocurrió un error al crear usuario',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusUsers]);

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setCurrentTabValue(newValue);
		setCurrentUser(users[newValue]);
	};

	const handleCloseTab = (
		e: React.MouseEvent<SVGSVGElement, MouseEvent>,
		index: number
	) => {
		e.stopPropagation();
		const newUsers = (users || []).filter((x: any, i: number) => i !== index);

		setUsers(newUsers);
		if (currentTabValue !== 0) {
			setCurrentTabValue((v) => (v = v - 1));
			setCurrentUser(users[currentTabValue - 1]);
		}
	};

	const handleSetData = (
		e: React.ChangeEvent<HTMLInputElement>,
		property: NewUserModelKeys
	) => {
		const { name, value } = e.target;
		if (name === 'employeeNumber' && !isNumber(value)) return;
		const newData: any[] = users;
		newData[currentTabValue][property] = value;
		setCurrentUser({
			...currentUser,
			[name]: value,
		});
		setUsers(newData);
	};

	const addUser = () => {
		const newUser = { ...defaultNewUser };
		const newUserFlag = [...(users || [])];
		newUserFlag.unshift(newUser);
		setCurrentUser({ ...defaultNewUser });
		setCurrentTabValue(0);
		setUsers(newUserFlag);
	};

	return {
		users,
		currentUser,
		currentTabValue,
		handleChangeTab,
		handleCloseTab,
		handleSetData,
		addUser,
		createUsers,
	};
}
