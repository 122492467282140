import StepTwoFixed from '../../../../components/step-two-fixed';
import { IStepProps, IRenderItemProps } from '../../../../interfaces/stepTwo.interface';
import Grid from '@mui/material/Grid';
import { TextArea } from '../../../../components/text-area.component';
import renderItem from '../../../../components/styles/render-item-styles.module.css';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

interface IItem {
	itemProps: IRenderItemProps;
}

const Input = styled(TextField)(({ theme }) => ({
	background: '#F7F7F7',
	borderRadius: '6px',
	fieldset: {
		border: 'none',
	},
}));

const ColumnItem = (props: IItem) => {
	const { item, column, handleTable, disabled } = props.itemProps;
	return (
		<Grid item className={renderItem.renderItem}>
			{item.columns.map((el, row) => (
				<TextArea
					id={`table-${column}-${row}`}
					key={`table-${column}-${row}`}
					RenderTitle={() => {
						if (column === 0) return <p>{item.attributes[row]}</p>;
						return null;
					}}
					disabled={disabled}
					defaultValue={el}
					onChange={(val) => {
						handleTable(column, row, val);
					}}
					onPaste={(txt) => {
						handleTable(column, row, txt);
					}}
				/>
			))}
		</Grid>
	);
};

const InputItem = (props: IItem) => {
	const { item, column, handleTable, disabled } = props.itemProps;
	return (
		<>
			{item.columns.map((el, row) => (
				<Input
					id={`table-${column}-${row}`}
					key={`table-${column}-${row}`}
					variant="outlined"
					size="small"
					fullWidth
					disabled={disabled}
					placeholder="Escribe aquí"
					value={el}
					onChange={(e) => handleTable(column, row, e.target.value)}
				/>
			))}
		</>
	);
};

const StepTwoComponent = (props: IStepProps) => {
	const { data } = props;

	return (
		<StepTwoFixed
			{...props}
			id="btn-continue-lvl2"
			renderItem={(props) => {
				if (data[0].attributes.length > 1)
					return <ColumnItem itemProps={props} />;
				return <InputItem itemProps={props} />;
			}}
		/>
	);
};

export default StepTwoComponent;
