/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from './../../../config/theme';
import { DOCS_EXTENSIONS, DOCS_TYPES } from './../../../constants/files-type';
import { bytesToMB } from './../../../utils/converts';
import { fileToBase64 } from './../../../utils/fileToBase64';
import { setAlert } from './../../../store/modules/snackbar';
import {
	INewExtraordinaryIncident,
	DefaultNewExtraordinaryIncident,
} from '../interfaces/extraordinary-incidence.interface';

export function useEvidences() {
	const dispatch = useDispatch();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [camera, setCamera] = useState(false);
	const [data, setData] = useState<INewExtraordinaryIncident>(
		DefaultNewExtraordinaryIncident
	);

	const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (data.files.length === 4) {
			dispatch(
				setAlert({
					show: true,
					message: 'No se pueden cargar más fotos.',
					severity: 'error',
				})
			);
			return;
		}
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const fullName = file.name.split('.');
			const size = file.size;
			const reader = new FileReader();
			const extension = file ? fileList[0].type : '';
			reader.onload = (e) => {
				const srcData = reader.result;
				if (srcData) {
					setData({
						...data,
						files: [
							...data.files,
							{
								fileName:
									fullName[0] +
									'_' +
									Date.now() +
									'.' +
									DOCS_EXTENSIONS[extension || 'image/jpeg'],
								type: DOCS_EXTENSIONS[extension],
								fileBase64: fileToBase64(srcData),
								description: data.findingDescription,
								size: bytesToMB(size),
							},
						],
					});
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const deleteFile = (index: number) => {
		const newFindings = data.files;
		newFindings.splice(index, 1);
		setData({ ...data, files: newFindings });
	};

	const handlePhoto = (imageSrc: string | null | undefined) => {
		if (data.files.length === 4) {
			dispatch(
				setAlert({
					show: true,
					message: 'No se pueden cargar más fotos.',
					severity: 'error',
				})
			);
			return;
		}
		if (imageSrc) {
			setData({
				...data,
				files: [
					...data.files,
					{
						fileName: `photo_user_${Date.now()}.jpeg`,
						type: DOCS_TYPES.JPEG,
						fileBase64: fileToBase64(imageSrc),
						description: data.findingDescription,
						size: '',
					},
				],
			});
			setCamera(false);
		}
	};

	const disabledButton = useMemo((): boolean => {
		if (data.findingDescription !== '' && data.files.length > 0) return false;
		return true;
	}, [data]);

	return {
		setData,
		data,
		matches,
		camera,
		disabledButton,
		dispatch,
		handleFile,
		deleteFile,
		setCamera,
		handlePhoto,
	};
}
