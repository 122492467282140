import { IUserRoles } from '../utils/permissions.utils';

export interface IUser {
	_id: string;
	firstName: string;
	lastName: string;
	employeeNumber: number;
	email: string;
	position: string;
	role: keyof IUserRoles;
	__v: number;
	token?: string;
	isLogged?: boolean;
	status?: boolean | string;
	lastConnection?: string;
	acciones?: JSX.Element;
	isDeviceCreated: boolean;
	loginMicrosoft: boolean;
}

export interface IGetPersonalByProject {
	supervisors: IGetPersonalOptions[];
	teams: ITeam[];
}

export interface IGetPersonalOptions {
	_id: string;
	firstName: string;
	lastName: string;
	fullName: string;
}

export interface ITeam {
	_id: string;
	teamName: string;
}

export interface IGetPersonal {
	active: boolean;
	rol: string;
	user: string;
	project: string;
	columns: string[];
	_id?: string;
}

export const defaultValueUser: IUser = {
	_id: '',
	firstName: '',
	lastName: '',
	employeeNumber: 0,
	email: '',
	position: '',
	role: 'ADMIN',
	__v: 0,
	token: '',
	isLogged: false,
	status: true,
	lastConnection: '',
	isDeviceCreated: false,
	loginMicrosoft: false,
};
